import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {InvoiceFlowsService} from "../data/invoice-flows.service";
import {HttpParams} from "@angular/common/http";
import {InvoiceFlow} from "../data/interfaces/invoice-flow";

@Component({
  selector: 'invoice-states-flow',
  templateUrl: './invoice-states-flow.component.html',
  styleUrls: ['./invoice-states-flow.component.scss']
})
export class InvoiceStatesFlowComponent implements OnInit, OnChanges {
  items: any[];
  editID: number;
  comments: string;

  constructor(private InvoiceFlowsService: InvoiceFlowsService) { }

  @Input() id: number;
  @Input() language: string;
  @Input() invoiceNumber: string;

  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    this.items=[];
    this.editID=-1;
    if(this.id==undefined) this.id=0;
    if(this.language==undefined) this.language='';
    if(this.invoiceNumber==undefined) this.invoiceNumber='';
  }
  ngOnChanges() {
    let params=new HttpParams().append('invoice_states','1');
    this.InvoiceFlowsService.get(this.language, this.id, params).subscribe(
        (data)=>this.items=data,
        (error)=>console.error(error)
    )
  }
  toggleEditing(id: number, comments: string) {
    this.editID=this.editID>0 ? -1 : id;
    if(this.editID>-1) {
      this.comments=comments;
      setTimeout(() =>
          document.getElementById('comments-' + this.editID.toString()).focus(), 100);
    }
  }
  save(id: number) {
    // Store invoice flow comment
    let data={id: id, comments: this.comments}
    this.InvoiceFlowsService.update(data).subscribe(
        ()=>{},error=>console.error(error),()=> {
          // Reset editing
          this.editID = -1;
          // Update items
          for(let i=0; i<this.items.length; i++)
            if(this.items[i].id===id)
              this.items[i].comments=this.comments;
        }
    );
  }
  close() {
    this.onClose.emit();
  }
}
