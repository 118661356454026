import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppLayouts} from "../../app.component";
import {FinancialDashboardComponent} from "./financial.dashboard.component";
import {PricelistsComponent} from "./pricelists/datatable/pricelists.component";
import {PricelistComponent} from "./pricelists/form/pricelist.component";
import {ConcretePricesComponent} from "./concrete_prices/datatable/concrete_prices.component";
import {ConcreteSpecsContainerComponent} from "./concrete_specs/concrete.specs.container.component";
import {ArticlePricesComponent} from "./article_prices/datatable/article_prices.component";
import {ArticlegroupsComponent} from "./articlegroups/articlegroups.component";
import {ArticleComponent} from "./articles/form/article.component";
import {ArticlesComponent} from "./articles/datatable/articles.component";
import {ArticlePriceTypesComponent} from "./article_price_types/article_price_types.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {ArticleResolver} from "./data/resolvers/article.resolver";
import {ConcretePricelistResolver} from "./data/resolvers/concrete-pricelist.resolver";
import {ArticlePricelistResolver} from "./data/resolvers/article-pricelist.resolver";
import {ResourcePricelistsComponent} from "./resources/datatable/resource-pricelists.component";
import {ResourcePricelistResolver} from "./data/resolvers/resource-pricelist.resolver";
import {ResourcePricesComponent} from "./resources/datatable/resource_prices.component";

const routes: Routes = [
  {
    path: 'financial',
    component: FinancialDashboardComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/concrete-pricelists',
    component: PricelistsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/concrete-pricelist/:id',
    component: PricelistComponent,
    canActivate: [AuthGuardService],
    resolve: { pricelist: ConcretePricelistResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/concrete-prices/:id',
    component: ConcretePricesComponent,
    canActivate: [AuthGuardService],
    resolve: { pricelist: ConcretePricelistResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/concrete-environments',
    component: ConcreteSpecsContainerComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/concrete-strengths',
    component: ConcreteSpecsContainerComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/concrete-consistencies',
    component: ConcreteSpecsContainerComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/article-pricelists',
    component: PricelistsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/article-pricelist/:id',
    component: PricelistComponent,
    canActivate: [AuthGuardService],
    resolve: { pricelist: ArticlePricelistResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/articles/create',
    component: ArticleComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/article-prices/:id',
    component: ArticlePricesComponent,
    canActivate: [AuthGuardService],
    resolve: { pricelist: ArticlePricelistResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/articlegroups',
    component: ArticlegroupsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/articles',
    component: ArticlesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },

  {
    path: 'settings/financial/articles/:id',
    component: ArticleComponent,
    canActivate: [AuthGuardService],
    resolve: { article: ArticleResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/article-pricetypes',
    component: ArticlePriceTypesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/resource-pricelists',
    component: ResourcePricelistsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/resource-pricelist/:id',
    component: PricelistComponent,
    canActivate: [AuthGuardService],
    resolve: { pricelist: ResourcePricelistResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/financial/resource-prices/:id',
    component: ResourcePricesComponent,
    canActivate: [AuthGuardService],
    resolve: { pricelist: ResourcePricelistResolver },
    data: {AppLayout: AppLayouts.User}
  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FinancialRoutingModule { }
