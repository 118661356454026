<form [formGroup]="form" (ngSubmit)="save()" class="article_pricelist">
    <div class="jbm-modal-header">
        <jbm-crud-title [subject]="caption" [action]="action"></jbm-crud-title>
        <div class="toolbar float-end">
            <jbm-activity-log-button *ngIf="pricelist.id" subject_type="21" class="bg-light"
              [subject_id]="pricelist.id" [subject]="activity_subject"
              [title]="pricelist.description">
            </jbm-activity-log-button>
            <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <div *ngIf="customer_id" class="clearfix">
            <jbm-static-label labelClass="width-8" [labelCaption]="'entity.customer'|translate"
              [value]="customer.name">
                <br />
                <button class="btn btn-sm btn-light ml-2" tabindex="-1" (click)="showCustomer();$event.stopPropagation();">
                    {{'entity.customer'|translate}} {{'ui.details'|translate|lowercase}}
                </button>
            </jbm-static-label>
            <hr />
        </div>
        <jbm-text-input [formControl]="formControls.description" [submitted]="isSubmitted" [id]="'description'"
          [maxlength]="125" required="true" labelCaption="{{'entity.description'|translate}}"
          labelClass="width-8" class="mt-3 mb-3 input-25">
            <jbm-input-error [formControl]="formControls.description" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-text-input>
        <jbm-select [formControl]="formControls.type" [options]="type_options" [submitted]="isSubmitted"
          [id]="'type'" [editable]="pricelist.id==0" [insertLeadingOption]="true" [required]="true"
          labelCaption="{{'pricelist.type'|translate}}" labelClass="width-8" class="input-25">
            <jbm-input-error [formControl]="formControls.type" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-select>
        <fieldset class="width-34 mt-3"><legend>{{'pricelist.validity'|translate}}</legend>
            <ngb-alert *ngIf="warning" type="warning" [dismissible]="false">{{warning}}</ngb-alert>
            <ngb-alert *ngIf="warningOverlap" type="warning" [dismissible]="false" class="mt-2">
                <b>{{warningOverlap}}</b>
                <div class="jbm-properties-list mt-2">
                    <div class="item"><label class="label width-8">{{'financial.concrete-pricelist'|translate}}:</label><label class="value">{{data.description}}</label></div>
                    <div class="item"><label class="label width-8">{{'pricelist.validity'|translate}}:</label><label class="value">{{data.valid_from}} {{'ui.up-to-and-including'|translate|lowercase}} {{data.valid_to}}</label></div>
                </div>
            </ngb-alert>
            <jbm-date-input [formControl]="formControls.valid_from" [submitted]="isSubmitted" [id]="'valid_from'"
              [maxlength]="10" required="true" labelCaption="{{'pricelist.valid-from'|translate}}" labelClass="width-7" class="input-8">
                <jbm-input-error [formControl]="formControls.valid_from" [submitted]="isSubmitted">
                  <span *ngIf="formControls['valid_from'].hasError('dateInvalid')">
                    {{'entity.dateformat'|translate}}</span>
                </jbm-input-error>
            </jbm-date-input>
            <jbm-date-input [formControl]="formControls.valid_to" [submitted]="isSubmitted" [id]="'valid_to'"
              [maxlength]="10" required="true" labelCaption="{{'pricelist.valid-to'|translate}}"
              labelClass="width-7" class="input-8">
                <jbm-input-error [formControl]="formControls.valid_to" [submitted]="isSubmitted">
                  <span *ngIf="formControls['valid_to'].hasError('dateInvalid')">
                    {{'entity.dateformat'|translate}}</span>
                </jbm-input-error>
            </jbm-date-input>
        </fieldset>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button subject="{{caption|translate}}" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
<jbm-toasts></jbm-toasts>
<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
