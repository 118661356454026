import {Component, EventEmitter, Input, Output, HostListener, ElementRef} from '@angular/core';

@Component({
  selector: 'jbm-table-layout',
  templateUrl: './tablelayout.component.html',
  styleUrls: ['./tablelayout.component.scss'],
  host: {'class': 'JBMButton'}
})
export class TableLayoutComponent {
  visible: boolean=false;

  @Input() class: string='';
  @Input() caption: string='';
  @Input() columnDefs: any[];
  @Output() columnToggled = new EventEmitter();

  constructor(private eRef: ElementRef) {}
  toggle() {
    this.visible=!this.visible;
  }
  toggleColumn(columnName) {
    this.toggleVisible(columnName);
    this.columnToggled.emit(this.columnDefs);
  }
  toggleVisible(columnName) {
    for(let i=0; i<this.columnDefs.length; i++ ) {
      if(this.columnDefs[i].name==columnName)
        this.columnDefs[i].visible = !this.columnDefs[i].visible;
    }
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.visible=false;
  }
}
