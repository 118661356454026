<div class="form-group row time {{class}}">
   <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked, 'col-form-label-sm': sm}">{{labelCaption}}
      <span *ngIf="editable && required" class="asterix">*</span>
      <span *ngIf="!editable">:</span>
   </label>
   <div *ngIf="!editable" class="input-container data-value {{inputClass}}">
      <label class="col-form-label">{{getDataValue()}}</label>
   </div>
   <div *ngIf="editable" class="controls">
      <div class="input-group">
         <input *ngIf="editable" type="time" class="form-control" id="{{id}}" autocomplete="off"
           [formControl]="ngControl.control"
           [attr.maxlength]="5"
           [ngClass]="{'is-invalid': editable && ngControl.invalid && (ngControl.touched || submitted), 'form-control-sm': sm }"
           (blur)="blur($event)">
         <div class="input-group-append">
            <span class="input-group-text">{{'datetime.time-format'|translate}}</span>
         </div>
      </div>
      <ng-content></ng-content>
   </div>
</div>
