import {Component, EventEmitter, Input, Output} from '@angular/core';
import {InvoicesService} from "../../data/invoices.service";

@Component({
  selector: 'invoice-remove',
  templateUrl: './invoice-remove.component.html',
  styleUrls: ['./invoice-remove.component.scss']
})
export class InvoiceRemoveComponent {
  constructor(private InvoicesService: InvoicesService) {}

  @Input() invoice_id: number;
  @Input() invoiceNumber: string;

  @Output() onClose=new EventEmitter();
  @Output() onRemove=new EventEmitter();

  remove() {
    this.InvoicesService.remove(this.invoice_id).subscribe(
        ()=>{},(error)=>console.error(error),()=>this.onRemove.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
