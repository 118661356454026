<jbm-table [classname]="'plants'"
           [data]="items"
           [sortable]="true"
           [sortColumn]="queryHttpHelper.sortProps.name"
           [sortDirection]="queryHttpHelper.sortProps.direction"
           [hoverable]="true"
           [rowSelectable]="userRights.AllowUpdate"
           [columnDefs]="columnDefs"
           [actionDefs]="actionDefs"
           (sortChange)="sortChange($event)"
           (actionClick)="action($event)"
           (rowSelect)="rowSelect($event)">
</jbm-table>
<jbm-toasts></jbm-toasts>