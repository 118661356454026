import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InvoiceReceiptsService} from "../data/invoice-receipts.service";
import {TokenService} from "../../../services/auth/token.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'invoice-receipts',
  templateUrl: './invoice-receipts.component.html',
  styleUrls: ['./invoice-receipts.component.scss']
})
export class InvoiceReceiptsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  receipts: any[]=[];
  invoiceableReceipts: any[]=[];
  recipeData: any={ id: 0, delivery_date: '', environment: '' };

  constructor(
      private InvoiceReceiptsService: InvoiceReceiptsService,
      private TokenService: TokenService,
      private ModalService: ModalService
  ) { }

  @Input() project_id: number;
  @Input() invoice_id: number;
  @Input() editable: boolean;

  @Output() afterAdd=new EventEmitter();
  @Output() afterDelete=new EventEmitter();
  @Output() onClose=new EventEmitter();

  @ViewChild('modalRecipe') modalRecipe: ElementRef;

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    let params=new HttpParams().append('receipt','1');
    this.InvoiceReceiptsService.get(this.language, this.invoice_id, params).subscribe(
        (data)=>this.receipts=data.data,
        (error)=>console.error(error),
        ()=>
          this.InvoiceReceiptsService.getInvoiceable(this.language, this.project_id, params).subscribe(
              (data)=>this.invoiceableReceipts=data.data,
              (error)=>console.error(error)
          )
    )
  }
  add(receipt_id: number) {
    this.InvoiceReceiptsService.add(this.language, this.invoice_id, receipt_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.getData();
          this.afterAdd.emit();
        }
    )
  }
  delete(receipt_id: number) {
    this.InvoiceReceiptsService.remove(receipt_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.afterDelete.emit();
          this.getData();
        }
    )
  }

  // Recipe
  showRecipe(recipes_id: number, environment: string, delivery_date: string ) {
    this.recipeData.id=recipes_id
    this.recipeData.environment=environment;
    this.recipeData.delivery_date=delivery_date;
    this.ModalService.open(this.modalRecipe,'receipt_recipeDetails');
  }
  closeRecipe() {
    this.ModalService.close('receipt_recipeDetails');
  }
  close() {
    this.onClose.emit();
  }
}
