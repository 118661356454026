import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {CustomerService} from "../data/customer.service";
import {CustomertypesService} from "../data/customertypes.service";
import {PriceService} from "../../../services/price/price.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {RefererService} from "../../../services/storage/referer.service";
import {Customer} from "../data/interfaces/customer";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'customer-view',
  templateUrl: './customer-view.component.html',
  styleUrls: ['./customer-view.component.scss']
})

export class CustomerViewComponent implements OnInit {
  customer: Customer;
  activeTab: number=0;
  basePrice: string='';
  baseDiscount: string='';
  userRights: GroupRights;
  userRightsFinancial: GroupRights;
  userRightsQuotations: GroupRights;
  userRightsProjects: GroupRights;

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private Router: Router,
    private CustomerService: CustomerService,
    private CustomertypeService: CustomertypesService,
    private PriceService: PriceService,
    private TokenService: TokenService,
    private TranslateService: TranslateService,
    private RefererService: RefererService
  ) {
    // The route resolver has fetched the data
    this.customer=this.ActivatedRoute.snapshot.data.customer.data;
    // Userrights
    this.userRights = this.TokenService.getRightsByName('customers');
    // Userrights for the quotations
    this.userRightsQuotations = this.TokenService.getRightsByName('quotations');
    // Userrights for the financial fields
    this.userRightsFinancial = this.TokenService.getRightsByName('financial');
    // Userrights for the projects
    this.userRightsProjects = this.TokenService.getRightsByName('projects');

    // Set referers
    let caption=this.TranslateService.GetTranslation('entity.customer' )+' '+this.customer.name;
    this.RefererService.addReferer({ section: 'customers', name: 'article-pricelists', route: 'customers/details/'+this.customer.id+'/article-pricelists', caption: caption });
    this.RefererService.addReferer({ section: 'customers', name: 'concrete-pricelists', route: 'customers/details/'+this.customer.id+'/concrete-pricelists', caption: caption });
    this.RefererService.addReferer({ section: 'customers', name: 'resource-pricelists', route: 'customers/details/'+this.customer.id+'/resource-pricelists', caption: caption });
    this.RefererService.addReferer({ section: 'customers', name: 'projects', route: 'customers/details/'+this.customer.id+'/projects', caption: caption });
  }
  ngOnInit(): void {
    if(this.customer.baseprice) this.basePrice=this.PriceService.formatPrice(this.customer.baseprice);
    if(this.customer.baseprice_discount) this.baseDiscount=this.PriceService.formatPrice(this.customer.baseprice_discount);

    // Set tab according active URL
    if(this.Router.url.indexOf('quotations')>0)  this.activeTab=1;
    if(this.Router.url.indexOf('projects')>0)  this.activeTab=2;
    if(this.Router.url.indexOf('article-pricelists')>0)  this.activeTab=3;
    if(this.Router.url.indexOf('concrete-pricelists')>0)  this.activeTab=4;
    if(this.Router.url.indexOf('resource-pricelists')>0)  this.activeTab=5;

    // Refresh data after edit customer modal closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && event.url.indexOf('/customers/details')>-1) {
        this.CustomerService.get(this.customer.id,true).subscribe((data)=>{
          this.customer=data.data;
        })
      }
    });
  }
  edit() {
    this.Router.navigate([ { outlets: {'modal': ['customer', 'edit', this.customer.id]}} ] );
  }
}
