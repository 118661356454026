import {Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {HttpParams} from '@angular/common/http';
import {QuotationsService} from '../data/quotations.service';
import {QuotationStatesService} from '../data/quotation-states.service';
import {QuotationAttachmentsService} from '../data/quotation-attachments.service';
import {CustomerService} from '../../customers/data/customer.service';
import {TranslateService} from '../../../services/multilingual/translate.service';
import {TokenService} from '../../../services/auth/token.service';
import {RefererService} from '../../../services/storage/referer.service';
import {StateService} from '../../../services/storage/state.service';
import {ModalService} from '../../../components/JBM/JBMModal/service/modal.service';
import {JBMTableActionDef,JBMTableColumnAlignment,JBMTableColumnDef,JBMTableColumnType,JBMTableComponent} from '../../../components/JBM/JBMTable/JBMTable.component';
import {QueryHttpHelper, SearchColumnType} from '../../../components/JBM/Helpers/QueryHttpHelper';
import {TablelayoutService} from '../../users/data/tablelayout.service';
import {Subscription} from 'rxjs';
import {QuotationState} from '../data/interfaces/quotation-state';
import {JBMToastsService} from '../../../components/JBM/Views/JBMToasts/JBMToasts.service';
import {Quotation} from '../data/interfaces/quotation';
import {GroupRights} from '../../users/data/interfaces/grouprights';

const tableName='quotations';

@Component({
  selector: 'quotations-table',
  templateUrl: './quotations.component.html',
  styleUrls: ['./quotations.component.scss']
})
export class QuotationsComponent implements OnInit, OnDestroy {
  asModule: boolean=true;
  items: any[]=[];
  initialCount: number=null;
  userRights: GroupRights;
  quotationstates: any[]=[];
  quotationstate: QuotationState={ id: 0, description: '', color: '', text_color: '' };
  quotationstateID: number=0;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[]=[];
  language: string=this.TokenService.getLanguage();
  id: number;
  customer: any;
  quotation: Quotation;
  revision_id: number=0;
  quotationNumber: string='';

  quotations$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  filtersVisible: boolean=false;
  loading: boolean=false;

  @Input() customer_id: number=0;
  @ViewChild('table') table: JBMTableComponent;

  @ViewChild('templateCustomer') templateCustomer: TemplateRef<any>;
  @ViewChild('templateProject') templateProject: TemplateRef<any>;
  @ViewChild('templateQuotationstate') templateQuotationstate: TemplateRef<any>;
  @ViewChild('templateContacts') templateContacts: TemplateRef<any>;
  @ViewChild('templateTotal') templateTotal: TemplateRef<any>;
  @ViewChild('templateAmount') templateAmount: TemplateRef<any>;
  @ViewChild('templatePreview') templatePreview: TemplateRef<any>;
  @ViewChild('modalStatesFlow') modalStatesFlow: ElementRef;
  @ViewChild('modalStateComments') modalStateComments: ElementRef;
  @ViewChild('revisionsTemplate') revisionsTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;
  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;
  // State actions
  @ViewChild('modalApprove') modalApprove: ElementRef;
  @ViewChild('modalDraft') modalDraft: ElementRef;
  @ViewChild('modalSend') modalSend: ElementRef;
  @ViewChild('modalNegotiate') modalNegotiate: ElementRef;
  @ViewChild('modalAccept') modalAccept: ElementRef;
  @ViewChild('modalCancel') modalCancel: ElementRef;
  @ViewChild('modalReject') modalReject: ElementRef;
  @ViewChild('modalRemove') modalRemove: ElementRef;

  constructor(
      public QuotationsService: QuotationsService,
      private QuotationStatesService: QuotationStatesService,
      private QuotationAttachmentsService: QuotationAttachmentsService,
      private CustomerService: CustomerService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private RefererService: RefererService,
      private StateService: StateService,
      private Router: Router,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService,
      private TablelayoutService: TablelayoutService,
  ) {}

  ngOnInit(): void {
    // Refresh data when modal outlet closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && event.url==='/quotations')
        this.getData();
    });

    this.userRights = this.TokenService.getRightsByName('quotations');

    if(this.customer_id==undefined) this.customer_id=0;
    if(this.customer_id>0) this.asModule=false;

    if(this.asModule)
      // Remove all active quotation-related referers
      this.RefererService.clearSection('customers');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('quotation_date','qr.quotation_date');
    this.queryHttpHelper.addAliasedColumn('due_date','qr.due_date');
    this.queryHttpHelper.addAliasedColumn('quotation_number','q.quotation_number');
    this.queryHttpHelper.addAliasedColumn('customer','c.name');
    this.queryHttpHelper.addAliasedColumn('project','p.name');
    this.queryHttpHelper.setSortColumn('quotation_date', true);
    this.queryHttpHelper.addSearchColumn('quotation_number', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('customer', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('project', SearchColumnType.string);

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id, tag: 'id' },
      { name: 'quotation_number', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('quotation.number-short'), visible: true,
        hideable: false, sortable: true, filterable: true, width: 7 },
      { name: 'customer', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.customer'), visible: true,
        hideable: false, sortable: true, filterable: true },
      { name: 'project', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.project'), visible: true,
        hideable: true, sortable: true, filterable: true, width: 16 },
      { name: 'contacts', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('entity.contacts'),
        hideable: true, width: 4, className: 'pr-2' },
      { name: 'quotation_states_id', header: this.TranslateService.GetTranslation('quotation.state'), visible: true, width: 7.5,
        hideable: false, sortable: true, className: 'opacity-7 pr-2' },
      { name: 'quotation_date', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('quotation.date'),
        hideable: true, sortable: true, width: 6 },
      { name: 'due_date', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('entity.due-date'),
        hideable: true, sortable: true, width: 6 },
      { name: 'totalprice', type: JBMTableColumnType.price, visible: true, header: this.TranslateService.GetTranslation('ui.totalprice'),
        hideable: true, sortable: true, width: 5.5 },
      { name: 'concrete_amount', type: JBMTableColumnType.number, visible: true, header: this.TranslateService.GetTranslation('order.amount'),
        hideable: true, width: 5.5 },
      { name: 'revisions', type: JBMTableColumnType.panelToggle, width: 6, header: this.TranslateService.GetTranslation('quotation.revisions'),
        hideable: false },
      { name: 'preview', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('ui.preview'),
        hideable: false, sortable: false, width: 4 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, hideable: false, width: 3.5 }
    ];

    if(this.asModule)
      this.TablelayoutService.setLayout(tableName, this.columnDefs);

    this.QuotationStatesService.get(this.language).subscribe(
      (data: any)=> this.quotationstates=data,()=>{},()=> {
        this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
        this.getData(true);
      }
    )
  }
  getData(countRows:boolean=false) {
    this.loading=true;

    this.columnDefs[2].template=this.templateCustomer;
    this.columnDefs[3].template=this.templateProject;
    this.columnDefs[4].template=this.templateContacts;
    this.columnDefs[5].template=this.templateQuotationstate;
    this.columnDefs[9].template=this.templateAmount;
    this.columnDefs[10].template=this.revisionsTemplate;
    this.columnDefs[11].template=this.templatePreview;
    this.columnDefs[12].template=this.actionsTemplate;

    let params=this.queryHttpHelper.getHttpParams(false);
    // Include quotation state description and action permissions
    params=params.append('state','1');
    params=params.append('permissions','1');
    params=this.setFilters(params);

    this.quotations$=this.QuotationsService.get(this.language, params).subscribe(
        (data: any)=>this.items=data.data,
        (error)=>console.error(error),
        ()=>{
          this.loading=false;
          this.formatData();
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            params=this.setFilters(params);
            this.count$=this.QuotationsService.get(this.language, params).subscribe(
                (data: any) => {
                  this.queryHttpHelper.paginationProps.itemCount=data.data;
                  if(this.initialCount===null) this.initialCount=data.data
                },
                (error)=> console.error(error),()=>{} );
          }
        } );
  }
  setFilters(params: HttpParams): HttpParams {
    if(this.customer_id)
      params=params.append('customer_id', this.customer_id.toString());
    if(this.quotationstateID) {
      this.quotationstate=this.QuotationStatesService.getByID(this.quotationstates, this.quotationstateID);
      // Filter quotation state
      params=params.append('quotation_states_id', this.quotationstateID.toString());
    }

    return params;
  }
  formatData() {
    for(let item of this.items) {
      // Set quotation state context colors
      item.color='';
      item.text_color='';
      let state=this.QuotationStatesService.getByID(this.quotationstates, item.quotation_states_id);
      if(state) {
        item.color=state.color;
        item.text_color=state.text_color;
      }
      item.isExpandable=item.revision_count;
    }
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  setQuotationstate(id: number) {
    this.quotationstateID=id;
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    if(event.name==='customer') event.name='customers_id';
    if(event.name==='project') event.name='projects_id';
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  saveLayout() {
    this.TablelayoutService.saveLayout(tableName, this.columnDefs);
  }

  // Item actions
  rowSelect(row) {
    this.Router.navigateByUrl('quotations/'+row.revision_id);
  }
  toggleRevisions(row) {
    this.table.toggleRowExpanded(row);
  }

  // Customer
  showCustomer(customer_id: number) {
    this.CustomerService.get(customer_id,true).subscribe(
      (data)=>this.customer=data.data,
      (error)=>console.error(error),
      ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }

  // Project
  showProject(project_id: number) {
    this.id=project_id;
    this.ModalService.open(this.modalProjectDetails,'projectDetails')
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }

  // Create quotation
  create() {
    this.StateService.addElement('forQuotation',true);
    this.Router.navigate([ { outlets: {'modal': ['project', 'create']}} ] );
  }

  // Quotation state actions
  approve(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalApprove,'stateAction');
  }
  approveSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.approved'));
    this.actionSuccess();
  }
  draft(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalDraft,'stateAction');
  }
  draftSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.made-draft'));
    this.actionSuccess();
  }
  send(data) {
    this.quotation=data;
    this.QuotationAttachmentsService.check(this.quotation.id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.ModalService.open(this.modalSend,'quotationSend');
        }
    );
  }
  sendSuccess() {
    this.sendClose();
    this.JBMToastsService.success(this.TranslateService.GetTranslation('entity.quotation') + ' '+ this.TranslateService.GetTranslation('entity.sent').toLowerCase());
    this.getData();
  }
  sendClose() {
    this.ModalService.close('quotationSend');
  }
  negotiate(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalNegotiate,'stateAction');
  }
  negotiateSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.set-negotiation'));
    this.actionSuccess();
  }
  accept(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalAccept,'stateAction');
  }
  acceptSuccess() {
    this.sendClose();
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.accepted'));
    this.getData();
  }
  cancel(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalCancel,'stateAction');
  }
  cancelSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.cancelled'));
    this.actionSuccess();
  }
  reject(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalReject,'stateAction');
  }
  rejectSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('entity.quotation') + ' '+ this.TranslateService.GetTranslation('ui.rejected').toLowerCase());
    this.actionSuccess();
  }
  remove(data) {
    this.initialCount=null;
    this.quotation=data;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalRemove,'stateAction');
  }
  removeSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
    this.stateActionClose();
    this.getData(true);
  }
  stateActionClose() {
    this.ModalService.close('stateAction');
  }
  actionSuccess() {
    this.stateActionClose();
    this.getData();
  }
  showStateFlow(data) {
    this.revision_id=data.revision_id;
    this.quotationNumber=data.quotation_number;
    this.ModalService.open(this.modalStatesFlow,'statesFlow');
  }
  statesFlowClose() {
    this.ModalService.close('statesFlow');
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.quotations$!=undefined) this.quotations$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
