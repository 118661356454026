import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'presentations/';
const segment: string = 'mail-default-attachments/';

@Injectable({
  providedIn: 'root'
})
export class MailDefaultAttachmentsService {

  constructor(
      private HttpService: HttpService,
  ) {}

  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  delete(mail_default_attachment_id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+mail_default_attachment_id.toString());
  }
}
