<form [formGroup]="form" (ngSubmit)="save()" class="usergroup">
   <div class="jbm-modal-header">
      <jbm-crud-title subject="production.truck" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-help-button [helpTopicID]="0" [class]="'light'"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-select [formControl]="formControls.plants_id" [options]="plantOptions" [submitted]="submitted" class="lg"
        [required]="true" [id]="'plants_id'" [insertLeadingOption]="true" [leadingOptionText]="''"
        labelCaption="{{'company.plant'|translate}}" labelClass="md">
      </jbm-select>
      <jbm-text-input [id]="'number'" [formControl]="formControls['number']"
        [required]="true" [submitted]="submitted"
        [labelCaption]="'ui.number'|translate"
        [labelClass]="'md'" [class]="'xs mt-4'">
         <jbm-input-error [formControl]="formControls['number']" [submitted]="submitted">
            <span *ngIf="formControls['number'].hasError('notUnique')">{{'entity.number-not-unique'|translate}}</span>
            <span *ngIf="formControls['number'].hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [id]="'numberplate'" [formControl]="formControls['numberplate']"
        [required]="true" [submitted]="submitted"
        [labelCaption]="'truck.numberplate'|translate"
        [labelClass]="'md'" [class]="'xs'">
         <jbm-input-error [formControl]="formControls['numberplate']" [submitted]="submitted">
            <span *ngIf="formControls['numberplate'].hasError('notUnique')">{{'truck.numberplate-not-unique'|translate}}</span>
            <span *ngIf="formControls['numberplate'].hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-float-input-group [formControl]="formControls.capacity" [submitted]="submitted"
        [id]="'capacity'" required="true" [inputClass]="'xxs'" append="{{'quantity.m3'|translate}}"
        labelCaption="{{'production.capacity'|translate}}" [labelClass]="'md'" decimals="2" [decimalsOptional]="true"
        [negativeAllowed]="false">
         <jbm-input-error [formControl]="formControls.capacity" [submitted]="submitted">
            {{'entity.concrete-amount-format'|translate}}
         </jbm-input-error>
      </jbm-float-input-group>
      <jbm-check [formControl]="formControls.active" [id]="'active'" labelCaption="{{'entity.active'|translate}}"
        labelClass="md" inputClass="sm" textChecked="{{'ui.yes'|translate}}" textUnchecked="{{'ui.no'|translate}}">
      </jbm-check>

      <driver-selector labelCaption="{{'production.driver'|translate}}" labelClass="md" class="lg mt-4"
        [driver_id]="truck.drivers_id" [template]="driverselect" (driverSelected)="selectDriver($event)">
          <ng-template #driverselect let-driver="driver">
             {{driver.name}}
          </ng-template>
      </driver-selector>

   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'production.truck'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
