import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[digits-only-input]'
})
export class DigitsOnlyInputDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue = this._el.nativeElement.value;
    if(this._el.nativeElement.classList.contains('negative'))
      this._el.nativeElement.value = initalValue.replace(/[^0-9-]*/g, '');
    else
      this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
