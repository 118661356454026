import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'resources/';

@Injectable({
  providedIn: 'root'
})
export class RecipeResourcesService {
  constructor(private HttpService: HttpService) {}

  get(recipe_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/recipe/'+recipe_id.toString(), params );
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/recipe', data);
  }
}
