import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {PriceUsagesService} from "../../../../../features/financial/data/price-usages.service";
import {PriceRulesTranslationKeys} from '../helpers/PriceRulesTranslationKeys';
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'jbm-resource-pricerules-breakdown-popover',
  templateUrl: './JBMResourcePriceRulesBreakdownPopover.component.html',
  styleUrls: ['./JBMResourcePriceRulesBreakdownPopover.component.scss']
})
export class JBMResourcePriceRulesBreakdownPopoverComponent implements OnInit {
  price: number=0;
  PriceRulesTranslationKeys: PriceRulesTranslationKeys;
  pricerules: any[]=[];

  @ViewChild('p') popover: NgbPopover;

  @Input() subject: number;
  @Input() subject_id: number;
  @Input() class: string;

  constructor(private PriceUsagesService: PriceUsagesService) {
    this.PriceRulesTranslationKeys=new PriceRulesTranslationKeys();
  }

  ngOnInit(): void {}
  toggle() {
    if( this.popover.isOpen() ) {
      this.popover.toggle();
      return;
    }
    if( !this.popover.isOpen() && this.pricerules.length > 0 ) {
      this.popover.toggle();
      return;
    }
    this.PriceUsagesService.get(this.subject, this.subject_id).subscribe(
        (data)=>{
          this.price=data.data.price;
          this.pricerules=data.data.pricerules
         },
        (error)=>console.error(error),
        ()=> {
          this.pricerules=this.PriceRulesTranslationKeys.setKeys(this.pricerules,false);
          this.toggle();
        }
    )
  }
}
