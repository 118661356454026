<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.project'|translate}} {{'ui.details'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <div class="row">
      <div class="col-md-6 mr-4">
         <div class="jbm-properties-list project-details">
            <div class="item">
               <label class="label">{{'entity.name'|translate}}:</label>
               <label class="value">{{project.name}}</label>
            </div>
            <div *ngIf="project.description" class="item">
               <label class="label">{{'entity.description'|translate}}:</label>
               <label class="value">{{project.description}}</label>
            </div>
            <div class="item">
               <label class="label">{{'project.state'|translate}}</label>
               <label class="value">{{project.projectState.description}}</label>
            </div>
            <div class="item">
               <label class="label">{{'company.plant'|translate}}:</label>
               <label *ngIf="!project.plant" class="value">
                  <span [ngbTooltip]="'ui.inapplicable-info'|translate"><span class="hover-tooltip">{{'ui.inapplicable'|translate}}</span></span>
               </label>
               <label *ngIf="project.plant" class="value">{{project.plant.name}}</label>
            </div>
            <div *ngIf="project.article_pricelists_id" class="item mt-3">
               <label class="label">{{'financial.article-pricelist'|translate}}:</label>
               <label class="value">{{project.articlePricelist.description}}</label>
            </div>
            <div *ngIf="project.concrete_pricelists_id" class="item" [ngClass]="{'mt-3': !project.article_pricelists_id}">
               <label class="label">{{'financial.concrete-pricelist'|translate}}:</label>
               <label class="value">{{project.concretePricelist.description}}</label>
            </div>
            <div *ngIf="project.resource_pricelists_id" class="item" [ngClass]="{'mt-3': !project.concrete_pricelists_id}">
               <label class="label">{{'financial.resource-pricelist'|translate}}:</label>
               <label class="value">{{project.resourcePricelist.description}}</label>
            </div>
            <div *ngIf="project.comments" class="item mt-3">
               <label class="label">{{'entity.comments'|translate}}:</label>
               <label class="value"><jbm-comments-dropdown [comments]="project.comments"></jbm-comments-dropdown></label>
            </div>
            <h6 class="mt-3">{{'entity.customer'|translate}}</h6>
            <div class="item">
               <label class="label">{{'entity.name'|translate}}:</label>
               <label *ngIf="project.customer" class="value">{{project.customer.name}}</label>
               <label *ngIf="!project.customer" class="value">
                  <span [ngbTooltip]="'ui.inapplicable-info'|translate"><span class="hover-tooltip">{{'ui.inapplicable'|translate}}</span></span>
               </label>
            </div>
            <div *ngIf="project.customer" class="item">
               <label class="label">{{'entity.blocked'|translate}}:</label>
               <label *ngIf="project.customer.blocked" class="value"><span class="badge bg-danger">{{'ui.yes'|translate}}</span></label>
               <label *ngIf="!project.customer.blocked" class="value"><span class="badge bg-success">{{'ui.no'|translate}}</span></label>
            </div>
            <div *ngIf="project.customer && customerRights.AllowRead" class="item mt-2">
               <label class="label"></label>
               <label class="value">
                  <button type="button" class="btn btn-sm btn-outline-secondary" (click)="customerDetails()">
                     <i class="fa fa-info"></i> {{'customer'|translate}}{{'ui.details'|translate|lowercase}}
                  </button>
               </label>
            </div>
         </div>
      </div>
      <div class="col-md-6 mr-4">
         <div *ngIf="project.address_details_id" class="mb-3">
            <h6>{{'project.work-location'|translate}}</h6>
            <div class="jbm-properties-list work-location">
               <div class="item">
                  <label class="label">{{'entity.streetname-number'|translate}}:</label>
                  <label class="value">{{project.address.street}}&nbsp;{{project.address.no}}</label>
               </div>
               <div class="item">
                  <label class="label">{{'entity.postalcode'|translate}} {{'ui.and'|translate}} {{'entity.cityname'|translate|lowercase}}:</label>
                  <label class="value">{{project.address.postcode}}&nbsp;&nbsp;{{project.address.city}}</label>
               </div>
               <div class="item mt-1">
                  <label class="label">&nbsp;</label>
                  <label class="value">
                     <jbm-gps-location-button *ngIf="GPS_address.latitude"
                       [latitude]="GPS_address.latitude"
                       [longitude]="GPS_address.longitude"
                       [title]="'project.work-location'|translate"
                       [address]="project.address.street+'&nbsp;'+project.address.no+', '+project.address.city">
                     </jbm-gps-location-button>
                  </label>
               </div>
               <div *ngIf="data.distance" class="item mt-2">
                  <label class="label">{{'geo.distance'|translate}}:</label>
                  <label class="value">{{data.distance}}</label>
               </div>
               <div *ngIf="data.traveltime" class="item">
                  <label class="label">{{'geo.traveltime'|translate}}:</label>
                  <label class="value">{{data.traveltime}}</label>
               </div>
            </div>
         </div>
         <h6 class="">{{'menu.invoicing'|translate}}</h6>
         <div class="jbm-properties-list work-location">
            <div class="item">
               <label class="label">{{'invoicing.on-resources-based'|translate}} {{'invoice.invoicing'|translate|lowercase}}:</label>
               <label *ngIf="resources_based_invoicing" class="value">{{'ui.yes'|translate}}</label>
               <label *ngIf="!resources_based_invoicing" class="value">{{'ui.no'|translate}}</label>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button [visible]="true" (click)="close()"></jbm-close-button>
   </div>
</div>
<jbm-modal [id]="'customerModal'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomer let-modal>
   <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
