import {Component, Input, Self, OnInit} from '@angular/core';
import { NgControl} from "@angular/forms";

@Component({ selector: '', template: '', styleUrls: [] })

export class FormGroupBaseComponent implements OnInit {

  @Input() id: string='';
  @Input() class: string='';
  @Input() editable: boolean=true;
  @Input() required: boolean=false;
  @Input() submitted: boolean=false;
  @Input() valueIfEmpty: string='-';
  @Input() labelCaption: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() inputClass: string='';
  @Input() tabIndex: number=null;
  @Input() sm: boolean=false; // Condensed height

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }
  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.sm==undefined) this.sm=false;
    if(this.editable==undefined) this.editable=true;
    if(this.submitted==undefined) this.submitted=false;
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.tabIndex==undefined) this.tabIndex=null;
  }
  public getDataValue() {
    return !this.ngControl.control.value ? this.valueIfEmpty : this.ngControl.control.value;
  }

  //ControlValueAccessor interface
  writeValue(obj: any) {  }
  registerOnChange(fn: any) { }
  registerOnTouched(fn: any) { }
  setDisabledState?(isDisabled: boolean) { }
}
