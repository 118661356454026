import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from "@angular/forms";
import {TextPlaceholdersService} from "../../data/text_placeholders.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TextPlaceholder} from "../../data/interfaces/text_placeholder";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'form-text-placeholder',
  templateUrl: './text-placeholder.component.html',
  styleUrls: ['./text-placeholder.component.scss']
})
export class TextPlaceholderComponent implements OnInit {
  textPlaceholder: TextPlaceholder;
  form: FormGroup;
  action: CRUDAction;
  isSubmitted: boolean=false;

  @Input() id: number;
  @Input() language: string;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
      private TextPlaceholdersService: TextPlaceholdersService,
      private ModalService: ModalService,
      private TranslateService: TranslateService,
      private formBuilder: FormBuilder
  ) {
    this.textPlaceholder=this.TextPlaceholdersService.getEmpty();
    this.addControls();
  }
  ngOnInit() {
    this.setControlValues();
    if(this.id) {
      this.TextPlaceholdersService.getOne(this.language, this.id).subscribe(
          (data)=>this.textPlaceholder=data.data as TextPlaceholder,
          (error)=>console.error(error),
          ()=> {
            this.setControlValues();
            document.getElementById('name').focus();
          }
      );
    }
  }
  ngOnChanges() {
    if(this.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          name: new FormControl('',[Validators.required] ),
          description: new FormControl('',[Validators.required]),
          text: new FormControl('',[Validators.required] ),
        }, { updateOn: 'blur' }
    );
  }
  setControlValues() {
    this.form.controls.name.setValue(this.textPlaceholder.name);
    this.form.controls.description.setValue(this.textPlaceholder.description);
    this.form.controls.text.setValue(this.textPlaceholder.text);
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    // Store text placeholder
    this.textPlaceholder.name=this.formControls.name.value;
    this.textPlaceholder.language=this.language;
    this.textPlaceholder.description=this.formControls.description.value;
    this.textPlaceholder.text=this.formControls.text.value;

    if(this.textPlaceholder.id===0) {
      this.TextPlaceholdersService.create(this.textPlaceholder).subscribe(
          ()=>{},error=>this.handleError(error),
          ()=>this.onSave.emit()
      )
    } else {
      this.TextPlaceholdersService.update(this.textPlaceholder).subscribe(
          ()=>{},error=>this.handleError(error),
          ()=>this.onSave.emit()
      )
    }
  }
  afterSave() {
    this.onSave.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
  handleError(error) {
    if(error=='name_not_unique') {
      this.formControls.name.setErrors({notUnique: true});
      document.getElementById('name').focus();
    }
  }
}