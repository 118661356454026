import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {InvoiceValidationsService} from "../../data/invoice-validations.service";
import {InvoiceValidation} from "../../data/interfaces/invoice-validation";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";

@Component({
  selector: 'invoice-validation-form',
  templateUrl: './invoice-validation-form.component.html',
  styleUrls: ['./invoice-validation-form.component.scss']
})
export class InvoiceValidationFormComponent implements OnInit {
  form: FormGroup;
  validations: InvoiceValidation[]=[];
  validation: InvoiceValidation=this.InvoiceValidationsService.getEmpty();
  user_id: number=this.TokenService.getUserId();
  approved: boolean=false;

  constructor(
      private formBuilder: FormBuilder,
      private InvoiceValidationsService: InvoiceValidationsService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService,
      private TranslateService: TranslateService
  ) {
    this.validation.users_id=this.user_id;
  }

  @Input() invoice_id: number=0;
  @Output() validated=new EventEmitter();

  ngOnInit(): void {
    this.form = this.formBuilder.group({comments: new FormControl('' )} );

    this.InvoiceValidationsService.get(this.invoice_id).subscribe(
        (data)=>this.validations=data,
        (error)=>console.error(error),
        ()=> {
          for(let validation of this.validations)
            // Get the validation of the current user
            if(validation.users_id===this.user_id) {
              this.validation=validation;
              this.formControls.comments.setValue(this.validation.comments);
            }
        }
    )
  }
  get formControls() {
    return this.form.controls;
  }
  approve() {
    this.approved=true;
    this.save();
  }
  disapprove() {
    this.approved=false;
    this.save();
  }
  save() {
    let data={
      invoices_id: this.invoice_id,
      users_id: this.user_id,
      approved: this.approved,
      comments: this.formControls.comments.value }
    this.InvoiceValidationsService.create(data).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
          this.validated.emit();
        }
    )
  }
}
