import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import { JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import { JBMMediaModule} from "../../components/JBM/Media/jbmmedia.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMPopoversModule} from "../../components/JBM/Popovers/jbmpopovers.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import { SelectorsModule} from "../../components/domain/selectors/selectors.module";
import { DomainDropdownsModule} from "../../components/domain/dropdowns/domain-dropdowns.module";
import { PresentationsModule} from "../presentations/presentations.module";
import { ContactsModule} from "../contacts/contacts.module";
import { SharedCustomersModule} from "../../shared/customers/shared.customers.module";
import { SharedProjectsModule} from "../../shared/projects/shared.projects.module";
import { QuotationsRoutingModule } from './quotations-routing.module';
import { SharedRecipesModule} from "../../shared/recipes/shared.recipes.module";
import { QuotationsComponent} from "./datatable/quotations.component";
import { QuotationStatesFlowComponent} from "./states-flow/quotation-states-flow.component";
import { QuotationItemsComponent} from "./items/quotation-items.component";
import { QuotationViewComponent} from "./quotation-view/quotation-view.component";
import { QuotationToolbarComponent} from "./quotation-view/toolbar/quotation-toolbar.component";
import { QuotationRevisionsComponent } from './quotation-revisions/quotation-revisions.component';
import { QuotationDetailsComponent} from "./details/quotation-details.component";
import { CustomerCommentsComponent} from "./details/customer-comments/customer-comments.component";
import { QuotationPreviewComponent} from "./preview/quotation-preview.component";
import { QuotationDraftComponent} from "./state-actions/draft/quotation-draft.component";
import { QuotationApproveComponent} from "./state-actions/approve/quotation-approve.component";
import { QuotationNegotiateComponent} from "./state-actions/negotiate/quotation-negotiate.component";
import { QuotationSendComponent} from "./state-actions/send/quotation-send.component";
import { QuotationAcceptComponent} from "./state-actions/accept/quotation-accept.component";
import { QuotationCancelComponent} from "./state-actions/cancel/quotation-cancel.component";
import { QuotationRejectComponent} from "./state-actions/reject/quotation-reject.component";
import { QuotationRemoveComponent} from "./state-actions/remove/quotation-remove.component";
import { FormQuotationRevisionComponent } from './quotation-revisions/form/form-quotation-revision.component';
import { QuotationContactsComponent } from './quotation-contacts/quotation-contacts.component';
import { FormQuotationItemComponent } from './items/form/form-quotation-item/form-quotation-item.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [QuotationsComponent, QuotationStatesFlowComponent, QuotationItemsComponent, QuotationViewComponent,
    QuotationToolbarComponent, QuotationRevisionsComponent, QuotationDetailsComponent, CustomerCommentsComponent,
    QuotationPreviewComponent, QuotationDraftComponent, QuotationApproveComponent, QuotationNegotiateComponent,
    QuotationSendComponent, QuotationAcceptComponent, QuotationCancelComponent, QuotationCancelComponent,
    QuotationRejectComponent, QuotationRemoveComponent, FormQuotationRevisionComponent, QuotationContactsComponent, FormQuotationItemComponent],
    imports: [CommonModule, SharedModule, QuotationsRoutingModule, PresentationsModule, JBMButtonsModule,
        JBMDropdownsModule, JBMFormModule, JBMModalModule, JBMMediaModule, JBMPopoversModule, JBMTableModule, JBMViewModule,
        SelectorsModule, DomainDropdownsModule, SharedCustomersModule, SharedProjectsModule, SharedRecipesModule, ContactsModule, LayoutModule],
  exports: [QuotationsComponent]
})
export class QuotationsModule { }
