import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'invoicing/';
const segment: string = 'concretes/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceConcretesService {

  constructor(private HttpService: HttpService) {}

  get(language: string, invoice_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+invoice_id.toString(), params );
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  updateExternalOrderComment(invoice_concrete_id: number, comment: string) {
    if(comment==='') comment=null;
    let data={ id: invoice_concrete_id, comment: comment }
    return this.HttpService.post(baseURL+'update/'+segment+'external-order-comment', data);
  }
  add(language: string, invoice_id: number, order_id: number) {
    return this.HttpService.get(baseURL+'create/'+segment+language+'/'+invoice_id.toString()+'/'+order_id.toString());
  }
  remove(id: number) {
    return this.HttpService.delete(baseURL+'delete/'+segment+id.toString());
  }
}
