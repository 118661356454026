import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'trucks-and-drivers',
  templateUrl: './trucks-and-drivers.component.html',
  styleUrls: ['./trucks-and-drivers.component.scss']
})
export class TrucksAndDriversComponent {
  activeTab: number;
  driverCount: number=0;
  truckCount: number=0;

  constructor(private Router: Router) {
    // Set tab according active URL
    if(Router.url.indexOf('drivers')>0) this.activeTab=0;
    if(Router.url.indexOf('trucks')>0) this.activeTab=1;
  }
  updateDriverCount(count) {
    this.driverCount=count;
  }
  updateTruckCount(count) {
    this.truckCount=count;
  }
}
