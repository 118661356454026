import {Component, ElementRef, Input, OnInit, OnDestroy, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../data/user.service";
import {UsergroupService} from '../data/usergroup.service';
import {LanguagesService} from "../../../services/multilingual/languages.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {JBMTableColumnType} from "../../../components/JBM/JBMTable/JBMTable.component";
import {JBMToastsService } from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {StateService} from "../../../services/storage/state.service";
import {Subscription} from "rxjs";
import {User} from "../data/interfaces/user";
import {GroupRights} from '../data/interfaces/grouprights';

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {
  appID: number=0;  // Management
  userRights: GroupRights;
  users: any[];
  user: User=this.UserService.getEmpty();
  userid: number;

  usergroups: any[];
  usergroups$: Subscription;
  usergroupId: number=0;

  users$: Subscription;
  count$: Subscription;

  @ViewChild('userModal') modalTemplate: ElementRef;
  @ViewChild('modalMailsettings') modalMailsettings: ElementRef;

  columnTypes = {
    name: JBMTableColumnType.string,
    full_name: JBMTableColumnType.string,
    language_choice: JBMTableColumnType.string,
    active: JBMTableColumnType.boolean
  }

  queryHttpHelper: QueryHttpHelper;
  showFilter: boolean=false;
  searchboxVisible: boolean=true;
  languages: any[];
  activestates: any[];

  constructor(
      private Router: Router,
      private UserService: UserService,
      private UsergroupService: UsergroupService,
      private LanguagesService: LanguagesService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private StateService: StateService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) {}
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('users');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('username');
    this.queryHttpHelper.addSearchColumn('username', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('full_name', SearchColumnType.string);

    // Set filter options
    this.languages=this.LanguagesService.GetLanguages();
    this.activestates=[
        { key: '1', value: this.TranslateService.GetTranslation('entity.active') },
        { key: '0', value: this.TranslateService.GetTranslation('entity.inactive')}
    ];

    this.usergroups$=this.UsergroupService.getData(this.appID).subscribe(
        (data: any) => {
          this.usergroups=data.data;
          this.getData(true);
        },(error)=>console.error(error)
    );
  }
  getData(countRows:boolean=false) {
    let params=this.getParams(false);
    params=params.append('appID', this.appID.toString());
    this.users$=this.UserService.getData(params).subscribe(
        (data: any) => this.users = data.data,(error)=>console.error(error),()=>{

          for(let user of this.users) {
            // Set language as texts instead of codes
            user.language = this.LanguagesService.GetLanguage(user.language_choice);

            // Format usergroups
            if(!this.usergroupId) {
              let usergroups = user.usergroups.join(', ');
              if(user.usergroups.count>1)
                usergroups = usergroups.substring(0, usergroups.length - 2);
            }
          }

          if(countRows) {
            let params=this.getParams(true);
            this.count$=this.UserService.getData(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,(error)=>console.error(error));
          }
        });
  }
  getParams(countRows:boolean) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    if(this.usergroupId)
      params=params.append('usergroup_id',this.usergroupId.toString());
    return params;
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  appChange(event) {
    this.appID=parseInt(event.target.value);
    this.usergroups$=this.UsergroupService.getData(this.appID).subscribe(
        (data: any) => {
          this.usergroups=data.data;
          this.usergroupId=this.usergroups.length ? this.usergroups[0].id : 0;
          this.getData(true);
        },(error)=>console.error(error)
    );
  }
  usergroupChange(event) {
    this.usergroupId=parseInt(event.target.value);
    this.getData(true);
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  filterToggle(event) {
    this.showFilter=!this.showFilter;
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  addUser() {
    this.user=this.UserService.getEmpty();
    this.ModalService.open(this.modalTemplate,'user');
  }
  userSaved() {
    this.ModalService.close('user');
    this.getData(true);
  }
  userCanceled() {
    this.ModalService.close('user');
  }
  editUser(user) {
    if(!this.userRights.AllowUpdate) return;
    this.UserService.getOne(user.id).subscribe(
        (data)=>this.user=data.data as User,(error)=>console.error(error),()=>{
          this.ModalService.open(this.modalTemplate,'user');
        }
    )
  }
  editMailSettings(user_id: number) {
    this.userid=user_id;
    this.ModalService.open(this.modalMailsettings,'mailsettings');
  }
  mailSettingsSaved() {
    this.ModalService.close('mailsettings');
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  mailSettingsCanceled() {
    this.ModalService.close('mailsettings');
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Refresh view
    this.getData(true);
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.users$!=undefined) this.users$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
