import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'jbm-table-header',
  templateUrl: './JBMTableHeader.component.html',
  styleUrls: ['./JBMTableHeader.component.scss']
})
export class JBMTableHeaderComponent implements OnInit {
  @Input() className: string;

  constructor() { }

  ngOnInit(): void {
    if(this.className==undefined) this.className='';
  }

}
