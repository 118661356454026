<app-header [module_header]="asModule">
    <div class="float-start" [ngClass]="{'toolbar': !asModule }" [class.float-start]="asModule" >
        <jbm-view-title *ngIf="asModule" [caption]="caption"
          [itemcount]="queryHttpHelper.paginationProps.itemCount"
          [parentLinkCaption]="'menu.settings'|translate"
          [parentLinkSlug]="'settings'">
        </jbm-view-title>
        <jbm-view-title *ngIf="!asModule" [caption]="caption"
          [itemcount]="queryHttpHelper.paginationProps.itemCount">
        </jbm-view-title>
        <jbm-create-button [visible]="userRights.AllowCreate" (click)="addItem()"></jbm-create-button>
    </div>
    <div class="toolbar float-start ml-4">
        <label for="view" class="label">{{'ui.show'|translate}}</label>
        <select id="view" class="form-control form-select" (change)="viewChange($event)">
            <option value="0" [selected]="view==0">{{'ui.all'|translate}} {{'financial.pricelists'|translate|lowercase}}</option>
            <option value="1" [selected]="view==1">{{'ui.actual-adjective'|translate}} {{'financial.pricelists'|translate|lowercase}}</option>
            <option value="2" [selected]="view==2">{{'ui.not'|translate}} {{'ui.actual-adjective'|translate|lowercase}} {{'financial.pricelists'|translate|lowercase}}</option>
        </select>
    </div>
    <div class="toolbar float-end">
        <jbm-help-button *ngIf="asModule" [helpTopicID]="0"></jbm-help-button>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination
          [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox
          [id]="'search-box'"
          [visible]="searchboxVisible && !filtersVisible"
          [search]="search"
          (searchChange)="searchChange($event)">
        </jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-filter-toggle
          [showFilter]="filtersVisible"
          (onToggle)="toggleFilters($event)">
        </jbm-filter-toggle>
        <div *ngIf="asModule" class="toolbar-spacer-1 float-end"></div>
    </div>
</app-header>
<section [ngClass]="{'module': asModule}">
    <jbm-table #table
       [absolute]="asModule"
       [classname]="tableClassname"
       [data]="items"
       [filterable]="true"
       [showFilters]="filtersVisible"
       [sortable]="true"
       [sortColumn]="queryHttpHelper.sortProps.name"
       [sortDirection]="queryHttpHelper.sortProps.direction"
       [hoverable]="true"
       [rowSelectable]="userRights.AllowRead"
       [columnDefs]="columnDefs"
       (rowCountChange)="rowCountChange($event)"
       (sortChange)="sortChange($event)"
       (filterChange)="filterChange($event)"
       (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>
<ng-template #usesTemplate let-data="data">
    <button [hidden]="data.deletePermission || data.type==0" class="btn btn-light btn-sm float-start" (click)="showUses(data.id); $event.stopPropagation()">
        <i class="fa fa-info"></i> {{'ui.uses'|translate}}
    </button>
</ng-template>
<ng-template #actionsTemplate let-data="data">
    <div class="float-end action-delete-container">
      <jbm-confirm-delete-dropdown *ngIf="userRights.AllowDelete && data.deletePermission" class="float-end"
         [placement]="'left-top'"
         [subject]="pricelistsType==0 ? ('financial.article-pricelist'|translate) : ('financial.concrete-pricelist'|translate)"
         (onConfirm)="delete(data.id)">
      </jbm-confirm-delete-dropdown>
      <span *ngIf="userRights.AllowDelete && !data.deletePermission">&nbsp;</span>
    </div>
    <div *ngIf="userRights.AllowUpdate" ngbDropdown container="body" class="float-end">
        <button class="actions btn btn-sm btn-light" id="articleListActions" ngbDropdownToggle (click)="$event.stopPropagation();">
            <i class="fa fa-ellipsis-h"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="articleListActions">
            <button class="dropdown-item" [ngbTooltip]="('financial.concrete-pricelist'|translate)+' '+('ui.copy'|translate|lowercase)+'...'"
              (click)="copy(data.id); $event.stopPropagation()">{{'ui.copy'|translate}}...
            </button>
            <div [hidden]="data.type==0" class="dropdown-divider"></div>
            <button [hidden]="data.type==0" class="dropdown-item" (click)="connectToCustomers(data.id); $event.stopPropagation()">
                {{'ui.connect-to'|translate}} {{'entity.customers'|translate|lowercase}}...
            </button>
            <button [hidden]="data.type==0" class="dropdown-item" (click)="assignToProjects(data.id); $event.stopPropagation()">
                {{'ui.connect-to'|translate}} {{'entity.projects'|translate|lowercase}}...
            </button>
        </div>
    </div>
    <div class="float-end">
        <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-primary mr-2" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
          (click)="edit(data.id); $event.stopPropagation()"><i class="far fa-edit"></i>
        </button>
    </div>
</ng-template>
<jbm-modal [id]="pricelistsType==0 ? 'article-pricelist' : 'concrete-pricelist'" [className]="'pricelist'"></jbm-modal>
<ng-template #pricelistModal let-modal>
    <form-pricelist [pricelistsType]="pricelistsType" [pricelist]="pricelist" [customer_id]="customer_id" (onSave)="pricelistSaved()" (onCancel)="pricelistCancelled()"></form-pricelist>
</ng-template>
<jbm-modal [id]="pricelistsType==0 ? 'copy-article-pricelist' : 'copy-concrete-pricelist'" [className]="'copy-pricelist'"></jbm-modal>
<ng-template #copyModal let-modal>
    <form-copy-concrete-pricelist [pricelistsType]="pricelistsType" [pricelist]="pricelist" [customer_id]="customer_id" (onSave)="copySaved()" (onCancel)="copyCancelled()"></form-copy-concrete-pricelist>
</ng-template>
<jbm-modal [id]="pricelistsType==0 ? 'connect-article-pricelist' : 'connect-concrete-pricelist'" [className]="'connect-pricelist'"></jbm-modal>
<ng-template #connectCustomersModal let-modal>
    <pricelist-connect-customers [pricelistsType]="pricelistsType" [pricelist]="pricelist" (onConnected)="connected()" (onCancel)="cancelConnect()"></pricelist-connect-customers>
</ng-template>
<jbm-modal [id]="pricelistsType==0 ? 'assign-article-pricelist' : 'assign-concrete-pricelist'" [className]="'assign-projects'"></jbm-modal>
<ng-template #assignProjectsModal let-modal>
    <pricelist-assign-projects [pricelistsType]="pricelistsType" [pricelist]="pricelist" (onAssigned)="projectsAssigned()" (onCancel)="projectsAssignCancel()"></pricelist-assign-projects>
</ng-template>
<jbm-modal [id]="pricelistsType==0 ? 'uses-article-pricelist' : 'uses-concrete-pricelist'" [className]="'uses'"></jbm-modal>
<ng-template #usesModal let-modal>
    <pricelist-uses [pricelistsType]="pricelistsType" [pricelist]="pricelist" (onClose)="closeUses()"></pricelist-uses>
</ng-template>
<jbm-toasts></jbm-toasts>
