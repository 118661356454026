<app-header>
    <jbm-view-title [caption]="'menu.settings'|translate" [iconClassname]="iconClassname"></jbm-view-title>
</app-header>
<section class="module settings padding padding-top bg-light-darker">
    <div class="row ml-0">
        <div *ngIf="hasAccessRights('customers') || hasAccessRights('projects') || hasAccessRights('presentations')" class="col-lg-3 pl-0">
            <div *ngIf="hasAccessRights('customers')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.customers" class="text-muted fa fa-{{iconClassnames.customers}}"></i> {{'menu.customers'|translate}}</h5>
                    <a routerLink="/settings/customertypes">{{'menu.customertypes'|translate}}</a>
                </div>
            </div>
            <div *ngIf="hasAccessRights('projects')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.projects" class="text-muted fa fa-{{iconClassnames.projects}}"></i> {{'menu.projects'|translate}}</h5>
                    <a routerLink="/settings/projectstates">{{'project.states'|translate}}</a>
                    <a routerLink="/settings/worktypes">{{'order.worktypes'|translate}}</a>
                    <a routerLink="/settings/unloading-methods">{{'order.unloading-methods'|translate}}</a>
                </div>
            </div>
            <div *ngIf="hasAccessRights('logistics')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.logistics" class="text-muted fa fa-{{iconClassnames.logistics}}"></i> {{'menu.logistics'|translate}}</h5>
                    <a routerLink="/settings/drivers">{{'production.drivers'|translate}}</a>
                    <a routerLink="/settings/trucks">{{'production.trucks'|translate}}</a>
                </div>
            </div>
        </div>
        <div *ngIf="hasAccessRights('financial')" class="col-lg-3 pl-0">
            <div class="card">
                <div class="card-body">
                    <h5><i *ngIf="iconClassnames.financial" class="text-muted fa fa-{{iconClassnames.financial}}"></i> {{'menu.financial'|translate}}</h5>
                    <h6 class="mt-3">{{'financial.concrete'|translate}}</h6>
                    <a routerLink="/settings/financial/concrete-pricelists">{{'financial.concrete-pricelists'|translate}}</a>
                    <a routerLink="/settings/financial/concrete-environments">{{'financial.concrete-environments'|translate}}</a>
                    <a routerLink="/settings/financial/concrete-strengths">{{'financial.concrete-strengths'|translate}}</a>
                    <a routerLink="/settings/financial/concrete-consistencies">{{'financial.concrete-consistencies'|translate}}</a>
                    <h6 class="mt-2">{{'financial.articles'|translate}}</h6>
                    <a routerLink="/settings/financial/article-pricelists">{{'financial.article-pricelists'|translate}}</a>
                    <a routerLink="/settings/financial/articles">{{'financial.articles'|translate}}</a>
                    <a routerLink="/settings/financial/articlegroups">{{'financial.articlegroups'|translate}}</a>
                    <a routerLink="/settings/financial/article-pricetypes">{{'financial.article-price-types'|translate}}</a>
                    <h6 class="mt-2">{{'entity.resources'|translate}}</h6>
                    <a routerLink="/settings/financial/resource-pricelists">{{'entity.resource'|translate}} {{'financial.pricelists'|translate|lowercase}}</a>
                </div>
            </div>
        </div>
        <div *ngIf="hasAccessRights('resources') || hasAccessRights('recipes') || hasAccessRights('company') || hasAccessRights('presentations')" class="col-lg-3 pl-0">
            <div *ngIf="hasAccessRights('resources') || hasAccessRights('recipes')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.recipes" class="text-muted fa fa-{{iconClassnames.recipes}}"></i> {{'entity.recipes'|translate}}</h5>
                    <a *ngIf="hasAccessRights('recipes')" routerLink="/settings/recipes">{{'entity.recipes'|translate}}</a>
                    <a *ngIf="hasAccessRights('resources')" routerLink="/settings/resources">{{'entity.resources'|translate}}</a>
                </div>
            </div>
            <div *ngIf="hasAccessRights('company')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.company" class="text-muted fa fa-{{iconClassnames.company}}"></i> {{'menu.company'|translate}}</h5>
                    <a routerLink="/settings/company">{{'company.data'|translate}}</a>
                    <a routerLink="/settings/company/settings">{{'company.settings'|translate}}</a>
                    <a routerLink="/settings/company/plants">{{'company.plants'|translate}}</a>
                </div>
            </div>
            <div *ngIf="hasAccessRights('presentations')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.presentations" class="text-muted fa fa-{{iconClassnames.presentations}}"></i> {{'menu.presentations'|translate}}</h5>
                    <a routerLink="/settings/presentations">{{'entity.presentation'|translate}} {{'presentations.templates'|translate|lowercase}}</a>
                    <a routerLink="/settings/text-placeholders">{{'ui.text'|translate}} {{'presentations.placeholders'|translate|lowercase}}</a>
                    <a routerLink="/settings/mediafiles">{{'presentations.mediafiles'|translate}}</a>
                </div>
            </div>
        </div>
        <div *ngIf="hasAccessRights('users') || hasAccessRights('translations') || recipeAppSupport" class="col-lg-3 pl-0">
            <div *ngIf="hasAccessRights('users')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.users" class="text-muted fa fa-{{iconClassnames.users}}"></i> {{'users.title'|translate}}</h5>
                    <a routerLink="/settings/users">{{'menu.users'|translate}}</a>
                    <a routerLink="/settings/usergroups">{{'users.usergroups'|translate}}</a>
                    <a routerLink="/settings/grouprights">{{'users.grouprights'|translate}}</a>
                    <a routerLink="/settings/activitylog">{{'log.activity-log'|translate}}</a>
                </div>
            </div>
            <div *ngIf="recipeAppSupport" class="card">
                <div class="card-body">
                    <h5 class="card-title">{{'recipe-app'|translate}}</h5>
                    <a routerLink="/recipe-app/users">{{'menu.users'|translate}}</a>
                </div>
            </div>
            <div *ngIf="hasAccessRights('translations')" class="card">
                <div class="card-body">
                    <h5 class="card-title"><i *ngIf="iconClassnames.translations" class="text-muted fa fa-{{iconClassnames.translations}}"></i> {{'ui.texts'|translate}}</h5>
                    <a routerLink="/settings/translations">{{'menu.translations'|translate}}</a>
                    <a routerLink="/settings/helpcentre">{{'menu.helpcentre'|translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
