import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'jbm-static-label',
  templateUrl: './JBMStaticLabel.component.html',
  styleUrls: ['./JBMStaticLabel.component.scss']
})
export class JBMStaticLabelComponent implements OnInit {

  constructor() { }

  @Input() class: string='';
  @Input() labelCaption: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() value: string='';
  @Input() valueClass: string='';

  ngOnInit(): void {
    if(this.class==undefined) this.class='';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.labelClass==undefined) this.labelClass='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.value==undefined) this.value='';
    if(this.valueClass==undefined) this.valueClass='';
  }
}
