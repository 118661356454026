import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { Presentation } from "./interfaces/presentation";
import {presentation_types} from './presentation-types.service';
import {presentation_output_types} from './output-types.service';
import {presentation_usages} from './presentation-usages.service';

const baseURL: string = 'presentations/';

@Injectable({
  providedIn: 'root'
})
export class PresentationsService {
  presentation: Presentation;

  constructor(
      private HttpService: HttpService,
  ) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/list/'+language, params );
  }
  getDefaultID(presentation_type: presentation_types, output_type: presentation_output_types, usage: presentation_usages) {
    return this.HttpService.get(baseURL+'read/default-id/'+presentation_type.toString()+'/'+output_type.toString()+'/'+usage.toString(),null);
  }
  getOne(language: string, id :number) {
    return this.HttpService.get(baseURL+'read/one/'+language+'/'+id.toString(),null );
  }
  getPDF(language: string, id :number) {
    return this.HttpService.get(baseURL+'read/one-output-type/0/'+language+'/'+id.toString(),null );
  }
  getEmail(language: string, id :number) {
    return this.HttpService.get(baseURL+'read/one-output-type/1/'+language+'/'+id.toString(),null );
  }
  getEmpty() {
    this.presentation = {
      id: 0,
      presentation_type: -1,
      output_type: null,
      usage: presentation_usages.default_usage,
      language: '',
      description: '',
      is_default: false,
      is_system: false
    }
    return this.presentation;
  }
  getIsAlwaysDefault(id: number) {
    return this.HttpService.get(baseURL+'read/is-always-default/'+id.toString(),null );
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create', data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update', data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+id.toString());
  }
}
