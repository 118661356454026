import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProjectsService} from "../../data/projects.service";
import {OrdersService} from "../../data/orders.service";
import {UnloadingMethodsService} from "../../data/unloading-methods.service";
import {Order} from "../../data/interfaces/order";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {ValidationPatternsService} from "../../../../services/validation/patterns.service";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {Project} from "../../data/interfaces/project";
import {UnloadingMethod} from "../../data/interfaces/unloading-method";

@Component({
  selector: 'order-copy',
  templateUrl: './order-copy.component.html',
  styleUrls: ['./order-copy.component.scss']
})
export class OrderCopyComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  project: Project;
  order: Order=this.OrdersService.getEmpty();
  form: FormGroup;
  isSubmitted: boolean=false;
  date: NgbDateStruct;
  maxdate: NgbDateStruct;
  loadingTime: number;
  unloadingMethod: UnloadingMethod;
  endTime: string;
  loadTime: string;

  constructor(
      private ProjectsService: ProjectsService,
      private OrdersService: OrdersService,
      private UnloadingMethodsService: UnloadingMethodsService,
      private formBuilder: FormBuilder,
      private DatetimeService: DatetimeService,
      private ValidationPatternsService: ValidationPatternsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService
  ) {
    this.form=this.formBuilder.group({
      production_date: new FormControl(this.date),
      starttime: new FormControl('',[Validators.pattern(this.DatetimeService.getTimeRegEx())])
    }, {updateOn: "blur"});
  }

  @Input() order_id: number;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  ngOnInit(): void {
    this.OrdersService.getByID(this.order_id).subscribe(
      (data)=>this.order=data.data as Order,
      (error)=>console.error(error),
      ()=> {
        this.ProjectsService.getByID(this.order.projects_id).subscribe(
          (data)=>this.project=data.data as Project,
          (error)=>console.error(error),
            ()=>
                this.UnloadingMethodsService.getByID(this.order.unloading_methods_id).subscribe(
                  (data)=>this.unloadingMethod=data.data as UnloadingMethod,
                  (error)=>console.error(error),
                    ()=>
                        this.OrdersService.getLoadingTime().subscribe(
                            (data)=>this.loadingTime=data.data,
                            (error)=>console.error(error)
                        )
                )
        )
      }
    )
  }
  get formControls() {
    return this.form.controls;
  }
  calcLoadTime() {
    this.loadTime='';
    if(!this.form.controls.starttime.valid) return;
    let starttime=this.form.controls.starttime.value.trim();
    if(starttime!=='')
      this.loadTime=this.OrdersService.calcLoadTime(starttime, this.project.traveltime, this.loadingTime );
  }
  calcEndTime() {
    this.endTime='';

    if(!this.form.controls.starttime.valid) return;
    let starttime=this.form.controls.starttime.value.trim();
    if(starttime==='') return;

    let unloading_flow=this.order.unloading_flow;
    if(!unloading_flow)
      unloading_flow=this.unloadingMethod.speed;

    this.endTime=this.OrdersService.calcEndTime(starttime, this.order.amount, unloading_flow);
  }
  save() {
    this.isSubmitted=true;
    if(this.form.invalid)
      return;

    let production_date=this.formControls.production_date.value;
    if(production_date.length===10)
        // Convert to YYYY-MM-DD
      production_date=this.DatetimeService.dateDMYToYMD(production_date);

    // Sanitize data
    this.order.id=0;
    this.order.production_date=production_date;
    this.order.comments=null;
    this.order.delivered=0;

    this.OrdersService.create(this.order, this.language).subscribe(
      ()=>{},error => console.error(error),()=> {
        this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
        this.onSave.emit();
      }
    )
  }
  cancel() {
    this.onCancel.emit();
  }
}