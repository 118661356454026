<form [formGroup]="form" (ngSubmit)="save()" class="quotation-item">
   <div class="jbm-modal-header">
      <jbm-crud-title subject="project.order" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-select
        [formControl]="formControls.worktypes_id" [submitted]="submitted"
        [required]="true" [options]="worktypeOptions" [id]="'worktypes_id'" insertLeadingOption="true" class="lg"
        labelClass="lg" labelCaption="{{'order.worktype'|translate}}">
         <jbm-input-error [formControl]="formControls.worktypes_id" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-select>
      <jbm-text-input [formControl]="formControls.description" [submitted]="submitted" [id]="'description'"
        [maxlength]="125" required="true" labelCaption="{{'entity.description'|translate}}"
        labelClass="lg" class="mb-3 input-25">
         <jbm-input-error [formControl]="formControls.description" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-text-input>

      <jbm-float-input-group [formControl]="formControls.amount" [submitted]="submitted"
        [id]="'amount'" required="true" [inputClass]="'xxs'" [class]="'mb-3'" append="{{'quantity.m3'|translate}}"
        labelCaption="{{'order.amount'|translate}}" labelClass="lg" decimals="1" [decimalsOptional]="true"
        [negativeAllowed]="false">
         <jbm-input-error [formControl]="formControls.amount" [submitted]="submitted">
            {{'entity.concrete-amount-format'|translate}}
         </jbm-input-error>
      </jbm-float-input-group>
      <div class="form-section-separator lg-lg mb-0 ">{{'entity.recipe'|translate}}</div>
      <jbm-check [formControl]="formControls.recipe_unknown" [id]="'recipe_unknown'"
        [labelCaption]="'order.recipe-unknown'|translate" class="xl"
        labelClass="lg" submitted="submitted" (change)="recipeUnknownChange()">
      </jbm-check>
      <recipe-selector *ngIf="!recipeUnknown"
        [labelCaption]="'entity.recipe'|translate"
        labelClass="lg"
        class="lg mb-2"
        required="true"
        [isSubmitted]="submitted"
        [template]="recipeselect"
        [recipe_id]="recipe_id"
        [environment_id]="environment_id"
        (recipeSelected)="selectRecipeEnvironment($event)">
         <ng-template #recipeselect let-recipe="recipe" let-environment="environment">
            <div *ngIf="recipe && environment">
               <table class="table table-xs table-borderless">
                  <tr><td>{{'entity.code'|translate}}:</td><td>{{recipe.code}}</td></tr>
                  <tr><td>{{'concrete.consistency'|translate}}:</td><td>{{recipe.consistency.code}}</td></tr>
                  <tr *ngIf="recipe.strength"><td>{{'concrete.strength'|translate}}:</td><td>{{recipe.strength.description}}</td></tr>
                  <tr><td>{{'concrete.environment'|translate}}:</td><td>{{environment.description}}</td></tr>
               </table>
            </div>
         </ng-template>
      </recipe-selector>
      <legend *ngIf="recipeUnknown" class="ml-4">{{'financial.concrete-specs'|translate}}</legend>
      <div *ngIf="recipeUnknown">
         <strength-selector
           [labelCaption]="'concrete.strength'|translate"
           labelClass="lg"
           class="lg"
           [id]="'strength'"
           [template]="strengthselect"
           [strength_id]="strength_id"
           required="true"
           [isSubmitted]="submitted"
           (strengthSelected)="selectStrength($event)">
            <ng-template #strengthselect let-strength="strength">
               <div *ngIf="strength">
                  {{strength.description}}
               </div>
            </ng-template>
         </strength-selector>
         <environment-selector
           [labelCaption]="'concrete.environment'|translate"
           labelClass="lg"
           class="lg"
           [id]="'environment'"
           [template]="environmentselect"
           [environment_id]="environment_id"
           required="true"
           [isSubmitted]="submitted"
           (environmentSelected)="selectEnvironment($event)">
            <ng-template #environmentselect let-environment="environment">
               <div *ngIf="environment">
                  {{environment.description}}
               </div>
            </ng-template>
         </environment-selector>
         <consistency-selector
           [labelCaption]="'concrete.consistency'|translate"
           labelClass="lg"
           class="lg"
           [id]="'consistency'"
           [template]="consistencyselect"
           [consistency_id]="consistency_id"
           required="true"
           [isSubmitted]="submitted"
           (consistencySelected)="selectConsistency($event)">
            <ng-template #consistencyselect let-consistency="consistency">
               <div *ngIf="consistency">
                  {{consistency.code}}
               </div>
            </ng-template>
         </consistency-selector>
      </div>
      <jbm-select [formControl]="formControls.unloading_methods_id" [submitted]="submitted"
        [options]="unloading_methodOptions" insertLeadingOption="true" [required]="true" [id]="'unloading_methods_id'"
        class="lg mt-3" labelClass="lg" labelCaption="{{'order.unloading-method'|translate}}">
         <jbm-input-error [formControl]="formControls.unloading_methods_id" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-select>
      <jbm-textarea [formControl]="formControls.comments" [submitted]="submitted"
        [id]="'comments'" class="lg mt-3"
        labelCaption="{{'entity.notes'|translate}}" labelClass="lg" rows="3">
      </jbm-textarea>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button subject="{{'project.order'|translate}}" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
