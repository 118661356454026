import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InvoicesService} from "../data/invoices.service";
import {InvoiceConcretesService} from "../data/invoice-concretes.service";
import {TokenService} from "../../../services/auth/token.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";

@Component({
  selector: 'invoice-orders',
  templateUrl: './invoice-orders.component.html',
  styleUrls: ['./invoice-orders.component.scss']
})
export class InvoiceOrdersComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  orders: any[]=[];
  invoiceableOrders: any[]=[];
  recipe_id: number=0;
  order_id: number=0;
  environment: string='';

  @Input() project_id: number=0;
  @Input() invoice_id: number;
  @Input() editable: boolean;

  @Output() afterAdd=new EventEmitter();
  @Output() afterDelete=new EventEmitter();
  @Output() onClose=new EventEmitter();

  @ViewChild('modalRecipe') modalRecipe: ElementRef;
  @ViewChild('modalOrder') modalOrder: ElementRef;

  constructor(
      private InvoicesService: InvoicesService,
      private InvoiceConcretesService: InvoiceConcretesService,
      private TokenService: TokenService,
      private ModalService: ModalService
  ) { }

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.InvoicesService.getInvoicedOrders(this.language, this.invoice_id).subscribe(
      (data)=>this.orders=data.data,
      (error)=>console.error(error),
      ()=>
          this.InvoicesService.getInvoiceableOrders(this.language, this.project_id).subscribe(
            (data)=>this.invoiceableOrders=data.data,
            (error)=>console.error(error),
            ()=>{}
          )
    )
  }
  add(order_id: number) {
    this.InvoiceConcretesService.add(this.language, this.invoice_id, order_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.getData();
          this.afterAdd.emit();
        }
    )
  }
  delete(order_id: number) {
    this.InvoiceConcretesService.remove(order_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.afterDelete.emit();
          this.getData();
        }
    )
  }
  showRecipe(order) {
    this.recipe_id=order.recipes_id;
    this.environment='';
    if(order.environment)
      this.environment=order.environment.description;

    this.ModalService.open(this.modalRecipe,'recipe');
  }
  closeRecipe() {
    this.ModalService.close('recipe');
  }
  // Order
  showOrder(order_id: number) {
    this.order_id=order_id;
    this.ModalService.open(this.modalOrder,'order')
  }
  closeOrder() {
    this.ModalService.close('order');
  }
  close() {
    this.onClose.emit();
  }
}
