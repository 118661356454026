import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {JBMTableColumnAlignment, JBMTableColumnType, JBMTableDisplayValue} from "./JBMTable.component";
import {DatePipe} from "@angular/common";
import {MoneyPipe} from "../../../pipes/money.pipe";

@Directive({
  selector: '[jbm-table-cell]',
  providers: [MoneyPipe]
})
export class CellDirective {
  element: any;

  @Input() type: JBMTableColumnType;
  @Input() align: JBMTableColumnAlignment;
  @Input() data: any;
  @Input() displayValues: JBMTableDisplayValue[];

  constructor(
      private e:ElementRef,
      private renderer: Renderer2,
      private DatePipe: DatePipe,
      private MoneyPipe: MoneyPipe
  )
  {
    this.element=e.nativeElement;
    this.renderer=renderer;
  }
  ngOnInit() {
    if(this.type==undefined) this.type=JBMTableColumnType.string;
    if(this.data==undefined) this.data='';
    if(this.displayValues==undefined) this.displayValues=[];
    this.render();
  }
  render() {
    // Sometimes there's no content; just classes and stylings
    let content=this.handleDisplayValues();
    if(!content)
      if (typeof this.data=="boolean" && this.data)
        // Boolean data with value true; add checked class to cell-element
        this.element.className += 'checked';

    // Set the content and text alignment of the cell element
    this.setContentAndAlignment(content);
  }
  handleDisplayValues() {
    let content='';
    for(let d=0; d<this.displayValues.length; d++) {
      if((this.data===this.displayValues[d].dataValue) &&
         (this.displayValues[d].displayValue != undefined)) {
        // Cell content will be the content specified for this specific data value
        content = this.displayValues[d].displayValue;
        if(this.displayValues[d].className != undefined)
          // Add data value related css classname to element
          this.element.className+=this.displayValues[d].className;
      }
    }
    return content;
  }
  setCellAlignment(alignment: JBMTableColumnAlignment) {
    // Reset current cell alignment
    this.renderer.removeStyle(this.element,'text-align');
    if(alignment==JBMTableColumnAlignment.left)
      return;
    this.renderer.setStyle(this.element,'text-align', this.getAlignmentStyle(alignment));
  }
  getAlignmentStyle(alignment: JBMTableColumnAlignment) {
    let alignments=['left','center','right'];
    return alignments[alignment];
  }
  setContentAndAlignment(content) {
    // Set cell content
    if(!content) {
      switch(this.type) {
        case JBMTableColumnType.date: {
          content=this.DatePipe.transform(this.data,'dd-MM-yyyy');
          break;
        }
        case JBMTableColumnType.datetime: {
          content=this.DatePipe.transform(this.data,'dd-MM-yyyy HH:mm');
          break;
        }
        case JBMTableColumnType.datetimeseconds: {
          content=this.DatePipe.transform(this.data,'dd-MM-yyyy HH:mm:ss');
          break;
        }
        case JBMTableColumnType.dateshort: {
          content=this.DatePipe.transform(this.data,'dd-MM');
          break;
        }
        case JBMTableColumnType.time: {
          if(this.data)
            content=this.DatePipe.transform(this.data, 'HH:mm');
          break;
        }
        case JBMTableColumnType.timeseconds: {
          content=this.DatePipe.transform(this.data, 'HH:mm:ss');
          break;
        }
        case JBMTableColumnType.price: {
          content=this.MoneyPipe.transform(this.data);
          break;
        }
        default: {
          content=this.data;
        }
      }
    }
    this.element.innerHTML=content;

    // Set cell alignment
    let alignment=this.align;
    switch(this.type) {
      case JBMTableColumnType.number: {
        if(this.align==undefined)
          alignment=JBMTableColumnAlignment.right;
        break;
      }
      case JBMTableColumnType.price: {
        if(this.align==undefined)
          alignment=JBMTableColumnAlignment.right;
        break;
      }
      case JBMTableColumnType.boolean: {
        if(this.align==undefined)
          alignment=JBMTableColumnAlignment.center;
        break;
      }
    }
    this.setCellAlignment(alignment);
  }
}