<jbm-modal [id]="'customer-invoice'" [className]="'customer-invoice'" (onClose)="close()"></jbm-modal>
<ng-template #modalInvoice let-modal>
<form [formGroup]="form" (ngSubmit)="save()" class="invoice" xmlns="http://www.w3.org/1999/html">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">
            {{'entity.customer'|translate}} {{'entity.invoice'|translate|lowercase}} {{'ui.generate'|translate|lowercase}}
         </div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <ngb-alert *ngIf="customerError" [type]="'danger'" [dismissible]="false" class="xl">{{customerError}}</ngb-alert>
      <customer-selector labelCaption="{{'customer'|translate}}" class="xxl"
        [id]="'customer-selector'"
        [customer_id]="formControls.customer_id.value"
        [template]="customerselect"
        [filter]="0"
        [labelStacked]="true"
        (customerSelected)="setCustomer($event)">
      </customer-selector>
      <ng-template #customerselect let-customer="customer">
         <div *ngIf="customer">
            {{customer.name}}<br />
            {{customer.address_details.street}}&nbsp;{{customer.address_details.no}}<br />
            {{customer.address_details.postcode}}&nbsp;&nbsp;{{customer.address_details.city}}<br />
            {{'entity.blocked'|translate}}: <span *ngIf="customer.blocked" class="badge bg-danger">{{'ui.yes'|translate}}</span>
            <span *ngIf="!customer.blocked" class="badge bg-success">{{'ui.no'|translate}}</span>
         </div>
      </ng-template>
      <jbm-textarea [id]="'comments'" [formControl]="formControls.comments" [submitted]="submitted" [rows]="4" labelStacked="true"
        class="mt-3 xxl" [labelCaption]="('entity.concept'|translate) + ' ' + ('entity.invoice'|translate|lowercase) + ' ' + ('entity.comments'|translate|lowercase)">
      </jbm-textarea>
      <jbm-check [id]="'openInvoice'" labelCaption="{{'invoice.open-after-create'|translate}}"
        [formControl]="formControls.openInvoice" alignLeft="true"></jbm-check>
   </div>
   <div class="jbm-modal-footer text-right">
      <button type="button" class="btn btn-primary" (click)="save()"><i class="fa fa-check"></i> {{'entity.customer'|translate}} {{'entity.invoice'|translate|lowercase}} {{'translate.insert'|translate|lowercase}}</button>
      <jbm-cancel-button context="secondary" [small]="false" class="ml-2" (click)="close()"></jbm-cancel-button>
   </div>
</form>
</ng-template>
<jbm-toasts></jbm-toasts>