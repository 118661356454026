import {Injectable} from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../services/auth/token.service";
import {JBMTableColumnDef, IDTag} from "../../../components/JBM/JBMTable/JBMTable.component";

const baseURL: string = 'users/read/tablelayout/';

@Injectable({
  providedIn: 'root'
})
export class TablelayoutService {
  constructor( private HttpService: HttpService, private TokenService: TokenService ) {}

  getEmpty() {
    return this.HttpService.get(baseURL+'empty');
  }
  setLayout(tablename: string, columnDefs: JBMTableColumnDef[]): any {
    let user_id=this.TokenService.getUserId();
    let params=new HttpParams();
    params=params.append('user_id',user_id.toString());
    params=params.append('tablename',tablename);
    this.HttpService.getAsPromise(baseURL+'one', params ).then( (data)=> {
      if(data.data.hasOwnProperty('layout')) {
        // Update columns layout if layout has been stored
        let layout = data.data.layout;
        columnDefs = this.setColumnsLayout(layout, columnDefs);
      }
    });
  }
  saveLayout(tablename: string, columnDefs: JBMTableColumnDef[]) {
    let user_id=this.TokenService.getUserId();
    let layout=this._getColumnsLayout(columnDefs);
    this.HttpService.post(baseURL+'create',
        { user_id: user_id, tablename: tablename, layout: layout }).subscribe();
  }
  setColumnsLayout(layout, columnDefs: JBMTableColumnDef[]): JBMTableColumnDef[] {
    let columns=layout.split(',');

    // First, make all hideable columns unvisible
    for(let c=0; c<columnDefs.length; c++) {
      if(columnDefs[c].tag==IDTag)
          // Skip as ID column tagged column
        continue;
      if(!columnDefs[c].hideable)
          // Skip not hideable column
        continue;
      columnDefs[c].visible=false;
    }

    // Last, make all columns from the layout array visible
    for(let c=0; c<columnDefs.length; c++) {
      let index=columns.indexOf(columnDefs[c].name);
      if(index==-1)
          // Columnname not found
        continue;
      columnDefs[c].visible=true;
    }
    return columnDefs;
  }
  _getColumnsLayout(columnDefs: JBMTableColumnDef[]): string {
    let result='';
    for(let c=0; c<columnDefs.length; c++) {
      if(columnDefs[c].tag==IDTag)
          // Skip as ID column tagged column
        continue;
      if(!columnDefs[c].hideable)
          // Skip not hideable column
        continue;
      if(columnDefs[c].visible) {
        result += columnDefs[c].name;
        if(c<(columnDefs.length-1))
          result +=',';
      }
    }
    return result;
  }

}
