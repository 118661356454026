import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'company-container',
  templateUrl: './company.container.component.html',
  styleUrls: ['./company.container.component.scss']
})
export class CompanyContainerComponent implements OnInit {
  activeTab: number=0;
  constructor(private Router: Router) {
    // Set tab according URL segment
    if(Router.url.indexOf('company/settings')>0) this.activeTab=1;
    if(Router.url.indexOf('company/plants')>0) this.activeTab=2;
  }
  ngOnInit(): void {}
}
