import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ArticlePricelistsService, PricelistsType} from "../../data/article-pricelists.service";
import {CustomerPricelistsService} from "../../data/customer-pricelists.service";
import {ProjectsService} from "../../../projects/data/projects.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {ArticlePricelist} from "../../data/interfaces/article-pricelist";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {NgbAccordion} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'pricelist-uses',
  templateUrl: './pricelist_uses.component.html',
  styleUrls: ['./pricelist_uses.component.scss']
})
export class PricelistUsesComponent implements OnInit {
  uses: any={ customers: [], projects: [] }
  types=this.ArticlePricelistsService.getTypeItems(this.TranslateService);
  caption: string;

  constructor(
      private ArticlePricelistsService: ArticlePricelistsService,
      private CustomerArticlePricelistsService: CustomerPricelistsService,
      private ProjectsService: ProjectsService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  )
  { this.pricelist=this.ArticlePricelistsService.getEmpty() }


  @Input() pricelistsType: PricelistsType=PricelistsType.articles;
  @Input() pricelist: ArticlePricelist;

  @Output() onClose=new EventEmitter();

  @ViewChild('acc') acc: NgbAccordion;

  ngOnInit(): void {
    if(this.pricelistsType===PricelistsType.articles)
      this.caption='financial.article-pricelist';
    else
      this.caption='financial.concrete-pricelist';
    this.getData();
  }
  getData() {
    this.ArticlePricelistsService.getUses(this.pricelistsType, this.pricelist.id).subscribe(
        (data)=>this.uses=data.data,
        (error)=>console.error(error)
    )
  }

  // Disconnect items
  confirmDisconnectCustomer(customer_id: number) {
    if(this.uses.customers.length===1)
        // Last customer will be removed, close panel before it's gonna be disabled
      this.acc.toggle('panel-1');
    this.CustomerArticlePricelistsService.deleteUse(this.pricelistsType, this.pricelist.id, customer_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData();
        }
    )
  }
  confirmDisconnectProject(project_id: number) {
    if(this.uses.projects.length===1)
      // Last project will be removed, close panel before it's gonna be disabled
      this.acc.toggle('panel-2');
    this.ProjectsService.deleteUse(this.pricelistsType, project_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData();
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
