import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {TestComponent} from "./test/test.component";
import {AppLayouts} from "../app.component";

const routes: Routes = [
  {
    path: 'range',
    component: TestComponent,
    data: {AppLayout: AppLayouts.User}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TestRoutingModule { }
