import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {QuotationsComponent} from "./datatable/quotations.component";
import {InvoiceViewComponent} from "../invoicing/invoice-view/invoice-view.component";
import {InvoiceResolver} from "../invoicing/data/resolvers/invoice.resolver";
import {QuotationViewComponent} from "./quotation-view/quotation-view.component";
import {QuotationResolver} from "./data/resolvers/quotation.resolver";
import {InvoicePreviewComponent} from "../invoicing/preview/invoice-preview.component";
import {QuotationPreviewComponent} from "./preview/quotation-preview.component";

const routes: Routes = [
  {
    path: 'quotations',
    component: QuotationsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  { path: 'quotations/:quotation_revision_id',
    component: QuotationViewComponent,
    resolve: { quotation: QuotationResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  { path: 'quotation-preview/:quotation_revision_id',
    component: QuotationPreviewComponent,
    resolve: { quotation: QuotationResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.ExternalOutlet}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QuotationsRoutingModule { }
