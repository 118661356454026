import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

/* We want to share common Services, Directives en Pipes:
   1. To avoid multiple instances of services (Singletons)
   2. Less imports in other (lazyloaded) features
   3. Avoid double imports in features of one Pipe, Directive, etc...
*/

// Shared services
import { TranslateService } from '../services/multilingual/translate.service';

// Shared directives
import { DigitsOnlyInputDirective } from '../directives/digits-only-input.directive';
import { FloatOnlyInputDirective} from "../directives/float-only-input.directive";

// Shared pipes
import { TranslatePipe } from '../pipes/translate.pipe';
import { MoneyPipe } from "../pipes/money.pipe";
import { FileSizePipe} from "../pipes/filesize.pipe";
import { FloatPipe} from "../pipes/float.pipe";

@NgModule({
  declarations: [
    DigitsOnlyInputDirective, FloatOnlyInputDirective,
    TranslatePipe, MoneyPipe, FileSizePipe, FloatPipe
  ],
  exports: [
    DigitsOnlyInputDirective, FloatOnlyInputDirective,
    TranslatePipe, MoneyPipe, FileSizePipe, FloatPipe,
    NgbModule, ReactiveFormsModule, FormsModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [ TranslateService ]
    };
  }
}
