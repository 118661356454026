import {Directive, ElementRef, Input} from '@angular/core';
import {JBMTableColumnType} from "./JBMTable.component";
import {Context, ContextType} from "../Helpers/Context";

@Directive({
  selector: '[jbm-table-action]'
})
export class ActionDirective {
  @Input() name: string;
  @Input() iconClass: string;
  @Input() caption: string;
  @Input() context: ContextType;
  @Input() size: string='sm';
  @Input() disabled: boolean=false;

  contextClass: Context;
  element: any;

  constructor(private e:ElementRef) {
    this.contextClass=new Context();
    this.element=e.nativeElement;
  }

  ngOnInit() {
    if(this.name==undefined) this.name='action';
    if(this.iconClass==undefined) this.iconClass='';
    if(this.caption==undefined) this.caption='';
    if(this.context==undefined) this.context=ContextType.primary;
    if(this.size==undefined) this.size='sm';
    this.render();
  }
  render() {
    if(!this.iconClass && !this.caption) return '';

    let disabled=this.disabled ? 'disabled="disabled"' : '';
    let content='<button '+disabled+' class="btn btn-'+this.size+' btn-'+this.contextClass.getContextClassname(this.context)+'">';
    if(this.iconClass) content+='<i class="'+this.iconClass+'"></i>';
    if(this.caption) content+=' '+this.caption;
    content+='</button>';
    this.element.innerHTML=content;
  }
}
