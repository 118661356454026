<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div *ngIf="receipts.length>1" class="title">{{'menu.production'|translate}} {{'production.receipts'|translate|lowercase}} {{'ui.synchronise'|translate|lowercase}}</div>
      <div *ngIf="receipts.length==1" class="title">{{'menu.production'|translate}} {{'production.receipt'|translate|lowercase}} {{'ui.synchronise'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <jbm-static-label [labelCaption]="receipts.length>1 ? ('production.receipts'|translate) : ('production.receipt'|translate)"
     [labelClass]="'sm'" [value]="receipt_ids">
   </jbm-static-label>
   <project-selector [project_id]="project_id" [template]="selectedProject" [with_customers_only]="true"
     [labelClass]="'sm'" [labelCaption]="'entity.project'|translate" [class]="'xxl'" [project_states_ids]="projectstate_ids"
     (projectSelected)="selectProject($event)">
   </project-selector>
   <ng-template #selectedProject let-project="project">
      <div *ngIf="project">
         {{project.name}}<br />
         <span class="td-badge badge badge-pill" style="background-color: {{project.projectState.color}};color: {{project.projectState.text_color}}">
            {{project.projectState.description}}
         </span>
      </div>
   </ng-template>
   <div *ngIf="project_id" class="mt-4">
      <h6>{{'project.select-order'|translate}}</h6>
      <table class="table table-xs table-hover">
         <thead>
            <tr>
               <th>{{'entity.description'|translate}}</th>
               <th>{{'order.worktype'|translate}}</th>
               <th>{{'entity.recipe'|translate}}</th>
               <th>{{'order.amount'|translate}}</th>
               <th></th>
            </tr>
         </thead>
         <tbody>
            <tr *ngFor="let order of orders" [ngClass]="{ 'bg-info': order_id==order.id }" (click)="selectOrder(order.id)">
               <td>{{order.description}}</td>
               <td>{{order.worktype.description}}</td>
               <td><span *ngIf="order.recipe">{{order.recipe.code}}</span></td>
               <td>{{order.amount}}</td>
               <td>
                  <jbm-link-button *ngIf="order.description" [iconClass]="'fa fa-info'" (onClick)="showOrder(order.id)"></jbm-link-button>
                  <jbm-link-button *ngIf="!order.description" [caption]="order.worktype.description" (onClick)="showOrder(order.id)"></jbm-link-button>
               </td>
            </tr>
         </tbody>
      </table>
   </div>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <button [disabled]="!project_id || !order_id" type="button" class="btn btn-sm btn-primary" (click)="save()">{{'ui.synchronise'|translate}}</button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button (click)="close()"></jbm-cancel-button>
   </div>
</div>

<jbm-modal [id]="'orderDetails'" [className]="'order-details-shared'"></jbm-modal>
<ng-template #modalOrderDetails let-modal>
   <order-details [order_id]="modal_order_id" (onClose)="closeOrder()"></order-details>
</ng-template>
