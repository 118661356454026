import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ContactsService} from "../../../../features/contacts/data/contacts.service";
import {Contact} from "../../../../features/contacts/data/interfaces/contact";

@Component({
  selector: 'contact-dropdown',
  templateUrl: './ContactDropdown.component.html',
  styleUrls: ['./ContactDropdown.component.scss']
})
export class ContactDropdownComponent implements OnInit {
  contact: Contact=this.ContactsService.getEmpty();
  loaded: boolean=false;

  constructor(
      private ContactsService: ContactsService
  ) { }

  @Input() subject: number=0;
  @Input() subject_id: number=0;
  @Input() roles_id: number=0;
  @Input() role: string='';
  @Input() class: string='';
  @Input() allowEditing: boolean=false;

  @Output() OnEdit=new EventEmitter();

  ngOnInit(): void {}
  toggle() {
    if(this.loaded) return;
    this.loadContact();
  }
  loadContact() {
    this.ContactsService.getOne(this.subject, this.subject_id, this.roles_id).subscribe(
        (data)=>this.contact=data.data as Contact,
        (error)=>console.error(error),
        ()=>this.loaded=true
    )
  }
  edit() {
    // Set contact properties
    this.contact.subject=this.subject;
    this.contact.subject_id=this.subject_id;
    this.contact.roles_id=this.roles_id;

    this.OnEdit.emit({ contact: this.contact, role: this.role } );
  }
}
