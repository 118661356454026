import {Component, OnInit, OnDestroy, HostListener, ElementRef} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {StateService} from "../../../../services/storage/state.service";
import {HelpTopicsService} from "../../../../features/helpcentre/data/helptopics.service";
import {TokenService} from "../../../../services/auth/token.service";
import {BodyClassTogglerService} from "../../../../services/interface/body-class-toggler.service";
import {Subscription} from "rxjs";
import {HelpTopic} from "../../../../features/helpcentre/data/interfaces/helptopic";

@Component({
  selector: 'jbm-help-index',
  templateUrl: './JBMHelpIndex.component.html',
  styleUrls: ['./JBMHelpIndex.component.scss']
})
export class JBMHelpIndexComponent implements OnInit, OnDestroy {
  helpTopic: HelpTopic=this.HelpTopicsService.getEmpty();
  childTopics: HelpTopic[]=[];
  parentTopics: HelpTopic[]=[];
  language: string=this.TokenService.getLanguage();

  param$: Subscription;
  parentItems$: Subscription;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private Router: Router,
      private StateService: StateService,
      private TokenService: TokenService,
      private HelpTopicsService: HelpTopicsService,
      private eRef: ElementRef,
      private BodyClassTogglerService: BodyClassTogglerService
  ) { }

  ngOnInit(): void {
    let topicID=this.StateService.getElementData('helpindex');
    if(topicID===null) topicID=0; // Element not found; show helpindex root
    this.StateService.deleteElement('helpindex');
    this.setHelpTopic(topicID);

    this.BodyClassTogglerService.checkClass('aside-right');
  }
  setHelpTopic(topicID) {
    let HTTPParams=new HttpParams().append('id', topicID.toString());
    this.HelpTopicsService.get(this.language, HTTPParams).subscribe(
        (data)=>this.helpTopic=data.data as HelpTopic,
        (error)=>console.error(error),
        ()=> {
          this.getChildTopics();
          this.getParentTopics();
        }
    )
  }
  getChildTopics() {
    let HTTPParams=new HttpParams().append('parent_id',this.helpTopic.id.toString());
    HTTPParams=HTTPParams.append('sort','position');
    this.HelpTopicsService.get(this.language, HTTPParams).subscribe(
        (data)=>this.childTopics=data.data as HelpTopic[],
        (error)=>console.error(error),
        ()=> {}
    )
  }
  getParentTopics() {
    this.parentItems$=this.HelpTopicsService.getParentTopics(this.language, this.helpTopic.parent_id).subscribe(
        (data)=>this.parentTopics=data.data as HelpTopic[],
        (error)=>console.error(error),
        ()=>{this.parentTopics.reverse()}
    )
  }
  close() {
    this.Router.navigate([{ outlets: { 'ar': null }} ] );
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.close();
  }
  @HostListener('window:keydown', ['$event'])
  handleEscape(event: KeyboardEvent) {
    if(event.code==='Escape')
      this.close();
  }

  ngOnDestroy() {
    if(this.parentItems$!=undefined) this.parentItems$.unsubscribe();
    if(this.param$!=undefined) this.param$.unsubscribe();
    this.BodyClassTogglerService.removeClass('aside-right');
    // Clear helptopic id State
    this.StateService.deleteElement('helpindex');
  }
}
