import {NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {PlanningComponent} from './planning.component';
import {AuthGuardService} from '../../services/auth/authguard.service';
import {AppLayouts} from '../../app.component';

const routes: Routes = [
  { path: 'planning',
    component: PlanningComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanningRoutingModule { }
