import {ElementRef, HostListener, Injectable, ViewChild} from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'planning/';

@Injectable({
  providedIn: 'root'
})
export class PlanningService {

  constructor( private HttpService: HttpService ) {}

  getProjectstates(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/projectstates/'+language, params );
  }
  getPeriod(language: string, startDate: string, endDate: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/period/'+language+'/'+startDate+'/'+endDate, params );
  }
  getTrucksCapacityIndication(truckHours: any[]): {} {
    let result= {
      smallestPositive: 0, // We want the smallest positive value
      largestNegative: 0   // We want the largest negative value
    }

    for(let s=0; s<truckHours.length; s++) {

      let trucksCapacity=Math.round(truckHours[s].plant.production_capacity / truckHours[s].average_truck_capacity);

      let smallestPositive=1000;
      let largestNegative=0;
      let plantTrucks=0;

      // Per hour: Calculate the smallest positive and largest negative trucks indication
      for(let h=0; h<truckHours[s].truckHours.length; h++) {
        let hourTrucks=truckHours[s].truckHours[h].totalTrucks;
        plantTrucks=Math.max(hourTrucks, plantTrucks);
        let hourIndication=trucksCapacity-hourTrucks;
        if(hourIndication<=0)
          largestNegative=Math.min(hourIndication, largestNegative);
        else
          smallestPositive=Math.min(hourIndication, smallestPositive);
      }
      result.largestNegative=Math.min(largestNegative, result.largestNegative);
      result.smallestPositive=Math.max(smallestPositive, result.smallestPositive);
    }

    return result;
  }
  getPlannableOrders(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/plannable/'+language, params);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/order', data);
  }
  remove(id: number) {
//    return this.HttpService.get(baseURL+'delete/'+id.toString());
    return this.HttpService.get(baseURL+'delete/0');

  }
}
