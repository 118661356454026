import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {FormGroupBaseComponent} from "../../formgroupbase.component";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerKeyboardService,
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';
import {CustomKeyboardService} from "../../../../../../services/NgbDatepicker/custom-keyboard-service";
import {CustomDatepickerI18n} from "../../../../../../services/NgbDatepicker/custom-datepicker-i18n.service";
import {CustomAdapter} from "../../../../../../services/NgbDatepicker/custom-adapter.service";
import {CustomDateParserFormatter} from "../../../../../../services/NgbDatepicker/custom-dateparser-formatter.service";

@Component({
  selector: 'jbm-date-input',
  templateUrl: './JBMDateInput.component.html',
  styleUrls: ['./JBMDateInput.component.scss'],
  providers: [
      {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, // define custom NgbDatepickerI18n provider
      {provide: NgbDateAdapter, useClass: CustomAdapter},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
      {provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService}
  ]
})

export class JBMDateInputComponent extends FormGroupBaseComponent implements OnInit {
  @Input() mindate: NgbDateStruct;
  @Input() maxdate: NgbDateStruct;
  @Input() date: NgbDateStruct;
  @Input() placement: string;
  @Output() onBlur = new EventEmitter();

  ngOnInit() {
    super.ngOnInit();
    if(this.placement==undefined) this.placement='bottom';
    if(this.date==undefined) this.date=null;
  }
  blur(event) {
    this.onBlur.emit(event);
  }
}