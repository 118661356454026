import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
  HostListener,
  ElementRef,
  OnChanges, ViewChild
} from '@angular/core';
import {MailSettingsService} from "../../../../features/users/data/mail-settings.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnDef, JBMTableColumnType, JBMTableComponent} from "../../../JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {MailSettings} from "../../../../features/users/data/interfaces/mail-settings";

@Component({
  selector: 'mail-settings-selector',
  templateUrl: './mail-settings.selector.component.html',
  styleUrls: ['./mail-settings.selector.component.scss']
})
export class MailSettingsSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  mail_settings: MailSettings=this.MailSettingsService.getEmpty();
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;
  @Input() editable: boolean=true;

  @Input() mail_settings_id: number=0;

  @Output() onSelect = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  @Input() template: TemplateRef<any>;

  @ViewChild('table') table: JBMTableComponent;

  constructor(
      private MailSettingsService: MailSettingsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.emailaddress'), sortable: true }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.mail_settings_id) this.setSettings();
  }
  toggleList() {
    if(!this.editable) return;
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.mail_settings_id=0;
    this.mail_settings=this.MailSettingsService.getEmpty();
    this.hideList();
    this.onSelect.emit(this.mail_settings);
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.MailSettingsService.get(params).subscribe(
        (data: any) => this.items = data.data,(error)=>console.error(error),()=> {
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.MailSettingsService.get(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  setSettings() {
    let params=new HttpParams().append('id', this.mail_settings_id.toString());
    this.MailSettingsService.get(params).subscribe(
        (data: any)=>this.mail_settings=data.data as MailSettings,
        (error)=>console.error(error)
    )
  }
  rowSelect(row) {
    this.mail_settings=row;
    this.mail_settings_id=row.id;
    this.onSelect.emit(this.mail_settings);
    this.hideList();
  }

  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }

  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
