import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from "../../../shared/shared.module";
import { DomainTablesModule} from "../tables/domain-tables.module";
import {ArticlePriceHistoryDropdownComponent} from "./ArticlePriceHistoryDropdown/ArticlePriceHistoryDropdown.component";
import { ContactDropdownComponent } from './ContactDropdown/ContactDropdown.component';

@NgModule({
  declarations: [ArticlePriceHistoryDropdownComponent, ContactDropdownComponent],
  imports: [CommonModule, SharedModule, DomainTablesModule],
	exports: [ArticlePriceHistoryDropdownComponent, ContactDropdownComponent]
})
export class DomainDropdownsModule { }
