import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {MimeTypesService} from "../../../../services/storage/mimetypes.service";

@Component({
  selector: 'jbm-document-viewer',
  templateUrl: './JBMDocumentViewer.component.html',
  styleUrls: ['./JBMDocumentViewer.component.scss']
})
export class JBMDocumentViewerComponent implements OnInit, OnChanges {
  mimeType: string;
  url: SafeResourceUrl;
  @Input() fileExtension: string;
  @Input() base64: string;

  constructor(
      private MimeTypesService: MimeTypesService,
      private DomSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.fileExtension==null) this.fileExtension='html';
    if(this.base64==null) this.base64='';
    this.mimeType=this.MimeTypesService.getMimetype(this.fileExtension);
    this.url='';
  }
  ngOnChanges() {
    this.mimeType=this.MimeTypesService.getMimetype(this.fileExtension);
    this.url=this.DomSanitizer.bypassSecurityTrustResourceUrl('data:'+this.mimeType+';base64,'+this.base64);
  }
}
