import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {FormGroupBaseComponent} from "../formgroupbase.component";

@Component({
  selector: 'jbm-text-input',
  templateUrl: './JBMTextInput.component.html',
  styleUrls: ['./JBMTextInput.component.scss']
})

export class JBMTextInputComponent extends FormGroupBaseComponent implements OnInit {
  @Input() type: string='text';
  @Input() maxlength: number=0;
  @Input() alignRight: boolean=false;
  @Output() onBlur = new EventEmitter();

  ngOnInit() {
    if(this.type==undefined) this.type='text';
    if(this.maxlength==undefined) this.maxlength=0;
    if(this.alignRight==undefined) this.alignRight=false;
    super.ngOnInit();
  }
  blur(event) {
    this.onBlur.emit(event);
  }
}