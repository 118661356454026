import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import {ResourcePrice} from "./interfaces/resource-price";

const baseURL: string = 'financial/';
const slug: string = 'resource-prices/';

@Injectable({
  providedIn: 'root'
})
export class ResourcePricesService {
  resource_price: ResourcePrice;

  constructor(private HttpService: HttpService) { }

  getData(resource_pricelists_id, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+resource_pricelists_id.toString(), params );
  }
  getOne(id) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id.toString() );
  }
  getHistory(language: string, id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+'history/'+language+'/'+id.toString(), params );
  }
  getEmpty() {
    this.resource_price = {
      id: 0,
      resource_pricelists_id: 0,
      resources_id: 0,
      valid_from: '',
      valid_to: '',
      price: 0
    }
    return this.resource_price;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
  calculatePrice(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/calculate-resource-price', params );
  }
  getDeletePermissions(ids: string[]) {
    return this.HttpService.get(baseURL+'read/resource-price-delete-permissions/'+ids.join());
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+slug+id);
  }
}
