<app-header>
    <jbm-view-title [caption]="'menu.customers'|translate" iconClassname="address-book" [itemcount]="queryHttpHelper.paginationProps.itemCount"></jbm-view-title>
    <div class="toolbar">
        <jbm-create-button [visible]="userRights.AllowCreate" (click)="addCustomer()"></jbm-create-button>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page" (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisible && !filtersVisible" [search]="queryHttpHelper.getSearchValue()"
          [minlength]="3" (searchChange)="searchChange($event)" [id]="'customer-search'">
        </jbm-searchbox>
        <div class="toolbar-spacer-1"></div>
        <div class="btn-group">
            <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
            <jbm-table-layout [caption]="'ui.columns-layout'|translate" [columnDefs]="columnDefs"
              (columnToggled)="saveLayout()">
            </jbm-table-layout>
        </div>
        <div class="toolbar-spacer-2 float-end"></div>
        <jbm-help-button [helpTopicID]="7"></jbm-help-button>
    </div>
</app-header>
<section class="module">
    <jbm-table #table
       [absolute]="true"
       [classname]="'customers padding'"
       [data]="customers"
       [filterable]="true"
       [showFilters]="filtersVisible"
       [searchMinLength]="3"
       [sortable]="true"
       [sortColumn]="queryHttpHelper.sortProps.name"
       [sortDirection]="queryHttpHelper.sortProps.direction"
       [hoverable]="true"
       [rowSelectable]="userRights.AllowRead"
       [columnDefs]="columnDefs"
       [actionDefs]="actionDefs"
       (rowCountChange)="rowCountChange($event)"
       (sortChange)="sortChange($event)"
       (filterChange)="filterChange($event)"
       (rowSelect)="rowSelect($event)"
       (actionClick)="action($event)"
       (onDropdownOpenChange)="dropdownOpenChanged($event)">
    </jbm-table>
</section>
<jbm-toasts></jbm-toasts>

<ng-template #blockedtemplate let-data="data">
    <span *ngIf="data.blocked" class="td-badge badge bg-danger">{{'ui.yes'|translate}}</span>
    <span *ngIf="!data.blocked" class="td-badge badge bg-success">{{'ui.no'|translate}}</span>
</ng-template>
<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
