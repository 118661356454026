import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";

const baseURL: string = 'invoicing/';
const segment: string = 'receipts/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceReceiptsService {

  constructor(private HttpService: HttpService) {}

  get(language: string, invoice_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment+'invoice/'+language+'/'+invoice_id.toString(), params );
  }
  getInvoiceable(language: string, project_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment+'invoiceable/'+language+'/'+project_id.toString(), params );
  }
  add(language: string, invoice_id: number, receipt_id: number) {
    return this.HttpService.get(baseURL+'create/'+segment+language+'/'+invoice_id.toString()+'/'+receipt_id.toString());
  }
  remove(id: number) {
    return this.HttpService.delete(baseURL+'delete/'+segment+id.toString());
  }
}