import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {fromEvent, merge, Subscription } from 'rxjs';
import {debounceTime} from "rxjs/operators";
import {KtdGridComponent, KtdGridLayout,  ktdTrackById} from "@katoid/angular-grid-layout";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  @ViewChild(KtdGridComponent, {static: true}) grid: KtdGridComponent;
  trackById = ktdTrackById;
  layout: KtdGridLayout = [
    {id: '0', x: 0, y: 0, w: 3, h: 4},
    {id: '1', x: 3, y: 0, w: 3, h: 4},
    {id: '2', x: 6, y: 0, w: 3, h: 4},
    {id: '3', x: 9, y: 0, w: 3, h: 4},
    {id: '4', x: 0, y: 0, w: 3, h: 4},
    {id: '5', x: 3, y: 0, w: 3, h: 4},
    {id: '6', x: 6, y: 0, w: 3, h: 4},
    {id: '7', x: 9, y: 0, w: 3, h: 4}
  ];

  private resizeSubscription: Subscription;

  constructor(@Inject(DOCUMENT) public document: Document) { }

  ngOnInit(): void {
    this.resizeSubscription = merge(
        fromEvent(window, 'resize'),
        fromEvent(window, 'orientationchange')
    ).pipe(
        debounceTime(50)
    ).subscribe(() => {
      this.grid.resize();
    });
  }
  onLayoutUpdated(layout: KtdGridLayout) {
    this.layout = layout;
  }
  /** Removes the item from the layout */
  removeItem(id: string) {
    // Important: Don't mutate the array. Let Angular know that the layout has changed creating a new reference.
    this.layout = ktdArrayRemoveItem(this.layout, (item) => item.id === id);
  }
  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
}
/**
 * Removes and item from an array. Returns a new array instance (it doesn't mutate the source array).
 * @param array source array to be returned without the element to remove
 * @param condition function that will return true for the item that we want to remove
 */
export function ktdArrayRemoveItem<T>(array: T[], condition: (item: T) => boolean) {
  const arrayCopy = [...array];
  const index = array.findIndex((item) => condition(item));
  if (index > -1) {
    arrayCopy.splice(index, 1);
  }
  return arrayCopy;
}