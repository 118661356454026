import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { LoginComponent} from "./login/login.component";
import { UsersRoutingModule } from './users-routing.module';
import { GroupsRightsComponent } from './datatable/groupsrights.component';
import { GroupRightsComponent } from './datatable/grouprights/grouprights.component';
import { UsersComponent } from './datatable/users.component';
import { UsersManagementComponent } from './users.management.component';
import { UsergroupsComponent } from './datatable/usergroups.component';
import { UsergroupComponent } from './form/usergroup.component';
import { UserComponent } from './form/user.component';
import { ActivityLogComponent } from "./activity-log/activity-log.component";
import { PasswordConstraintsAlertComponent } from './password/password-constraints-alert/password-constraints-alert.component';
import { PasswordChangeComponent } from "./password/password-change/password-change.component";
import { MailSettingsComponent} from "./mail-settings/mail-settings.component";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [LoginComponent, UsersManagementComponent, UsersComponent, UsergroupsComponent, UsergroupComponent, GroupsRightsComponent,
	  GroupRightsComponent, UserComponent, ActivityLogComponent, PasswordConstraintsAlertComponent, PasswordChangeComponent,
	  MailSettingsComponent],
    imports: [CommonModule, SharedModule, UsersRoutingModule, JBMButtonsModule, JBMTableModule, JBMViewModule, JBMFormModule, JBMModalModule, LayoutModule],
  exports: []
})
export class UsersModule { }
