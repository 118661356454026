import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {InvoicesService} from "../invoices.service";
import {Invoice} from "../interfaces/invoice";
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../../services/auth/token.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class InvoiceResolver implements Resolve<any> {
  language: string=this.TokenService.getLanguage();

  constructor(
      private Router: Router,
      private InvoicesService: InvoicesService,
      private TokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Invoice> {
    let id=parseInt(route.paramMap.get('invoice_id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      // Get the invoice inclusive related data
      let params=new HttpParams()
          .append('customer','1')
          .append('project','1')
          .append('permissions','1')
      // Get the invoice
      return this.InvoicesService.getOne(this.language, id, params).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
