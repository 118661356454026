<form [formGroup]="form" (ngSubmit)="save()" class="article_pricelist" xmlns="http://www.w3.org/1999/html">
    <app-header>
        <jbm-crud-title [subject]="'helpcentre.helptopic'" [action]="action"></jbm-crud-title>
        <div class="float-end toolbar action-buttons">
            <jbm-save-button subject="{{'helpcentre.helptopic'|translate}}" (click)="save()"></jbm-save-button>
            <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
        </div>
    </app-header>
    <section class="module bg-light padding padding-top border-top">
        <jbm-static-label
          [labelClass]="'width-9'" [value]="parentTopic.title" [labelCaption]="'ui.group'|translate">
        </jbm-static-label>
        <jbm-text-input [formControl]="formControls.title" [submitted]="isSubmitted" [id]="'title'"
          [maxlength]="125" required="true" labelCaption="{{'entity.title'|translate}}"
          labelClass="width-9" class="mt-3 mb-3 input-36">
            <jbm-input-error [formControl]="formControls.title" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-text-input>
        <div class="form-group row">
            <label class="col-form-label width-9 mr-2">
                {{'helpcentre.helptopic-content'|translate}} <span class="asterix">*</span>
            </label>
            <div class="float-start width-36">
               <!-- <quill-editor [ngModelOptions]="{standalone: true}" [(ngModel)]="content"></quill-editor>-->
                <angular-editor [ngModelOptions]="{standalone: true}" [(ngModel)]="content" [config]="config"></angular-editor>
            </div>
        </div>
    </section>
</form>

