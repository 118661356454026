<h6>{{'invoice.mandatory-articles'|translate}}</h6>
<table [hidden]="articles.length==0" class="table table-sm table-hover padding invoice-articles">
   <thead>
   <tr>
      <th class="description">{{'entity.description'|translate}}</th>
      <th class="display">{{'article.display'|translate}}</th>
      <th class="pricetype">{{'article.pricetype'|translate}}</th>
      <th class="price align-right">{{'article.price'|translate}}</th>
      <th class="amount align-right">{{'order.amount'|translate}}</th>
      <th class="totalprice align-right">{{'ui.totalprice'|translate}}</th>
      <th></th>
   </tr>
   </thead>
   <tbody>
   <ng-container *ngFor="let article of articles">
      <tr>
         <td [ngClass]="{editable: article.editing}"><label class="value">{{article.name}}</label></td>
         <td [ngClass]="{editable: article.editing}"><label class="value">{{article.code}}</label></td>
         <td class="align-right" [ngClass]="{editable: article.editing}"><label class="value">{{article.price|money}}</label></td>
         <td *ngIf="article.editing" class="editable"><input class="form-control form-control-sm amount" type="text" autocomplete="off" [(ngModel)]="amount"
           (change)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
            <div *ngIf="amountError" class="invalid-feedback">{{amountError}}</div>
         </td>
         <td *ngIf="!article.editing" class="align-right"><label class="value">{{article.amount}}</label></td>
         <td *ngIf="!article.editing" class="align-right"><label class="value">{{article.totalPrice|money}}</label></td>
         <td *ngIf="article.editing" class="align-right editable"><label class="value">{{totalPrice|money}}</label></td>
         <td *ngIf="!article.editing" class="action-buttons align-right">
            <button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm mr-2" (click)="startEditing(article.id)"><i class="far fa-edit"></i></button>
         </td>
         <td *ngIf="editable" class="editable action-buttons">
            <button type="button" class="btn btn-sm btn-primary mr-1"
              [disabled]="!validateArticle() || !validateAmount()" (click)="updateItem()">{{'ui.save'|translate}}</button>
            <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
         </td>
      </tr>
   </ng-container>
   </tbody>
</table>
<jbm-empty-view [emptyView]="articles.length==0"></jbm-empty-view>

