import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {displayFormat} from "./article-price-types.service";

const BaseURL: string = 'financial/';
const URL: string = 'articlegroups/';

@Injectable({
  providedIn: 'root'
})
export class ArticlegroupsService {

  constructor(private HttpService: HttpService) {}

  get(language: string) {
    return this.HttpService.get(BaseURL+'read/'+URL+language );
  }
  create(data: any) {
    return this.HttpService.post(BaseURL+'create/'+URL, data);
  }
  update(data: any) {
    return this.HttpService.post(BaseURL+'update/'+URL, data);
  }

  // Articlegroup options

  getSelectOptions( articlegroups: any[], insertEmptyOption: boolean=true ): JBMSelectOption[] {
    let options: JBMSelectOption[]=insertEmptyOption ? [{key:'0',value:''}] : [];
    for(let ag=0; ag<articlegroups.length; ag++) {
      let articlegroup=articlegroups[ag];
      options.push( { key: articlegroup.id.toString(), value: articlegroup.name } );
    }
    return options;
  }
}
