import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {Recipe} from "./interfaces/recipe";

const baseURL: string = 'recipes/';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  recipe: Recipe;

  constructor(private HttpService: HttpService) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+language, params );
  }
  getEmpty() {
    this.recipe = {
      id: 0,
      description: '',
      internal_description: '',
      code: '',
      consistencies_id: null,
      strengths_id: null,
      revision: '',
    }
    return this.recipe;
  }
  getOne(id: number, language: string='', params: HttpParams=null) {
    if(!language)
      return this.HttpService.get(baseURL+'read/one/'+id.toString(), params );
    else
      return this.HttpService.get(baseURL+'read/one/'+id.toString()+'/'+language, params );
  }
  getBaseRecipe(params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/base-recipe', params );
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/data', data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/data', data);
  }
  setPublished(id: number, published: boolean) {
    return this.HttpService.post(baseURL+'update/published',{ recipe_id: id, published: published} );
  }

  // Service functions
  environmentsToString(environments: any[]): string {
    let result='';
    for(let environment of environments)
      result+=environment.description+', ';
    return result.substr(0,result.length-2 );
  }
}
