<div class="template-placeholder-picker">
   <div class="header">
      <h5 class="float-start">{{'presentations.placeholders'|translate}} {{'template-editor.insert'|translate|lowercase}} </h5>
      <jbm-cross-close-button class="float-end" (onClick)="close()"></jbm-cross-close-button>
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
         <ng-container ngbNavItem [ngbNavItem]="0">
            <a ngbNavLink>{{'entity.data'|translate}}</a>
            <ng-template ngbNavContent>
               <div *ngFor="let entity of placeholders" class="placeholder">
                  <h6 class="entity mt-2">{{entity.translationKey|translate}}</h6>
                  <table class="table table-xs table-hover">
                     <tr *ngFor="let placeholder of entity.placeholders">
                        <td class="placeholder">
                           <button type="button" class="btn btn-xs btn-outline-primary"
                             (click)="pickData(entity.name+placeholder.name)"><i class="fa fa-caret-left"></i> {{entity.name+placeholder.name}}</button>
                        </td>
                        <td class="description">{{placeholder.description}}</td>
                     </tr>
                  </table>
               </div>
            </ng-template>
         </ng-container>
         <ng-container ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink>{{'ui.texts'|translate}}</a>
            <ng-template ngbNavContent>
               <table class="table table-xs table-hover">
                  <tr *ngFor="let textPlaceholder of textPlaceholders">
                     <td class="placeholder">
                        <button type="button" class="btn btn-xs btn-outline-primary"
                          (click)="pickText(textPlaceholder.name)"><i class="fa fa-caret-left"></i> {{textPlaceholder.name}}</button>
                     </td>
                     <td class="description">{{textPlaceholder.description}}</td>
                  </tr>
               </table>
            </ng-template>
         </ng-container>
         <ng-container ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink>{{'entity.images'|translate}}</a>
            <ng-template ngbNavContent>
               <div *ngFor="let image of images; let i=index">
                  <div class="image">
                     <div class="image-container bg-secondary">
                        <img [src]="getSafeImageURL(i)" [alt]="image.filename">
                     </div>
                     <button type="button" class="btn btn-xs btn-outline-primary"
                       (click)="pickImage(image)">
                        <i class="fa fa-caret-left"></i> image{{image.id}}
                     </button>
                     <div class="description">{{image.description}}</div>
                  </div>
               </div>
            </ng-template>
         </ng-container>
      </nav>
   </div>
   <div class="body">
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
   </div>
</div>
