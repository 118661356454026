import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {MediaFilesService} from "../../data/media_files.service";
import {UploadSettingsService} from "../../../../services/storage/uploads/upload-settings.service";
import {Base64UploadService} from "../../../../services/storage/uploads/base64-upload.service";
import {MediaFile} from "../../data/interfaces/media_file";

@Component({
  selector: 'form-media-file',
  templateUrl: './mediafile.component.html',
  styleUrls: ['./mediafile.component.scss']
})
export class MediaFileComponent implements OnInit, AfterViewInit {
  action: CRUDAction;
  form: FormGroup;
  error: string;
  requiredFileType: string;
  fileName: string;
  uploadInfo: any;
  allowedExtensions: string;

  submitted: boolean=false;

  @Input() mediaFile: MediaFile;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private MediaFilesService: MediaFilesService,
      private UploadSettingsService: UploadSettingsService,
      private Base64UploadService: Base64UploadService,
      private formBuilder: FormBuilder
  ) {
    this.mediaFile = this.MediaFilesService.getEmpty();
    this.addControls();
  }

  ngOnInit(): void {
    this.allowedExtensions='';
    this.uploadInfo={ maxSize: 0 };
    this.error='';
    if(this.mediaFile.id===0)
      this.action = CRUDAction.Create;
    else
      this.action = CRUDAction.Update;

    // Get upload settings info
    this.UploadSettingsService.get().subscribe(
        (data)=>this.uploadInfo=data,
        ()=>{},
        ()=> {
          this.allowedExtensions=this.uploadInfo.allowedExtensions.join(', ');
          this.setControlValues();
        }
    );
  }
  ngAfterViewInit(): void {
    document.getElementById('description').focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      description: new FormControl('',{validators: [Validators.required]} ),
      filename: new FormControl('',{validators: [Validators.required]} ),
    },{updateOn: "blur"});
  }
  setControlValues() {
    this.form.controls.description.setValue(this.mediaFile.description);
    this.form.controls.filename.setValue(this.mediaFile.filename);
  }
  get formControls() {
    return this.form.controls;
  }
  // Mediafile upload
  onFileSelected(event) {
    const file:File = event.target.files[0];
    if(file) this.upload(file);
  }
  upload(file) {
    this.error='';

    this.fileName=file.name;
    const formData = new FormData();
    formData.append("file", file);

    this.Base64UploadService.upload(formData).subscribe(
      (data)=> {
        this.mediaFile.content=data.base64;
        this.mediaFile.extension=data.extension;
        this.mediaFile.size=data.size;
        this.form.controls.filename.setValue( this.removeExtension(file.name));
      },
      (error)=> this.handleUploadError(error)
    )
  }
  handleUploadError(error) {
    switch(error) {
      case 'no_file_uploaded':
        this.error=this.TranslateService.GetTranslation('ui.upload-no-file');
        break;
      case 'not_allowed_extension':
        this.error=this.TranslateService.GetTranslation('ui.upload-extension-not-allowed');
        break;
      case 'exceeded_filesize':
        this.error=this.TranslateService.GetTranslation('ui.upload-exceeded_filesize');
        break;
    }
  }
  removeExtension(fileName): string {
    let lastSeparator=fileName.lastIndexOf('.');
    if(lastSeparator===-1)
      return fileName;
    let extension=fileName.substring(lastSeparator, fileName.length);
    if(this.uploadInfo.allowedExtensions.indexOf(extension.toLowerCase().replace('.',''))>-1)
      fileName = fileName.substring(0, lastSeparator);
    return fileName;
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    // Validate content
    if(this.mediaFile.content==='') {
      this.error = this.TranslateService.GetTranslation('ui.upload-no-file');
      return;
    }

    let filename=this.removeExtension(this.formControls.filename.value);
    if(!filename) {
      this.formControls.filename.setErrors({required: true});
      return;
    }
    this.formControls.filename.setValue(filename);

    // Store media file
    this.mediaFile.description = this.formControls.description.value;
    this.mediaFile.filename = this.formControls.filename.value;
    if(this.mediaFile.id===0) {
      this.MediaFilesService.create(this.mediaFile).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    } else {
      this.MediaFilesService.update(this.mediaFile).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    }
  }
  handleError(error) {
    switch(error) {
      case 'description_not_unique':
        this.formControls.description.setErrors({notUnique: true});
        document.getElementById('description').focus();
        break;
      case 'filename_not_unique':
        this.formControls.filename.setErrors({notUnique: true});
        document.getElementById('filename').focus();
        break;
    }
  }
  cancel() {
    this.onCancel.emit();
  }
}
