<table class="permissions table table-xs">
   <thead>
   <tr>
      <th>{{'ui.feature'|translate}}</th>
      <th>{{'users.permissions'|translate}}</th>
   </tr>
   </thead>
   <tbody>
   <tr *ngFor="let entity of permissions">
      <td>{{entity.name}}</td>
      <td>
         <div *ngFor="let action of entity.actions" class="check">
            <input id="permission-{{entity.name}}-{{action}}" type="checkbox" [checked]="isChecked(entity.name, action)" (click)="togglePermission($event)">
            <label for="permission-{{entity.name}}-{{action}}">{{action}}</label>
         </div>
      </td>
   </tr>
   </tbody>
</table>
<jbm-toasts></jbm-toasts>
