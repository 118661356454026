import { Injectable } from '@angular/core';
import {JBMSelectOption} from '../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';

const apps=[
  { id: 0, description: 'Management' },
  { id: 1, description: 'Receptuur' },
]

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  get() {
    return apps;
  }
  getSelectOptions(insertEmptyOption: boolean = true): JBMSelectOption[] {
    let options: JBMSelectOption[] = insertEmptyOption ? [{key: '', value: ''}] : [];
    for(let app of apps) {
      options.push({key: app.id.toString(), value: app.description});
    }
    return options;
  }
}
