import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {QuotationsService} from "../../data/quotations.service";

@Component({
  selector: 'quotation-negotiate',
  templateUrl: './quotation-negotiate.component.html',
  styleUrls: ['./quotation-negotiate.component.scss']
})
export class QuotationNegotiateComponent implements OnInit {
  form: FormGroup;

  constructor(
      private formBuilder: FormBuilder,
      private QuotationsService: QuotationsService,
  ) {
    this.addControls();
  }

  @Input() quotation_revision_id: number;
  @Input() quotationNumber: string;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  ngOnInit(): void {
    this.addControls();
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          comments: new FormControl('' ),
        }
    );
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    let data={
      quotation_revision_id: this.quotation_revision_id,
      comments: this.formControls.comments.value
    }
    this.QuotationsService.negotiate(data).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=>this.onSuccess.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
