import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'financial/';
const slug: string = 'environments/';

@Injectable({
  providedIn: 'root'
})
export class ConcreteEnvironmentsService {

  constructor(private HttpService: HttpService) { }

  getData(language, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+language, params );
  }
  getOne(id, language) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id+'/'+language );
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
}
