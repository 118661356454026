import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {Observable} from 'rxjs';
import {HttpParams} from "@angular/common/http";

const readURL: string = 'userrights/read/grouprights/';
const updateURL: string = 'userrights/update/grouprights/';

@Injectable({
  providedIn: 'root'
})
export class GrouprightsService {

  constructor(private HttpService: HttpService) {}

  getData(app_id: number=0, usergroup_id: number=0): Observable<any> {
    let params=new HttpParams();
    let filter='r.app eq '+app_id.toString();
    if(usergroup_id)
      filter+=' and gr.group_id eq '+usergroup_id.toString();
      params=params.append('filter', filter);
    return this.HttpService.get(readURL, params);
  }
  update(data: any): Observable<any> {
    return this.HttpService.post(updateURL, data);
  }
}
