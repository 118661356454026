import {Component, OnInit, AfterViewInit} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location} from "@angular/common";
import { StateService } from "../../../../services/storage/state.service";
import { ArticlegroupsService} from "../../data/articlegroups.service";
import { ArticlesService } from "../../data/articles.service";
import { ArticlePriceTypesService} from "../../data/article-price-types.service";
import { TranslateService} from "../../../../services/multilingual/translate.service";
import { TokenService} from "../../../../services/auth/token.service";
import { forkJoin, Subscription} from "rxjs";
import { JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import { Article} from "../../data/interfaces/article";
import { JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'article-form',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.scss']
})

export class ArticleComponent implements OnInit, AfterViewInit {
  article: Article;
  caption: string;
  language: string;
  articlegroups: any[]=[];
  articlegroup_options: any[]=[];
  pricetypes: any[]=[];
  pricetype_options: any[]=[];
  displayOptions: JBMSelectOption[]=[];
  form: FormGroup;
  firstinput: any;
  isSubmitted: boolean=false;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private Router: Router,
    private Location: Location,
    private StateService: StateService,
    private formBuilder: FormBuilder,
    private ArticlegroupsService: ArticlegroupsService,
    private ArticlesService: ArticlesService,
    private ArticlePriceTypesService: ArticlePriceTypesService,
    private TokenService: TokenService,
    private TranslateService: TranslateService,
    private JBMToastsService: JBMToastsService,
  ) {
    this.caption=this.TranslateService.GetTranslation('financial.article');
    if(this.Router.url.indexOf('create')>0) {
      // Get empty article data
      this.article=this.ArticlesService.getEmpty();
      this.caption+=' '+this.TranslateService.GetTranslation('ui.insert').toLowerCase();
    } else {
      // The route resolver has fetched the article data
      this.article=this.ActivatedRoute.snapshot.data.article.data;
      this.caption+=' '+this.TranslateService.GetTranslation('ui.edit').toLowerCase();
    }
  }
  ngOnInit(): void {
    this.language=this.TokenService.getLanguage();
    this.addControls();

    this.displayOptions=this.ArticlesService.getDisplaySelectOptions(this.TranslateService);

    let agObservable=this.ArticlegroupsService.get(this.language);
    let ptObservable=this.ArticlePriceTypesService.keyValueList(this.language);
    forkJoin([agObservable, ptObservable]).subscribe(results => {
      this.articlegroups=results[0].data;
      this.articlegroup_options=this.ArticlegroupsService.getSelectOptions(this.articlegroups, false);
      this.pricetypes=results[1].data;
      this.pricetype_options=this.ArticlePriceTypesService.getSelectOptions(this.pricetypes);
      this.setControlValues();
    });
  }
  ngAfterViewInit(): void {
    this.firstinput=document.getElementById('name');
    this.firstinput.focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      id: new FormControl(),
      name: new FormControl('', [Validators.required]),
      description: new FormControl('',[Validators.required]),
      articlegroups_id: new FormControl('',[Validators.required]),
      article_price_types_id: new FormControl('',[Validators.required]),
      mandatory: new FormControl(false ),
      display:new FormControl('',[Validators.required]),
      reference_id: new FormControl('')
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.reference_id.setValue(this.article.reference_id);
    this.form.controls.articlegroups_id.setValue(
        this.article.article_groups_id>0 ? this.article.article_groups_id.toString() : '');
    this.form.controls.article_price_types_id.setValue(
        this.article.article_price_types_id>0 ? this.article.article_price_types_id.toString() : '');
    this.form.controls.name.setValue(this.article.name);
    this.form.controls.description.setValue(this.article.description);
    this.form.controls.display.setValue(this.article.display);
    this.form.controls.mandatory.setValue(this.article.mandatory);
  }
  changeArticlegroup(e) {
    this.article.article_groups_id = parseInt(e.target.value);
  }
  changePriceType(e) {
    this.article.article_price_types_id = parseInt(e.target.value);
  }
  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    let data = {
      id: this.article.id,
      article_groups_id: this.article.article_groups_id,
      article_price_types_id: this.article.article_price_types_id,
      display: parseInt(this.formControls.display.value),
      reference_id: this.formControls.reference_id.value,
      mandatory: this.formControls.mandatory.value,
      l: {
        name: this.formControls.name.value,
        language: this.language,
        description: this.formControls.description.value
      }
    }

    if(data.id==0)
      this.ArticlesService.create(data).subscribe(
          data => {}, error => this.handleError(error),() => this.afterSave())
    else
      this.ArticlesService.update(data).subscribe(
          data => {}, error => this.handleError(error),() => this.afterSave())
  }
  handleError(error) {
    if(error=='name_not_unique') {
      this.formControls.name.setErrors({'name_not_unique': true});
      let input=this.firstinput=document.getElementById('name');
      input.focus();
    } else
      console.error(error);
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.redirect();
  }
  cancel() {
    this.redirect();
  }
  redirect() {
    this.Location.back();
  }
}