<app-header [module_header]="asModule">
    <div class="float-start" [ngClass]="{'toolbar': !asModule }" [class.float-start]="asModule" >
        <jbm-view-title *ngIf="asModule" [caption]="('entity.resource'|translate)+' '+('financial.pricelists'|translate|lowercase)"
          [itemcount]="queryHttpHelper.paginationProps.itemCount"
          [parentLinkCaption]="'menu.settings'|translate"
          [parentLinkSlug]="'settings'">
        </jbm-view-title>
        <jbm-view-title *ngIf="!asModule" [caption]="('entity.resource'|translate)+' '+('financial.pricelists'|translate|lowercase)"
          [itemcount]="queryHttpHelper.paginationProps.itemCount">
        </jbm-view-title>
        <jbm-create-button [visible]="userRights.AllowCreate" (click)="addItem()"></jbm-create-button>
    </div>
    <div class="toolbar ml-4 float-start">
        <label *ngIf="plantOptions.length>1">{{'company.plant'|translate}}</label>
        <select *ngIf="plantOptions.length>1" (change)="changePlant($event)" class="form-control form-control-sm form-select">
            <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
        </select>
    </div>
    <div class="toolbar float-end">
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination
          [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox
          [id]="'search-box'"
          [visible]="searchboxVisible && !filtersVisible"
          [search]="search"
          (searchChange)="searchChange($event)">
        </jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-filter-toggle
          [showFilter]="filtersVisible"
          (onToggle)="toggleFilters($event)">
        </jbm-filter-toggle>
        <div class="toolbar-spacer-1 float-end"></div>
    </div>
</app-header>
<section [ngClass]="{'module': asModule}">
    <jbm-table #table
       [absolute]="asModule"
       [classname]="tableClassname"
       [data]="items"
       [filterable]="true"
       [showFilters]="filtersVisible"
       [sortable]="true"
       [sortColumn]="queryHttpHelper.sortProps.name"
       [sortDirection]="queryHttpHelper.sortProps.direction"
       [hoverable]="true"
       [rowSelectable]="userRights.AllowRead"
       [columnDefs]="columnDefs"
       (rowCountChange)="rowCountChange($event)"
       (sortChange)="sortChange($event)"
       (filterChange)="filterChange($event)"
       (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>
<ng-template #usesTemplate let-data="data">
    <button [hidden]="data.deletePermission || data.type==0" class="btn btn-light btn-sm float-start" (click)="showUses(data.id); $event.stopPropagation()">
        <i class="fa fa-info"></i> {{'ui.uses'|translate}}
    </button>
</ng-template>
<ng-template #actionsTemplate let-data="data">
    <jbm-confirm-delete-dropdown *ngIf="userRights.AllowDelete && data.deletePermission" class="float-end ml-2"
      [placement]="'left-top'"
      [subject]="('financial.pricelist'|translate)"
      (onConfirm)="delete(data.id)">
    </jbm-confirm-delete-dropdown>
    <div *ngIf="userRights.AllowUpdate" ngbDropdown container="body" class="float-end">
        <button class="actions btn btn-sm btn-light" id="articleListActions" ngbDropdownToggle (click)="$event.stopPropagation();">
            <i class="fa fa-ellipsis-h"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="articleListActions">
            <button class="dropdown-item" [ngbTooltip]="('entity.resource'|translate)+' '+('financial.pricelists'|translate|lowercase)+'...'"
              (click)="copy(data.id); $event.stopPropagation()">{{'ui.copy'|translate}}...
            </button>
            <div class="dropdown-divider"></div>
            <button class="dropdown-item" (click)="connectToCustomers(data.id); $event.stopPropagation()">
                {{'ui.connect-to'|translate}} {{'entity.customers'|translate|lowercase}}...
            </button>
            <button class="dropdown-item" (click)="assignToProjects(data.id); $event.stopPropagation()">
                {{'ui.connect-to'|translate}} {{'entity.projects'|translate|lowercase}}...
            </button>
        </div>
    </div>
    <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-primary float-end mr-2" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
      (click)="edit(data.id); $event.stopPropagation()"><i class="far fa-edit"></i>
    </button>
</ng-template>
<jbm-modal [id]="'resource-pricelist'" [className]="'pricelist'"></jbm-modal>
<ng-template #pricelistModal let-modal>
    <form-resource-pricelist [pricelist]="pricelist" [customer_id]="customer_id" (onSave)="pricelistSaved()" (onCancel)="pricelistCancelled()"></form-resource-pricelist>
</ng-template>
<jbm-modal [id]="'copy-pricelist'" [className]="'copy-resource-pricelist'"></jbm-modal>
<ng-template #copyModal let-modal>
    <form-copy-resource-pricelist [pricelist]="pricelist" [customer_id]="customer_id" (onSave)="copySaved()" (onCancel)="copyCancelled()"></form-copy-resource-pricelist>
</ng-template>
<jbm-modal [id]="'uses'" [className]="'uses'"></jbm-modal>
<ng-template #usesModal let-modal>
    <resource-pricelist-uses [pricelist]="pricelist" (onClose)="closeUses()"></resource-pricelist-uses>
</ng-template>
<jbm-modal [id]="'connect'" [className]="'connect-pricelist'"></jbm-modal>
<ng-template #connectCustomersModal let-modal>
    <resource-pricelist-connect-customers [pricelist]="pricelist" (onConnected)="connected()" (onCancel)="cancelConnect()"></resource-pricelist-connect-customers>
</ng-template>
<jbm-modal [id]="'assign'" [className]="'assign-projects'"></jbm-modal>
<ng-template #assignProjectsModal let-modal>
    <resource-pricelist-assign-projects [pricelist]="pricelist" (onAssigned)="projectsAssigned()" (onCancel)="projectsAssignCancel()"></resource-pricelist-assign-projects>
</ng-template>
<jbm-toasts></jbm-toasts>
