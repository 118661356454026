import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service"

export enum CRUDAction { 'Create', 'Read', 'Update','Delete'};

@Component({
  selector: 'jbm-crud-title',
  templateUrl: './JBMCRUDTitle.component.html',
  styleUrls: ['./JBMCRUDTitle.component.scss']
})
export class JBMCRUDTitleComponent implements OnInit, OnChanges {
  language: string='';
  caption: string;

  @Input() subject: string='';
  @Input() action: CRUDAction;

  constructor(private TokenService: TokenService, private TranslateService: TranslateService) {
    this.language=this.TokenService.getLanguage();
  }
  ngOnInit(): void {
    if(this.subject==undefined) this.subject='';
    if(this.action==undefined) this.action=CRUDAction.Create;
  }
  ngOnChanges() {
    let action='';
    switch(this.action) {
      case CRUDAction.Create:
        action=this.TranslateService.GetTranslation('ui.insert');
        break;
      case CRUDAction.Read:
        action=this.TranslateService.GetTranslation('ui.details');
        break;
      case CRUDAction.Update:
        action=this.TranslateService.GetTranslation('ui.edit');
        break;
      case CRUDAction.Delete:
        action=this.TranslateService.GetTranslation('ui.delete');
        break;
    }
    let subject=this.TranslateService.GetTranslation(this.subject);
    switch(this.language) {
      case 'en':
        if(this.action===CRUDAction.Read)
          this.caption=subject+' '+action.toLowerCase();
        else
          this.caption=action+' '+subject.toLowerCase();
        break;
      default:
        this.caption=subject+' '+action.toLowerCase();
        break;
    }
  }
}
