import { Injectable } from '@angular/core';
import {AbstractControl, ValidatorFn} from "@angular/forms";

const date_separator='-';
const time_separator=':';

@Injectable({
  providedIn: 'root'
})

export class DatetimeService {
  date: Date;
  constructor() { this.date=new Date(); }

  // Get the day as a number (1-31)
  getCurrentDay() {
    return this.date.getDate();
  }
  // Get the weekday as a number (0-6)
  getCurrentWeekday(): number {
    return this.date.getDay();
  }
  // Get the month as a number (1-12)
  getCurrentMonth(): number {
    return this.date.getMonth() + 1;
  }
  // Get the four digit year (yyyy)
  getCurrentYear(): number {
    return this.date.getFullYear();
  }

  // Get the hour as a number (0-59)
  getCurrentHour(): number {
    return this.date.getHours();
  }
  // Get the minute as a number (0-59)
  getCurrentMinute(): number {
    return this.date.getMinutes();
  }

  // Get current date in yyyy-mm-dd format
  getCurrentDateYMD(): string {
    return this.getDateYMDFromParts(this.getCurrentYear(), this.getCurrentMonth(), this.getCurrentDay());
  }
  // Get current date in dd-mm-yyyy format
  getCurrentDateDMY(): string {
    return this.getDateDMYFromParts(this.getCurrentDay(), this.getCurrentMonth(), this.getCurrentYear());
  }

  // Validates given date string (date format=dd-mm-yyyy or dd/mm/yyyy)
  validateDateStr( date: string ): boolean {
    // Validate the date format through regular expression
    let format=/^(0[1-9]|[12][0-9]|3[01])[-](0[1-9]|1[012])[-](19|20|21)\d\d$/;
    if(!format.test(date)) return false;
    // Validate day, month en year parts
    let parts = this.splitDate(date,'dmy');
    if(parts===false) return false;

    return this.validateDateParts(parts.day, parts.month, parts.year);
  }
  validateDateParts( day: number, month: number, year: number ): boolean {
    // Create list of days of a month [assume there is no leap year by default]
    let days = [31,28,31,30,31,30,31,31,30,31,30,31];
    if(month===1 || month>2) {
      if(day>days[month-1]) return false;
    }
    if(month===2) {
      let leapyear=( (!(year % 4) && year % 100) || !(year % 400) );
      if(!leapyear && (day>=29)) return false;
      if(leapyear && (day>29)) return false;
    }
    return true;
  }

  getDatePartsDMY( date: string ): { day: number, month: number, year: number } {
    let parts=this.splitDate(date,'dmy');
    if(parts===false) return { day: 0, month: 0, year: 0 }
    return parts;
  }

  getDatePartsYMD( date: string ): { day: number, month: number, year: number } {
    let parts=this.splitDate(date,'ymd');
    if(parts===false) return { day: 0, month: 0, year: 0 }
    return parts;
  }

  getDateYMDFromParts( year: number, month: number, day: number ): string {
    if(!this.validateDateParts(day, month, year)) return '';
    return this.formatDate({day: day, month: month, year: year} );
  }

  getDateDMYFromParts( day: number, month: number, year: number ): string {
    if(!this.validateDateParts(day, month, year)) return '';
    return this.formatDate({day: day, month: month, year: year },'dmy' );
  }

  dateDMYToYMD( date: string ): string {
    let parts=this.splitDate(date,'dmy');
    if(parts===false) return '';
    return this.getDateYMDFromParts(parts.year, parts.month, parts.day);
  }

  dateYMDToDMY( date: string ): string {
    let parts=this.splitDate(date,'ymd');
    if(parts===false) return '';
    return this.getDateDMYFromParts(parts.day, parts.month, parts.year);
  }

  formatDate( parts, dateFormat: string='ymd'): string {
    dateFormat=dateFormat.toLowerCase();
    if((dateFormat!=='ymd') && (dateFormat!=='dmy')) return '';

    let year=parts.year.toString();
    let month=parts.month.toString();
    if(month.length<2) month='0'+month;
    let day=parts.day.toString();
    if(day.length<2) day='0'+day;

    switch(dateFormat) {
      case 'dmy': return day+date_separator+month+date_separator+year;
      default: return year+date_separator+month+date_separator+day;
    }
  }

  getUnixTimeFromDateParts( parts ): number {
    let date=this.formatDate(parts);
    return Math.round(new Date(date).getTime());
  }

  dateStrFromUnixTime( time: number ): string {
    let date=new Date(time);
    let str=new Intl.DateTimeFormat('en-GB').format(date);
    return str.split('/').join('-');  // Replace '/' with '-'
  }

  timeStrFromUnixTime( time: number, includeSeconds: boolean=false ): string {
    let date=new Date(time * 1000);
    let hours=date.getHours();
    let hoursStr=hours.toString();
    if(hours<10) hoursStr='0'+hoursStr;
    let minutes=date.getMinutes();
    let minutesStr=minutes.toString();
    if(minutes<10) minutesStr='0'+minutesStr;
    let seconds=date.getSeconds();
    let secondsStr=seconds.toString();
    if(seconds<10) secondsStr='0'+secondsStr;
    return hoursStr+':'+minutesStr+(includeSeconds ? ':'+secondsStr : '');
  }

  private splitDate( date: string, dateFormat: string='ymd' ): { day: number, month: number, year: number } | false {
    if(date.length!=10) return false;
    let parts=date.split(date_separator);
    if(parts.length<3) return false;
    if(dateFormat=='ymd') return { day: parseInt(parts[2]), month: parseInt(parts[1]), year: parseInt(parts[0]) };
    if(dateFormat=='dmy') return { day: parseInt(parts[0]), month: parseInt(parts[1]), year: parseInt(parts[2]) };
  }

  // Date object conversions

  getDateToYMD(date: Date) {
    let month=(date.getMonth()+1).toString() //getMonth is zero based;
    while(month.length<2) month='0'+month;
    let day=date.getDate().toString();
    while(day.length<2) day='0'+day;
    return date.getFullYear().toString()+date_separator+month+date_separator+day;
  }

  getDateToDMY(date: Date) {
    let month=(date.getMonth()+1).toString() //getMonth is zero based;
    while(month.length<2) month='0'+month;
    let day=date.getDate().toString();
    while(day.length<2) day='0'+day;
    return day+date_separator+month+date_separator+date.getFullYear().toString();
  }

  getDateToTimeStr(date: Date) {
    let hours=date.getHours().toString();
    while(hours.length<2) hours='0'+hours;
    let minutes=date.getMinutes().toString();
    while(minutes.length<2) minutes='0'+minutes;
    return hours+time_separator+minutes;
  }

  dateValidator(DatetimeService: DatetimeService, required: boolean=true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if(!required && control.value.trim()==='')
        return null;
      let valid=(control.value && control.value.length==10) &&
          (DatetimeService.validateDateStr(control.value)!==false);
      if(!valid) return {'dateInvalid': true};
      return null;
    }
  }

  // Times

  getTimeParts( time: string ): { hours: number, minutes: number, seconds: number } {
    let timeParts={ hours: 0, minutes: 0, seconds: 0 };
    if(typeof time!=='string' || time.length<5) return timeParts;
    let parts=time.split(time_separator);
    if(parts.length<2) return timeParts;
    timeParts.hours=parseInt(parts[0]);
    timeParts.minutes=parseInt(parts[1]);
    if(parts.length===3) timeParts.seconds=parseInt(parts[2]);
    return timeParts;
  }

  getTimeRegEx(includeSeconds: boolean=false) {
    if(!includeSeconds)
      // Represents hh:mm format
      return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    else
      // Represents hh:mm:ss format
      return /(?:[01]\d|2[0123]):(?:[012345]\d):(?:[012345]\d)/;
  }
}
