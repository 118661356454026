<app-header>
    <jbm-view-title [caption]="'financial.article-prices'|translate"
       [itemcount]="queryHttpHelper.paginationProps.itemCount"
       [subCaption]="articlePricelist.description"
      [parentLinkCaption]="parentLinkCaption"
       [parentLinkSlug]="parentLinkRoute"></jbm-view-title>
    <jbm-create-button [visible]="userRights.AllowCreate && available" (click)="addItem()"></jbm-create-button>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisible && !filtersVisible" [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module">
    <jbm-table-header className="bg-light pl-4">
        <jbm-properties-list [properties]="priceListProps" className="inline bg-light"></jbm-properties-list>
    </jbm-table-header>
    <jbm-table #table
      [id]="'article-prices'"
      [classname]="'article-prices padding'"
      [absolute]="true"
      [data]="items"
      [filterable]="true"
      [showFilters]="filtersVisible"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="userRights.AllowUpdate"
      [columnDefs]="columnDefs"
      [actionDefs]="actionDefs"
      (rowCountChange)="rowCountChange($event)"
      (actionClick)="action($event)"
      (sortChange)="sortChange($event)"
      (filterChange)="filterChange($event)"
      (rowSelect)="rowSelect($event)">
    </jbm-table>

    <jbm-modal [id]="'article-price'" [className]="'article-price'"></jbm-modal>
    <ng-template #modalContent let-modal>
        <form-article-price [userRights]="userRights" [id]="id" [details]="details" [article_pricelist]="articlePricelist" [language]="language" (onSave)="saved()" (onCancel)="canceled()"></form-article-price>
    </ng-template>
</section>

<ng-template #actionsTemplate let-data="data">
    <jbm-confirm-delete-dropdown *ngIf="userRights.AllowDelete && !data.deletePermission" class="float-end ml-2"
      [placement]="'left-bottom'"
      [subject]="('financial.article-price'|translate)"
      (onConfirm)="delete(data.id)">
    </jbm-confirm-delete-dropdown>
    <article-price-history-dropdown [subject]="0" [price_id]="data.id" [placement]="'left'"></article-price-history-dropdown>
    <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-primary float-end mr-2" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
      (click)="rowSelect(data); $event.stopPropagation()"><i class="far fa-edit"></i>
    </button>
</ng-template>

<jbm-toasts></jbm-toasts>
