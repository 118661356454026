import { Injectable } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {Driver} from "./interfaces/driver";

const baseURL: string = 'logistics/';
const segment: string = 'drivers/'

@Injectable({
  providedIn: 'root'
})
export class DriversService {
  driver: Driver;

  constructor(private HttpService: HttpService) { }

  get(params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment, params);
  }
  getEmpty() {
    this.driver = {
      id: 0,
      name: '',
      active: true
    }
    return this.driver;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
}
