import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {Customer} from "../../../features/customers/data/interfaces/customer";
import {CustomerService} from "../../../features/customers/data/customer.service";
import {SettingsService} from "../../../services/storage/settings.service";
import {GoogleMapsService} from "../../../services/google-maps/google-maps.service";
import {GPSCoordinates} from "../../../components/JBM/Helpers/GPSInfo";
import {setting_subjects} from "../../../services/storage/settings.service";
import {Event, NavigationEnd} from "@angular/router";

@Component({
  selector: 'customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {
  data: any;
  settings: any;
  shift_vat_setting: boolean=false;
  resources_based_invoicing: boolean=false;
  GPS_address: GPSCoordinates={longitude: '', latitude: ''};
  GPS_invoice_address: GPSCoordinates={longitude: '', latitude:''};

  constructor(
      private Router: Router,
      private CustomerService: CustomerService,
      private GoogleMapsService: GoogleMapsService,
      private SettingsService: SettingsService
  ) {}

  @Input() customer: Customer=this.CustomerService.getEmpty();
  @Input() inModal: boolean;

  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    // Refresh data when customer details modal outlet closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && event.url.indexOf('customers/details')>-1)
        setTimeout(()=>this.getSettings(),1000);
    });

    if(this.inModal==undefined) this.inModal=false;

    this.getSettings();

    // Get GPS Location of address
    this.GoogleMapsService.getLookup(
        this.customer.address_details.street,
        this.customer.address_details.no,
        this.customer.address_details.postcode,
        this.customer.address_details.city).subscribe(
        (data)=> this.GPS_address=data,(error)=>console.error(error),()=>{}
    );

    // Get GPS Location of invoice address if set
    if(this.customer.invoice_address_id==0)
      return;
    this.GoogleMapsService.getLookup(
        this.customer.invoice_address_details.street,
        this.customer.invoice_address_details.no,
        this.customer.invoice_address_details.postcode,
        this.customer.invoice_address_details.city).subscribe(
        (data)=> this.GPS_invoice_address=data,(error)=>console.error(error),()=>{}
    );
  }
  getSettings() {
    this.SettingsService.getSubject(0, setting_subjects.customer, this.customer.id).subscribe(
        (data)=>this.settings=data.settings,
        (error)=>console.error(error),
        ()=> {
          if(this.settings) {
            if(this.settings.hasOwnProperty('shift_vat'))
              this.shift_vat_setting=this.settings.shift_vat;
            if(this.settings.hasOwnProperty('resources_based_invoicing'))
              this.resources_based_invoicing=this.settings.resources_based_invoicing;
          }
        }
    );
  }
  close() {
    this.onClose.emit();
  }
}
