import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { TextPlaceholder } from "./interfaces/text_placeholder";

const baseURL: string = 'presentations/';
const segment: string = 'text-placeholders/';

@Injectable({
  providedIn: 'root'
})
export class TextPlaceholdersService {
  textPlaceholder: TextPlaceholder;

  constructor(
      private HttpService: HttpService,
  ) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language, params );
  }
  getOne(language: string, id :number) {
    return this.HttpService.get(baseURL+'read/one/'+segment+language+'/'+id.toString(),null );
  }
  getEmpty() {
    this.textPlaceholder = {
      id: 0,
      name: '',
      language: '',
      description: '',
      text: ''
    }
    return this.textPlaceholder;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  getDeletePermissions(ids: string[]) {
    return this.HttpService.get(baseURL+'read/text-placeholder-delete-permissions/'+ids.join());
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+id.toString());
  }
}
