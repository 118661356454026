import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayouts } from "../../app.component";
import {DashboardComponent} from "./dashboard.component";
import {AuthGuardService} from "../../services/auth/authguard.service";

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
