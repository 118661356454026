<div class="table-responsive">
    <table class="table table-sm table-row-selectable table-hover order-articles">
    <thead>
        <tr>
            <th class="description">{{'entity.description'|translate}}</th>
            <th class="amount align-right">{{'order.amount'|translate}}</th>
            <th *ngIf="showPrices" class="amount align-right">{{'article.price'|translate}}</th>
            <th *ngIf="showPrices" class="amount align-right">{{'ui.total'|translate}}</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let article of articles">
            <td>
                {{article.description}}
                <span *ngIf="article.usage==0" class="badge bg-light text-dark ml-2">{{'entity.recipe'|translate}}{{'financial.article'|translate|lowercase}}</span>
            </td>
            <td class="align-right">{{article.amountStr}}</td>
            <td *ngIf="showPrices" class="align-right">{{article.price|money}}</td>
            <td *ngIf="showPrices" class="align-right">{{article.totalPrice|money}}</td>
        </tr>
        </tbody>
    </table>
</div>
<jbm-empty-view [emptyView]="articles.length==0"></jbm-empty-view>
