import {Component, OnInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {UsersService} from "../data/users.service";
import {Subscription} from "rxjs";
import {User} from "../data/interfaces/user";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {
  JBMTableActionDef,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {TranslateService} from "../../../services/multilingual/translate.service";

@Component({
  selector: 'recipe-users',
  templateUrl: './recipe-users.component.html',
  styleUrls: ['./recipe-users.component.scss']
})
export class RecipeUsersComponent implements OnInit, OnDestroy {
  user: User;
  users: any[]=[];

  queryHttpHelper: QueryHttpHelper;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];

  data$: Subscription;

  constructor(
      private UserService: UsersService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService,
      private TranslateService: TranslateService
  ) {
    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('username');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'username', header: this.TranslateService.GetTranslation('login.username') },
      { name: 'audience', header: this.TranslateService.GetTranslation('entity.customer') },
      { name: 'permissions', type: JBMTableColumnType.panelToggle, header: this.TranslateService.GetTranslation('users.permissions'), width: 9 }
    ];

    this.actionDefs = [ { name: 'edit', iconClass: 'far fa-edit' } ];

    this.getData();
  }

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('userModal') modalTemplate: ElementRef;

  ngOnInit(): void {
  }
  getData() {
    this.data$=this.UserService.get().subscribe((data)=>this.users=data.data,(error)=>console.log(error));
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  rowSelect(event) {
    this.table.toggleRowExpanded(event);
  }
  add() {
    this.user=this.UserService.getEmpty();
    this.ModalService.open(this.modalTemplate,'user');
  }
  userSaved() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.ModalService.close('user');
    this.getData();
  }
  userCanceled() {
    this.ModalService.close('user');
  }
  edit(action: any) {
    this.UserService.getOne(action.row.id).subscribe(
        (data)=>this.user=data.data as User,(error)=>console.error(error),()=>{
          this.ModalService.open(this.modalTemplate,'user');
        }
    )
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.data$!=undefined) this.data$.unsubscribe();
  }
}
