import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";

@Component({
  selector: 'jbm-toggle-button',
  templateUrl: './JBMToggleButton.component.html',
  styleUrls: ['./JBMToggleButton.component.scss'],
  host: {'class': 'JBMButton'}
})

export class JBMToggleButtonComponent implements OnInit, OnChanges {
  language: string='';
  tooltip: string='';

  @Input() caption: string='';
  @Input() toggled: boolean=false;
  @Input() small: boolean=false;
  @Input() class: string;

  @Output() onToggle = new EventEmitter();

  constructor(private TokenService: TokenService, private TranslateService: TranslateService) {
    this.language=this.TokenService.getLanguage();
  }
  ngOnInit(): void {
    if(this.class==undefined) this.class='outline-primary';
    if(this.small==undefined) this.small=false;
    this.setTooltip();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.setTooltip();
  }
  setTooltip() {
    if(this.toggled)
      this.tooltip=this.TranslateService.GetTranslation('ui.hide-verb');
    else
      this.tooltip=this.TranslateService.GetTranslation('ui.show-verb');
  }
  click() {
    this.toggled=!this.toggled;
    this.setTooltip();
    this.onToggle.emit(this.toggled);
  }
}
