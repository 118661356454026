import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'jbm-filter-toggle',
  templateUrl: './JBMFilterToggle.component.html',
  styleUrls: ['./JBMFilterToggle.component.scss'],
  host: {'class': 'JBMButton'}
})
export class JBMFilterToggleComponent implements OnInit {

  @Input() showFilter: boolean=false;
  @Input() class: string;
  @Output() onToggle = new EventEmitter();

  ngOnInit() {
    if(this.class==undefined) this.class='light';
  }
  toggleFilter(event) {
    this.onToggle.emit(event.value);
  }
}
