import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ProductionReceiptsService} from "../../data/production-receipts.service";
import {ProjectstatesService, WorkflowStates} from "../../../projects/data/projectstates.service";
import {OrdersService} from "../../../projects/data/orders.service";
import {OrderPresenter} from "../../../projects/presenters/order.presenter";
import {TokenService} from "../../../../services/auth/token.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";

@Component({
  selector: 'synchronise-order',
  templateUrl: './synchronise-order.component.html',
  styleUrls: ['./synchronise-order.component.scss']
})
export class SynchroniseOrderComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  receipt_ids: string='';

  project_id: number=0;
  projectstates: any[]=[];
  projectstate_ids: string='';

  orders: any[]=[];
  order_id: number=0;
  modal_order_id: number=0;

  constructor(
      private ProductionReceiptsService: ProductionReceiptsService,
      private ProjectstatesService: ProjectstatesService,
      private OrdersService: OrdersService,
      private OrderPresenter: OrderPresenter,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private ModalService: ModalService
  ) {}

  @Input() receipts: any[]=[];

  @Output() onSave=new EventEmitter();
  @Output() onClose=new EventEmitter();

  @ViewChild('modalOrderDetails') modalOrderDetails: ElementRef;

  ngOnInit(): void {
    this.receipt_ids=this.receipts.join(', ');

    this.ProjectstatesService.get(this.language).subscribe(
        (data: any)=>this.projectstates=data.data,(error)=>console.error(error),
        ()=> {
          // Project selector: Only projects in planning and in progress states
          for(let projectstate of this.projectstates)
            if(projectstate.workflow_state===WorkflowStates.planned || projectstate.workflow_state===WorkflowStates.inProgress)
              this.projectstate_ids += projectstate.id.toString() + ',';
          this.projectstate_ids=this.projectstate_ids.substring(0,this.projectstate_ids.length-1);
        }
    )
  }
  selectProject(project) {
    this.project_id=project.id;
    this.getOrders();
  }
  getOrders() {
    this.orders=[];
    this.OrdersService.get(this.project_id, this.language).subscribe(
        (data)=>this.orders=data.data,(error)=>console.error(error),
        ()=> {
          for(let order of this.orders)
            order=this.OrderPresenter.format(order);
        }
    )
  }
  selectOrder(order_id) {
    this.order_id=order_id;
  }
  save() {
    let data= { receipts: this.receipts, order_id: this.order_id }
    this.ProductionReceiptsService.synchronise(data).subscribe(
      ()=>this.onSave.emit(),(error)=> { console.error(error); this.onSave.emit() }
    )
    this.onSave.emit();
  }
  close() {
    this.onClose.emit();
  }
  // Order
  showOrder(id: number) {
    this.modal_order_id=id;
    this.ModalService.open(this.modalOrderDetails,'orderDetails')
  }
  closeOrder() {
    this.ModalService.close('orderDetails');
  }
}
