<app-header [module_header]="asModule">
    <jbm-view-title [caption]="'menu.quotations'|translate" iconClassname="handshake"
      [itemcount]="queryHttpHelper.paginationProps.itemCount">
    </jbm-view-title>
    <div class="toolbar float-start">
        <jbm-create-button [visible]="userRights.AllowCreate" [className]="'float-start'" (click)="create()"></jbm-create-button>
        <div class="toolbar-spacer-2"></div>
        <jbm-quotationstate-dropdown
          [quotationStates]="quotationstates"
          [quotationState]="quotationstate"
          (quotationStateChanged)="setQuotationstate($event)">
        </jbm-quotationstate-dropdown>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
                        [pageSize]="queryHttpHelper.paginationProps.pageSize"
                        [page]="queryHttpHelper.paginationProps.page"
                        (pageClick)="pageChange($event)" [hidden]="!initialCount">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisble && !filtersVisible" [search]="search" [minlength]="3" [id]="'search-box'"
                       (searchChange)="searchChange($event)" [hidden]="!initialCount">
        </jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <div class="btn-group">
            <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)" [hidden]="!initialCount"></jbm-filter-toggle>
            <jbm-table-layout [caption]="'ui.columns-layout'|translate"
              [columnDefs]="columnDefs" (columnToggled)="saveLayout()"></jbm-table-layout>
        </div>
        <div *ngIf="asModule" class="toolbar-spacer-2 float-end"></div>
        <jbm-help-button *ngIf="asModule" [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section id="projects-table" [ngClass]="{'module': asModule, 'padding': !asModule }">
    <jbm-table #table [classname]="'projects padding'"
      [data]="items"
      [absolute]="asModule"
      [filterable]="true"
      [showFilters]="filtersVisible"
      [searchMinLength]="3"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="true"
      [columnDefs]="columnDefs"
      [actionDefs]="actionDefs"
      [template]="templateRevisions"
      (rowCountChange)="rowCountChange($event)"
      (sortChange)="sortChange($event)"
      (filterChange)="filterChange($event)"
      (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>

<jbm-toasts></jbm-toasts>

<ng-template #templateQuotationstate let-data="data">
    <span class="td-badge badge badge-pill" style="background-color: {{data.color}};color: {{data.text_color}}">
        {{data.quotationstate}}
    </span>
</ng-template>

<ng-template #templateCustomer let-data="data">
    <jbm-link-button [caption]="('entity.customer'|translate)+' '+('ui.details'|translate|lowercase)" [showIcon]="false" (onClick)="showCustomer(data.customers_id)"></jbm-link-button>
    <span *ngIf="data.blocked" class="pl-0 pt-1 blocked float-start">
       <span [ngbTooltip]="'entity.blocked'|translate" class="text-danger"><i class="fa fa-exclamation-triangle"></i></span>
    </span>
</ng-template>

<ng-template #templateProject let-data="data">
    <jbm-link-button *ngIf="data.projects_id" (onClick)="showProject(data.projects_id)" [caption]="data.project" [showIcon]="false"></jbm-link-button>
</ng-template>

<ng-template #templateAmount let-data="data">
    <span class="float-end" *ngIf="data.concrete_amount">{{data.concrete_amount}} {{'quantity.m3'|translate}}</span>
</ng-template>

<ng-template #templateContacts let-data="data">
    <quotation-contacts [quotation_id]="data.quotations_id" [project_id]="data.projects_id" [class]="'btn-block'"></quotation-contacts>
</ng-template>

<ng-template #revisionsTemplate let-data="data">
    <button *ngIf="data.revision_count" class="btn btn-light btn-block btn-sm" (click)="toggleRevisions(data);$event.stopPropagation()">
        {{'quotation.revisions'|translate}}
        <span class="badge bg-secondary ml-2">{{data.revision_count}}</span>
    </button>
</ng-template>

<ng-template #templateRevisions let-data="data">
    <div class="p-3 bg-light-darker">
      <quotation-revisions [quotation_id]="data.id" [revision_number]="data.revision_number"></quotation-revisions>
    </div>
</ng-template>

<ng-template #templatePreview let-data="data">
    <a *ngIf="data.concrete_total" class="btn btn-sm btn-link" target="_blank" [ngbTooltip]="'PDF '+('ui.preview'|translate|lowercase)"
      [href]="'quotation-preview/'+data.revision_id" (click)="$event.stopPropagation()">
        <i class="far fa-file-pdf"></i> <i class="fa fa-external-link-alt ml-1"></i>
    </a>
</ng-template>

<ng-template #actionsTemplate let-data="data">
    <div ngbDropdown container="body" [placement]="'left'">
        <button class="actions btn btn-sm btn-light" id="quoActions" ngbDropdownToggle (click)="$event.stopPropagation();">
            <i class="fa fa-ellipsis-h"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="quoActions">
            <button *ngIf="userRights.AllowUpdate && data.allowApprove" ngbDropdownItem (click)="approve(data)">{{'entity.approve'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowDraft" ngbDropdownItem (click)="draft(data)">{{'entity.make-concept'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowSend" ngbDropdownItem (click)="send(data)">{{'entity.send'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowNegotiate" ngbDropdownItem (click)="negotiate(data)">{{'quotation.in-negotiation'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowAccept" ngbDropdownItem (click)="accept(data)">{{'ui.accept'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowCancel" ngbDropdownItem (click)="cancel(data)">{{'ui.cancel'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowReject" ngbDropdownItem (click)="reject(data)">{{'ui.reject'|translate}}...</button>
            <div *ngIf="userRights.AllowUpdate && data.allowApprove || userRights.AllowUpdate && data.allowDraft || userRights.AllowUpdate && data.allowSend ||
                userRights.AllowUpdate && data.allowNegotiate || userRights.AllowUpdate && data.allowAccept || userRights.AllowUpdate && data.allowCancel ||
                userRights.AllowUpdate && data.allowReject" class="dropdown-divider">
            </div>
            <button ngbDropdownItem (click)="showStateFlow(data)"><i class="fa fa-history"></i> {{'quotation.state'|translate}} {{'entity.history'|translate|lowercase}}</button>
            <button *ngIf="userRights.AllowDelete" ngbDropdownItem (click)="remove(data)">{{'ui.delete'|translate}}...</button>
        </div>
    </div>
</ng-template>

<jbm-modal [id]="'statesFlow'" [className]="'statesFlow'"></jbm-modal>
<ng-template #modalStatesFlow let-modal>
    <quotation-states-flow [language]="language" [revision_id]="revision_id" [quotationNumber]="quotationNumber" (onClose)="statesFlowClose()"></quotation-states-flow>
</ng-template>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
    <project-details [project_id]="id" (onClose)="closeProject()"></project-details>
</ng-template>

<jbm-modal [id]="'stateAction'" [className]="'state-action'"></jbm-modal>
<ng-template #modalApprove let-modal>
    <quotation-approve [quotation_revision_id]="revision_id" [quotationNumber]="quotationNumber" (onSuccess)="approveSuccess()" (onClose)="stateActionClose()"></quotation-approve>
</ng-template>

<ng-template #modalDraft let-modal>
    <quotation-draft [quotation_revision_id]="revision_id" [quotationNumber]="quotationNumber" (onSuccess)="draftSuccess()" (onClose)="stateActionClose()"></quotation-draft>
</ng-template>

<jbm-modal [id]="'quotationSend'" [className]="'content quotation-send'"></jbm-modal>
<ng-template #modalSend let-modal>
    <quotation-send [quotation]="quotation" (onSuccess)="sendSuccess()" (onClose)="sendClose()"></quotation-send>
</ng-template>

<ng-template #modalNegotiate let-modal>
    <quotation-negotiate [quotation_revision_id]="revision_id" [quotationNumber]="quotationNumber" (onSuccess)="negotiateSuccess()" (onClose)="stateActionClose()"></quotation-negotiate>
</ng-template>

<ng-template #modalAccept let-modal>
    <quotation-accept [quotation_revision_id]="revision_id" [quotationNumber]="quotationNumber" (onSuccess)="acceptSuccess()" (onClose)="stateActionClose()"></quotation-accept>
</ng-template>

<ng-template #modalCancel let-modal>
    <quotation-cancel [quotation_revision_id]="revision_id" [quotationNumber]="quotationNumber" (onSuccess)="cancelSuccess()" (onClose)="stateActionClose()"></quotation-cancel>
</ng-template>

<ng-template #modalReject let-modal>
    <quotation-reject [quotation_revision_id]="revision_id" [quotationNumber]="quotationNumber" (onSuccess)="rejectSuccess()" (onClose)="stateActionClose()"></quotation-reject>
</ng-template>

<ng-template #modalRemove let-modal>
    <quotation-remove [quotation_id]="quotation.id" [quotationNumber]="quotationNumber" (onRemove)="removeSuccess()" (onClose)="stateActionClose()"></quotation-remove>
</ng-template>

