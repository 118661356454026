<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'financial.resource-pricelist'|translate}} {{'ui.uses'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <div class="jbm-properties-list uses">
      <div class="item">
         <label class="label">
            {{'financial.resource-pricelist'|translate}}:
         </label>
         <label class="value">
            {{pricelist.description}}
         </label>
      </div>
      <div class="item">
         <label class="label">
            {{'pricelist.type'|translate}}:
         </label>
         <label class="value">
            {{types[pricelist.type].value}}
         </label>
      </div>
   </div>
   <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="panel-1">
      <ngb-panel [id]="'panel-1'" [disabled]="uses.customers.length==0">
         <ng-template ngbPanelTitle>
            <div class="float-start pl-1">{{'entity.customers'|translate}}
               <span *ngIf="uses.customers.length" class="badge badge-pill bg-secondary">{{uses.customers.length}}</span>
               <span *ngIf="!uses.customers.length" class="badge badge-pill bg-secondary">{{'ui.empty'|translate}}</span>
            </div>
         </ng-template>
         <ng-template ngbPanelContent>
            <span class="text-muted pl-1">({{'ui.click-item-open'|translate}})</span>
            <div class="uses-table-container">
               <table class="table table-xs table-borderless uses">
                  <tr *ngFor="let customer of uses.customers">
                     <td><a href="customers/details/{{customer.id}}">{{customer.name}}</a></td>
                     <td class="action">
                        <jbm-confirm-delete-dropdown
                          [placement]="'bottom'"
                          [subject]="('entity.customer'|translate)+' '+('ui.connection'|translate|lowercase)+' '+('ui.delete'|translate|lowercase)"
                          (onConfirm)="confirmDisconnectCustomer(customer.id)">
                        </jbm-confirm-delete-dropdown>
                     </td>
                  </tr>
               </table>
            </div>
         </ng-template>
      </ngb-panel>
      <ngb-panel [id]="'panel-2'" [disabled]="uses.projects==0">
         <ng-template ngbPanelTitle>
            <div class="float-start pl-1">{{'entity.projects'|translate}}
               <span *ngIf="uses.projects.length" class="badge badge-pill bg-secondary">{{uses.projects.length}}</span>
               <span *ngIf="!uses.projects.length" class="badge badge-pill bg-secondary">{{'ui.empty'|translate}}</span>
            </div>
         </ng-template>
         <ng-template ngbPanelContent>
            <span class="text-muted pl-1">({{'ui.click-item-open'|translate}})</span>
            <div class="uses-table-container">
               <table class="table table-xs table-borderless uses">
                  <tr *ngFor="let project of uses.projects">
                     <td><a href="projects/{{project.id}}">{{project.name}}</a></td>
                     <td class="action">
                        <jbm-confirm-delete-dropdown
                          [placement]="'left'"
                          [subject]="('entity.project'|translate)+' '+('ui.connection'|translate|lowercase)+' '+('ui.delete'|translate|lowercase)"
                          (onConfirm)="confirmDisconnectProject(project.id)">
                        </jbm-confirm-delete-dropdown>
                     </td>
                  </tr>
               </table>
            </div>
         </ng-template>
      </ngb-panel>
   </ngb-accordion>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button class="light" (click)="close()"></jbm-close-button>
   </div>
</div>
