import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from "@angular/common";
import {StateService} from "../../../services/storage/state.service";
import {ProjectsService} from "../data/projects.service";
import {Project} from "../data/interfaces/project";
import {ProjectstatesService} from "../data/projectstates.service";
import {AddressesService} from "../../addresses/data/addresses.service";
import {ArticlePricelistsService} from "../../financial/data/article-pricelists.service";
import {ResourcePricelistsService} from "../../financial/data/resource-pricelists.service";
import {PlantsService} from "../../company/data/plants.service";
import {CustomerService} from "../../customers/data/customer.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";
import {ValidationPatternsService} from "../../../services/validation/patterns.service";
import {forkJoin} from "rxjs";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {Address} from "../../addresses/data/interfaces/address";
import {Plant} from "../../company/data/interfaces/Plant";
import {GoogleMapsService} from "../../../services/google-maps/google-maps.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {setting_subjects, settings, SettingsService} from "../../../services/storage/settings.service";
import {GeoSegment, OpenRouteService} from "../../../services/openroute/openroute.service";
import {EmptyGPSAddress, GPSAddress} from "../../../components/JBM/Helpers/GPSInfo";
import {HttpParams} from "@angular/common/http";
import {CRUDAction} from "../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'project-form',
  templateUrl: './project-form.component.html',
  styleUrls: ['./project-form.component.scss']
})

export class ProjectFormComponent implements OnInit {
  action: CRUDAction=CRUDAction.Create;
  id: number=0;
  project: Project=this.ProjectsService.getEmpty();
  forQuotation: boolean=false;
  userRights: GroupRights;
  editable: boolean=false;
  editing: boolean=true;
  language: string=this.TokenService.getLanguage();
  caption: string;
  address: Address;
  plants: Plant[]=[];
  plant: Plant;
  plantOptions: JBMSelectOption[]=[];
  projectstates: any[]=[];
  projectstatesOptions: JBMSelectOption[]=[];
  GPS_address: GPSAddress=EmptyGPSAddress();
  geoSegment: GeoSegment;
  resources_based_invoicing: boolean=false;
  warning: string='';

  stateError: string='';
  customerError: string='';
  error_lonlat: string='';

  form: FormGroup;
  isSubmitted: boolean=false;

  data: any;

  @ViewChild('modalProject') modalProject: ElementRef;

  constructor(
    private Router: Router,
    private Location: Location,
    private ActivatedRoute: ActivatedRoute,
    private StateService: StateService,
    private SanitizationService: SanitizationService,
    private ValidationPatternsService: ValidationPatternsService,
    private formBuilder: FormBuilder,
    private ModalService: ModalService,
    private ProjectsService: ProjectsService,
    private ProjectstatesService: ProjectstatesService,
    private AddressesService: AddressesService,
    private PlantsService: PlantsService,
    private CustomerService: CustomerService,
    public  ArticlePricelistsService: ArticlePricelistsService,
    public  ResourcePricelistsService: ResourcePricelistsService,
    public  TranslateService: TranslateService,
    private TokenService: TokenService,
    private SettingsService: SettingsService,
    private GoogleMapsService: GoogleMapsService,
    private JBMToastsService: JBMToastsService,
    private OpenRouteService: OpenRouteService
  ) {
    this.project=this.ProjectsService.getEmpty();
    this.project.address=this.AddressesService.getEmpty();

    if(this.ActivatedRoute.snapshot.url[1].path==='create') {
      // Is this new project assigned to a customer ?
      let element=this.StateService.getElement('customerID');
      if(element!==false) {
        this.project.customer_id=element.data;
        this.StateService.deleteElement('customerID');
      }

      // Is this new project for a quotation ?
      element=this.StateService.getElement('forQuotation');
      if(element!==false) {
        this.forQuotation=true;
        this.StateService.deleteElement('forQuotation');
      }
    }

    if(this.ActivatedRoute.snapshot.url[1].path!=='create') {
      this.id=parseInt(this.ActivatedRoute.snapshot.paramMap.get('id'));
      if(isNaN(this.id)) this.close();
      this.action=CRUDAction.Update;
    }

    this.userRights=this.TokenService.getRightsByName('projects');
    this.editable=this.userRights.AllowCreate || this.userRights.AllowUpdate;

    // Exit if user has not sufficient rights
    if(!this.editable)
      this.close();

    this.addControls();
  }
  ngOnInit(): void {
    if(this.id) {
      let params=new HttpParams().append('state','1').append('address','1')
          .append('plant', '1').append('customer','1');
      this.ProjectsService.getOne(this.id, this.language, params).subscribe(
        (data)=>this.project=data.data,
        (error)=> {
          console.error(error);
          this.close();
        },
        ()=> {
          let setting=false;
          this.SettingsService.getSetting(0, setting_subjects.project, this.project.id, settings.resources_based_invoicing).subscribe(
              (data)=>setting=data.setting,
              (error)=>console.error(error),
              ()=> {
                this.resources_based_invoicing=setting===null ? false: setting;
                this.setControlValues();
              }
          );

          this.initData();
        }
      )
    } else
      this.initData();

    setTimeout(()=>this.ModalService.open(this.modalProject,'project'),100);
    setTimeout(()=>document.getElementById('name').focus(),500);
  }
  initData() {
    let psObservable=this.ProjectstatesService.get(this.language);
    let plObservable=this.PlantsService.getData(null);
    forkJoin([psObservable, plObservable]).subscribe(results => {
      this.projectstates=results[0].data;

      if(this.action===CRUDAction.Create && !this.forQuotation) {
        // Only potential project state allowed
        let projectState=this.ProjectstatesService.filterPotentialState(this.projectstates);
        if(projectState) {
          this.project.projectstates_id=projectState.id;
          this.projectstates=[];
          this.projectstates.push(projectState);
        }
      }

      if(this.forQuotation) {
        // Only quotation project state allowed
        let projectState=this.ProjectstatesService.filterQuotationState(this.projectstates);
        if(projectState) {
          this.project.projectstates_id=projectState.id;
          this.projectstates=[];
          this.projectstates.push(projectState);
        }
      }

      this.plants=results[1].data;
      this.setControlValues();
    });
  }
  addControls() {
    this.form = this.formBuilder.group({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(''),
      projectstates_id: new FormControl('',[Validators.required]),
      plants_id: new FormControl(''),
      customer_id: new FormControl(''),
      article_pricelists_id: new FormControl(0),
      concrete_pricelists_id: new FormControl(0),
      resource_pricelists_id: new FormControl(0),
      city : new FormControl(''),
      street: new FormControl(''),
      no: new FormControl(''),
      postcode: new FormControl('',[Validators.pattern('[1-9][0-9]{3}[\\s]?[A-Za-z]{2}')]),
      lon: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      lat: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      distance: new FormControl('',[Validators.pattern("^[0-9]*$")]),
      traveltime: new FormControl('',[Validators.pattern("^[0-9]*$")]),
      comments: new FormControl(''),
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.projectstatesOptions=this.ProjectstatesService.getSelectOptions(this.projectstates);
    this.plantOptions=this.PlantsService.getSelectOptions(this.plants);

    this.form.controls.name.setValue(this.project.name);
    this.form.controls.description.setValue(this.SanitizationService.emptyIfNull(this.project.description));
    this.form.controls.projectstates_id.setValue(this.project.projectstates_id);
    this.form.controls.plants_id.setValue(this.project.plants_id===null ? '' : this.project.plants_id.toString());
    this.form.controls.customer_id.setValue(this.project.customer_id===null ? 0 : this.project.customer_id);
    this.form.controls.concrete_pricelists_id.setValue(this.project.concrete_pricelists_id===null ? 0 : this.project.concrete_pricelists_id);
    this.form.controls.article_pricelists_id.setValue(this.project.article_pricelists_id===null ? 0 : this.project.article_pricelists_id);
    this.form.controls.resource_pricelists_id.setValue(this.project.resource_pricelists_id===null ? 0 : this.project.resource_pricelists_id);
    this.form.controls.city.setValue(this.project.address===null ? '' : this.project.address.city);
    this.form.controls.street.setValue(this.project.address===null ? '' : this.project.address.street);
    this.form.controls.no.setValue(this.project.address===null ? '' : this.project.address.no);
    this.form.controls.postcode.setValue(this.project.address===null ? '' : this.project.address.postcode);
    this.form.controls.lon.setValue(this.project.address===null ? '' : this.project.address.lon );
    this.form.controls.lat.setValue(this.project.address===null ? '' : this.project.address.lat);
    this.form.controls.distance.setValue(this.project.distance===null ? '' : this.project.distance.toString());
    this.form.controls.traveltime.setValue(this.project.traveltime===null ? '' : this.project.traveltime.toString());
    this.form.controls.comments.setValue(this.SanitizationService.emptyIfNull(this.project.comments));
    this.updateAddress();
  }
  setCustomer(event) {
    this.formControls.customer_id.setValue(event.id);
    this.customerError='';
  }
  setConcretePricelist(event) {
    this.formControls.concrete_pricelists_id.setValue(event.id);
  }
  setArticlePricelist(event) {
    this.formControls.article_pricelists_id.setValue(event.id);
  }
  setResourcePricelist(pricelist) {
    this.form.controls.resource_pricelists_id.setValue(pricelist.id);
  }
  setResourcesBasedInvoicing(event) {
    this.resources_based_invoicing=event.target.checked;
  }
  updateAddress() {
    if(!this.formControls.street.value && !this.formControls.no.value)
      return;
    this.GoogleMapsService.getLookup( this.formControls.street.value,
        this.formControls.no.value,this.formControls.postcode.value, this.formControls.city.value,true)
        .subscribe(
            (data)=>this.GPS_address=data,
            (error)=>{console.error(error)},
            ()=>{
              this.autoCompleteAddress();
              this.updateRouteInfo();
            }
        );
  }
  autoCompleteAddress() {
    if(this.GPS_address.street) this.formControls.street.setValue(this.GPS_address.street);
    if(this.GPS_address.postcode) this.formControls.postcode.setValue(this.GPS_address.postcode);
    if(this.GPS_address.city) this.formControls.city.setValue(this.GPS_address.city);
    if(this.GPS_address.no) this.formControls.no.setValue(this.GPS_address.no);
    if(this.GPS_address.latitude && this.GPS_address.longitude) {
      this.formControls.lat.setValue(this.GPS_address.latitude);
      this.formControls.lon.setValue(this.GPS_address.longitude);
    } else {
      this.formControls.lat.setValue('');
      this.formControls.lon.setValue('');
      this.form.controls.distance.setValue('');
      this.form.controls.traveltime.setValue('');
    }
  }
  updateRouteInfo() {
    if(!this.form.controls.plants_id.value) return;
    if(!this.GPS_address.latitude) return;

    let plants_id=parseInt(this.form.controls.plants_id.value);
    if(plants_id===0) return;
    this.PlantsService.getOne(plants_id).subscribe(
        (data)=>this.plant=data.data,
        (error)=>console.error(error),
        ()=> {
          this.OpenRouteService.getGeoData(
              this.plant.address_details.lon.toString(), this.plant.address_details.lat.toString(),
              this.GPS_address.longitude.toString(), this.GPS_address.latitude.toString()).subscribe(
                (data)=> this.geoSegment=this.OpenRouteService.getGeoSegment(data),
                (error)=>console.error(error),
                ()=>{
                  this.form.controls.distance.setValue(this.geoSegment.distance.toString());
                  this.form.controls.traveltime.setValue(this.geoSegment.duration.toString());
              }
          );
        }
    )
  }
  plantChange(event) {
    this.form.controls.plants_id.setValue(event.target.value);
    this.updateRouteInfo();
  }
  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    // Validate longitudes and latitudes
    let valid=true;
    let lon=this.formControls.lon.value.toString().trim();
    let lat=this.formControls.lat.value.toString().trim();

    if(lat.length && lon.length && lat==lon) {
      this.error_lonlat=this.TranslateService.GetTranslation('gps.longitude-latitude-same');
      valid=false;
    }
    if(lat.length && lon.length===0) {
      this.formControls.lon.setErrors({'required': true});
      valid=false;
    }
    if(lon.length && lat.length===0) {
      this.formControls.lat.setErrors({'required': true});
      valid=false;
    }
    if(!valid) return;

    let data = {
      id: this.project.id,
      name: this.formControls.name.value,
      description: this.SanitizationService.nullIfEmpty(this.form.controls.description.value),
      projectstates_id: this.form.controls.projectstates_id.value,
      plants_id: this.SanitizationService.nullIfZero(parseInt(this.form.controls.plants_id.value)),
      customer_id: this.SanitizationService.nullIfZero(parseInt(this.form.controls.customer_id.value)),
      concrete_pricelists_id: this.SanitizationService.nullIfZero(parseInt(this.form.controls.concrete_pricelists_id.value)),
      article_pricelists_id: this.SanitizationService.nullIfZero(parseInt(this.form.controls.article_pricelists_id.value)),
      resource_pricelists_id: this.SanitizationService.nullIfZero(parseInt(this.form.controls.resource_pricelists_id.value)),
      address_details: {
        city: this.form.controls.city.value,
        street: this.form.controls.street.value,
        no: this.form.controls.no.value,
        postcode: this.form.controls.postcode.value,
        lon: this.form.controls.lon.value,
        lat: this.form.controls.lat.value
      },
      distance: this.SanitizationService.nullIfZero(parseInt(this.form.controls.distance.value)),
      traveltime: this.SanitizationService.nullIfZero(parseInt(this.form.controls.traveltime.value)),
      comments: this.SanitizationService.nullIfEmpty(this.formControls.comments.value)
    }

    if(this.project.id==0) {
      this.ProjectsService.create(data).subscribe(
          (data)=>this.project=data.data as Project,error => this.handleError(error),() => {
            this.saveSettings();
            this.afterSave()
          })
    } else {
      this.ProjectsService.update(data).subscribe(
          data => {},error => this.handleError(error),() => {
            this.saveSettings();
            this.afterSave()
          })
    }
  }
  handleError(error) {
    switch(error) {
      case 'name_not_unique':
        this.formControls.name.setErrors({'not_unique': true});
        document.getElementById('name').focus();
        break;
      case 'customer_blocked':
        this.stateError=this.TranslateService.GetTranslation('project.customer-blocked');
        document.getElementById('projectstates_id').focus();
        break;
    }

    if(error=='not_unique') {
      this.formControls.name.setErrors({'not_unique': true});
      document.getElementById('name').focus();
    } else
      console.error(error);
  }
  saveSettings() {
    this.SettingsService.setSetting(null, setting_subjects.project, this.project.id,
        settings.resources_based_invoicing, this.resources_based_invoicing ).subscribe(
        ()=>{},(error)=>console.error(error))
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.close();
  }
  close() {
    this.Router.navigate([{ outlets: { 'modal': null }} ] );
  }
}
