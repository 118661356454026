import {Component, EventEmitter, Input, OnInit, AfterViewInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ArticlePricelistsService } from "../../data/article-pricelists.service";
import {ConcretePricesService } from "../../data/concrete-prices.service";
import {PriceService } from "../../../../services/price/price.service";
import {ConcreteEnvironmentsService } from "../../data/concrete-environments.service";
import {ConcreteStrengthsService } from "../../data/concrete-strengths.service";
import {ConcreteConsistenciesService } from "../../data/concrete-consistencies.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMPropertiesListItem } from "../../../../components/JBM/Views/JBMPropertiesList/JBMPropertiesList.component";
import {forkJoin} from "rxjs";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {ConcretePrice} from "../../data/interfaces/concrete-price";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'form-concrete-price',
  templateUrl: './concrete_price.component.html',
  styleUrls: ['./concrete_price.component.scss']
})

export class ConcretePriceComponent implements OnInit, AfterViewInit {
  action: CRUDAction;
  concretePrice: ConcretePrice;
  error: string;
  environments: any[]=[];
  strengths: any[]=[];
  consistencies: any[]=[];
  environmentOptions: JBMSelectOption[]=[];
  strengthOptions: JBMSelectOption[]=[];
  consistencyOptions: JBMSelectOption[]=[];
  form: FormGroup;
  isSubmitted: boolean=false;
  priceListProps: JBMPropertiesListItem[]=[];

  @Input() concrete_pricelist: any;
  @Input() userRights: any;
  @Input() id: number;
  @Input() strength_id: number=0;
  @Input() environment_id: number=0;
  @Input() consistency_id: number=0;
  @Input() language: string;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private ArticlePricelistsService: ArticlePricelistsService,
    private ConcretePricesService: ConcretePricesService,
    private PriceService: PriceService,
    private ConcreteEnvironmentsService: ConcreteEnvironmentsService,
    private ConcreteStrengthsService: ConcreteStrengthsService,
    private ConcreteConsistenciesService: ConcreteConsistenciesService,
    private TranslateService: TranslateService
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id=0;

    if(this.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;

    this.error='';
    this.addControls();
    this.initData();
  }
  ngAfterViewInit(): void {
    if(this.id || this.strength_id)
      document.getElementById('price').focus();
    else
      document.getElementById('strengths_id').focus();
  }
  initData() {
    // Set pricelist properties
    let type=this.ArticlePricelistsService.getTypeAsString(this.concrete_pricelist.type, this.TranslateService);
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('financial.concrete-pricelist'), value: this.concrete_pricelist.description });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'), value: type });

    if(this.id) {
      this.ConcretePricesService.getOne(this.id, this.language).subscribe(
          (data: any)=>{
            this.concretePrice=data.data as ConcretePrice;
            this.environment_id=this.concretePrice.environments_id;
            this.consistency_id=this.concretePrice.consistencies_id;
            this.strength_id=this.concretePrice.strengths_id;
          },() => {},()=>this.getConcreteSpecs());
    } else {
      this.concretePrice=this.ConcretePricesService.getEmpty();
      this.getConcreteSpecs();
    }
  }
  getConcreteSpecs() {
    let params=new HttpParams().append('sort','descriptions.description'); // Sort +description
    params=params.append('no-baseprice-indicators','1');
    let envObservable=this.ConcreteEnvironmentsService.getData(this.language, params);
    let conObservable=this.ConcreteConsistenciesService.getData(this.language, params);
    let strObservable=this.ConcreteStrengthsService.getData(this.language, params);

    forkJoin([envObservable, conObservable, strObservable]).subscribe(results => {
      this.environments=results[0].data;
      this.consistencies=results[1].data;
      this.strengths=results[2].data;
      this.setControlValues();
    });
  }
  addControls() {
    let negativeAllowed=this.concrete_pricelist.type==1;
    this.form = this.formBuilder.group({
      environments_id: new FormControl('',[Validators.required]),
      consistencies_id: new FormControl('',[Validators.required]),
      strengths_id: new FormControl('',[Validators.required]),
      price: new FormControl('',[this.PriceService.priceValidator(this.PriceService, negativeAllowed)]),
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    // Convert concrete specs into select option lists
    for(let spec=0; spec<this.environments.length; spec++)
      this.environmentOptions.push({key: this.environments[spec].id, value: this.environments[spec].description });
    this.environments=[];
    for(let spec=0; spec<this.consistencies.length; spec++)
      this.consistencyOptions.push({key: this.consistencies[spec].id, value: this.consistencies[spec].code });
    this.consistencies=[];
    for(let spec=0; spec<this.strengths.length; spec++)
      this.strengthOptions.push({key: this.strengths[spec].id, value: this.strengths[spec].description });
    this.strengths=[];

    if(this.environment_id)
      this.form.controls.environments_id.setValue(this.environment_id.toString())
    else
      this.form.controls.environments_id.setValue(this.concretePrice.environments_id===0 ? '' : this.concretePrice.environments_id.toString());
    if(this.consistency_id)
      this.form.controls.consistencies_id.setValue(this.consistency_id.toString())
    else
      this.form.controls.consistencies_id.setValue(this.concretePrice.consistencies_id===0 ? '' : this.concretePrice.consistencies_id.toString());
    if(this.strength_id)
      this.form.controls.strengths_id.setValue(this.strength_id.toString())
    else
      this.form.controls.strengths_id.setValue( this.concretePrice.strengths_id===0 ? '' : this.concretePrice.strengths_id.toString());

    this.form.controls.price.setValue( this.PriceService.formatPrice(this.concretePrice.price));
  }
  changeEnvironment(event) {
    if(this.form.controls.environments_id.value=='-1') {
      // Old value is baseprice indication, so reset others spec selectors
      this.form.controls.consistencies_id.setValue('');
      this.form.controls.strengths_id.setValue('');
    }
    if(parseInt(event.target.value)>-1) return;
    // Baseprice indication is selected
    this.setBasepriceSelection();
  }
  changeConsistency(event) {
    if(this.form.controls.consistencies_id.value=='-1') {
      // Old value is baseprice indication, so reset others spec selectors
      this.form.controls.environments_id.setValue('');
      this.form.controls.strengths_id.setValue('');
    }
    if(parseInt(event.target.value)>-1) return;
    // Baseprice indication is selected
    this.setBasepriceSelection();
  }
  changeStrength(event) {
    if(this.form.controls.strengths_id.value=='-1') {
      // Old value is baseprice indication, so reset others spec selectors
      this.form.controls.consistencies_id.setValue('');
      this.form.controls.environments_id.setValue('');
    }
    if(parseInt(event.target.value)>-1) return;
    // Baseprice indication is selected
    this.setBasepriceSelection();
  }
  setBasepriceSelection() {
    this.form.controls.environments_id.setValue('-1');
    this.form.controls.consistencies_id.setValue('-1');
    this.form.controls.strengths_id.setValue('-1');
  }
  save() {
    this.isSubmitted = true;
    if(this.form.invalid)
      return;

    let data ={
      id: this.id,
      environments_id: parseInt(this.formControls.environments_id.value),
      consistencies_id: parseInt(this.formControls.consistencies_id.value),
      strengths_id: parseInt(this.formControls.strengths_id.value),
      concrete_pricelists_id: this.concrete_pricelist.id,
      price: this.PriceService.getPriceFromStr( this.formControls.price.value )
    }

    if(data.id==0) {
      this.ConcretePricesService.create(data).subscribe(
          ()=>{},error => this.handleError(error),
          ()=> this.onSave.emit())
    } else {
      this.ConcretePricesService.update(data).subscribe(
          ()=>{},error => this.handleError(error),
          ()=> this.onSave.emit())
    }
  }
  handleError(error) {
    if(error=='not_unique')
      this.error=this.TranslateService.GetTranslation('concrete.price.specs-not-unique');
  }
  cancel() {
    this.onCancel.emit();
  }
}
