import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayouts} from "../../app.component";
import { UsersManagementComponent } from "./users.management.component";
import { ActivityLogComponent } from "./activity-log/activity-log.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {LoginComponent} from "./login/login.component";

const routes: Routes = [
  { path: 'login',
    component: LoginComponent,
    data: {AppLayout: AppLayouts.Guest}
  },
  {
    path: 'settings/users',
    component: UsersManagementComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/usergroups',
    component: UsersManagementComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/grouprights',
    component: UsersManagementComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/activitylog',
    component: ActivityLogComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
