import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../shared/shared.module";
import { JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import { LogisticsRoutingModule } from './logistics-routing.module';
import { TrucksAndDriversComponent} from "./trucks-and-drivers/trucks-and-drivers.component";
import { TrucksComponent } from './trucks/trucks.component';
import { DriversComponent } from './drivers/drivers.component';
import { TruckFormComponent } from './trucks/form/truck-form.component';
import {SelectorsModule} from "../../components/domain/selectors/selectors.module";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [TrucksAndDriversComponent, TrucksComponent, DriversComponent, TruckFormComponent],
    imports: [CommonModule, SharedModule, LogisticsRoutingModule, JBMViewModule, JBMTableModule, JBMButtonsModule,
        JBMModalModule, JBMFormModule, SelectorsModule, LayoutModule]
})
export class LogisticsModule { }
