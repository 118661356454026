import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'guest-header',
  templateUrl: './guestheader.component.html',
  styleUrls: ['./guestheader.component.scss']
})
export class GuestheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
