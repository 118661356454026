<jbm-modal-header
  [editable]="false"
  [readTitle]="'customers.select'|translate"
  (closeClick)="closeModal()">
</jbm-modal-header>
<div class="modal-body">
    <div class="toolbar float-end">
        <jbm-searchbox [visible]="searchboxVisible && queryHttpHelper.paginationProps.itemCount>7" [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
        <div class="toolbar-spacer-2 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
            [pageSize]="queryHttpHelper.paginationProps.pageSize"
            [page]="queryHttpHelper.paginationProps.page"
            (pageClick)="pageChange($event)">
        </jbm-pagination>
        <div class="toolbar-spacer-2 float-end"></div>
        <jbm-spinner [loading]="loading"></jbm-spinner>
    </div>
    <jbm-table [classname]="'customers'"
               [data]="items"
               [sortable]="true"
               [sortColumn]="queryHttpHelper.sortProps.name"
               [sortDirection]="queryHttpHelper.sortProps.direction"
               [hoverable]="true"
               [rowSelectable]="true"
               [columnDefs]="columnDefs"
               (sortChange)="sortChange($event)"
               (rowSelect)="rowSelect($event)">
    </jbm-table>
    <jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
</div>