import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'resources/';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {
  constructor(private HttpService: HttpService) {}

  get(type_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+type_id.toString(), params );
  }
  getOne(id: number) {
    return this.HttpService.get(baseURL+'read/one/'+id.toString());
  }
  getSelectList(resource_pricelists_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/select-list/'+resource_pricelists_id.toString(), params );
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/', data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/', data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+id.toString());
  }
}
