import {AfterViewChecked, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {NgbDropdown, NgbDropdownMenu} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'jbm-color-palette-picker',
  templateUrl: './JBMColorPalettePicker.component.html',
  styleUrls: ['./JBMColorPalettePicker.component.scss']
})
export class JBMColorPalettePickerComponent implements OnInit, AfterViewChecked {
  backgrounds=['#f8f9fa','#17a2b8','#28a745','#6c757d','#007bff','#ffc107','#343a40','#dc3545'];
  foregrounds=['#ffffff','#000000'];
  noWhiteForegrounds=[0];
  noBlackForegrounds=[3,6];
  initialBackground: string='';
  initialForeground: string='';

  @Input() background: string;
  @Input() foreground: string;

  @Output() onAccept = new EventEmitter();
  @Output() onChange = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  @ViewChild('dropdown') dropdown: NgbDropdown;

  constructor() { }

  ngOnInit(): void {
    this.initialBackground=this.background;
    this.initialForeground=this.foreground;
  }
  ngAfterViewChecked() {
    this.changeBackground(this.background);
  }
  changeBackground(background: string) {
    if(this.background===null)
      return;
    this.background=background;

    this.toggleForegrounds(true);

    // Check allowed foreground colors
    let index=this.backgrounds.indexOf(background);

    if(!this.whiteForegroundAllowed(index)) {
      this.foreground=this.foregrounds[1];
      this.toggleForegrounds(false);
    }
    if(!this.blackForegroundAllowed(index)) {
      this.foreground=this.foregrounds[0];
      this.toggleForegrounds(false);
    }

    this.onChange.emit({ background: this.background, foreground: this.foreground } );
  }
  whiteForegroundAllowed(backgroundIndex: number) {
    return this.noWhiteForegrounds.indexOf(backgroundIndex)===-1;
  }
  blackForegroundAllowed(backgroundIndex: number) {
    return this.noBlackForegrounds.indexOf(backgroundIndex)===-1;
  }
  toggleForegrounds(enabled: boolean) {
    for(let fg=0; fg<this.foregrounds.length; fg++) {
      if(enabled) {
        if( document.getElementById('foregrounds' + fg).hasAttribute('disabled') )
          document.getElementById('foregrounds' + fg).removeAttribute('disabled');
      } else
        document.getElementById('foregrounds' + fg).setAttribute('disabled', 'disabled');
    }
  }
  changeForeground(foreground: string) {
    this.foreground=foreground;
    this.onChange.emit({ background: this.background, foreground: this.foreground } );
  }
  accept() {
    this.dropdown.toggle();
    this.onAccept.emit({ background: this.background, foreground: this.foreground } );
  }
  cancel() {
    this.background=this.initialBackground;
    this.foreground=this.initialForeground;
    this.dropdown.toggle();
    this.onCancel.emit();
  }
}
