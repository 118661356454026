import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'recipes/';

@Injectable({
  providedIn: 'root'
})
export class RecipeArticlesService {
  constructor(private HttpService: HttpService) {}

  get(recipe_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/articles/'+recipe_id.toString(), params );
  }
  getArticles(recipe_id: number, language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/articles/articles/'+recipe_id.toString()+'/'+language, params );
  }
  getArticlesList(language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/articles-selectlist/'+language, params );
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/recipe-articles', data);
  }
}
