export interface GPSCoordinates {
	longitude: string;
	latitude: string;
}
export interface GPSAddress {
	longitude: string;
	latitude: string;
	postcode: string;
	no: string;
	street: string;
	city: string;
}
export function EmptyGPSAddress(): GPSAddress {
	return { longitude: '', latitude: '', postcode: '', no: '', street: '', city: '' }
}
