import { NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import {SharedModule } from '../../shared/shared.module';
import {JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import {JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import {JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import {JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import {JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import {JBMModalModule } from "../../components/JBM/JBMModal/jbmmodal.module";
import {JBMAsidesModule} from "../../components/JBM/Asides/jbmasides.module";
import {JBMPopoversModule} from "../../components/JBM/Popovers/jbmpopovers.module";
import {SharedCustomersModule} from "../../shared/customers/shared.customers.module";
import {SharedProjectsModule} from "../../shared/projects/shared.projects.module";
import {SharedRecipesModule} from "../../shared/recipes/shared.recipes.module";
import {SelectorsModule} from "../../components/domain/selectors/selectors.module";
import {ProjectsRoutingModule } from './projects-routing.module';
import {ProjectsComponent } from './datatable/projects.component';
import {ProjectFormComponent} from "./form/project-form.component";
import {ProjectViewComponent } from './project-view/project-view.component';
import {OrderComponent} from "./orders/form/order.component";
import {OrdersComponent} from "./orders/datatable/orders.component";
import {OrderArticlesComponent} from "./orders/order-articles/order-articles.component";
import {ProjectstatesComponent} from "./projectstates/projectstates.component";
import {UnloadingMethodsComponent} from "./unloading-methods/unloading-methods.component";
import {WorktypesComponent} from "./worktypes/worktypes.component";
import {OrderCopyComponent} from "./orders/copy/order-copy.component";
import {OrderAmountFormComponent } from './orders/amount-form/order-amount-form.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
	declarations: [ProjectsComponent, ProjectViewComponent, ProjectFormComponent, OrdersComponent, OrderComponent,
		OrderArticlesComponent, ProjectstatesComponent, UnloadingMethodsComponent,
		WorktypesComponent, OrderCopyComponent, OrderAmountFormComponent],
	exports: [ProjectsComponent],
    imports: [CommonModule, SharedModule, SelectorsModule, JBMAsidesModule, JBMButtonsModule,
        JBMDropdownsModule, JBMTableModule, JBMViewModule, JBMFormModule, JBMModalModule, JBMPopoversModule,
        SharedCustomersModule, SharedProjectsModule, SharedRecipesModule, ProjectsRoutingModule, LayoutModule]
})
export class ProjectsModule { }
