import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {ConcretePricesService} from "../../../../../features/financial/data/concrete-prices.service";
import {PriceRulesTranslationKeys} from '../helpers/PriceRulesTranslationKeys';
import {HttpParams} from "@angular/common/http";
import {NgbPopover} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'jbm-concrete-base-pricerules-breakdown-popover',
  templateUrl: './JBMConcreteBasePriceRulesBreakdownPopover.component.html',
  styleUrls: ['./JBMConcreteBasePriceRulesBreakdownPopover.component.scss']
})
export class JBMConcreteBasePriceRulesBreakdownPopoverComponent implements OnInit {
  PriceRulesTranslationKeys: PriceRulesTranslationKeys;
  pricerules: any[]=[];

  @ViewChild('p') popover: NgbPopover;

  @Input() price: number;
  @Input() project_id: number;
  @Input() customer_id: number;
  @Input() plant_id: number;
  @Input() environment_id: number;
  @Input() strength_id: number;
  @Input() consistency_id: number;
  @Input() class: string;

  constructor(private ConcretePricesService: ConcretePricesService) {
    this.PriceRulesTranslationKeys=new PriceRulesTranslationKeys();
  }

  ngOnInit(): void {
    if(this.price==undefined) this.price=0.0;
    if(this.project_id==undefined) this.project_id=0;
    if(this.customer_id==undefined) this.customer_id=0;
    if(this.plant_id==undefined) this.plant_id=0;
  }
  toggle() {
    if(this.popover.isOpen()) {
      this.popover.toggle();
      return;
    }
    if(!this.popover.isOpen() && this.pricerules.length>0) {
      this.popover.toggle();
      return;
    }
    let params=new HttpParams()
        .append('projects_id',this.project_id.toString())
        .append('customers_id', this.customer_id.toString())
        .append('plants_id', this.plant_id.toString())
        .append('environments_id','-1')
        .append('strengths_id', '-1')
        .append('consistencies_id', '-1');
    this.ConcretePricesService.calculateBasePrice(params).subscribe(
        (data)=>this.pricerules=data.data.pricerules,
        (error)=>console.error(error),
        ()=> {
          this.pricerules=this.PriceRulesTranslationKeys.setKeys(this.pricerules);
          this.toggle();
        }
    )
  }
}
