import {Component, Input} from '@angular/core';
import {FormGroupBaseComponent} from "../formgroupbase.component";

@Component({
  selector: 'jbm-textarea',
  templateUrl: './JBMTextarea.component.html',
  styleUrls: ['./JBMTextarea.component.scss']
})

export class JBMTextareaComponent extends FormGroupBaseComponent {
  @Input() rows: number=3;
}


