<div ngbDropdown container="body">
   <button type="button" class="btn btn-sm btn-light {{class}}" id="quocnt" ngbDropdownToggle
      (click)="toggle();$event.stopPropagation();">
      <i class="fas fa-user-friends"></i><span *ngIf="caption"> {{caption}}</span>
   </button>
   <div ngbDropdownMenu [attr.aria-labelledby]="'quocnt'" class="table-cell">
      <div class="jbm-properties-list contact stacked">
         <div class="item">
            <label class="label">{{'project.site-manager'|translate}} <i class="fas fa-mobile-alt"></i></label>
            <ngb-alert *ngIf="!siteManager.phone" [dismissible]="false" [type]="'info'" [class]="'mb-0'">
               {{'ui.empty-item'|translate}}
            </ngb-alert>
            <label *ngIf="siteManager.phone" class="value">{{siteManager.phone}}</label>
         </div>
         <div class="item">
            <label class="label">{{'entity.name'|translate}}</label>
            <label *ngIf="siteManager.name" class="value">{{siteManager.name}}</label>
         </div>
         <div *ngIf="siteManager.phone" class="item">
            <label class="label">{{'entity.phone'|translate}}</label>
            <label class="value">{{siteManager.phone}}</label>
         </div>
         <div *ngIf="siteManager.email" class="item">
            <label class="label">{{'entity.email'|translate}}</label>
            <label class="value">{{siteManager.email}}</label>
         </div>
         <div class="item mt-3">
            <label class="label">{{'contact.person'|translate}}</label>
            <ngb-alert *ngIf="!contactPerson.name && !contactPerson.phone && !contactPerson.email" [dismissible]="false" [type]="'info'" [class]="'mb-0'">
               {{'ui.empty-item'|translate}}
            </ngb-alert>
            <label *ngIf="contactPerson.name" class="value">{{contactPerson.name}}</label>
         </div>
         <div *ngIf="contactPerson.phone" class="item">
            <label class="label">{{'entity.phone'|translate}}</label>
            <label class="value">{{contactPerson.phone}}</label>
         </div>
         <div *ngIf="contactPerson.email" class="item">
            <label class="label">{{'entity.email'|translate}}</label>
            <label class="value">{{contactPerson.email}}</label>
         </div>
      </div>
   </div>
</div>
