import {HttpParams} from "@angular/common/http";
import {LocalStorageService} from "../../../services/storage/localstorage.service";

export const defaultPageSize=20;

export interface PaginationProps {
	itemCount: number;
	page: number;
	pageSize: number;
}

export interface SortProps {
	name: string;
	descending: boolean;
	direction: string;
}

export const asc='+';
export const desc='-';

export interface AliasedColumn {
	name: string;
	aliasedName: string;    // format as <table-alias>.<column-name>
}

export enum SearchColumnType {
	string,
	number,
	date,
}

export interface SearchColumn {
	name: string;
	columnType: SearchColumnType;
}

export interface SearchProps {
	columnDefs: SearchColumn[];
	searchValue?: string;
}

export const enum FilterOperator { eq, noteq, gt,lt, gteq, lteq}

export interface FilterItem {
	name: string;
	value: any;
	operator?: FilterOperator;
}

export interface FilterProps {
	search?: string;
	or_filter?: string;
	and_filter?: string;
	and_filters?: FilterItem[];
}

export class QueryHttpHelper {
	public LocalStorageService: LocalStorageService;
	public paginationProps: PaginationProps;
	public sortProps: SortProps;
	private aliasedColumns: AliasedColumn[]=[];
	private httpParams: HttpParams;
	private searchProps: SearchProps;
	private filterProps: FilterProps;

	static readonly LocalStoragePrefix='QueryHttpHelper';

	constructor() {
		this.LocalStorageService=new LocalStorageService();
		this.paginationProps={ itemCount:0, page:1, pageSize:0 };
		this.sortProps = { name: '', descending: false, direction: asc };
		this.searchProps = { columnDefs: [], searchValue: null };
		this.filterProps = { search: null, or_filter: '', and_filter: '', and_filters:[] };
	}
	getHttpParams(countRows: boolean=false): HttpParams {
		this.httpParams=new HttpParams();
		if(!countRows)
			this.httpParams=this._setPaginationParams();
		this.httpParams=this._setFilterParams();

		if(!countRows) {
			// Asure that direction is set!
			this.sortProps.direction = this.sortProps.descending ? '-' : '+';
			this.httpParams = this._setSortParams();
		} else {
			this.httpParams = this._setCountParam();
		}
		return this.httpParams;
	}
	// Aliased columns; needed in joins with ambiguous columns
	// PostgresQL does NOT support aliased columnnames in where clausules
	// AliasedName is formatted as <table-alias>.<column-name>
	addAliasedColumn( name: string, aliasedName: string) {
		this.aliasedColumns.push( { name: name, aliasedName: aliasedName} );
	}
	private getColumnName(name: string): string {
		for(let ac=0; ac<this.aliasedColumns.length; ac++)
			if(this.aliasedColumns[ac].name===name)
				return this.aliasedColumns[ac].aliasedName
					? this.aliasedColumns[ac].aliasedName : this.aliasedColumns[ac].name;
		return name;
	}

	// Searching
	addSearchColumn( name: string, columnType: SearchColumnType) {
		for(let columnDef of this.searchProps.columnDefs)
			if( columnDef.name==name)
				// Search column exists already!
				return false;
		this.searchProps.columnDefs.push( { name: name, columnType: columnType });
	}
	getSearchColumnType( name: string ) {
		for(let columnDef of this.searchProps.columnDefs)
			if( columnDef.name==name)
				return columnDef.columnType;
		return false;
	}
	setSearchValue(value) {
		this.searchProps.searchValue = value;
		// Clear and filter
		this.filterProps.and_filter='';
		this._buildOrFilter();
		this.paginationProps.page=1;
	}
	getSearchValue(): string {
		return this.searchProps.searchValue;
	}

	// Filtering
	setFilterItem( name: string, value: any, operator: FilterOperator=FilterOperator.eq, resetPagination: boolean=true ) {
		// Clear or filter
		this.filterProps.or_filter='';

		let exists=false;
		for(let f=0; f<this.filterProps.and_filters.length; f++) {
			if( this.filterProps.and_filters[f].name===name) {
				exists=true;
				if(typeof value=='string' && value==='') {
					// Delete empty filter item
					this.deleteFilterItem(name);
					break;
				}
				// Filter exists! Update filter value
				this.filterProps.and_filters[f].value = value;
				break;
			}
		}
		if(!exists && !(typeof value=='string' && value===''))
			// Add filter item
			this.filterProps.and_filters.push({name: name, value: value, operator: operator});

		this._buildAndFilter();
		if(resetPagination)
			this.paginationProps.page=1;
	}
	getFilterProps() {
		return this.filterProps;
	}
	getFilterValue(name: string) {
		for(let f=0; f<this.filterProps.and_filters.length; f++)
			if(this.filterProps.and_filters[f].name===name)
				return this.filterProps.and_filters[f].value;
		return false;
	}

	hasAndFilters() {
		return this.filterProps.and_filters.length>0;
	}
	getAndFilters() {
		return this.filterProps.and_filters;
	}
	deleteFilterItem(name: string) {
		for(let f=0; f<this.filterProps.and_filters.length; f++) {
			if(this.filterProps.and_filters[f].name===name) {
				this.filterProps.and_filters.splice(f, 1);
				this._buildAndFilter();
				this.paginationProps.page=1;
				break;
			}
		}
	}
	clearFilters() {
		this.filterProps.and_filters=[];
		this.filterProps.and_filter='';
		this.filterProps.or_filter='';
		this.filterProps.search='';
	}

	// Sorting
	setSortColumn( name: string, descending: boolean=false ) {
		this.sortProps.name=name;
		this.sortProps.descending=descending;
		this.sortProps.direction=descending ? '-' : '+';
		this.paginationProps.page=1;
	}

	// Localstorage State
	saveState(storageKey: string) {
		let key=QueryHttpHelper.LocalStoragePrefix+storageKey;
		if(this.LocalStorageService.hasKey(key))
			// Delete actual state data
			this.clearState(storageKey);
		this.LocalStorageService.set(key,
			{ pagination: this.paginationProps, sort: this.sortProps, search: this.searchProps, filter: this.filterProps });
	}
	restoreState(storageKey: string, clearState: boolean=true) {
		let key=QueryHttpHelper.LocalStoragePrefix+storageKey;
		if(!this.LocalStorageService.hasKey(key)) return;
		let state=this.LocalStorageService.get(key);
		if(state!==null) {
			this.paginationProps=state.pagination;
			this.sortProps=state.sort;
			this.searchProps=state.search;
			this.filterProps=state.filter;
		}
		if(clearState)
			this.clearState(storageKey);
	}
	clearState(storageKey: string) {
		let key=QueryHttpHelper.LocalStoragePrefix+storageKey;
		if(this.LocalStorageService.hasKey(key))
			this.LocalStorageService.remove(key);
	}

	private _buildAndFilter() {
		// Clear and filter
		this.filterProps.and_filter='';
		let filter='';
		for(let f=0; f<this.filterProps.and_filters.length; f++) {
			let and_filter=this.filterProps.and_filters[f];

			// Skip filter if null, undefined or empty
			if(and_filter.value===null) continue;
			if(and_filter.value===undefined) continue;
			if(typeof and_filter.value=='string' && and_filter.value.trim()==='') continue;

			let operator = ' eq ';
			switch (and_filter.operator) {
				case FilterOperator.noteq:  { operator = ' noteq '; break; }
				case FilterOperator.gt:     { operator = ' gt '; break; }
				case FilterOperator.lt:     { operator = ' lt '; break;	}
				case FilterOperator.gteq:   { operator = ' gteq '; break; }
				case FilterOperator.lteq:   { operator = ' lteq '; break; }
			}

			let columnName=this.getColumnName(and_filter.name);
			filter+=columnName+operator;

			let searchType=this.getSearchColumnType(columnName);
			if(searchType!==false)
				if(searchType===SearchColumnType.number && typeof and_filter.value=='string')
					// Convert string value to float value
					and_filter.value=parseFloat(and_filter.value);

			if(typeof and_filter.value=='boolean' || typeof and_filter.value=='number')
				filter+=and_filter.value;
			else {
				// Replace all spaces with * (required by the backend QueryParser)
				and_filter.value=and_filter.value.replace(' ','*');
				filter+="'" + and_filter.value + "'";
			}
			if(f<(this.filterProps.and_filters.length-1))
				filter+=' and ';
		}
		this.filterProps.and_filter=filter;
	}

	private _setPaginationParams() {
		if(!this.paginationProps.pageSize)
			this.paginationProps.pageSize=defaultPageSize;
		this.httpParams = this.httpParams.append(
			'offset', (this.paginationProps.page * this.paginationProps.pageSize - this.paginationProps.pageSize).toString());
		this.httpParams = this.httpParams.append(
			'limit', this.paginationProps.pageSize.toString() );
		return this.httpParams;
	}
	private _setFilterParams() {
		if(this.filterProps.and_filter)
			this.httpParams = this.httpParams.append(
				'filter',this.filterProps.and_filter);
		if(this.filterProps.or_filter)
			this.httpParams = this.httpParams.append(
				'filter',this.filterProps.or_filter);
		return this.httpParams;
	}
	private _buildOrFilter() {
		// Clear or filter
		this.filterProps.or_filter='';
		let filterValue=this.searchProps.searchValue.trim();
		if(!filterValue) return;

		let columnFilters=[];
		for(let c=0; c<this.searchProps.columnDefs.length; c++) {
			let columnDef=this.searchProps.columnDefs[c];
			let columnName=this.getColumnName(columnDef.name);
			switch (columnDef.columnType) {
				case SearchColumnType.number: {
					if(!isNaN(parseFloat(filterValue)))
						columnFilters.push(columnName+" eq " + filterValue);
					break;
				}
				case SearchColumnType.date: {
					if(!isNaN( parseInt(filterValue)))
						columnFilters.push(columnName+" eq " + filterValue);
					break;
				}
				default: {
					// Replace all spaces with * (required by the backend QueryParser)
					filterValue=filterValue.split(' ').join('*');
					// Replace singles and double quotes with string constants
					filterValue=filterValue.split("'").join('_SQ_');
					filterValue=filterValue.split("'").join('_DQ_');
					columnFilters.push(columnName+" eq '" + filterValue + "'");
				}
			}
		}

		this.filterProps.or_filter=columnFilters.join(' or ');
	}
	private _setSortParams() {
		if(!this.sortProps.name)
			return this.httpParams; // No column to sort on

		let columnName=this.sortProps.name;

		// URL Encode plus or minus
		let encoded='%2b'; if(this.sortProps.direction==desc) encoded='%2d';
		this.httpParams = this.httpParams.append('sort',encoded+columnName );
		return this.httpParams;
	}
	private _setCountParam() {
		this.httpParams = this.httpParams.append('count','1' );
		return this.httpParams;
	}
}