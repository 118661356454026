import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { TranslateService } from "../../../../services/multilingual/translate.service";

export interface JBMModalHeaderButton {
  name: string;
  caption: string;
  iconclass?: string;
  visible?: boolean;
  enabled?: boolean;
}

@Component({
  selector: 'jbm-modal-header',
  templateUrl: './JBMModalHeader.component.html',
  styleUrls: ['./JBMModalHeader.component.scss']
})
export class JBMModalHeaderComponent implements OnInit {
  @Input() editable: boolean=true;
  @Input() updateMode: boolean=false;
  @Input() createTitle: string='';
  @Input() updateTitle: string='';
  @Input() readTitle: string='';
  @Input() saveTitle: string='';
  @Input() buttons: JBMModalHeaderButton[]=[];

  @Output() click = new EventEmitter();
  @Output() closeClick = new EventEmitter();

  constructor(public TranslateService: TranslateService) { }

  ngOnInit(): void {
    if(this.editable==undefined) this.editable=true;
    if(this.updateMode==undefined) this.updateMode=false;
    if(this.createTitle==undefined) this.createTitle='';
    if(this.updateTitle==undefined) this.updateTitle='';
    if(this.readTitle==undefined) this.readTitle='';
    if(this.saveTitle==undefined)
      this.saveTitle='';
    for(let b=0; b<this.buttons.length; b++) {
      if (this.buttons[b].visible == undefined)
        this.buttons[b].visible = true;
      if (this.buttons[b].enabled == undefined)
        this.buttons[b].enabled = true;
    }
  }
  OnClick(button) {
    this.click.emit(button.name);
  }
  onClose() {
    this.closeClick.emit();
  }
}
