import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import { InvoicesService} from "../data/invoices.service";
import { CustomerService} from "../../customers/data/customer.service";
import { ProjectsService} from "../../projects/data/projects.service";
import { SanitizationService} from "../../../services/sanitization/sanitization.service";
import { JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import { TranslateService} from "../../../services/multilingual/translate.service";
import { ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import { TokenService} from "../../../services/auth/token.service";
import { FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { Invoice} from "../data/interfaces/invoice";

@Component({
  selector: 'customer-invoice-form',
  templateUrl: './customer-invoice-form.component.html',
  styleUrls: ['./customer-invoice-form.component.scss']
})
export class CustomerInvoiceFormComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  source: number=0;
  invoice: Invoice;
  id: number=0;
  form: FormGroup;
  submitted: boolean=false;
  customerError: string='';

  @ViewChild('modalInvoice') modalInvoice: ElementRef;

  constructor(
      private InvoicesService: InvoicesService,
      private CustomerService: CustomerService,
      private ProjectsService: ProjectsService,
      private FormBuilder: FormBuilder,
      private Router: Router,
      private SanitizationService: SanitizationService,
      private JBMToastsService: JBMToastsService,
      private TranslateService: TranslateService,
      private ModalService: ModalService,
      private TokenService: TokenService
  ) {
    this.addControls();
  }
  ngOnInit(): void {
    this.invoice=this.InvoicesService.getEmpty();
    setTimeout(()=>this.ModalService.open(this.modalInvoice,'customer-invoice'),100);
  }
  addControls() {
    this.form = this.FormBuilder.group({
      customer_id: new FormControl(0),
      comments: new FormControl(''),
      openInvoice: new FormControl(true)
    },{ updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setCustomer(customer) {
    this.formControls.customer_id.setValue(customer.id);
  }
  save() {
    this.submitted = true;

    if(this.form.controls.customer_id.value===0) {
      this.customerError=this.TranslateService.GetTranslation('project.no-customer-selected');
      return;
    }

    let data = {
      id: this.invoice.id,
      customer_id: this.form.controls.customer_id.value,
      project_id: null,
      comments: this.form.controls.comments.value
    }

    this.InvoicesService.create(data).subscribe(
        data =>this.id=data.data,(error) =>console.error(error),() => {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
          if(this.formControls.openInvoice.value)
            this.Router.navigateByUrl('invoicing/'+this.id)
          else
            this.Router.navigate([{ outlets: { 'modal': null }} ] );
        }
    );
  }
  close() {
    this.Router.navigate([{ outlets: { 'modal': null }} ] );
  }
}
