import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrdersService} from "../../data/orders.service";
import {Order} from "../../data/interfaces/order";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {ValidationPatternsService} from "../../../../services/validation/patterns.service";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'order-amount-form',
  templateUrl: './order-amount-form.component.html',
  styleUrls: ['./order-amount-form.component.scss']
})
export class OrderAmountFormComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  action: CRUDAction=CRUDAction.Update;
  order: Order=this.OrdersService.getEmpty();
  form: FormGroup;
  isSubmitted: boolean=false;

  constructor(
      private OrdersService: OrdersService,
      private formBuilder: FormBuilder,
      private ValidationPatternsService: ValidationPatternsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService
  ) {
    this.form=this.formBuilder.group({
      amount: new FormControl('', [Validators.pattern(this.ValidationPatternsService.floatPattern(2))])
    });
  }

  @Input() order_id: number;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  ngOnInit(): void {
    this.OrdersService.getOne(this.order_id, this.language).subscribe(
        (data) => this.order=data.data as Order,
        (error) => console.error(error),
        ()=> {
          this.form.controls.amount.setValue(this.order.amount.toString().replace('.', ','));
          setTimeout(()=>document.getElementById('amount').focus(),500);
        }
    )
  }

  get formControls() {
    return this.form.controls;
  }

  save() {
    this.isSubmitted=true;
    if(this.form.invalid)
      return;

    // Sanitize data
    let amount=parseFloat(this.formControls.amount.value.replace(',', '.'));
    this.OrdersService.updateAmount({order_id: this.order_id, amount: amount}).subscribe(
        () =>{},
        error => console.error(error),() => {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
          this.onSave.emit();
        }
    )
  }
  cancel() {
    this.onCancel.emit();
  }
}