<h5 class="pt-3">{{'ui.totals'|translate}}</h5>
<table class="table table-xs bg-white">
   <thead>
      <tr>
         <th style="width: 6rem"></th>
         <th *ngFor="let total of totals">{{total.code}}</th>
      </tr>
   </thead>
   <tbody>
      <tr>
         <th class="align-middle">{{'production.weighed'|translate}}</th>
         <td *ngFor="let total of totals">{{total.result_total}}</td>
      </tr>
      <tr>
         <th class="align-middle">{{'entity.recipe'|translate}}</th>
         <td *ngFor="let total of totals">{{total.wet_total}}</td>
      </tr>
      <tr>
         <th class="align-middle">{{'ui.difference'|translate}}</th>
         <td *ngFor="let total of totals">
            {{total.difference}} {{'quantity.kilo-short'|translate}} ({{total.percentage}}%)
         </td>
      </tr>
   </tbody>
</table>

<h5 class="pt-4">{{'production.charges'|translate}}</h5>

<table class="table table-xs bg-white">
   <thead>
   <tr>
      <th style="width: 6rem">{{'production.charge'|translate}}</th>
      <th>{{'quantity.size'|translate}}</th>
      <th>{{'production.unloading-time'|translate}} {{'production.mixer'|translate}}</th>
      <th>{{'production.mixer'|translate}}{{'production.flow'|translate|lowercase}}</th>
      <th>{{'production.water-correction'|translate}}</th>
   </tr>
   </thead>
   <tbody>
   <tr *ngFor="let charge of charges">
      <td>{{charge.charge_no}}</td>
      <td>{{charge.size}}</td>
      <td>{{charge.start}}</td>
      <td>{{charge.mixer_resistance}}</td>
      <td>{{charge.water_correction}}</td>
   </tr>
   </tbody>
</table>

<div>
   <div class="toolbar float-start">
      <h5 class="pt-4">{{'production.produced'|translate}}</h5>
   </div>
   <div class="toolbar float-end pt-4">
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page" (pageClick)="pageChange($event)">
      </jbm-pagination>
   </div>
</div>
<jbm-table [size]="'xs'" [classname]="'bg-white'" [data]="lines" [columnDefs]="columnDefs"></jbm-table>
<ng-template #differenceTemplate let-data="data">
   <div *ngIf="data.difference" class="align-right">
      {{data.difference}} {{'quantity.kilo-short'|translate}} ({{data.percentage}}%)
   </div>
   <div *ngIf="!data.difference" class="align-right">
      -
   </div>

</ng-template>

