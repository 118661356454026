import {NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import {SharedModule } from '../../shared/shared.module';
import {JBMButtonsModule } from "../../components/JBM/Buttons/jbmbuttons.module";
import {JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import {JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import {JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import {JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import {SelectorsModule} from "../../components/domain/selectors/selectors.module";
import {FinancialRoutingModule } from './financial-routing.module';
import {FinancialDashboardComponent } from './financial.dashboard.component';
import {ConcreteSpecsContainerComponent} from './concrete_specs/concrete.specs.container.component';
import {ArticlePricesComponent} from "./article_prices/datatable/article_prices.component";
import {ArticlePriceComponent} from "./article_prices/form/article_price.component";
import {ArticlePriceTypesComponent} from "./article_price_types/article_price_types.component";
import {ArticlegroupsComponent} from "./articlegroups/articlegroups.component";
import {ArticlesComponent} from "./articles/datatable/articles.component";
import {ArticleComponent} from "./articles/form/article.component";
import {ArticleSelectListComponent} from "./articles/selectlist/article_selectlist.component";
import {PricelistsComponent} from "./pricelists/datatable/pricelists.component";
import {ConcretePriceComponent} from "./concrete_prices/form/concrete_price.component";
import {PricelistComponent} from "./pricelists/form/pricelist.component";
import {ConcretePricesComponent} from "./concrete_prices/datatable/concrete_prices.component";
import {ConsistenciesComponent} from './concrete_specs/consistencies/consistencies.component';
import {EnvironmentsComponent} from './concrete_specs/environments/environments.component';
import {StrengthsComponent} from './concrete_specs/strengths/strengths.component';
import {CustomerSelectListComponent} from './customers/selectlist/customer_selectlist.component';
import {CopyPricelistComponent} from "./pricelists/copy/copy_pricelist.component";
import {PricelistConnectCustomersComponent} from "./pricelists/connect-customers/pricelist_connect_customers.component";
import {JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import {SharedCustomersModule} from "../../shared/customers/shared.customers.module";
import {PricelistUsesComponent} from './pricelists/uses/pricelist_uses.component';
import {PricelistAssignProjectsComponent} from "./pricelists/assign-project/pricelist-assign-projects.component";
import {ResourcePriceComponent} from "./resources/form/resource_price.component";
import {ResourceSelectListComponent} from "./resources/selectlist/resource_selectlist.component";
import {ResourcePricelistsComponent} from "./resources/datatable/resource-pricelists.component";
import {ResourcePricelistComponent} from "./resources/form/resource-pricelist.component";
import {CopyResourcePricelistComponent} from "./resources/copy/copy-resource-pricelist.component";
import {ResourcePricelistUsesComponent} from "./resources/uses/resource-pricelist-uses.component";
import {ResourcePricelistAssignProjectsComponent} from "./resources/assign-project/resource-pricelist-assign-projects.component";
import {ResourcePricesComponent} from "./resources/datatable/resource_prices.component";
import {ResourcePricelistConnectCustomersComponent} from "./resources/connect-customers/resource-pricelist-connect-customers.component";
import {DomainDropdownsModule} from "../../components/domain/dropdowns/domain-dropdowns.module";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [FinancialDashboardComponent, ArticlePricesComponent,
    ArticlePriceComponent, ArticlePriceTypesComponent, ArticlegroupsComponent, ArticlesComponent, ArticleComponent, ArticleSelectListComponent,
    PricelistsComponent, PricelistComponent, ConcretePricesComponent, ConcretePriceComponent, CopyPricelistComponent,
    PricelistConnectCustomersComponent, PricelistAssignProjectsComponent,
    ConcreteSpecsContainerComponent, ConsistenciesComponent, EnvironmentsComponent, StrengthsComponent,
    CustomerSelectListComponent, PricelistUsesComponent,
    ResourcePriceComponent, ResourceSelectListComponent, ResourcePricesComponent, ResourcePricelistsComponent,
    CopyResourcePricelistComponent, ResourcePricelistComponent, ResourcePricelistUsesComponent, ResourcePricelistAssignProjectsComponent,
    ResourcePricelistConnectCustomersComponent
  ],
	exports: [PricelistsComponent, CustomerSelectListComponent, ResourcePricelistsComponent],
  providers: [CustomerSelectListComponent],
    imports: [CommonModule, SharedModule, DomainDropdownsModule, FinancialRoutingModule, JBMButtonsModule, JBMTableModule, JBMViewModule,
        JBMFormModule, JBMModalModule, JBMDropdownsModule, SelectorsModule, SharedCustomersModule, DomainDropdownsModule, LayoutModule]
})
export class FinancialModule { }
