import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'jbm-pagination',
  templateUrl: './JBMPagination.component.html',
  styleUrls: ['./JBMPagination.component.scss']
})
export class JBMPaginationComponent implements OnInit {

  @Input() itemCount: number=0;
  @Input() size: string='';
  @Input() page: number=1;
  @Input() pageSize: number=0;
  @Output() pageClick = new EventEmitter();

  constructor() {}
  ngOnInit(): void {
    if(this.size==undefined) this.size='';
  }
  setPage(page) {
    this.pageClick.emit(page);
  }

  @HostListener('window:keydown', ['$event'])
  handleShortcut(event: KeyboardEvent) {
    let pageCount=Math.ceil(this.itemCount / this.pageSize);

    if(event.code==='Home' && this.page>1) {
      this.setPage(1);
      event.preventDefault();
    }
    if(event.code==='PageUp' && this.page>1) {
      this.setPage(this.page - 1);
      event.preventDefault();
    }
    if(event.code==='PageDown' && this.page < pageCount) {
      this.setPage(this.page + 1);
      event.preventDefault();
    }
    if(event.code==='End' && this.page < pageCount) {
      this.setPage(pageCount);
      event.preventDefault();
    }
  }
}
