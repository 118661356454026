import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {Project} from "./interfaces/project";
import {AddressesService} from "../../addresses/data/addresses.service";
import {PricelistsType} from "../../financial/data/article-pricelists.service";

const baseURL: string = 'projects/';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  project: Project;

  constructor(
      private HttpService: HttpService,
      private AddressesService: AddressesService
  ) {}

  get(language, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+language, params );
  }
  getByID(id:number) {
    return this.HttpService.get(baseURL+'read/getbyid/'+id.toString());
  }
  getOne(id:number, language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/one/'+id.toString()+'/'+language, params );
  }
  getEmpty() {
    this.project = {
      id: 0,
      name: '',
      description: '',
      address_details_id: 0,
      plants_id: 0,
      distance: null,
      traveltime: null,
      projectstates_id: 1,  // Potential
      customer_id: 0,
      concrete_pricelists_id: null,
      article_pricelists_id: null,
      resource_pricelists_id: null,
      comments: '',
      address: this.AddressesService.getEmpty()
    }
    return this.project;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/', data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/', data);
  }
  deleteUse(pricelistsType: PricelistsType, id: number) {
    return this.HttpService.get(baseURL+'read/delete-use/'+id.toString()+'/'+pricelistsType);
  }
  assignPricelists(data: any) {
    return this.HttpService.post(baseURL+'update/pricelists', data);
  }
  getSelectList(language, category, pricelist_id, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/selectlist/'+category+'/'+pricelist_id.toString(), params );
  }
}
