import {Component, OnInit, OnDestroy, Output, EventEmitter, Input} from '@angular/core';
import {ResourcesService} from "../../../recipes/data/resources.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {
  JBMTableActionDef, JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";

@Component({
  selector: 'resource-selectlist',
  templateUrl: './resource_selectlist.component.html',
  styleUrls: ['./resource_selectlist.component.scss']
})
export class ResourceSelectListComponent implements OnInit, OnDestroy {
  items: any[]=[];
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  language: string=this.TokenService.getLanguage();
  id: number;
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @Input() caption: string;
  @Input() resource_pricelists_id: number=0;
  @Output() resourceSelected = new EventEmitter();

  constructor(
      private ResourcesService: ResourcesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'code', header: this.TranslateService.GetTranslation('entity.code'), sortable: true, filterable: true, width: 15 },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true}
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addAliasedColumn('code','r.code');
    this.queryHttpHelper.addAliasedColumn('description','r.description');
    this.queryHttpHelper.addSearchColumn('code', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=5;

    this.getData(true);

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('resource-search')).focus();
    },100);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.ResourcesService.getSelectList(this.resource_pricelists_id, params).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.ResourcesService.getSelectList(this.resource_pricelists_id, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  rowSelect(row) {
    // Emit event
    this.resourceSelected.emit( row );
    return false;
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
