import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {OrdersService} from "../orders.service";
import {Order} from "../interfaces/order";
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../../services/auth/token.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class OrderResolver implements Resolve<any> {
  language: string=this.TokenService.getLanguage();

  constructor(
      private Router: Router,
      private OrdersService: OrdersService,
      private TokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Order> {
    let id=parseInt(route.paramMap.get('order_id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      // Get the order inclusive related data
      let language=this.TokenService.getLanguage();
      let params=new HttpParams()
        .append('recipe','1')
        .append('consistency','1')
        .append('strength', '1')
        .append('environment','1')
        .append('worktype','1')
        .append('unloading_speed','1');
      return this.OrdersService.getOne(id,language,params).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
