<form [formGroup]="form" (ngSubmit)="save()" class="order" xmlns="http://www.w3.org/1999/html">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'project.order'|translate}} {{'ui.copy'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-date-input labelClass="lg" labelCaption="{{'order.production-date'|translate}}"
        [formControl]="formControls.production_date" [maxdate]="maxdate"
        placement="bottom">
         <jbm-input-error [required]="false" [formControl]="formControls.production_date" [submitted]="isSubmitted">
                     <span *ngIf="formControls['production_date'].hasError('dateInvalid')">
                       {{'entity.dateformat'|translate}}
                     </span>
         </jbm-input-error>
      </jbm-date-input>
      <jbm-time-input [formControl]="formControls.starttime" [submitted]="isSubmitted"
        [id]="'starttime'" labelCaption="{{'order.starttime'|translate}}" labelClass="lg"
        (onBlur)="calcEndTime(); calcLoadTime()">
         <jbm-input-error [formControl]="formControls.starttime" [submitted]="isSubmitted">
            {{'entity.timeformat'|translate}}
         </jbm-input-error>
      </jbm-time-input>
      <jbm-static-label *ngIf="loadTime" labelClass="lg" [labelCaption]="'order.loadtime'|translate" [value]="loadTime"> {{'datetime.hour'|translate}}</jbm-static-label>
      <jbm-static-label *ngIf="endTime" labelClass="lg" [labelCaption]="'order.endtime'|translate" [value]="endTime"> {{'datetime.hour'|translate}}</jbm-static-label>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-primary btn-sm" (click)="save()">{{'project.order'|translate}} {{'ui.copy'|translate|lowercase}}</button>
         <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>