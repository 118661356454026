import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RecipeAppRoutingModule } from './recipe-app-routing.module';
import {JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import {JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import {JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import {SharedModule} from "../../shared/shared.module";
import {RecipeUsersComponent} from "./users/recipe-users.component";
import {RecipeUserComponent} from "./users/form/recipe-user.component";
import {JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import {JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import {PasswordChangeComponent} from "./users/password/password-change/password-change.component";
import {
  PasswordConstraintsAlertComponent
} from "./users/password/password-constraints-alert/password-constraints-alert.component";
import { UserPermissionsComponent } from './users/user-permissions/user-permissions.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [
      RecipeUsersComponent, RecipeUserComponent, PasswordChangeComponent, PasswordConstraintsAlertComponent, UserPermissionsComponent
  ],
    imports: [
        CommonModule,
        RecipeAppRoutingModule,
        SharedModule,
        JBMTableModule,
        JBMViewModule,
        JBMFormModule,
        JBMButtonsModule,
        JBMModalModule,
        LayoutModule
    ]
})
export class RecipeAppModule { }
