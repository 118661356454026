export class PriceRulesTranslationKeys {
	setKeys(priceRules: any[], isConcretePrice: boolean=true) {
		for(let pr=0; pr<priceRules.length; pr++) {
			priceRules[pr].translationKey='';
			switch(priceRules[pr].entity) {
				case 'project':
					priceRules[pr].translationKey='entity.project';
					break;
				case 'baseprice':
					priceRules[pr].translationKey='financial.baseprice';
					break;
				case 'customer':
					priceRules[pr].translationKey='entity.customer';
					break;
				case 'customer.pricelist':
					priceRules[pr].translationKey='financial.customer-pricelist';
					break;
				case 'pricelist':
					if(isConcretePrice)
						priceRules[pr].translationKey='financial.concrete-pricelist';
					else
						priceRules[pr].translationKey='financial.article-pricelist';
					break;
				case 'plant':
					priceRules[pr].translationKey='company.plant';
					break;
				case 'baseprices':
					priceRules[pr].translationKey='pricelist.type-base';
					break;
				case 'company':
					priceRules[pr].translationKey='company';
					break;
			}
		}
		return priceRules;
	}
}
