import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'presentations/';

export enum presentation_types { quotation, quotationConfirmation, invoice}

@Injectable({
  providedIn: 'root'
})
export class PresentationTypesService {

  constructor(
      private HttpService: HttpService,
  ) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/types/'+language, params );
  }
  getOne(id :number, language: string) {
    let params=new HttpParams().append('id',id.toString());
    return this.HttpService.get(baseURL+'read/types/'+language, params );
  }
}
