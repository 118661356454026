import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
  HostListener,
  ElementRef,
  OnChanges, ViewChild
} from '@angular/core';
import {RecipesService} from "../../../../features/projects/data/recipes.service";
import {ConcreteEnvironmentsService} from "../../../../features/financial/data/concrete-environments.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnDef, JBMTableColumnType, JBMTableComponent} from "../../../JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'recipe-selector',
  templateUrl: './recipe-environments.selector.component.html',
  styleUrls: ['./recipe-environments.selector.component.scss']
})
export class RecipeEnvironmentsSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  recipe: any;
  environment: any= { id: 0, description: '' };
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;
  @Input() editable: boolean=true;
  @Input() isSubmitted: boolean=false;

  @Input() recipe_id: number=0;
  @Input() environment_id: number=0;
  @Output() recipeSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Input() template: TemplateRef<any>;

  @ViewChild('table') table: JBMTableComponent;

  constructor(
      private RecipesService: RecipesService,
      private ConcreteEnvironmentsService: ConcreteEnvironmentsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'code', header: this.TranslateService.GetTranslation('entity.code'), sortable: true, filterable: true },
      { name: 'strength', header: this.TranslateService.GetTranslation('concrete.strength-short'), filterable: true, width: 5 },
      { name: 'consistency', header: this.TranslateService.GetTranslation('concrete.consistency-short'), filterable: true, width: 11 },
      { name: 'environment_descriptions', header: this.TranslateService.GetTranslation('financial.concrete-environments'), filterable: true, width: 15 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('code');

    this.queryHttpHelper.addAliasedColumn('code','r.code');
    this.queryHttpHelper.addAliasedColumn('strength','s.code');
    this.queryHttpHelper.addAliasedColumn('consistency','c.code');

    this.queryHttpHelper.addSearchColumn('code', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('environment_descriptions', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('strength', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('consistency', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;

    this.environment.id=this.environment_id;
  }
  ngOnChanges() {
    if(this.recipe_id) this.setRecipeEnvironment();
  }
  toggleList() {
    if(!this.editable) return;
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('recipe-search').focus() },100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.recipe_id=0;
    this.environment_id=0;
    this.hideList();
    this.recipeSelected.emit( { recipe_id: 0, environment_id: 0 } );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.setParams(false);
    this.items$=this.RecipesService.get(this.language, params).subscribe(
        (data: any) => this.items = data.data,(error)=>console.error(error),()=> {
          this.formatData();
          if(countRows) {
            let params=this.setParams(true);
            this.count$=this.RecipesService.get(this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  setParams(countRows=false) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    params=params.append('published','1');
    return params;
  }
  formatData() {
    for(let recipe of this.items) {
      recipe.environment_descriptions='';
      for(let environment of recipe.environments)
        recipe.environment_descriptions+=environment.description+', ';
      recipe.environment_descriptions=recipe.environment_descriptions.substr(0,recipe.environment_descriptions.length-2);
    }
  }

  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    this.recipe_id=row.id;
    this.table.toggleRowExpanded(row);
  }
  select(environment_id) {
    // Emit event
    this.environment_id=environment_id;
    this.setRecipeEnvironment();
    this.hideList();
    this.recipeSelected.emit({ recipe_id: this.recipe_id, environment_id: this.environment_id } );
  }
  setRecipeEnvironment() {
    let params=new HttpParams().append('concrete-specifications', '1');
    this.RecipesService.getOne(this.recipe_id, this.language, params).subscribe(
        (data: any)=>this.recipe=data.data,
        (error)=>console.error(error),
        ()=> {
          this.ConcreteEnvironmentsService.getOne(this.environment_id, this.language).subscribe(
              (data)=>this.environment=data.data,
              (error)=>console.error(error)
          )
        }
    )
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }

  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
