import {AfterViewInit, Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from "@angular/common";
import {HelpTopicsService} from "../data/helptopics.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {StateService} from "../../../services/storage/state.service";
import {HelpTopic} from "../data/interfaces/helptopic";
import {CRUDAction} from "../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {HttpParams} from "@angular/common/http";
import {AngularEditorConfig} from "@kolkov/angular-editor/lib/config";

@Component({
  selector: 'helptopic-form',
  templateUrl: './helptopic.component.html',
  styleUrls: ['./helptopic.component.scss']
})

export class HelpTopicComponent implements OnInit, AfterViewInit {
  helpTopic: HelpTopic=this.HelpTopicsService.getEmpty();
  parentTopic: HelpTopic=this.HelpTopicsService.getEmpty();
  action: CRUDAction;
  language: string=this.TokenService.getLanguage();
  content: string;
  form: FormGroup;
  isSubmitted: boolean=false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Start hier met typen...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Helvetica, Arial, sans-serif;',
    toolbarHiddenButtons: [
      ['fontName']
    ],
    outline: false
  };

  constructor(
      private Router: Router,
      private Location: Location,
      private ActivatedRoute: ActivatedRoute,
      private formBuilder: FormBuilder,
      private HelpTopicsService: HelpTopicsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private StateService: StateService,
      private JBMToastsService: JBMToastsService,
  ) {
    if(this.Router.url.indexOf('create')>0) {
      // Get empty helptopic data
      this.action=CRUDAction.Create;
      // The route resolver has fetched the parent helptopic data
      this.parentTopic=this.ActivatedRoute.snapshot.data.helptopic.data;
    } else {
      // The route resolver has fetched the helptopic data
      this.action=CRUDAction.Update;
      this.helpTopic=this.ActivatedRoute.snapshot.data.helptopic.data;

      this.helpTopic.content=this.helpTopic.content.replace("\\",'');
      this.setParentTopic(this.helpTopic.parent_id);
    }
  }
  setParentTopic(parent_id: number) {
    let params=new HttpParams().append('id', parent_id.toString());
    this.HelpTopicsService.get(this.language, params).subscribe(
        (data)=>this.parentTopic=data.data as HelpTopic,
        (error)=>console.log(error)
    )
  }
  ngOnInit(): void {
    this.addControls();
    this.setControlValues();
  }
  ngAfterViewInit(): void {
    document.getElementById('title').focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      title: new FormControl('',[Validators.required])
    },{
      updateOn: "blur"
    });
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.title.setValue(this.helpTopic.title);
    this.content=this.helpTopic.content;
  }
  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    if(this.content===null || this.content=='') this.content=this.TranslateService.GetTranslation('ui.empty');

    let data={
      id: this.helpTopic.id,
      parent_id: this.parentTopic.id,
      l: {
        helptopics_id: this.helpTopic.id,
        language: this.language,
        title: this.formControls.title.value,
        content: this.content
      }
    }

    if(this.helpTopic.id===0)
      this.HelpTopicsService.create(data).subscribe(
          ()=>{},error => this.handleError(error),() => this.afterSave())
    else
      this.HelpTopicsService.update(data).subscribe(
          ()=>{},error => this.handleError(error),() => this.afterSave())
  }
  handleError(error) {
    if(error.error=='title_not_unique') {
      document.getElementById('title').focus();
    } else
      console.error(error);
  }
  afterSave() {
    // We go back to this parent level in the helptopics list
    this.StateService.addElement('parentid',this.parentTopic.id);

    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.redirect();
  }
  cancel() {
    // We go back to this parent level in the helptopics list
    this.StateService.addElement('parentid',this.parentTopic.id);

    this.redirect();
  }
  redirect() {
    this.Router.navigateByUrl('settings/helpcentre');
  }
}
