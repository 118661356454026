import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { JBMButtonsModule} from "../Buttons/jbmbuttons.module";
import { JBMTableModule} from "../JBMTable/jbmtable.module";
import { JBMViewModule } from "../Views/jbmview.module";
import { JBMFormModule } from "../Forms/jbmform.module";
import { JBMActivityLogComponent} from "./JBMActivityLog/JBMActivityLog.component";
import { JBMAsideTitleComponent} from "./JBMAsideTitle/JBMAsideTitle.component";
import { JBMHelpIndexComponent} from "./JBMHelpIndex/JBMHelpIndex.component";
import { JBMShortcutsComponent} from "./JBMShortcuts/JBMShortcuts.component";
import { JBMLocationMapComponent} from "./JBMLocationMap/JBMLocationMap.component";

@NgModule({
  declarations: [JBMActivityLogComponent, JBMAsideTitleComponent, JBMHelpIndexComponent, JBMLocationMapComponent, JBMShortcutsComponent],
  exports: [JBMActivityLogComponent, JBMAsideTitleComponent, JBMHelpIndexComponent, JBMLocationMapComponent, JBMShortcutsComponent],
  imports: [CommonModule, SharedModule, JBMButtonsModule, JBMTableModule, JBMViewModule, JBMFormModule]
})
export class JBMAsidesModule { }
