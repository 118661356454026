import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {PriceService} from "../../../services/price/price.service";
import {InvoiceItemsService} from "../data/invoice-items.service";
import {InvoiceConcretesService} from '../data/invoice-concretes.service';
import {InvoiceArticlesService} from "../data/invoice-articles.service";
import {ArticlesService} from "../../financial/data/articles.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {DatetimeService} from "../../../services/datetime/datetime.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {SettingsService} from "../../../services/storage/settings.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {HttpParams} from "@angular/common/http";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerKeyboardService,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import {CustomDatepickerI18n} from "../../../services/NgbDatepicker/custom-datepicker-i18n.service";
import {CustomAdapter} from "../../../services/NgbDatepicker/custom-adapter.service";
import {CustomDateParserFormatter} from "../../../services/NgbDatepicker/custom-dateparser-formatter.service";
import {CustomKeyboardService} from "../../../services/NgbDatepicker/custom-keyboard-service";
import {price_usage_subjects} from "../../financial/data/price-usages.service";
import {price_type_system_codes} from "../../projects/data/order-articles.service";
import {article_usages} from "../data/invoice-articles.service";
import {invoice_usage} from "../data/invoices.service";

@Component({
  selector: 'invoice-items',
  templateUrl: './invoice-items.component.html',
  styleUrls: ['./invoice-items.component.scss'],
  providers: [
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, // define custom NgbDatepickerI18n provider
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService}
  ]
})
export class InvoiceItemsComponent implements OnInit, OnChanges {
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;
  items: any[]=[];
  item: any;
  itemAmount: number=0;
  settingSubjects: any={};
  settings: any;
  subject: number=price_usage_subjects.invoice_article;
  resources_subject: number=price_usage_subjects.invoice_resource;
  invoice_item_id: number=0;
  recipe_comment_id: number=-1;
  order_comment_id: number=-1;
  usage: number=-1;
  delivery_date: string='';

  recipe: string='';
  recipe_id: number=0;
  environment: string='';

  creating: boolean = false;
  editing: boolean = false;
  id: number = 0;
  order_id: number=0;

  article: any;
  article_id: number = 0;
  allowedPricetypes = [price_type_system_codes.M3, price_type_system_codes.M, price_type_system_codes.M2,
    price_type_system_codes.PIECE];
  article_price_types_id: number=price_type_system_codes.M3;

  code: string = '';
  date: string='';
  dateTouched: boolean=false;
  amount: string = '';
  amountTouched: boolean = false;
  price: string = '';
  priceTouched: boolean = false;
  description: string = '';
  descriptionTouched: boolean = false;
  totalPrice: number = 0.0;

  constructor(
      private InvoiceItemsService: InvoiceItemsService,
      private InvoiceConcretesService: InvoiceConcretesService,
      private InvoiceArticlesService: InvoiceArticlesService,
      private ArticlesService: ArticlesService,
      private SanitizationService: SanitizationService,
      private TranslateService: TranslateService,
      private DatetimeService: DatetimeService,
      private JBMToastsService: JBMToastsService,
      private ModalService: ModalService,
      private PriceService: PriceService,
      private TokenService: TokenService,
      private SettingsService: SettingsService
  ) {}

  @Input() invoice: any;
  @Input() userRights: GroupRights;
  @Input() editable: boolean;
  @Input() viewSettings: any;

  @Output() onUpdateTotalsNeeded=new EventEmitter();

  @ViewChild('modalOrderDetails') modalOrderDetails: ElementRef;
  @ViewChild('modalConcreteComments') modalConcreteComments: ElementRef;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;

  ngOnInit(): void {
    if(this.invoice==undefined) this.invoice={};
    if(this.editable==undefined) this.editable=true;
    this.loading=true;

    this.SettingsService.getSubjects().subscribe(
        (data)=>this.settingSubjects=data.data,(error)=>console.error(error),
        ()=>
            this.SettingsService.getSubject(this.settingSubjects.invoicing,0).subscribe(
                (data)=>this.settings=data.data,
                (error)=>console.error(error)
            )
    )
  }
  ngOnChanges() {
    this.getData();
  }
  getData() {
    let params=new HttpParams().append('concretes','1').append('extra_articles','1');
    this.InvoiceItemsService.get( this.language, this.invoice.id, params).subscribe(
        (data)=>this.items=data.data,
        (error)=>console.error(error),
        ()=> {
          this.formatData();
          if(this.loading) this.loading=false;
        }
    )
  }
  formatData() {
    for(let item of this.items) {
      item.amountStr=this.SanitizationService.amountFloatToStr(item.amount,1);
      item.priceStr=this.PriceService.formatPrice(item.price);
      item.totalpriceStr=this.PriceService.formatPrice(item.totalprice);
      item.editing=false;
      for(let recipe_article of item.recipe_articles)
        recipe_article.editing=false;
      for(let concrete of item.concretes)
        for(let order_article of concrete.order_articles)
          order_article.editing=false;
      for(let extra_article of item.extra_articles)
        extra_article.editing=false;
    }
  }

  // Order
  showOrder(id: number) {
    this.order_id=id;
    this.ModalService.open(this.modalOrderDetails,'orderDetails')
  }
  closeOrder() {
    this.ModalService.close('orderDetails');
  }
  editOrderComment(item_id: number) {
    this.order_comment_id=item_id;
  }
  cancelOrderComment() {
    this.order_comment_id=-1;
  }
  saveOrderComment(concrete: any) {
    this.InvoiceConcretesService.updateExternalOrderComment(concrete.id, concrete.external_order_comment).subscribe(
      ()=>{}, (error)=>console.error(error), ()=> {
        this.order_comment_id=-1;
        this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
      }
    )
  }
  editConcreteComments(item: any) {
    console.log(item);
    this.item=item;
    this.ModalService.open(this.modalConcreteComments,'concreteComments')
  }
  closeConcreteComments() {
    this.ModalService.close('concreteComments');
  }

  // Recipe
  editRecipeComment(item_id: number) {
    this.recipe_comment_id=item_id;
  }
  cancelRecipeComment() {
    this.recipe_comment_id=-1;
  }
  saveRecipeComment(item: any) {
    this.InvoiceItemsService.updateExternalRecipeComment(item.id, item.external_recipe_comment).subscribe(
        ()=>{}, (error)=>console.error(error), ()=> {
          this.recipe_comment_id=-1;
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
        }
    )
  }

  // Invoice items
  editItem(id: number) {
    if(this.invoice.usage!==invoice_usage.orders)
      // Editing allowed when invoice usage is orders based
      return;
    if(!this.editable) return;

    let item=this.getItem(id);
    if(!item || item.editing) return false;

    this.id=item.id;

    item.editing=true;
    this.editing=true;
  }
  cancelItem() {
    this.editing = false;
    this.clearItemData();
  }
  saveItem() {
    this.editing = false;
    this.updateItem();
  }
  getItem(id: number) {
    for(let invoice_item of this.items)
        if(invoice_item.id===id) {
          this.date=invoice_item.delivery_date;
          return invoice_item;
        }
    return false;
  }

  // Recipe articles
  editRecipeArticle(id: number) {
    if(!this.editable) return;

    let article=this.getRecipeArticle(id);
    if(!article || article.editing) return false;

    if(this.creating)
      this.cancelCreating();

    if(this.editing && this.id!==article.id)
      this.cancelEditing();

    article['editing']=true;
    this.editing=true;
    this.clearItemData();
    this.setItemData(article);
    this.totalPrice=this.InvoiceArticlesService.calcTotalPrice(
        article.price, article.amount, this.article_price_types_id, this.itemAmount);

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('recipe-article-amount-' + id.toString())).focus();
    },100);
  }
  cancelRecipeArticle() {
    let article=this.getRecipeArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.clearItemData();
  }
  saveRecipeArticle() {
    let article=this.getRecipeArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;

    this.updateArticle(article);
  }
  getRecipeArticle(id: number) {
    for(let invoice_item of this.items)
      for(let recipe_article of invoice_item.recipe_articles)
        if(recipe_article.id===id) {
          this.itemAmount=invoice_item.amount;
          this.usage=article_usages.recipe;
          this.article_price_types_id=recipe_article.article_price_types_id;
          return recipe_article;
        }
    return false;
  }

  // Order articles
  editOrderArticle(id: number) {
    if(!this.editable) return;

    let article=this.getOrderArticle(id);
    if(!article || article.editing) return false;

    if(this.creating)
      this.cancelCreating();

    if(this.editing && this.id!==article.id)
      this.cancelEditing();

    article['editing']=true;
    this.editing = true;
    this.clearItemData();
    this.setItemData(article);
    this.totalPrice=this.InvoiceArticlesService.calcTotalPrice(
        article.price, article.amount, this.article_price_types_id, this.itemAmount);

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('order-article-amount-' + id.toString())).focus();
    },100);
  }
  cancelOrderArticle() {
    let article=this.getOrderArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.clearItemData();
  }
  saveOrderArticle() {
    let article=this.getOrderArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;

    this.updateArticle(article);
  }
  getOrderArticle(id: number) {
    for(let invoice_item of this.items)
      for(let concrete of invoice_item.concretes)
        for(let order_article of concrete.order_articles)
          if(order_article.id===id) {
            this.usage=article_usages.order;
            this.article_price_types_id=order_article.article_price_types_id;
            this.itemAmount=invoice_item.amount;
            return order_article;
          }
    return false;
  }

  editExtraArticle(id: number) {
    if(!this.editable) return;

    let article=this.getExtraArticle(id);
    if(!article || article.editing) return false;

    if(this.creating)
      this.cancelCreating();

    if(this.editing && this.usage!==article_usages.extra)
      this.cancelEditing();

    article['editing']=true;
    this.editing = true;
    this.clearItemData();
    this.setItemData(article);
    this.totalPrice=this.InvoiceArticlesService.calcTotalPrice(
        article.price, article.amount, this.article_price_types_id, this.itemAmount);

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('extra-article-amount-' + id.toString())).focus();
    },100);
  }
  cancelExtraArticle() {
    let article=this.getExtraArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.clearItemData();
  }
  saveExtraArticle() {
    let article=this.getExtraArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.updateArticle(article);
  }
  getExtraArticle(id: number) {
    for(let invoice_item of this.items)
      for(let extra_article of invoice_item.extra_articles)
        if(extra_article.id===id) {
          this.usage=article_usages.extra;
          this.itemAmount=invoice_item.amount;
          this.article_price_types_id=extra_article.article_price_types_id;
          return extra_article;
        }
    return false;
  }

  // Create item
  startCreating(invoice_item_id: number) {
    if(this.editing) this.cancelEditing();
    if(this.creating && this.invoice_item_id!==invoice_item_id)
      this.cancelCreating();
    this.invoice_item_id=invoice_item_id;
    this.creating=true;
    this.clearItemData();
    this.setItemAmount();
  }
  cancelCreating() {
    this.invoice_item_id=-1;
    this.creating=false;
    this.clearItemData();
  }
  setItemAmount() {
    for(let item of this.items)
      if(item.id===this.invoice_item_id)
        this.itemAmount=item.amount;
    return 0;
  }

  selectArticle(article) {
    if(!article.id) return;
    this.article=article;
    this.article_id=article.id;
    this.article_price_types_id=article.article_price_types_id;
    this.code=article.price_type_code;
    this.price=this.SanitizationService.amountFloatToStr(article.price);
    this.description=article.name;

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('extra-article-amount-0')).focus();
    },100);
  }
  setItemData(article) {
    this.id=article.id;
    this.article_id=article.articles_id;
    this.date=article.delivery_date===null ? '' : article.delivery_date;
    this.article_price_types_id=article.article_price_types_id;
    this.code=article.price_type_code;
    this.amount=this.SanitizationService.amountFloatToStr(article.amount);
    this.price=this.SanitizationService.amountFloatToStr(article.price);
    this.description=article.description;
  }

  cancelEditing() {
    for(let item of this.items) {
      for(let recipe_article of item.recipe_articles)
        recipe_article.editing=false;
      for(let concrete of item.concretes)
        for(let order_article of concrete.order_articles)
          order_article.editing=false;
      for(let extra_article of item.extra_articles)
        extra_article.editing=false;
    }
  }
  clearItemData() {
    this.id=0;
    this.date='';
    this.dateTouched=false;
    this.article_id=0;
    this.amount='';
    this.amountTouched=false;
    this.price='';
    this.totalPrice=null;
    this.priceTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  calculateTotalPrice() {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
    let price  = this.SanitizationService.checkPriceStr(this.price);

    this.totalPrice=this.InvoiceArticlesService.calcTotalPrice(price, amount, this.article_price_types_id, this.itemAmount );
  }
  selectDate(event) {}
  onChangeDate() {
    this.dateTouched=true;
  }
  validateDate() {
    if(this.date===null) return false;
    this.date=this.date.trim();
    return !this.date || this.DatetimeService.validateDateStr(this.date);
  }
  onChangeAmount() {
    this.amountTouched=true;
    this.calculateTotalPrice();
  }
  validateAmount() {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount,0);
    if(this.article_price_types_id==price_type_system_codes.PERCENT)
      return (amount>0 && amount<=100)
    return amount > 0;
  }
  onChangePrice() {
    this.price=this.price.trim();
    if(this.price==='') this.price='0';
    this.priceTouched=true;
    this.calculateTotalPrice();
  }
  validatePrice() {
    return this.SanitizationService.checkPriceStr(this.price) >= 0;
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    return this.description.trim()!=='';
  }

  updateItem() {
    let delivery_date=this.getDeliveryDate();
    let data={
      id: this.id,
      delivery_date: delivery_date,
    }
    this.InvoiceItemsService.update(data).subscribe(()=>{},(error)=>console.error(error),()=> this.afterSave())
  }

  insertArticle() {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount);
    let price =this.SanitizationService.checkPriceStr(this.price);
    this.calculateTotalPrice();

    let data= {
      id: 0,
      invoices_id: this.invoice.id,
      invoice_items_id: this.invoice_item_id,
      delivery_date: this.getDeliveryDate(),
      orders_id: null,
      articles_id: this.article_id,
      article_price_types_id: this.article_price_types_id,
      description: this.description,
      article_display: 0,
      usage: article_usages.extra,
      price: price,
      amount: amount,
      totalprice: this.totalPrice
    }
    this.InvoiceArticlesService.create(data).subscribe(()=>{},(error)=>console.error(error),()=> this.afterSave())
  }
  updateArticle(article: any) {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount);
    let price =this.SanitizationService.checkPriceStr(this.price);
    this.calculateTotalPrice();

    // Update fields in interface to prevent showing old values before data update completion
    article.amount=amount;
    article.price=price;
    article.totalprice=this.totalPrice;

    let delivery_date=this.getDeliveryDate();
    let data={
      id: this.id,
      invoices_id: article.invoices_id,
      invoice_items_id: article.invoice_items_id,
      articles_id: this.article_id,
      usage: article.usage,
      delivery_date: delivery_date,
      description: article.usage===article_usages.recipe ? article.description : this.description,
      price: price,
      amount: amount,
      totalprice: this.totalPrice
    }
    this.InvoiceArticlesService.update(data).subscribe(()=>{},(error)=>console.error(error),()=> this.afterSave())
  }
  getDeliveryDate() {
    let delivery_date=null;
    if(this.date)
      delivery_date=this.DatetimeService.dateDMYToYMD(this.date);
    return delivery_date;
  }
  afterSave() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.getData();
    this.cancelCreating();
    this.cancelEditing();
    this.onUpdateTotalsNeeded.emit();
  }
  delete(invoice_article_id: number) {
    this.InvoiceArticlesService.delete(invoice_article_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.onUpdateTotalsNeeded.emit();
          this.getData();
        }
    )
  }
  showRecipe(recipe_id: number, delivery_date: string, environment: string) {
    this.recipe_id=recipe_id;
    this.delivery_date=delivery_date;
    this.environment=environment;
    this.ModalService.open(this.modalRecipe,'itemRecipe');
  }
  closeRecipe() {
    this.ModalService.close('itemRecipe');
  }
}
