import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {ArticlePricelistsService} from "../article-pricelists.service";
import {ArticlePricelist} from "../interfaces/article-pricelist";
import {TokenService} from "../../../../services/auth/token.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ArticlePricelistResolver implements Resolve<any> {
  language: string=this.TokenService.getLanguage();

  constructor(
      private Router: Router,
      private ArticlePricelistsService: ArticlePricelistsService,
      private TokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ArticlePricelist> {
    let id=parseInt(route.paramMap.get('id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else
      return this.ArticlePricelistsService.getOne(0, id, this.language).pipe(catchError(this.handleError));
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
