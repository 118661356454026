import { Injectable } from '@angular/core';
import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {CustomerService} from "../customer.service";
import {EMPTY, Observable, throwError} from "rxjs";
import {Customer} from "../interfaces/customer";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class CustomerResolver implements Resolve<any> {

  constructor(
      private CustomerService: CustomerService,
      private Router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Customer> {
    let id=parseInt(route.paramMap.get('id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      // Get the customer inclusive addresses
      return this.CustomerService.get(id,true).pipe(catchError(this.handleError));
    }

  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
