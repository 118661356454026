import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {QuotationsService} from "../quotations.service";
import {Quotation} from "../interfaces/quotation";
import {HttpParams} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class QuotationResolver implements Resolve<any> {
  constructor(
      private Router: Router,
      private QuotationsService: QuotationsService,
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Quotation> {
    let id=parseInt(route.paramMap.get('quotation_revision_id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      // Get the quotation inclusive related data
      let params=new HttpParams()
          .append('customer','1')
          .append('project','1')
          .append('permissions','1')
      // Get the quotation revision
      return this.QuotationsService.getByRevision(id, params).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
