import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvoicesService} from "../../data/invoices.service";
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {FormControl, FormGroup} from "@angular/forms";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'invoice-customer-comments',
  templateUrl: './customer-comments.component.html',
  styleUrls: ['./customer-comments.component.scss']
})
export class CustomerCommentsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  invoice: any= { invoice_number: '', customer_comments: '' };
  form: FormGroup;
  submitted: boolean=false;

  constructor(
      private InvoicesService: InvoicesService,
      private FormBuilder: FormBuilder,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) {
    this.form = this.FormBuilder.group({
      customer_comments: new FormControl('' ),
    });
  }

  @Input() invoice_id: number;

  @Output() onClose=new EventEmitter();
  @Output() onSave=new EventEmitter();

  ngOnInit(): void {
    this.InvoicesService.getOne(this.language, this.invoice_id,null).subscribe(
        (data)=>this.invoice=data.data,
        (error)=>console.error(error),
        ()=> {
          this.form.controls.customer_comments.setValue(this.invoice.customer_comments);
          document.getElementById('customer_comments').focus()
        }
    )
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    // Store customer comments
    let details={
      id: this.invoice.id,
      customer_comments: this.form.controls.customer_comments.value,
    }
    this.InvoicesService.update(details).subscribe(
        ()=>{},error=>console.error(error),()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.details-saved'));
          this.onSave.emit( { comments: details.customer_comments } );
        })
  }
  close() {
    this.onClose.emit();
  }

}
