import {Component, Input, OnChanges, OnInit} from '@angular/core';

@Component({
  selector: 'jbm-price-rules-table',
  templateUrl: './JBMPriceRulesTable.component.html',
  styleUrls: ['./JBMPriceRulesTable.component.scss']
})
export class JBMPriceRulesTableComponent implements OnInit {

  @Input() isConcrete: boolean;
  @Input() isConcreteBasePrice: boolean;
  @Input() pricerules: any[];

  constructor() { }

  ngOnInit(): void {
    if(this.isConcrete==undefined) this.isConcrete=false;
    if(this.isConcreteBasePrice==undefined) this.isConcreteBasePrice=false;
    if(this.pricerules==undefined) this.pricerules=[];
  }
}
