import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {TextPlaceholdersService} from "../../data/text_placeholders.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {LanguagesService} from "../../../../services/multilingual/languages.service";
import {TokenService} from "../../../../services/auth/token.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from '../../../users/data/interfaces/grouprights';

@Component({
  selector: 'app-text-placeholders',
  templateUrl: './text-placeholders.component.html',
  styleUrls: ['./text-placeholders.component.scss']
})
export class TextPlaceholdersComponent implements OnInit {
  userRights: GroupRights;
  items: any[];
  columnDefs: JBMTableColumnDef[];
  languages: any[]=[];
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;
  id: number=0;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('modalTextPlaceholder') modalTextPlaceholder: ElementRef;

  constructor(
      private TextPlaceholdersService: TextPlaceholdersService,
      private TokenService: TokenService,
      private ModalService: ModalService,
      private TranslateService: TranslateService,
      private LanguagesService: LanguagesService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('presentations');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id},
      { name: 'name', header: this.TranslateService.GetTranslation('presentations.placeholder'), sortable: true, filterable: true, width: 15},
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true, width: 15},
      { name: 'text', header: this.TranslateService.GetTranslation('ui.text'), filterable: true },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 7 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('text', SearchColumnType.string);

    // Set language options and counts
    this.languages=this.LanguagesService.GetLanguages();

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    params=params.append('between_placeholders','1');
    this.items$=this.TextPlaceholdersService.get(this.language, params).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          this.loading=false;

          if(this.items.length)
            this.setDeletePermissions();

          // Set actions column template
          this.columnDefs[4].template=this.actionsTemplate;

          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.TextPlaceholdersService.get(this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  setDeletePermissions() {
    let ids=[];
    for(let i=0; i<this.items.length; i++ ) {
      this.items[i].deletePermission=false;
      ids.push(this.items[i].id);
    }
    let deletePermissions=[];
    this.TextPlaceholdersService.getDeletePermissions(ids).subscribe(
        (data)=>deletePermissions=data.data,
        (error)=>console.error(error),
        ()=>{
          for(let i=0; i<this.items.length; i++ )
            this.items[i].deletePermission=(deletePermissions.indexOf(this.items[i].id)>-1);
        }
    )
  }
  languageChange(event) {
    this.language=event.target.value;
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }

  addItem() {
    this.id=0;
    this.ModalService.open(this.modalTextPlaceholder,'textPlaceholder');
  }
  rowSelect(event) {
    this.edit(event.id);
  }
  edit(id: number) {
    this.id=id;
    this.ModalService.open(this.modalTextPlaceholder,'textPlaceholder');
  }
  delete(id) {
    this.TextPlaceholdersService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData(true);
        }
    )
  }
  onSaved() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.ModalService.close('textPlaceholder');
    this.getData();
  }
  onCanceled() {
    this.ModalService.close('textPlaceholder');
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
