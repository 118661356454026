import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecipesService} from "../../../features/recipes/data/recipes.service";
import {ConcreteConsistenciesService} from "../../../features/financial/data/concrete-consistencies.service";
import {ConcreteStrengthsService} from "../../../features/financial/data/concrete-strengths.service";
import {TokenService} from "../../../services/auth/token.service";
import {PriceService} from "../../../services/price/price.service";
import {HttpParams} from "@angular/common/http";
import {Recipe} from "../../../features/recipes/data/interfaces/recipe";

@Component({
  selector: 'recipe-details',
  templateUrl: './recipe-details.component.html',
  styleUrls: ['./recipe-details.component.scss']
})
export class RecipeDetailsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  recipe: Recipe=this.RecipesService.getEmpty();

  constructor(
      private RecipesService: RecipesService,
      private ConcreteConsistenciesService: ConcreteConsistenciesService,
      private ConcreteStrengthsService: ConcreteStrengthsService,
      private PriceService: PriceService,
      private TokenService: TokenService
  ) {
    this.recipe.consistency=this.ConcreteConsistenciesService.getEmpty();
    this.recipe.strength=this.ConcreteStrengthsService.getEmpty();
    this.recipe.articles=[];
    this.recipe.resources=[];
  }

  @Input() recipe_id: number;
  @Input() delivery_date: string;
  @Input() environment: string;

  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    let params=new HttpParams()
        .append('articles','1')
        .append('resources','1')
        .append('types','1')
        .append('concrete-specifications','1');
    this.RecipesService.getOne(this.recipe_id, this.language, params ).subscribe(
        (data)=>this.recipe=data.data,
        (error)=>console.error(error),
        ()=> {
          for(let article of this.recipe.articles)
            article.amount=this.PriceService.formatPrice(article.amount) + ' ' + article.article.price_type_code;
          for(let resource of this.recipe.resources)
            resource.amount=this.PriceService.formatPrice(resource.amount);
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
