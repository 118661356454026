import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {MediaFilesService} from "../../data/media_files.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {SanitizationService} from '../../../../services/sanitization/sanitization.service';
import {MediaFile} from "../../data/interfaces/media_file";
import {GroupRights} from "../../../users/data/interfaces/grouprights";

@Component({
  selector: 'media-files',
  templateUrl: './media-files.component.html',
  styleUrls: ['./media-files.component.scss']
})
export class MediaFilesComponent implements OnInit, OnDestroy {
  userRights: GroupRights;
  mediaFile: MediaFile;
  base64: string;
  fileExtension: string;
  description: string='';
  preview: boolean=false;

  items: any;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchBoxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('modalMediaFile') modalMediaFile: ElementRef;
  @ViewChild('table') table: JBMTableComponent;

  constructor(
      private Router: Router,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private MediaFilesService: MediaFilesService,
      private SanitizationService: SanitizationService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) {}
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('presentations');

    this.base64='';
    this.fileExtension='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true},
      { name: 'filename', header: this.TranslateService.GetTranslation('ui.filename'), sortable: true, filterable: true, width: 18 },
      { name: 'extension', header: this.TranslateService.GetTranslation('ui.file-extension'), sortable: true, filterable: true, width: 8 },
      { name: 'size', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('ui.file-size'), sortable: true, width: 5 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 13.57 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('filename', SearchColumnType.string);

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.MediaFilesService.get(params).subscribe(
        (data: any) => this.items = data.data,(error) => console.error(error),()=> {

          if(this.items.length)
            this.setDeletePermissions();

          for(let item of this.items)
            item.size = this.SanitizationService.formatFilesize(item.size);

          // Set actions column template
          this.columnDefs[5].template=this.actionsTemplate;

          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.MediaFilesService.get(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  setDeletePermissions() {
    let ids=[];
    for(let i=0; i<this.items.length; i++ ) {
      this.items[i].deletePermission=false;
      ids.push(this.items[i].id);
    }
    let deletePermissions=[];
    this.MediaFilesService.getDeletePermissions(ids).subscribe(
        (data)=>deletePermissions=data.data,
        (error)=>console.error(error),
        ()=>{
          for(let i=0; i<this.items.length; i++ )
            this.items[i].deletePermission=(deletePermissions.indexOf(this.items[i].id)>-1);
        }
    )
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  addItem() {
    this.mediaFile=this.MediaFilesService.getEmpty();
    this.ModalService.open(this.modalMediaFile,'mediafile');
  }
  rowSelect(event) {
    this.showPreview(event);
  }
  showPreview(event) {
    this.base64='';
    this.fileExtension='';
    this.MediaFilesService.getOne(event.id).subscribe(
        (data)=> {
          this.base64=data.content;
          this.fileExtension=data.extension;
          this.description=data.description;
          this.preview=true;
        },
        (error)=> console.error(error)
    )
  }
  edit(id: number) {
    this.MediaFilesService.getOne(id).subscribe(
        (data)=>this.mediaFile=data as MediaFile,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.modalMediaFile,'mediafile')
    );
  }
  delete(id) {
    this.MediaFilesService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData(true);
        }
    )
  }
  saved() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.ModalService.close('mediafile');
    this.getData();
  }
  canceled() {
    this.ModalService.close('mediafile');
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
