import {Component, ElementRef, HostListener, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from "../../../services/multilingual/translate.service";

@Component({
  selector: 'truck-capacity-indication',
  templateUrl: './truck-capacity-indication.component.html',
  styleUrls: ['./truck-capacity-indication.component.scss']
})
export class TruckCapacityIndicationComponent implements OnInit, OnChanges {
  visible: boolean=false;
  graphData=[];
  referenceLines=[];
  trucksLabel: string;
  plantLabel: string;

  constructor(private TranslateService: TranslateService, private eRef: ElementRef) {}

  @Input() truckHours: any[]=[];

  ngOnInit(): void {
    this.trucksLabel=this.TranslateService.GetTranslation('production.truck')+
        this.TranslateService.GetTranslation('datetime.hours').toLowerCase();
    this.plantLabel=this.TranslateService.GetTranslation('company.plant');
  }
  ngOnChanges(): void {
    let trucks=this.TranslateService.GetTranslation('production.trucks').toLowerCase();
    let hour=this.TranslateService.GetTranslation('datetime.hour');

    this.graphData=[];
    for(let s=0; s<this.truckHours.length; s++) {
      let hours=[];

      // Calculate maximum value
      let max=0;
      for(let h=0; h<this.truckHours[s].truckHours.length; h++)
        max=Math.max(this.truckHours[s].truckHours[h].totalTrucks, max);

      for(let h=0; h<this.truckHours[s].truckHours.length; h++) {
        hours.push( {
          value: this.truckHours[s].truckHours[h].totalTrucks,
          percentage: Math.ceil(this.truckHours[s].truckHours[h].totalTrucks /max * 100 ),
          name: this.truckHours[s].truckHours[h].hour.toString(),
        });
      }

      let trucks_per_hour=Math.round(this.truckHours[s].plant.production_capacity / this.truckHours[s].average_truck_capacity);
      this.referenceLines.push({ name: this.truckHours[s].plant.code +
            ' (' + trucks_per_hour + ' ' + trucks + '/' + hour + ')', value: trucks_per_hour });
      this.graphData.push( { "name": this.truckHours[s].plant.code +
        ' (' + trucks_per_hour + ' ' + trucks + '/' + hour + ')', "series": hours });
    }
  }
  toggle() {
    this.visible=!this.visible;
  }
  @HostListener('document:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    if(event.key === 'Escape')
      this.toggle();
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.visible=false;
  }
}
