import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { MailTemplate } from "./interfaces/mail_template";

const baseURL: string = 'presentations/';
const segment: string = 'mail-templates/';

@Injectable({
  providedIn: 'root'
})
export class MailTemplatesService {
  mail_template: MailTemplate;

  constructor(
      private HttpService: HttpService,
  ) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language, params );
  }
  getOne(language: string, id :number) {
    return this.HttpService.get(baseURL+'read/one/'+segment+language+'/'+id.toString(),null );
  }
  getEmpty() {
    this.mail_template = {
      id: 0,
      presentations_id: 0,
      sender: '',
      language: '',
      subject: '',
      html_body: this.getHTMLBody(),
      text_body: ''
    }
    return this.mail_template;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+id.toString());
  }

  /* Email XHTML head snippet */
  getHTMLHead() {
    return '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n' +
        '<html xmlns="http://www.w3.org/1999/xhtml" lang="en-GB">\n' +
        '<head>\n' +
        '  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />\n' +
        '  <title>Email</title>\n' +
        '  <meta name="viewport" content="width=device-width, initial-scale=1.0"/>\n' +
        '\n' +
        '  <style type="text/css">\n' +
        '    a[x-apple-data-detectors] {color: inherit !important;}\n' +
        '  </style>\n' +
        '\n' +
        '</head>\n';
  }
  getHTMLBody() {
    return '<body style="background-color: #fff;">\n</body>';
  }
  getHTMLClose() {
    return '</html>';
  }
}
