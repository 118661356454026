import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../../shared/shared.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import { JBMButtonsModule } from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMModalModule } from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import { SelectorsModule} from "../../components/domain/selectors/selectors.module";
import { PlanningRoutingModule } from './planning-routing.module';
import { PlanningComponent } from './planning.component';
import { TaskDetailsComponent} from './task-details/task-details.component';
import { TruckCapacityIndicationComponent} from "./truck-capacity-indication/truck-capacity-indication.component";
import { PlanOrderComponent} from "./plan-order/plan-order.component";
import { NgxChartsModule} from "ngx-charts-reflines";
import { InstantProductionOrderComponent } from './instant-production-order/instant-production-order.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [PlanningComponent, PlanOrderComponent, TaskDetailsComponent, TruckCapacityIndicationComponent,
	  InstantProductionOrderComponent],
    imports: [CommonModule, PlanningRoutingModule, SharedModule, JBMViewModule, JBMFormModule, JBMTableModule,
        JBMButtonsModule, JBMModalModule, JBMDropdownsModule, SelectorsModule, NgxChartsModule, LayoutModule]
})
export class PlanningModule { }
