import { Injectable } from '@angular/core';

export interface menuItem {
  id: number;
  parent_id: number;
  header: string;
  url: string;
  name: string;
  right_id: number;
  sorting_order: number;
  icon_class: string;
  subitems: menuItem[];
  expanded: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menuItem: menuItem;

  constructor() {}

  // Transforms given (flat) menu items list into menu tree structure
  buildMenuTree(menu: menuItem[]): menuItem[] {
    // Supply each menuitem with subitems array
    for(let m=0; m<menu.length; m++)
      menu[m].subitems=[];

    // Move each candidate child menuitem to its parent item
    let m=0;
    do {
      let hasParent=(menu[m].parent_id>0);
      if(hasParent) {
        let parent = this.findMenuItem(menu, menu[m].parent_id);
        if(parent) parent.subitems.push(menu[m]);
        menu.splice(m, 1);
      } else
        m++;
    } while (m<menu.length);
    return menu;
  }
  // Find menuitem with id <id> in menu tree structure
  findMenuItem(menu: menuItem[], id: number ): menuItem {
    this.menuItem=null;
    for(let m=0; m<menu.length; m++) {
      if(menu[m].id == id)
        return menu[m];
      if(menu[m].subitems.length)
        this.findMenuItem(menu[m].subitems, id);
    }
    return this.menuItem;
  }
  // Get menuitems within given parent menuitem <parent_id>
  getMenuItemsByParentId(menu: menuItem[], parent_id: number ): menuItem[] {
    let items=[];
    for(let m=0; m<menu.length; m++)
      if(menu[m].parent_id == parent_id)
        items.push(menu[m]);
    return items;
  }
  // Get menuitem iconClassname for the given menuitem name <name>
  getMenuItemIconClassname(menu: menuItem[], name: string ): string {
    for(let m=0; m<menu.length; m++)
      if(menu[m].name == name)
        return menu[m].icon_class;
    return '';
  }

}
