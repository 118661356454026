import {Injectable } from '@angular/core';
import {HttpService} from "../http.service";
import {BrowserLanguage} from "./browser.language";
import {LocalStorageService} from "../storage/localstorage.service";
import {HttpParams} from "@angular/common/http";
import {View} from "../../features/translations/translations.component";

const baseUrl = 'translations/';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  BrowserLanguage: BrowserLanguage;

  constructor( private HttpService: HttpService, private LocalStorageService: LocalStorageService ) {
    this.BrowserLanguage=new BrowserLanguage();
  }
  GetLanguage() {
    let result = this.LocalStorageService.get('language');
    if (result === null)
      return this.BrowserLanguage.getLanguage();
    else
      return result;
  }
  LoadTranslations() {
    let translations=[];
    this.HttpService._get(baseUrl+'read/'+this.GetLanguage()).subscribe(
      (data: any[]) => translations=data,(error)=>{console.log(error)},()=>
        this.LocalStorageService.set('translation', translations));
  }
  GetTranslation(key: string) {
    key = key.trim().toLowerCase();
    let translations = this.LocalStorageService.get('translation');
    if(translations===null)
      return key;
    if(translations.hasOwnProperty(key))
      return translations[key].translation;
    return key;
  }
  getData(view: View, GETParams: string, params: HttpParams) {
    if(view.translated)
      // Translated rows
      return this.HttpService.get(baseUrl+'read/'+view.language+'/query'+GETParams, params);
    else
      // Untranslated rows
      return this.HttpService.get(baseUrl+'read/'+view.language+'/untranslated'+GETParams, params);
  }
  updateTranslations(translations: any[]) {
    return this.HttpService.post(baseUrl+"update",translations);
  }
  insertTranslation(translations){
    return this.HttpService.post(baseUrl+"create",translations);
  }
}
