<app-header [class]="'translations'">
  <jbm-view-title [caption]="'menu.translations'|translate"
    [parentLinkCaption]="'menu.settings'|translate"
    [parentLinkSlug]="'settings'"></jbm-view-title>
  <div class="header-section">
    <label for="language" class="label">{{'translate.language'|translate}}</label>
    <select id="language" class="form-control form-select" (change)="languageChange($event)">
      <option *ngFor="let lang of languages" value="{{lang.key}}">{{lang.value}}</option>
    </select>
    <div class="header-spacer-2"></div>
    <jbm-create-button [visible]="userRights.AllowCreate && view.language=='nl' && !createActive" (click)="startCreate()"></jbm-create-button>
    <div [hidden]="!userRights.AllowCreate || createActive" class="header-spacer-2"></div>
    <div *ngIf="view.language!='nl'" class="float-start">
      <label for="filter" class="label">{{'ui.show'|translate}}</label>
      <select id="filter" class="form-control form-select" (change)="filterChange($event)">
        <option value="0" [selected]="view.translated">{{'translation.translated-items'|translate}}</option>
        <option value="1" [selected]="!view.translated">{{'translation.untranslated-items'|translate}}</option>
      </select>
    </div>
  </div>
  <div class="btn-group float-end">
    <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
      [pageSize]="queryHttpHelper.paginationProps.pageSize" [page]="queryHttpHelper.paginationProps.page"
      (pageClick)="pageChange($event)">
    </jbm-pagination>
    <jbm-searchbox [visible]="searchboxVisible && !showFilter" [search]="queryHttpHelper.getSearchValue()"
      [minlength]="3" (searchChange)="searchChange($event)">
    </jbm-searchbox>
    <div class="header-spacer-1 float-end"></div>
    <jbm-help-button [helpTopicID]="0"></jbm-help-button>
  </div>
</app-header>
<section class="module">
  <div *ngIf="updates.length || translateprogress" class="status clearfix">
    <div class="progress-container">
      <ngb-progressbar *ngIf="translateprogress" type="success" [value]="translateprogress">{{translateprogress}}% {{'translation.translated'|translate}}</ngb-progressbar>
    </div>
    <div *ngIf="updates.length" class="updates">
      <button type="button" class="btn btn-primary" (click)="saveUpdates()">{{'translation.saveupdate'|translate}} <span class="badge bg-light text-dark">{{updates.length}}</span> </button>
    </div>
  </div>
  <div class="table-responsive">
  <table class="table padding table-sm translations">
  <thead>
    <tr>
      <th [hidden]="true"></th>
      <th jbm-table-column class="key" [name]="'key'" [header]="'translation.key'|translate"
        [sortable]="true" [sorted]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)"></th>
      <th class="translation">{{'translation.translation'|translate}}</th>
      <th class="actions"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngIf="createActive">
      <th [hidden]="true"></th>
      <th>
        <input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="newKey"
          (blur)="onBlurKey()" [ngClass]="{'is-valid':keyTouched && validateKey(), 'is-invalid':keyTouched && !validateKey() }">
        <jbm-help-text [helptext]="'translation.key-format'|translate"></jbm-help-text>
      </th>
      <th colspan="2">
        <input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="newTranslation"
          (blur)="onBlurTranslation()" [ngClass]="{'is-valid':translationTouched && validateTranslation(), 'is-invalid':translationTouched && !validateTranslation()}">
        <div class="mt-2 mb-3">
          <button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!validateKey() || !validateTranslation()" (click)="insertTranslation()">{{'translation.save'|translate}}</button>
          <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
        </div>
      </th>
    </tr>
    <tr *ngFor="let line of translations">
      <td [hidden]="true"></td>
      <td><i class="fa fa-key"></i> &quot;{{ line.key }}&quot;</td>

      <td *ngIf="userRights.AllowUpdate">
        <input class="form-control form-control-sm" type="text" id="--key--{{line.key}}" autocomplete="off"
          [placeholder]="line.translation" (focusin)="onFocus(line)" (focusout)="onLeave(line)" (blur)="onBlur($event)" [ngClass]="{'is-valid':isUpdated(view.language, line.key) }">
        <small *ngIf="line.focused" class="form-text text-muted">
          {{'translation.translate-help'|translate}}
        </small>
      </td>

      <td *ngIf="!userRights.AllowUpdate">{{line.translation}}</td>

      <td>
        <button *ngIf="userRights.AllowUpdate && isUpdated(view.language, line.key)" type="button" tabindex="-1" class="btn btn-sm btn-primary"
          (click)="onUndo(view.language, line.key)"><i class="fa fa-undo"></i>
        </button>
      </td>
    </tr>
  </tbody>
  </table>
  </div>
  <jbm-empty-view [emptyView]="!translations.length"></jbm-empty-view>
</section>
<jbm-toasts></jbm-toasts>
