<app-header [class]="'breadcrumbs'">
    <div class="float-start">
        <jbm-view-title [caption]="'entity.project'|translate" [subCaption]="caption"
          [parentLinkCaption]="parentLinkCaption" [parentLinkSlug]="parentLinkRoute">
        </jbm-view-title>
    </div>
    <div class="toolbar float-start">
        <button *ngIf="userRights.AllowRead" type="button" class="btn btn-primary round mr-2"
          placement="bottom" container="body" [ngbTooltip]="('ui.details'|translate)+' '+('ui.show-verb'|translate)"
          (click)="projectDetails()"><i class="fa fa-info"></i>
        </button>
        <jbm-edit-round-button *ngIf="userRights.AllowUpdate" (click)="edit()"></jbm-edit-round-button>
        <div class="toolbar-spacer-2"></div>
        <button *ngIf="editable" class="btn btn-primary" (click)="addOrder()"><i class="fa fa-plus"></i> {{'project.order'|translate}}</button>
    </div>
    <div class="float-end toolbar action-buttons">
        <jbm-help-button></jbm-help-button>
    </div>
</app-header>
<section class="module projectview padding">
    <div class="project-details bg-blue-extra-light p-1 pt-2 mb-3">
        <div class="header">
            <section class="project">
                <label class="top">{{'project.state'|translate}}</label>
                <label class="value">{{project.projectState.description}}</label>
            </section>
            <section *ngIf="hasCustomer" class="customer">
                <label class="top">{{'entity.customer'|translate}}</label>
                <jbm-link-button [caption]="project.customer.name" style="margin-top: -0.1rem;" (onClick)="customerDetails()"></jbm-link-button>
            </section>
            <section *ngIf="hasAddress" class="work-location">
                <label class="top">{{'project.work-location'|translate}}</label>
                <label class="value">{{project.address.street}}&nbsp;{{project.address.no}}, {{project.address.city}}</label>
            </section>
            <section *ngIf="hasPlant" class="plant">
                <label class="top">{{'company.plant'|translate}}</label>
                <label class="value">{{project.plant.name}}</label>
            </section>
        </div>
        <div class="clearfix">
            <section class="project">
                <div>
                    <jbm-comments-dropdown [comments]="project.description" [iconClass]="'info'" [placement]="'left-bottom'"
                      [container]="'body'" [ngbTooltip]="'entity.description'|translate">
                    </jbm-comments-dropdown>
                    <jbm-comments-dropdown [comments]="project.comments" [placement]="'top-left'" [container]="'body'"
                      [ngbTooltip]="'entity.comments'|translate">
                    </jbm-comments-dropdown>
                </div>
            </section>
            <section *ngIf="hasCustomer" class="customer">
                <div>
                    <div class="clearfix">
                        <div class="float-start mr-4">
                            <label *ngIf="project.customer.phone" class="top">{{'entity.phone'|translate}}</label>
                            <label *ngIf="project.customer.phone" class="value">{{project.customer.phone}}</label>
                        </div>
                        <div class="float-start mr-4">
                            <label *ngIf="project.customer.email" class="top">{{'entity.emailaddress'|translate}}</label>
                            <label *ngIf="project.customer.email" class="value">{{project.customer.email}}</label>
                        </div>
                        <label *ngIf="project.customer.blocked" class="value">
                            <span class="badge bg-danger">{{'entity.blocked'|translate}}</span>
                        </label>
                    </div>
                </div>
            </section>
            <section *ngIf="hasAddress" class="work-location">
                <div>
                    <div class="clearfix">
                        <div class="float-start mr-4">
                            <label *ngIf="data.distance" class="top">{{'geo.distance'|translate}}</label>
                            <label *ngIf="data.distance" class="value">{{data.distance}}</label>
                        </div>
                        <div class="float-start mr-4">
                            <label *ngIf="data.traveltime" class="top">{{'geo.traveltime'|translate}}</label>
                            <label *ngIf="data.traveltime" class="value">{{data.traveltime}}</label>
                        </div>
                        <div *ngIf="project.address_details_id" class="float-start">
                            <jbm-gps-location-button
                              [longitude]="project.address.lon"
                              [latitude]="project.address.lat"
                              [address]="(project.address.street)+' '+(project.address.no)+ ', '+project.address.city">
                            </jbm-gps-location-button>
                        </div>
                    </div>
                </div>
            </section>
            <section class="plant">
            </section>
        </div>
    </div>
    <orders [language]="language" [projects_id]="project.id" [customers_id]="project.customer_id"
      [plants_id]="project.plants_id" [travelTime]="project.traveltime" [editable]="editable">
    </orders>
</section>
<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
    <project-details [project_id]="project.id" (onClose)="closeProject()"></project-details>
</ng-template>
