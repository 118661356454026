import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize'
})
export class FileSizePipe implements PipeTransform {
  transform( value: number, decimals: number=0 ): string | null {
    if(value==undefined) return '';
    if(value===0) return '';

    let u = 0, s=1024;
    while (value >= s || -value >= s) {
      value /= s;
      u++;
    }
    return (u ? value.toFixed(decimals) + ' ' : value) + ' KMGTPEZY'[u] + 'b';
  }
}
