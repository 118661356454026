import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import {AddressesService} from "../../addresses/data/addresses.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {Plant} from "./interfaces/Plant";

const baseURL: string = 'company/';
const slug: string = 'plants/';

@Injectable({
  providedIn: 'root'
})
export class PlantsService {
  plant: Plant;

  constructor(private HttpService: HttpService, private AddressesService: AddressesService) { }

  getData(params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug, params);
  }
  getSelectOptions(plants: Plant[]): JBMSelectOption[] {
    let options: JBMSelectOption[]=[];
    for(let p=0; p<plants.length; p++)
      options.push({key: plants[p].id.toString(), value: plants[p].name});
    return options;
  }
  getOne(id) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id );
  }
  getEmpty() {
    this.plant = {
      id: 0,
      code: '',
      name: '',
      address_details_id: 0,
      address_details: this.AddressesService.getEmpty(),
      baseprice: 0.0,
      version: 0,
      production_capacity: 0,
      driving_time_margin: 0
    }
    return this.plant;
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
}
