import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';
import {CustomerService} from "../data/customer.service";
import {CustomertypesService} from "../data/customertypes.service";
import {COCService} from "../../../services/chamber-of-commerce/coc.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {StateService} from "../../../services/storage/state.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {PriceService} from "../../../services/price/price.service";
import {setting_subjects, settings, SettingsService} from "../../../services/storage/settings.service";
import {ValidationPatternsService} from "../../../services/validation/patterns.service";
import {GoogleMapsService} from "../../../services/google-maps/google-maps.service";
import {Subscription} from "rxjs";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {Customer} from "../data/interfaces/customer";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {EmptyGPSAddress, GPSAddress} from "../../../components/JBM/Helpers/GPSInfo";
import {CRUDAction} from "../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.scss']
})

export class CustomerFormComponent implements OnInit, OnDestroy {
  action: CRUDAction=CRUDAction.Create;
  customer: Customer;
  id: number;
  rights: GroupRights;
  financialRights: GroupRights;
  caption: string;
  customertypes: any[];
  ct_options:JBMSelectOption[]=[];
  error: string='';
  error_lonlat: string='';
  error_invoice_lonlat: string='';
  invoice_address_switch: boolean=false;
  language: string=this.TokenService.getLanguage();
  firstinput: any;

  GPS_address: GPSAddress=EmptyGPSAddress();
  GPS_invoice_address: GPSAddress=EmptyGPSAddress();

  settings: any=null;
  shift_vat_setting: boolean=false;
  resources_based_invoicing: boolean=false;

  customertypes$: Subscription;

  @ViewChild('modalCustomer') modalCustomer: ElementRef;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private Router: Router,
    private Location: Location,
    private formBuilder: FormBuilder,
    private CustomerService: CustomerService,
    private CustomertypeService: CustomertypesService,
    private SettingsService: SettingsService,
    private COCService: COCService,
    private TranslateService: TranslateService,
    private TokenService: TokenService,
    private ModalService: ModalService,
    private StateService: StateService,
    private PriceService: PriceService,
    private ValidationPatternsService: ValidationPatternsService,
    private GoogleMapsService: GoogleMapsService,
    private JBMToastsService: JBMToastsService
  ) {
    this.customer=this.CustomerService.getEmpty();

    if(this.ActivatedRoute.snapshot.url[1].path!=='create') {
      this.id=parseInt(this.ActivatedRoute.snapshot.paramMap.get('id'));
      if(isNaN(this.id)) this.close();
      this.action=CRUDAction.Update;
    }

    this.rights=this.TokenService.getRightsByName('customers');
    this.financialRights=this.TokenService.getRightsByName('financial');

    // Exit if user has not sufficient rights
    if(!this.rights.AllowCreate || !this.rights.AllowUpdate)
      this.close();

    this.addControls();
  }

  customerForm: FormGroup;
  isSubmitted: boolean=false;
  customer_type_value: string;

  ngOnInit(): void {

    if(this.id) {
      this.CustomerService.get(this.id, true).subscribe(
          (data)=>this.customer=data.data,
          (error)=>{
            console.error(error);
            this.close();
          },
          ()=>{
            this.initData();
          }
      )
    } else
      this.initData();

    setTimeout(()=>this.ModalService.open(this.modalCustomer,'customer'),100);
    setTimeout(()=>document.getElementById('nameinput').focus(),500);
  }
  addControls() {
    this.customerForm = this.formBuilder.group({
      customer_type: new FormControl('', [Validators.required]),
      debtor_id: new FormControl(0),
      name: new FormControl( '', [Validators.required]),
      full_name: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('',[Validators.required, Validators.email]),
      coc: new FormControl(0,[Validators.pattern('^[0-9]{8}')]),
      description: new FormControl(''),
      blocked: new FormControl(false),
      city : new FormControl('',[Validators.required]),
      street: new FormControl('',[Validators.required]),
      no: new FormControl('',[Validators.required]),
      postcode: new FormControl('',[Validators.required, Validators.pattern('[1-9][0-9]{3}[\\s]?[A-Za-z]{2}')]),
      lon: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      lat: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      invoice_address_switch: new FormControl(false),
      invoice_email: new FormControl('', [Validators.email]),
      invoice_city : new FormControl(''),
      invoice_street: new FormControl(''),
      invoice_no: new FormControl(''),
      invoice_postcode: new FormControl('',[Validators.pattern('[1-9][0-9]{3}[\\s]?[A-Za-z]{2}')]),
      invoice_lon: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      invoice_lat: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      baseprice: new FormControl('',[this.PriceService.priceValidator(this.PriceService,false,false)]),
      baseprice_discount: new FormControl('',[this.PriceService.priceValidator(this.PriceService,true,false)]),
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.customerForm.controls; }

  initData() {
    // Get Customertypes
    this.customertypes$=this.CustomertypeService.get().subscribe(
      (data: any) => this.customertypes=data.data,
      (error)=>console.error(error),
      ()=>{
        for(let c=0; c<this.customertypes.length; c++)
          this.ct_options.push( { key: this.customertypes[c].id, value: this.customertypes[c].description } );
        this.setControlValues();

        // Get existing customer settings
        if(this.customer.id===0)
          return false;

        this.SettingsService.getSubject(0, setting_subjects.customer, this.customer.id).subscribe(
            (data)=>this.settings=data.settings,
            (error)=>console.error(error),
            ()=> {
              if(this.settings) {
                if(this.settings.hasOwnProperty(settings.shift_vat))
                  this.shift_vat_setting=this.settings.shift_vat;
                if(this.settings.hasOwnProperty(settings.resources_based_invoicing))
                  this.resources_based_invoicing=this.settings.resources_based_invoicing;
              }
            }
        );
      });
  }

  setControlValues() {
    this.invoice_address_switch=this.customer.invoice_address_id>0;
    this.customerForm.controls.customer_type.setValue(this.customer.customer_type.toString());
    this.customerForm.controls.debtor_id.setValue(this.customer.debtor_id);
    this.customerForm.controls.name.setValue(this.customer.name);
    this.customerForm.controls.full_name.setValue(this.customer.full_name);
    this.customerForm.controls.phone.setValue(this.customer.phone);
    this.customerForm.controls.email.setValue(this.customer.email);
    this.customerForm.controls.coc.setValue(this.customer.coc);
    this.customerForm.controls.description.setValue(this.customer.description);
    this.customerForm.controls.blocked.setValue(this.customer.blocked);
    // Address
    this.customerForm.controls.city.setValue(this.customer.id>0 ? this.customer.address_details.city : '');
    this.customerForm.controls.street.setValue(this.customer.id>0 ? this.customer.address_details.street : '');
    this.customerForm.controls.no.setValue(this.customer.id>0 ? this.customer.address_details.no : '');
    this.customerForm.controls.postcode.setValue(this.customer.id>0 ? this.customer.address_details.postcode : '');
    this.customerForm.controls.lon.setValue(this.customer.id>0 ? this.customer.address_details.lon : '');
    this.customerForm.controls.lat.setValue(this.customer.id>0 ? this.customer.address_details.lat : '');
    // Lookup for GEO-location
    this.updateAddress(false);

    this.customerForm.controls.invoice_address_switch.setValue(this.invoice_address_switch);
    this.customerForm.controls.invoice_email.setValue(this.customer.invoice_email);
    this.customerForm.controls.invoice_city.setValue(this.customer.id>0 ? this.customer.invoice_address_details.city : '');
    this.customerForm.controls.invoice_street.setValue(this.customer.id>0 ? this.customer.invoice_address_details.street : '');
    this.customerForm.controls.invoice_no.setValue(this.customer.id>0 ? this.customer.invoice_address_details.no : '');
    this.customerForm.controls.invoice_postcode.setValue(this.customer.id>0 ? this.customer.invoice_address_details.postcode : '');
    this.customerForm.controls.invoice_lon.setValue(this.customer.id>0 ? this.customer.invoice_address_details.lon : '');
    this.customerForm.controls.invoice_lat.setValue(this.customer.id>0 ? this.customer.invoice_address_details.lat : '');
    // Lookup for GEO-location
    this.updateAddress(true);

    let price='';
    if(this.customer.baseprice!==null)
      price=this.PriceService.formatPrice(this.customer.baseprice);
    this.customerForm.controls.baseprice.setValue( price );
    price='';
    if(this.customer.baseprice_discount!==null)
      price=this.PriceService.formatPrice(this.customer.baseprice_discount);
    this.customerForm.controls.baseprice_discount.setValue( price );

    this.customer_type_value=this.customer.customer_type.toString();
  }
  changeCustomertype(e) {
    this.customer_type_value = e.target.value;
  }
  updateInvoiceAddressSwitch($event) {
    this.invoice_address_switch=$event.target.checked;
  }
  updateCOCAddress(e) {
    let COCNumber=e.target.value;

    COCNumber=COCNumber.trim();
    if(!COCNumber || COCNumber.length<8)
      return false; // COCNumber not given/valid
    let coc_address;
    this.COCService.getAddress(COCNumber).subscribe(
        (data: any) => coc_address = data,()=>{},()=>{
          if(typeof coc_address === "string")
            return false; // Backend status is 500; error
          // Autocomplete address fields
          this.formControls.name.setValue(coc_address.name);
          this.formControls.full_name.setValue(coc_address.full_name);
          this.formControls.city.setValue(coc_address.city);
          this.formControls.street.setValue(coc_address.street);
          this.formControls.postcode.setValue(coc_address.postcode);
          this.formControls.no.setValue(coc_address.no);
          // Lookup for GEO-location
          this.updateAddress(false);
        })
  }
  updateAddress(invoice: boolean) {
    if(!invoice) {
      // Lookup if postcode and no are given
      if(this.formControls.postcode.value.trim()!='' && this.formControls.no.value.trim()!='') {
         this.GoogleMapsService.getLookup('', this.formControls.no.value,
         this.formControls.postcode.value,'',true)
            .subscribe(
                (data)=>this.GPS_address=data,
                (error)=>{console.error(error)},
                ()=>this.autoCompleteAddress()
        );
      } else {
        // Lookup if street, no and city are given
        if(this.formControls.street.value.trim()!='' && this.formControls.no.value.trim()!='' &&
           this.formControls.city.value.trim()!='') {
           this.GoogleMapsService.getLookup( this.formControls.street.value,
           this.formControls.no.value,'', this.formControls.city.value,true)
              .subscribe(
                  (data)=>this.GPS_address=data,
                  (error)=>{console.error(error)},
                  ()=>{this.autoCompleteAddress()}
          );
        }
      }
    } else {
      // Lookup if postcode and no are given
      if(this.formControls.invoice_postcode.value.trim()!='' && this.formControls.invoice_no.value.trim()!='') {
        this.GoogleMapsService.getLookup('', this.formControls.invoice_no.value,
        this.formControls.invoice_postcode.value,'',true)
            .subscribe(
                (data)=>this.GPS_invoice_address=data,
                (error)=>{console.error(error)},
                ()=>this.autoCompleteInvoiceAddress()
        );
      } else {
        // Lookup if street, no and city are given
        if(this.formControls.invoice_street.value.trim()!='' && this.formControls.invoice_no.value.trim()!='' &&
           this.formControls.invoice_city.value.trim()!='') {
           this.GoogleMapsService.getLookup(this.formControls.invoice_street.value,
              this.formControls.invoice_no.value,'', this.formControls.invoice_city.value,true)
              .subscribe(
                  (data)=>this.GPS_invoice_address=data,
                  (error)=>{console.error(error)},
                  ()=>this.autoCompleteInvoiceAddress()
          );
        }
      }
    }
  }
  autoCompleteAddress() {
    if(this.GPS_address.street) this.formControls.street.setValue(this.GPS_address.street);
    if(this.GPS_address.postcode) this.formControls.postcode.setValue(this.GPS_address.postcode);
    if(this.GPS_address.city) this.formControls.city.setValue(this.GPS_address.city);
    if(this.GPS_address.no) this.formControls.no.setValue(this.GPS_address.no);
    this.formControls.lat.setValue(this.GPS_address.latitude);
    this.formControls.lon.setValue(this.GPS_address.longitude);
  }
  autoCompleteInvoiceAddress() {
    if(this.GPS_invoice_address.street) this.formControls.invoice_street.setValue(this.GPS_invoice_address.street);
    if(this.GPS_invoice_address.postcode) this.formControls.invoice_postcode.setValue(this.GPS_invoice_address.postcode);
    if(this.GPS_invoice_address.city) this.formControls.invoice_city.setValue(this.GPS_invoice_address.city);
    if(this.GPS_invoice_address.no) this.formControls.invoice_no.setValue(this.GPS_invoice_address.no);
    this.formControls.invoice_lat.setValue(this.GPS_invoice_address.latitude);
    this.formControls.invoice_lon.setValue(this.GPS_invoice_address.longitude);
  }
  setShiftVatSetting(event) {
    this.shift_vat_setting=event.target.checked;
  }
  setResourcesBasedInvoicing(event) {
    this.resources_based_invoicing=event.target.checked;
  }
  save() {
    this.isSubmitted = true;

    if(this.customerForm.invalid)
      return;

    // Validate invoice address
    let valid=true;
    if(this.invoice_address_switch) {
      if(this.formControls.invoice_street.value.trim()=='') {
        this.formControls.invoice_street.setErrors({'required': true});
        valid=false;
      }
      if(this.formControls.invoice_no.value.trim()=='') {
        this.formControls.invoice_no.setErrors({'required': true});
        valid=false;
      }
      if(this.formControls.invoice_postcode.value.trim()=='') {
        this.formControls.invoice_postcode.setErrors({'required': true});
        valid=false;
      }
      if(this.formControls.invoice_city.value.trim()=='') {
        this.formControls.invoice_city.setErrors({'required': true});
        valid=false;
      }
    }
    if(!valid) return;

    // Validate longitudes and latitudes
    valid=true;
    let lon=this.formControls.lon.value.toString().trim();
    let lat=this.formControls.lat.value.toString().trim();

    if(lat.length && lon.length && lat==lon) {
      this.error_lonlat=this.TranslateService.GetTranslation('gps.longitude-latitude-same');
      valid=false;
    }
    if(lat.length && lon.length===0) {
      this.formControls.lon.setErrors({'required': true});
      valid=false;
    }
    if(lon.length && lat.length===0) {
      this.formControls.lat.setErrors({'required': true});
      valid=false;
    }
    if(!valid) return;

    if(this.invoice_address_switch) {
      // Validate longitudes and latitudes invoice address
      valid=true;
      let lon=this.formControls.invoice_lon.value.toString().trim();
      let lat=this.formControls.invoice_lat.value.toString().trim();

      if(lat.length && lon.length && lat==lon) {
        this.error_invoice_lonlat=this.TranslateService.GetTranslation('gps.longitude-latitude-same');
        valid=false;
      }
      if(lat.length && lon.length===0) {
        this.formControls.invoice_lon.setErrors({'required': true});
        valid=false;
      }
      if(lon.length && lat.length===0) {
        this.formControls.invoice_lat.setErrors({'required': true});
        valid=false;
      }
      if(!valid) return;
    }

    if(!this.invoice_address_switch) {
      // Invoice address same as address; empty invoice address fields
      this.customerForm.controls.invoice_city.setValue('');
      this.customerForm.controls.invoice_street.setValue('');
      this.customerForm.controls.invoice_no.setValue('');
      this.customerForm.controls.invoice_postcode.setValue('');
      this.customerForm.controls.invoice_lon.setValue('');
      this.customerForm.controls.invoice_lat.setValue('');
    }

    // Validate price and discount
    if( (this.formControls.baseprice.value!='') && this.formControls.baseprice_discount.value!='') {
      this.error=this.TranslateService.GetTranslation('concrete.price.baseprice-and-discount');
      return;
    }

    // Store customer
    let data = {
      id: this.customer.id,
      customer_type: parseInt(this.formControls.customer_type.value),
      debtor_id: this.formControls.debtor_id.value,
      name: this.formControls.name.value,
      full_name: this.formControls.full_name.value,
      phone: this.formControls.phone.value,
      email: this.formControls.email.value,
      coc: this.formControls.coc.value,
      description: this.formControls.description.value,
      blocked: this.formControls.blocked.value,
      invoice_email: this.formControls.invoice_email.value,
      baseprice: this.PriceService.getPriceFromStr(this.formControls.baseprice.value),
      baseprice_discount: this.PriceService.getPriceFromStr(this.formControls.baseprice_discount.value),
      address_id: this.customer.address_id,
      invoice_address_id: this.customer.invoice_address_id,
      address_details: {
        city: this.formControls.city.value,
        street: this.formControls.street.value,
        postcode: this.formControls.postcode.value,
        no: this.formControls.no.value,
        lat: this.formControls.lat.value,
        lon: this.formControls.lon.value
      },
      invoice_address_details: {
        city: this.formControls.invoice_city.value,
        street: this.formControls.invoice_street.value,
        postcode: this.formControls.invoice_postcode.value,
        no: this.formControls.invoice_no.value,
        lat: this.formControls.invoice_lat.value,
        lon: this.formControls.invoice_lon.value
      }
    }
    if(data.id==0) {
      this.CustomerService.create(data).subscribe((data)=>{
        console.log(data);
        this.customer.id=data.data.id;
      },(error)=>this.handleError(error),()=> {
          this.saveSettings();
          this.afterSave()
      })
    } else {
      this.CustomerService.update(data).subscribe(()=>{},(error)=>this.handleError(error),()=> {
          this.saveSettings();
          this.afterSave()
      })
    }
  }
  saveSettings() {
    this.settings={
      shift_vat: this.shift_vat_setting,
      resources_based_invoicing: this.resources_based_invoicing
    };
    this.SettingsService.setSubject(0, setting_subjects.customer, this.customer.id, this.settings).subscribe(
      ()=>{},(error)=>console.error(error))
  }
  handleError(error) {
    if(error=='not_unique') {
      this.formControls.coc.setErrors({'not_unique': true});
      let input=this.firstinput=document.getElementById('coc');
      input.focus();
    } else
      console.error(error);
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.close();
  }
  close() {
    this.Router.navigate([{ outlets: { 'modal': null }} ] );
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.customertypes$!=undefined) this.customertypes$.unsubscribe();
  }
}
