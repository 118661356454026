import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../services/auth/token.service";
import {InvoiceConcretesService} from "../data/invoice-concretes.service";
import {ArticlesService} from "../../financial/data/articles.service";
import {PriceService} from "../../../services/price/price.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";

@Component({
  selector: 'invoice-concretes',
  templateUrl: './invoice-concretes.component.html',
  styleUrls: ['./invoice-concretes.component.scss']
})
export class InvoiceConcretesComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  concretes: any[]=[];
  display_options: any[]=[];
  recipe: any={ id: 0, delivery_date: '', environment: '' };

  @Input() projects_id: number;
  @Input() customers_id: number;
  @Input() invoice_id: number;

  @Output() onClose=new EventEmitter();

  constructor(
      private TokenService: TokenService,
      private InvoiceConcretesService: InvoiceConcretesService,
      private ArticlesService: ArticlesService,
      private PriceService: PriceService,
      private TranslateService: TranslateService,
      private ModalService: ModalService
  ) {}

  ngOnInit(): void {
    this.display_options=this.ArticlesService.getDisplayItems(this.TranslateService);

    let params=new HttpParams().append('order','1').append('articles','1');
    this.InvoiceConcretesService.get(this.language, this.invoice_id, params).subscribe(
        (data)=>this.concretes=data.data,
        (error)=>console.error(error),
        ()=>this.formatData()
    )
  }
  formatData() {
      for(let concrete of this.concretes)
        for(let article of concrete.articles) {
          article.amount=this.PriceService.formatPrice(article.amount) + ' ' + article.code;
          if( article.hasOwnProperty('recipe_amount'))
            article.recipe_amount=this.PriceService.formatPrice(article.recipe_amount) + ' ' + article.code;
        }
  }

  close() {
    this.onClose.emit();
  }
}
