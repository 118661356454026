import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JBMModalHeaderComponent } from "./JBMModalHeader/JBMModalHeader.component";
import { SharedModule} from "../../../shared/shared.module";
import { FormGroupBaseComponent } from "./JBMFormGroups/formgroupbase.component";
import { JBMTextInputComponent} from "./JBMFormGroups/JBMTextInput/JBMTextInput.component";
import { JBMIntegerInputComponent} from "./JBMFormGroups/JBMTextInput/JBMIntegerInput/JBMIntegerInput.component";
import { JBMFloatInputComponent} from "./JBMFormGroups/JBMTextInput/JBMFloatInput/JBMFloatInput.component";
import { JBMTextInputGroupComponent } from './JBMFormGroups/JBMTextInputGroup/JBMTextInputGroup.component';
import { JBMFloatInputGroupComponent} from "./JBMFormGroups/JBMTextInputGroup/JBMFloatInputGroup/JBMFloatInputGroup.component";
import { JBMIntegerInputGroupComponent} from "./JBMFormGroups/JBMTextInputGroup/JBMIntegerInputGroup/JBMIntegerInputGroup.component";
import { JBMTimeInputComponent } from "./JBMFormGroups/JBMTextInputGroup/JBMTimeInput/JBMTimeInput.component";
import { JBMDateInputComponent } from "./JBMFormGroups/JBMTextInputGroup/JBMDateInput/JBMDateInput.component";
import { JBMInputErrorComponent } from './JBMFormGroups/JBMInputError/JBMInputError.component';
import { JBMSelectComponent } from './JBMFormGroups/JBMSelect/JBMSelect.component';
import { JBMTextareaComponent } from './JBMFormGroups/JBMTextarea/JBMTextarea.component';
import { JBMCheckComponent } from './JBMFormGroups/JBMCheck/JBMCheck.component';
import { JBMCheckGroupComponent } from './JBMFormGroups/JBMCheckGroup/JBMCheckGroup.component';
import { JBMRadioGroupComponent } from './JBMFormGroups/JBMRadioGroup/JBMRadioGroup.component';
import { JBMHelpTextComponent } from './JBMHelpText/JBMHelpText.component';
import { JBMStaticLabelComponent } from './JBMFormGroups/JBMStaticLabel/JBMStaticLabel.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { JBMMultiSelectComponent } from './JBMFormGroups/JBMMultiSelect/JBMMultiSelect.component';

@NgModule({
  declarations: [
    JBMModalHeaderComponent,
    FormGroupBaseComponent,
    JBMTextInputComponent,
    JBMIntegerInputComponent,
    JBMFloatInputComponent,
    JBMTextInputGroupComponent,
    JBMFloatInputGroupComponent,
    JBMIntegerInputGroupComponent,
    JBMTimeInputComponent,
    JBMDateInputComponent,
    JBMInputErrorComponent,
    JBMSelectComponent,
    JBMTextareaComponent,
    JBMCheckComponent,
    JBMCheckGroupComponent,
    JBMRadioGroupComponent,
    JBMHelpTextComponent,
    JBMStaticLabelComponent,
    JBMMultiSelectComponent,
  ],
  imports: [CommonModule, SharedModule, ReactiveFormsModule, FormsModule],
    exports: [
        JBMModalHeaderComponent,
        FormGroupBaseComponent,
        JBMTextInputComponent,
        JBMIntegerInputComponent,
        JBMFloatInputComponent,
        JBMTextInputGroupComponent,
        JBMFloatInputGroupComponent,
        JBMIntegerInputGroupComponent,
        JBMTimeInputComponent,
        JBMDateInputComponent,
        JBMInputErrorComponent,
        JBMSelectComponent,
        JBMTextareaComponent,
        JBMCheckComponent,
        JBMCheckGroupComponent,
        JBMRadioGroupComponent,
        JBMHelpTextComponent,
        JBMStaticLabelComponent,
        JBMMultiSelectComponent
    ]
})
export class JBMFormModule { }
