import {NgModule } from '@angular/core';
import {RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {PresentationsComponent} from "./datatable/presentations.component";
import {PdfPresentationResolver} from "./data/resolvers/PdfPresentation.resolver";
import {PdfTemplateEditorComponent} from "./pdf-template-editor/pdf-template-editor.component";
import {MailPresentationResolver} from "./data/resolvers/MailPresentation.resolver";
import {MailTemplateEditorComponent} from "./mail-template-editor/mail-template-editor.component";
import {MediaFilesComponent} from "./media-files/datatable/media-files.component";
import {TextPlaceholdersComponent} from "./text-placeholders/datatable/text-placeholders.component";
import {MailAttachmentsComponent} from "./mail-attachments/mail-attachments.component";

const routes: Routes = [
  {
    path: 'settings/presentations',
    component: PresentationsComponent,
    canActivate: [ AuthGuardService ],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/pdf-template-editor/:id',
    component: PdfTemplateEditorComponent,
    canActivate: [AuthGuardService],
    resolve: { presentation: PdfPresentationResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/mail-template-editor/:id',
    component: MailTemplateEditorComponent,
    canActivate: [AuthGuardService],
    resolve: { presentation: MailPresentationResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/mail-attachments/:id',
    component: MailAttachmentsComponent,
    canActivate: [AuthGuardService],
    resolve: { presentation: MailPresentationResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/mediafiles',
    component: MediaFilesComponent,
    canActivate: [ AuthGuardService ],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/text-placeholders',
      component: TextPlaceholdersComponent,
      canActivate: [ AuthGuardService ],
      data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PresentationsRoutingModule { }
