<form [formGroup]="userForm" (ngSubmit)="save()" class="user">
    <div class="jbm-modal-header">
        <jbm-crud-title subject="entity.user" [action]="action"></jbm-crud-title>
        <div class="toolbar float-end">
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <jbm-text-input [id]="'username'" [formControl]="formControls['username']"
            [required]="true" [submitted]="isSubmitted"
            [labelCaption]="'login.username'|translate"
            labelClass="width-10" class="input-20">
                <jbm-input-error [formControl]="formControls['username']" [submitted]="isSubmitted">
                    <span *ngIf="formControls['username'].hasError('notUnique')">
                        {{'entity.name-not-unique'|translate}}</span>
                    <span *ngIf="formControls['username'].hasError('required')">
                        {{'entity.requiredfield'|translate}}</span>
                </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [id]="'audience'" [formControl]="formControls['audience']"
            [required]="true" [submitted]="isSubmitted"
            [labelCaption]="'entity.customer'|translate"
            labelClass="width-10" class="input-20">
            <jbm-input-error [formControl]="formControls['audience']" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-text-input>
        <fieldset *ngIf="!user.id" class="width-30 mt-3">
            <legend>{{'users.password-set'|translate}}</legend>
            <password-constraints-alert visible="true"></password-constraints-alert>

            <jbm-text-input
              [id]="'passwordNew'"
              [formControl]="formControls.passwordNew"
              [required]="true"
              [class]="'input-15'"
              [labelCaption]="'users.password-new'|translate"
              [labelClass]="'width-9'"
              [submitted]="isSubmitted"
              [type]="'password'">
                <jbm-input-error [formControl]="formControls.passwordNew" [submitted]="isSubmitted">
                    <span *ngIf="formControls.passwordNew.hasError('passwordInvalid')">
                        {{'login.password-error'|translate}}
                    </span>
                </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input
              [id]="'passwordConfirm'"
              [formControl]="formControls.passwordConfirm"
              [required]="true"
              [class]="'input-15'"
              [labelCaption]="('users.password-new'|translate)+' '+('users.password-confirm'|translate)"
              [labelClass]="'width-9'"
              [submitted]="isSubmitted"
              [type]="'password'">
                <jbm-input-error [formControl]="formControls.passwordConfirm" [submitted]="isSubmitted">
                    <span *ngIf="formControls.passwordConfirm.hasError('passwordInvalid')">
                        {{'login.password-error'|translate}}
                    </span>
                    <span *ngIf="formControls.passwordConfirm.hasError('confirmInvalid')">
                        {{'users.password-confirm-error'|translate}}
                    </span>
                </jbm-input-error>
            </jbm-text-input>
        </fieldset>
        <div *ngIf="user.id" class="mt-3 mb-3 clearfix">
            <div class="width-11 float-start">&nbsp;</div>
            <div class="float-start">
                <button type="button" class="btn btn-outline-secondary" (click)="passwordChange()">{{'users.password-change'|translate}}</button>
            </div>
        </div>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="'entity.user'|translate" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
<jbm-modal [id]="'userpassword'" [className]="'userpassword'"></jbm-modal>
<ng-template #userPasswordModal let-modal>
    <form-password-change [user]="user"
      (onChanged)="onPasswordChange()" (onCancel)="onPasswordChangeCancel()"></form-password-change>
</ng-template>
<jbm-toasts></jbm-toasts>