import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule} from "../../../shared/shared.module";
import { JBMButtonsModule} from "../Buttons/jbmbuttons.module";
import { JBMDropdownsModule} from "../Dropdowns/jbmdropdowns.module";
import { TableLayoutComponent} from "./Layout/tablelayout.component";
import { ColumnFilterComponent } from './ColumnFilter/columnfilter.component';
import { JBMTableComponent } from './JBMTable.component';
import { JBMTableHeaderComponent } from './JBMTableHeader/JBMTableHeader.component';
import { JBMFilterToggleComponent} from './JBMFilterToggle/JBMFilterToggle.component';
import { ActionDirective } from './action.directive';
import { CellDirective } from './cell.directive';
import { ColumnDirective } from './column.directive';

@NgModule({
  imports: [CommonModule, NgbModule, SharedModule, JBMButtonsModule, JBMDropdownsModule],
  declarations: [JBMTableComponent, TableLayoutComponent, ColumnDirective, CellDirective, ColumnFilterComponent, ActionDirective,
    JBMTableHeaderComponent, JBMFilterToggleComponent],
  exports: [JBMTableComponent, TableLayoutComponent, ColumnDirective, CellDirective, ColumnFilterComponent, ActionDirective,
    JBMTableHeaderComponent, JBMFilterToggleComponent]
})
export class JBMTableModule { }
