import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyRoutingModule } from './company-routing.module';
import {SharedModule } from '../../shared/shared.module';
import {JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import {JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import {JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import {JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import {JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import {CompanyContainerComponent} from "./company.container.component";
import {CompanyComponent} from "./company/form/company.component"
import {PlantsComponent} from "./plants/datatable/plants.component";
import {PlantComponent} from "./plants/form/plant.component";
import {SharedRecipesModule} from "../../shared/recipes/shared.recipes.module";
import {SelectorsModule} from "../../components/domain/selectors/selectors.module";
import { CompanySettingsComponent } from './settings/company-settings/company-settings.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [CompanyContainerComponent, CompanyComponent, PlantsComponent, PlantComponent, CompanySettingsComponent],
    imports: [CommonModule, CompanyRoutingModule, SharedModule, SharedRecipesModule, JBMTableModule, JBMViewModule,
        JBMFormModule, JBMModalModule, JBMButtonsModule, SelectorsModule, LayoutModule]
})
export class CompanyModule { }
