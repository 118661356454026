<div ngbDropdown container="body" (openChange)="openChange($event)">
   <button type="button" [disabled]="!comments" class="btn btn-{{btnContext}} {{class}}" id="dd1" ngbDropdownToggle
      [ngClass]="{'btn-sm': !extraSmall, 'btn-xs': extraSmall}" (click)="$event.stopPropagation();">
      <i *ngIf="iconClass" class="fas fa-{{iconClass}}"></i>
      <i *ngIf="!iconClass" class="fas fa-comments"></i>
      <span *ngIf="caption"> {{caption}}</span>
   </button>
   <div ngbDropdownMenu [attr.aria-labelledby]="'dd1'" class="table-cell comments">
      {{comments}}
   </div>
</div>
