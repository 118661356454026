import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'jbm-close-button',
  templateUrl: './JBMCloseButton.component.html',
  styleUrls: ['./JBMCloseButton.component.scss'],
  host: {'class': 'JBMButton'}
})
export class JBMCloseButtonComponent {
  @Input() visible: boolean=true;
  @Output() click = new EventEmitter();
  onBtnClick(event) {
    this.click.emit();
  }
}
