import {Component, OnInit, OnDestroy, HostListener, ElementRef} from '@angular/core';
import {Router} from "@angular/router";
import {BodyClassTogglerService} from "../../../../services/interface/body-class-toggler.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'jbm-shortcuts',
  templateUrl: './JBMShortcuts.component.html',
  styleUrls: ['./JBMShortcuts.component.scss']
})
export class JBMShortcutsComponent implements OnInit, OnDestroy {
  param$: Subscription;
  parentItems$: Subscription;

  constructor(
      private Router: Router,
      private eRef: ElementRef,
      private BodyClassTogglerService: BodyClassTogglerService
  ) { }

  ngOnInit(): void {
    this.BodyClassTogglerService.checkClass('aside-right');
  }
  close() {
    this.Router.navigate([{ outlets: { 'ar': null }} ] );
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.close();
  }
  @HostListener('window:keydown', ['$event'])
  handleEscape(event: KeyboardEvent) {
    if(event.code==='Escape')
      this.close();
  }

  ngOnDestroy() {
    if(this.parentItems$!=undefined) this.parentItems$.unsubscribe();
    if(this.param$!=undefined) this.param$.unsubscribe();
    this.BodyClassTogglerService.removeClass('aside-right');
  }
}
