<app-header [module_header]="asModule">
    <jbm-view-title [caption]="'menu.projects'|translate" iconClassname="cogs"
      [itemcount]="queryHttpHelper.paginationProps.itemCount">
    </jbm-view-title>
    <div class="toolbar float-start">
        <jbm-create-button [visible]="userRights.AllowCreate" [className]="'float-start'" (click)="addItem()"></jbm-create-button>
        <div class="toolbar-spacer-2"></div>
    </div>
    <div *ngIf="plantOptions.length>1" class="toolbar float-start">
        <label>{{'company.plant'|translate}}</label>
        <select (change)="changePlant($event)" class="form-control form-control-sm form-select">
            <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
        </select>
        <div class="toolbar-spacer-2"></div>
    </div>
    <div class="toolbar float-start">
        <jbm-projectstate-dropdown
          [projectstates]="projectstates"
          [projectstate]="projectstate"
          (projectStateChanged)="setProjectstate($event)">
        </jbm-projectstate-dropdown>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
                        [pageSize]="queryHttpHelper.paginationProps.pageSize"
                        [page]="queryHttpHelper.paginationProps.page"
                        (pageClick)="pageChange($event)" [hidden]="!initialCount">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisble && !filtersVisible" [search]="search" [minlength]="3" [id]="'search-box'"
                       (searchChange)="searchChange($event)" [hidden]="!initialCount">
        </jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <div class="btn-group">
            <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)" [hidden]="!initialCount"></jbm-filter-toggle>
            <jbm-table-layout [caption]="'ui.columns-layout'|translate"
              [columnDefs]="columnDefs" (columnToggled)="saveLayout()"></jbm-table-layout>
        </div>
        <div *ngIf="asModule" class="toolbar-spacer-2 float-end"></div>
        <jbm-help-button *ngIf="asModule" [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section id="projects-table" [ngClass]="{'module': asModule, 'padding': !asModule }">
    <jbm-table #table [classname]="'projects padding'"
      [data]="projects"
      [absolute]="asModule"
      [filterable]="true"
      [showFilters]="filtersVisible"
      [searchMinLength]="3"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="true"
      [columnDefs]="columnDefs"
      [actionDefs]="actionDefs"
      (rowCountChange)="rowCountChange($event)"
      (sortChange)="sortChange($event)"
      (filterChange)="filterChange($event)"
      (rowSelect)="rowSelect($event)"
      (actionClick)="actionClick($event)">
    </jbm-table>
</section>
<jbm-toasts></jbm-toasts>

<ng-template #templateProjectstate let-data="data">
    <span class="td-badge badge badge-pill" style="background-color: {{data.color}};color: {{data.text_color}}">
        {{data.projectstate}}
    </span>
</ng-template>
<ng-template #templateCustomer let-data="data">
    <button *ngIf="data.customer_id" class="btn btn-sm btn-link text-left float-start pl-0"
      [ngbTooltip]="('entity.customer'|translate)+' '+('ui.details'|translate|lowercase)"
      (click)="showCustomer(data.customer_id);$event.stopPropagation();">
        <span class="float-start">{{data.customername}}</span>
    </button>
    <span *ngIf="data.customer_id && data.blocked" class="pl-0 pt-1 blocked float-start">
       <span [ngbTooltip]="'entity.blocked'|translate" class="text-danger"><i class="fa fa-exclamation-triangle"></i></span>
    </span>
</ng-template>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
    <project-details [project_id]="id" (onClose)="closeProject()"></project-details>
</ng-template>
