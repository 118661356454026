import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { OrderArticle } from "./interfaces/order-article";

const baseURL: string = 'projects/';
const slug: string = 'order-articles/';

export const price_type_system_codes = { M: 57, M2: 54, M3: 1, PIECE: 56, ITEM: 59, PERCENT: 58, TRANSPORT: 2 }
export const article_usages = { recipe: 0, order: 1, required: 2 }

@Injectable({
  providedIn: 'root'
})
export class OrderArticlesService {
  orderArticle: OrderArticle;

  constructor(private HttpService: HttpService) { }

  get(orders_id: number, language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+orders_id.toString()+'/'+language, params );
  }
  getSelectList(language: string, projects_id: number, orders_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+'selectlist/'+language+'/'+projects_id.toString()+'/'+orders_id.toString(), params );
  }
  getOne(id:number) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id.toString());
  }
  getEmpty() {
    this.orderArticle = {
      id: 0,
      orders_id: 0,
      articles_id: 0,
      usage: article_usages.order,
      amount: 0.0,
      price: 0.0
    }
    return this.orderArticle;
  }
  calcTotalPrice(article_price: number, article_amount: number, article_price_types_id: number, order_amount: number) {
    if(article_price_types_id===price_type_system_codes.ITEM)
        // Applies to an item occurrence (item like quotation item, project order, or invoice item)
      return article_price;

    if(article_amount===null || !article_amount)
      return 0;

    if(article_price_types_id===price_type_system_codes.PERCENT) {
      // Applies to percentage of order amount
      if(!order_amount) return 0;
      return (order_amount/100) * article_amount * article_price;
    }

    return article_amount * article_price;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
  massUpdate(order_id: number, data: any) {
    return this.HttpService.post(baseURL+'update/'+slug+'mass/'+order_id.toString(), data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+slug+id.toString());
  }
}
