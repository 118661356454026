<app-header>
   <jbm-view-title [caption]="('entity.presentation'|translate) + ' ' + ('presentations.templates'|translate|lowercase)"
     [itemcount]="queryHttpHelper.paginationProps.itemCount"
     [parentLinkCaption]="'menu.settings'|translate"
     [parentLinkSlug]="'settings'">
   </jbm-view-title>
   <div class="toolbar float-start">
      <jbm-create-button [visible]="userRights.AllowCreate" (click)="addItem()"></jbm-create-button>
   </div>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page"
        (pageClick)="pageChange($event)">
      </jbm-pagination>
      <jbm-searchbox [id]="'search-box'" [visible]="searchboxVisible && !filtersVisible" [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module">
   <jbm-table #table [classname]="'presentations padding'"
     [absolute]="true"
     [data]="items"
     [filterable]="true"
     [showFilters]="filtersVisible"
     [sortable]="true"
     [sortColumn]="queryHttpHelper.sortProps.name"
     [sortDirection]="queryHttpHelper.sortProps.direction"
     [hoverable]="true"
     [rowSelectable]="userRights.AllowUpdate"
     [columnDefs]="columnDefs"
     [actionDefs]="actionDefs"
     (rowCountChange)="rowCountChange($event)"
     (sortChange)="sortChange($event)"
     (filterChange)="filterChange($event)"
     (rowSelect)="rowSelect($event)">
   </jbm-table>
</section>
<ng-template #usageTemplate let-data="data">
   <span *ngFor="let usage of data.usages" class="badge bg-secondary mr-1">{{usage}}</span>
</ng-template>
<ng-template #actionsTemplate let-data="data">
   <div class="width-6 float-end">
      <jbm-confirm-delete-dropdown *ngIf="userRights.AllowDelete && !data.is_system" class="float-end"
        [placement]="'left-top'"
        [subject]="('entity.presentation'|translate)+' '+('presentations.template'|translate|lowercase)"
        (onConfirm)="delete(data.id)">
      </jbm-confirm-delete-dropdown>
      <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-light mr-3 float-start" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
        (click)="edit(data.id); $event.stopPropagation()"><i class="far fa-edit"></i>
      </button>
   </div>
   <div class="width-16 float-start">
      <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-primary mr-3 editor" [ngbTooltip]="('presentations.template'|translate)+' '+('ui.modify'|translate|lowercase)"
        (click)="editTemplate(data); $event.stopPropagation()">
         <i class="fa" [ngClass]="{'fa-envelope': data.output_type==1, 'fa-file-pdf': data.output_type==0}"></i>
         {{'presentations.template'|translate}}
      </button>
      <button *ngIf="userRights.AllowUpdate && data.output_type==1" type="button" class="btn btn-sm btn-primary mr-3 attachments"
        (click)="editAttachments(data.id); $event.stopPropagation()">
         <i class="fa" [ngClass]="{'fa-envelope': data.output_type==1, 'fa-file-pdf': data.output_type==0}"></i>
         {{'entity.email-attachments'|translate}}
      </button>
   </div>
</ng-template>

<jbm-modal [id]="'presentation'" [className]="'presentation'"></jbm-modal>
<ng-template #modalPresentation let-modal>
   <form-presentation [presentation]="presentation" (onSave)="saved()" (onCancel)="cancelled()"></form-presentation>
</ng-template>
<jbm-toasts></jbm-toasts>
