import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {QuotationsService} from "../../data/quotations.service";
import {Quotation} from "../../data/interfaces/quotation";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {differenceInDays, addDays} from 'date-fns';

@Component({
  selector: 'form-quotation-revision',
  templateUrl: './form-quotation-revision.component.html',
  styleUrls: ['./form-quotation-revision.component.scss']
})
export class FormQuotationRevisionComponent implements OnInit {
  form: FormGroup;
  submitted: boolean=false;
  warning: string;

  @Input() quotation: Quotation=this.QuotationsService.getEmpty();

  @Output() onSave=new EventEmitter();
  @Output() onClose=new EventEmitter();

  constructor(
      private FormBuilder: FormBuilder,
      private DatetimeService: DatetimeService,
      private TranslateService: TranslateService,
      private QuotationsService: QuotationsService
  ) {
    this.addControls();
  }
  ngOnInit(): void {
    this.setControlValues();
  }
  addControls() {
    this.form = this.FormBuilder.group(
        {
          quotation_date: new FormControl(0,[Validators.required, this.DatetimeService.dateValidator(this.DatetimeService,true)]),
          validity_period: new FormControl('',{updateOn: 'blur',
            validators: [Validators.min(1), Validators.max(365), Validators.pattern('[1-9][0-9]*')]} ),
          due_date: new FormControl(0,[Validators.required, this.DatetimeService.dateValidator(this.DatetimeService,true)])
        }
    );
  }
  setControlValues() {
    this.form.controls.quotation_date.setValue(this.quotation.quotation_date);
    this.form.controls.validity_period.setValue(this.quotation.validity_period.toString());
    this.form.controls.due_date.setValue(this.quotation.due_date);
  }
  get formControls() {
    return this.form.controls;
  }
  calcValidityPeriod() {
    let date=this.DatetimeService.getDatePartsDMY(this.formControls.quotation_date.value);
    let due=this.DatetimeService.getDatePartsDMY(this.formControls.due_date.value);
    let dDate=new Date(date.year, date.month, date.day);
    let dDue=new Date(due.year, due.month, due.day);

    let difference=differenceInDays(dDue, dDate);

    this.form.controls.validity_period.setValue(difference.toString());
  }
  calcEndDate(event) {
    let validity_period=this.form.controls.validity_period.value;
    let date=this.DatetimeService.getDatePartsDMY(this.formControls.quotation_date.value);
    let dDue=addDays(new Date(date.year,date.month-1, date.day), validity_period);
    this.formControls.due_date.setValue(this.DatetimeService.getDateToDMY(dDue));
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let date=this.DatetimeService.dateDMYToYMD(this.formControls.quotation_date.value);
    let due_date=this.DatetimeService.dateDMYToYMD(this.formControls.due_date.value);
    if(date>=due_date) {
      this.warning=this.TranslateService.GetTranslation('quotation.date-before-due-date');
      return;
    }

    let data={
      quotation_id: this.quotation.id,
      quotation_date: this.DatetimeService.getDatePartsDMY( this.form.controls.quotation_date.value),
      validity_period: parseInt(this.form.controls.validity_period.value)
    }
    this.QuotationsService.createRevision(data).subscribe(
        ()=>{},(error)=>console.error(error),()=>this.onSave.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
