import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {PriceService} from "../../../services/price/price.service";
import {QuotationItemsService} from "../data/quotation-items.service";
import {QuotationArticlesService} from "../data/quotation-articles.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {DatetimeService} from "../../../services/datetime/datetime.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {HttpParams} from "@angular/common/http";
import {price_usage_subjects} from "../../financial/data/price-usages.service";
import {price_type_system_codes} from "../../projects/data/order-articles.service";

@Component({
  selector: 'quotation-items',
  templateUrl: './quotation-items.component.html',
  styleUrls: ['./quotation-items.component.scss'],
  providers: []
})
export class QuotationItemsComponent implements OnInit, OnChanges {
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;
  subject: number=price_usage_subjects.quotation_article;
  items: any[]=[];
  itemAmount: number=0;
  quotation_revision_id: number=0;
  quotation_item_id: number=0;

  code: string='';
  recipe: string='';
  recipe_id: number=0;
  environment: string='';
  order_id: number=0;
  article_id: number=0;
  article_price_types_id: number=price_type_system_codes.M3;
  id: number = 0;

  creating: boolean = false;
  editing: boolean = false;

  amount: string = '';
  amountTouched: boolean = false;
  price: string = '';
  priceTouched: boolean = false;
  description: string = '';
  descriptionTouched: boolean = false;
  totalPrice: number = 0.0;

  constructor(
      private QuotationItemsService: QuotationItemsService,
      private QuotationArticlesService: QuotationArticlesService,
      private SanitizationService: SanitizationService,
      private TranslateService: TranslateService,
      private DatetimeService: DatetimeService,
      private JBMToastsService: JBMToastsService,
      private ModalService: ModalService,
      private PriceService: PriceService,
      private TokenService: TokenService
  ) {}

  @Input() quotation: any;
  @Input() userRights: GroupRights;
  @Input() editable: boolean;
  @Input() viewSettings: any;

  @Output() onUpdateTotalsNeeded=new EventEmitter();

  @ViewChild('modalItem') modalItem: ElementRef;
  @ViewChild('modalOrderDetails') modalOrderDetails: ElementRef;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;

  ngOnInit(): void {
    if(this.quotation==undefined) this.quotation={};
    if(this.editable==undefined) this.editable=true;
    this.loading=true;
  }
  ngOnChanges() {
    if(this.items.length===0)
      this.getData();
  }
  getData() {
    let params=new HttpParams().append('concrete_specs','1');
    params=params.append('articles','1');
    this.QuotationItemsService.get(this.language, this.quotation.revision_id, params).subscribe(
        (data)=>this.items=data.data,
        (error)=>console.error(error),
        ()=> {
          this.formatData();
          if(this.loading) this.loading=false;
        }
    )
  }
  formatData() {
    for(let item of this.items) {
      item.amountStr=this.SanitizationService.amountFloatToStr(item.amount,1);
      item.priceStr=this.PriceService.formatPrice(item.concrete_price);
      item.unitPriceStr=this.PriceService.formatPrice(item.totalprice);
      item.totalpriceStr=this.PriceService.formatPrice(item.totalprice);
      item.editing=false;
    }
  }
  create() {
    this.quotation_item_id=0;
    this.ModalService.open(this.modalItem,'quotationItem');
  }
  // Invoice items
  editItem(id: number) {
    this.quotation_item_id=id;
    this.ModalService.open(this.modalItem,'quotationItem');
  }
  onSaveItem() {
    this.ModalService.close('quotationItem');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.onUpdateTotalsNeeded.emit();
    this.getData();
  }
  onCancelItem() {
    this.ModalService.close('quotationItem');
  }
  delete(quotation_item_id: number) {
    this.QuotationItemsService.remove(quotation_item_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.onUpdateTotalsNeeded.emit();
          this.getData();
        }
    )
  }

  // Articles
  editArticle(id: number) {
    if(!this.editable) return;

    let article=this.getArticle(id);
    if(!article || article.editing) return false;

    if(this.creating)
      this.cancelCreating();

    if(this.editing && this.id!==article.id)
      this.cancelEditing();

    article['editing']=true;
    this.editing = true;
    this.clearItemData();
    this.setItemData(article);
    this.totalPrice=this.QuotationArticlesService.calcTotalPrice(
        article.price, article.amount, this.article_price_types_id, this.itemAmount);

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('article-' + id.toString())).focus();
    },100);
  }
  cancelArticle() {
    let article=this.getArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.clearItemData();
  }
  saveArticle() {
    let article=this.getArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;

    this.updateArticle(article);
  }
  getArticle(id: number) {
    for(let quotation_item of this.items)
      for(let article of quotation_item.articles)
        if(article.id===id) {
          this.article_price_types_id=article.article_price_types_id;
          this.itemAmount=quotation_item.amount;
          return article;
        }
    return false;
  }
  onChangeAmount() {
    this.amountTouched=true;
    this.calculateTotalPrice();
  }
  validateAmount() {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount,0);
    if(this.article_price_types_id===price_type_system_codes.PERCENT)
      return (amount>=0 && amount<=100)
    return amount >= 0;
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    return this.description.trim()!=='';
  }
  updateArticle(article: any) {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount,0);
    let price =this.SanitizationService.checkPriceStr(this.price);
    this.calculateTotalPrice();

    // Update fields in interface to prevent showing old values before data update completion
    article.amount=amount;
    article.price=price;
    article.totalprice=this.totalPrice;

    let data={
      id: this.id,
      quotation_revisions_id: this.quotation.revision_id,
      quotation_items_id: article.quotation_items_id,
      articles_id: this.article_id,
      description: this.description,
      price: price,
      amount: amount,
      totalprice: this.totalPrice
    }
    this.QuotationArticlesService.update(data).subscribe(()=>{},(error)=>console.error(error),()=> this.afterSave())
  }
  setItemData(article) {
    this.id=article.id;
    this.article_id=article.articles_id;
    this.article_price_types_id=article.article_price_types_id;
    this.code=article.price_type_code;
    this.amount=this.SanitizationService.amountFloatToStr(article.amount);
    this.price=this.SanitizationService.amountFloatToStr(article.price);
    this.description=article.description;
  }
  cancelCreating() {
    this.quotation_item_id=-1;
    this.creating=false;
    this.clearItemData();
  }
  cancelEditing() {
    for(let item of this.items) {
      for(let article of item.articles)
        article.editing=false;
    }
  }
  clearItemData() {
    this.id=0;
    this.article_id=0;
    this.amount='';
    this.amountTouched=false;
    this.price='';
    this.totalPrice=null;
    this.priceTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  calculateTotalPrice() {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
    let price  = this.SanitizationService.checkPriceStr(this.price);

    this.totalPrice=this.QuotationArticlesService.calcTotalPrice(price, amount, this.article_price_types_id, this.itemAmount );
  }
  afterSave() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.getData();
    this.cancelCreating();
    this.cancelEditing();
    this.onUpdateTotalsNeeded.emit();
  }

  // Recipe
  showRecipe(recipe_id: number, environment: string) {
    this.recipe_id=recipe_id;
    this.environment=environment;
    this.ModalService.open(this.modalRecipe,'itemRecipe');
  }
  closeRecipe() {
    this.ModalService.close('itemRecipe');
  }
  // Order
  showOrder(id: number) {
    this.order_id=id;
    this.ModalService.open(this.modalOrderDetails,'orderDetails')
  }
  closeOrder() {
    this.ModalService.close('orderDetails');
  }
}
