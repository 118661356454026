import {Component, Input, OnInit, Output, EventEmitter, ViewChild} from '@angular/core';
import {FormGroupBaseComponent} from "../../formgroupbase.component";
import {InputHelper} from "../../../../Helpers/InputHelper";

@Component({
  selector: 'jbm-float-input-group',
  templateUrl: './JBMFloatInputGroup.component.html',
  styleUrls: ['./JBMFloatInputGroup.component.scss']
})

export class JBMFloatInputGroupComponent extends FormGroupBaseComponent implements OnInit {
  @Input() type: string='text';
  @Input() maxlength: number=0;
  @Input() decimals: number=2;
  @Input() decimalsOptional: boolean=false;
  @Input() negativeAllowed: boolean=true;
  @Input() emptyIfZero: boolean=true;
  @Input() prepend: string;
  @Input() append: string;
  @Output() onBlur = new EventEmitter();

  ngOnInit() {
    if(this.type==undefined) this.type='text';
    if(this.maxlength==undefined) this.maxlength=0;
    if(this.decimals==undefined) this.decimals=2;
    if(this.decimalsOptional==undefined) this.decimalsOptional=false;
    if(this.prepend==undefined) this.prepend='';
    if(this.append==undefined) this.append='';

    super.ngOnInit();
  }
  blur(event) {
    if(typeof this.ngControl.control.value==='number')
      this.ngControl.control.setValue(this.ngControl.control.value.toString());

    let value=this.ngControl.control.value.trim();
    if(!value) {
      this.onBlur.emit(event);
      return;
    }

    if(this.decimals<0) this.decimals=1;

    let helper=new InputHelper();
    value=helper.sanitizeFloatValue(value, this.decimalsOptional, this.decimals, this.emptyIfZero);

    this.ngControl.control.setValue(value);
    this.onBlur.emit(event);
  }
}
