import {Component, Input} from '@angular/core';
import {FormGroupBaseComponent} from "../formgroupbase.component";

@Component({
  selector: 'jbm-check',
  templateUrl: './JBMCheck.component.html',
  styleUrls: ['./JBMCheck.component.scss']
})
export class JBMCheckComponent extends FormGroupBaseComponent {
  @Input() textUnchecked: string='';
  @Input() textChecked: string='';
  @Input() alignLeft: boolean=false;

}
