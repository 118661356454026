<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.invoice'|translate}} {{'entity.delete'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <jbm-static-label [labelCaption]="'invoice.number'|translate" [value]="invoiceNumber"></jbm-static-label>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <button type="button" class="btn btn-sm btn-primary" (click)="remove()">{{'entity.invoice'|translate}} {{'entity.delete'|translate|lowercase}}</button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button (click)="close()"></jbm-cancel-button>
   </div>
</div>
