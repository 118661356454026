import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {SettingsComponent} from "./settings.component";

const routes: Routes = [
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
