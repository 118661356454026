import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {PricelistsType} from "./article-pricelists.service";

const baseURL: string = 'financial/';
const slugs = ['article','concrete','resource'];

@Injectable({
  providedIn: 'root'
})
export class CustomerPricelistsService {

  constructor(private HttpService: HttpService) { }

  getData(pricelistsType: PricelistsType, language: string, params: HttpParams) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL+'read/customer-'+slug+'-pricelists/'+language, params );
  }
  getOne(pricelistsType: PricelistsType, id: number, language: string) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL+'read/customer-'+slug+'-pricelists/'+'one/'+id.toString()+'/'+language );
  }
  create(pricelistsType: PricelistsType, data: any) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL+'create/customer-'+slug+'-pricelists/', data);
  }
  createMulti(pricelistsType: PricelistsType, data: any) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL+'create/multi/customer-'+slug+'-pricelists/', data);
  }
  deleteUse(pricelistsType: PricelistsType, id: number, customer_id: number) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL+'read/customer-'+slug+'-pricelists/delete-use/'+id.toString()+'/'+customer_id.toString());
  }
  update(pricelistsType: PricelistsType, data: any) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL+'update/customer-'+slug+'-pricelists/', data);
  }
  copy(pricelistsType: PricelistsType, pricelist_id: number, customer_id: number, data) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL+'create/customer-'+slug+'-pricelists/copy/'+pricelist_id.toString()+'/'+customer_id.toString(), data);
  }

  getPricelistsTypeSlug(pricelistsType: PricelistsType) {
    return slugs[pricelistsType];
  }
}
