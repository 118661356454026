import {Component, Input, OnInit} from '@angular/core';
import {InvoiceValidationsService} from "../../data/invoice-validations.service";
import {InvoiceValidation} from "../../data/interfaces/invoice-validation";
import {UserService} from "../../../users/data/user.service";
import {TokenService} from "../../../../services/auth/token.service";

@Component({
  selector: 'invoice-validations',
  templateUrl: './invoice-validations.component.html',
  styleUrls: ['./invoice-validations.component.scss']
})
export class InvoiceValidationsComponent implements OnInit {
  validations: InvoiceValidation[]=[];
  user_id: number=this.TokenService.getUserId();
  isEmpty: boolean=false;

  constructor(
      private InvoiceValidationsService: InvoiceValidationsService,
      private UserService: UserService,
      private TokenService: TokenService
  ) {}

  @Input() invoice_id: number=0;
  @Input() excludeCurrentUser: boolean=false;

  ngOnInit(): void {
    this.InvoiceValidationsService.get(this.invoice_id).subscribe(
        (data)=>{
            this.validations=data
            this.isEmpty=!this.validations.length;
        },
        (error)=>console.error(error),
        ()=> {
          for(let validation of this.validations)
            this.UserService.getOne(validation.users_id).subscribe(
                (data)=>validation.user=data.data,
                (error)=>console.error(error),
                ()=> {
                  for(let validation of this.validations)
                    if(this.excludeCurrentUser && validation.users_id===this.user_id && this.validations.length===1)
                      // Exclude current user validation from table view
                      this.isEmpty=true;
                }
            )
        }
    )
  }
}
