<app-header>
    <jbm-view-title [caption]="'financial.articles'|translate"
        [itemcount]="queryHttpHelper.paginationProps.itemCount"
        [parentLinkCaption]="'menu.settings'|translate"
        [parentLinkSlug]="'settings'">
    </jbm-view-title>
    <div class="toolbar float-start">
        <jbm-create-button [visible]="userRights.AllowCreate" (click)="addItem()"></jbm-create-button>
        <div class="toolbar-spacer-2"></div>
        <label>{{'financial.articlegroup'|translate}}</label>
        <select (change)="changeArticlegroup($event)" class="form-control form-select">
            <option *ngFor="let group of articlegroupOptions" value="{{group.key}}">{{group.value}}</option>
        </select>
    </div>

    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [id]="'search-box'" [visible]="searchboxVisible && !filtersVisible" [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module">
    <jbm-table #table [classname]="'articles padding'"
       [absolute]="true"
       [data]="items"
       [filterable]="true"
       [showFilters]="filtersVisible"
       [sortable]="true"
       [sortColumn]="queryHttpHelper.sortProps.name"
       [sortDirection]="queryHttpHelper.sortProps.direction"
       [hoverable]="true"
       [rowSelectable]="userRights.AllowCreate"
       [columnDefs]="columnDefs"
       [actionDefs]="actionDefs"
       (rowCountChange)="rowCountChange($event)"
       (sortChange)="sortChange($event)"
       (filterChange)="filterChange($event)"
       (actionClick)="action($event)"
       (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>
<jbm-toasts></jbm-toasts>
