import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {Order} from "../../../../features/projects/data/interfaces/order";
import {OrdersService} from "../../../../features/projects/data/orders.service";
import {OrderPresenter} from "../../../../features/projects/presenters/order.presenter";
import {TokenService} from "../../../../services/auth/token.service";

@Component({
  selector: 'order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {
  order: any=this.OrdersService.getEmpty();
  data: any;
  language: string=this.TokenService.getLanguage();

  constructor(
      private OrdersService: OrdersService,
      private OrderPresenter: OrderPresenter,
      private TokenService: TokenService
  ) {
    this.order.consistency={description: ''};
    this.order.strength={description:''};
    this.order.environment={description:''};
  }

  @Input() order_id: number;
  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    let params=new HttpParams()
        .append('recipe','1')
        .append('environment','1')
        .append('worktype','1')
        .append('unloading_method','1')
        .append('plant','1')
    this.OrdersService.getOne(this.order_id, this.language, params).subscribe(
        (data)=>this.order=data.data,
        (error)=>console.error(error),
        ()=> {
          this.data=this.OrderPresenter.format(this.order);
          console.log(this.data);
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
