<app-header [class]="'invoice'">
   <jbm-view-title [caption]="'entity.quotation'|translate"
     [subCaption]="quotation.quotation_number"
     [parentLinkCaption]="'menu.quotations'|translate"
     [parentLinkSlug]="'quotations'"></jbm-view-title>
   <div class="float-start toolbar">
      <span class="badge badge-xl badge-pill float-start" style="background-color: {{quotationState.color}};color: {{quotationState.text_color}}">
         {{quotationState.description}}
      </span>
     <div class="toolbar-spacer-1"></div>
     <button class="btn btn-light float-start" [ngbTooltip]="('quotation.state'|translate)+' '+('entity.history'|translate|lowercase)"
       (click)="showStateFlow();$event.stopPropagation();">
       <i class="fa fa-history"></i>
     </button>
     <div class="toolbar-spacer-1"></div>
     <a class="btn btn-sm btn-link" target="_blank" [href]="'quotation-preview/'+quotation.revision_id">
        PDF {{'ui.preview'|translate|lowercase}}<i class="fa fa-external-link-alt ml-2"></i>
     </a>
     <div class="toolbar-spacer-1"></div>
   </div>
   <div class="float-end toolbar action-buttons">
      <div *ngIf="quotation.concrete_total" class="totals">
         <label class="price float-start">{{'ui.totalprice'|translate}}:&nbsp;&nbsp;
            <span *ngIf="taxInclusive">{{quotation.totalprice_incl_vat|money}}</span>
            <span *ngIf="!taxInclusive">{{quotation.totalprice|money}}</span>
         </label>
         <div ngbDropdown class="float-start">
            <button type="button" class="btn btn-sm btn-outline-primary" id="inclexcl" ngbDropdownToggle>
               <span *ngIf="taxInclusive">{{'ui.inclusive'|translate}}</span>
               <span *ngIf="!taxInclusive">{{'ui.exclusive'|translate}}</span>
            </button>
            <div ngbDropdownMenu [attr.aria-labelledby]="'inclexcl'">
               <button *ngIf="taxInclusive" ngbDropdownItem (click)="toggleTaxInclusive()">{{'ui.exclusive'|translate}}</button>
               <button *ngIf="!taxInclusive" ngbDropdownItem (click)="toggleTaxInclusive()">{{'ui.inclusive'|translate}}</button>
            </div>
         </div>
         <label class="float-start">{{quotation.vat|money}} ({{quotation.vat_rate}}%) {{'financial.tax'|translate}}</label>
      </div>
      <div class="toolbar-spacer-2"></div>
      <div ngbDropdown>
         <button class="actions btn btn-light btn-block" id="quoActions" ngbDropdownToggle (click)="$event.stopPropagation();">
            <i class="fa fa-ellipsis-h"></i>
         </button>
         <div ngbDropdownMenu aria-labelledby="quoActions">
           <button *ngIf="userRights.AllowUpdate" ngbDropdownItem (click)="createRevision()">{{'entity.quotation'|translate}} {{'quotation.revision'|translate|lowercase}} {{'entity.create'|translate|lowercase}}...</button>
           <div *ngIf="userRights.AllowUpdate" class="dropdown-divider"></div>
           <button *ngIf="userRights.AllowUpdate && quotation.allowApprove" ngbDropdownItem (click)="approve()">{{'entity.approve'|translate}}...</button>
           <button *ngIf="userRights.AllowUpdate && quotation.allowDraft" ngbDropdownItem (click)="draft()">{{'entity.make-concept'|translate}}...</button>
           <button *ngIf="userRights.AllowUpdate && quotation.allowSend" ngbDropdownItem (click)="send()">{{'entity.send'|translate}}...</button>
           <button *ngIf="userRights.AllowUpdate && quotation.allowNegotiate" ngbDropdownItem (click)="negotiate()">{{'quotation.in-negotiation'|translate}}...</button>
           <button *ngIf="userRights.AllowUpdate && quotation.allowAccept" ngbDropdownItem (click)="accept()">{{'ui.accept'|translate}}...</button>
           <button *ngIf="userRights.AllowUpdate && quotation.allowCancel" ngbDropdownItem (click)="cancel()">{{'ui.cancel'|translate}}...</button>
           <button *ngIf="userRights.AllowUpdate && quotation.allowReject" ngbDropdownItem (click)="reject()">{{'ui.reject'|translate}}...</button>
           <div *ngIf="userRights.AllowUpdate && quotation.allowApprove || userRights.AllowUpdate && quotation.allowDraft || userRights.AllowUpdate && quotation.allowSend ||
            userRights.AllowUpdate && quotation.allowNegotiate || userRights.AllowUpdate && quotation.allowAccept || userRights.AllowUpdate && quotation.allowCancel ||
            userRights.AllowUpdate && quotation.allowReject" class="dropdown-divider">
           </div>
           <button ngbDropdownItem (click)="showStateFlow()"><i class="fa fa-history"></i> {{'quotation.state'|translate}} {{'entity.history'|translate|lowercase}}</button>
           <button *ngIf="userRights.AllowDelete" ngbDropdownItem (click)="remove()">{{'ui.delete'|translate}}...</button>
         </div>
      </div>
      <div class="toolbar-spacer-2"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module quotation">
   <quotation-toolbar
     [settings]="viewSettings"
     [quotation_id]="quotation.id"
     [project_id]="quotation.projects_id"
     [customer_id]="quotation.customers_id"
     [plant_id]="quotation.project.plants_id"
     (onChange)="updateSettings($event)">
   </quotation-toolbar>

   <div *ngIf="quotation.quotation_states_id>200" class="clearfix">
      <div class="jbm-properties-list header inline float-start">
         <div class="item">
            <label class="label">{{'quotation.date'|translate}}:</label>
            <label class="value">{{quotation.quotation_date}}</label>
         </div>
         <div class="item">
            <label class="label">{{'entity.validity-period'|translate}}:</label>
            <label class="value">{{quotation.validity_period}} {{'datetime.days'|translate}}</label>
         </div>
         <div class="item">
            <label class="label">{{'entity.due-date'|translate}}:</label>
            <label class="value">{{quotation.due_date}}</label>
         </div>
      </div>
      <div *ngIf="userRightsRecipes.AllowRead" class="float-end pt-2 mr-4">
         <label class="float-start mr-2">
            {{'ui.base'|translate}}{{'entity.recipe'|translate|lowercase}}:
         </label>
         <div class="float-start">
            <jbm-link-button
              [caption]="baseRecipe.description + '&nbsp;&nbsp;(' + (baseRecipe.price|money) + ('quantity.per-m3'|translate) + ')'"
              (onClick)="showRecipe()" [style]="'margin-top: -2px'">
            </jbm-link-button>
         </div>
      </div>
   </div>
   <div class="pl-4 pr-4 pb-4">
      <quotation-details *ngIf="quotation.id"
        [editable]="editable"
        [quotation_id]="quotation.id"
        [revision_number]="quotation.revision_number"
        [expanded]="viewSettings.details">
      </quotation-details>
      <quotation-items #quotationItems
        [quotation]="quotation"
        [userRights]="userRights"
        [editable]="editable"
        [viewSettings]="viewSettings"
        (onUpdateTotalsNeeded)="refreshQuotation()">
      </quotation-items>
   </div>
</section>
<jbm-toasts></jbm-toasts>

<jbm-modal [id]="'statesFlow'" [className]="'statesFlow'"></jbm-modal>
<ng-template #modalStatesFlow let-modal>
  <quotation-states-flow [language]="language" [id]="quotation.id" [quotationNumber]="quotation.quotation_number"
    [revision_id]="quotation.revision_id" (onClose)="statesFlowClose()">
  </quotation-states-flow>
</ng-template>

<jbm-modal [id]="'recipeDetails'" [className]="'recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [recipe_id]="baseRecipe.id" [environment]="baseRecipe.environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
  <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>

<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
  <project-details [project_id]="id" (onClose)="closeProject()"></project-details>
</ng-template>

<jbm-modal [id]="'stateAction'" [className]="'state-action'"></jbm-modal>
<ng-template #modalApprove let-modal>
  <quotation-approve [quotation_revision_id]="quotation.revision_id" [quotationNumber]="quotation.quotation_number" (onSuccess)="approveSuccess()" (onClose)="stateActionClose()"></quotation-approve>
</ng-template>

<ng-template #modalDraft let-modal>
  <quotation-draft [quotation_revision_id]="quotation.revision_id" [quotationNumber]="quotation.quotation_number" (onSuccess)="draftSuccess()" (onClose)="stateActionClose()"></quotation-draft>
</ng-template>

<jbm-modal [id]="'quotationSend'" [className]="'content quotation-send'"></jbm-modal>
<ng-template #modalSend let-modal>
  <quotation-send [quotation]="quotation" (onSuccess)="sendSuccess()" (onClose)="sendClose()"></quotation-send>
</ng-template>

<ng-template #modalNegotiate let-modal>
  <quotation-negotiate [quotation_revision_id]="quotation.revision_id" [quotationNumber]="quotation.quotation_number" (onSuccess)="negotiateSuccess()" (onClose)="stateActionClose()"></quotation-negotiate>
</ng-template>

<ng-template #modalAccept let-modal>
  <quotation-accept [quotation_revision_id]="quotation.revision_id" [quotationNumber]="quotation.quotation_number" (onSuccess)="acceptSuccess()" (onClose)="stateActionClose()"></quotation-accept>
</ng-template>

<ng-template #modalCancel let-modal>
  <quotation-cancel [quotation_revision_id]="quotation.revision_id" [quotationNumber]="quotation.quotation_number" (onSuccess)="cancelSuccess()" (onClose)="stateActionClose()"></quotation-cancel>
</ng-template>

<ng-template #modalReject let-modal>
  <quotation-reject [quotation_revision_id]="quotation.revision_id" [quotationNumber]="quotation.quotation_number" (onSuccess)="rejectSuccess()" (onClose)="stateActionClose()"></quotation-reject>
</ng-template>

<ng-template #modalRemove let-modal>
  <quotation-remove [quotation_id]="quotation.id" [quotationNumber]="quotation.quotation_number" (onRemove)="removeSuccess()" (onClose)="stateActionClose()"></quotation-remove>
</ng-template>

<jbm-modal [id]="'revision'" [className]="'revision'"></jbm-modal>
<ng-template #modalRevision let-modal>
   <form-quotation-revision [quotation]="quotation" (onSave)="revisionSuccess()" (onClose)="revisionClose()"></form-quotation-revision>
</ng-template>
