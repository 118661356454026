import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { HelpTopic } from "./interfaces/helptopic";

const BaseURL: string = 'helpcentre/';
const URL: string = 'helptopics';

@Injectable({
    providedIn: 'root'
})
export class HelpTopicsService {
    helptopic: HelpTopic;

    constructor( private HttpService: HttpService ) {}

    get(language: string, params: HttpParams) {
        let url=BaseURL+'read/'+URL+'/'+language;
        return this.HttpService.get(url, params );
    }
    getParentTopics(language: string, parent_id: number) {
        let url=BaseURL+'read/'+URL+'/parents/'+language+'/'+parent_id;
        return this.HttpService.get(url);
    }
    getEmpty() {
        this.helptopic = {
            id: 0,
            parent_id: 0,
            position: 0,
            title: '',
            content: ''
        }
        return this.helptopic;
    }
    create(data: any) {
        return this.HttpService.post(BaseURL+'create/'+URL, data);
    }
    update(data: any) {
        return this.HttpService.post(BaseURL+'update/'+URL, data);
    }
    updatePosition(id: number, position: number, parent_id: number, direction: number) {
        return this.HttpService.post(
            BaseURL+'update/'+URL+'/position', { id: id, position: position, parent_id: parent_id, direction: direction});
    }
}
