<header *ngIf="userRights.AllowCreate" class="module subheader">
    <div class="header-section">
        <jbm-create-button [visible]="userRights.AllowCreate" class="mr-4" (click)="addGroup()"></jbm-create-button>
        <label for="app" class="label">{{'ui.app'|translate}}</label>
        <select id="app" class="form-control form-select mr-4" (change)="appChange($event)">
            <option value="0">Management</option>
            <option value="1">Receptuur</option>
        </select>
    </div>
</header>
<jbm-table #table
  [data]="usergroups"
  [sortable]="true"
  [sortColumn]="queryHttpHelper.sortProps.name"
  [sortDirection]="queryHttpHelper.sortProps.direction"
  [hoverable]="true"
  [rowSelectable]="true"
  [columnDefs]="columnDefs"
  [actionDefs]="actionDefs"
  [template]="templateGroupRights"
  (rowSelect)="rowSelect($event)"
  (actionClick)="actionClick($event)">
</jbm-table>

<ng-template #templateGroupRights let-data="data">
    <grouprights [appID]="appID" [usergroupID]="data.id"></grouprights>
</ng-template>

<jbm-modal [id]="'usergroup'" [className]="'usergroup'"></jbm-modal>
<ng-template #modalUsergroup let-modal>
    <form-usergroup [userRights]="userRights" [usergroup]="usergroup"
      (onSave)="saved()" (onCancel)="canceled()"></form-usergroup>
</ng-template>
<jbm-toasts></jbm-toasts>
