import { Injectable } from "@angular/core";
import { Router} from "@angular/router";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { environment } from '../../../environments/environment';
import {tap} from "rxjs/operators";

/* This interceptor sets the Authorisation token and Companyname in the HTTP header using the Angular HttpInterceptor
   for each HTTP request */

const company=environment.company;

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
      private authService: AuthService,
      private Router: Router,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    req = req.clone({
      setHeaders: { 'authentication': authToken, 'company': company}
    });

    return next.handle(req).pipe( tap(() => {},(err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401) return;
        this.Router.navigateByUrl('login');
      }})
    );
  }
}
