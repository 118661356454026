import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'production/';

@Injectable({
  providedIn: 'root'
})
export class ProductionLinesService {
  constructor(
      private HttpService: HttpService,
  ) {}

  get(receipt_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/lines/'+receipt_id, params );
  }
}
