<form [formGroup]="form" (ngSubmit)="save()" class="article-price">
   <div class="jbm-modal-header">
      <jbm-crud-title subject="financial.resource-price" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-activity-log-button *ngIf="id" subject_type="34" class="bg-light"
           [subject_id]="id" subject="{{'financial.resource-price'|translate}}"
           [title]="details.description">
         </jbm-activity-log-button>
         <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-properties-list className="article-pricelist" [properties]="priceListProps"></jbm-properties-list>
      <jbm-properties-list className="article" [properties]="resourceProps"></jbm-properties-list>
      <div *ngIf="id" class="jbm-properties-list article">
         <div class="item">
            <label class="label"></label>
            <label class="value">
               <article-price-history-dropdown [subject]="2" [price_id]="id"></article-price-history-dropdown>
            </label>
         </div>
      </div>
      <ngb-alert *ngIf="isSubmitted && data.resources_id==0" type="danger" class="mb-0" [dismissible]="false">{{'articles.select-article'|translate}}</ngb-alert>
      <button *ngIf="id==0 && !selectList" class="btn btn-secondary" (click)="showSelectList()">{{'resources.select-resource'|translate}}</button>
      <resource-selectlist *ngIf="selectList" [caption]="'resources.select-resource'|translate" (resourceSelected)="selectResource($event)"></resource-selectlist>
      <hr />
      <fieldset class="width-34 mt-3"><legend>{{'pricelist.validity'|translate}}</legend>
         <ngb-alert *ngIf="warning" type="warning" [dismissible]="false">{{warning}}</ngb-alert>
         <ngb-alert *ngIf="warningOverlap" type="warning" [dismissible]="false" class="mt-2">
            {{warningOverlap}}
         </ngb-alert>
         <jbm-date-input [formControl]="formControls.valid_from" [submitted]="isSubmitted" [id]="'valid_from'"
           [maxlength]="10" labelCaption="{{'pricelist.valid-from'|translate}}" labelClass="width-7"
           [placement]="'top'" class="input-8">
            <jbm-input-error [formControl]="formControls.valid_from" [submitted]="isSubmitted">
                  <span *ngIf="formControls['valid_from'].hasError('dateInvalid')">
                    {{'entity.dateformat'|translate}}</span>
            </jbm-input-error>
         </jbm-date-input>
         <jbm-date-input [formControl]="formControls.valid_to" [submitted]="isSubmitted" [id]="'valid_to'"
           [maxlength]="10" labelCaption="{{'pricelist.valid-to'|translate}}" labelClass="width-7"
           [placement]="'top'" class="input-8">
            <jbm-input-error [formControl]="formControls.valid_to" [submitted]="isSubmitted">
                  <span *ngIf="formControls['valid_to'].hasError('dateInvalid')">
                    {{'entity.dateformat'|translate}}</span>
            </jbm-input-error>
         </jbm-date-input>
      </fieldset>
      <jbm-float-input-group [formControl]="formControls.price" [submitted]="isSubmitted" [id]="'price'" required="true"
        labelCaption="{{'financial.price'|translate}}" labelClass="width-8" class="input-8" prepend="&euro;">
          <jbm-input-error [formControl]="formControls.price" [submitted]="isSubmitted">
              <span *ngIf="formControls['price'].hasError('priceInvalid')">
                        {{'entity.priceformat'|translate}}</span>
          </jbm-input-error>
      </jbm-float-input-group>
    </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'financial.resource-price'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
