import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'financial/';
const slug: string = 'article-prices/';

@Injectable({
  providedIn: 'root'
})
export class ArticlePricesService {

  constructor(private HttpService: HttpService) { }

  getData(language: string, article_pricelists_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+language+'/'+article_pricelists_id.toString(), params );
  }
  getOne(id: number, language: string) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id+'/'+language );
  }
  getHistory(language: string, id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+'history/'+language+'/'+id.toString(), params );
  }
  getEmpty() {
    return this.HttpService.get(baseURL+'read/'+slug+'empty');
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
  calculatePrice(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/calculate-article-price', params );
  }
  getDeletePermissions(ids: string[]) {
    return this.HttpService.get(baseURL+'read/article-price-delete-permissions/'+ids.join());
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+slug+id);
  }
}
