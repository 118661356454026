import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'float'
})
export class FloatPipe implements PipeTransform {
  transform( value: number, decimals: number=2 ): string | null {
    return Intl.NumberFormat('nl-NL',{ style: 'decimal', maximumFractionDigits: decimals }).format(value);
  }
}
