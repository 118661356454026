<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.invoice'|translate}} {{'project.orders'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <h6>{{'invoice.invoiced'|translate}}</h6>
   <div class="table-responsive">
      <table class="table table-xs table-hover table-row-selectable invoice-concretes">
         <thead>
         <tr>
            <th class="worktype">{{'order.worktype'|translate}}</th>
            <th class="description">{{'entity.description'|translate}}</th>
            <th class="recipe">{{'entity.recipe'|translate}}</th>
            <th class="amount align-right">{{'order.amount'|translate}}</th>
            <th class="action"></th>
         </tr>
         </thead>
         <tbody>
         <tr *ngFor="let order of orders">
            <td>
               <jbm-link-button [caption]="order.order.worktype.description" (onClick)="showOrder(order.order.id)"></jbm-link-button>
            </td>
            <td>
               <jbm-link-button *ngIf="order.order.description" [caption]="order.order.description" (onClick)="showOrder(order.order.id)"></jbm-link-button>
            </td>
            <td>
               <jbm-link-button [caption]="order.order.recipe.code" (onClick)="showRecipe(order.order)"></jbm-link-button>
            </td>
            <td class="amount align-right">{{order.order.amount|float}} {{'quantity.m3'|translate}}</td>
            <td class="action">
               <jbm-confirm-delete-dropdown *ngIf="editable && orders.length>1" [class]="'float-end'"
                 [subject]="('project.order'|translate)+' '+('ui.delete'|translate|lowercase)"
                 (onConfirm)="delete(order.invoice_concrete_id)">
               </jbm-confirm-delete-dropdown>
            </td>
         </tr>
         </tbody>
      </table>
   </div>
   <h6 class="mt-3">{{'invoice.to-invoice'|translate}}</h6>
   <div [hidden]="invoiceableOrders.length==0" class="table-responsive">
      <table class="table table-xs table-hover table-row-selectable invoice-concretes">
         <thead>
         <tr>
            <th class="worktype">{{'order.worktype'|translate}}</th>
            <th class="description">{{'entity.description'|translate}}</th>
            <th class="recipe">{{'entity.recipe'|translate}}</th>
            <th class="amount align-right">{{'order.amount'|translate}}</th>
            <th class="action"></th>
         </tr>
         </thead>
         <tbody>
         <tr *ngFor="let order of invoiceableOrders">
            <td>
               <jbm-link-button [caption]="order.worktype.description" (onClick)="showOrder(order.id)"></jbm-link-button>
            </td>
            <td>
               <jbm-link-button *ngIf="order.description" [caption]="order.description" (onClick)="showOrder(order.id)"></jbm-link-button>
            </td>
            <td>
               <jbm-link-button [caption]="order.recipe.code" (onClick)="showRecipe(order)"></jbm-link-button>
            </td>
            <td class="amount align-right">{{order.amount|float}} {{'quantity.m3'|translate}}</td>
            <td class="float-end">
               <button *ngIf="editable" class="btn btn-sm btn-primary" (click)="add(order.id)"><i class="fa fa-calculator"></i> {{'entity.create'|translate}}</button>
            </td>
         </tr>
         </tbody>
      </table>
   </div>
   <jbm-empty-view [emptyView]="invoiceableOrders.length==0"></jbm-empty-view>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button (click)="close()"></jbm-close-button>
   </div>
</div>

<jbm-modal [id]="'recipe'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [delivery_date]="" [recipe_id]="recipe_id" [environment]="environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<jbm-modal [id]="'order'" [className]="'order-details'"></jbm-modal>
<ng-template #modalOrder let-modal>
   <order-details [order_id]="order_id" (onClose)="closeOrder()"></order-details>
</ng-template>
