import {Component, Input, OnInit} from '@angular/core';
import {PermissionsService} from "../../data/permissions.service";
import {UsersService} from "../../data/users.service";
import {User} from "../../data/interfaces/user";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";

@Component({
  selector: 'recipe-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit {
  data: any[];
  permissions: any[];
  user: User=this.UsersService.getEmpty();

  constructor(
      private PermissionsService: PermissionsService,
      private UsersService: UsersService,
      private JBMToastsService: JBMToastsService,
      private TranslateService: TranslateService
  ) { }

  @Input() user_id: number=0;

  ngOnInit(): void {
    this.PermissionsService.get().subscribe(
        (data)=>this.permissions=data.data,
        (error)=>console.error(error),
        ()=> {
          this.UsersService.getOne(this.user_id).subscribe(
            (data)=>this.user=data.data,
            (error)=>console.error(error))
        })
  }
  isChecked(name, action) {
    return this.user.permissions.indexOf(action + ':' + name)>-1;
  }
  togglePermission(event) {
    let parts=event.target.id.split('-');
    let entity=parts[1];
    let action=parts[2];

    let index=this.user.permissions.indexOf(action + ':' + entity);
    if(index>-1)
      this.user.permissions.splice(index,1);
    else
      this.user.permissions.push(action + ':' + entity);

    this.UsersService.update(this.user).subscribe(
      ()=>this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved')),
      (error)=>console.error(error)
    )
  }
}
