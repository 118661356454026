import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ArticlePricelistsService} from "../../data/article-pricelists.service";
import {ResourcePricelistsService} from "../../data/resource-pricelists.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {ProjectsService} from "../../../projects/data/projects.service";
import {JBMTableColumnDef,JBMTableColumnType} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Subscription} from "rxjs";
import {ResourcePricelist} from "../../data/interfaces/resource-pricelist";

@Component({
  selector: 'resource-pricelist-assign-projects',
  templateUrl: './resource-pricelist-assign-projects.component.html',
  styleUrls: ['./resource-pricelist-assign-projects.component.scss']
})
export class ResourcePricelistAssignProjectsComponent implements OnInit {
  items: any[]=[];
  types=this.ArticlePricelistsService.getTypeItems(this.TranslateService);
  columnDefs: JBMTableColumnDef[];
  loading: boolean=false;
  language: string=this.TokenService.getLanguage();
  project_ids: number[]=[];
  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';

  constructor(
      private ArticlePricelistsService: ArticlePricelistsService,
      private ResourcePricelistsService: ResourcePricelistsService,
      private ProjectsService: ProjectsService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  )
  { this.pricelist=this.ResourcePricelistsService.getEmpty() }

  @Input() pricelist: ResourcePricelist;

  @Output() onAssigned=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  @ViewChild('toggleTemplate') toggleTemplate: TemplateRef<any>;

  ngOnInit(): void {
    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'toggle', type: JBMTableColumnType.boolean, iconClassname: 'check', sortable: false, filterable: false, width: 1 },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'), sortable: true, filterable: true }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=5;

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let httpParams=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.ProjectsService.getSelectList(this.language,2, this.pricelist.id, httpParams).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          this.loading=false;

          // Set toggle template
          this.columnDefs[1].template = this.toggleTemplate;

          if(countRows) {
            let httpParams=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.ProjectsService.getSelectList(this.language, 2, this.pricelist.id, httpParams).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        }
    );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    this.toggleProject(row.id);
  }
  toggleProject(id:number) {
    let index=this.project_ids.indexOf(id);
    if(index===-1)
      this.project_ids.push(id)
    else
      this.project_ids.splice(index,1);
  }
  assign() {
    this.ProjectsService.assignPricelists({ pricelistsType: 2, pricelist_id: this.pricelist.id,
      project_ids: this.project_ids }).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entities-saved'));
          this.onAssigned.emit();
        }
    )
  }
  cancel() {
    this.onCancel.emit();
  }
}
