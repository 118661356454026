import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router, ActivatedRoute} from "@angular/router";
import { InvoicesService} from "../data/invoices.service";
import { Invoice} from "../data/interfaces/invoice";
import { InvoiceValidationsService} from "../data/invoice-validations.service";
import { TokenService} from "../../../services/auth/token.service";
import { ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import { PdfService} from "../../../services/pdf-make/pdf-service";
import { HttpParams} from "@angular/common/http";
import {forkJoin} from "rxjs";
import {InvoiceValidation} from "../data/interfaces/invoice-validation";

@Component({
  selector: 'invoices-validation',
  templateUrl: './invoices-validation.component.html',
  styleUrls: ['./invoices-validation.component.scss'],
  providers: [PdfService]
})
export class InvoicesValidationComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  invoices: any;
  invoice_id: number=0;
  validations: InvoiceValidation[]=[];
  PDFTemplate: any=JSON.stringify({});

  constructor(
      private InvoicesService: InvoicesService,
      private InvoiceValidationsService:InvoiceValidationsService,
      private PdfService: PdfService,
      private TokenService: TokenService,
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private ModalService: ModalService
  ) { }

  @ViewChild('modalValidations') modalValidations: ElementRef;

  ngOnInit(): void {
    if(this.ActivatedRoute.snapshot.paramMap.has('id')) {
      this.invoice_id=parseInt(this.ActivatedRoute.snapshot.paramMap.get('id'));
      if(isNaN(this.invoice_id)) this.close();
    }

    this.getData();
  }
  getData() {
    let params=new HttpParams()
        .append('filter','invoice_states_id eq 150')
        .append('customer','1')
        .append('project','1')
        .append('validations','1');
    this.InvoicesService.get(this.language, params).subscribe(
        (data)=>this.invoices=data.data,
        (error)=>console.error(error),
        ()=> {
          // Set per invoice global validation status
          for(let invoice of this.invoices)
            if(this.toApproveByUsers(invoice.validations))
              invoice.approved=null;
            else
              invoice.approved=this.approvedByAllUsers(invoice.validations);

          setTimeout(()=>this.ModalService.open(this.modalValidations,'validations'),200);
        });
  }
  approvedByAllUsers(validations) {
    let result=true;
    for(let validation of validations)
      if(validation.approved===false)
        result=false;
    return result;
  }
  toApproveByUsers(validations) {
    let result=false;
    for(let validation of validations)
      if(validation.approved===null)
        result=true;
    return result;
  }

  refresh() {
    this.invoice_id=0;
    this.getData();
  }

  setInvoice(invoice_id: number) {
    this.PDFTemplate=JSON.stringify({});

    this.invoice_id=invoice_id;
    this.InvoicesService.compose_pdf(this.language, this.invoice_id).subscribe(
        (data)=>this.PDFTemplate=data.data,
        (error)=>console.error(error),
        ()=>
            this.PdfService.loadPdfMaker().then( ()=>
                this.PdfService.pdfMake.createPdf(this.PDFTemplate).getDataUrl(function(outDoc) {
                  (document.getElementById('pdfObject') as HTMLObjectElement).data=outDoc;
                })
            )
    )
  }
  cancelValidation() {
    this.invoice_id=0;
  }
  close() {
    this.Router.navigate([{ outlets: { 'modal': null }} ] );
  }
}
