import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import {ConcretePrice} from "./interfaces/concrete-price";

const baseURL: string = 'financial/';
const slug: string = 'concrete-prices/';

@Injectable({
  providedIn: 'root'
})
export class ConcretePricesService {
  concretePrice: ConcretePrice;

  constructor(private HttpService: HttpService) { }

  get(language, concrete_pricelists_id, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+language+'/'+concrete_pricelists_id, params );
  }
  getGridData(language, concrete_pricelists_id) {
    return this.HttpService.get(baseURL+'read/'+slug+'grid/'+language+'/'+concrete_pricelists_id);
  }
  getOne(id, language) {
    let params=new HttpParams().set('id',id.toString());
    return this.get(language,0, params);
  }
  getHistory(language: string, id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+'history/'+language+'/'+id.toString(), params );
  }
  getEmpty() {
    this.concretePrice = {
      id: 0,
      concrete_pricelists_id: 0,
      strengths_id: 0,
      environments_id: 0,
      consistencies_id: 0,
      price: 0.0
    }
    return this.concretePrice;
  }
  calculateBasePrice(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/calculate-concrete-base-price', params );
  }
  calculatePrice(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/calculate-concrete-price', params );
  }
  getDeletePermissions(ids: string[]) {
    return this.HttpService.get(baseURL+'read/concrete-price-delete-permissions/'+ids.join());
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+slug+id);
  }
}
