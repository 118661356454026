<div *ngFor="let concrete of concretes">
   <table class="table table-sm invoice-concretes">
      <tr>
         <th class="description">{{'project.order'|translate}}</th>
         <th class="worktype">{{'order.worktype'|translate}}</th>
         <th class="ordered align-right pr-3">{{'order.ordered'|translate}}</th>
         <th class="delivered align-right">{{'order.delivered'|translate}}</th>
      </tr>
      <tr>
         <td>{{concrete.order.description}}</td>
         <td>{{concrete.order.worktype.description}}</td>
         <td class="align-right pr-3">{{concrete.order.amount}} {{'quantity.m3'|translate}}</td>
         <td class="align-right">{{concrete.order.delivered}} {{'quantity.m3'|translate}}</td>
      </tr>
      <tr>
         <td colspan="4">
            <jbm-comments-box [comments]="concrete.order.comments" height="2.75" [hideIfEmpty]="false"></jbm-comments-box>
         </td>
      </tr>
      <tr>
         <td colspan="4">
            <jbm-comments-box [comments]="concrete.order.recipe_comments" height="2.75" [hideIfEmpty]="false"></jbm-comments-box>
         </td>
      </tr>
   </table>
</div>