<form [formGroup]="form" (ngSubmit)="save()" class="copy-article-pricelist">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'entity.resource'|translate}} {{'pricelist.copy'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-properties-list className="article-pricelist" [properties]="priceListProps"></jbm-properties-list>
      <hr />
      <customer-selector labelCaption="{{'customer'|translate}}" class="lg mt-3" labelClass="width-7"
         [id]="'customer-selector'"
         [customer_id]="formControls.customer_id.value"
         [template]="customerselect"
         [filter]="0"
         (customerSelected)="setCustomer($event)">
      </customer-selector>
      <ng-template #customerselect let-customer="customer">
         <div *ngIf="customer">
            {{customer.name}}<br />
            {{customer.address_details.street}}&nbsp;{{customer.address_details.no}}<br />
            {{customer.address_details.postcode}}&nbsp;&nbsp;{{customer.address_details.city}}<br />
            {{'entity.blocked'|translate}}: <span *ngIf="customer.blocked" class="badge bg-danger">{{'ui.yes'|translate}}</span>
            <span *ngIf="!customer.blocked" class="badge bg-success">{{'ui.no'|translate}}</span>
         </div>
      </ng-template>
      <div *ngIf="!selectList">
          <jbm-text-input [formControl]="formControls.description" [submitted]="isSubmitted" [id]="'description'"
             required="true" labelCaption="{{'entity.description'|translate}}" labelClass="width-7" class="input-25">
              <jbm-input-error [formControl]="formControls.description" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
              </jbm-input-error>
          </jbm-text-input>
      </div>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button subject="{{'entity.resource'|translate}} {{'financial.pricelist'|translate|lowercase}}" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
