import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {InvoicesService} from "../../data/invoices.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Invoice} from "../../data/interfaces/invoice";
import {DatetimeService} from "../../../../services/datetime/datetime.service";

@Component({
  selector: 'invoice-copy',
  templateUrl: './invoice-copy.component.html',
  styleUrls: ['./invoice-copy.component.scss']
})
export class InvoiceCopyComponent implements OnInit {
  form: FormGroup;
  submitted: boolean=false;

  constructor(
      private JBMToastsService: JBMToastsService,
      private formBuilder: FormBuilder,
      private InvoicesService: InvoicesService,
      private DatetimeService: DatetimeService
  ) {
    this.addControls();
    this.setControlValues();
  }

  @Input() invoice_id: number;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  ngOnInit(): void {}
  addControls() {
    this.form = this.formBuilder.group(
        {
          invoice_date: new FormControl(0,[this.DatetimeService.dateValidator(this.DatetimeService,true)]),
        }
    );
  }
  setControlValues() {
    this.form.controls.invoice_date.setValue(this.DatetimeService.getCurrentDateDMY());
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let invoice_id=this.invoice_id;
    let invoice_date=this.DatetimeService.dateDMYToYMD(this.form.controls.invoice_date.value);
    this.InvoicesService.copy(invoice_id, invoice_date).subscribe(
        (data)=>invoice_id=data.data,
        (error)=>console.error(error),
        ()=>this.onSuccess.emit({ invoice_id: invoice_id } )
    )
  }
  close() {
    this.onClose.emit();
  }
}
