import {Component, TemplateRef} from '@angular/core';
import {JBMToastsService} from "./JBMToasts.service";

@Component({
  selector: 'jbm-toasts',
  templateUrl: './JBMToasts.component.html',
  host: {'[class.ngb-toasts]': 'true'},
  styleUrls: ['./JBMToasts.component.scss']
})
export class JBMToastsComponent {
  constructor(public JBMToastsService: JBMToastsService) {}
  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
