import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { PDFTemplate } from "./interfaces/pdf_template";

const baseURL: string = 'presentations/';
const segment: string = 'pdf-templates/';

@Injectable({
  providedIn: 'root'
})
export class PDFTemplatesService {
  pdf_template: PDFTemplate;

  constructor(
      private HttpService: HttpService,
  ) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language, params );
  }
  getOne(language: string, id :number) {
    return this.HttpService.get(baseURL+'read/one/'+segment+language+'/'+id.toString(),null );
  }
  getEmpty() {
    this.pdf_template = {
      id: 0,
      presentations_id: 0,
      language: '',
      template: {},
      group_template: {},
      row_template: {}
    }
    return this.pdf_template;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+id.toString());
  }
}
