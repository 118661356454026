import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import { InvoicesService} from "../data/invoices.service";
import { CustomerService} from "../../customers/data/customer.service";
import { ProjectsService} from "../../projects/data/projects.service";
import { SanitizationService} from "../../../services/sanitization/sanitization.service";
import { JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import { TranslateService} from "../../../services/multilingual/translate.service";
import { ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import { TokenService} from "../../../services/auth/token.service";
import { FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import { Invoice} from "../data/interfaces/invoice";

@Component({
  selector: 'project-invoice-form',
  templateUrl: './project-invoice-form.component.html',
  styleUrls: ['./project-invoice-form.component.scss']
})
export class ProjectInvoiceFormComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  source: number=0;
  invoice: Invoice;
  id: number=0;
  recipe_id: number=0;
  order_id: number=0;
  environment: string='';
  form: FormGroup;
  submitted: boolean=false;
  customer_name: string='';
  orders: any[]=[];
  order_ids: number[]=[];

  @ViewChild('modalInvoice') modalInvoice: ElementRef;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;
  @ViewChild('modalOrder') modalOrder: ElementRef;

  constructor(
      private InvoicesService: InvoicesService,
      private CustomerService: CustomerService,
      private ProjectsService: ProjectsService,
      private FormBuilder: FormBuilder,
      private Router: Router,
      private SanitizationService: SanitizationService,
      private JBMToastsService: JBMToastsService,
      private TranslateService: TranslateService,
      private ModalService: ModalService,
      private TokenService: TokenService
  ) {
    this.addControls();
  }
  ngOnInit(): void {
    this.invoice=this.InvoicesService.getEmpty();
    setTimeout(()=>this.ModalService.open(this.modalInvoice,'project-invoice'),100);
  }
  addControls() {
    this.form = this.FormBuilder.group({
      customer_id: new FormControl(0),
      project_id: new FormControl(0,[Validators.required]),
      comments: new FormControl(''),
      openInvoice: new FormControl(true)
    },{ updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setProject(project) {
    this.formControls.project_id.setValue(project.id);

    if(project.id===0) {
      // Project deselected; reset orders and customer
      this.orders=[];
      this.order_ids=[];
      this.form.controls.customer_id.setValue(0);
      return;
    }

    this.form.controls.customer_id.setValue(project.customer_id);
    this.CustomerService.get(project.customer_id).subscribe(
        (data)=>this.customer_name=data.data.name,
        (error)=>console.error(error),
        ()=> this.InvoicesService.getInvoiceableOrders(this.language, project.id).subscribe(
            (data)=>this.orders=data.data,
            (error)=>console.error(error),
            ()=> {
              for(let order of this.orders)
                order.amount=order.amount.toString() + ' ' + this.TranslateService.GetTranslation('quantity.m3');
            }
        )
    )
  }
  toggleOrders() {
    let toggledOrders=this.order_ids.length;
    this.order_ids = [];
    // Reset ?
    if(toggledOrders===this.orders.length)
      return;
    // Select all
    for(let order of this.orders)
      this.order_ids.push(order.id);
  }
  toggleOrder(id: number) {
    let order_index=this.order_ids.indexOf(id);
    if(order_index===-1)
      this.order_ids.push(id);
    else
      this.order_ids=this.order_ids.splice(order_index,1);
  }
  showRecipe(order) {
    this.recipe_id=order.recipe.id;
    this.environment='';
    if(order.concrete_specs.hasOwnProperty('environment'))
      this.environment=order.concrete_specs.environment.description;

    this.ModalService.open(this.modalRecipe,'recipe');
  }
  closeRecipe() {
    this.ModalService.close('recipe');
  }
  // Order
  showOrder(order_id: number) {
    this.order_id=order_id;
    this.ModalService.open(this.modalOrder,'order')
  }
  closeOrder() {
    this.ModalService.close('order');
  }
  save() {
    this.submitted = true;

    let data = {
      customer_id: this.form.controls.customer_id.value,
      project_id: this.SanitizationService.nullIfZero(this.form.controls.project_id.value),
      comments: this.form.controls.comments.value,
    }

    if(this.order_ids.length) {
      data['order_ids']=this.order_ids.join();
      this.createFromOrders(data);
    } else
      this.create(data);
  }
  createFromOrders(data: any) {
    this.InvoicesService.createFromOrders(this.language, data).subscribe(
        (data)=>this.id=data.data,(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
          if(this.formControls.openInvoice.value)
            this.Router.navigateByUrl('invoicing/'+this.id)
          else
            this.Router.navigate([{ outlets: { 'modal': null }} ] );
        });
  }
  create(data: any) {
    this.InvoicesService.create(data).subscribe(
        data =>this.id=data.data,(error) =>console.error(error),() => {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
          if(this.formControls.openInvoice.value)
            this.Router.navigateByUrl('invoicing/'+this.id)
          else
            this.Router.navigate([{ outlets: { 'modal': null }} ] );
        }
    );
  }
  close() {
    this.Router.navigate([{ outlets: { 'modal': null }} ] );
  }
}
