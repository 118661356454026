import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../http.service";

const baseURL: string = 'settings/';
const segment: string = 'mail-settings-uses/';

export enum subjects { company, quotations, quotation, confirmations, confirmation, invoices, invoice}

@Injectable({
  providedIn: 'root'
})
export class MailSettingsUsesService {

  constructor(
      private HttpService: HttpService,
  ) {}
  get(subject: subjects, subject_id: number, presentation_type: number) {
    let params=new HttpParams()
      .append('subject',subject.toString())
      .append('subject_id', subject_id.toString())
      .append('presentation_type', presentation_type.toString());
    return this.HttpService.get(baseURL+'read/'+segment, params);
  }
  save(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
}
