import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../../shared/shared.module";
import { ArticlePriceHistoryTableComponent} from "./ArticlePriceHistoryTable/ArticlePriceHistoryTable.component";

@NgModule({
  declarations: [ArticlePriceHistoryTableComponent],
  imports: [CommonModule, SharedModule],
  exports: [ArticlePriceHistoryTableComponent]
})
export class DomainTablesModule { }
