<div class="planning-order-details">
   <div *ngIf="showAsModal" class="planning-task clearfix">
      <section class="date-time">
         <section class="date">
            <label class="top">{{'order.production-date'|translate}}</label>
            <label class="value">{{task.startDate|date:'EEEE d MMM y':'':'nl'|titlecase}}</label>
         </section>
         <section class="times">
            <div class="time">
               <label class="top">{{'order.starttime'|translate}}</label>
               <label class="value">{{task.order.starttime}}</label>
            </div>
            <div class="time">
               <label class="top">{{'order.loadtime'|translate}}</label>
               <label class="value">{{task.order.loadtime}}</label>
            </div>
            <div class="time">
               <label class="top">{{'order.endtime'|translate}}</label>
               <label class="value">{{task.order.endtime}}</label>
            </div>
         </section>
      </section>
      <section class="projectstate">
         <div class="label">
            <label class="top">{{'project.state'|translate}}</label>
            <label class="value">{{task.order.project.projectState.description}}</label>
         </div>
         <div *ngIf="task.completedPercentage>0" class="progressbar1">
            <div class="task" style="left:0;width:100%;background-color:{{task.backgroundColor}};color:#fff"
              ngbTooltip="{{task.order.project.projectState.description}}">
               <div class="task-progress bg-success" style="width:{{task.completedPercentage}}%">
                  {{task.order.delivered}}
               </div>
               <div *ngIf="task.stagingPercentage>0" class="task-progress" style="background-color: darkorange;width:{{task.stagingPercentage}}%">
                  {{task.stagingAmount}}
               </div>
            </div>
         </div>
      </section>
      <section class="amounts">
         <div class="amount">
            <label class="top">{{'order.amount'|translate}}</label>
            <label class="value">{{task.order.amount}} {{'quantity.m3'|translate}}</label>
         </div>
         <div class="flow">
            <label *ngIf="task.order.unloading_flow" class="top">{{'order.unloading-flow'|translate}}</label>
            <label *ngIf="task.order.unloading_flow" class="value">{{task.order.unloading_flow}} {{'quantity.m3'|translate}}</label>
            <label *ngIf="!task.order.unloading_flow" class="top">{{'order.unloading-speed'|translate}}</label>
            <label *ngIf="!task.order.unloading_flow" class="value">{{task.order.unloading_method.speed}} {{'quantity.m3'|translate}}</label>
         </div>
         <div class="delivered">
            <label class="top">{{'order.delivered'|translate}}</label>
            <label *ngIf="task.order.delivered>0" class="value">{{task.order.delivered}} {{'quantity.m3'|translate}}</label>
            <label *ngIf="!task.order.delivered" class="value">-</label>
         </div>
      </section>
   </div>
   <div class="header clearfix">
      <section class="projectname">
         <label class="top">{{'entity.project'|translate}}</label>
         <label class="value">
            <jbm-link-button [caption]="task.order.project.name" [tooltip]="'ui.click-item-open'|translate" [iconClass]="''" (onClick)="openProject(task.order.project.id)"></jbm-link-button></label>
      </section>
      <section class="order-description">
         <label class="top">{{'project.order'|translate}}</label>
         <label class="value">{{task.order.description}}</label>
      </section>
      <section class="worktype">
         <label class="top">{{'order.worktype'|translate}}</label>
         <label class="value">{{task.order.worktype.description}}</label>
      </section>
   </div>
   <div *ngIf="!compactView" class="body clearfix">
      <section class="customer has-button">
         <label class="top">{{'customer'|translate}}</label>
         {{task.order.project.customer.name}}<br />
         <i *ngIf="task.order.project.customer.phone" class="fa fa-mobile"></i> {{task.order.project.customer.phone}}<br />
         <jbm-address-dropdown
           [caption]="'entity.address'|translate"
           [extraSmall]="false"
           [btnContext]="'outline-secondary'"
           [address_details_id]="task.order.project.customer.address_id"
           (onOpenChange)="popupOpenChange($event)">
         </jbm-address-dropdown>
      </section>
      <section class="work-location has-button">
         <div *ngIf="task.order.project.address">
            <label class="top">{{'project.work-location'|translate}}</label>
            {{task.order.project.address.street}}&nbsp;{{task.order.project.address.no}}<br />{{task.order.project.address.city}}
            <br />
            <jbm-gps-location-button
              [title]="'project.work-location'|translate"
              [btnContext]="'outline-secondary'"
              [address]="task.order.project.address.street+'&nbsp;'+task.order.project.address.no+', '+task.order.project.address.city"
              [latitude]="task.order.project.address.lat" [longitude]="task.order.project.address.lon"
              [ngbTooltip]="('project.work-location'|translate)+' '+('geo.map'|translate|lowercase)"
              [extraSmall]="false">
            </jbm-gps-location-button>
         </div>
         <div *ngIf="!task.order.project.address">&nbsp;</div>
      </section>
      <section class="recipe has-button">
         <label class="top">{{'entity.recipe'|translate}}</label>
         <span *ngIf="task.order.recipe" class="value">{{task.order.recipe.code}}</span>
         <span *ngIf="!task.order.recipe" class="value">{{'order.recipe-unknown'|translate}}</span>
         <br />
         {{task.order.unloading_method.description}}&nbsp;({{task.order.unloading_method.speed}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}})
         <div class="buttons">
            <div ngbDropdown container="body" (openChange)="popupOpenChange($event)">
               <button type="button" class="btn btn-outline-secondary btn-sm" id="recipe-specs" ngbDropdownToggle>
                  {{'financial.concrete-specs'|translate}}
               </button>
               <div ngbDropdownMenu [attr.aria-labelledby]="'recipe-specs'" class="table-cell">
                  <table class="table table-xs table-borderless">
                     <tr><td>{{'concrete.strength-short'|translate}}:</td><td class="value">{{task.order.concrete_specs.strength.description}}</td></tr>
                     <tr><td>{{'concrete.consistency-short'|translate}}:</td><td class="value">{{task.order.concrete_specs.consistency.description}}</td></tr>
                     <tr><td>{{'concrete.environment-short'|translate}}:</td><td class="value">{{task.order.concrete_specs.environment.description}}</td></tr>
                  </table>
               </div>
            </div>
            <div ngbDropdown container="body" (openChange)="popupOpenChange($event)">
               <button type="button" [disabled]="!task.order.recipe_comments" class="btn btn-outline-secondary btn-sm" id="recipe-comments" ngbDropdownToggle>
                  <i class="fa fa-comments"></i>
               </button>
               <div ngbDropdownMenu [attr.aria-labelledby]="'recipe-comments'" class="table-cell comments">
                  {{task.order.recipe_comments}}
               </div>
            </div>
         </div>
      </section>
   </div>
   <div class="footer" [ngClass]="{ compact: compactView }">
      <label class="value">{{task.order.project.plant.name}}</label>
      <div class="task-actions float-end mr-0">
         <jbm-confirm-delete-dropdown *ngIf="userRights.AllowDelete && task.order.production_date" [class]="'float-end ml-3'" [subject]="'planning.remove'|translate" [placement]="'left'"
           (onConfirm)="confirmDelete(task.order.id)" (onOpenChange)="popupOpenChange($event)">
         </jbm-confirm-delete-dropdown>
         <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-secondary btn-sm float-end ml-2" (click)="showInstantReceipt(task)">
            <i class="fa fa-industry"></i> {{'production.push-through'|translate}} {{'production.for-production'|translate|lowercase}}...
         </button>
         <jbm-comments-dropdown [caption]="'entity.comments'|translate" [comments]="task.order.comments" [btnContext]="'outline-secondary'" [class]="'float-end'"></jbm-comments-dropdown>
      </div>
   </div>
</div>
