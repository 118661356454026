import {Component, Input, OnInit} from '@angular/core';
import {ArticlePricesService} from "../../../../features/financial/data/article-prices.service";
import {ConcretePricesService} from "../../../../features/financial/data/concrete-prices.service";
import {ResourcePricesService} from "../../../../features/financial/data/resource-prices.service";
import {TokenService} from "../../../../services/auth/token.service";

@Component({
  selector: 'article-price-history-dropdown',
  templateUrl: './ArticlePriceHistoryDropdown.component.html',
  styleUrls: ['./ArticlePriceHistoryDropdown.component.scss']
})
export class ArticlePriceHistoryDropdownComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  data: any;
  dataLoaded: boolean=false;

  constructor(
      private ArticlePricesService: ArticlePricesService,
      private ConcretePricesService: ConcretePricesService,
      private ResourcePricesService: ResourcePricesService,
      private TokenService: TokenService
  ) { }

  @Input() subject: number=0; // 0=Article, 1=Concrete, 2=Resource
  @Input() price_id: number=0;
  @Input() placement: string='bottom';

  ngOnInit(): void {
  }
  toggle() {
    if(this.dataLoaded) return;
    if(this.subject===0) // Article
        this.ArticlePricesService.getHistory(this.language, this.price_id).subscribe(
            (data)=>this.data=data.data,
            (error)=>console.log(error),
            ()=>this.dataLoaded=true
        )
    if(this.subject===1) // Concrete
      this.ConcretePricesService.getHistory(this.language, this.price_id).subscribe(
          (data)=>this.data=data.data,
          (error)=>console.log(error),
          ()=>this.dataLoaded=true
      )
    if(this.subject===2) // Resource
      this.ResourcePricesService.getHistory(this.language, this.price_id).subscribe(
          (data)=>this.data=data.data,
          (error)=>console.log(error),
          ()=>this.dataLoaded=true
      )
  }
}
