import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef, ViewChild
} from '@angular/core';
import {ProjectsService} from "../../../../features/projects/data/projects.service";
import {ProjectstatesService} from "../../../../features/projects/data/projectstates.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnDef, JBMTableColumnType} from "../../../JBM/JBMTable/JBMTable.component";
import {FilterOperator, QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {Project} from "../../../../features/projects/data/interfaces/project";
import {ProjectState} from "../../../../features/projects/data/interfaces/projectstate";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'project-selector',
  templateUrl: './project.selector.component.html',
  styleUrls: ['./project.selector.component.scss']
})
export class ProjectSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  project: Project;
  projectstates: any[]=[];
  projectstate: ProjectState={ id: 0, description: '', color: '', text_color: '' };
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;
  @Input() isSubmitted: boolean=false;
  @Input() with_customers_only: boolean=false;
  @Input() customer_id: number=0;
  @Input() project_states_id: number=0;
  @Input() project_states_ids: string='';

  @Input() project_id: number=0;
  @Output() projectSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Input() template: TemplateRef<any>;

  @ViewChild('templateProjectstate') templateProjectstate: TemplateRef<any>;

  constructor(
      private ProjectsService: ProjectsService,
      private ProjectstatesService: ProjectstatesService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.name'), sortable: true, sorted: true },
      { name: 'projectstates_id', header: this.TranslateService.GetTranslation('project.state'), visible: true, width: 6,
        hideable: false, sortable: true, filterable: false, className: 'opacity-7' },
      { name: 'customername', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.customer') }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('name','p.name');
    this.queryHttpHelper.addAliasedColumn('customername','c.name');
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('customername', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;

    this.ProjectstatesService.get(this.language).subscribe(
        (data: any)=> this.projectstates=data.data,(error)=>console.error(error) )
  }
  ngOnChanges() {
    if(this.project_id) this.setProject();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('project-search').focus() },100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.project_id=0;
    this.hideList();
    this.projectSelected.emit( { id: 0 } );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.getParams();
    this.items$=this.ProjectsService.get(this.language, params).subscribe(
      (data: any) => this.items=data.data,()=>{},()=>{

        this.columnDefs[2].template=this.templateProjectstate;
        this.formatData();

        if(countRows) {
          let params=this.getParams(true);
          this.count$=this.ProjectsService.get(this.language, params).subscribe(
           (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
        }
      } );
  }
  formatData() {
    // Set projectstate context colors
    for(let item of this.items) {
      item.color='';
      item.text_color='';
      let projectState=this.ProjectstatesService.getByID(this.projectstates, item.projectstates_id);
      if(projectState) {
        item.color=projectState.color;
        item.text_color=projectState.text_color;
      }
    }
  }
  getParams(rowCount=false) {
    let params=this.queryHttpHelper.getHttpParams(rowCount);
    // Include projectstate description
    params=params.append('state','1');
    if(this.customer_id)
      params=params.append('customer_id', this.customer_id.toString());
    if(this.with_customers_only)
      params=params.append('customer_not_null','1');
    if(this.project_states_id)
      params=params.append('projectstates_id', this.project_states_id.toString());
    else
      if(this.project_states_ids)
        params=params.append('projectstates', this.project_states_ids);
    return params;
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
/*
    if(this.queryHttpHelper.getFilterValue('name') !== value) {
      this.queryHttpHelper.setFilterItem('name',value);
      this.getData(true);
    }
    if(this.queryHttpHelper.getFilterValue('customername') !== value) {
      this.queryHttpHelper.setFilterItem('customername',value);
      this.getData(true);
    }*/
  }
  rowSelect(row) {
    // Emit event
    this.project_id=row.id;
    this.setProject();
    this.hideList();
    this.projectSelected.emit( row );
  }
  setProject() {
    let params=new HttpParams().append('state','1');
    this.ProjectsService.getOne(this.project_id,this.language, params).subscribe(
        (data: any)=>this.project=data.data as Project,
        (error)=>console.error(error)
    )
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
