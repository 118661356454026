import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormGroupBaseComponent} from "../formgroupbase.component";

export interface JBMSelectOption {
  key: string;
  value: string;
}

@Component({
  selector: 'jbm-select',
  templateUrl: './JBMSelect.component.html',
  styleUrls: ['./JBMSelect.component.scss']
})
export class JBMSelectComponent extends FormGroupBaseComponent implements OnInit, OnChanges {
  @Input() options: JBMSelectOption[]=[];
  @Input() insertLeadingOption: boolean=false;
  @Input() leadingOptionValue: string='';
  @Input() leadingOptionText: string='';
  @Output() onBlur = new EventEmitter();
  @Output() onChange = new EventEmitter();

  ngOnInit(): void {
    if(this.options==undefined) this.options=[];

    if(this.leadingOptionValue==undefined) this.leadingOptionValue='';
    if(this.leadingOptionText==undefined) this.leadingOptionText='';
    this.checkLeadingOption();
  }
  emptyOptionExists() {
    for(let o=0; o<this.options.length; o++ )
      if(this.options[o].key==='') return true;
    return false;
  }
  ngOnChanges() {
    this.checkLeadingOption();
  }
  checkLeadingOption() {
    if(this.insertLeadingOption && !this.emptyOptionExists()) {
      let empty={ key: this.leadingOptionValue, value: this.leadingOptionText };
      if(this.options.length)
        this.options.unshift(empty);
      else
        this.options.push(empty);
    }
  }
  getSelected() {
    for(let o=0; o<this.options.length; o++ )
      if(this.options[o].key==this.ngControl.control.value)
        return this.options[o].value;

    if(this.editable && this.insertLeadingOption && this.leadingOptionValue && this.leadingOptionText)
      return this.leadingOptionText;

    return '';
  }
  blur(event) {
    this.onBlur.emit(event);
  }
  change(event) {
    this.onChange.emit(event);
  }
}
