import {ElementRef, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modals: any[] = [];

  add(modal: any) {
    // Add modal to array of active modals
    this.modals.push(modal);
  }
  remove(id: string) {
    // Remove modal from array of active modals
    this.modals = this.modals.filter(x => x.id !== id);
  }
  open(templateRef: ElementRef, id: string) {
    // Open modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal.template=templateRef;
    modal.open();
  }
  close(id: string) {
    // Close modal specified by id
    const modal = this.modals.find(x => x.id === id);
    modal.template=null;
    modal.close();
  }
  count(): number {
    return this.modals.length;
  }
}
