import {NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import {ReportsRoutingModule } from './reports-routing.module';
import {ReportsComponent } from './reports.component';
import {ProductionReportComponent } from './production/production-report/production-report.component';
import {JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import {ResourceUsageReportComponent} from "./production/resource-usage-report/resource-usage-report.component";
import {SelectorsModule} from "../../components/domain/selectors/selectors.module";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [ ReportsComponent, ProductionReportComponent, ResourceUsageReportComponent ],
    imports: [CommonModule, ReportsRoutingModule, SharedModule, JBMViewModule, JBMFormModule, SelectorsModule, LayoutModule]
})
export class ReportsModule { }
