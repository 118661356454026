import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {User} from "./interfaces/user";

const baseUrl='recipe-app/';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  user: User;

  constructor(private HttpService: HttpService) { }

  get(params: HttpParams=null) {
    return this.HttpService.get(baseUrl+'read/users', params);
  }
  getOne(userid: number=0) {
    return this.HttpService.get( baseUrl+'read/users/'+userid.toString());
  }
  getEmpty() {
    this.user = {
      id: 0,
      username: '',
      password: '',
      audience: '',
      permissions: []
    }
    return this.user;
  }
  create(data: any) {
    return this.HttpService.post(baseUrl+'create/users', data);
  }
  update(data: any) {
    return this.HttpService.post(baseUrl+'update/users', data);
  }
}
