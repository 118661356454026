<app-header [class]="'invoice'">
   <jbm-view-title [caption]="'entity.invoice'|translate"
     [subCaption]="invoice.invoice_number"
     [parentLinkCaption]="historyView ? ('entity.invoice'|translate)+('entity.history'|translate|lowercase) : ('entity.invoices'|translate)"
     [parentLinkSlug]="historyView ? 'invoicing-history' : 'invoicing'"></jbm-view-title>
   <div class="float-start toolbar">
      <span class="badge badge-xl badge-pill float-start" style="background-color: {{invoiceState.color}};color: {{invoiceState.text_color}}">
         {{invoiceState.description}}
      </span>
      <div class="toolbar-spacer-1"></div>
      <button class="btn btn-light float-start" [ngbTooltip]="('invoice.state'|translate)+' '+('entity.history'|translate|lowercase)"
        (click)="showStateFlow();$event.stopPropagation();">
         <i class="fa fa-history"></i>
      </button>
      <div class="toolbar-spacer-1"></div>
      <a class="btn btn-sm btn-link" target="_blank" [href]="'invoice-preview/'+invoice.id">
         PDF {{'ui.preview'|translate|lowercase}}<i class="fa fa-external-link-alt ml-2"></i>
      </a>
      <div class="toolbar-spacer-1"></div>
      <a *ngIf="!historyView" class="btn btn-sm btn-link" target="_blank" href="invoicing-history/customer/{{invoice.customers_id}}">
         <i class="fa fa-history"></i> {{'entity.invoice'|translate}}{{'entity.history'|translate|lowercase}}
         <i class="fa fa-external-link-alt ml-2"></i>
      </a>
   </div>
   <div class="float-end toolbar action-buttons">
      <div *ngIf="invoice.totalprice" class="totals">
         <label class="price float-start">{{'ui.totalprice'|translate}}:&nbsp;&nbsp;
            <span *ngIf="taxInclusive">{{invoice.totalprice_incl_vat|money}}</span>
            <span *ngIf="!taxInclusive">{{invoice.totalprice|money}}</span>
         </label>
         <div *ngIf="!invoice.shifted_vat" ngbDropdown class="float-start">
            <button type="button" class="btn btn-sm btn-light" id="inclexcl" ngbDropdownToggle>
               <span *ngIf="taxInclusive">{{'ui.inclusive'|translate}}</span>
               <span *ngIf="!taxInclusive">{{'ui.exclusive'|translate}}</span>
            </button>
            <div ngbDropdownMenu [attr.aria-labelledby]="'inclexcl'">
               <button *ngIf="taxInclusive" ngbDropdownItem (click)="toggleTaxInclusive()">{{'ui.exclusive'|translate}}</button>
               <button *ngIf="!taxInclusive" ngbDropdownItem (click)="toggleTaxInclusive()">{{'ui.inclusive'|translate}}</button>
            </div>
         </div>
         <label *ngIf="!invoice.shifted_vat" class="float-start">{{invoice.vat|money}} ({{invoice.vat_rate}}%) {{'financial.tax'|translate}}</label>
         <label *ngIf="invoice.shifted_vat" class="float-start">(0% {{'financial.tax'|translate}})</label>
      </div>
      <div class="toolbar-spacer-2"></div>
      <div *ngIf="!historyView" ngbDropdown>
         <button class="actions btn btn-light btn-block" id="invActions" ngbDropdownToggle (click)="$event.stopPropagation();">
            <i class="fa fa-ellipsis-h"></i>
         </button>
         <div ngbDropdownMenu aria-labelledby="invActions">
            <button *ngIf="userRights.AllowUpdate && invoice.allowValidate" ngbDropdownItem (click)="validate()">{{'ui.validate'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && invoice.allowApprove" ngbDropdownItem (click)="approve()">{{'entity.approve'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && invoice.allowConcept" ngbDropdownItem (click)="makeConcept()">{{'entity.make-concept'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && invoice.allowSend" ngbDropdownItem (click)="send()">{{'entity.send'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && invoice.allowPayment" ngbDropdownItem (click)="handlePayment()">{{'invoice.handle-payment'|translate}}...</button>
            <div *ngIf="userRights.AllowUpdate && invoice.allowValidate || userRights.AllowUpdate && invoice.allowApprove || userRights.AllowUpdate && invoice.allowConcept ||
              userRights.AllowUpdate && invoice.allowSend || userRights.AllowUpdate && invoice.allowPayment" class="dropdown-divider">
            </div>
            <button *ngIf="userRights.AllowUpdate && invoice.allowReject" ngbDropdownItem (click)="reject()">{{'entity.invoice'|translate}} {{'ui.cancel'|translate|lowercase}}...</button>
            <button *ngIf="userRights.AllowDelete && invoice.allowRemove" ngbDropdownItem (click)="remove()">{{'ui.delete'|translate}}...</button>
            <div *ngIf="userRights.AllowUpdate && invoice.allowReject || userRights.AllowUpdate && invoice.allowRemove" class="dropdown-divider"></div>
            <button *ngIf="userRights.AllowUpdate && invoice.allowCopy" ngbDropdownItem (click)="copy()">{{'ui.copy'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && invoice.allowSettings" ngbDropdownItem (click)="settings()">{{'entity.settings'|translate}}...</button>
            <button ngbDropdownItem (click)="showStateFlow()"><i class="fa fa-history"></i> {{'invoice.state'|translate}} {{'entity.history'|translate|lowercase}}</button>
         </div>
      </div>
      <div class="toolbar-spacer-2"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module invoice">
   <invoice-view-settings
     [invoice_usage]="invoice.usage"
     [settings]="viewSettings"
     [resources_based_invoicing]="invoice.resources_based_invoicing"
     [shifted_vat]="invoice.shifted_vat"
     (onChange)="updateSettings($event)"
     (onEditReceipts)="editReceipts()"
     (onEditOrders)="editOrders()">
   </invoice-view-settings>

   <div *ngIf="invoice.invoice_states_id>200" class="clearfix">
      <div class="jbm-properties-list header inline float-start">
         <div class="item">
            <label class="label">{{'invoice.date'|translate}}:</label>
            <label class="value">{{invoice.invoice_date}}</label>
         </div>
         <div class="item">
            <label class="label">{{'invoice.term-of-payment'|translate}}:</label>
            <label class="value">{{invoice.term_of_payment}} {{'datetime.days'|translate}}</label>
         </div>
         <div class="item">
            <label class="label">{{'entity.due-date'|translate}}:</label>
            <label class="value">{{invoice.due_date}}</label>
         </div>
      </div>
      <div class="jbm-properties-list header inline float-end">
         <div *ngIf="invoice.outstanding>0" class="item">
            <label class="label">{{'entity.outstanding'|translate}}:</label>
            <label class="value">{{invoice.outstanding|money}}</label>
         </div>
         <div *ngIf="invoice.paid>0" class="item">
            <label class="label">{{'entity.paid'|translate}}</label>
            <label class="value">{{invoice.paid|money}}</label>
         </div>
      </div>
   </div>
   <div class="pl-4 pr-4 pb-4">
      <invoice-details *ngIf="invoice.id"
        [editable]="editable"
        [invoice]="invoice"
        [expanded]="viewSettings.details"
        (projectSelected)="projectSelected()">
      </invoice-details>
      <invoice-items #invoiceItems [hidden]="!invoice.projects_id || invoice.resources_based_invoicing"
        [invoice]="invoice"
        [userRights]="userRights"
        [editable]="editable"
        [viewSettings]="viewSettings"
        (onUpdateTotalsNeeded)="refreshInvoice()">
      </invoice-items>
      <invoice-resources #invoiceResources [hidden]="!invoice.resources_based_invoicing"
        [invoice]="invoice"
        [userRights]="userRights"
        [editable]="editable"
        [viewSettings]="viewSettings"
        (onUpdateTotalsNeeded)="refreshInvoice()">
      </invoice-resources>
      <invoice-articles
        [invoice_id]="invoice.id"
        [invoice_item_id]="0"
        [userRights]="userRights"
        [projects_id]="invoice.projects_id"
        [customers_id]="invoice.customers_id"
        [editable]="editable"
        (onUpdateTotalsNeeded)="refreshInvoice()">
      </invoice-articles>
   </div>
</section>

<jbm-modal [id]="'statesFlow'" [className]="'statesFlow'"></jbm-modal>
<ng-template #modalStatesFlow let-modal>
   <invoice-states-flow [language]="language" [id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onClose)="statesFlowClose()"></invoice-states-flow>
</ng-template>

<jbm-modal [id]="'invoiceReceipts'" [className]="'invoice-receipts'"></jbm-modal>
<ng-template #modalReceipts let-modal>
   <invoice-receipts [invoice_id]="invoice.id" [project_id]="invoice.projects_id" [editable]="editable"
     (afterAdd)="addedReceipt()" (afterDelete)="deletedReceipt()" (onClose)="receiptsClose()">
   </invoice-receipts>
</ng-template>

<jbm-modal [id]="'invoiceOrders'" [className]="'invoice-concretes'"></jbm-modal>
<ng-template #modalOrders let-modal>
   <invoice-orders [invoice_id]="invoice.id" [project_id]="invoice.projects_id" [editable]="editable"
     (afterAdd)="addedOrder()" (afterDelete)="deletedOrder()" (onClose)="ordersClose()">
   </invoice-orders>
</ng-template>

<jbm-modal [id]="'stateAction'" [className]="'state-action'"></jbm-modal>
<ng-template #modalValidate let-modal>
   <invoice-validation [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="validateSuccess()" (onClose)="stateActionClose()"></invoice-validation>
</ng-template>
<ng-template #modalApprove let-modal>
   <invoice-approve [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="approveSuccess()" (onClose)="stateActionClose()"></invoice-approve>
</ng-template>
<ng-template #modalCopy let-modal>
   <invoice-copy [invoice_id]="invoice.id" (onSuccess)="copySuccess($event)" (onClose)="stateActionClose()"></invoice-copy>
</ng-template>
<ng-template #modalMakeConcept let-modal>
   <invoice-make-concept [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="makeConceptSuccess()" (onClose)="stateActionClose()"></invoice-make-concept>
</ng-template>
<jbm-modal [id]="'invoiceSend'" [className]="'content invoice-send'"></jbm-modal>
<ng-template #modalSend let-modal>
   <invoice-send [invoice]="invoice" (onSuccess)="sendSuccess()" (onClose)="sendClose()"></invoice-send>
</ng-template>
<ng-template #modalHandlePayment let-modal>
   <invoice-handle-payment [invoice_id]="invoice.id" (onSuccess)="paymentSuccess()" (onClose)="stateActionClose()"></invoice-handle-payment>
</ng-template>
<ng-template #modalReject let-modal>
   <invoice-reject [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="rejectSuccess()" (onClose)="stateActionClose()"></invoice-reject>
</ng-template>

<jbm-modal [id]="'invoiceSettings'" [className]="'invoice-settings'"></jbm-modal>
<ng-template #modalSettings let-modal>
   <invoice-settings [invoice_id]="invoice.id" (onSucces)="settingsSuccess()" (onClose)="settingsClose()"></invoice-settings>
</ng-template>
<ng-template #modalRemove let-modal>
   <invoice-remove [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onRemove)="removeSuccess()" (onClose)="stateActionClose()"></invoice-remove>
</ng-template>

<jbm-toasts></jbm-toasts>
