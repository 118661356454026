<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked}">
   <div class="input-container selector-container">
      <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}"
         [ngClass]="{'col-form-label': !labelStacked }" (click)="setFocus()">
         {{labelCaption}}
         <span *ngIf="required" class="asterix">*</span>
      </label>
      <div id="{{id}}" class="box form-control form-control-sm" [ngClass]="{'is-invalid': touched && required && recipe_id==0, 'is-valid': touched && required && recipe_id>0, 'readonly': !editable}" tabindex="0" (keydown.enter)="toggleList()" (blur)="blur()">
         <div class="selected" (click)="toggleList()">
            <label *ngIf="recipe_id">
               <ng-container *ngTemplateOutlet="template; context: { 'recipe': recipe, 'environment': environment }"></ng-container>
            </label>
         </div>
         <div *ngIf="editable" class="buttons">
            <button type="button" tabIndex="-1" class="btn btn-sm btn-link"
              placement="left-bottom" container="body" ngbTooltip="{{'ui.selector.select-tooltip'|translate}}"
              (click)="toggleList()"><i class="fa fa-ellipsis-v"></i>
            </button>
            <button *ngIf="recipe_id>0" type="button" tabIndex="-1" class="btn btn-sm btn-link"
              placement="left-bottom" container="body" ngbTooltip="{{'ui.selector.unselect-tooltip'|translate}}"
              (click)="clearSelection()"><i class="fa fa-times"></i>
            </button>
         </div>
         <div [hidden]="!listOpen" class="selector-list recipes" (click)="$event.stopPropagation()">
            <div class="title clearfix">
               <h5>{{'entity.recipe'|translate}} {{'ui.select'|translate|lowercase}}</h5>
               <div class="float-end">
                  <jbm-cross-close-button (onClick)="hideList()"></jbm-cross-close-button>
               </div>
            </div>
            <div class="toolbar clearfix">
               <div class="search float-start">
                  <jbm-searchbox [id]="'recipe-search'" [search]="search" [minlength]="2"
                    [size]="'xs'" (searchChange)="searchChange($event)">
                  </jbm-searchbox>
               </div>
               <div class="float-end">
                  <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
                    [size]="'sm'"
                    [pageSize]="queryHttpHelper.paginationProps.pageSize"
                    [page]="queryHttpHelper.paginationProps.page"
                    (pageClick)="pageChange($event)">
                  </jbm-pagination>
               </div>
            </div>
            <jbm-table #table
              [data]="items"
              [sortable]="true"
              [size]="'xs'"
              [sortColumn]="queryHttpHelper.sortProps.name"
              [sortDirection]="queryHttpHelper.sortProps.direction"
              [hoverable]="true"
              [rowSelectable]="true"
              [template]="templateEnvironments"
              [columnDefs]="columnDefs"
              [searchMinLength]="2"
              (sortChange)="sortChange($event)"
              (rowSelect)="rowSelect($event)">
            </jbm-table>
         </div>
      </div>
      <div class="invalid-feedback" [ngClass]="{'is-invalid': (isSubmitted || touched) && required && !recipe_id}">
         <label *ngIf="labelCaption" class="col-form-label {{labelClass}}"></label>
         <div class="error">{{'entity.requiredfield'|translate}}</div>
      </div>
   </div>
   <ng-content></ng-content>
</div>
<ng-template #templateEnvironments let-data="data">
   <label class="value float-start pt-2">{{'concrete.environment'|translate}} {{'ui.select'|translate|lowercase}}</label>
   <div *ngFor="let environment of data.environments" class="float-start ml-2">
      <button type="button" class="btn btn-light float-start mr-1" (click)="select(environment.id)">{{environment.description}}</button>
   </div>
</ng-template>
