<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked, 'readonly': !editable}">
    <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked, 'col-form-label-sm': sm}">
        {{labelCaption}}
        <span *ngIf="!editable">:</span>
        <span *ngIf="editable && required" class="asterix">*</span>
    </label>
    <div class="input-container {{inputClass}}" [ngClass]="{'data-value': !editable}">
        <label *ngIf="!editable" class="col-form-label">{{getSelected()}}</label>
        <select [hidden]="!editable" id="{{id}}" [formControl]="ngControl.control" class="form-control form-select"
          [attr.tabindex]="tabIndex!==null ? tabIndex : null"
          [ngClass]="{'is-invalid': editable && ngControl.invalid && (ngControl.touched || submitted), 'form-control-sm': sm }"
          (blur)="blur($event)" (change)="change($event)">
            <option *ngFor="let o of options" value="{{o.key}}">{{o.value}}</option>
        </select>
        <ng-content></ng-content>
    </div>
</div>
