<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.recipe'|translate}} {{'financial.articles'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   {{'entity.recipe'|translate}}: <span class="fw-bold">{{recipe.description}}</span>
   <table *ngIf="articles.length" class="table table-xs mt-4 recipe-articles">
      <tr>
         <th>{{'financial.article'|translate}}</th>
         <th class="amount">{{'order.amount'|translate}}</th>
         <th class="action"></th>
      </tr>
      <tr *ngFor="let article of articles">
         <td>{{article.description}}</td>
         <td><input float-only-input class="form-control form-control-sm align-right" [id]="'amount-'+article.id.toString()" [value]="article.amountStr" (blur)="blurAmount(article.id,$event)"></td>
         <td><button type="button" class="btn btn-sm btn-block btn-danger" tabindex="-1" (click)="delete(article.id)">{{'ui.delete'|translate}}</button></td>
      </tr>
   </table>
   <table [hidden]="article_options.length==0" class="table table-xs mt-3 recipe-articles">
      <tr>
         <th>{{'financial.article'|translate}}</th>
         <th class="amount">{{'order.amount'|translate}}</th>
         <th class="action"></th>
      </tr>
      <tr>
         <td style="width: 356px;">
            <select id="article" class="form-control form-control-sm form-select" (change)="changeArticle($event)">
               <option value=""></option>
               <option *ngFor="let option of article_options" [value]="option.key">{{option.value}}</option>
            </select>
         </td>
         <td style="width: 128px;">
            <input id="amount" float-only-input class="form-control form-control-sm align-right" (keyup)="keyupAmount($event)">
         </td>
         <td style="width: 92px;">
            <button [disabled]="article_id==0 || amount==0.0" type="button" class="btn btn-sm btn-block btn-primary" (click)="add()">{{'ui.insert'|translate}}</button>
         </td>
      </tr>
   </table>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-save-button [subject]="'financial.articles'|translate" (click)="save()"></jbm-save-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button (click)="close()"></jbm-cancel-button>
   </div>
</div>
