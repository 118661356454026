import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {CustomerService} from "../../../../features/customers/data/customer.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {Customer} from "../../../../features/customers/data/interfaces/customer";
import {JBMTableColumnDef, JBMTableColumnType} from "../../../JBM/JBMTable/JBMTable.component";
import {FilterOperator, QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";

export enum filter { none, onlyBlocked, excludeBlocked}

@Component({
  selector: 'customer-selector',
  templateUrl: './customer.selector.component.html',
  styleUrls: ['./customer.selector.component.scss']
})
export class CustomerSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  customer: Customer;
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;
  @Input() isSubmitted: boolean=false;
  @Input() filter: filter;

  @Input() customer_id: number=0;
  @Output() customerSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Input() template: TemplateRef<any>;

  constructor(
      private CustomerService: CustomerService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';
    if(this.filter==undefined) this.filter=filter.none;

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'), sortable: true, filterable: true, width: 24 },
      { name: 'address_id', type: JBMTableColumnType.address,
        header: this.TranslateService.GetTranslation('entity.address'), width: 4 },
      { name: 'blocked', type: JBMTableColumnType.boolean, visible: this.filter==filter.none,
        header: this.TranslateService.GetTranslation('entity.blocked'),
        displayValues: [
          { dataValue: false, displayValue: this.TranslateService.GetTranslation('ui.no') },
          { dataValue: true, displayValue: this.TranslateService.GetTranslation('ui.yes'), className: 'text-danger' },
        ], width: 5 },
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.customer_id) this.setCustomer();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('customer-search').focus() }, 100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.customer_id=0;
    this.hideList();
    this.customerSelected.emit( { id: 0 } );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    this.setFilter();
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.CustomerService.getData(params).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.CustomerService.getData(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  setFilter() {
    if(this.filter===filter.onlyBlocked)
      this.queryHttpHelper.setFilterItem('blocked',true, FilterOperator.eq,false);
    if(this.filter===filter.excludeBlocked)
      this.queryHttpHelper.setFilterItem('blocked',false, FilterOperator.eq,false);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getFilterValue('name') !== value) {
      this.queryHttpHelper.setFilterItem('name',value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    // Emit event
    this.customer_id=row.id;
    this.setCustomer();
    this.hideList();
    this.customerSelected.emit( row );
  }
  setCustomer() {
    this.CustomerService.get(this.customer_id,true).subscribe(
        (data: any)=>this.customer=data.data as Customer,
        (error)=>console.error(error)
    )
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
