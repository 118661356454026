import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { Contact } from "./interfaces/contact";

export enum roles { contact_person=1, site_manager}
export enum subjects { quotation, project}

const BaseURL: string = 'contacts/';

@Injectable({
    providedIn: 'root'
})
export class ContactsService {
    contact: Contact;
    constructor(private HttpService: HttpService) {}

    getOne(subject: subjects, subject_id: number, roles_id: roles) {
        let params=new HttpParams()
            .append('subject', subject.toString())
            .append('subject_id', subject_id.toString())
            .append('roles_id', roles_id)
        return this.HttpService.get(BaseURL+'read/one/', params );
    }
    getById(id: number) {
        return this.HttpService.get(BaseURL+'read/id/'+id.toString());
    }
    getEmpty() {
        this.contact= {
            id: 0,
            subject: subjects.project,
            subject_id: 0,
            roles_id: roles.contact_person,
            firstname: '',
            lastname: '',
            name: '',
            phone: '',
            email: ''
        }
        return this.contact;
    }
    update(data: any) {
        return this.HttpService.post(BaseURL+'update', data);
    }
}
