import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {JBMTableColumnType, JBMTableFilter} from "../JBMTable.component";

@Component({
  selector: 'jbm-table-column-filter',
  templateUrl: './columnfilter.component.html',
  styleUrls: ['./columnfilter.component.scss']
})
export class ColumnFilterComponent implements OnInit {
  hasFilterValues: boolean;

  @Input() name: string;
  @Input() filterable: boolean;
  @Input() columnType: JBMTableColumnType;
  @Input() filterValues: JBMTableFilter[];
  @Input() filterValue: string;
  @Input() searchMinLength: number=2;

  @Output() filterChange = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    this.hasFilterValues = this.filterValues!==undefined && this.filterValues.length>0;
    if(this.filterValue==undefined) this.filterValue='';
    if(this.searchMinLength==undefined) this.searchMinLength=2;
  }
  inputChange(event) {
    let value=event.target.value;
    value=value.trimLeft(); if(value.length<this.searchMinLength) value='';
    // Emit event
    this.filterChange.emit({ name: this.name, value: value } );
  }
  numberChange(event) {
    if(event.target.value.trim()==='')
      // Emit event
      this.filterChange.emit({ name: this.name, value: '' } );
  }
  numberSearch(event) {
    this.filterValue=event.target.value;
    let value=parseInt(this.filterValue);
    // Emit event
    this.filterChange.emit({ name: this.name, value: value } );
  }
  selectChange(event) {
    let value=event.target.value;

    // Prevent angular's check mark rendering !
    event.target.classList.remove('ng-valid');

    if(value==='') {
      // Emit event
      this.filterChange.emit( { name: this.name, value: null } );
      return false;
    }
    switch (this.columnType) {
      case JBMTableColumnType.number: {
        value=parseInt( value );
        break;
      }
      case JBMTableColumnType.boolean: {
        if(value==='1') value = true;
        if(value==='0') value = false;
        break;
      }
    }

    // Emit event
    this.filterChange.emit( { name: this.name, value: value } );
  }
}
