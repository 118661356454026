import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {JBMSelectOption} from '../JBMSelect/JBMSelect.component';

@Component({
  selector: 'jbm-multi-select',
  templateUrl: './JBMMultiSelect.component.html',
  styleUrls: ['./JBMMultiSelect.component.scss']
})
export class JBMMultiSelectComponent implements OnChanges {
  @Input() id: string='';
  @Input() options: JBMSelectOption[]=[];
  @Input() selection: number[]=[];
  @Input() removeableSelection: boolean=false;
  @Input() class: string='';
  @Input() editable: boolean=true;
  @Input() required: boolean=false;
  @Input() submitted: boolean=false;
  @Input() labelCaption: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() inputClass: string='';
  @Input() sm: boolean=false; // Condensed height

  @Output() onSelectionChange = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  open: boolean=false;
  touched: boolean=false;

  constructor(private eRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {
    if(this.selection===undefined)
      this.selection=[];
  }

  toggle() {
    this.open=!this.open;
  }
  hide() {
    this.open=false;
  }
  toggleOption(index: number) {
    let key=parseInt(this.options[index].key);
    let i=this.selection.indexOf(key);
    if(i===-1)
      this.selection.push(key);
    else
      this.selection.splice(i,1);
    this.onSelectionChange.emit(this.selection);
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getOptionValue(selectedIndex) {
    let index=this.options.findIndex(function (option) { return parseInt(option.key)===selectedIndex} );
    return this.options[index].value
  }
  getSelected(key) {
    return this.selection.indexOf(parseInt(key))>-1
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hide();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hide();
  }
}
