import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../../shared/shared.module";
import { JBMActivityLogButtonComponent} from "./JBMActivityLogButton/JBMActivityLogButton.component";
import { JBMCancelButtonComponent} from "./JBMCancelButton/JBMCancelButton.component";
import { JBMCloseButtonComponent} from "./JBMCloseButton/JBMCloseButton.component";
import { JBMCreateButtonComponent} from "./JBMCreateButton/JBMCreateButton.component";
import { JBMCrossCloseButtonComponent} from "./JBMCrossCloseButton/JBMCrossCloseButton.component";
import { JBMEditRoundButtonComponent } from "./JBMEditRoundButton/JBMEditRoundButton.component";
import { JBMGPSLocationButtonComponent} from "./JBMGPSLocationButton/JBMGPSLocationButton.component";
import { JBMLinkButtonComponent} from "./JBMLinkbutton/JBMLinkButton.component";
import { JBMHelpButtonComponent} from "./JBMHelpButton/JBMHelpButton.component";
import { JBMSaveButtonComponent} from "./JBMSaveButton/JBMSaveButton.component";
import { JBMToggleButtonComponent} from "./JBMToggleButton/JBMToggleButton.component";
import {JBMEditButtonComponent} from "./JBMEditButton/JBMEditButton.component";

@NgModule({
  declarations: [JBMActivityLogButtonComponent,JBMCancelButtonComponent, JBMCloseButtonComponent,
    JBMCreateButtonComponent, JBMCrossCloseButtonComponent, JBMEditButtonComponent, JBMEditRoundButtonComponent, JBMLinkButtonComponent,
    JBMGPSLocationButtonComponent, JBMHelpButtonComponent, JBMSaveButtonComponent, JBMToggleButtonComponent
  ],
	exports: [JBMActivityLogButtonComponent, JBMCancelButtonComponent, JBMCloseButtonComponent,
		JBMCreateButtonComponent, JBMCrossCloseButtonComponent, JBMEditRoundButtonComponent, JBMLinkButtonComponent,
		JBMGPSLocationButtonComponent, JBMHelpButtonComponent, JBMSaveButtonComponent, JBMToggleButtonComponent, JBMEditButtonComponent],
  imports: [CommonModule, SharedModule]
})
export class JBMButtonsModule { }
