<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked, 'readonly': !editable}">
    <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked, 'col-form-label-sm': sm}">
        {{labelCaption}}
        <span *ngIf="!editable">:</span>
        <span *ngIf="editable && required" class="asterix">*</span>
    </label>
    <div class="input-container {{inputClass}}">
        <textarea [attr.disabled]="!editable ? '' : null" id="{{id}}" [formControl]="ngControl.control" class="form-control"
          [ngClass]="{'is-invalid': editable && ngControl.invalid && (ngControl.touched || submitted), 'form-control-sm': sm }"
          [rows]="rows">
        </textarea>
        <ng-content></ng-content>
    </div>
</div>
