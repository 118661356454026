import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'jbm-comments-box',
  templateUrl: './JBMCommentsBox.component.html',
  styleUrls: ['./JBMCommentsBox.component.scss']
})
export class JBMCommentsBoxComponent implements OnInit, OnChanges {
  maxHeight: string='auto';
  constructor() { }

  @Input() comments: string='';
  @Input() class: string;
  @Input() height: number;  // Height in Rem
  @Input() hideIfEmpty: boolean;
  @Input() showEditButton: boolean;

  @Output() onEdit=new EventEmitter();

  ngOnInit(): void {
    if(this.comments==undefined) this.comments='';
    if(this.class==undefined) this.class='';
    if(this.height==undefined) this.height=0;
    if(this.height>0) this.maxHeight=this.height.toString()+'rem';
    if(this.hideIfEmpty==undefined) this.hideIfEmpty=true;
    if(this.showEditButton==undefined) this.showEditButton=false;
  }
  ngOnChanges(changes: SimpleChanges) {
    // Prevents comments with only whitespace(s)
    if(this.comments)
      this.comments=this.comments.trim();
  }
  editComment() {
    this.onEdit.emit();
  }
}
