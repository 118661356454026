<div class="invoice-details pt-3 pb-1" [ngClass]="{'bg-blue-extra-light p-3 mt-2 mb-3': expanded}">
   <div class="expandable clearfix">
      <div class="row">
         <div class="col-md-6">
            <div class="jbm-properties-list" [ngClass]="{'collapsed': !expanded}">
               <div class="item">
                  <label class="label">{{'customer'|translate}}:</label>
                  <label class="value">
                     <jbm-link-button
                       [caption]="invoice.customer.name"
                       [style]="'margin-top:-0.15rem'"
                       (onClick)="showCustomer(invoice.customers_id)">
                     </jbm-link-button>
                  </label>
               </div>
            </div>
         </div>
         <div class="col-md-5">
            <div *ngIf="invoice.projects_id" class="jbm-properties-list ml-3" [ngClass]="{'collapsed': !expanded}">
               <div class="item">
                  <label class="label">{{'entity.project'|translate}}:</label>
                  <label class="value">
                     <jbm-link-button
                       [caption]="invoice.project.name"
                       [style]="'margin-top:-0.15rem'"
                       (onClick)="showProject()">
                     </jbm-link-button>
                  </label>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="expanded" class="row">
      <div class="col-md-6">
         <div class="jbm-properties-list">
            <div *ngIf="invoice.customer.phone" class="item">
               <label class="label">{{'entity.phone'|translate}}:</label>
               <label class="value">{{invoice.customer.phone}}</label>
            </div>
            <div *ngIf="invoice.customer.email" class="item">
               <label class="label">{{'entity.email'|translate}}:</label>
               <label class="value">{{invoice.customer.email}}</label>
            </div>
            <div class="item">
               <label class="label">{{'entity.blocked'|translate}}:</label>
               <label class="value">
                  <span *ngIf="invoice.customer.blocked"  class="badge bg-danger">{{'ui.yes'|translate}}</span>
                  <span *ngIf="!invoice.customer.blocked" class="badge bg-success">{{'ui.no'|translate}}</span>
               </label>
            </div>
            <div *ngIf="invoice.customer.description" class="item">
               <label class="label">{{'entity.notes'|translate}}:</label>
               <label>
                  <jbm-comments-box [comments]="invoice.customer.description" height="2.75" class="customer"></jbm-comments-box>
               </label>
            </div>
            <div class="item">
               <label class="label">{{'entity.customer'|translate}}{{'ui.message'|translate|lowercase}}:</label>
               <label class="value" style="margin-top: -0.2rem;">
                  {{invoice.customer_comments}}
                  <button *ngIf="editable" class="btn btn-sm round btn-primary" [ngClass]="{'ml-3': invoice.customer_comments}" (click)="editCustomerComments()">
                     <i class="far fa-edit"></i>
                  </button>
               </label>
            </div>
         </div>
      </div>
      <div *ngIf="invoice.projects_id" class="col-md-6">
         <div class="jbm-properties-list ml-3">
            <div class="item">
               <label *ngIf="invoice.project.address" class="label">{{'project.work-location'|translate}}:</label>
               <label *ngIf="invoice.project.address" class="value location">
                  <div class="float-start">
                     {{invoice.project.address.street}}&nbsp;{{invoice.project.address.no}}<br />
                     {{invoice.project.address.postcode}}&nbsp;&nbsp;{{invoice.project.address.city}}
                  </div>
                  <div class="float-start mt-2 pt-1 ml-3">
                     <jbm-gps-location-button [title]="'project.work-location'|translate" [btnContext]="'outline-secondary'" [longitude]="invoice.project.address.lon" [latitude]="invoice.project.address.lat"
                       [address]="(invoice.project.address.street)+' '+(invoice.project.address.no)+ ', '+invoice.project.address.city">
                     </jbm-gps-location-button>
                  </div>
               </label>
            </div>
            <div class="item">
               <label class="label">{{'project.state'|translate}}:</label>
               <label class="value">{{invoice.project.projectState.description}}</label>
            </div>
            <div class="item">
               <label class="label">{{'company.plant'|translate}}:</label>
               <label class="value">{{invoice.project.plant.name}}</label>
            </div>
         </div>
      </div>
   </div>
</div>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
   <customer-details [customer]="invoice.customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>

<jbm-modal [id]="'customerComments'" [className]="'customer-comments'"></jbm-modal>
<ng-template #modalCustomerComments let-modal>
   <invoice-customer-comments [invoice_id]="invoice.id" (onSave)="saveCustomerComments($event)" (onClose)="closeCustomerComments()"></invoice-customer-comments>
</ng-template>

<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
   <project-details [project_id]="invoice.project.id" (onClose)="closeProject()"></project-details>
</ng-template>
