import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'presentations/';

@Injectable({
  providedIn: 'root'
})
export class PlaceholdersService {
  private placeholder_delimiters = { left: '<%', right: '%>' }

  constructor(
      private HttpService: HttpService,
  ) {}

  get(language: string, presentation_type: number, output_type: number, allocation: number) {
    return this.HttpService.get(baseURL+'read/placeholders/'+language+'/'+presentation_type.toString()+'/'+output_type.toString()+'/'+allocation,null );
  }
  getDelimiters() {
    return this.placeholder_delimiters;
  }
  removeDelimiters(placeholder: string) {
    placeholder=placeholder.replace(this.placeholder_delimiters.left,'');
    placeholder=placeholder.replace(this.placeholder_delimiters.right,'');
    return placeholder;
  }
}
