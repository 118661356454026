import { Pipe, PipeTransform } from '@angular/core';
import {formatCurrency, getCurrencySymbol} from "@angular/common";

@Pipe({
  name: 'money'
})
export class MoneyPipe implements PipeTransform {

  transform(
    value: number,
    currencyCode: string='EUR',
    display: |'code'|'symbol'|'symbol-narrow'|string|boolean='symbol',
    digitsInfo: string='1.2-2',
    locale: string='nl' ): string | null
  {
    if(value==undefined) return '';
    if(value===0) return '';
    return formatCurrency(
        value,
        locale,
        getCurrencySymbol(currencyCode,'wide'),
        currencyCode, digitsInfo );
  }
}
