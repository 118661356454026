import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'quotations/';
const slug: string = 'articles/';

export const price_type_system_codes = { M: 57, M2: 54, M3: 1, PIECE: 56, PERCENT: 58 }

@Injectable({
  providedIn: 'root'
})
export class QuotationArticlesService {

  constructor(private HttpService: HttpService) { }

  calcTotalPrice(price: number, amount: number, article_price_types_id: number, order_amount: number) {
    if(article_price_types_id===price_type_system_codes.PERCENT)
      return (order_amount/100) * amount * price;
    return amount * price;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
}
