import {Component, Input, OnInit} from '@angular/core';
import {PriceRulesTranslationKeys} from '../helpers/PriceRulesTranslationKeys';

@Component({
  selector: 'jbm-pricerules-breakdown-popover',
  templateUrl: './JBMPriceRulesBreakdownPopover.component.html',
  styleUrls: ['./JBMPriceRulesBreakdownPopover.component.scss']
})
export class JBMPriceRulesBreakdownPopoverComponent implements OnInit {
  PriceRulesTranslationKeys: PriceRulesTranslationKeys;

  constructor() {
    this.PriceRulesTranslationKeys=new PriceRulesTranslationKeys();
  }

  @Input() price: number=0;
  @Input() isConcrete: boolean=false;
  @Input() pricerules: any[]=[];

  ngOnInit(): void {
    if(this.price==undefined) this.price=0.0;
    if(this.isConcrete==undefined) this.isConcrete=false;
    if(this.pricerules==undefined) this.pricerules=[];
  }
  ngOnChanges() {
    if(this.pricerules==undefined) return;
    this.pricerules=this.PriceRulesTranslationKeys.setKeys(this.pricerules, this.isConcrete);
  }
}
