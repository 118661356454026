import { Injectable } from '@angular/core';

const mimetypes = {
  html: 'text/html',
  pdf: 'application/pdf',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
}

@Injectable({
  providedIn: 'root'
})
export class MimeTypesService {

  constructor() { }

  get() {
    return mimetypes;
  }
  getMimetype( fileExtension ) {
    if(mimetypes.hasOwnProperty(fileExtension))
      return mimetypes[fileExtension];
    else
      return mimetypes.html; // Assume text/html
  }
}
