import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'invoicing/';
const segment: string = 'invoice-items/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceItemsService {

  constructor(private HttpService: HttpService) {}

  get(language: string, invoice_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+invoice_id.toString(), params );
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  updateExternalRecipeComment(invoice_item_id: number, comment: string) {
    if(comment==='') comment=null;
    let data={ id: invoice_item_id, comment: comment }
    return this.HttpService.post(baseURL+'update/'+segment+'external-recipe-comment', data);
  }
}
