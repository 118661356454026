<form [formGroup]="form" (ngSubmit)="save()" class="recipe">
   <div class="jbm-modal-header">
      <jbm-crud-title [subject]="'entity.recipe'" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-text-input [id]="'description'" [formControl]="formControls.description"
        [required]="true" [submitted]="submitted" [labelCaption]="'entity.description'|translate" class="lg" labelClass="lg">
         <jbm-input-error [formControl]="formControls.description" [submitted]="submitted">
            <span *ngIf="formControls.description.hasError('required')">{{'entity.requiredfield'|translate}}</span>
            <span *ngIf="formControls.description.hasError('notUnique')">{{'entity.description-not-unique'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [id]="'internal_description'" [formControl]="formControls.internal_description"
        [required]="false" [submitted]="submitted" [labelCaption]="'entity.notes'|translate" class="lg" labelClass="lg">
      </jbm-text-input>
      <strength-selector
        [labelCaption]="'concrete.strength'|translate"
        labelClass="lg"
        class="lg"
        [id]="'strength'"
        [template]="strengthselect"
        [strength_id]="strength_id"
        [required]="true"
        [isSubmitted]="submitted"
        (strengthSelected)="selectStrength($event)">
         <ng-template #strengthselect let-strength="strength">
            <div *ngIf="strength">
               {{strength.description}}
            </div>
         </ng-template>
      </strength-selector>
      <jbm-multi-select [options]="environments" [selection]="environment_ids"
        [labelCaption]="'financial.concrete-environments'|translate"
        [submitted]="submitted"
        [required]="true"
        [editable]="true"
        [removeableSelection]="false"
        (onSelectionChange)="selectEnvironment($event)"
        class="lg"
        labelClass="lg">
      </jbm-multi-select>
      <consistency-selector
        [labelCaption]="'concrete.consistency'|translate"
        labelClass="lg"
        class="lg"
        [id]="'consistency'"
        [template]="consistencyselect"
        [consistency_id]="consistency_id"
        [required]="true"
        [isSubmitted]="submitted"
        (consistencySelected)="selectConsistency($event)">
         <ng-template #consistencyselect let-consistency="consistency">
            <div *ngIf="consistency">
               {{consistency.code}}
            </div>
         </ng-template>
      </consistency-selector>
      <recipe-resources [recipe_id]="id"></recipe-resources>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'entity.recipe'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
