import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {QuotationState} from "./interfaces/quotation-state";

export const quotation_state = {
  draft: 100,
  approved: 200,
  send: 300,
  negotiating: 400,
  accepted: 500,
  overdue: 600,
  rejected: 700,
  cancelled: 800
}

const baseURL: string = 'quotations/';

@Injectable({
  providedIn: 'root'
})
export class QuotationStatesService {
  quotationState: QuotationState;

  constructor(private HttpService: HttpService) {}

  get(language, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/quotation-states/'+language, params );
  }
  getEmpty() {
    this.quotationState = {
      id: 0,
      description: '',
      color: '',
      text_color: ''
    }
    return this.quotationState;
  }
  getByID(quotationStates: any[], id: number): any {
    for(let is=0; is<quotationStates.length; is++)
      if(quotationStates[is].id===id)
        return quotationStates[is];
    return false;  // Means not found
  }
}