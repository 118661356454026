import {NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {InvoicesComponent} from "./datatable/invoices.component";
import {InvoiceViewComponent} from "./invoice-view/invoice-view.component";
import {InvoiceResolver} from "./data/resolvers/invoice.resolver";
import {InvoicePreviewComponent} from "./preview/invoice-preview.component";
import {InvoiceableReceiptsComponent} from "./invoiceable-receipts/invoiceable-receipts.component";
import {CustomerResolver} from "../customers/data/resolvers/customer.resolver";

const routes: Routes = [
  { path: 'invoicing',
    component: InvoicesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  { path: 'invoicing/invoiceable-receipts',
    component: InvoiceableReceiptsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  { path: 'invoicing-history',
    component: InvoicesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.ExternalOutlet, history: true}
  },
  { path: 'invoicing-history/customer/:id',
    component: InvoicesComponent,
    canActivate: [AuthGuardService],
    resolve: { customer: CustomerResolver},
    data: {AppLayout: AppLayouts.ExternalOutlet, history: true, customer: true}
  },
  { path: 'invoicing/:invoice_id',
    component: InvoiceViewComponent,
    resolve: { invoice: InvoiceResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  { path: 'invoicing-history/:invoice_id',
    component: InvoiceViewComponent,
    resolve: { invoice: InvoiceResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.ExternalOutlet, history: true}
  },
  { path: 'invoice-preview/:invoice_id',
    component: InvoicePreviewComponent,
    resolve: { invoice: InvoiceResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.ExternalOutlet}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class InvoicingRoutingModule { }
