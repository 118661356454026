import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {OrderArticlesService} from "../../../../features/projects/data/order-articles.service";
import {SanitizationService} from "../../../../services/sanitization/sanitization.service";
import {Subscription} from "rxjs";
import {HttpParams} from "@angular/common/http";

@Component({
	selector: 'order-articles-list',
	templateUrl: './order-articles-list.component.html',
	styleUrls: [ './order-articles-list.component.scss' ]
})
export class OrderArticlesListComponent implements OnInit, OnDestroy {
	language: string=this.TokenService.getLanguage();
    articles: any[] = [];
	totalPrice: number=0;

	items$: Subscription;
	count$: Subscription;

	@Input() orderID: number;
	@Input() showPrices: boolean=false;

	@Output() onTotalsCalculated=new EventEmitter();

	constructor(
		private OrderArticlesService: OrderArticlesService,
		private SanitizationService: SanitizationService,
		private TokenService: TokenService
	) {}

	ngOnInit(): void {
		let params = new HttpParams();
		params=params.append('recipe_articles','1');
		params=params.append('totalprices','1');
		this.items$ = this.OrderArticlesService.get(this.orderID, this.language, params).subscribe(
			(data: any) => this.articles = data.data, (error) =>console.error(error),() => {
				this.totalPrice = 0.0;
				for( let article of this.articles)
					article.amountStr=this.SanitizationService.amountFloatToStr(article.amount,1) + ' ' + article.code;
				this.onTotalsCalculated.emit(this.totalPrice);
			});
	}
	ngOnDestroy(): void {
		// Clean up observable subscriptions to avoid memory leaks
		if( this.items$ != undefined ) this.items$.unsubscribe();
		if( this.count$ != undefined ) this.count$.unsubscribe();
	}
}
