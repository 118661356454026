<app-header>
    <jbm-view-title [caption]="'financial.concrete-prices'|translate"
      [subCaption]="concretePricelist.description"
      [parentLinkCaption]="parentLinkCaption"
      [parentLinkSlug]="parentLinkRoute">
    </jbm-view-title>
    <jbm-create-button [visible]="userRights.AllowCreate"
      tooltip="{{'concrete.price'|translate}} {{'ui.insert'|translate|lowercase}}"
      (click)="add()">
    </jbm-create-button>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-2 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module concrete-prices">
    <jbm-table-header className="bg-light pl-4">
        <jbm-properties-list [properties]="priceListProps" className="inline"></jbm-properties-list>
    </jbm-table-header>
    <div class="table-responsive pl-3 pr-3">
        <table class="table table-xs">
            <thead>
            <tr>
                <td></td>
                <td *ngFor="let environment of gridData.environments" class="fw-bold text-end">{{environment.description}}</td>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let strength of gridData.strengths; let s_index=index">
                <td class="strength align-top fw-bold">{{strength.description}}</td>
                <td *ngFor="let environment of gridData.environments; let e_index=index" class="p-0">
                    <table class="table table-xs table-borderless">
                        <tbody>
                        <tr *ngFor="let consistency of gridData.consistencies; let c_index=index">
                            <td *ngIf="!e_index">{{consistency.code}}</td>
                            <td *ngIf="e_index" hidden></td>
                            <td *ngIf="gridData.prices[s_index][e_index][c_index].price" class="text-end">
                                <jbm-link-button *ngIf="userRights.AllowUpdate" caption="{{gridData.prices[s_index][e_index][c_index].price|money}}"
                                  [showIcon]="false" [tooltip]="priceTooltip"
                                  (onClick)="edit(gridData.prices[s_index][e_index][c_index].id)">
                                </jbm-link-button>
                                <span *ngIf="!userRights.AllowUpdate">{{gridData.prices[s_index][e_index][c_index].price|money}}</span>
                                <ng-template #priceTooltip>
                                    {{gridData.strengths[s_index].description}}&nbsp;
                                    {{gridData.environments[e_index].description}}&nbsp;
                                    {{gridData.consistencies[c_index].code}}
                                </ng-template>
                            </td>
                            <td *ngIf="!gridData.prices[s_index][e_index][c_index].price" class="text-end">
                                <jbm-link-button *ngIf="userRights.AllowCreate" caption="{{gridData.prices[s_index][e_index][c_index].price|money}}"
                                  [iconClass]="'fa fa-edit'" tooltip="{{'concrete.price'|translate}} {{'ui.insert'|translate|lowercase}}"
                                  (onClick)="addConcreteSpecs(gridData.strengths[s_index].id, gridData.environments[e_index].id, gridData.consistencies[c_index].id)">
                                </jbm-link-button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <jbm-modal [id]="'concrete-price'" [className]="'concrete-price'"></jbm-modal>
    <ng-template #modalContent let-modal>
        <form-concrete-price [userRights]="userRights" [id]="price_id"
          [strength_id]="strength_id" [environment_id]="environment_id" [consistency_id]="consistency_id"
          [concrete_pricelist]="concretePricelist" [language]="language" (onSave)="saved()" (onCancel)="canceled()">
        </form-concrete-price>
    </ng-template>
</section>

<ng-template #actionsTemplate let-data="data">
    <jbm-confirm-delete-dropdown *ngIf="data.deletePermission" class="float-end ml-2"
      [placement]="'left-top'"
      [subject]="('financial.concrete-price'|translate)"
      (onConfirm)="delete(data.id)">
    </jbm-confirm-delete-dropdown>
    <article-price-history-dropdown [subject]="1" [price_id]="data.id" [placement]="'left'"></article-price-history-dropdown>
    <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-primary mr-2 float-end" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
      (click)="edit(data.id); $event.stopPropagation()"><i class="far fa-edit"></i>
    </button>
</ng-template>

<jbm-toasts></jbm-toasts>
