import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {InvoiceArticle} from "./interfaces/invoice-article";
import {price_type_system_codes} from "../../projects/data/order-articles.service";

const baseURL: string = 'invoicing/';
const segment: string = 'invoice-articles/';

export const article_usages = {
  recipe: 0,       // Recipe article
  order: 1,        // Order article
  order_extra: 2,  // Extra invoiced order article
  extra: 2         // An extra invoiced article
}

@Injectable({
  providedIn: 'root'
})
export class InvoiceArticlesService {
  invoiceArticle: InvoiceArticle;

  constructor(private HttpService: HttpService) {}

  get(language: string, invoice_id: number, invoice_item_id: number, usage: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+invoice_id.toString()+'/'+invoice_item_id.toString()+'/'+usage.toString(), params );
  }
  getExtraArticles(language: string, invoice_id: number, invoice_item_id: number, params: HttpParams) {
    return this.get(language, invoice_id, invoice_item_id, article_usages.extra, params );
  }
  getSelectList(language: string, invoice_id: number, invoice_item_id:number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+'selectlist/'+language+'/'+invoice_id+'/'+invoice_item_id, params );
  }
  getEmpty() {
    this.invoiceArticle = {
      id: 0,
      invoices_id: 0,
      invoice_items_id: null,
      usage: 0,
      article_price_types_id: 0,
      delivery_date: '',
      orders_id: 0,
      articles_id: 0,
      description: '',
      article_display: 0,
      amount: 0,
      price: 0,
      totalprice: 0
    }
    return this.invoiceArticle;
  }
  calcTotalPrice(price: number, amount: number, article_price_types_id: number, invoice_item_amount: number) {
    if(article_price_types_id===price_type_system_codes.PERCENT)
      return (invoice_item_amount/100) * amount * price;
    return amount * price;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  delete(invoice_article_id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+invoice_article_id.toString());
  }
}