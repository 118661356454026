import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'quotations/';

@Injectable({
  providedIn: 'root'
})
export class DefaultUnloadingMethodService {
  constructor(private HttpService: HttpService) { }

  get() {
    return this.HttpService.get(baseURL+'read/default-unloading-method');
  }
}
