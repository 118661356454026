<div id="{{id}}" class="table-container jbm-table" [class.table-absolute]="absolute" [class.table-responsive]="responsive">
    <table class="table jbm-table {{classname}}"
      [class.table-hover]="hoverable"
      [class.table-row-selectable]="rowSelectable"
      [class.table-striped]="striped"
      [class.table-bordered]="bordered"
      [class.table-sm]="size=='sm'"
      [class.table-xs]="size=='xs'">
    <thead>
    <tr>
        <ng-container *ngFor="let c of columnDefs">
            <th *ngIf="c.type==15" class="table-row-expand-btn" style="width:{{c.width}}rem">&nbsp;</th>
            <th *ngIf="c.type<15" jbm-table-column
              [class.sortable]="sortable"
              [class.sorted]="c.sorted"
              [class.asc]="sortDirection=='+'"
              [class.desc]="sortDirection=='-'"
              [name]="c.name" [type]="c.type" [header]="c.header" [iconClassname]="c.iconClassname"
              [sortable]="sortable && c.sortable" [hidden]="!c.visible" [sorted]="c.sorted"
              [sortdirection]="sortDirection" [width]="c.width" [align]="c.align"
              (sortChange)="tableSortChange($event)">
            </th>
        </ng-container>
        <th *ngIf="actionDefs.length" class="actions"></th>
    </tr>
    </thead>
    <tbody>
        <tr *ngIf="filterable && showFilters" class="table-filters">
            <ng-container *ngFor="let c of columnDefs">
                <td *ngIf="c.type==15" class="table-row-expand">&nbsp;</td>
                <td *ngIf="c.type<15" [hidden]="!c.visible">
                    <jbm-table-column-filter *ngIf="c.filterable"
                      [name]="c.name" [columnType]="c.type" [filterValues]="c.filterValues" [filterValue]="c.filterValue"
                      [searchMinLength]="c.searchMinLength"
                      (filterChange)="tableFilterChange($event)"></jbm-table-column-filter>
                </td>
            </ng-container>
            <td *ngIf="actionDefs.length">&nbsp;</td>
        </tr>
        <ng-container *ngFor="let row of data">
            <tr (click)="onRowSelect(row)">
                <ng-container *ngFor="let c of columnDefs">
                    <td *ngIf="c.type==0" [hidden]="true" jbm-table-cell [data]="row.id"></td>
                    <td *ngIf="c.type==12" [hidden]="!c.visible" class="comments {{c.className}}">
                        <jbm-comments-dropdown [iconClass]="c.iconClassname" [comments]="row[c.name]" [extraSmall]="size=='xs'"
                        (onOpenChange)="openDropdownChange($event)"></jbm-comments-dropdown>
                    </td>
                    <td *ngIf="c.type==13" [hidden]="!c.visible" class="address {{c.className}}">
                        <jbm-address-dropdown [address_details_id]="row[c.name]" [extraSmall]="size=='xs'" [class]="'btn-block'"
                        (onOpenChange)="openDropdownChange($event)"></jbm-address-dropdown>
                    </td>
                    <td *ngIf="c.type==15" class="expandable {{c.className}}">
                        <button *ngIf="isExpandable(row) && rowExpanded!=row.id" class="btn btn-light btn-block"
                          [ngbTooltip]="rowExpandTooltip" container="body"
                          [ngClass]="{'btn-xs': size=='xs', 'btn-sm': size=='sm' }"
                          (click)="toggleRowExpanded(row); $event.stopPropagation()">
                            <i class="fa fa-plus"></i><span *ngIf="c.header">&nbsp;{{c.header}}</span>
                        </button>
                        <button *ngIf="isExpandable(row) && rowExpanded==row.id" class="btn btn-light btn-block active"
                          [ngbTooltip]="rowCollapseTooltip" container="body"
                          [ngClass]="{'btn-xs': size=='xs', 'btn-sm': size=='sm' }"
                          (click)="toggleRowExpanded(row); $event.stopPropagation()">
                            <i class="fa fa-minus"></i><span *ngIf="c.header">&nbsp;{{c.header}}</span>
                        </button>
                    </td>
                    <td *ngIf="c.type>0 && c.type<12 && c.template" [hidden]="!c.visible" class="{{c.className}}">
                        <ng-container *ngTemplateOutlet="c.template; context: { 'data': row }"></ng-container>
                    </td>
                    <td *ngIf="c.type>0 && c.type<12 && !c.template" [hidden]="!c.visible" jbm-table-cell [type]="c.type" [align]="c.align" [data]="row[c.name]" [displayValues]="c.displayValues" class="{{c.className}}"></td>
                </ng-container>
                <td *ngIf="actionDefs.length" class="actions">
                    <span *ngFor="let a of actionDefs" jbm-table-action
                        [name]="a.name" [iconClass]="a.iconClass" [caption]="a.caption" [size]="size"
                        [context]="a.context" (click)="onActionClick(row,a.name); $event.stopPropagation()">
                    </span>
                </td>
            </tr>
            <tr *ngIf="template && rowExpanded==row.id" class="table-row-expanded">
                <td [colSpan]="columnDefs.length" class="expanded">
                    <ng-container *ngTemplateOutlet="template; context: { 'data': row }"></ng-container>
                </td>
            </tr>
        </ng-container>
    </tbody>
    </table>
    <div *ngIf="data && !data.length" class="jbm-empty-view">{{'ui.empty-view'|translate}}</div>
</div>
