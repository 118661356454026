import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import {JBMActivityLogComponent} from "./components/JBM/Asides/JBMActivityLog/JBMActivityLog.component";
import {JBMHelpIndexComponent} from "./components/JBM/Asides/JBMHelpIndex/JBMHelpIndex.component";
import {JBMLocationMapComponent} from "./components/JBM/Asides/JBMLocationMap/JBMLocationMap.component";
import {JBMShortcutsComponent} from "./components/JBM/Asides/JBMShortcuts/JBMShortcuts.component";
import {AuthGuardService} from "./services/auth/authguard.service";
import {ProjectFormComponent} from "./features/projects/form/project-form.component";
import {CustomerFormComponent} from "./features/customers/form/customer-form.component";
import {CustomerInvoiceFormComponent} from "./features/invoicing/customer-form/customer-invoice-form.component";
import {InvoicesValidationComponent} from "./features/invoicing/invoices-validation/invoices-validation.component";
import {ProjectInvoiceFormComponent} from "./features/invoicing/project-form/project-invoice-form.component";

const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  },
  {
    path: 'history',
    component: JBMActivityLogComponent,
    canActivate: [AuthGuardService],
    outlet: "ar"
  },
  {
    path: 'map/:longitude/:latitude',
    component: JBMLocationMapComponent,
    canActivate: [AuthGuardService],
    outlet: "ar"
  },
  {
    path: 'help',
    component: JBMHelpIndexComponent,
    outlet: "ar"
  },
  {
    path: 'shortcuts',
    component: JBMShortcutsComponent,
    outlet: "ar"
  },
  {
    path: 'project/create',
    component: ProjectFormComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'project/edit/:id',
    component: ProjectFormComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'customer/create',
    component: CustomerFormComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'customer/edit/:id',
    component: CustomerFormComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'invoicing/customer-create',
    component: CustomerInvoiceFormComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'invoicing/project-create',
    component: ProjectInvoiceFormComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'invoicing/validation/:id',
    component: InvoicesValidationComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  },
  {
    path: 'invoicing/validation',
    component: InvoicesValidationComponent,
    canActivate: [AuthGuardService],
    outlet: "modal"
  }
];
@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: "reload" })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
