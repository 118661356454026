import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const BaseURL: string = 'financial/';

@Injectable({
    providedIn: 'root'
})
export class CustomersService {

    constructor(private HttpService: HttpService) {}

    getSelectList(language, category, article_pricelists_id, params: HttpParams) {
        return this.HttpService.get(BaseURL+'read/customers/selectlist/'+category+'/'+article_pricelists_id, params );
    }
}
