<jbm-modal [id]="'customer'" [className]="'content'" (onClose)="close()"></jbm-modal>
<ng-template #modalCustomer let-modal>
<form [formGroup]="customerForm" (ngSubmit)="save()" class="customer" xmlns="http://www.w3.org/1999/html">
   <div class="jbm-modal-header">
      <jbm-crud-title [subject]="'entity.customer'" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <div class="action-buttons">
            <jbm-save-button [subject]="'customer'|translate|titlecase" [small]="false" (click)="save()"></jbm-save-button>
            <jbm-cancel-button context="secondary" [small]="false" (click)="close()"></jbm-cancel-button>
         </div>
         <div class="toolbar-spacer-1"></div>
         <div class="btn-toolbar">
            <jbm-activity-log-button *ngIf="customer.id" subject_type="7" [subject_id]="customer.id" class="light"
              subject="{{'entity.customer'|translate}}" [title]="customer.name"></jbm-activity-log-button>
            <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         </div>
      </div>
   </div>
   <div class="jbm-modal-body">
      <div class="split-container clearfix">
         <div class="left-side">
            <jbm-text-input [formControl]="formControls.name" [submitted]="isSubmitted" [id]="'nameinput'"
              required="true" [maxlength]="75" class="mt-3 md" labelCaption="{{'entity.name'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.name" [submitted]="isSubmitted">
                  <span *ngIf="formControls.name.hasError('not_unique')">{{'entity.name-not-unique'|translate}}</span>
                  <span *ngIf="formControls.name.hasError('required')">{{'entity.requiredfield'|translate}}</span>
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.full_name" [id]="'full_name'" class="md" [maxlength]="75"
              labelCaption="{{'customer.fullname'|translate}}" labelClass="md" [submitted]="isSubmitted">
            </jbm-text-input>
            <jbm-select [formControl]="formControls.customer_type" [options]="ct_options" [submitted]="isSubmitted" class="md"
              (change)="changeCustomertype($event)" [id]="'customer_type'" insertLeadingOption="true" required="true"
              labelCaption="{{'customer.type'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.customer_type" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-select>
            <jbm-text-input [formControl]="formControls.coc" [hidden]="customer_type_value!=='1'" [submitted]="isSubmitted"
              [id]="'coc'" [maxlength]="15" class="sm" labelCaption="{{'customer.coc-number'|translate}}"
              labelClass="md" (onBlur)="updateCOCAddress($event)">
               <jbm-input-error [formControl]="formControls.coc" [submitted]="isSubmitted">
                  <span *ngIf="formControls.coc.hasError('not_unique')">{{'customer.coc-not-unique'|translate}}</span>
                  <span *ngIf="!formControls.coc.hasError('not_unique')">{{'customer.coc-number-format'|translate}}</span>
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.debtor_id" [submitted]="isSubmitted" [id]="'debtor_id'" class="xs"
               [maxlength]="15" labelCaption="{{'customer.debtornumber'|translate}}" labelClass="md">
            </jbm-text-input>
            <jbm-check [formControl]="formControls.blocked" [id]="'blocked'" labelCaption="{{'entity.blocked'|translate}}"
              labelClass="md" inputClass="sm" textChecked="{{'ui.yes'|translate}}" textUnchecked="{{'ui.no'|translate}}">
            </jbm-check>
            <jbm-text-input [formControl]="formControls.phone" [submitted]="isSubmitted" [id]="'phone'" class="mt-3 xs"
              [maxlength]="15" labelCaption="{{'entity.phone'|translate}}" labelClass="md">
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.email" [submitted]="isSubmitted" [id]="'email'"
              required="true" [maxlength]="125" labelCaption="{{'entity.emailaddress'|translate}}" class="md" labelClass="md">
               <jbm-input-error [formControl]="formControls.email" [submitted]="isSubmitted">
                  {{'entity.emailformat'|translate}}
               </jbm-input-error>
            </jbm-text-input>

            <div class="form-section-separator mt-3">{{'entity.address'|translate}}</div>
            <jbm-text-input [formControl]="formControls.postcode" [submitted]="isSubmitted" [id]="'postalcode'" class="xxs"
              required="required" [maxlength]="7" (onBlur)="updateAddress(false)"
              labelCaption="{{'entity.postalcode'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.postcode" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.no" [submitted]="isSubmitted" [id]="'housenr'" class="xxs"
              required="required" [maxlength]="15" (onBlur)="updateAddress(false)"
              labelCaption="{{'entity.housenumber'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.no" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.street" [submitted]="isSubmitted" [id]="'street'" class="md"
              required="required" [maxlength]="75" (onBlur)="updateAddress(false)"
              labelCaption="{{'entity.streetname'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.street" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.city" [submitted]="isSubmitted" [id]="'city'" class="md"
              required="required" [maxlength]="50" (onBlur)="updateAddress(false)"
              labelCaption="{{'entity.cityname'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.city" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <fieldset style="position: relative"><legend>{{'gps.location'|translate}}</legend>
               <ngb-alert *ngIf="error_lonlat" [type]="'warning'" [dismissible]="false">{{error_lonlat}}</ngb-alert>
               <jbm-text-input [formControl]="formControls.lon" [submitted]="isSubmitted" [id]="'longitude'" class="xxs"
                 [maxlength]="13" labelCaption="{{'gps.longitude'|translate}}" labelClass="width-7">
                  <jbm-input-error [formControl]="formControls.lon" [submitted]="isSubmitted">
                     {{'gps.longitude-latitude-format'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <jbm-text-input [formControl]="formControls.lat" [submitted]="isSubmitted" [id]="'latitude'" class="xxs"
                 [maxlength]="12" labelCaption="{{'gps.latitude'|translate}}" labelClass="width-7">
                  <jbm-input-error [formControl]="formControls.lat" [submitted]="isSubmitted">
                     {{'gps.longitude-latitude-format'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <div *ngIf="GPS_address.latitude && formControls.lat.value && formControls.lon.value" class="form-group">
                  <div class="col-form-label width-7 float-start">&nbsp;</div>
                  <div class="float-start ml-2">
                     <jbm-gps-location-button
                       [latitude]="GPS_address.latitude"
                       [longitude]="GPS_address.longitude"
                       title="{{'customer'|translate}}&nbsp;{{'entity.address'|translate|lowercase}}"
                       [address]="formControls.street.value+'&nbsp;'+formControls.no.value+', '+formControls.city.value"
                       context="outline-secondary">
                     </jbm-gps-location-button>
                  </div>
               </div>
            </fieldset>
            <jbm-textarea [formControl]="formControls.description" [submitted]="isSubmitted" [id]="'description'" class="mt-4 md"
              labelCaption="{{'entity.notes'|translate}}" labelClass="md" rows="3">
            </jbm-textarea>
         </div>
         <div class="right-side">
            <div *ngIf="financialRights.AllowUpdate">
               <div class="form-section-separator md-md mt-3">{{'concrete.price'|translate}}</div>
               <ngb-alert *ngIf="error" [type]="'warning'" [dismissible]="false">{{error}}</ngb-alert>
               <jbm-float-input-group [formControl]="formControls.baseprice" [id]="'baseprice'" class="xxs"
                 labelCaption="{{'concrete.price'|translate}}" [prepend]="'€'" labelClass="md">
                  <jbm-input-error [formControl]="formControls.baseprice" [submitted]="isSubmitted">
                     <span *ngIf="formControls['baseprice'].hasError('priceInvalid')">
                        {{'entity.priceformat'|translate}}</span>
                  </jbm-input-error>
               </jbm-float-input-group>
               <jbm-float-input-group [formControl]="formControls.baseprice_discount" [id]="'baseprice_discount'" class="xxs"
                  labelCaption="{{'concrete.price-discount'|translate}}" [prepend]="'€'" labelClass="md">
                  <jbm-input-error [formControl]="formControls.baseprice_discount" [submitted]="isSubmitted">
                      <span *ngIf="formControls['baseprice_discount'].hasError('priceInvalid')">
                        {{'entity.priceformat'|translate}}</span>
                  </jbm-input-error>
               </jbm-float-input-group>
            </div>
            <div class="form-section-separator md-md mt-3">{{'menu.invoicing'|translate}}</div>
            <jbm-text-input [formControl]="formControls.invoice_email" [submitted]="isSubmitted" [id]="'invoice_email'" class="md"
              labelCaption="{{'entity.emailaddress'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.invoice_email" [submitted]="isSubmitted">
                  {{'entity.emailformat'|translate}}
               </jbm-input-error>
            </jbm-text-input>

            <jbm-check [formControl]="formControls.invoice_address_switch" [id]="'invoice_address_switch'"
              labelCaption="{{'customer.invoice-address-switch'|translate}}" labelClass="md" inputClass="md mt-2"
              (change)="updateInvoiceAddressSwitch($event)">
            </jbm-check>
            <div class="invoice-address" [hidden]="!invoice_address_switch">
               <div class="form-section-separator">{{'customer.invoice-address'|translate}}</div>
               <jbm-text-input [formControl]="formControls.invoice_postcode" [submitted]="isSubmitted" [id]="'address.postalcode'" class="xxs"
                 [maxlength]="7" (onBlur)="updateAddress(true)"
                 labelCaption="{{'entity.postalcode'|translate}}" labelClass="md">
                  <jbm-input-error [formControl]="formControls.invoice_postcode" [submitted]="isSubmitted">
                     {{'entity.requiredfield'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <jbm-text-input [formControl]="formControls.invoice_no" [submitted]="isSubmitted"  [id]="'address.nr'" class="xxs"
                 [maxlength]="15" (onBlur)="updateAddress(true)"
                 labelCaption="{{'entity.housenumber'|translate}}" labelClass="md">
                  <jbm-input-error [formControl]="formControls.invoice_no" [submitted]="isSubmitted">
                     {{'entity.requiredfield'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <jbm-text-input [formControl]="formControls.invoice_street" [submitted]="isSubmitted" [id]="'address.street'" class="md"
                 [maxlength]="50" (onBlur)="updateAddress(true)"
                 labelCaption="{{'entity.streetname'|translate}}" labelClass="md">
                  <jbm-input-error [formControl]="formControls.invoice_street" [submitted]="isSubmitted">
                     {{'entity.requiredfield'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <jbm-text-input [formControl]="formControls.invoice_city" [submitted]="isSubmitted" [id]="'address.city'" class="md"
                 [maxlength]="50" (onBlur)="updateAddress(true)"
                 labelCaption="{{'entity.cityname'|translate}}" labelClass="md">
                  <jbm-input-error [formControl]="formControls.invoice_city" [submitted]="isSubmitted">
                     {{'entity.requiredfield'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <fieldset><legend>{{'gps.location'|translate}}</legend>
                  <ngb-alert *ngIf="error_invoice_lonlat" [type]="'warning'" [dismissible]="false">{{error_invoice_lonlat}}</ngb-alert>
                  <jbm-text-input [formControl]="formControls.invoice_lon" [id]="'address.lon'" [submitted]="isSubmitted" class="xxs"
                    [maxlength]="13" labelCaption="{{'gps.longitude'|translate}}" labelClass="width-7">
                     <jbm-input-error [formControl]="formControls.invoice_lon" [submitted]="isSubmitted">
                        {{'gps.longitude-latitude-format'|translate}}
                     </jbm-input-error>
                  </jbm-text-input>
                  <jbm-text-input [formControl]="formControls.invoice_lat" [id]="'address.lat'" [submitted]="isSubmitted" class="xxs"
                    [maxlength]="12" labelCaption="{{'gps.latitude'|translate}}" labelClass="width-7">
                     <jbm-input-error [formControl]="formControls.invoice_lat" [submitted]="isSubmitted">
                        {{'gps.longitude-latitude-format'|translate}}
                     </jbm-input-error>
                  </jbm-text-input>
                  <div *ngIf="formControls.invoice_lat.value && formControls.invoice_lon.value" class="form-group">
                     <div class="col-form-label width-7 float-start">&nbsp;</div>
                     <div class="float-start ml-2">
                        <jbm-gps-location-button
                          [latitude]="GPS_invoice_address.latitude"
                          [longitude]="GPS_invoice_address.longitude"
                          title="{{'customer'|translate}}&nbsp;{{'customer.invoice-address'|translate|lowercase}}"
                          [address]="formControls.invoice_street.value+'&nbsp;'+formControls.invoice_no.value+', '+formControls.invoice_city.value"
                          context="outline-secondary">
                        </jbm-gps-location-button>
                     </div>
                  </div>
               </fieldset>
            </div>

            <div class="form-section-separator md-md mt-3">{{'entity.settings'|translate}}</div>
            <div class="form-group row">
               <label class="md col-form-label"></label>
               <div class="sm">
                  <div class="form-check form-check-inline">
                     <input type="checkbox" class="form-check-input" id="setting"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="resources_based_invoicing" (change)="setResourcesBasedInvoicing($event)">
                     <label class="form-check-label" for="setting">&nbsp;{{'invoicing.on-resources-based'|translate}} {{'invoice.invoicing'|translate|lowercase}}</label>
                  </div>
               </div>
            </div>
            <div class="form-group row">
               <label class="md col-form-label"></label>
               <div class="sm">
                  <div class="form-check form-check-inline">
                     <input type="checkbox" class="form-check-input" id="shift_vat"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="shift_vat_setting" (change)="setShiftVatSetting($event)">
                     <label class="form-check-label" for="shift_vat">&nbsp;{{'customer.shift-vat-setting'|translate}}</label>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</form>
</ng-template>
<jbm-toasts></jbm-toasts>