import {Component, HostListener, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Location} from "@angular/common";
import {StateService} from "../../../../services/storage/state.service";
import {BodyClassTogglerService} from "../../../../services/interface/body-class-toggler.service";
import Map from 'ol/Map';
import OsmSource from 'ol/source/OSM';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import {Tile as TileLayer, Vector as VectorLayer} from 'ol/layer';
import {fromLonLat} from 'ol/proj';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Feature from 'ol/Feature';
import {Icon, Style} from 'ol/style';
import {Subscription} from "rxjs";
import {GPSCoordinates} from "../../Helpers/GPSInfo";

@Component({
  selector: 'jbm-location-map',
  templateUrl: './JBMLocationMap.component.html',
  styleUrls: ['./JBMLocationMap.component.scss']
})
export class JBMLocationMapComponent implements OnInit, OnDestroy {
  GPSCoordinates: GPSCoordinates={longitude: '', latitude: ''};
  title: string='';
  address: string='';
  map: any;

  param$: Subscription;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private Router: Router,
      private Location: Location,
      private eRef: ElementRef,
      private StateService: StateService,
      private BodyClassTogglerService: BodyClassTogglerService,
  ) {}

  ngOnInit(): void {
    this.param$=this.ActivatedRoute.params.subscribe(params => {
      this.GPSCoordinates.longitude=params.longitude;
      this.GPSCoordinates.latitude=params.latitude;
      this.BodyClassTogglerService.checkClass('aside-right');
        // Get title from State
        let stateElements=this.StateService.getElements();
        for(let stateElement of stateElements)
            if(stateElement.name===params.longitude+','+params.latitude) {
                this.title=stateElement.data.title;
                this.address=stateElement.data.address;
            }

        this.StateService.clear();

      this.createMap();
    },()=>{
      this.close();
    });
  }
  createMap() {
   const div = document.querySelector('#map');
   div.innerHTML='<div id="popup"></div>';

   this.map=null;
   this.map= new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new OsmSource()
        })
      ],
      view: new View({
        center: fromLonLat([this.GPSCoordinates.longitude, this.GPSCoordinates.latitude]),
        zoom: 18
      })
    });

    var marker = new Overlay({
      position: fromLonLat([this.GPSCoordinates.longitude, this.GPSCoordinates.latitude]),
      positioning: 'center-center',
      element: document.getElementById('ol-marker'),
      stopEvent: false,
    });
    this.map.addOverlay(marker);
  }
  close() {
    this.Router.navigate([{ outlets: { 'ar': null }} ],{ relativeTo: this.ActivatedRoute.parent });
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target)) {
      console.log('test');
      this.close();
    }
  }
  ngOnDestroy() {
    if(this.param$!=undefined) this.param$.unsubscribe();
    this.BodyClassTogglerService.removeClass('aside-right');
  }
}
