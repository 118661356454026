import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BodyClassTogglerService {
  body: any;
  constructor() {
    this.body=document.getElementsByTagName('body')[0];
  }
  containsClass(className: string) {
    return this.body.classList.contains(className);
  }
  toggleClass(className: string) {
    if(this.body.classList.contains(className))
      this.body.classList.remove(className);
    else
      this.body.classList.add(className);
  }
  toggleClasses(classNames: string[]) {
    for(let c=0; c<classNames.length, c++;)
      this.toggleClass(classNames[c]);
  }
  checkClass(className: string) {
    if(!this.body.classList.contains(className))
      this.body.classList.add(className);
  }
  removeClass(className: string) {
    if(this.body.classList.contains(className))
      this.body.classList.remove(className);
  }
  toggleBackdrop() {
    this.toggleClass('show-backdrop');
  }
  toggleModal(popupClassName: string='') {
    this.toggleClass('jbm-modal-open');
    if(popupClassName) this.toggleClass(popupClassName);
  }
}
