import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslationsRoutingModule } from './translations-routing.module';
import { TranslationsComponent } from './translations.component';
import { SharedModule } from "../../shared/shared.module";
import { FormsModule} from "@angular/forms";
import { JBMButtonsModule } from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [TranslationsComponent],
    imports: [CommonModule, SharedModule, TranslationsRoutingModule, FormsModule, JBMButtonsModule, JBMViewModule, JBMTableModule, JBMFormModule, LayoutModule]
})
export class TranslationsModule {}
