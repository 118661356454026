import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {QuotationsService} from "../data/quotations.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {PdfService} from "../../../services/pdf-make/pdf-service";
import {Quotation} from "../data/interfaces/quotation";

@Component({
  selector: 'quotation-preview',
  templateUrl: './quotation-preview.component.html',
  styleUrls: ['./quotation-preview.component.scss'],
  providers: [PdfService]
})
export class QuotationPreviewComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  pdfMakeTemplate: string;
  quotation: Quotation;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private QuotationsService: QuotationsService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private PdfService: PdfService,
      private Title: Title
  ) {
    // The route resolver has fetched the data
    this.quotation=this.ActivatedRoute.snapshot.data.quotation.data;

    this.Title.setTitle('PDF '+this.TranslateService.GetTranslation('ui.preview').toLowerCase());
  }

  ngOnInit(): void {
    this.QuotationsService.compose_pdf(this.language, this.quotation.revision_id).subscribe(
     (data)=>this.pdfMakeTemplate=data.data,
        (error)=>console.error(error),
        ()=>
            this.PdfService.loadPdfMaker().then( ()=>
                  this.PdfService.pdfMake.createPdf(this.pdfMakeTemplate).getDataUrl(function(outDoc) {
                    (document.getElementById('pdfObject') as HTMLObjectElement).data=outDoc;
                  })
            )
    )
  }
}
