<app-header>
    <jbm-view-title *ngIf="!historyView" [caption]="'menu.invoicing'|translate" [iconClassname]="'calculator'"
      [itemcount]="queryHttpHelper.paginationProps.itemCount">
    </jbm-view-title>
    <jbm-view-title *ngIf="historyView" [caption]="('entity.invoice'|translate)+('entity.history'|translate|lowercase)" [iconClassname]="'calculator'"
      [itemcount]="queryHttpHelper.paginationProps.itemCount">
    </jbm-view-title>
    <div *ngIf="!historyView" class="toolbar float-start">
        <div *ngIf="userRights.AllowCreate && !historyView" ngbDropdown container="body" [placement]="'bottom'">
            <button class="btn btn-primary" id="createActions" ngbDropdownToggle (click)="$event.stopPropagation();">
                <i class="fa fa-plus"></i>
            </button>
            <div ngbDropdownMenu aria-labelledby="createActions">
                <button ngbDropdownItem (click)="createCustomer()">
                    <i class="fa-address-book fas"></i>&nbsp;
                    {{'entity.customer'|translate}} {{'entity.invoice'|translate|lowercase}} {{'ui.generate'|translate|lowercase}}...
                </button>
                <button ngbDropdownItem (click)="createProject()">
                    <i class="fa-cogs fas"></i>&nbsp;
                    {{'entity.project'|translate}} {{'entity.invoice'|translate|lowercase}} {{'ui.generate'|translate|lowercase}}...
                </button>
            </div>
        </div>
    </div>
    <div [hidden]="!initialCount" class="toolbar float-start">
        <div class="toolbar-spacer-2"></div>
        <label>{{'company.plant'|translate}}</label>
        <select (change)="changePlant($event)" class="form-control form-control-sm form-select">
            <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
        </select>
    </div>
    <div [hidden]="!initialCount" class="toolbar float-start">
        <div class="toolbar-spacer-2"></div>
        <jbm-invoicestate-dropdown
          [invoiceStates]="invoiceStates"
          [invoiceState]="invoiceState"
          (invoiceStateChanged)="setInvoiceState($event)">
        </jbm-invoicestate-dropdown>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1"></div>
        <jbm-pagination [hidden]="!initialCount" [itemCount]="queryHttpHelper.paginationProps.itemCount"
            [pageSize]="queryHttpHelper.paginationProps.pageSize"
            [page]="queryHttpHelper.paginationProps.page"
            (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [id]="'search-box'" [hidden]="!initialCount"  [visible]="searchboxVisble && !filtersVisible" [search]="search"
          [minlength]="3" (searchChange)="searchChange($event)">
        </jbm-searchbox>
        <div [hidden]="!initialCount" class="toolbar-spacer-1 float-end"></div>
        <div [hidden]="!initialCount" class="btn-group float-end">
            <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
            <jbm-table-layout [caption]="'ui.columns-layout'|translate"
              [columnDefs]="columnDefs" (columnToggled)="saveLayout()"></jbm-table-layout>
        </div>
        <div class="toolbar-spacer-2 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section id="invoices-table" class="module">
    <div *ngIf="!historyView" class="bg-blue-extra-light clearfix">
        <div class="toolbar float-end">
            <button type="button" class="btn btn-primary btn-sm" (click)="showInvoiceableReceipts()">{{'invoice.invoiceable'|translate}} {{'menu.production'|translate|lowercase}}{{'production.receipts'|translate|lowercase}}</button>
            <div class="toolbar-spacer-1 float-end"></div>
            <a class="btn btn-sm btn-link" target="_blank" href="invoicing-history">
                {{'entity.invoice'|translate}}{{'entity.history'|translate|lowercase}}
                <i class="fa fa-external-link-alt"></i>
            </a>
            <div class="toolbar-spacer-1 float-end"></div>
        </div>
    </div>
    <div *ngIf="invoiceStateID==150 && items.length" class="p-1 pt-2 pl-4 pr-4">
        <h5 class="d-inline-block mr-4">{{'ui.validation'|translate}}</h5>
        <label class="mr-2">{{'ui.share-link'|translate}}:</label>
        <label class="fw-bold mr-4">{{baseURL}}/invoicing/(modal:invoicing/validation)</label>
        <button type="button" class="btn btn-sm btn-light" (click)="validateInvoices()">
            {{'entity.invoices'|translate}} {{'ui.validate'|translate|lowercase}}
        </button>
    </div>
    <jbm-table #table [classname]="'invoices padding'"
      [data]="items"
      [filterable]="true"
      [showFilters]="filtersVisible"
      [searchMinLength]="3"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="true"
      [columnDefs]="columnDefs"
      (rowCountChange)="rowCountChange($event)"
      (sortChange)="sortChange($event)"
      (filterChange)="filterChange($event)"
      (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>
<jbm-toasts></jbm-toasts>

<ng-template #templateValidation let-data="data">
    <button class="btn btn-sm btn-primary" [ngClass]="{'mr-2':data.validation_status>0}" (click)="validateInvoice(data.id);$event.stopPropagation()">{{'ui.validate'|translate}}</button>
    <span *ngIf="data.validation_status==1" class="badge bg-success">{{'ui.approved'|translate}}</span>
    <span *ngIf="data.validation_status==2" class="badge bg-danger">{{'ui.rejected'|translate}}</span>
</ng-template>

<ng-template #templateCustomer let-data="data">
    <div class="customer">
        <table class="table table-borderless table-sm">
            <tr>
                <td class="pl-0">
                    <jbm-link-button (onClick)="showCustomer(data.customers_id)" [caption]="data.customer" [showIcon]="false"></jbm-link-button>
                </td>
                <td *ngIf="data.customer_blocked" class="pl-0 blocked"><span class="td-badge badge bg-danger">{{'entity.blocked'|translate}}</span></td>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #templateProject let-data="data">
    <jbm-link-button *ngIf="data.projects_id" (onClick)="showProject(data.projects_id)" [caption]="data.project" [showIcon]="false"></jbm-link-button>
</ng-template>

<ng-template #templateInvoiceState let-data="data">
    <div class="invoice-state">
        <span class="badge badge-xl badge-pill float-start" style="background-color: {{data.color}};color: {{data.text_color}}">
            {{data.invoicestate}}
        </span>
    </div>
</ng-template>

<ng-template #templateTotal let-data="data">
    <div *ngIf="data.totalprice_incl_vat" ngbDropdown container="body" [placement]="'left'" class="total">
        <button class="btn btn-sm btn-light btn-block" id="invTotal" ngbDropdownToggle (click)="$event.stopPropagation();">
            {{data.totalprice_incl_vat|money}}
        </button>
        <div ngbDropdownMenu aria-labelledby="invTotal" class="p-3">
            <table class="table table-sm table-borderless">
                <tr>
                    <td>{{'financial.tax'|translate}} ({{data.vat_rate}}%):</td>
                    <td>{{data.vat|money}}</td>
                </tr>
                <tr *ngIf="data.outstanding && data.invoice_states_id>=300 && data.invoice_states_id<600">
                    <td>{{'entity.outstanding'|translate}}:</td>
                    <td>{{data.outstanding|money}}</td>
                </tr>
                <tr *ngIf="data.paid && data.invoice_states_id>=300">
                    <td>{{'entity.paid'|translate}}:</td>
                    <td>{{data.paid|money}}</td>
                </tr>
                <tr *ngIf="data.concrete_amount"><td colspan="2"><hr class="mt-0 mb-0" /></td></tr>
                <tr *ngIf="data.concrete_amount"><td colspan="2"><span class="fw-bold p-0">{{'financial.concrete'|translate}}</span></td></tr>
                <tr *ngIf="data.concrete_amount">
                    <td>{{'order.amount'|translate}}:</td>
                    <td>{{data.concrete_amount | float:0 }} {{'quantity.m3'|translate}}</td>
                </tr>
                <tr *ngIf="data.concrete_amount">
                    <td>{{'ui.totalprice'|translate}}:</td>
                    <td>{{data.concrete_total|money}}</td>
                </tr>
                <tr *ngIf="data.articles_total>0"><td colspan="2"><hr class="mt-0 mb-0" /></td></tr>
                <tr *ngIf="data.articles_total>0"><td colspan="2"><span class="fw-bold p-0">{{'financial.articles'|translate}}</span></td></tr>
                <tr *ngIf="data.articles_total>0">
                    <td>{{'ui.totalprice'|translate}}:</td>
                    <td>{{data.articles_total|money}}</td>
                </tr>
            </table>
        </div>
    </div>
</ng-template>

<ng-template #templatePreview let-data="data">
    <a *ngIf="data.totalprice" class="btn btn-sm btn-link" target="_blank" [ngbTooltip]="'PDF '+('ui.preview'|translate|lowercase)"
      [href]="'invoice-preview/'+data.id" (click)="$event.stopPropagation()">
        <i class="far fa-file-pdf"></i> <i class="fa fa-external-link-alt ml-1"></i>
    </a>
</ng-template>

<ng-template #actionsTemplate let-data="data">
    <div *ngIf="!historyView" ngbDropdown container="body" [placement]="'left'">
        <button class="actions btn btn-sm btn-light" id="invActions" ngbDropdownToggle (click)="$event.stopPropagation();">
            <i class="fa fa-ellipsis-h"></i>
        </button>
        <div ngbDropdownMenu aria-labelledby="invActions">
            <button *ngIf="userRights.AllowUpdate && data.allowValidate" ngbDropdownItem (click)="validate(data)">{{'ui.validate'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowApprove" ngbDropdownItem (click)="approve(data)">{{'entity.approve'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowConcept" ngbDropdownItem (click)="makeConcept(data)">{{'entity.make-concept'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowSend" ngbDropdownItem (click)="send(data)">{{'entity.send'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowPayment" ngbDropdownItem (click)="handlePayment(data.id)">{{'invoice.handle-payment'|translate}}...</button>
            <div *ngIf="userRights.AllowUpdate && data.allowValidate || userRights.AllowUpdate && data.allowApprove || userRights.AllowUpdate && data.allowConcept ||
            userRights.AllowUpdate && data.allowSend || userRights.AllowUpdate && data.allowPayment" class="dropdown-divider"></div>
            <button *ngIf="userRights.AllowUpdate && data.allowReject" ngbDropdownItem (click)="reject(data)">{{'entity.invoice'|translate}} {{'ui.cancel'|translate|lowercase}}...</button>
            <button *ngIf="userRights.AllowDelete && data.allowRemove" ngbDropdownItem (click)="remove(data)">{{'ui.delete'|translate}}...</button>
            <div *ngIf="userRights.AllowUpdate && data.allowReject || userRights.AllowUpdate && data.allowRemove" class="dropdown-divider"></div>
            <button *ngIf="userRights.AllowUpdate && data.allowCopy" ngbDropdownItem (click)="copy(data.id)">{{'ui.copy'|translate}}...</button>
            <button *ngIf="userRights.AllowUpdate && data.allowSettings" ngbDropdownItem (click)="settings(data.id)">{{'entity.settings'|translate}}...</button>
            <button ngbDropdownItem (click)="showStateFlow(data.id, data.invoice_number)"><i class="fa fa-history"></i> {{'invoice.state'|translate}} {{'entity.history'|translate|lowercase}}</button>
        </div>
    </div>
</ng-template>

<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
    <project-details [project_id]="id" (onClose)="closeProject()"></project-details>
</ng-template>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>

<jbm-modal [id]="'statesFlow'" [className]="'statesFlow'"></jbm-modal>
<ng-template #modalStatesFlow let-modal>
    <invoice-states-flow [language]="language" [id]="id" [invoiceNumber]="invoiceNumber" (onClose)="statesFlowClose()"></invoice-states-flow>
</ng-template>

<jbm-modal [id]="'stateAction'" [className]="'state-action'"></jbm-modal>
<ng-template #modalValidate let-modal>
    <invoice-validation [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="validateSuccess()" (onClose)="stateActionClose()"></invoice-validation>
</ng-template>
<ng-template #modalApprove let-modal>
    <invoice-approve [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="approveSuccess()" (onClose)="stateActionClose()"></invoice-approve>
</ng-template>
<ng-template #modalCopy let-modal>
    <invoice-copy [invoice_id]="id" (onSuccess)="copySuccess($event)" (onClose)="stateActionClose()"></invoice-copy>
</ng-template>
<ng-template #modalMakeConcept let-modal>
    <invoice-make-concept [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="conceptSuccess()" (onClose)="stateActionClose()"></invoice-make-concept>
</ng-template>
<jbm-modal [id]="'invoiceSend'" [className]="'content invoice-send'"></jbm-modal>
<ng-template #modalSend let-modal>
    <invoice-send [invoice]="invoice" (onSuccess)="sendSuccess()" (onClose)="sendClose()"></invoice-send>
</ng-template>
<ng-template #modalHandlePayment let-modal>
    <invoice-handle-payment [invoice_id]="id" (onSuccess)="paymentSuccess()" (onClose)="stateActionClose()"></invoice-handle-payment>
</ng-template>
<ng-template #modalReject let-modal>
    <invoice-reject [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onSuccess)="rejectSuccess()" (onClose)="stateActionClose()"></invoice-reject>
</ng-template>
<jbm-modal [id]="'settings'" [className]="'settings'"></jbm-modal>
<ng-template #modalSettings let-modal>
    <invoice-settings [invoice_id]="id" (onSucces)="settingsSuccess()" (onClose)="settingsClose()"></invoice-settings>
</ng-template>
<ng-template #modalRemove let-modal>
    <invoice-remove [invoice_id]="invoice.id" [invoiceNumber]="invoice.invoice_number" (onRemove)="removeSuccess()" (onClose)="stateActionClose()"></invoice-remove>
</ng-template>


