<form [formGroup]="form" (ngSubmit)="save()" class="concrete-price">
    <div class="jbm-modal-header">
        <jbm-crud-title subject="financial.concrete-price" [action]="action"></jbm-crud-title>
        <div class="toolbar float-end">
           <jbm-activity-log-button *ngIf="id" subject_type="20"
             [subject_id]="id" subject="{{'financial.concrete-price'|translate}}"
             title="{{'financial.concrete-price'|translate}}">
           </jbm-activity-log-button>
           <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
           <div class="toolbar-spacer-1"></div>
           <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
      <jbm-properties-list className="concrete-pricelist" [properties]="priceListProps"></jbm-properties-list>
      <div *ngIf="id" class="jbm-properties-list concrete-pricelist">
          <div class="item">
             <label class="label"></label>
             <label class="value">
                <article-price-history-dropdown [subject]="1" [price_id]="id"></article-price-history-dropdown>
             </label>
          </div>
      </div>
      <hr />
      <ngb-alert *ngIf="error" [type]="'warning'" [dismissible]="false">{{error}}</ngb-alert>
       <jbm-select [formControl]="formControls.strengths_id" [options]="strengthOptions"
         [submitted]="isSubmitted" [id]="'strengths_id'" [required]="true" [insertLeadingOption]="true"
         labelClass="width-8" class="input-15" labelCaption="{{'concrete.strength'|translate}}"
         (onChange)="changeStrength($event)">
          <jbm-input-error [formControl]="formControls.strengths_id" [submitted]="isSubmitted">
             {{'entity.requiredfield'|translate}}
          </jbm-input-error>
       </jbm-select>
      <jbm-select [formControl]="formControls.environments_id" [options]="environmentOptions"
         [submitted]="isSubmitted" [id]="'environments_id'" [required]="true" [insertLeadingOption]="true"
         labelClass="width-8" labelCaption="{{'concrete.environment'|translate}}" class="input-15"
         (onChange)="changeEnvironment($event)">
          <jbm-input-error [formControl]="formControls.environments_id" [submitted]="isSubmitted">
              {{'entity.requiredfield'|translate}}
          </jbm-input-error>
      </jbm-select>
      <jbm-select [formControl]="formControls.consistencies_id" [options]="consistencyOptions"
         [submitted]="isSubmitted" [id]="'consistencies_id'" [required]="true" [insertLeadingOption]="true"
         labelClass="width-8" labelCaption="{{'concrete.consistency'|translate}}" class="input-15"
         (onChange)="changeConsistency($event)">
          <jbm-input-error [formControl]="formControls.consistencies_id" [submitted]="isSubmitted">
              {{'entity.requiredfield'|translate}}
          </jbm-input-error>
      </jbm-select>
      <hr />
      <jbm-float-input-group [formControl]="formControls.price" [submitted]="isSubmitted" [id]="'price'"
         required="true" labelClass="width-8" class="input-8" labelCaption="{{'concrete.price'|translate}}" prepend="&euro;">
          <jbm-input-error [formControl]="formControls.price" [submitted]="isSubmitted">
              <span *ngIf="formControls['price'].hasError('priceInvalid')">
                        {{'entity.priceformat'|translate}}</span>
          </jbm-input-error>
       </jbm-float-input-group>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="'financial.concrete-price'|translate" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>