import {Component, ElementRef,EventEmitter,Input,OnInit,Output,ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UsersService} from "../../data/users.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {LanguagesService} from "../../../../services/multilingual/languages.service";
import {TokenService} from "../../../../services/auth/token.service";
import {FormValidationService} from "../../../../services/validation/form.validation.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {User} from "../../data/interfaces/user";
import {GroupRights} from "../../../../features/users/data/interfaces/grouprights";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'form-recipe-user',
  templateUrl: './recipe-user.component.html',
  styleUrls: ['./recipe-user.component.scss']
})
export class RecipeUserComponent implements OnInit {
  action: CRUDAction;
  userRights: GroupRights=this.TokenService.getRightsByName('recipe-app');

  userForm: FormGroup;
  isSubmitted: boolean=false;

  @Input() user: User;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();
  @ViewChild('userPasswordModal') modalTemplate: ElementRef;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      public UserService: UsersService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private LanguagesService: LanguagesService,
      private ModalService: ModalService,
      private formBuilder: FormBuilder,
      private FormValidationService: FormValidationService,
  ) {
    this.addControls();
  }
  ngOnInit() {
    this.setControlValues();
  }
  ngOnChanges() {
    if(this.user.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;
  }
  ngAfterViewInit(): void {
    document.getElementById('username').focus();
  }
  addControls() {
    this.userForm = this.formBuilder.group(
        {
          username: new FormControl('',{updateOn: 'blur', validators: [Validators.required]} ),
          audience: new FormControl('',{updateOn: 'blur', validators: [Validators.required]} ),
          passwordNew: new FormControl('',[this.FormValidationService.passwordValidator(8,false)]),
          passwordConfirm: new FormControl('',[this.FormValidationService.passwordValidator(8,false)])
        }
    );
  }
  setControlValues() {
    this.userForm.controls.username.setValue(this.user.username);
    this.userForm.controls.audience.setValue(this.user.audience);
    this.userForm.controls.passwordNew.setValue('');
    this.userForm.controls.passwordConfirm.setValue('');
  }
  get formControls() {
    return this.userForm.controls;
  }
  passwordChange() {
    this.ModalService.open(this.modalTemplate,'userpassword');
  }
  onPasswordChange() {
    this.ModalService.close('userpassword');
  }
  onPasswordChangeCancel() {
    this.ModalService.close('userpassword');
  }
  save() {
    this.isSubmitted = true;

    if(this.userForm.invalid)
      return;

    // Check confirmed password
    if(this.formControls.passwordNew.value!=this.formControls.passwordConfirm.value)
      this.formControls.passwordConfirm.setErrors({confirmInvalid: true});

    // Store user
    let data = {
      id: this.user.id,
      username: this.formControls.username.value,
      audience: this.formControls.audience.value,
      password: this.formControls.passwordNew.value
    }

    if(data.id===0)
      this.UserService.create(data).subscribe(
          ()=>{},error => this.handleError(error),() => this.afterSave())
    else
      this.UserService.update(data).subscribe(
          ()=>{},error => this.handleError(error),() => this.afterSave())
  }
  afterSave() {
    this.onSave.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
  handleError(error) {
    if(error=='not_unique')
      this.formControls.username.setErrors({ notUnique: true});
  }
}