import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";
import { presentation_output_types} from "./output-types.service";

const baseURL: string = 'presentations/';
const segment: string = 'uses/';

@Injectable({
  providedIn: 'root'
})
export class PresentationUsesService {
  constructor(private HttpService: HttpService) {}

  get(subject: number, subject_id: number, presentation_output_type: presentation_output_types) {
    return this.HttpService.get(baseURL+'read/'+segment+subject.toString()+'/'+subject_id.toString()+'/'+presentation_output_type,null );
  }
  create(subject: number, subject_id: number, presentation_id: number) {
    return this.HttpService.post(baseURL+'create/'+segment,{
      subject: subject,
      subject_id: subject_id,
      presentation_id: presentation_id
    });
  }
  deleteFromSubject(subject: number, subject_id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+subject.toString()+'/'+subject_id.toString());
  }
}
