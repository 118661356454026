import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
  HostListener,
  ElementRef,
  OnChanges, ViewChild
} from '@angular/core';
import {DriversService} from "../../../../features/logistics/data/drivers.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMTableColumnDef, JBMTableColumnType, JBMTableComponent} from "../../../JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {Driver} from "../../../../features/logistics/data/interfaces/driver";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'driver-selector',
  templateUrl: './driver.selector.component.html',
  styleUrls: ['./driver.selector.component.scss']
})
export class DriverSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  driver: Driver=this.DriversService.getEmpty();
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;

  @Input() driver_id: number=0;

  @Output() driverSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  @Input() template: TemplateRef<any>;

  @ViewChild('table') table: JBMTableComponent;

  constructor(
      private DriversService: DriversService,
      private TranslateService: TranslateService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'), sortable: true, filterable: true },
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.driver_id) this.setDriver();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('driver-search').focus() }, 100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.driver_id=0;
    this.driver=this.DriversService.getEmpty();
    this.hideList();
    this.driverSelected.emit(this.driver );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.DriversService.get(params).subscribe(
        (data: any) => this.items = data.data,(error)=>console.error(error),()=> {
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.DriversService.get(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  setDriver() {
    let params=new HttpParams().append('id', this.driver_id.toString());
    this.DriversService.get(params).subscribe(
        (data: any)=>this.driver=data.data as Driver,
        (error)=>console.error(error)
    )
  }
  rowSelect(row) {
    this.driver=row;
    this.driver_id=row.id;
    this.driverSelected.emit(this.driver);
    this.hideList();
  }

  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }

  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
