import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {ArticlePricelistsService, PricelistsType} from "../../data/article-pricelists.service";
import {CustomerPricelistsService } from "../../data/customer-pricelists.service";
import {CustomerService} from "../../../customers/data/customer.service";
import {CustomerSelectListComponent} from "../../customers/selectlist/customer_selectlist.component";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Customer} from "../../../customers/data/interfaces/customer";
import {ArticlePricelist} from "../../data/interfaces/article-pricelist";

@Component({
  selector: 'form-copy-concrete-pricelist',
  templateUrl: './copy_pricelist.component.html',
  styleUrls: ['./copy_pricelist.component.scss']
})

export class CopyPricelistComponent implements OnInit, AfterViewInit {
  customer: Customer=this.CustomerService.getEmpty();
  language: string;
  editable: boolean=false;
  valid_from: string;
  warning: string;
  customerWarning: string;
  priceListProps: any[]=[];
  customerError: string='';
  destinations: JBMSelectOption[]=[];
  destination: number=0;
  selectList: boolean;
  form: FormGroup;
  firstinput: any;
  isSubmitted: boolean=false;

  @Input() pricelistsType: PricelistsType=PricelistsType.articles;
  @Input() pricelist: ArticlePricelist;
  @Input() customer_id: number=0;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  @ViewChild('content') modalTemplate: ElementRef;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private Location: Location,
    private formBuilder: FormBuilder,
    private ArticlePricelistsService: ArticlePricelistsService,
    private CustomerArticlePricelistsService: CustomerPricelistsService,
    private CustomerService: CustomerService,
    private CustomerSelectListComponent: CustomerSelectListComponent,
    private TranslateService: TranslateService,
    private TokenService: TokenService,
    private DatetimeService: DatetimeService,
    private JBMToastsService: JBMToastsService
  ) {
    this.pricelist=this.ArticlePricelistsService.getEmpty();
    this.customer=this.CustomerService.getEmpty();
  }
  ngOnInit(): void {
    this.language=this.TokenService.getLanguage();
    this.warning='';

    this.destinations=[
      {key: '0', value: this.TranslateService.GetTranslation('pricelist.copy-for-a-new-year')},
      {key: '1', value: this.TranslateService.GetTranslation('pricelist.copy-for-a-customer') }
    ];

    // Set pricelist properties
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('financial.concrete-pricelist'), value: this.pricelist.description });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'),
      value: this.ArticlePricelistsService.getTypeAsString(this.pricelist.type, this.TranslateService)});

    this.addControls();
    this.setControlValues();
  }
  ngAfterViewInit(): void {
    this.firstinput=document.getElementById('destination');
    this.firstinput.focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      description: new FormControl('',[Validators.required]),
      valid_from: new FormControl('',[this.DatetimeService.dateValidator(this.DatetimeService,true)]),
      valid_to: new FormControl('',[this.DatetimeService.dateValidator(this.DatetimeService,true)]),
      customer_id: new FormControl('')
    });
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.pricelist.valid_from=this.DatetimeService.getDateDMYFromParts(1,1,this.DatetimeService.getCurrentYear()+1);
    this.pricelist.valid_to=this.DatetimeService.getDateDMYFromParts(31,12, this.DatetimeService.getCurrentYear()+1);
    this.form.controls.description.setValue(this.pricelist.description);
    this.form.controls.valid_from.setValue(this.pricelist.valid_from);
    this.form.controls.valid_to.setValue(this.pricelist.valid_to);
  }
  changeDestination(event) {
    this.destination=parseInt(event.target.value);
    let year=this.destination==1 ? this.DatetimeService.getCurrentYear() : this.DatetimeService.getCurrentYear()+1;
    this.form.controls.valid_from.setValue( this.DatetimeService.getDateDMYFromParts(1,1,year ) );
    this.form.controls.valid_to.setValue( this.DatetimeService.getDateDMYFromParts(31,12, year ) );
  }
  setCustomer(event) {
    this.formControls.customer_id.setValue(event.id);
    this.customerError = '';
  }
  save() {
    this.isSubmitted = true;

    // Check date range
    let valid_from=this.DatetimeService.dateDMYToYMD(this.formControls.valid_from.value);
    let valid_to  =this.DatetimeService.dateDMYToYMD(this.formControls.valid_to.value);
    if(valid_from>valid_to) {
      this.warning=this.TranslateService.GetTranslation('entity.to-date-before-from-date');
      return;
    }

    // Check customer
    if(this.destination==1 && this.customer.id==0) {
      this.customerWarning=this.TranslateService.GetTranslation('customers.select-warning');
      return;
    }

    if(this.form.invalid)
      return;

    let data={
      valid_from: valid_from,
      valid_to: valid_to,
      type: this.pricelist.type,
      l: {language: this.language, description: this.formControls.description.value},
    }

    if(this.destination==0)
      // Copy for a new year
      this.ArticlePricelistsService.copyNewYear(this.pricelistsType, this.pricelist.id, data).subscribe(
          data => {}, error => console.error(error),() => this.afterSave())
    else
      // Copy for a customer
      this.CustomerArticlePricelistsService.copy(this.pricelistsType, this.pricelist.id, this.customer.id, data).subscribe(
      data => {}, error => console.error(error),() => this.afterSave())
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.onSave.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
}
