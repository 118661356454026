<jbm-create-button *ngIf="userRights.AllowUpdate" [class]="'mb-1'" (click)="create()"></jbm-create-button>
<table class="table table-sm table-row-selectable quotation-items mb-3">
   <thead>
      <tr>
         <th class="description">{{'entity.description'|translate}}</th>
         <th class="section">{{'ui.section'|translate}}</th>
         <th class="amount align-right">{{'order.amount'|translate}}</th>
         <th class="price-type"></th>
         <th class="price align-right">{{'article.pricetype-price'|translate}}</th>
         <th class="totalprice align-right">{{'ui.totalprice'|translate}}</th>
         <th class="actions align-right"></th>
      </tr>
   </thead>
   <tbody>
   <ng-container *ngFor="let item of items">
      <tr (click)="editItem(item.id)">
         <td *ngIf="item.order.recipes_id">
            <jbm-link-button [caption]="item.order.recipe.code" class="pr-1" (onClick)="showRecipe(item.order.recipes_id, item.environment.description)"></jbm-link-button>
            ({{'ui.number'|translate|lowercase}} {{item.order.recipes_id}})
         </td>
         <td *ngIf="!item.order.recipes_id" class="fw-500">
            {{item.strength.description}} {{item.environment.description}} {{item.consistency.description}}
         </td>
         <td class="p-1">
            <span class="badge bg-light text-dark">{{'concrete.mortal'|translate}}</span>
         </td>
         <td class="align-right">{{item.amount|float}}</td>
         <td class="pl-1">{{'quantity.m3'|translate}}</td>
         <td class="align-right">
            <jbm-concrete-pricerules-breakdown-popover
              [project_id]="quotation.projects_id"
              [customer_id]="quotation.customers_id"
              [strength_id]="item.strengths_id"
              [consistency_id]="item.consistencies_id"
              [environment_id]="item.environments_id"
              [price]="item.concrete_price"
              [class]="'btn-sm'">
            </jbm-concrete-pricerules-breakdown-popover>
         </td>
         <td class="align-right">{{item.totalprice|money}}</td>
         <td *ngIf="editable" class="action-buttons align-right p-1">
            <jbm-confirm-delete-dropdown
              [subject]="('entity.quotation'|translate)+' '+('ui.item'|translate|lowercase)"
              [placement]="'top-right'"
              [class]="'float-end ml-2 mr-0'"
              (onConfirm)="delete(item.id)">
            </jbm-confirm-delete-dropdown>
            <jbm-edit-button *ngIf="userRights.AllowUpdate" (onClick)="editItem(item.id)"></jbm-edit-button>
         </td>
      </tr>
      <tr *ngIf="viewSettings.orders" (click)="editItem(item.id)">
         <td>
            <jbm-link-button [caption]="item.description" (onClick)="showOrder(item.order.id)"></jbm-link-button>
            <span class="ml-1 hover-tooltip" [ngbTooltip]="'order.worktype'|translate">({{item.worktype.description}})</span>
         </td>
         <td class="p-1">
            <span class="badge bg-light text-dark">{{'project.order'|translate}}</span>
         </td>
         <td colspan="5"></td>
      </tr>
      <tr *ngIf="viewSettings.orders && viewSettings.comments && item.comments" class="borderless" (click)="editItem(item.id)">
         <td class="pt-0"><jbm-comments-box [comments]="item.comments" height="2.75"></jbm-comments-box></td>
         <td colspan="6"></td>
      </tr>
      <ng-container *ngFor="let article of item.articles">
         <tr *ngIf="viewSettings.articles" class="borderless" (click)="editArticle(article.id)">
            <td *ngIf="!article.editing" [ngClass]="{editable: article.editing}">{{article.description}}</td>
            <td *ngIf="article.editing">
               <input class="form-control form-control-sm description" type="text" autocomplete="off"
                 [(ngModel)]="description" (change)="onChangeDescription()"
                 [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':this.descriptionTouched && !validateDescription()}">
            </td>
            <td class="p-1">
               <span class="badge bg-light text-dark">
                  {{'ui.required'|translate}} {{'financial.article'|translate|lowercase}}
               </span>
            </td>
            <td *ngIf="!article.editing" class="align-right">{{article.amount}}</td>
            <td *ngIf="article.editing && article.article_price_types_id!=58" class="editable">
               <input float-only-input class="form-control form-control-sm align-right" [id]="'article-'+article.id" type="text" autocomplete="off"
                 [(ngModel)]="amount" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
            </td>
            <td *ngIf="article.editing && article.article_price_types_id==58" class="editable">
               <input digits-only-input class="form-control form-control-sm align-right" [id]="'article-'+article.id" type="text" autocomplete="off"
                 [(ngModel)]="amount" [maxLength]="3" [minLength]="1" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
            </td>
            <td class="pl-1">{{article.price_type.code}}</td>

            <td class="align-right" [ngClass]="{editable: article.editing}">
               <jbm-article-pricerules-breakdown-popover *ngIf="article.price"
                 [subject]="subject" [subject_id]="article.id" [price]="article.price" [class]="'btn-xs'">
               </jbm-article-pricerules-breakdown-popover>
            </td>
            <td *ngIf="!article.editing" class="align-right">{{article.totalprice|money}}</td>
            <td *ngIf="article.editing" class="align-right editable"><span *ngIf="totalPrice">{{totalPrice|money}}</span></td>

            <td *ngIf="editable && !article.editing" class="editable action-buttons align-right p-1">
               <jbm-edit-button *ngIf="userRights.AllowUpdate" (onClick)="editArticle(article.id)"></jbm-edit-button>
            </td>
            <td *ngIf="article.editing" class="editable action-buttons align-right p-1">
               <jbm-cancel-button class="float-end mr-0" [showCaption]="false" (click)="cancelArticle()"></jbm-cancel-button>
               <jbm-save-button [disabled]="!validateDescription() || !validateAmount()" class="float-end mr-1" [showCaption]="false" (click)="saveArticle()"></jbm-save-button>
            </td>
         </tr>
      </ng-container>
   </ng-container>
   </tbody>
</table>

<jbm-modal [id]="'quotationItem'" [className]="'quotation-item'"></jbm-modal>
<ng-template #modalItem let-modal>
   <form-quotation-item
     [quotation_revision_id]="quotation.revision_id"
     [id]="quotation_item_id"
     (onSave)="onSaveItem()"
     (onCancel)="onCancelItem()">
   </form-quotation-item>
</ng-template>

<jbm-modal [id]="'itemRecipe'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [delivery_date]="" [recipe_id]="recipe_id" [environment]="environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<jbm-modal [id]="'orderDetails'" [className]="'order-details-shared'"></jbm-modal>
<ng-template #modalOrderDetails let-modal>
   <order-details [order_id]="order_id" (onClose)="closeOrder()"></order-details>
</ng-template>
