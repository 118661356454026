import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {QuotationFlow} from "./interfaces/quotation-flow";

const baseURL: string = 'quotations/';
const segment: string = 'state-flow/';

@Injectable({
  providedIn: 'root'
})
export class QuotationFlowsService {
  quotation_flow: QuotationFlow;

  constructor(private HttpService: HttpService) {}

  get(language: string, quotaton_revision_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+quotaton_revision_id.toString(), params);
  }
  getEmpty() {
    this.quotation_flow = {
      id: 0,
      quotaton_revisions_id: 0,
      quotation_states_id: 0,
      date: '',
      time: '',
      comments: ''
    }
    return this.quotation_flow;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
}
