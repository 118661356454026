import { Injectable } from '@angular/core';
import { TranslateService } from "../../../services/multilingual/translate.service";
import { presenterConfig } from "../../../config/presenter"
import {Project} from "../data/interfaces/project";

@Injectable({
  providedIn: 'root'
})
export class ProjectPresenter {

  constructor(private TranslateService: TranslateService) {}

  format( project: Project) {
    let data={distance: presenterConfig.emptyString, traveltime: presenterConfig.emptyString };

    let km =this.TranslateService.GetTranslation('geo.km-abbr');
    let min=this.TranslateService.GetTranslation('datetime.minutes-abbr');

    if(project.distance!==null) data.distance=project.distance + ' ' + km;
    if(project.traveltime!==null) data.traveltime=project.traveltime + ' ' + min;

    return data;
  }
}
