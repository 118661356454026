import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMTableDisplayValue} from "../../../components/JBM/JBMTable/JBMTable.component";

const baseURL: string = 'financial/';
const slug: string = 'article-price-types/';

export enum displayFormat {
  codeOnly,
  descriptionOnly,
  codeWithDescription
}

@Injectable({
  providedIn: 'root'
})
export class ArticlePriceTypesService {

  constructor(private HttpService: HttpService) { }

  getData(language, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+language, params );
  }
  keyValueList(language) {
    let params=new HttpParams();
    params.append('sort', '%2dcode');
    return this.HttpService.get(baseURL+'read/'+slug+language, params );
  }
  getOne(id, language) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id+'/'+language );
  }
  getEmpty() {
    return this.HttpService.get(baseURL+'read/'+slug+'empty');
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }

  // Article price type options

  getSelectOptions( pricetypes: any[], format: displayFormat=displayFormat.codeWithDescription, insertEmptyOption: boolean=true ): JBMSelectOption[] {
    let options: JBMSelectOption[]=insertEmptyOption ? [{key:'',value:''}] : [];
    for(let pt=0; pt<pricetypes.length; pt++) {
      let pricetype=pricetypes[pt];
      options.push( { key: pricetype.article_price_types_id.toString(), value: this.getTypeValue(pricetype, format) } );
    }
    return options;
  }

  getValues(pricetypes: any[], format: displayFormat=displayFormat.codeWithDescription): JBMTableDisplayValue[] {
    let values: JBMTableDisplayValue[]=[];
    for(let pt=0; pt<pricetypes.length; pt++) {
      let pricetype=pricetypes[pt];
      values.push( { dataValue: pricetype.article_price_types_id, displayValue: this.getTypeValue(pricetype, format) } );
    }
    return values;
  }

  getAsString(pricetypes: any[], index: number, format: displayFormat=displayFormat.codeWithDescription) {
    if((index<0) || (index>=pricetypes.length))
      return '';
    return this.getTypeValue(pricetypes[index], format);
  }

  private getTypeValue(pricetype, format: displayFormat): string {
    switch(format) {
      case displayFormat.codeOnly: return pricetype.code;
      case displayFormat.descriptionOnly: return pricetype.description;
      case displayFormat.codeWithDescription:
        // Formatted as code (description)
        return pricetype.code===pricetype.description ? pricetype.code : pricetype.code + ' (' + pricetype.description + ')';
      default:
        return '';
    }
  }
}
