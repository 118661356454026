import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {PlantsService} from "../../../../features/company/data/plants.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnDef, JBMTableColumnType} from "../../../JBM/JBMTable/JBMTable.component";
import {FilterOperator, QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {Project} from "../../../../features/projects/data/interfaces/project";
import {Plant} from "../../../../features/company/data/interfaces/Plant";

@Component({
  selector: 'plant-selector',
  templateUrl: './plant.selector.component.html',
  styleUrls: ['./plant.selector.component.scss']
})
export class PlantSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  plant: Plant;
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;

  @Input() plant_id: number=0;
  @Output() plantSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Input() template: TemplateRef<any>;

  constructor(
      private PlantsService: PlantsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.name') }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.plant_id) this.setPlant();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      document.getElementById('plant-search').focus();
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.plant_id=0;
    this.hideList();
    this.plantSelected.emit( { id: 0 } );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.PlantsService.getData(params).subscribe(
      (data: any) => this.items=data.data,()=>{},()=>{
        if(countRows) {
          let params=this.queryHttpHelper.getHttpParams(true);
          this.count$=this.PlantsService.getData(params).subscribe(
           (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
        }
      } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getFilterValue('name') !== value) {
      this.queryHttpHelper.setFilterItem('name',value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    // Emit event
    this.plant_id=row.id;
    this.setPlant();
    this.hideList();
    this.plantSelected.emit( row );
  }
  setPlant() {
    this.PlantsService.getOne(this.plant_id).subscribe(
        (data: any)=>this.plant=data.data as Plant,
        (error)=>console.error(error)
    )
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
