import { Injectable } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {WorkType} from "./interfaces/worktype";

const baseURL: string = 'projects/';
const slug: string = 'worktypes/';

@Injectable({
  providedIn: 'root'
})
export class WorktypesService {

  constructor(private HttpService: HttpService) { }

  get(language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+language, params);
  }
  getOne(id: number, language: string) {
    let params=new HttpParams().append('subject_id', id.toString());
    return this.HttpService.get(baseURL+'read/'+slug+language, params);
  }
  getSelectOptions(worktypes: WorkType[], includeEmptyOption: boolean=false): JBMSelectOption[] {
    let options: JBMSelectOption[]=[];
    if(includeEmptyOption) options.push({key: '', value: ''});
    for(let wt=0; wt<worktypes.length; wt++)
      options.push({key: worktypes[wt].id.toString(), value: worktypes[wt].description});
    return options;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
}
