import {Component, EventEmitter, Input, Output, OnInit} from '@angular/core';
import {TranslateService} from "../../../../services/multilingual/translate.service";

@Component({
  selector: 'jbm-cancel-button',
  templateUrl: './JBMCancelButton.component.html',
  styleUrls: ['./JBMCancelButton.component.scss'],
  host: {'class': 'JBMButton'}
})
export class JBMCancelButtonComponent implements OnInit {

  @Input() context: string='secondary';
  @Input() class: string='';
  @Input() small: boolean=true;
  @Input() showIcon: boolean=true;
  @Input() showCaption: boolean=true;
  @Input() tooltip: any;
  @Input() tooltipPlacement: string='bottom';

  @Output() click = new EventEmitter();

  constructor(private TranslateService: TranslateService) {}

  ngOnInit(): void {
    if(!this.showCaption)
      this.tooltip=this.TranslateService.GetTranslation('ui.cancel');
  }

  onBtnClick() {
    this.click.emit();
  }
}
