<div class="toolbar float-start">
   <jbm-create-button [visible]="userRights.AllowCreate" (click)="insert()"></jbm-create-button>
   <div class="toolbar ml-4 float-start p-0">
      <label *ngIf="plantOptions.length>1">{{'company.plant'|translate}}</label>
      <select *ngIf="plantOptions.length>1" (change)="changePlant($event)" class="form-control form-control-sm form-select">
         <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
      </select>
   </div>
   <div class="btn-toolbar btn-group trucks-view">
      <button type="button" class="btn btn-sm btn-outline-primary" [ngClass]="{active: view==0}" (click)="setView(0)">{{'ui.all'|translate}}</button>
      <button type="button" class="btn btn-sm btn-outline-primary" [ngClass]="{active: view==1}" (click)="setView(1)">{{'entity.active'|translate}}</button>
      <button type="button" class="btn btn-sm btn-outline-primary" [ngClass]="{active: view==2}" (click)="setView(2)">{{'entity.inactive'|translate}}</button>
   </div>
</div>
<div class="toolbar float-end">
   <jbm-spinner [loading]="loading"></jbm-spinner>
   <div class="toolbar-spacer-1 float-end"></div>
   <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
     [pageSize]="queryHttpHelper.paginationProps.pageSize"
     [page]="queryHttpHelper.paginationProps.page"
     (pageClick)="pageChange($event)">
   </jbm-pagination>
   <jbm-searchbox [visible]="searchboxVisible && !showFilters" [search]="search" [minlength]="2" (searchChange)="searchChange($event)"></jbm-searchbox>
   <div class="toolbar-spacer-1"></div>
   <div class="btn-group">
      <jbm-filter-toggle [showFilter]="showFilters" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
   </div>
</div>
<jbm-table #table
  [data]="items"
  [sortable]="true"
  [responsive]="false"
  [sortColumn]="queryHttpHelper.sortProps.name"
  [sortDirection]="queryHttpHelper.sortProps.direction"
  [hoverable]="true"
  [rowSelectable]="true"
  [columnDefs]="columnDefs"
  [actionDefs]="actionDefs"
  [filterable]="true"
  [showFilters]="showFilters"
  (sortChange)="sortChange($event)"
  (actionClick)="actionClick($event)"
  (filterChange)="filterChange($event)"
  (rowSelect)="rowSelect($event)">
</jbm-table>

<ng-template #activeTemplate let-data="data">
   <div class="custom-control custom-switch float-start" (click)="$event.stopPropagation()">
      <input type="checkbox" class="custom-control-input" id="active{{data.id}}" [checked]="data.active" (click)="toggleActive(data.id,!data.active)">
      <label class="custom-control-label" for="active{{data.id}}"></label>
   </div>
</ng-template>

<jbm-modal [id]="'truck'" [className]="'truck'"></jbm-modal>
<ng-template #modalTruck let-modal>
   <truck-form [truck]="truck" (onSave)="saved()" (onCancel)="canceled()"></truck-form>
</ng-template>
