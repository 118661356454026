import {Component, Input, Self} from '@angular/core';
import { FormControl, NgControl} from "@angular/forms";

@Component({
  selector: 'jbm-input-error',
  templateUrl: './JBMInputError.component.html',
  styleUrls: ['./JBMInputError.component.scss']
})
export class JBMInputErrorComponent {
  @Input() submitted: boolean=false;

  constructor(@Self() public ngControl: NgControl) {
    this.ngControl.valueAccessor = this;
  }

  //ControlValueAccessor interface
  writeValue(obj: any) {  }
  registerOnChange(fn: any) { }
  registerOnTouched(fn: any) { }
  setDisabledState?(isDisabled: boolean) { }
}
