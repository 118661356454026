<form [formGroup]="form" (ngSubmit)="save()" class="invoice-validation">
   <div class="float-start comments">
      <label class="mb-1">{{'entity.comments'|translate}}</label>
      <textarea class="form-control form-control-sm w-100" rows="2" formControlName="comments"></textarea>
   </div>
   <div class="float-end mt-4">
      <button type="button" class="btn btn-sm btn-success mr-2" (click)="approve()">
         {{'entity.invoice'|translate}} {{'entity.approve'|translate|lowercase}}
      </button>
      <button type="button" class="btn btn-sm btn-danger" (click)="disapprove()">
         {{'entity.invoice'|translate}} {{'ui.reject'|translate|lowercase}}
      </button>
   </div>
</form>
<jbm-toasts></jbm-toasts>