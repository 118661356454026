<form [formGroup]="form" (ngSubmit)="save()" class="instant-production-order">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'project.order'|translate}} {{'production.push-through'|translate|lowercase}} {{'production.for-production'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <div class="jbm-properties-list">
         <div class="item">
            <label class="label">{{'entity.customer'|translate}}:</label>
            <label class="value">{{customer.name}} <br />{{customer.address_details.street}} {{customer.address_details.no}}</label>
         </div>
      </div>

      <jbm-static-label [labelCaption]="('project.order'|translate) + ' ' + ('order.amount'|translate|lowercase)" labelClass="xl"
        [value]="amountStr + ('quantity.m3'|translate)"></jbm-static-label>
      <jbm-static-label *ngIf="delivered" [labelCaption]="'production.produced'|translate" labelClass="xl"
        [value]="deliveredStr + ('quantity.m3'|translate)"></jbm-static-label>
      <jbm-static-label *ngIf="!delivered" [labelCaption]="'production.produced'|translate" labelClass="xl"
        [value]="'ui.inapplicable'|translate"></jbm-static-label>

      <hr />

      <jbm-select *ngIf="plantOptions.length>1" [options]="plantOptions" [insertLeadingOption]="true" [formControl]="formControls.plants_id" [required]="true" [submitted]="isSubmitted"
        [class]="'md'" [labelCaption]="'company.plant'|translate" labelClass="xl" (onChange)="changePlant">
         <jbm-input-error [formControl]="formControls.plants_id" [submitted]="isSubmitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-select>

      <truck-selector [labelCaption]="'production.truck'|translate" labelClass="xl" [id]="'truck_id'"
        class="md mb-2" [required]="true" [template]="truckselect" [truck_id]="receipt.trucks_id" [plant_id]="formControls.plants_id.value"
        (truckSelected)="selectTruck($event)">
         <ng-template #truckselect let-truck="truck">
            <table class="table table-xs table-borderless">
               <tr><td>{{'ui.number'|translate}}:</td><td>{{truck.number}}</td></tr>
               <tr *ngIf="truck.numberplate"><td>{{'truck.numberplate'|translate}}:</td><td>{{truck.numberplate}}</td></tr>
               <tr><td>{{'production.capacity'|translate}}:</td><td>{{truck.capacity}} {{'quantity.m3'|translate}}</td></tr>
            </table>
         </ng-template>
      </truck-selector>

      <jbm-float-input-group [formControl]="formControls.amount" [submitted]="isSubmitted"
        [id]="'amount'" required="true" [inputClass]="'xxs'" append="{{'quantity.m3'|translate}}"
        labelCaption="{{'menu.production'|translate}} {{'order.amount'|translate|lowercase}}" [labelClass]="'xl'" decimals="2" [decimalsOptional]="true"
        [negativeAllowed]="false">
        <jbm-input-error [formControl]="formControls.amount" [submitted]="isSubmitted">
           {{'entity.concrete-amount-format'|translate}}
        </jbm-input-error>
      </jbm-float-input-group>

      <jbm-float-input-group [formControl]="formControls.receipt_amount" [submitted]="isSubmitted"
        [id]="'amount'" required="true" [inputClass]="'xxs'" append="{{'quantity.m3'|translate}}"
        labelCaption="{{'production.receipt'|translate}} {{'order.amount'|translate|lowercase}}" labelClass="xl" decimals="2" [decimalsOptional]="true"
        [negativeAllowed]="false">
         <jbm-input-error [formControl]="formControls.receipt_amount" [submitted]="isSubmitted">
            {{'entity.concrete-amount-format'|translate}}
         </jbm-input-error>
      </jbm-float-input-group>

      <jbm-textarea [id]="'comments'" [formControl]="formControls.comment" class="md" labelClass="xl"
        [labelCaption]="('production.receipt'|translate)+' '+('entity.comments'|translate|lowercase)"></jbm-textarea>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-sm btn-primary" (click)="save()">{{'project.order'|translate}} {{'production.push-through'|translate|lowercase}}</button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
