import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ConcretePricesService} from "../../data/concrete-prices.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {ArticlePricelistsService} from "../../data/article-pricelists.service";
import {PriceService} from "../../../../services/price/price.service";
import {TokenService} from "../../../../services/auth/token.service";
import {LocalStorageService} from "../../../../services/storage/localstorage.service";
import {RefererService} from "../../../../services/storage/referer.service";
import {JBMPropertiesListItem} from "../../../../components/JBM/Views/JBMPropertiesList/JBMPropertiesList.component";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";

import {ConcretePricelist} from "../../data/interfaces/concrete-pricelist";
import {GroupRights} from '../../../users/data/interfaces/grouprights';

@Component({
  selector: 'concrete-prices',
  templateUrl: './concrete_prices.component.html',
  styleUrls: ['./concrete_prices.component.scss']
})
export class ConcretePricesComponent implements OnInit, OnDestroy {
  userRights: GroupRights;
  gridData: any={ environments: [], strengths: [], consistencies: [], prices: [] };
  prices: number[][][];
  language: string=this.TokenService.getLanguage();
  parentLinkCaption: string;
  parentLinkRoute: string;
  concretePricelist: ConcretePricelist;
  priceListProps: JBMPropertiesListItem[];
  price_id: number=0;
  strength_id: number=0;
  environment_id: number=0;
  consistency_id: number=0;
  loading: boolean=false;

  gridData$: Subscription;
  count$: Subscription;

  @ViewChild('modalContent') modalTemplate: ElementRef;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private ConcretePricesService: ConcretePricesService,
      private ArticlePricelistsService: ArticlePricelistsService,
      private PriceService: PriceService,
      public modalService: ModalService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private LocalStorageService: LocalStorageService,
      private RefererService: RefererService,
      private JBMToastsService: JBMToastsService
  ) {
    // The route resolver has fetched the pricelist data
    this.concretePricelist=this.ActivatedRoute.snapshot.data.pricelist.data;
  }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    // Set parent route
    this.parentLinkCaption=this.TranslateService.GetTranslation('financial.concrete-pricelists');
    this.parentLinkRoute='settings/financial/concrete-pricelists';

    // Set optional parent route from active referer
    let referer=this.RefererService.getReferer('customers','concrete-pricelists');
    if(typeof referer=='object') {
      this.parentLinkCaption=referer.caption;
      this.parentLinkRoute=referer.route;
    }

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    // Set pricelist properties
    let type=this.ArticlePricelistsService.getTypeAsString(this.concretePricelist.type, this.TranslateService);
    this.priceListProps=[];
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('financial.concrete-pricelist'), value: this.concretePricelist.description });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'), value: type });

    this.loading=true;
    this.gridData$=this.ConcretePricesService.getGridData(this.language, this.concretePricelist.id).subscribe(
        (data: any) => this.gridData = data.data,()=>{},()=> {

//          if(this.items.length)
  //          this.setDeletePermissions();

          this.loading=false;
        } );
  }
  setDeletePermissions() {
    /*
    let ids=[];
    for(let i=0; i<this.items.length; i++ ) {
      this.items[i].deletePermission=false;
      ids.push(this.items[i].id);
    }
    let deletePermissions=[];
    this.ConcretePricesService.getDeletePermissions(ids).subscribe(
        (data)=>deletePermissions=data.data,
        (error)=>console.error(error),
        ()=>{
          for(let i=0; i<this.items.length; i++ )
            this.items[i].deletePermission=(deletePermissions.indexOf(this.items[i].id)>-1);
        }
    )*/
  }
  add() {
    this.strength_id=0;
    this.environment_id=0;
    this.consistency_id=0;
    this.price_id=0;
    this.openModal();
  }
  addConcreteSpecs(strength_id: number, environment_id: number, consistency_id: number) {
    this.strength_id=strength_id;
    this.environment_id=environment_id;
    this.consistency_id=consistency_id;
    this.price_id=0;
    this.openModal();
  }
  edit(id: number) {
    this.price_id=id;
    this.openModal();
  }
  openModal() {
    this.modalService.open( this.modalTemplate,'concrete-price');
  }
  saved() {
    this.closeModal();
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Refresh view
    this.getData(true);
  }
  canceled() {
    this.closeModal();
  }
  closeModal() {
    this.modalService.close('concrete-price');
  }
  delete(id) {
    this.ConcretePricesService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData(true);
        }
    )
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.gridData$!=undefined) this.gridData$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
