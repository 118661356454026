<div class="truck-capacity-indication">
   <button type="button" class="btn btn-primary" [ngClass]="{'active': visible}" (click)="toggle()">
      {{'production.trucks'|translate}} {{'production.capacity'|translate|lowercase}} {{'ui.indication'|translate|lowercase}} <i class="fa fa-caret-down"></i>
   </button>
   <div class="panel-container clearfix">
      <div [hidden]="!visible" class="jbm-overlay-panel panel">
         <div class="header"></div>
         <ngx-charts-line-chart
           [results]="graphData"
           [view]="[850,150]"
           [legendTitle]="plantLabel"
           [yAxisLabel]="'production.trucks'|translate"
           [legend]="true"
           [showXAxisLabel]="true"
           [showYAxisLabel]="true"
           [xAxis]="true"
           [yAxis]="true"
           fitContainer="true"
           [xAxisLabel]=""
           [referenceLines]="referenceLines"
           showRefLines="true"
           showRefLabels="true"
         ></ngx-charts-line-chart>
<!--         <ngx-charts-area-chart
           [results]="graphData"
           [view]="[850,150]"
           [legendTitle]="plantLabel"
           [yAxisLabel]="'production.trucks'|translate"
           [legend]="true"
           [showXAxisLabel]="true"
           [showYAxisLabel]="true"
           [xAxis]="true"
           [yAxis]="true"
           fitContainer="true"
           [xAxisLabel]=""
           [referenceLines]="[{ name: 'test', value: 6}]"
           showRefLines="true"
           showRefLabels="true"
         >
         </ngx-charts-area-chart>-->
         <div class="footer">
            <span class="hour-label">{{'datetime.hours'|translate}}</span>
            <button type="button" class="btn btn-sm btn-secondary float-end" (click)="toggle()">{{'ui.close'|translate}} </button>
         </div>
      </div>
   </div>
</div>