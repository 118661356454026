import {Injectable, TemplateRef} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JBMToastsService {

  toasts: any[] = [];
  constructor() {this.toasts=[];}

  // Push new Toasts to array with content and options
  push(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  // Context dependent toasts
  private contextBase(text, classname, delay, autohide=true ) {
    let options= { classname: classname, delay: delay, autohide: autohide }
    this.push(text, options);
  }
  success(text, autohide: boolean=true) {
    text=text.trim();
    if(!text) return  false;
    this.contextBase(text,'bg-success text-light',2000, autohide);
  }
  info(text, autohide: boolean=true) {
    text=text.trim();
    if(!text) return  false;
    this.contextBase(text,'bg-info text-light',5000, autohide);
  }
  warning(text, autohide: boolean=true) {
    text=text.trim();
    if(!text) return  false;
    this.contextBase(text,'bg-warning text-light',5000, autohide);
  }
  error(text, autohide: boolean=true) {
    text=text.trim();
    if(!text) return  false;
    this.contextBase(text,'bg-danger text-light',5000, autohide);
  }

  // Callback methods to remove Toast DOM element(s) from view
  remove(toast) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }
  removeAll() {
    this.toasts = [];
  }
}
