<header class="module subheader">
    <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
    <jbm-searchbox [visible]="searchboxVisble" [search]="search" [minlength]="2"
                   (searchChange)="searchChange($event)">
    </jbm-searchbox>
    <div class="header-spacer-2 float-end"></div>
    <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
                    [pageSize]="queryHttpHelper.paginationProps.pageSize"
                    [page]="queryHttpHelper.paginationProps.page"
                    (pageClick)="pageChange($event)">
    </jbm-pagination>
    <div class="header-spacer-2 float-end"></div>
    <jbm-spinner [loading]="loading"></jbm-spinner>
</header>

<div class="table-responsive">
    <table class="table table-sm strengths" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
        <thead>
        <tr>
            <th jbm-table-column class="description" [name]="'description'" [header]="'entity.description'|translate"
                [sortable]="true" [sorted]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)"></th>
            <th class="editable actions"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="createActive" class="edit-active">
            <th><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="description"
                       (change)="onChangeDescription()" [ngClass]="{'is-valid':validateDescription(), 'is-invalid':this.descriptionTouched && (!validateDescription() || !unique) }">
                <div *ngIf="error" class="invalid-feedback">{{error}}</div>
            </th>
            <th class="editable actions">
                <button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!validateDescription()" (click)="createItem()">{{'ui.save'|translate}}</button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
            </th>
        </tr>
        <tr *ngFor="let item of items" [ngClass]="{ 'edit-active': item.editing }" (click)="startEditing(item.id)">
            <td *ngIf="!item.editing">{{item.description}}</td>
            <td *ngIf="!item.editing" class="actions"><button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm float-end"><i class="far fa-edit"></i></button></td>
            <td *ngIf="item.editing" class="editable">
                <input class="form-control form-control-sm" type="text" autocomplete="off"
                       [(ngModel)]="description" [placeholder]="item.description" (change)="onChangeDescription()" [ngClass]="{'is-valid':validateDescription(), 'is-invalid':!validateDescription() || !unique }">
                <div *ngIf="error" class="invalid-feedback">{{error}}</div>
            </td>
            <td *ngIf="item.editing" class="editable actions">
                <button type="button" class="btn btn-sm btn-primary mr-1"
                        [disabled]="!validateDescription()" (click)="updateItem()">{{'ui.save'|translate}}</button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
            </td>
        </tr>
        </tbody>
    </table>
    <jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
</div>
<jbm-toasts></jbm-toasts>
