<form [formGroup]="form" (ngSubmit)="save()" class="company" xmlns="http://www.w3.org/1999/html">
  <div class="toolbar buttons">
    <jbm-save-button *ngIf="userRights.AllowUpdate" [subject]="'company.data'|translate" [small]="false" (click)="save()"></jbm-save-button>
    <div class="toolbar-spacer-1"></div>
    <jbm-cancel-button *ngIf="userRights.AllowUpdate" [small]="false" (click)="cancel()"></jbm-cancel-button>
    <div class="toolbar-spacer-1"></div>
    <jbm-activity-log-button
      [title]="'company.data'|translate" [subject_type]="23" [subject_id]="1">
    </jbm-activity-log-button>
  </div>
  <div class="float-start">
    <jbm-text-input [formControl]="formControls.coc" [id]="'code'" [editable]="editable"
      labelCaption="{{'customer.coc-number'|translate}}" labelClass="width-9" class="input-7"
      (onBlur)="updateAddress($event)">
        <jbm-input-error [formControl]="formControls.coc" [submitted]="isSubmitted">
          {{'customer.coc-number-format'|translate}}
        </jbm-input-error>
        <jbm-input-error [formControl]="formControls.coc" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
    </jbm-text-input>
    <jbm-text-input [formControl]="formControls.vat" [submitted]="isSubmitted" [id]="'vat'" [editable]="editable"
      labelCaption="{{'company.vat'|translate}}" labelClass="width-9" class="input-10">
    </jbm-text-input>

    <fieldset class="mt-3 width-36"><legend>{{'entity.address'|translate}}</legend>
      <jbm-text-input [formControl]="formControls.postcode" [submitted]="isSubmitted" [id]="'postalcode'" class="input-7"
        [editable]="editable"
        required="required" [maxlength]="7" (onBlur)="updateAddress(false)"
        labelCaption="{{'entity.postalcode'|translate}}" labelClass="width-8">
        <jbm-input-error [formControl]="formControls.postcode" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.no" [submitted]="isSubmitted" [id]="'housenr'"  class="input-7"
        [editable]="editable"
        required="required" [maxlength]="15" (onBlur)="updateAddress(false)"
        labelCaption="{{'entity.housenumber'|translate}}" labelClass="width-8">
        <jbm-input-error [formControl]="formControls.no" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.street" [submitted]="isSubmitted" [id]="'street'"  class="input-23"
        [editable]="editable"
        required="required" [maxlength]="75" (onBlur)="updateAddress(false)"
        labelCaption="{{'entity.streetname'|translate}}" labelClass="width-8">
        <jbm-input-error [formControl]="formControls.street" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.city" [submitted]="isSubmitted" [id]="'city'" class="input-23"
        [editable]="editable"
        required="required" [maxlength]="50" (onBlur)="updateAddress(false)"
        labelCaption="{{'entity.cityname'|translate}}" labelClass="width-8">
        <jbm-input-error [formControl]="formControls.city" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-text-input>
    </fieldset>
  </div>
</form>
<jbm-toasts></jbm-toasts>
