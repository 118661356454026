import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const BaseURL: string = 'customertypes/';

@Injectable({
  providedIn: 'root'
})
export class CustomertypesService {

  constructor(private HttpService: HttpService) {}

  get() {
    return this.HttpService.get(BaseURL+'read/' );
  }
  getDescriptionByID( customertypes, id) {
    for(let c=0; c<customertypes.length; c++) {
      if(customertypes[c].id == id)
        return customertypes[c].description;
    }
    return '';
  }
  create(data: any) {
    return this.HttpService.post(BaseURL+'create',data);
  }
  update(data: any) {
    return this.HttpService.post(BaseURL+'update', data);
  }
}
