<table *ngFor="let item of items" class="table table-sm table-row-selectable invoice-items mb-3">
   <thead>
   <tr>
      <th class="delivery-date">{{'order.delivery-date'|translate}}</th>
      <th class="description">{{'entity.description'|translate}}</th>
      <th class="section">{{'ui.section'|translate}}</th>
      <th class="amount align-right">{{'order.amount'|translate}}</th>
      <th class="price-type"></th>
      <th class="price align-right">{{'article.pricetype-price'|translate}}</th>
      <th class="totalprice align-right">{{'ui.totalprice'|translate}}</th>
      <th class="actions align-right">{{item.totalprice|money}}</th>
   </tr>
   </thead>
   <tbody>
   <tr>
      <td>{{item.delivery_date}}</td>
      <td>{{'concrete.mortal'|translate}}</td>
      <td><span class="badge bg-light text-dark">{{'concrete.mortal'|translate}}</span></td>
      <td class="amount align-right">{{item.amountStr}}</td>
      <td class="pl-0">{{'quantity.m3'|translate}}</td>
      <td class="align-right">{{item.price|money}}</td>
      <td class="align-right">{{item.totalBasePrice|money}}</td>
      <td></td>
   </tr>
   <tr *ngIf="viewSettings.recipes">
      <td></td>
      <td [hidden]="item.editing">
         <jbm-link-button [caption]="item.description" class="pr-1" (onClick)="showRecipe(item.recipes_id, item.delivery_date, item.environment.description)"></jbm-link-button>
         ({{'ui.number'|translate|lowercase}} {{item.recipes_id}})
      </td>
      <td><span class="badge bg-light text-dark">{{'entity.recipe'|translate}}</span></td>
      <td></td>
      <td class="pl-0"></td>
      <td></td>
      <td></td>
      <td></td>
   </tr>
   <tr *ngIf="viewSettings.recipes && viewSettings.comments && item.recipe.internal_description" class="borderless">
      <td class="p-0"></td>
      <td class="pt-0 pb-0"><jbm-comments-box [comments]="item.recipe.internal_description" height="2.75"></jbm-comments-box></td>
      <td class="p-0" colspan="6"></td>
   </tr>
   <ng-container *ngFor="let resource of item.resources">
      <tr class="borderless" (click)="editResource(resource.id)">
         <td *ngIf="!resource.editing" [ngClass]="{editable: resource.editing}">{{resource.delivery_date}}</td>
         <td *ngIf="resource.editing">
            <div class="float-start">
               <input class="form-control form-control-sm delivery-date" [maxLength]="10" [placeholder]="'datetime.date-format'|translate" [placement]="'right'"
                 [container]="'body'" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker"
                 (change)="onChangeDate()"
                 (blur)="onChangeDate()"
                 [ngClass]="{'ng-valid':validateDate(), 'is-invalid':this.dateTouched && !validateDate()}">
            </div>
            <div class="float-start">
               <button type="button" class="btn btn-sm btn-light" (click)="d.toggle()">
                  <i class="fa fa-calendar"></i>
               </button>
            </div>
         </td>

         <td *ngIf="!resource.editing">{{resource.description}}</td>
         <td *ngIf="resource.editing">
            <input class="form-control form-control-sm description ml-1" type="text" autocomplete="off"
              [(ngModel)]="description" (change)="onChangeDescription()"
              [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':this.descriptionTouched && !validateDescription()}">
         </td>

         <td>
            <span class="badge bg-light text-dark">{{'entity.resources'|translate}}</span>
         </td>

         <td *ngIf="!resource.editing" class="align-right">{{resource.amount|float:4}}</td>
         <td *ngIf="resource.editing" class="editable">
            <input float-only-input class="form-control form-control-sm align-right" [id]="'resource-amount-'+resource.id" type="text" autocomplete="off"
              [(ngModel)]="amount" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
         </td>
         <td class="pl-0">{{'quantity.kilo-short'|translate}}</td>

         <td *ngIf="!resource.editing" class="align-right">
            <jbm-resource-pricerules-breakdown-popover *ngIf="resource.price"
              [subject]="resources_subject" [subject_id]="resource.id" [class]="'btn-sm'">
            </jbm-resource-pricerules-breakdown-popover>
         </td>
         <td *ngIf="resource.editing" class="editable">
            <input float-only-input class="form-control form-control-sm align-right" type="text" autocomplete="off"
              [(ngModel)]="price"
              (change)="onChangePrice()"
              [ngClass]="{'ng-valid':validatePrice(), 'is-invalid':this.priceTouched && !validatePrice()}">
         </td>
         <td *ngIf="!resource.editing" class="align-right">{{resource.totalprice|money}}</td>
         <td *ngIf="resource.editing" class="align-right editable"><span *ngIf="totalPrice">{{totalPrice|money}}</span></td>

         <td *ngIf="editable && !resource.editing" class="action-buttons align-right p-1">
            <jbm-confirm-delete-dropdown
              [subject]="('entity.invoice'|translate)+' '+('entity.resource'|translate|lowercase)"
              [placement]="'top-right'"
              [class]="'float-end ml-2 mr-0'"
              (onConfirm)="deleteResource(resource.id)">
            </jbm-confirm-delete-dropdown>
            <jbm-edit-button *ngIf="userRights.AllowUpdate" (onClick)="editResource(resource.id)"></jbm-edit-button>
         </td>
         <td *ngIf="editable && resource.editing" class="editable action-buttons align-right p-1">
            <jbm-cancel-button class="float-end" [showCaption]="false" [small]="true" (click)="cancelResource()"></jbm-cancel-button>
            <jbm-save-button [disabled]="!validateDate() || !validateDescription() || !validateAmount()" class="float-end mr-1"
              [showCaption]="false" (click)="saveResource()">
            </jbm-save-button>
         </td>
      </tr>
   </ng-container>
   <ng-container *ngFor="let concrete of item.concretes">
      <tr *ngIf="viewSettings.orders">
         <td></td>
         <td *ngIf="concrete.order.description">
            <jbm-link-button [caption]="concrete.order.description" (onClick)="showOrder(concrete.order.id)"></jbm-link-button>
            <span class="ml-1 hover-tooltip" [ngbTooltip]="'order.worktype'|translate">({{concrete.order.worktype.description}})</span>
         </td>
         <td *ngIf="!concrete.order.description">
            <jbm-link-button [caption]="concrete.order.worktype.description" (onClick)="showOrder(concrete.order.id)"></jbm-link-button>
         </td>
         <td><span class="badge bg-light text-dark">{{'project.order'|translate}}</span></td>
         <td colspan="5"></td>
      </tr>
      <tr *ngIf="viewSettings.orders && viewSettings.comments && concrete.order.recipe_comments" class="borderless">
         <td></td>
         <td colspan="1"><jbm-comments-box [comments]="concrete.order.recipe_comments" height="2.75"></jbm-comments-box></td>
         <td colspan="6"></td>
      </tr>
      <tr *ngIf="viewSettings.orders && viewSettings.comments && concrete.order.comments" class="borderless">
         <td></td>
         <td colspan="1"><jbm-comments-box [comments]="concrete.order.comments" height="2.75"></jbm-comments-box></td>
         <td colspan="6"></td>
      </tr>
   </ng-container>
   <tr *ngIf="!creating && editable">
      <td colspan="2" class="p-0"></td>
      <td class="p-0 pl-1">
         <button type="button" class="btn btn-xs btn-primary" (click)="startCreating(item.id)">
            <i class="fa fa-plus"></i> {{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}}
         </button>
      </td>
      <td colspan="5" class="p-0"></td>
   </tr>
   <tr *ngIf="creating && invoice_item_id==item.id">
      <th></th>
      <th>{{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}} {{'entity.create'|translate|lowercase}}</th>
      <th colspan="6"></th>
   </tr>
   <tr *ngIf="creating && invoice_item_id==item.id">
      <td class="delivery-date">
         <div class="float-start">
            <input class="form-control form-control-sm delivery-date" [maxLength]="10" [placeholder]="'datetime.date-format'|translate" [placement]="'right'"
              [container]="'body'" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="selectDate($event)"
              (change)="onChangeDate()"
              (blur)="onChangeDate()"
              [ngClass]="{'ng-valid':validateDate(), 'is-invalid':this.dateTouched && !validateDate()}">
         </div>
         <div class="float-start">
            <button type="button" class="btn btn-sm btn-light" (click)="d.toggle()">
               <i class="fa fa-calendar"></i>
            </button>
         </div>
      </td>

      <td>
         <invoice-article-selector [id]="'article-selector'" [invoice_id]="invoice.id" [invoice_item_id]="invoice_item_id"
           [articles_id]="article_id" [allowedPricetypes]="allowedPricetypes" [required]="true" [labelStacked]="true" [template]="articleselect" [showError]="false"
           [class]="'mb-0'" (articleSelected)="selectArticle($event)">
         </invoice-article-selector>
         <ng-template #articleselect let-article="article">
            <div *ngIf="article">{{article.name}}</div>
         </ng-template>
      </td>

      <td>
         <span class="badge bg-light text-dark">{{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}}</span>
      </td>

      <td *ngIf="article_price_types_id!=58" class="editable">
         <input float-only-input type="text" autocomplete="off" [disabled]="!this.article_id" class="form-control form-control-sm align-right"
           [(ngModel)]="amount" [id]="'extra-article-amount-0'" (change)="onChangeAmount()" (blur)="onChangeAmount()"
           [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
      </td>
      <td *ngIf="article_price_types_id==58" class="editable">
         <input digits-only-input type="text" autocomplete="off" [disabled]="!this.article_id" class="form-control form-control-sm align-right"
           [(ngModel)]="amount" [id]="'extra-article-amount-0'" [maxLength]="3" [minLength]="1"
           (change)="onChangeAmount()" (blur)="onChangeAmount()"
           [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
      </td>
      <td>{{code}}</td>

      <td>
         <input [disabled]="!this.article_id" float-only-input class="form-control form-control-sm price align-right" type="text" autocomplete="off"
           [(ngModel)]="price" (change)="onChangePrice()"
           [ngClass]="{'ng-valid':validatePrice(), 'is-invalid':this.priceTouched && !validatePrice()}">
      </td>
      <td class="align-right"><span *ngIf="totalPrice">{{totalPrice|money}}</span></td>
      <td class="editable actions p-0">
         <jbm-cancel-button class="float-end" [showCaption]="false" (click)="cancelCreating()"></jbm-cancel-button>
         <jbm-save-button [disabled]="!validateDate() || !validateDescription() || !validateAmount() || !validatePrice()" class="float-end mr-1"
           [showCaption]="false" (click)="insertArticle()">
         </jbm-save-button>
      </td>
   </tr>
   <ng-container *ngFor="let article of item.extra_articles">
      <tr class="borderless" (click)="editExtraArticle(article.id)">
         <td *ngIf="!article.editing" [ngClass]="{editable: article.editing}">{{article.delivery_date}}</td>
         <td *ngIf="article.editing">
            <div class="float-start">
               <input class="form-control form-control-sm delivery-date" [maxLength]="10" [placeholder]="'datetime.date-format'|translate" [placement]="'right'"
                 [container]="'body'" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker"
                 (change)="onChangeDate()"
                 (blur)="onChangeDate()"
                 [ngClass]="{'ng-valid':validateDate(), 'is-invalid':this.dateTouched && !validateDate()}">
            </div>
            <div class="float-start">
               <button type="button" class="btn btn-sm btn-light" (click)="d.toggle()">
                  <i class="fa fa-calendar"></i>
               </button>
            </div>
         </td>

         <td *ngIf="!article.editing">{{article.description}}</td>
         <td *ngIf="article.editing">
            <input class="form-control form-control-sm description ml-1" type="text" autocomplete="off"
              [(ngModel)]="description" (change)="onChangeDescription()"
              [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':this.descriptionTouched && !validateDescription()}">
         </td>

         <td>
            <span class="badge bg-light text-dark">{{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}}</span>
         </td>

         <td *ngIf="!article.editing" class="align-right">{{article.amount}}</td>
         <td *ngIf="article.editing && article.article_price_types_id!=58" class="editable">
            <input float-only-input class="form-control form-control-sm align-right" [id]="'extra-article-amount-'+article.id" type="text" autocomplete="off"
              [(ngModel)]="amount" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
         </td>
         <td *ngIf="article.editing && article.article_price_types_id==58" class="editable">
            <input digits-only-input class="form-control form-control-sm align-right" [id]="'extra-article-amount-'+article.articles_id" type="text" autocomplete="off"
              [(ngModel)]="amount" [maxLength]="3" [minLength]="1" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
         </td>
         <td class="pl-0">{{article.price_type.code}}</td>

         <td *ngIf="!article.editing" class="align-right">
            <jbm-article-pricerules-breakdown-popover *ngIf="article.price"
              [subject]="articles_subject" [subject_id]="article.id" [price]="article.price" [class]="'btn-sm'">
            </jbm-article-pricerules-breakdown-popover>
         </td>
         <td *ngIf="article.editing" class="editable">
            <input float-only-input class="form-control form-control-sm align-right" type="text" autocomplete="off"
              [(ngModel)]="price"
              (change)="onChangePrice()"
              [ngClass]="{'ng-valid':validatePrice(), 'is-invalid':this.priceTouched && !validatePrice()}">
         </td>
         <td *ngIf="!article.editing" class="align-right">{{article.totalprice|money}}</td>
         <td *ngIf="article.editing" class="align-right editable"><span *ngIf="totalPrice">{{totalPrice|money}}</span></td>

         <td *ngIf="editable && !article.editing" class="action-buttons align-right p-1">
            <jbm-confirm-delete-dropdown
              [subject]="('entity.invoice'|translate)+' '+('financial.article'|translate|lowercase)"
              [placement]="'top-right'"
              [class]="'float-end ml-2 mr-0'"
              (onConfirm)="delete(article.id)">
            </jbm-confirm-delete-dropdown>
            <jbm-edit-button *ngIf="userRights.AllowUpdate" (onClick)="editExtraArticle(article.id)"></jbm-edit-button>
         </td>
         <td *ngIf="editable && article.editing" class="editable action-buttons align-right p-1">
            <jbm-cancel-button class="float-end" [showCaption]="false" [small]="true" (click)="cancelExtraArticle()"></jbm-cancel-button>
            <jbm-save-button [disabled]="!validateDate() || !validateDescription() || !validateAmount()" class="float-end mr-1"
              [showCaption]="false" (click)="saveExtraArticle()">
            </jbm-save-button>
         </td>
      </tr>
   </ng-container>
   </tbody>
</table>

<jbm-modal [id]="'itemRecipe'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [delivery_date]="delivery_date" [recipe_id]="recipe_id" [environment]="environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<jbm-modal [id]="'orderDetails'" [className]="'order-details-shared'"></jbm-modal>
<ng-template #modalOrderDetails let-modal>
   <order-details [order_id]="order_id" (onClose)="closeOrder()"></order-details>
</ng-template>

