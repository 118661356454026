<div class="bg-light p-1 pl-4 pr-3 clearfix">
   <div *ngIf="invoice_usage==0" class="float-start mr-2">
      <span class="badge bg-secondary mt-2">{{'menu.production'|translate}} {{'ui.based'|translate|lowercase}}</span>
   </div>
   <div *ngIf="invoice_usage==1" class="float-start mr-2">
      <span class="badge bg-secondary mt-2">{{'project.orders'|translate}} {{'ui.based'|translate|lowercase}}</span>
   </div>
   <div *ngIf="invoice_usage==2" class="float-start mr-2">
      <span class="badge bg-secondary mt-2">{{'entity.customer'|translate}} {{'entity.invoice'|translate|lowercase}}</span>
   </div>
   <div *ngIf="invoice_usage==3" class="float-start mr-2">
      <span class="badge bg-secondary mt-2">{{'entity.project'|translate}} {{'entity.invoice'|translate|lowercase}}</span>
   </div>
   <div *ngIf="invoice_usage==2 || invoice_usage==3" class="float-start mr-2">
      <span class="badge bg-secondary mt-2">{{'invoicing.articles-only'|translate}}</span>
   </div>
   <div *ngIf="resources_based_invoicing" class="float-start mr-4">
      <span class="badge bg-secondary mt-2">
         {{'entity.resources'|translate}} {{'ui.based'|translate|lowercase}}
      </span>
   </div>
   <div *ngIf="shifted_vat" class="float-start mr-4">
      <span class="badge bg-secondary mt-2">
         {{'invoicing.shifted-vat'|translate}}
      </span>
   </div>
   <div *ngIf="invoice_usage==0" class="float-start ml-3 mr-4">
      <button type="button" class="btn btn-sm btn-outline-primary" (click)="editReceipts()">
         {{'order.production'|translate}}{{'production.receipts'|translate|lowercase}}
      </button>
   </div>
   <div *ngIf="invoice_usage==1" class="float-start ml-3">
      <button type="button" class="btn btn-sm btn-outline-primary" (click)="editOrders()">
         {{'project.orders'|translate}}
      </button>
   </div>
   <div class="float-end align-right">
      <label class="mr-2">{{'ui.view'|translate}}: </label>
      <jbm-toggle-button [class]="'light mr-1'" [small]="true" [caption]="('entity.invoice'|translate)+('ui.details'|translate|lowercase)"
         [toggled]="settings.details" (onToggle)="toggleDetails()"></jbm-toggle-button>
      <jbm-toggle-button *ngIf="invoice_usage==0 || invoice_usage==1" [class]="'light mr-1'" [small]="true" [caption]="'entity.recipes'|translate"
        [toggled]="settings.recipes" (onToggle)="toggleRecipes()"></jbm-toggle-button>
      <jbm-toggle-button *ngIf="invoice_usage==0 || invoice_usage==1" [class]="'light mr-1'" [small]="true" [caption]="'project.orders'|translate"
        [toggled]="settings.orders" (onToggle)="toggleOrders()"></jbm-toggle-button>
      <jbm-toggle-button *ngIf="invoice_usage==0 || invoice_usage==1" [class]="'light'" [small]="true" [caption]="'entity.comments'|translate"
        [toggled]="settings.comments" (onToggle)="toggleComments()"></jbm-toggle-button>
      <jbm-toggle-button *ngIf="invoice_usage==0 || invoice_usage==1" [class]="'light'" [small]="true" [caption]="'entity.resources'|translate"
        [toggled]="settings.resources" (onToggle)="toggleResources()"></jbm-toggle-button>
   </div>
</div>
