<ngb-alert *ngIf="visible" [dismissible]="false" class="mt-2 mb-3">
   <ul class="pl-1">
      <li>{{'login.password-length'|translate}}</li>
      <li>{{'login.password-uppercase'|translate}}</li>
      <li>{{'login.password-lowercase'|translate}}</li>
      <li>{{'login.password-digits'|translate}}</li>
      <li>{{'login.password-spaces'|translate}}</li>
      <li>{{'login.password-special'|translate}}</li>
   </ul>
</ngb-alert>
