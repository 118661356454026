import { Injectable } from '@angular/core';
import {AbstractControl, ValidatorFn} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  RegEx() { return /^\s*\d+\,\d{2}\s*$/; }
  RegExNegative() { return /^\-{0,1}s*\d+\,\d{2}\s*$/; }

  validatePriceStr( price: string, negativeAllowed=false ): boolean {
    // Validate the price format through regular expression
    let re=this.RegEx();
    if(negativeAllowed) re=this.RegExNegative();
    if(!re.test(price)) return false;
  }

  formatPrice( price: number ) {
    if(price==undefined || price==0.0 || isNaN(price)) return '';
    return this.formatPriceStr(price.toString());
  }

  formatPriceStr( price: string ): string {
    let result=price.replace('.',',');
    if(result.indexOf(',')==-1) result+=',00';
    let parts=result.split(',');
    while(parts[1].length<2) parts[1]+='0';
    result=parts.join(',');
    return result;
  }

  getPriceFromStr( price: string ): number {
    return parseFloat(price.replace(',','.'));
  }

  getTotalPrice( price: number, amount: number ): number {
    return amount * price;
  }

  priceValidator(PriceService: PriceService, negativeAllowed: boolean=false, required=true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if(!required && control.value.trim()==='')
        return null;
      let valid=(control.value && control.value.length>3) &&
          (PriceService.validatePriceStr(control.value, negativeAllowed)!==false);
      if(!valid) {
        return {'priceInvalid': true};
      }
      return null;
    }
  }

}
