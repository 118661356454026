import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {PresentationsService} from "../data/presentations.service";
import {PresentationUsagesService} from '../data/presentation-usages.service';
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {Presentation} from "../data/interfaces/presentation";

@Component({
  selector: 'presentations',
  templateUrl: './presentations.component.html',
  styleUrls: ['./presentations.component.scss']
})
export class PresentationsComponent implements OnInit {
  userRights: GroupRights;
  presentation: Presentation;

  items: any;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('modalPresentation') modalPresentation: ElementRef;
  @ViewChild('usageTemplate') usageTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

  constructor(
      private Router: Router,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private PresentationsService: PresentationsService,
      private PresentationUsagesService: PresentationUsagesService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('presentations');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true},
      { name: 'presentationtype', header: this.TranslateService.GetTranslation('entity.type'),
        align: JBMTableColumnAlignment.left, width: 10},
      { name: 'usage', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('ui.usage'), sortable: true, width: 9},
      { name: 'outputtype', header: this.TranslateService.GetTranslation('presentations.output')+' '+this.TranslateService.GetTranslation('entity.type').toLowerCase(),
        align: JBMTableColumnAlignment.left, width: 6 },
      { name: 'is_default', type: JBMTableColumnType.boolean, header: this.TranslateService.GetTranslation('entity.default'),
        align: JBMTableColumnAlignment.left, width: 5,
        filterable: true, filterValues: [
          {key: '1', value: this.TranslateService.GetTranslation('ui.yes')},
          {key: '0', value: this.TranslateService.GetTranslation('ui.no')}
        ],
        displayValues: [
          { dataValue: false, displayValue: this.TranslateService.GetTranslation('ui.no') },
          { dataValue: true, displayValue: this.TranslateService.GetTranslation('ui.yes') },
        ]
      },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 23 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('description','pd.description');
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.PresentationsService.get(this.language, params).subscribe(
        (data: any) => this.items = data.data,(error) => console.error(error),()=> {

          this.columnDefs[3].template=this.usageTemplate;
          this.columnDefs[6].template=this.actionsTemplate;

          for(let item of this.items)
            // Set presentation usage labels
            item.usages=this.PresentationUsagesService.getLabels(item.presentation_type, item.usage);

          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.PresentationsService.get(this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  addItem() {
    this.presentation=this.PresentationsService.getEmpty();
    this.ModalService.open(this.modalPresentation,'presentation');
  }
  edit(id: number) {
    this.PresentationsService.getOne(this.language, id).subscribe(
        (data)=>this.presentation=data.data as Presentation,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.modalPresentation,'presentation')
    );
  }
  editTemplate(row) {
    this.rowSelect(row);
  }
  editAttachments(id: number) {
    this.Router.navigateByUrl('settings/mail-attachments/' + id);
  }
  rowSelect(row) {
    switch(row.output_type) {
      case 0: // PDF
        this.Router.navigateByUrl('settings/pdf-template-editor/' + row.id);
        break;
      case 1: // Email
        this.Router.navigateByUrl('settings/mail-template-editor/' + row.id);
        break;
    }
  }
  delete(id) {
    this.PresentationsService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData();
        }
    )
  }
  saved() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.ModalService.close('presentation');
    this.getData();
  }
  cancelled() {
    this.ModalService.close('presentation');
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
