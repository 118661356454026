import {Component, OnInit, OnDestroy, ViewChild, ElementRef, Input} from '@angular/core';
import {ResourcesService} from "../data/resources.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {JBMTableColumnDef} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {ValidationPatternsService} from "../../../services/validation/patterns.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";

@Component({
  selector: 'resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnDestroy {
  items: any[];
  columnDefs: JBMTableColumnDef[];
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  search: string='';

  createActive: boolean=false;
  description: string='';
  descriptionError: string='';
  descriptionTouched: boolean=false;
  descriptionUnique:boolean=true;
  code: string='';
  codeError: string='';
  codeTouched: boolean=false;
  codeUnique:boolean=true;
  absorption: string='';
  absorptionError: string='';
  absorptionTouched: boolean=false;
  editing: boolean=false;

  @Input() type_id: number;
  @Input() userRights: GroupRights;

  constructor(
      private ResourcesService: ResourcesService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService,
      private ValidationPatternsService: ValidationPatternsService,
      private SanitizationService: SanitizationService
  ) { }
  ngOnInit(): void {
    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.ResourcesService.get(this.type_id, params).subscribe(
        (data: any)=>this.items=data.data,
        (error)=>console.error(error),()=>{
          this.loading=false;

          // Set editmode to false for each item
          for(let item of this.items)
            item.editing=false;

          // Format absorption
          for(let item of this.items)
            item.absorptionStr=item.absorption.toString().replace('.',',');

          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.ResourcesService.get(this.type_id, params).subscribe(
                (data: any)=>this.queryHttpHelper.paginationProps.itemCount = data.data,
                (error)=>console.error(error));
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row alreay in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.code=data.code;
    this.codeTouched=false;
    this.codeUnique=true;
    this.description=data.description;
    this.descriptionTouched=false;
    this.descriptionUnique=true;
    this.absorption=data.absorption.toString().replace('.',',');
    this.absorptionTouched=false;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData(false);
  }
  onChangeCode() {
    this.codeTouched=true;
  }
  validateCode() {
    let value=this.code.trim();
    if(!value) this.codeError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.descriptionError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  onChangeAbsorption() {
    this.absorptionTouched=true;
  }
  validateAbsorption() {
    let value=this.absorption.trim();
    if(!value) {
      this.absorptionError=this.TranslateService.GetTranslation('entity.requiredfield');
      return false;
    }
    let reg=RegExp( this.ValidationPatternsService.floatPattern(2));
    if(!reg.test(value)) {
      this.absorptionError = this.TranslateService.GetTranslation('entity.requiredfield');
      return false;
    }
    return true;
  }
  // Save item data
  createItem() {
    this.getValues();
    let data={ id: 0, type: this.type_id, code: this.code, description: this.description, absorption: parseFloat(this.absorption), price: null }
    this.ResourcesService.create(data).subscribe(()=>{},error=>this.handleError(error),()=>this.afterSave())
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.code===this.code && originalValues.description===this.description && originalValues.absorption===this.absorption) {
      // Nothing changed; pretend if it has been a successful update
      this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
      this.cancelEditing();
      return false;
    }
    let data={ id: originalValues.id, code: this.code, description: this.description, absorption: parseFloat(this.absorption) }
    this.ResourcesService.update(data).subscribe(()=>{},error=>this.handleError(error),()=>this.afterSave())
  }
  getValues() {
    this.code=this.code.trim();
    this.description=this.description.trim();
    this.absorption=this.absorption.trim().replace(',','.');
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    return { id: id, type: this.type_id, code: this.code, description: this.description, absorption: parseFloat(this.absorption), price: null }
  }
  clearItemData() {
    this.code='';
    this.codeTouched=false;
    this.description='';
    this.descriptionTouched=false;
    this.absorption='';
    this.absorptionTouched=false;
  }
  handleError(error) {
    this.codeUnique=true;
    this.descriptionUnique=true;
    if(error=='code_not_unique') {
      this.codeUnique=false;
      this.codeError=this.TranslateService.GetTranslation('entity.code-not-unique');
    }
    if(error=='description_not_unique') {
      this.descriptionUnique=false;
      this.descriptionError=this.TranslateService.GetTranslation('entity.description-not-unique');
    }
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    if(this.createActive) {
      this.createCancel();
      this.getData(true);
    }
    if(this.editing) this.cancelEditing();
  }
  remove(id: number) {
    this.ResourcesService.delete(id).subscribe(()=>{},error=>this.handleError(error),()=>this.afterRemove())
  }
  afterRemove() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
    this.getData(true);
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
