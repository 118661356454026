import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMTableDisplayValue} from "../../../components/JBM/JBMTable/JBMTable.component";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {Article} from "./interfaces/article";

const baseURL: string = 'financial/';
const slug: string = 'articles/';

@Injectable({
  providedIn: 'root'
})
export class ArticlesService {
  article: Article;

  constructor(private HttpService: HttpService) { }

  get(language, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+language, params );
  }
  getSelectList(language, article_pricelists_id, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+'selectlist/'+language+'/'+article_pricelists_id, params );
  }
  getOne(id, language) {
    let params=new HttpParams().set('id',id.toString());
    return this.get(language, params);
  }
  getEmpty() {
    this.article = {
      id: 0,
      article_groups_id: 0,
      article_price_types_id: 0,
      reference_id: '',
      display: 0,
      mandatory: false,
      language: '',
      name: '',
      description: ''
    }
    return this.article;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }

  // Article display options

  getDisplayItems(TranslateService: TranslateService) {
    return [
      {key: 0, value: TranslateService.GetTranslation('article.display-description-price')},
      {key: 1, value: TranslateService.GetTranslation('article.display-description-concrete-addition')},
      {key: 2, value: TranslateService.GetTranslation('article.display-no-description-concrete-addition')},
      {key: 3, value: TranslateService.GetTranslation('article.display-description-only')}
    ];
  }

  getDisplaySelectOptions(TranslateService: TranslateService): JBMSelectOption[] {
    let items=this.getDisplayItems(TranslateService);
    let options: JBMSelectOption[]=[];
    for(let i=0; i<items.length; i++)
      options.push({key: items[i].key.toString(), value: items[i].value});
    return options;
  }

  getTypeDisplayValues(TranslateService: TranslateService): JBMTableDisplayValue[] {
    let items=this.getDisplayItems(TranslateService);
    let values: JBMTableDisplayValue[]=[];
    for(let i=0; i<items.length; i++)
      values.push({dataValue: items[i].key, displayValue: items[i].value});
    return values;
  }

  getDisplayAsString(type: number, TranslateService: TranslateService) {
    let items=this.getDisplayItems(TranslateService);
    if((type<0) || (type>=items.length))
      return '';
    return items[type].value;
  }
}
