import { Injectable } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {Truck} from "./interfaces/truck";

const baseURL: string = 'logistics/';
const segment: string = 'trucks/'

@Injectable({
  providedIn: 'root'
})
export class TrucksService {
  truck: Truck;

  constructor(private HttpService: HttpService) { }

  get(params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment, params);
  }
  getOne(truck_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/one/'+segment+truck_id.toString(), params);
  }
  getEmpty() {
    this.truck = {
      id: 0,
      number: '',
      capacity: 0,
      active: true,
      numberplate: '',
      plants_id: 0,
      drivers_id: null
    }
    return this.truck;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  updateActive(id: number, active: boolean) {
    return this.HttpService.post(baseURL+'update/'+segment+'active',{ id: id, active: active } );
  }
}
