import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../shared/shared.module';
import { JBMViewModule} from "../../JBM/Views/jbmview.module";
import { JBMTableModule} from "../../JBM/JBMTable/jbmtable.module";
import { JBMButtonsModule} from "../../JBM/Buttons/jbmbuttons.module";
import { PlantSelectorComponent} from "./plant/plant.selector.component";
import { PresentationSelectorComponent } from './presentation/presentation.selector.component';
import { CustomerSelectorComponent} from "./customer/customer.selector.component";
import { ProjectSelectorComponent} from "./project/project.selector.component";
import { ConcretePricelistSelectorComponent} from "./concrete-pricelist/concrete-pricelist.selector.component";
import { ArticlePricelistSelectorComponent} from "./article-pricelist/article-pricelist.selector.component";
import { ResourcePricelistSelectorComponent} from "./resource-pricelist/resource-pricelist.selector.component";
import { OrderArticleSelectorComponent} from "./order-article/order-article.selector.component";
import { InvoiceArticleSelectorComponent} from "./invoice-article/invoice-article.selector.component";
import { RecipeEnvironmentsSelectorComponent} from "./recipe-environments/recipe-environments.selector.component";
import { EnvironmentSelectorComponent} from "./concrete-specs/environment/environment.selector.component";
import { StrengthSelectorComponent} from "./concrete-specs/strength/strength.selector.component";
import { ConsistencySelectorComponent} from "./concrete-specs/consistency/consistency.selector.component";
import { TruckSelectorComponent} from "./truck/truck.selector.component";
import { DriverSelectorComponent} from "./driver/driver.selector.component";
import { MailSettingsSelectorComponent} from "./mail-settings/mail-settings.selector.component";

@NgModule({
  declarations: [PlantSelectorComponent, PresentationSelectorComponent, CustomerSelectorComponent, ProjectSelectorComponent,
    ConcretePricelistSelectorComponent, ArticlePricelistSelectorComponent, ResourcePricelistSelectorComponent,
    OrderArticleSelectorComponent, InvoiceArticleSelectorComponent, RecipeEnvironmentsSelectorComponent,
    EnvironmentSelectorComponent, StrengthSelectorComponent, ConsistencySelectorComponent,
    TruckSelectorComponent, DriverSelectorComponent, MailSettingsSelectorComponent],
  imports: [CommonModule, SharedModule, JBMViewModule, JBMTableModule, JBMButtonsModule],
  exports: [PlantSelectorComponent, PresentationSelectorComponent, CustomerSelectorComponent, ProjectSelectorComponent, ConcretePricelistSelectorComponent,
    ArticlePricelistSelectorComponent, ResourcePricelistSelectorComponent, OrderArticleSelectorComponent,
    InvoiceArticleSelectorComponent, RecipeEnvironmentsSelectorComponent,
    EnvironmentSelectorComponent, StrengthSelectorComponent, ConsistencySelectorComponent,
    TruckSelectorComponent, DriverSelectorComponent, MailSettingsSelectorComponent
  ]
})
export class SelectorsModule { }
