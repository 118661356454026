import {Component, Input} from '@angular/core';

@Component({
  selector: 'jbm-help-text',
  templateUrl: './JBMHelpText.component.html',
  styleUrls: ['./JBMHelpText.component.scss']
})
export class JBMHelpTextComponent {
  @Input() helptext: string='';
  @Input() class: string='';
}
