import { NgModule } from '@angular/core';
import { RouterModule} from "@angular/router";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule} from "../../../shared/shared.module";
import { JBMBreadcrumbsComponent } from './JBMBreadcrumbs/JBMBreadcrumbs.component';
import { JBMEmptyViewComponent } from './JBMEmptyView/JBMEmptyView.component';
import { JBMPaginationComponent } from './JBMPagination/JBMPagination.component';
import { JBMPropertiesListComponent } from './JBMPropertiesList/JBMPropertiesList.component';
import { JBMSearchBoxComponent } from './JBMSearchBox/JBMSearchBox.component';
import { JBMSpinnerComponent } from "./JBMSpinner/JBMSpinner.component";
import { JBMToastsComponent } from './JBMToasts/JBMToasts.component';
import { JBMViewTitle } from './JBMViewTitle/JBMViewTitle.component';
import { JBMCRUDTitleComponent} from "./JBMCRUDTitle/JBMCRUDTitle.component";
import { JBMDateRangeInputComponent } from './JBMDateRangeInput/JBMDateRangeInput.component';
import { JBMFormModule} from "../Forms/jbmform.module";
import { JBMSwitchComponent } from './JBMSwitch/JBMSwitch.component';
import { JBMCommentsBoxComponent } from './JBMCommentsBox/JBMCommentsBox.component';

@NgModule({
  imports: [CommonModule, NgbModule, SharedModule, RouterModule, JBMFormModule],
  declarations: [JBMViewTitle, JBMPaginationComponent, JBMSearchBoxComponent, JBMToastsComponent,
    JBMEmptyViewComponent, JBMSpinnerComponent, JBMPropertiesListComponent, JBMBreadcrumbsComponent,
    JBMCRUDTitleComponent, JBMDateRangeInputComponent, JBMSwitchComponent, JBMCommentsBoxComponent],
	exports: [JBMViewTitle, JBMPaginationComponent, JBMSearchBoxComponent, JBMToastsComponent,
		JBMEmptyViewComponent, JBMSpinnerComponent, JBMPropertiesListComponent, JBMBreadcrumbsComponent,
		JBMCRUDTitleComponent, JBMDateRangeInputComponent, JBMSwitchComponent, JBMCommentsBoxComponent]
})

export class JBMViewModule { }
