<div *ngIf="address_details_id!=0" ngbDropdown container="body" (openChange)="openChange($event)">
   <button type="button" class="btn btn-{{btnContext}} {{class}}" id="{{id}}" ngbDropdownToggle
      [ngClass]="{'btn-sm': !extraSmall, 'btn-xs': extraSmall}" (click)="toggle();$event.stopPropagation();">
      {{caption}}<i *ngIf="iconClass" class="fas fa-{{iconClass}}"></i>
   </button>
   <div ngbDropdownMenu [attr.aria-labelledby]="id" class="table-cell">
      <div class="jbm-properties-list address stacked">
         <div class="item">
            <label class="label">{{'entity.streetname-number'|translate}}</label>
            <label class="value">{{address.street}}&nbsp;{{address.no}}</label>
         </div>
         <div class="item">
            <label class="label">{{'entity.postalcode'|translate}} {{'ui.and'|translate}} {{'entity.cityname'|translate|lowercase}}</label>
            <label class="value">{{address.postcode}}&nbsp;&nbsp;{{address.city}}</label>
         </div>
      </div>
      <div *ngIf="address.lat" class="mt-3">
         <jbm-gps-location-button  [latitude]="address.lat" [longitude]="address.lon"
           title="{{'customer'|translate}}&nbsp;{{'entity.address'|translate|lowercase}}"
           [address]="address.street+'&nbsp;'+address.no+', '+address.city">
         </jbm-gps-location-button>
      </div>
   </div>
</div>
