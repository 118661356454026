import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {ProductionReceiptsComponent} from "./datatable/production-receipts.component";
import {SynchroniseReceiptsComponent} from "./synchronisation/synchronise-receipts.component";

const routes: Routes = [
  { path: 'production',
    component: ProductionReceiptsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  { path: 'production/synchronisation',
    component: SynchroniseReceiptsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductionRoutingModule { }
