import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {InvoicesService} from "../../data/invoices.service";

@Component({
  selector: 'concrete-comments',
  templateUrl: './concrete-comments.component.html',
  styleUrls: ['./concrete-comments.component.scss']
})
export class ConcreteCommentsComponent implements OnInit {
  form: FormGroup;

  constructor(
      private formBuilder: FormBuilder,
      private InvoicesService: InvoicesService
  ) { }

  @Input() invoice_item: any;
  @Input() invoiceNumber: string;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  ngOnInit(): void {
    this.addControls();
    this.setValues();
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          recipe_internal_comments: new FormControl('' ),
          recipe_external_comments: new FormControl('' ),
          order_internal_comments: new FormControl('' ),
          order_external_comments: new FormControl('' ),
        }
    );
  }
  setValues() {
    this.formControls.recipe_internal_comments.setValue(this.invoice_item.recipe.internal_description);
    this.formControls.recipe_external_comments.setValue(this.invoice_item.recipe.comments);
    this.formControls.order_internal_comments.setValue(this.invoice_item.concretes[0].order.comments);
    this.formControls.order_external_comments.setValue('');
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    let data={
      invoice_items_id: this.invoice_item.id,
      recipe_internal_comments: this.formControls.recipe_internal_comments.value,
      recipe_external_comments: this.formControls.recipe_external_comments.value,
      order_internal_comments: this.formControls.order_internal_comments.value,
      order_external_comments: this.formControls.order_external_comments.value,
    }
    this.onSuccess.emit();
/*    this.InvoicesService.approve(data).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=>this.onSuccess.emit()
    )*/
  }
  close() {
    this.onClose.emit();
  }
}
