import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InvoiceState} from "../../../../features/invoicing/data/interfaces/invoice-state";

@Component({
  selector: 'jbm-invoicestate-dropdown',
  templateUrl: './JBMInvoiceStateDropdown.component.html',
  styleUrls: ['./JBMInvoiceStateDropdown.component.scss']
})
export class JBMInvoiceStateDropdownComponent implements OnInit {

  @Input() id: number;
  @Input() invoiceState: any;
  @Input() invoiceStates: InvoiceState[];

  @Output() invoiceStateChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  setInvoiceState(id) {
    this.id=id;
    this.invoiceStateChanged.emit(id);
  }
}
