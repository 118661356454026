import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { MailAttachment } from "./interfaces/mail_attachment";

const baseURL: string = 'presentations/';
const segment: string = 'mail-attachments/';

@Injectable({
  providedIn: 'root'
})
export class MailAttachmentsService {
  mail_attachment: MailAttachment;

  constructor(
      private HttpService: HttpService,
  ) {}

  get(mail_template_id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+mail_template_id, params );
  }
  getOne(id :number) {
    return this.HttpService.get(baseURL+'read/one/'+segment+id.toString(),null );
  }
  getEmpty() {
    this.mail_attachment = {
      id: 0,
      media_files_id: 0
    }
    return this.mail_attachment;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+id.toString());
  }
}
