import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import { TokenService } from "../../services/auth/token.service";
import { menuItem, MenuService} from "../../services/menu/menu.service";
import { AuthService} from "../../services/auth/auth.service";
import { LocalStorageService} from "../../services/storage/localstorage.service";
import { TranslateService} from "../../services/multilingual/translate.service";
import { BodyClassTogglerService } from "../../services/interface/body-class-toggler.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  username: string;
  menu: menuItem[];
  narrow: boolean=false;
  tooltip: string=this.TranslateService.GetTranslation('ui.sidebar-collapse');

  @Output() onSidebarMenuShow=new EventEmitter();

  constructor(
      private Router: Router,
      private TokenService: TokenService,
      private MenuService: MenuService,
      private AuthService: AuthService,
      private LocalStorageService: LocalStorageService,
      private TranslateService: TranslateService,
      private BodyClassTogglerService: BodyClassTogglerService
  ) {}

  ngOnInit(): void {
    this.username=this.TokenService.getUsername();
    // Get modules and build the menu tree
    let modules = this.TokenService.getAuthorizedModules();
    this.menu = this.MenuService.buildMenuTree(modules);
    // Check sidebar narrow state
    if(this.LocalStorageService.hasKey('sidebar-narrow') &&
       this.LocalStorageService.get('sidebar-narrow')===true) {
      this.narrow=true;
      this.updateNarrowState();
    }
  }
  toggleSidebarMenu(menuitem: menuItem) {
    this.BodyClassTogglerService.toggleClass('sidebar-menu');
    this.onSidebarMenuShow.emit({header: menuitem.header, iconClass: menuitem.icon_class, menuitems: menuitem.subitems});
  }
  toggleSidebar() {
    this.narrow=!this.narrow;
    this.updateNarrowState();
  }
  updateNarrowState() {
    if(this.narrow) {
      this.tooltip=this.TranslateService.GetTranslation('ui.sidebar-expand');
      this.BodyClassTogglerService.checkClass('sidebar-narrow');
    } else {
      this.tooltip=this.TranslateService.GetTranslation('ui.sidebar-collapse');
      this.BodyClassTogglerService.removeClass('sidebar-narrow');
    }
    this.LocalStorageService.set('sidebar-narrow', this.narrow);
  }
  expand() {
    this.BodyClassTogglerService.checkClass('sidebar-expand');
  }
  collapse() {
    this.BodyClassTogglerService.removeClass('sidebar-expand');
  }
  shortCuts() {
    // Show the Shortcuts
    this.Router.navigate([ { outlets: {'ar': ['shortcuts']}} ] );
  }
  logOut() {
    this.AuthService.logOut();
  }
}
