import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'jbm-create-button',
  templateUrl: './JBMCreateButton.component.html',
  styleUrls: ['./JBMCreateButton.component.scss']
})
export class JBMCreateButtonComponent implements OnInit {
  @Input() visible: boolean=true;
  @Input() tooltip: string;
  @Input() class: string='';
  @Output() click = new EventEmitter();

  ngOnInit() {
    if(this.tooltip==undefined) this.tooltip='ui.insert-tooltip';
  }
  onBtnClick(event) {
    this.click.emit();
  }

  @HostListener('window:keydown', ['$event'])
  handleShortcut(event: KeyboardEvent) {
    if(event.code==='NumpadAdd' || event.code==='+') {
      this.onBtnClick(event);
      event.preventDefault();
    }
  }
}
