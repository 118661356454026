<app-header>
    <jbm-view-title [caption]="'project.states'|translate"
        [parentLinkCaption]="'menu.settings'|translate"
        [parentLinkSlug]="'settings'"></jbm-view-title>
    <div class="toolbar float-start">
        <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module overflow-y">
    <div class="table-responsive">
        <table class="table padding table-sm" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
            <thead>
            <tr>
                <th [hidden]="true"></th>
                <th jbm-table-column class="description" [name]="'description'" [header]="'entity.description'|translate"></th>
                <th jbm-table-column class="preview" [name]="'preview'" [header]="'ui.preview'|translate" width="8"></th>
                <th jbm-table-column class="colors" [name]="'colors'" [header]="'ui.colors'|translate" width="7" [hidden]="(editing==false) && (createActive==false)"></th>
                <th class="actions editable"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="createActive" class="edit-active">
                <th [hidden]="true"></th>
                <td><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="description"
                    (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && unique, 'is-invalid':this.descriptionTouched && !validateDescription() || !unique }">
                    <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
                </td>
                <td>
                   <span class="td-badge badge badge-pill" style="background-color: {{color}}; color:{{text_color}}">
                      {{'ui.preview'|translate}}
                   </span>
                </td>
                <td>
                    <jbm-color-palette-picker [background]="color" [foreground]="text_color"
                      (onChange)="changedColor($event)" (onAccept)="acceptColor($event)" (onCancel)="cancelColor(null)"></jbm-color-palette-picker>
                </td>
                <td><button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!validateDescription()" (click)="createItem();$event.stopPropagation()">{{'ui.save'|translate}}</button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel();$event.stopPropagation()">{{'ui.cancel'|translate}}</button>
                </td>
            </tr>
            <tr *ngFor="let item of items" [ngClass]="{'edit-active': item.editing}" (click)="startEditing(item.id)">
                <td [hidden]="true"></td>
                <td *ngIf="!item.editing">{{item.description}}</td>
                <td *ngIf="!item.editing">
                   <span class="td-badge badge badge-pill" style="background-color: {{item.color}}; color:{{item.text_color}}">
                      {{item.description}}
                   </span>
                </td>
                <td *ngIf="item.editing" class="editable">
                    <input class="form-control form-control-sm" type="text" autocomplete="off"
                           [(ngModel)]="description" [placeholder]="item.description" (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && unique, 'is-invalid':!validateDescription() || !unique}">
                    <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
                </td>
                <td *ngIf="item.editing">
                   <span class="td-badge badge badge-pill" style="background-color: {{color}}; color:{{text_color}}">
                      {{description}}
                   </span>
                </td>
                <td *ngIf="item.editing">
                    <jbm-color-palette-picker [background]="item.color" [foreground]="item.text_color"
                       (onChange)="changedColor($event)" (onAccept)="acceptColor($event)" (onCancel)="cancelColor(item)"></jbm-color-palette-picker>
                </td>
                <td [hidden]="!editing || item.editing"></td>
                <td class="editable">
                    <button *ngIf="userRights.AllowUpdate && !item.editing" class="btn btn-primary btn-sm float-end"><i class="far fa-edit"></i></button>
                    <button *ngIf="item.editing" type="button" class="btn btn-sm btn-primary mr-1"
                      [disabled]="!validateDescription()" (click)="updateItem();$event.stopPropagation()">{{'ui.save'|translate}}</button>
                    <button *ngIf="item.editing" type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing();$event.stopPropagation()">{{'ui.cancel'|translate}}</button>
                </td>
            </tr>
            </tbody>
        </table>
        <jbm-empty-view [emptyView]="items.length==0"></jbm-empty-view>
    </div>
</section>
<jbm-toasts></jbm-toasts>
