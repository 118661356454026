<app-header>
    <jbm-view-title caption="{{'recipe-app'|translate}} {{'users.title'|translate|lowercase}}"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'">
    </jbm-view-title>
    <jbm-create-button (click)="add()"></jbm-create-button>
    <div class="btn-group float-end">
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module padding padding-top">
    <jbm-table #table
      [data]="users"
      [classname]="'recipe-users'"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="true"
      [columnDefs]="columnDefs"
      [actionDefs]="actionDefs"
      [template]="templatePermissions"
      (rowSelect)="rowSelect($event)"
      (actionClick)="edit($event)">
    </jbm-table>
</section>

<ng-template #templatePermissions let-data="data">
    <recipe-user-permissions [user_id]="data.id"></recipe-user-permissions>
</ng-template>

<jbm-modal [id]="'user'" [className]="'user'"></jbm-modal>
<ng-template #userModal let-modal>
    <form-recipe-user [user]="user" (onSave)="userSaved()" (onCancel)="userCanceled()"></form-recipe-user>
</ng-template>
