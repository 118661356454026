import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslateService } from "../services/multilingual/translate.service";

export interface ComponentCanDeactivate {
	canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable( )
export class ChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
	message: string;
	constructor(private TranslateService: TranslateService) {
		this.message=this.TranslateService.GetTranslation('ui.discarded-changes');
	}
	canDeactivate( component: ComponentCanDeactivate ): boolean | Observable<boolean> {
		return component.canDeactivate() ? true : confirm( this.message );
	}
}