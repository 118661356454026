<app-header>
    <jbm-view-title [caption]="'company.and-plants'|translate"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'">
    </jbm-view-title>
   <div class="btn-group float-end">
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module padding">
    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs module navs bg-light pt-2 pb-2">
        <ng-container ngbNavItem [ngbNavItem]="0">
            <a ngbNavLink>{{'company'|translate}}</a>
            <ng-template ngbNavContent>
              <div class="mt-3">
                <form-company></form-company>
              </div>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem [ngbNavItem]="1">
          <a ngbNavLink>{{'company.settings'|translate}}</a>
          <ng-template ngbNavContent>
            <div class="mt-3">
              <form-company-settings></form-company-settings>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink>{{'company.plants'|translate}}</a>
            <ng-template ngbNavContent>
                <div class="mt-3">
                    <plants-table></plants-table>
                </div>
            </ng-template>
        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>
