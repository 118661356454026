import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../../services/storage/state.service";

@Component({
  selector: 'jbm-activity-log-button',
  templateUrl: './JBMActivityLogButton.component.html',
  styleUrls: ['./JBMActivityLogButton.component.scss'],
  host: {'class': 'JBMButton'}
})
export class JBMActivityLogButtonComponent implements OnInit {

  @Input() subject_type: number=0;
  @Input() subject_id: number=0;
  @Input() subject: string='';
  @Input() title: string='';
  @Input() class: string;

  constructor(
      private Router: Router,
      private StateService: StateService) {}

  ngOnInit(): void {
    if(this.subject_type==undefined) this.subject_type=0;
    if(this.subject_id==undefined) this.subject_id=0;
    if(this.subject==undefined) this.subject='';
    if(this.title==undefined) this.title='';
    if(this.class==undefined) this.class='light';
  }
  click() {

    // Set Activity Log data in State Provider
    this.StateService.setData({
      subject_type: this.subject_type,
      subject_id: this.subject_id,
      subject: this.subject,
      title: this.title
    });
    // Show the Activities Log
    this.Router.navigate([ { outlets: {'ar': ['history']}} ] );
  }
}
