<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.invoice'|translate}} {{'entity.send'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <button type="button" class="btn btn-sm btn-primary" (click)="save();">{{'entity.email'|translate}} {{'entity.send'|translate|lowercase}}</button>
      <div class="toolbar-spacer-1"></div>
      <button type="button" class="btn btn-sm btn-secondary" (click)="close();">{{'ui.cancel'|translate}}</button>
      <div class="toolbar-spacer-1"></div>
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
   </div>
</div>
<div class="jbm-modal-body">
   <form [formGroup]="form" (ngSubmit)="save()" class="send">
      <div class="row">
         <div class="col-6">
            <jbm-static-label labelClass="md" [labelCaption]="'invoice.number'|translate" [value]="invoice.invoice_number"></jbm-static-label>
            <jbm-date-input labelClass="md" labelCaption="{{'invoice.date'|translate}}"
              [formControl]="formControls.invoice_date" placement="bottom" [submitted]="submitted">
               <jbm-input-error [required]="false" [formControl]="formControls.invoice_date" [submitted]="submitted">
                  <span *ngIf="formControls['invoice_date'].hasError('dateInvalid')">
                    {{'entity.dateformat'|translate}}
                  </span>
               </jbm-input-error>
            </jbm-date-input>
            <jbm-integer-input-group [formControl]="formControls.term_of_payment" [required]="true" [append]="'datetime.days'|translate" [submitted]="submitted"
              [labelCaption]="'invoice.term-of-payment'|translate" labelClass="md" class="xxxs" [id]="'term_of_payment'" [maxlength]="3">
               <jbm-input-error [formControl]="formControls.term_of_payment" [submitted]="submitted">
                  <span *ngIf="formControls.term_of_payment.hasError('required')">{{'entity.requiredfield'|translate}}</span>
                  <span *ngIf="formControls.term_of_payment.hasError('min')">{{'invoice.term-of-payment-range'|translate}}</span>
                  <span *ngIf="formControls.term_of_payment.hasError('max')">{{'invoice.term-of-payment-range'|translate}}</span>
                  <span *ngIf="formControls.term_of_payment.hasError('pattern')">{{'invoice.term-of-payment-range'|translate}}</span>
               </jbm-input-error>
            </jbm-integer-input-group>
         </div>
         <div class="col-6">
            <jbm-textarea [id]="'comments'" [formControl]="formControls.comments" [submitted]="submitted" [rows]="4" labelStacked="true"
              class="xl" [labelCaption]="('invoice.state'|translate) + ' ' + ('entity.comments'|translate|lowercase)">
            </jbm-textarea>
         </div>
      </div>

      <nav ngbNav #nav="ngbNav" class="nav-tabs mt-3">
         <ng-container ngbNavItem [ngbNavItem]="0">
            <a ngbNavLink>{{'entity.email-message'|translate}}</a>
            <ng-template ngbNavContent>
               <jbm-text-input [id]="'email'" [formControl]="formControls.email" [required]="true" [submitted]="submitted" [labelCaption]="'entity.emailaddress'|translate" class="xl" labelClass="md">
                  <jbm-input-error [formControl]="formControls.email" [submitted]="submitted">
                     <span *ngIf="formControls.email.hasError('required')">{{'entity.requiredfield'|translate}}</span>
                  </jbm-input-error>
               </jbm-text-input>
               <jbm-text-input [id]="'subject'" [formControl]="formControls.subject" [required]="true" [submitted]="submitted" [labelCaption]="'entity.email-subject'|translate" class="xl" labelClass="md">
                  <jbm-input-error [formControl]="formControls.subject" [submitted]="submitted">
                     <span *ngIf="formControls.subject.hasError('required')">{{'entity.requiredfield'|translate}}</span>
                  </jbm-input-error>
               </jbm-text-input>
               <nav ngbNav #navMessage="ngbNav" class="nav-tabs mt-3 message">
                  <ng-container ngbNavItem [ngbNavItem]="1">
                     <a ngbNavLink>{{'template-editor.html-body'|translate}}</a>
                     <ng-template ngbNavContent>
                        <div class="html-body">
                           <mail-viewer [base64]="mailBase64"></mail-viewer>
                        </div>
                     </ng-template>
                  </ng-container>
                  <ng-container ngbNavItem [ngbNavItem]="0">
                     <a ngbNavLink>{{'template-editor.text-body'|translate}}</a>
                     <ng-template ngbNavContent>
                        <div class="text-body">
                           <textarea [rows]="8" class="form-control">{{mail.text_body}}</textarea>
                        </div>
                     </ng-template>
                  </ng-container>
               </nav>
               <div [ngbNavOutlet]="navMessage" class="mt-2"></div>
            </ng-template>
         </ng-container>
         <ng-container ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink>{{'entity.email-attachments'|translate}}</a>
            <ng-template ngbNavContent>
               <jbm-table [classname]="'attachments'"
                 [data]="attachments"
                 [filterable]="false"
                 [sortable]="true"
                 [rowSelectable]="true"
                 [sortColumn]="queryHttpHelper.sortProps.name"
                 [sortDirection]="queryHttpHelper.sortProps.direction"
                 [hoverable]="true"
                 [columnDefs]="columnDefs">
               </jbm-table>
               <media-files-selector [padding]="false" (onSelect)="selectMediaFile($event)"></media-files-selector>
            </ng-template>
         </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
   </form>
</div>

<div [hidden]="!preview" class="document-preview">
   <div class="header">
      <div class="title float-start">
         {{'ui.preview'|translate}}: <span class="fw-bold">{{description}}</span>
      </div>
      <jbm-cross-close-button class="float-end" (onClick)="preview=!preview"></jbm-cross-close-button>
   </div>
   <jbm-document-viewer [base64]="base64" [fileExtension]="fileExtension"></jbm-document-viewer>
</div>

<ng-template #actionsTemplate let-data="data">
   <button type="button" class="btn btn-sm btn-light mr-3 float-start" (click)="showPreview(data); $event.stopPropagation()">{{'ui.preview'|translate}}</button>
   <jbm-confirm-delete-dropdown class="float-start"
     [ngbTooltip]="'ui.delete-confirm-tooltip'|translate"
     [placement]="'left-top'"
     [subject]="('entity.email-attachment'|translate)"
     (onConfirm)="delete(data.mail_attachments_id)">
   </jbm-confirm-delete-dropdown>
</ng-template>

