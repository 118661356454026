<form [formGroup]="form" (ngSubmit)="save()" class="invoice-settings">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'entity.invoice'|translate}} {{'entity.settings'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label labelClass="md" [labelCaption]="'invoice.number'|translate" [value]="invoice.invoice_number"></jbm-static-label>
      <jbm-integer-input-group [formControl]="formControls.term_of_payment" [required]="true" [append]="'datetime.days'|translate"
        [labelCaption]="'invoice.term-of-payment'|translate" labelClass="md" class="xxxs" [id]="'term_of_payment'" [maxlength]="3">
         <jbm-input-error [formControl]="formControls.term_of_payment" [submitted]="submitted">
            <span *ngIf="formControls.term_of_payment.hasError('required')">{{'entity.requiredfield'|translate}}</span>
            <span *ngIf="formControls.term_of_payment.hasError('min')">{{'invoice.term-of-payment-range'|translate}}</span>
            <span *ngIf="formControls.term_of_payment.hasError('max')">{{'invoice.term-of-payment-range'|translate}}</span>
            <span *ngIf="formControls.term_of_payment.hasError('pattern')">{{'invoice.term-of-payment-range'|translate}}</span>
         </jbm-input-error>
      </jbm-integer-input-group>
      <jbm-check [formControl]="formControls.shifted_vat"
        labelCaption="{{'customer.shift-vat-setting'|translate}}"
        labelClass="md" [id]="'shifted_vat'" (change)="shiftedVatChange($event)">
      </jbm-check>
      <jbm-check *ngIf="invoice.usage==0 || invoice.usage==1" [formControl]="formControls.resources_based_invoicing"
         labelCaption="{{'invoicing.on-resources-based'|translate}} {{'invoice.invoicing'|translate|lowercase}}"
         labelClass="md" [id]="'resources_based_invoicing'">
      </jbm-check>
      <fieldset class="width-33 mt-3"><legend>{{'menu.presentations'|translate}}</legend>
         <presentation-selector
           labelCaption="PDF" labelClass="md" class="md"
           [required]="true"
           [presentation_type]="2"
           [output_type]="0"
           [usage]="invoice.usage"
           [presentation_id]="formControls.pdf_presentations_id.value"
           [template]="pdfselect"
           (presentationSelected)="setPDFPresentation($event)">
         </presentation-selector>
         <ng-template #pdfselect let-presentation="presentation">
            <div *ngIf="presentation">
               {{presentation.description}}
            </div>
         </ng-template>
         <presentation-selector
           [labelCaption]="'entity.email'|translate" labelClass="md" class="md"
           [required]="true"
           [presentation_type]="2"
           [output_type]="1"
           [usage]="invoice.usage"
           [template]="pdfselect"
           [presentation_id]="formControls.mail_presentations_id.value"
           (presentationSelected)="setMailPresentation($event)">
         </presentation-selector>
         <ng-template #mailselect let-presentation="presentation">
            <div *ngIf="presentation">
               {{presentation.description}}
            </div>
         </ng-template>
      </fieldset>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'entity.settings'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
