import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {ArticlePricelistsService, PricelistsType} from "../../../../features/financial/data/article-pricelists.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {ArticlePricelist} from "../../../../features/financial/data/interfaces/article-pricelist";
import {JBMTableColumnAlignment, JBMTableColumnDef, JBMTableColumnType} from "../../../JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMSelectOption} from "../../../JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'concrete-pricelist-selector',
  templateUrl: './concrete-pricelist.selector.component.html',
  styleUrls: ['./concrete-pricelist.selector.component.scss']
})
export class ConcretePricelistSelectorComponent implements OnInit, OnDestroy, OnChanges {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  pricelist: ArticlePricelist;
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;
  @Input() template: TemplateRef<any>;
  @Output() onBlur = new EventEmitter();

  @Input() pricelist_id: number=0;
  @Input() types: string='';
  @Input() actualOnly: boolean=true;
  @Output() pricelistSelected = new EventEmitter();

  constructor(
      private ArticlePricelistsService: ArticlePricelistsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';
    if(this.types==undefined) this.types='';
    if(this.actualOnly==undefined) this.actualOnly=true;

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true},
      { name: 'valid_from', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('pricelist.valid-from'), sortable: true, filterable: true },
      { name: 'valid_to', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('pricelist.valid-to'), sortable: true, filterable: true },
      { name: 'type', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('pricelist.type'), filterable: true, width: 12,
        align: JBMTableColumnAlignment.left,
        filterValues: this.ArticlePricelistsService.getTypeSelectOptions(this.TranslateService) as JBMSelectOption[],
        displayValues: this.ArticlePricelistsService.getTypeDisplayValues(this.TranslateService)
      },
    ];
    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('valid_to',true);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.pricelist_id) this.setPricelist();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('pricelist-search').focus() }, 100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.pricelist_id=0;
    this.hideList();
    this.pricelistSelected.emit({ id: 0 } );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.setParams(false);
    this.items$=this.ArticlePricelistsService.get(PricelistsType.concrete, this.language, params).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          if(countRows) {
            params=this.setParams(true);
            this.count$=this.ArticlePricelistsService.get(PricelistsType.concrete, this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  setParams(countRows:boolean) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    if(this.actualOnly) params=params.append('actual_only','1');
    if(this.types) params=params.append('types', this.types);
    return params;
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    // Emit event
    this.pricelist_id=row.id;
    this.setPricelist();
    this.hideList();
    this.pricelistSelected.emit( row );
  }
  setPricelist() {
    this.ArticlePricelistsService.getOne(PricelistsType.concrete, this.pricelist_id, this.language).subscribe(
        (data: any)=>this.pricelist=data.data as ArticlePricelist,
        (error)=>console.error(error)
    )
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
