import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { MediaFile } from "./interfaces/media_file";

const baseURL: string = 'presentations/';
const segment: string = 'media-files/';

@Injectable({
  providedIn: 'root'
})
export class MediaFilesService {
  mediaFile: MediaFile;

  constructor(
      private HttpService: HttpService,
  ) {}

  get(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment, params );
  }
  getOne(id :number) {
    return this.HttpService.get(baseURL+'read/'+segment+'one/'+id.toString(),null );
  }
  getEmpty() {
    this.mediaFile = {
      id: 0,
      description: '',
      filename: '',
      extension: '',
      size: 0,
      content: ''
    }
    return this.mediaFile;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data );
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  getDeletePermissions(ids: string[]) {
    return this.HttpService.get(baseURL+'read/media-file-delete-permissions/'+ids.join());
  }
  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+id.toString());
  }
}
