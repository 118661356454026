import { Injectable } from '@angular/core';
import {AbstractControl, FormGroup, ValidatorFn} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {

  constructor() { }

  // Validator to check that two control values match
  ValuesMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      if(control.errors)
        // Return if another validator has already found an error on the control
        return;
      const matchingControl = formGroup.controls[matchingControlName];
      if(matchingControl.errors && !matchingControl.errors.mustMatch)
        // Return if another validator has already found an error on the matchingControl
        return;
      // Set error on matchingControl if validation fails
      if(control.value !== matchingControl.value)
        matchingControl.setErrors({ mustMatch: true });
      else
        matchingControl.setErrors(null);
    }
  }

  passwordValidator(minLength: number=8, digitsRequired=true): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      if(!control.dirty && !control.touched)
        return null;

      let errCode='passwordInvalid';
      let value=control.value.trim();

      if(this.validatePassword(value, minLength, digitsRequired))
        return null;
      else
        return {[errCode]: true};
    }
  }
  validatePassword(password: string, minLength: number=8, digitsRequired=true): boolean {
    password=password.trim();

    if(password.indexOf(' ')>-1)
      return false;
    if(password.length<minLength)
      return false;

    let uppercase=new RegExp('[A-Z]').test(password);
    let lowercase=new RegExp('[a-z]').test(password);

    if(digitsRequired) {
      let digit=new RegExp('[0-9]').test(password);
      if(!digit) return false;
    }

    return (uppercase && lowercase);
  }
}
