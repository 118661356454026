import {Component, OnInit, OnDestroy} from '@angular/core';
import {ResourceTypesService} from "../data/resource-types.service";
import {TokenService} from "../../../services/auth/token.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'resources-types',
  templateUrl: './resources-types.component.html',
  styleUrls: ['./resources-types.component.scss']
})
export class ResourcesTypesComponent implements OnInit, OnDestroy {
  userRights: any;
  types: any[]=[];
  types$: Subscription;
  activeTab: number;

  constructor(
      private ResourceTypesService: ResourceTypesService,
      private TokenService: TokenService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('resources');
    this.getData();
  }
  getData() {
    this.types$=this.ResourceTypesService.get().subscribe(
        (data: any)=>this.types=data.data,
        (error)=>console.error(error),
        ()=>this.activeTab=0
    );
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.types$!=undefined) this.types$.unsubscribe();
  }
}
