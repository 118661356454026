import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { DatetimeService } from "../../../services/datetime/datetime.service";
import { Order } from "./interfaces/order";
import {addHours, addMinutes, format, roundToNearestMinutes, subMinutes} from "date-fns";

const baseURL: string = 'projects/';
const slug: string = 'orders/';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  order: Order;

  constructor(
      private HttpService: HttpService,
      private DatetimeService: DatetimeService,
  ) { }

  get(projects_id: number, language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+language+'/'+projects_id, params );
  }
  getByID(id:number) {
    return this.HttpService.get(baseURL+'read/'+slug+'getbyid/'+id.toString());
  }
  getOne(id:number, language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id.toString()+'/'+language, params );
  }
  getProjectPricetotals(projects_id: number) {
    return this.HttpService.get(baseURL+'read/'+slug+'projectpricetotals/'+projects_id, null );
  }
  getEmpty() {
    this.order = {
      id: 0,
      projects_id: 0,
      description: null,
      recipes_id: null,
      consistencies_id: null,
      environments_id: null,
      strengths_id: null,
      amount: 0.0,
      delivered: 0.0,
      concrete_price: 0.0,
      worktypes_id: 0,
      comments: null,
      recipe_comments: null,
      unloading_methods_id: 0,
      unloading_flow: null,
      production_date: null,
      starttime: null,
      loadtime: null,
      on_call: false
    }
    return this.order;
  }
  copy(order_id: number, production_date: string) {
    return this.HttpService.get(baseURL+'update/'+slug+'copy/'+order_id.toString()+'/'+production_date);
  }
  create(data: any, language: string) {
    return this.HttpService.post(baseURL+'create/'+slug+language, data);
  }
  update(data: any, language: string) {
    return this.HttpService.post(baseURL+'update/'+slug+language, data);
  }
  updateAmount(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug+'amount', data);
  }

  // Calculations

  // Get the concrete truck loading time in minutes
  getLoadingTime() {
    return this.HttpService.get(baseURL+'read/'+slug+'loadingtime', null );
  }

  calcLoadTime( startTime: string, travelTime: number, loadingTime: number ) {
    // Calculate loadtime (starttime - (traveltime + loadingtime in minutes))
    let timeParts=this.DatetimeService.getTimeParts(startTime);
    let dateStart=new Date(2000,1,1, timeParts.hours, timeParts.minutes,0);
    let dateLoad=subMinutes(dateStart,travelTime + loadingTime );
    // Round time to nearest quarter
    dateLoad=roundToNearestMinutes(dateLoad,{ nearestTo: 15 });
    return format(dateLoad,'HH:mm');
  }

  calcEndTime( startTime: string, amount: number, unloadingFlow: number ) {
    let timeParts=this.DatetimeService.getTimeParts(startTime);
    let dateStart=new Date(2000,0,1, timeParts.hours, timeParts.minutes,0);
    amount = Math.ceil(amount); // Round up to next largest integer
    let dateEnd=addHours(dateStart, (amount/unloadingFlow));
    // Round time to nearest quarter
    dateEnd=roundToNearestMinutes(dateEnd,{ nearestTo: 15 });
    return format(dateEnd,'HH:mm');
  }

  delete(id: number) {
    return this.HttpService.get(baseURL+'delete/'+slug+id.toString());
  }
}
