import { Injectable } from '@angular/core';
import { TranslateService } from "../multilingual/translate.service";

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  constructor(private TranslateService: TranslateService) {}

  getActivityItems() {
    return [
      { key: 'read', value: this.TranslateService.GetTranslation('userrights.read') },
      { key: 'create', value: this.TranslateService.GetTranslation('userrights.create') },
      { key: 'update', value: this.TranslateService.GetTranslation('userrights.update') },
      { key: 'delete', value: this.TranslateService.GetTranslation('userrights.delete') }
    ];
  }

}
