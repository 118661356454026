import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {InvoiceValidation} from "./interfaces/invoice-validation";

const baseURL: string = 'invoicing/';
const segment: string = 'validations/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceValidationsService {
  validation: InvoiceValidation;

  constructor(
      private HttpService: HttpService,
  ) {}
  get(invoice_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment+invoice_id.toString(), params);
  }
  getEmpty() {
    this.validation = {
      id: 0,
      invoices_id: 0,
      users_id :0,
      date: '',
      time: '',
      comments: '',
      approved: false
    }
    return this.validation;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
}
