import { Component } from '@angular/core';
import {Router, RoutesRecognized} from "@angular/router";
import {TokenService} from "./services/auth/token.service";
import localeNL from '@angular/common/locales/nl';
import localeEN from '@angular/common/locales/en';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import {registerLocaleData} from "@angular/common";

export enum AppLayouts {
  Guest,              // Guest layout
  ExternalOutlet,     // External (New browser tab) Outlet layout
  User                // User session layout
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  language: string;
  title = 'JBM - CIS';

  AppLayouts = AppLayouts
  AppLayout: AppLayouts;

  constructor( private router: Router, private TokenService: TokenService ) {}

  ngOnInit() {
    this.language=this.TokenService.getLanguage();
    if(!this.language)
      // No token or no language in token; assume 'nl'
      registerLocaleData(localeNL,'nl');
    else
      switch(this.language) {
        case 'nl':
          registerLocaleData(localeNL, 'nl');
          break;
        case 'en':
          registerLocaleData(localeEN, 'en');
          break;
        case 'de':
          registerLocaleData(localeDE, 'de');
          break;
        case 'fr':
          registerLocaleData(localeFR, 'fr');
          break;
      }

    // We can't use `ActivatedRoute` here since we are not within a `router-outlet` context yet.
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.AppLayout = data.state.root.firstChild.data.AppLayout;
      }
    });
  }
}
