import {Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MailSettingsService} from "../data/mail-settings.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {MailSettings} from "../data/interfaces/mail-settings";

@Component({
  selector: 'form-email-settings',
  templateUrl: './mail-settings.component.html',
  styleUrls: ['./mail-settings.component.scss']
})
export class MailSettingsComponent implements OnInit {
  mailSettings: MailSettings;
  form: FormGroup;
  isSubmitted: boolean=false;

  @Input() users_id: number;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
      private MailSettingsService: MailSettingsService,
      private ModalService: ModalService,
      private formBuilder: FormBuilder
  ) {
    this.mailSettings=this.MailSettingsService.getEmpty();
    this.mailSettings.users_id=this.users_id;
    this.addControls();
  }
  ngOnInit() {
    this.MailSettingsService.getOne(this.users_id).subscribe(
        (data)=>{ if(data.data.length===1) this.mailSettings=data.data[0]; },
        (error)=>console.error(error),
        ()=> {
          this.setControlValues();
          document.getElementById('name').focus();
        }
    );
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          name: new FormControl('',{updateOn: 'blur', validators: [Validators.required]} ),
          description: new FormControl(''),
          smtp: new FormControl('',{updateOn: 'blur', validators:[Validators.required]} ),
          port: new FormControl('',{updateOn: 'blur', validators:[Validators.required, Validators.pattern('[1-9][0-9]*')]} ),
          password: new FormControl('')
        }
    );
  }
  setControlValues() {
    this.form.controls.name.setValue(this.mailSettings.name);
    this.form.controls.description.setValue(this.mailSettings.description);
    this.form.controls.smtp.setValue(this.mailSettings.smtp);
    this.form.controls.port.setValue(this.mailSettings.port===0 ? '' : this.mailSettings.port.toString());
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    this.isSubmitted = true;

    if(this.mailSettings.id===0) {
      let password=this.formControls.password.value.trim();
      if(password==='')
        this.formControls.password.setErrors({'required': true} );
    }

    if(this.form.invalid)
      return;

    // Store mail settings
    this.mailSettings.users_id=this.users_id;
    this.mailSettings.name=this.formControls.name.value;
    this.mailSettings.description=this.formControls.description.value;
    this.mailSettings.smtp=this.formControls.smtp.value;
    this.mailSettings.port=parseInt(this.formControls.port.value);
    this.mailSettings.password='';
    if(this.formControls.password.value)
      this.mailSettings.password=this.formControls.password.value;

    if(this.mailSettings.id===0) {
      this.MailSettingsService.create(this.mailSettings).subscribe(
          ()=>{},error=>this.handleError(error),
          ()=>this.onSave.emit()
      )
    } else {
      this.MailSettingsService.update(this.mailSettings).subscribe(
          ()=>{},error=>this.handleError(error),
          ()=>this.onSave.emit()
      )
    }
  }
  afterSave() {
    this.onSave.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
  handleError(error) {
    if(error=='not_unique') {
      this.formControls.name.setErrors({notUnique: true});
      document.getElementById('name').focus();
    }
  }
}
