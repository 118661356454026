import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'jbm-spinner',
  templateUrl: './JBMSpinner.component.html',
  styleUrls: ['./JBMSpinner.component.scss']
})
export class JBMSpinnerComponent implements OnInit {

  @Input() loading: boolean=false;

  constructor() { }

  ngOnInit(): void {
    if(this.loading==undefined) this.loading=false;
  }
}
