import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {InvoicesService} from "../data/invoices.service";
import {InvoiceStatesService} from "../data/invoice-states.service";
import {InvoiceFlowsService} from "../data/invoice-flows.service";
import {InvoiceAttachmentsService} from "../data/invoice-attachments.service";
import {PriceService} from "../../../services/price/price.service";
import {setting_subjects, SettingsService} from "../../../services/storage/settings.service";
import {Invoice} from "../data/interfaces/invoice";
import {InvoiceState} from "../data/interfaces/invoice-state";
import {HttpParams} from "@angular/common/http";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {InvoiceItemsComponent} from "../items/invoice-items.component";
import {InvoiceResourcesComponent} from "../resources/invoice-resources.component";

@Component({
  selector: 'invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnInit {
  userRights: GroupRights;
  editable: boolean=true;
  activeTab: number=1;
  taxInclusive: boolean=true;
  language: string;
  invoice: any;
  invoiceState: InvoiceState;
  viewSettings: any={ details: true, recipes: true, orders: true, comments: true, resources: false };
  historyView: boolean=false;

  @ViewChild('invoiceItems') invoiceItems: InvoiceItemsComponent;
  @ViewChild('invoiceResources') invoiceResources: InvoiceResourcesComponent;

  @ViewChild('modalStatesFlow') modalStatesFlow: ElementRef;
  @ViewChild('modalReceipts') modalReceipts: ElementRef;
  @ViewChild('modalOrders') modalOrders: ElementRef;
  @ViewChild('modalValidate') modalValidate: ElementRef;
  @ViewChild('modalHandlePayment') modalHandlePayment: ElementRef;
  @ViewChild('modalApprove') modalApprove: ElementRef;
  @ViewChild('modalCopy') modalCopy: ElementRef;
  @ViewChild('modalMakeConcept') modalMakeConcept: ElementRef;
  @ViewChild('modalSend') modalSend: ElementRef;
  @ViewChild('modalReject') modalReject: ElementRef;
  @ViewChild('modalRemove') modalRemove: ElementRef;
  @ViewChild('modalSettings') modalSettings: ElementRef;
  @ViewChild('modalPreview') modalPreview: ElementRef;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private TokenService: TokenService,
      private InvoicesService: InvoicesService,
      private InvoiceStatesService: InvoiceStatesService,
      private InvoiceFlowsService: InvoiceFlowsService,
      private InvoiceAttachmentsService: InvoiceAttachmentsService,
      private PriceService: PriceService,
      private SettingsService: SettingsService,
      private ModalService: ModalService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) {
    this.userRights=this.TokenService.getRightsByName('invoicing');

    // The route resolver has fetched the data
    this.invoice=this.ActivatedRoute.snapshot.data.invoice.data;

    // Editing invoice allowed if in Draft or Approved state
    this.editable=this.userRights.AllowUpdate && this.invoice.invoice_states_id<300;
    this.invoiceState=this.InvoiceStatesService.getEmpty();
  }
  ngOnInit(): void {
    this.language=this.TokenService.getLanguage();

    this.ActivatedRoute.data.subscribe(data => {
      this.historyView=data.hasOwnProperty('history');
      // No editing allowed in invoice history mode
      if(this.historyView) this.editable=false;
    });

    let exists=true;
    this.SettingsService.subjectExists( this.SettingsService.getUserID(), setting_subjects.invoicing ).subscribe(
        (data)=>exists=data.exists,
        (error)=>console.error(error),
        ()=> {
            if(exists)
              this.getViewSettings()
            else
              this.saveViewSettings()
        }
    );

    this.setInvoiceState();
  }
  setInvoiceState() {
    let params=new HttpParams().append('id',this.invoice.invoice_states_id.toString());
    this.InvoiceStatesService.get(this.language, params).subscribe(
        (data)=>this.invoiceState=data,
        (error)=>console.error(error)
    )
  }
  refreshInvoice() {
    let params=new HttpParams()
        .append('customer','1')
        .append('project','1')
        .append('permissions','1');
    this.InvoicesService.getOne(this.language, this.invoice.id, params).subscribe(
      (data)=>this.invoice=data.data as Invoice,
      (error)=>console.error(error),
      ()=>{
        this.editable=this.invoice.invoice_states_id<300;
        this.taxInclusive=!this.invoice.shifted_vat;

        // Refresh invoice items component
        if(this.invoice.resources_based_invoicing)
          this.invoiceResources.getData();
        else
          this.invoiceItems.getData();
      }
    )
  }
  refreshState() {
    let invoice=null;
    this.InvoicesService.getOne(this.language, this.invoice.id,null).subscribe(
        (data)=>invoice=data.data,
        (error)=>console.error(error),
        ()=> {
          this.invoice.invoice_states_id = invoice.invoice_states_id;
          this.setInvoiceState()
        }
    )
  }
  toggleTaxInclusive() {
    this.taxInclusive=!this.taxInclusive;
  }

  projectSelected() {
    this.refreshInvoice();
  }

  // View Settings
  getViewSettings() {
    this.SettingsService.getSubject(this.SettingsService.getUserID(), setting_subjects.invoicing).subscribe(
        (data)=>this.viewSettings=data.settings,(error)=>console.error(error)), ()=>console.log(this.viewSettings)
  }
  saveViewSettings() {
    this.SettingsService.setSubject(this.SettingsService.getUserID(), setting_subjects.invoicing,null, this.viewSettings).subscribe(
        ()=>{},(error)=>console.error(error))
  }
  updateSettings(event) {
    this.viewSettings=event.settings;
    this.saveViewSettings();
  }

  // Receipts
  editReceipts() {
    this.ModalService.open(this.modalReceipts,'invoiceReceipts');
  }
  addedReceipt() {
    this.refreshInvoice();
  }
  deletedReceipt() {
    this.refreshInvoice();
  }
  receiptsClose() {
    this.ModalService.close('invoiceReceipts');
  }

  // Orders
  editOrders() {
    this.ModalService.open(this.modalOrders,'invoiceOrders');
  }
  addedOrder() {
    this.refreshInvoice();
  }
  deletedOrder() {
    this.refreshInvoice();
  }
  ordersClose() {
    this.ModalService.close('invoiceOrders');
  }

  // Invoice states flow
  showStateFlow() {
    this.ModalService.open(this.modalStatesFlow,'statesFlow');
  }
  statesFlowClose() {
    this.ModalService.close('statesFlow');
  }

  // Invoice state actions
  validate() {
    this.ModalService.open(this.modalValidate,'stateAction');
  }
  validateSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.refreshInvoice();
    this.refreshState();
  }
  approve() {
    this.ModalService.open(this.modalApprove,'stateAction');
  }
  approveSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.approved'));
    this.refreshInvoice();
    this.refreshState();
  }
  copy() {
    this.ModalService.open(this.modalCopy,'stateAction');
  }
  copySuccess(data: any) {
    this.ModalService.close('stateAction');
    this.Router.navigateByUrl('invoicing/'+data.invoice_id.toString())
  }
  makeConcept() {
    this.ModalService.open(this.modalMakeConcept,'stateAction');
  }
  makeConceptSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.made-concept'));
    this.refreshState();
  }
  send() {
    this.InvoiceAttachmentsService.check(this.invoice.id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.ModalService.open(this.modalSend,'invoiceSend');
        }
    );
  }
  sendSuccess() {
    this.ModalService.close('invoiceSend');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('entity.invoice') + ' '+ this.TranslateService.GetTranslation('entity.send'));
    this.refreshInvoice();
    this.refreshState();
  }
  sendClose() {
    this.ModalService.close('invoiceSend');
  }
  handlePayment() {
    this.ModalService.open(this.modalHandlePayment,'stateAction');
  }
  paymentSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.payment-handled'));

    let invoice=null;
    this.InvoicesService.getOne(this.language, this.invoice.id,null).subscribe(
        (data)=>invoice=data.data,
        (error)=>console.error(error),
        ()=> {
          this.invoice.outstanding=invoice.outstanding;
          this.invoice.paid=invoice.paid;
          this.invoice.invoice_states_id=invoice.invoice_states_id;
          this.refreshInvoice();
          this.setInvoiceState()
        }
    )
  }
  reject() {
    this.ModalService.open(this.modalReject,'stateAction');
  }
  rejectSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('invoice.rejected'));
    this.refreshState();
  }
  settings() {
    this.ModalService.open(this.modalSettings,'invoiceSettings');
  }
  settingsSuccess() {
    this.ModalService.close('invoiceSettings');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.refreshInvoice();
  }
  settingsClose() {
    this.ModalService.close('invoiceSettings');
  }

  remove() {
    this.ModalService.open(this.modalRemove,'stateAction');
  }
  removeSuccess() {
    this.stateActionClose();
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
    this.Router.navigateByUrl('invoicing');
  }

  stateActionClose() {
    this.ModalService.close('stateAction');
  }
}
