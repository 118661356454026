import { Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { HttpService } from "../../services/http.service";
import { LanguagesService} from "../../services/multilingual/languages.service";
import { TranslateService } from "../../services/multilingual/translate.service";
import { TokenService} from "../../services/auth/token.service";
import { QueryHttpHelper} from "../../components/JBM/Helpers/QueryHttpHelper";
import { Observable, Subscription} from "rxjs";
import { JBMToastsService} from "../../components/JBM/Views/JBMToasts/JBMToasts.service";

export interface View {
  language: string;
  translated: boolean;
  searchValue: string;
}

@Component({
  selector: 'app-translations',
  templateUrl: './translations.component.html',
  styleUrls: ['./translations.component.scss']
})
export class TranslationsComponent implements OnInit, OnDestroy {

  @HostListener("window:beforeunload")
  ComponentCanDeactivate(): Observable<boolean> | boolean { return false; }

  userRights: any;
  translations: any[]=[];
  updates: any[]=[];
  loadedLanguageUpdated: boolean=false;

  languages: any[]=[];
  languageCount: any[]=[];
  translateprogress:number=0;
  view: View;

  queryHttpHelper: QueryHttpHelper;
  showFilter: boolean=false;
  searchboxVisible: boolean=true;

  createActive: boolean=false;
  newKey: string='';
  newTranslation: string='';
  keyTouched: boolean=false;
  translationTouched: boolean=false;

  translations$: Subscription;
  count$: Subscription;

  constructor(
    private HttpService: HttpService,
    private TranslateService : TranslateService,
    private LanguagesService: LanguagesService,
    private TokenService: TokenService,
    private JBMToastsService: JBMToastsService
  ) {}
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('translations');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('key');

    this.initLanguages();

    // Init view properties
    this.view = { language: this.TokenService.getLanguage(), translated: true, searchValue: '' };

    this.getData(true);
  }
  initLanguages() {
    // Set language options and counts
    this.languages=this.LanguagesService.GetLanguages();
    for(let l=0; l<this.languages.length; l++) {
      this.languageCount.push( {language: this.languages[l].key, count: 0} );
    }
  }
  getData(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(false);
    this.translations$=this.TranslateService.getData(this.view, this.getParams(), params).subscribe(
        (data: any) => this.translations = data.data,()=>{},()=>{

          let empty='('+this.TranslateService.GetTranslation('ui.empty')+')';
          for(let t=0; t<this.translations.length; t++) {
            if(this.isUpdated(this.view.language, this.translations[t].key))
              // Show updated translation
              this.translations[t].translation=this.updatedTranslation(this.view.language, this.translations[t].key);
            if(!this.userRights.AllowUpdate && !this.translations[t].translation)
              // Show Empty if updating not allowed
              this.translations[t].translation=empty;
          }

          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.TranslateService.getData(this.view, this.getParams(), params).subscribe(
                (data: any) => this.updateCount(data.data),()=>{},()=>{} );
          }
        });
  }
  getParams(): string {
    let params='';
    if(this.view.searchValue) params+="/?search="+this.view.searchValue;
    return params;
  }
  updateCount(count) {
    if(this.view.searchValue) return; // We count only the total translation
    count=parseInt(count); // Can be string from server results
    this.queryHttpHelper.paginationProps.itemCount = count;
    this.updateLanguageCount(count);
    this.updateProgress(count);
  };
  updateProgress(count) {

    // Dutch translations are always complete
    if(this.view.language=='nl') return;

    let nl_count=this.getLanguageCount('nl');
    this.translateprogress=Math.round((count / nl_count) * 100);

    if(!this.view.translated)
      this.translateprogress=100-this.translateprogress;
  }
  updateLanguageCount(count) {
    for(let l=0; l<this.languageCount.length; l++)
      if(this.languageCount[l].language===this.view.language)
        this.languageCount[l].count=count;
  }
  saveUpdates() {
    this.TranslateService.updateTranslations(this.updates).subscribe( ()=>{},
        (error)=> console.log(error), ()=> {
          if(this.loadedLanguageUpdated)
            // Update loaded translations
            this.TranslateService.LoadTranslations();
          this.afterSave();
        })
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('translation.saved'));
    // Empty updates array
    this.updates=[];
    // Refresh view
    this.getData(true);
  }
  getLanguageCount(language) {
    for(let l=0; l<this.languageCount.length; l++)
      if(this.languageCount[l].language===language)
        return this.languageCount[l].count;
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.view.searchValue != value) {
      this.view.searchValue=value.toLowerCase();
      this.queryHttpHelper.paginationProps.page=1;
      this.getData(true);
    }
  }
  languageChange(event) {
    this.view.language=event.target.value;
    // Show untranslated items for foreign languages
    this.view.translated=this.view.language=='nl';
    this.view.searchValue='';
    this.queryHttpHelper.paginationProps.page=1;
    this.translateprogress=0;
    this.createActive=false;
    this.getData(true);
  }
  filterChange(event) {
    this.view.translated=(event.target.value==0);
    this.queryHttpHelper.paginationProps.page=1;
    this.view.searchValue='';
    this.getData(true);
  }

  // Create translation
  startCreate() {
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.newKey='';
    this.newTranslation='';
    this.keyTouched=false;
    this.translationTouched=false;
  }
  // Editing
  onBlur(event) {
    this.handleTranslation(event.target);
  }
  onFocus(line) {
    line.focused=true;
  }
  onLeave(line) {
    line.focused=false;
  }
  handleTranslation(input) {

    // Get key in input ID and value
    let key=input.id.replace('--key--','');
    let value=input.value.trim();

    // We don't store empty translation(s)
    if(value=='') {
      input.value='';
      this.deleteUpdate(this.view.language, key);
      return false;
    }

    // Store translation in updates array
    this.updates.push( {
      key: key,
      translated: this.view.translated,
      language: this.view.language,
      oldValue: input.placeholder,
      translation: value
    });

    if(this.TokenService.getLanguage()==this.view.language)
      this.loadedLanguageUpdated=true;
  }
  // Updates management
  updatedTranslation(language, key) {
    let index=this.findUpdate(language, key);
    if(index===-1) return false;
    return this.updates[index].translation;
  }
  deleteUpdate(language, key) {
    let index=this.findUpdate(language, key);
    if(index>-1) {
      // Delete update
      this.updates.splice(index, 1);
      return true;
    }
    return false;
  }
  findUpdate(language, key) {
    for(let u=0; u<this.updates.length; u++)
      if((this.updates[u].language===language) && (this.updates[u].key===key))
        return u;
    return -1;
  }
  isUpdated(language, key) {
    return this.findUpdate(language, key)>-1;
  }
  onUndo(language, key) {
    this.deleteUpdate(language, key);
    this.getData(false);
  }

  // Insert new translation
  onBlurKey() {
    this.keyTouched=true;
  }
  onBlurTranslation() {
    this.translationTouched=true;
  }
  validateKey() {
    let test=this.newKey.trim();
    // Validate key; not empty, only alphanumeric chars, - and . are allowed
    let regEx = /^[a-zA-Z0-9-.]*$/;
    return (test) && regEx.test(test);
  }
  validateTranslation() {
    return this.newTranslation.trim()!='';
  }
  insertTranslation() {
    // The API server handles insert as array of translation items
    let translations=[];
    translations.push({key: this.newKey.toLowerCase(), language: this.view.language, translation: this.newTranslation});

    this.TranslateService.insertTranslation(translations).subscribe( ()=>{},
        (error)=> console.log(error), ()=> {
          this.afterInsert();
        })
  }
  afterInsert() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Cancel create item modus
    this.createCancel();
    // Refresh view
    this.getData(true);
  }
  canDeactivate(): Observable<boolean> | boolean {
    // Leaving this task is allowed if no pending updates or active insert details
    return !(this.updates.length || this.createActive);
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.translations$!=undefined) this.translations$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
