import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuotationsService} from "../../data/quotations.service";
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {FormControl, FormGroup} from "@angular/forms";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'quotation-customer-comments',
  templateUrl: './customer-comments.component.html',
  styleUrls: ['./customer-comments.component.scss']
})
export class CustomerCommentsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  quotation: any= { quotation_number: '', customer_comments: '' };
  form: FormGroup;
  submitted: boolean=false;

  constructor(
      private QuotationsService: QuotationsService,
      private FormBuilder: FormBuilder,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) {
    this.form = this.FormBuilder.group({
      customer_comments: new FormControl('' ),
    });
  }

  @Input() quotation_id: number;
  @Input() quotation_number: string;
  @Input() revision_number: number;

  @Output() onClose=new EventEmitter();
  @Output() onSave=new EventEmitter();

  ngOnInit(): void {
    this.QuotationsService.getOne(this.quotation_id, this.revision_number).subscribe(
        (data)=>this.quotation=data.data,
        (error)=>console.error(error),
        ()=> {
          this.form.controls.customer_comments.setValue(this.quotation.customer_comments);
          document.getElementById('customer_comments').focus()
        }
    )
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    // Store customer comments
    let details={
      id: this.quotation.quotation_revisions_id,
      customer_comments: this.form.controls.customer_comments.value,
    }
    this.QuotationsService.updateRevision(details).subscribe(
      ()=>{},error=>console.error(error),()=> {
        this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.details-saved'));
        this.onSave.emit( { comments: details.customer_comments } );
      })
  }
  close() {
    this.onClose.emit();
  }

}
