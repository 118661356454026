import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PriceService} from "../../../services/price/price.service";
import {OrderArticlesService} from "../../projects/data/order-articles.service";
import {InvoiceArticlesService} from "../data/invoice-articles.service";
import {ArticlesService} from "../../financial/data/articles.service";
import {TokenService} from "../../../services/auth/token.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {InvoiceArticle} from "../data/interfaces/invoice-article";
import {GroupRights} from "../../users/data/interfaces/grouprights";

@Component({
  selector: 'invoice-mandatory-articles',
  templateUrl: './invoice-mandatory-articles.component.html',
  styleUrls: ['./invoice-mandatory-articles.component.scss']
})
export class InvoiceMandatoryArticlesComponent implements OnInit, OnChanges {
  language: string=this.TokenService.getLanguage();
  articles: any[]=[];
  article: any;
  article_id: number = 0;
  display_options: any[]=[];
  invoiceArticle: InvoiceArticle;

  price: number = 0.0;
  code: string = '';
  display: string = '';
  description: string='';
  descriptionTouched: boolean = false;
  descriptionError: string = '';

  amount: string = '';
  amountTouched: boolean = false;
  amountError: string = '';
  totalPrice: number = 0.0;

  editing: boolean = false;

  @Input() order_id: number;
  @Input() invoice_order_id: number;
  @Input() userRights: GroupRights;
  @Input() editable: boolean;

  constructor(
      private OrderArticlesService: OrderArticlesService,
      private InvoiceArticlesService: InvoiceArticlesService,
      private ArticlesService: ArticlesService,
      private PriceService: PriceService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private SanitizationService: SanitizationService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    if(this.order_id==undefined) this.order_id=0;
    if(this.invoice_order_id==undefined) this.invoice_order_id=0;
    if(this.editable==undefined) this.editable=true;

    this.display_options=this.ArticlesService.getDisplayItems(this.TranslateService);
  }
  ngOnChanges() {
    this.getData();
  }
  getData() {
    // let params=new HttpParams().append('order','1').append('receipt','1');
    this.OrderArticlesService.get( this.order_id, this.language,null).subscribe(
        (data)=>this.articles=data.data,
        (error)=>console.error(error),
        ()=> {
        }
    )
  }

  // Edit item
  startEditing(id) {
    for( let article of this.articles )
      if( article.id === id ) {
        if( article.editing ) return false;  // Row alreay in edit mode
        article.editing = true;
      } else
        article.editing = false;
    this.editing = true;
    let data = this.getOriginalValues();
    this.article_id = data.articles_id;
    this.amount = this.SanitizationService.amountFloatToStr(data.amount);
    this.price = data.price;
    this.calculateTotalPrice();
  }

  setArticle(article) {
    this.article_id = article.id;
    if( this.article_id === 0 ) return;
    this.price = this.PriceService.getPriceFromStr(article.price);
    this.code = article.price_type_code;
    this.description=article.description;
    this.display=this.display_options[article.display].value;
    this.calculateTotalPrice();
  }

  validateArticle() {
    return this.article_id > 0;
  }

  onChangeAmount() {
    this.amountTouched = true;
    this.calculateTotalPrice();
  }

  calculateTotalPrice() {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount, 0);
    this.totalPrice = this.PriceService.getTotalPrice(this.price, amount);
  }

  validateAmount() {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount, 0);
    if( amount < 1 )
      this.amountError = this.TranslateService.GetTranslation('ui.invalid');
    return amount > 0;
  }

  getOriginalValues() {
    for( let article of this.articles )
      if( article.editing )
        return article;
    return null;
  }

  setItemData(id) {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
    //price  = this.SanitizationService.checkPriceStr(this.price);

    return {
      id: id,
      invoice_orders_id: this.invoice_order_id,
      description: this.description,
      articles_id: this.article_id,
      price: this.price,
      amount: amount,
      totalprice: amount*this.price

      // todo order_articles_id
    };
  }

  clearItemData() {
    this.invoiceArticle = this.InvoiceArticlesService.getEmpty();
    this.amount = '';
    this.amountTouched = false;
    this.amountError = '';
  }

  // Save item data
  updateItem() {
    let originalValues = this.getOriginalValues();
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount);
    if( this.article_id == originalValues.articles_id && amount == originalValues.amount ) {
      // Nothing changed; pretend if it has been a successful update
      this.showSavedToast();
      this.cancelEditing();
      return false;
    }
    let data = this.setItemData(originalValues.id);

    this.InvoiceArticlesService.update(data).subscribe(
        () => {
        }, error => this.handleError(error),
        () => {
          this.showSavedToast();
          this.cancelEditing();
          this.getData();
        })
  }

  cancelEditing() {
    for( let article of this.articles )
      article.editing = false;
    this.editing = false;
    this.clearItemData();
    this.getData();
  }
  handleError(error) {
    console.error(error);
  }
  showSavedToast() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
  }
}
