<header class="module subheader">
    <div class="header-section">
        <label for="app" class="label">{{'ui.app'|translate}}</label>
        <select id="app" class="form-control form-select mr-4" (change)="appChange($event)">
            <option value="0">Management</option>
            <option value="1">Receptuur</option>
        </select>
        <label for="ug" class="label">{{'users.usergroup'|translate}}</label>
        <select id="ug" class="form-control form-select" (change)="usergroupChange($event)">
            <option value="0">{{'ui.all'|translate}}</option>
            <option *ngFor="let usergroup of usergroups" value="{{usergroup.id}}">{{usergroup.name}}</option>
        </select>
    </div>
</header>
<table class="userrights table table-hover">
    <thead>
    <tr>
        <th>{{'userrights.usergroup'|translate}}</th>
        <th>{{'userrights.right'|translate}}</th>
        <th class="right">{{'userrights.create'|translate}}</th>
        <th class="right">{{'userrights.read'|translate}}</th>
        <th class="right">{{'userrights.update'|translate}}</th>
        <th class="right">{{'userrights.delete'|translate}}</th>
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rights of groupRights">
            <td>{{rights.groupname}}</td>
            <td>{{rights.description}}</td>
            <td class="right"><input *ngIf="rights.can_be_create" id="create_{{rights.id}}" type="checkbox" [checked]="rights.allow_create" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
            <td class="right"><input *ngIf="rights.can_be_read" id="read_{{rights.id}}" type="checkbox" [checked]="rights.allow_read"  [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
            <td class="right"><input *ngIf="rights.can_be_update" id="update_{{rights.id}}" type="checkbox" [checked]="rights.allow_update" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
            <td class="right"><input *ngIf="rights.can_be_delete" id="delete_{{rights.id}}" type="checkbox" [checked]="rights.allow_delete" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
        </tr>
    </tbody>
</table>
<jbm-toasts></jbm-toasts>
