<app-header>
    <jbm-view-title [caption]="'financial.articlegroups'|translate"
        [parentLinkCaption]="'menu.settings'|translate"
        [parentLinkSlug]="'settings'"></jbm-view-title>
    <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module overflow-y">
    <div class="table-responsive">
        <table class="table padding table-sm" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
            <thead>
            <tr>
                <th [hidden]="true"></th>
                <th jbm-table-column class="name" [name]="'name'" [header]="'entity.name'|translate"></th>
                <th jbm-table-column class="description" [name]="'description'" [header]="'entity.description'|translate"></th>
                <th class="actions editable"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="createActive" class="edit-active">
                <th [hidden]="true"></th>
                <td><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="name"
                  (change)="onChangeName()" [ngClass]="{'ng-valid':validateName() && unique, 'is-invalid':this.nameTouched && !validateName() || !unique }">
                    <div *ngIf="nameError" class="invalid-feedback">{{nameError}}</div>
                </td>
                <td><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="description"
                    (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':this.descriptionTouched && !validateDescription() }">
                    <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
                </td>
                <td class="editable actions"><button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!validateDescription()" (click)="createItem()">{{'ui.save'|translate}}</button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
                </td>
            </tr>
            <tr *ngFor="let item of items" [ngClass]="{'edit-active': item.editing}" (click)="startEditing(item.id)">
                <td [hidden]="true"></td>
                <td *ngIf="!item.editing">{{item.name}}</td>
                <td *ngIf="!item.editing">{{item.description}}</td>
                <td *ngIf="!item.editing" class="actions"><button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm float-end"><i class="far fa-edit"></i></button></td>
                <td *ngIf="item.editing" class="editable">
                    <input class="form-control form-control-sm" type="text" autocomplete="off"
                      [(ngModel)]="name" [placeholder]="item.name" (change)="onChangeName()" [ngClass]="{'ng-valid':validateName() && unique, 'is-invalid':!validateName() || !unique }">
                    <div *ngIf="nameError" class="invalid-feedback">{{nameError}}</div>
                </td>
                <td *ngIf="item.editing" class="editable">
                    <input class="form-control form-control-sm" type="text" autocomplete="off"
                           [(ngModel)]="description" [placeholder]="item.description" (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':!validateDescription() }">
                    <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
                </td>
                <td *ngIf="item.editing" class="actions editable">
                    <button type="button" class="btn btn-sm btn-primary mr-1"
                      [disabled]="!validateDescription()" (click)="updateItem()">{{'ui.save'|translate}}</button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <jbm-empty-view [emptyView]="items.length==0"></jbm-empty-view>
</section>
<jbm-toasts></jbm-toasts>
