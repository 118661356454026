<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'project.order'|translate}} {{'ui.details'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <div class="row">
      <div class="col-md-6">
         <div class="jbm-properties-list order-details">
            <div *ngIf="order.description" class="item">
               <label class="label">{{'entity.description'|translate}}:</label>
               <label class="value">{{order.description}}</label>
            </div>
            <div *ngIf="order.worktype" class="item">
               <label class="label">{{'order.worktype'|translate}}:</label>
               <label class="value">{{order.worktype.description}}</label>
            </div>
            <div *ngIf="order.amount" class="item">
               <label class="label">{{'order.amount'|translate}}:</label>
               <label class="value">{{order.amount}}</label>
            </div>
            <div *ngIf="order.delivered" class="item">
               <label class="label">{{'order.delivered'|translate}}:</label>
               <label class="value">{{order.delivered}}</label>
            </div>
            <div class="item">
               <label class="label">{{'order.on-call'|translate}}:</label>
               <label class="value">
                  <span *ngIf="order.on_call" class="badge bg-light text-dark">{{'ui.yes'|translate}}</span>
                  <span *ngIf="!order.on_call" class="badge bg-light text-dark">{{'ui.no'|translate}}</span>
               </label>
            </div>

            <h6 class="mt-3">{{'entity.recipe'|translate}}</h6>
            <div *ngIf="order.consistency.description" class="item">
               <label class="label">{{'concrete.consistency'|translate}}:</label>
               <label class="value">{{order.consistency.description}}</label>
            </div>
            <div *ngIf="order.strength" class="item">
               <label class="label">{{'concrete.strength'|translate}}:</label>
               <label class="value">{{order.strength.description}}</label>
            </div>
            <div *ngIf="order.environment" class="item">
               <label class="label">{{'concrete.environment'|translate}}:</label>
               <label class="value">{{order.environment.description}}</label>
            </div>
            <div *ngIf="order.recipe_comments" class="item mt-3">
               <label class="label">{{'entity.recipe'|translate}} {{'entity.comments'|translate|lowercase}}:</label>
               <label class="value"><jbm-comments-box [comments]="order.recipe_comments" [height]=2.75></jbm-comments-box></label>
            </div>
         </div>
      </div>
      <div class="col-md-6">
         <div class="jbm-properties-list order-details">
            <h6 *ngIf="order.production_date" class="mt-3">{{'order.production'|translate}}</h6>
            <div *ngIf="order.production_date" class="item">
               <label class="label">{{'order.production-date'|translate}}:</label>
               <label class="value">{{order.production_date}}</label>
            </div>
            <div *ngIf="order.starttime" class="item">
               <label class="label">{{'order.starttime'|translate}}:</label>
               <label class="value"><i class="far fa-clock"></i> {{order.starttime}}</label>
            </div>
            <div *ngIf="order.loadtime" class="item">
               <label class="label">{{'order.loadtime'|translate}}:</label>
               <label class="value"><i class="far fa-clock"></i> {{order.loadtime}}</label>
            </div>
            <div *ngIf="order.endtime" class="item">
               <label class="label">{{'order.endtime'|translate}}:</label>
               <label class="value"><i class="far fa-clock"></i> {{order.endtime}}</label>
            </div>
            <div *ngIf="order.comments" class="item mt-3">
               <label class="label">{{'entity.comments'|translate}}:</label>
               <label class="value"><jbm-comments-box [comments]="order.comments" [height]=2.75></jbm-comments-box></label>
            </div>
         </div>
      </div>
   </div>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button [visible]="true" (click)="close()"></jbm-close-button>
   </div>
</div>
