<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.quotation'|translate}} {{'entity.delete'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <jbm-static-label [labelCaption]="'quotation.number'|translate" [value]="quotationNumber"></jbm-static-label>
   <div class="form-check form-check-inline mt-2 ml-0">
      <input type="checkbox" class="form-check-input" id="remove"
        [ngModelOptions]="{standalone: true}" [(ngModel)]="removeProject" (change)="removeProject=!removeProject">
      <label class="form-check-label pl-0" for="remove">&nbsp;{{'quotation.confirm-project-removal'|translate}}</label>
   </div>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <button type="button" class="btn btn-sm btn-primary" (click)="remove()">{{'entity.quotation'|translate}} {{'entity.delete'|translate|lowercase}}</button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button (click)="close()"></jbm-cancel-button>
   </div>
</div>
