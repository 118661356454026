import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {HelpTopicsService} from "../data/helptopics.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {StateService} from "../../../services/storage/state.service";
import {
  JBMTableActionDef,
  JBMTableColumnDef,
  JBMTableColumnType
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {ContextType} from "../../../components/JBM/Helpers/Context";
import {HelpTopic} from "../data/interfaces/helptopic";

@Component({
  selector: 'helptopics',
  templateUrl: './helptopics.component.html',
  styleUrls: ['./helptopics.component.scss']
})
export class HelpTopicsComponent implements OnInit, AfterViewInit, OnDestroy {
  initialCount: number=null;
  userRights: any;
  items: any;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  language: string=this.TokenService.getLanguage();
  id: number;
  parent_id: number=0;
  parentTopics: HelpTopic[]=[];
  loading: boolean=false;

  items$: Subscription;
  parentItems$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('positionTemplate') positionTemplate: TemplateRef<any>;
  @ViewChild('previewTemplate') previewTemplate: TemplateRef<any>;

  constructor(
      private HelpTopicsService: HelpTopicsService,
      private TokenService: TokenService,
      private StateService: StateService,
      private Router: Router,
      private TranslateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'title', header: this.TranslateService.GetTranslation('entity.title'), sortable: true, filterable: true},
      { name: 'preview', header: this.TranslateService.GetTranslation('ui.preview'), type: JBMTableColumnType.boolean, width: 6},
      { name: 'position', header: this.TranslateService.GetTranslation('ui.position'), sortable: true, width: 6},
    ];
    this.actionDefs = [
        { name: 'edit', iconClass: 'far fa-edit', context: ContextType.primary }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('position');
    this.queryHttpHelper.addSearchColumn('title', SearchColumnType.string);

    // Has State a helptopic parent_id to view initially ?
    if(this.StateService.hasElement('parentid')) {
      this.parent_id=this.StateService.getElementData('parentid');
      this.StateService.deleteElement('parentid');
    }

    this.getData(true);
  }
  ngAfterViewInit() {
    this.columnDefs[2].template=this.previewTemplate;
    this.columnDefs[3].template=this.positionTemplate;
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    params=params.append('parent_id', this.parent_id.toString());
    this.items$=this.HelpTopicsService.get(this.language, params).subscribe(
        (data: any) => this.items = data.data,(error)=>console.error(error),()=>{
          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            params=params.append('parent_id', this.parent_id.toString());
            this.count$=this.HelpTopicsService.get(this.language, params).subscribe(
                (data: any) => {
                  this.queryHttpHelper.paginationProps.itemCount = data.data;
                  if(this.initialCount===null) this.initialCount=data.data;
                },
                (error)=>console.error(error),()=>{
                  this.getParentTopics();
                }
            );
          }
        } );
  }
  getParentTopics() {
    this.parentItems$=this.HelpTopicsService.getParentTopics(this.language, this.parent_id).subscribe(
        (data)=>this.parentTopics=data.data as HelpTopic[],
        (error)=>console.error(error),
        ()=>{this.parentTopics.reverse()}
    )
  }
  setParentTopic(parent_id: number) {
    this.parent_id=parent_id;
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  action(event) {
    switch(event.name) {
      case 'edit':
        this.Router.navigateByUrl('settings/helptopic/'+event.row.id.toString());
        break;
    }
  }
  positionUp(helpTopic: HelpTopic) {
    this.HelpTopicsService.updatePosition(helpTopic.id, helpTopic.position, helpTopic.parent_id,0).subscribe(
        ()=> this.getData(false)
    )
  }
  positionDown(helpTopic: HelpTopic) {
    this.HelpTopicsService.updatePosition(helpTopic.id, helpTopic.position, helpTopic.parent_id,1).subscribe(
        ()=> this.getData(false)
    )
  }
  add() {
    this.Router.navigateByUrl('settings/helptopic/create/'+this.parent_id.toString());
  }
  rowSelect(row) {
    this.parent_id=row.id;
    this.getData(true);
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.parentItems$!=undefined) this.parentItems$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
