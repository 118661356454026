import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../../services/storage/state.service";

@Component({
  selector: 'jbm-help-button',
  templateUrl: './JBMHelpButton.component.html',
  styleUrls: ['./JBMHelpButton.component.scss'],
  host: {'class': 'JBMButton'}
})

export class JBMHelpButtonComponent implements OnInit {

  @Input() helpTopicID: number;
  @Input() class: string;

  constructor(private Router: Router, private StateService: StateService) {}

  ngOnInit(): void {
    if(this.helpTopicID==undefined) this.helpTopicID=0; // Root index
    if(this.class==undefined) this.class='light';
  }
  click() {
    // Buffer helptopic id in State
    this.StateService.addElement('helpindex', this.helpTopicID);

    // Show the Help
    this.Router.navigate([ { outlets: {'ar': ['help']}} ] );
  }
}

