import {Component, OnInit } from '@angular/core';
import {TokenService} from "../../services/auth/token.service";
import {MenuService} from "../../services/menu/menu.service";
import {GroupRights} from "../users/data/interfaces/grouprights";

@Component({
  selector: 'reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  iconClassnames={ 'production': null };
  rights: GroupRights=this.TokenService.getRightsByName('reports');

  constructor(
      private TokenService: TokenService,
      private MenuService: MenuService
  ) {
    let modules = this.TokenService.getAuthorizedModules();

    // Get icon classnames of the reports
    for(let name in this.iconClassnames)
      this.iconClassnames[name]=this.MenuService.getMenuItemIconClassname(modules, name);
  }

  ngOnInit(): void {
  }

}
