import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {ModalService} from "./service/modal.service";

@Component({
  selector: 'jbm-modal',
  templateUrl: './JBMModal.component.html',
  styleUrls: ['./JBMModal.component.scss']
})
export class JBMModalComponent implements OnInit, OnDestroy {
  template: TemplateRef<any>;
  modalZIndex: number;

  @Input() id: string;
  @Input() className: string;
  @Output() onClose=new EventEmitter();

  private readonly element: any;

  constructor(public ModalService: ModalService, private el: ElementRef) {
    this.element=el.nativeElement;
  }
  ngOnInit(): void {
    // Ensure id attribute exists
    if(!this.id) {
      console.error('JBMModal must have an id');
      return;
    }

    // Modals are stacked; layered by CSS z-index
    this.modalZIndex=900+(this.ModalService.count()*10);

    // Move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // Close modal on background click
    this.element.addEventListener('click', el => {
      if(el.target.className==='jbm-modal-backdrop') this.close()
    } );

    document.addEventListener('keydown', event => {
      if( event.key==="Escape") this.close();
    });

    // Add self (this modal instance) to the modal service so it's accessible from controllers
    this.ModalService.add(this);
  }

  open(): void {
    this.element.style.display='block';
    document.body.classList.add('jbm-modal-open');
  }

  close() {
    this.onClose.emit();
    this.template=null;
    this.element.style.display='none';
    document.body.classList.remove('jbm-modal-open');
  }

  ngOnDestroy(): void {
    // Remove self from modal service when component is destroyed
    this.ModalService.remove(this.id);
    this.element.remove();
  }
}
