import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router, ActivatedRoute} from "@angular/router";
import {ArticlegroupsService} from "../../data/articlegroups.service";
import {ArticlesService} from "../../data/articles.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {
  JBMTableActionDef,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss']
})
export class ArticlesComponent implements OnInit, OnDestroy {
  userRights: any;
  items: any;
  articlegroupOptions: JBMSelectOption[]=[];
  articlegroups_id: number=0;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  language: string=this.TokenService.getLanguage();
  id: number;
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('table') table: JBMTableComponent;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private ArticlegroupsService: ArticlegroupsService,
      private ArticlesService: ArticlesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'), sortable: true, filterable: true },
      { name: 'groupname', header: this.TranslateService.GetTranslation('financial.articlegroup'), sortable: true, filterable: false, width: 10 },
      { name: 'price_type_code', header: this.TranslateService.GetTranslation('article.pricetype'), sortable: true, filterable: true, width: 10 },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true, width: 15},
      { name: 'reference_id', header: this.TranslateService.GetTranslation('article.reference-id'), sortable: true, filterable: true, width: 9 }
    ];
    this.actionDefs = [ { name: 'edit', iconClass: 'far fa-edit', disabled: !this.userRights.AllowUpdate } ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('name','al.name' );
    this.queryHttpHelper.addAliasedColumn('description','al.description' );
    this.queryHttpHelper.addAliasedColumn('price_type_code', 'aptl.code' );
    this.queryHttpHelper.addAliasedColumn('reference_id','a.reference_id' );
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('reference_id', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('price_type_code', SearchColumnType.string);

    let articlegroups=[];
    this.ArticlegroupsService.get(this.language).subscribe(
        (data: any)=>articlegroups=data.data,(error)=>console.error(error),()=>{
          this.articlegroupOptions=this.ArticlegroupsService.getSelectOptions(articlegroups);
          this.articlegroupOptions[0].value=this.TranslateService.GetTranslation('ui.all');

          this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
          this.getData(true);
        }
    );
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    if(this.articlegroups_id) params=params.append('articlegroups_id',this.articlegroups_id.toString());
    this.items$=this.ArticlesService.get(this.language, params).subscribe(
        (data: any) => this.items = data.data,(error)=>console.error(error),()=>{

          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            if(this.articlegroups_id) params=params.append('articlegroups_id',this.articlegroups_id.toString());
            this.count$=this.ArticlesService.get(this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,(error)=>console.error(error));
          }
        } );
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  changeArticlegroup(event) {
    this.articlegroups_id=parseInt(event.target.value);
    this.columnDefs[2].visible=(this.articlegroups_id===0);
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  addItem() {
    this.Router.navigateByUrl('settings/financial/articles/create');
  }
  action(event) {
    this.rowSelect(event.row);
  }
  rowSelect(row) {
    this.Router.navigateByUrl('settings/financial/articles/'+row.id);
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Refresh view
    this.getData(true);
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
