import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {ProjectsComponent} from "../projects/datatable/projects.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {TrucksAndDriversComponent} from "./trucks-and-drivers/trucks-and-drivers.component";

const routes: Routes = [
  {
    path: 'settings/drivers',
    component: TrucksAndDriversComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/trucks',
    component: TrucksAndDriversComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LogisticsRoutingModule { }
