import { Injectable } from '@angular/core';

export interface stateElement { name: string; data: any }

@Injectable()
export class StateService {
  private data: any;
  private elements: stateElement[];

  constructor() {
    if(this.data==undefined) this.data=null;
    if(this.elements==undefined) this.elements=[];
  }

  // Global state data
  setData(data:any) {
    this.data=data;
  }
  getData() {
    if(this.isEmpty()) return false;
    return this.data;
  }
  isEmpty() {
    return this.data===null;
  }
  clear() {
    this.data=null;
  }

  // State elements

  addElement(name: string, data: any): void {
    this.elements.push({ name: name, data: data });
  }
  getElement(name: string) {
    for(let e=0; e<this.elements.length; e++ )
      if(this.elements[e].name===name)
        return this.elements[e];
    return false;
  }
  getElementData(name: string): any {
    let element=this.getElement(name);
    if(element===false) return null;
    return element.data;
  }
  hasElement(name: string): boolean {
    for(let e=0; e<this.elements.length; e++ )
      if(this.elements[e].name===name)
        return true;
    return false;
  }
  deleteElement(name: string): boolean {
    for(let e=0; e<this.elements.length; e++ )
      if(this.elements[e].name===name)
        this.elements.splice(e,1);
    return true;
  }
  getElements(): stateElement[] {
    return this.elements;
  }
}
