import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";
import { HttpParams } from "@angular/common/http";

export interface user_group {
  user_id: number;
  group_id: number;
}

const baseUrl='users/';
const insertURL: string = 'users/create/user_group/';
const deleteURL: string = 'users/delete/user_group/';
const getGroupsURL: string = 'users/create/user_group/';

@Injectable({
  providedIn: 'root'
})
export class User_GroupService {

  constructor(private HttpService: HttpService) {}

  getGroups(user_id) {
    let params=new HttpParams();
    params=params.append('user_id',user_id.toString());
    return this.HttpService.get(baseUrl+'read/user_group/groups/', params);
  }
  insert(data) {
    return this.HttpService.post(baseUrl+'create/user_group',data);
  }
  update(groups: user_group[]) {
    return this.HttpService.post(baseUrl+'update/user_group',groups);
  }
  delete(user_id: number, group_id: number) {
    return this.HttpService.delete(baseUrl+'delete/user_group/?user_id='+user_id+'&group_id='+group_id);
  }
}
