<app-header>
    <div class="float-start">
        <jbm-view-title [caption]="'menu.helpcentre'|translate"
         [itemcount]="queryHttpHelper.paginationProps.itemCount"
         [parentLinkCaption]="'menu.settings'|translate"
         [parentLinkSlug]="'settings'">
        </jbm-view-title>
    </div>
    <div [hidden]="!initialCount" class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisible && !filtersVisible" [search]="search" [minlength]="3" (searchChange)="searchChange($event)"></jbm-searchbox>
    </div>
</app-header>
<section class="module overflow-y helpcentre">
    <div class="toolbar">
        <nav class="float-start">
            <ol class="breadcrumb padding">
                <li>{{'ui.path'|translate}}:&nbsp;&nbsp;</li>
                <li *ngFor="let helpTopic of parentTopics; index as i;" class="breadcrumb-item" [ngClass]="{ active:i==parentTopics.length-1 }">
                    <a *ngIf="i<parentTopics.length-1" href="javascript:void(0);"
                      (click)="setParentTopic(helpTopic.id);$event.stopPropagation()">
                      {{helpTopic.title}}
                    </a>
                    <span *ngIf="i==parentTopics.length-1">
                        {{helpTopic.title}}
                    </span>
                </li>
            </ol>
        </nav>
        <div class="toolbar-spacer-1"></div>
        <jbm-create-button (click)="add()"></jbm-create-button>
    </div>
    <jbm-table [hidden]="!initialCount" [classname]="'helptopics padding'"
               [data]="items"
               [filterable]="true"
               [showFilters]="filtersVisible"
               [sortable]="true"
               [sortColumn]="queryHttpHelper.sortProps.name"
               [sortDirection]="queryHttpHelper.sortProps.direction"
               [hoverable]="true"
               [rowSelectable]="userRights.AllowCreate"
               [columnDefs]="columnDefs"
               [actionDefs]="actionDefs"
               (actionClick)="action($event)"
               (sortChange)="sortChange($event)"
               (filterChange)="filterChange($event)"
               (rowSelect)="rowSelect($event)">
    </jbm-table>
</section>
<ng-template #positionTemplate let-data="data">
    <button *ngIf="data.position>0" class="btn btn-sm btn-light mr-2" (click)="positionUp(data); $event.stopPropagation();"><i class="fa fa-arrow-up"></i></button>
    <button *ngIf="data.position<(queryHttpHelper.paginationProps.itemCount-1)" class="btn btn-sm btn-light" (click)="positionDown(data); $event.stopPropagation();"><i class="fa fa-arrow-down"></i></button>
</ng-template>
<ng-template #previewTemplate let-data="data">
    <div class="align-center">
        <jbm-help-button [helpTopicID]="data.id"></jbm-help-button>
    </div>
</ng-template>
<jbm-toasts></jbm-toasts>
