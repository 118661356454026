import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationPatternsService {

  constructor() { }

  floatPattern(decimalsMax: number=0) {
    switch(decimalsMax) {
      case 0: // Unlimited decimals
        return /[0-9]*\,?[0-9]+/;
      case 1: // Maximum one decimal
        return /^[0-9]+(\,[0-9]{0,1})?$/;
      case 2: // Maximum two decimals
        return /^[0-9]+(\,[0-9]{0,2})?$/;
    }
  }

  latLongitudePattern() {
    return /^((\-?|\+?)?\d+(\.\d+)?).\s*((\-?|\+?)?\d+(\.\d+)?)$/;
  }

  passwordPattern() {
    // Minimal 8 chars (special chars included), minimal 1 uppercase alpha char, minimal 1 digit
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  }

}
