<button type="button" class="btn btn-light" [class.active]="visible"
  placement="left" container="body" [ngbTooltip]="'ui.columns-layout-tooltip'|translate"
  (click)="toggle(); $event.stopPropagation()"><i class="fa fa-th-list"></i></button>
<div class="jbm-table-layout-container">
    <div class="jbm-overlay-panel jbm-table-layout" [hidden]="!visible" [ngClass]="class">
        <h6 class="caption">{{caption}}</h6>
        <div class="columns-container">
            <div *ngFor="let c of columnDefs">
                <div *ngIf="c.type!=0 && c.hideable" class="form-check form-check-inline">
                    <input class="form-check-input" type="checkbox" [checked]="c.visible" value="" id="jbmlayout{{c.name}}" [disabled]="!c.hideable" (change)="toggleColumn(c.name)">
                    <label class="form-check-label" for="jbmlayout{{c.name}}">{{c.header}}</label>
                </div>
            </div>
        </div>
    </div>
</div>
