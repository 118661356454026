<form [formGroup]="form" (ngSubmit)="save()">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'entity.customer'|translate}}{{'ui.message'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label labelClass="md" [labelCaption]="'quotation.number'|translate" [value]="quotation.quotation_number"></jbm-static-label>
      <jbm-textarea [formControl]="form.controls.customer_comments" [id]="'customer_comments'" class="customer-comments" [class]="'xl'"
        [submitted]="submitted" labelCaption="{{'entity.customer'|translate}}{{'ui.message'|translate|lowercase}}" labelClass="md" rows="3">
      </jbm-textarea>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="('entity.customer'|translate)+('ui.message'|translate|lowercase)" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
