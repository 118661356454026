import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Project} from "../../../features/projects/data/interfaces/project";
import {ProjectsService} from "../../../features/projects/data/projects.service";
import {ProjectPresenter} from "../../../features/projects/presenters/project.presenter";
import {ProjectstatesService} from "../../../features/projects/data/projectstates.service";
import {CustomerService} from "../../../features/customers/data/customer.service";
import {PlantsService} from "../../../features/company/data/plants.service";
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../services/auth/token.service";
import {GoogleMapsService} from "../../../services/google-maps/google-maps.service";
import {GPSCoordinates} from "../../../components/JBM/Helpers/GPSInfo";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {GroupRights} from "../../../features/users/data/interfaces/grouprights";
import {SettingsService, setting_subjects} from "../../../services/storage/settings.service";

const resources_based_invoicing_setting='resources_based_invoicing';

@Component({
  selector: 'project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit {
  project: Project;
  customer: any;
  customerRights: GroupRights=this.TokenService.getRightsByName('customers');
  data: any;
  language: string=this.TokenService.getLanguage();
  GPS_address: GPSCoordinates={longitude: '', latitude: ''};
  resources_based_invoicing: boolean=false;

  constructor(
      private ProjectsService: ProjectsService,
      private ProjectPresenter: ProjectPresenter,
      private ProjectstatesService: ProjectstatesService,
      private CustomerService: CustomerService,
      private PlantsService: PlantsService,
      private TokenService: TokenService,
      private SettingsService: SettingsService,
      private GoogleMapsService: GoogleMapsService,
      private ModalService: ModalService
  ) {
    this.project=this.ProjectsService.getEmpty();
    this.project.projectState=this.ProjectstatesService.getEmpty();
    this.project.plant=this.PlantsService.getEmpty();
    this.project.customer=this.CustomerService.getEmpty();
  }

  @Input() project_id: number;
  @Output() onClose=new EventEmitter();

  @ViewChild('modalCustomer') modalCustomer: ElementRef;

  ngOnInit(): void {
    let params=new HttpParams()
     .append('state','1')
     .append('address','1')
     .append('plant','1')
     .append('customer','1')
     .append('article_pricelist','1')
     .append('concrete_pricelist','1')
     .append('resource_pricelist','1');
    this.ProjectsService.getOne(this.project_id, this.language, params).subscribe(
      (data)=>this.project=data.data,
      (error)=>console.error(error),
      ()=> {
        this.data=this.ProjectPresenter.format(this.project);

        let setting=false;
        this.SettingsService.getSetting(0, setting_subjects.project, this.project.id, resources_based_invoicing_setting).subscribe(
            (data)=>setting=data.setting,
            (error)=>console.error(error),
            ()=> {
              this.resources_based_invoicing=setting===null ? false: setting;

              if(this.project.address_details_id===null)
                return;
              // Get GPS Location of address
              this.GoogleMapsService.getLookup(
                  this.project.address.street,
                  this.project.address.no,
                  this.project.address.postcode,
                  this.project.address.city).subscribe((data)=>this.GPS_address=data,(error)=>console.error(error)
              )
            }
        );
      }
    )
  }
  customerDetails() {
    this.CustomerService.get(this.project.customer_id,true).subscribe(
        (data)=>this.customer=data.data,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.modalCustomer,'customerModal')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerModal');
  }
  close() {
    this.onClose.emit();
  }
}
