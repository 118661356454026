import {Component, Input, OnInit} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";

@Component({
  selector: 'mail-viewer',
  templateUrl: './mail-viewer.component.html',
  styleUrls: ['./mail-viewer.component.scss']
})
export class MailViewerComponent implements OnInit {
  url: SafeResourceUrl;
  @Input() base64: string;
  @Input() borderless: boolean=false;

  constructor(
      private DomSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    if(this.base64==null) this.base64='';
    this.url=this.DomSanitizer.bypassSecurityTrustResourceUrl('data:text/html;base64,'+this.base64);
  }
  ngOnChanges() {
    this.url=this.DomSanitizer.bypassSecurityTrustResourceUrl('data:text/html;base64,'+this.base64);
  }

}
