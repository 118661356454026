import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppLayouts} from "../../app.component";
import {CustomersComponent} from "./datatable/customers.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {CustomerResolver} from "./data/resolvers/customer.resolver";
import {CustomerViewComponent} from "./customer-view/customer-view.component";
import {CustomertypesComponent} from "./customertypes/customertypes.component";

const routes: Routes = [
  {
    path: 'customers',
    pathMatch: 'full',
    component: CustomersComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'customers/details/:id',
    pathMatch: 'full',
    component: CustomerViewComponent,
    canActivate: [AuthGuardService],
    resolve: { customer: CustomerResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'customers/details/:id/projects',
    pathMatch: 'full',
    component: CustomerViewComponent,
    canActivate: [AuthGuardService],
    resolve: { customer: CustomerResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'customers/details/:id/article-pricelists',
    pathMatch: 'full',
    component: CustomerViewComponent,
    canActivate: [AuthGuardService],
    resolve: { customer: CustomerResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'customers/details/:id/concrete-pricelists',
    pathMatch: 'full',
    component: CustomerViewComponent,
    canActivate: [AuthGuardService],
    resolve: { customer: CustomerResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'customers/details/:id/resource-pricelists',
    pathMatch: 'full',
    component: CustomerViewComponent,
    canActivate: [AuthGuardService],
    resolve: { customer: CustomerResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/customertypes',
    pathMatch: 'full',
    component: CustomertypesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CustomersRoutingModule { }
