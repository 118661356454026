import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-concrete.specs.container',
  templateUrl: './concrete.specs.container.component.html',
  styleUrls: ['./concrete.specs.container.component.scss']
})
export class ConcreteSpecsContainerComponent implements OnInit {

  active: number=0;
  constructor(router: Router) {
    // Set tab according active URL
    if(router.url.indexOf('consistencies')>0) this.active=1;
    if(router.url.indexOf('strengths')>0)     this.active=2;
  }
  ngOnInit(): void {}
}
