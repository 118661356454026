import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { JBMAsidesModule} from "../../components/JBM/Asides/jbmasides.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMModalHeaderComponent } from "../../components/JBM/Forms/JBMModalHeader/JBMModalHeader.component";
import { CustomersRoutingModule } from './customers.routing.module';
import { CustomerFormComponent } from "./form/customer-form.component";
import { CustomerViewComponent} from "./customer-view/customer-view.component";
import { CustomersComponent } from "./datatable/customers.component";
import { SharedCustomersModule} from "../../shared/customers/shared.customers.module";
import { CustomertypesComponent} from "./customertypes/customertypes.component";
import { JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import { ProjectsModule} from "../projects/projects.module";
import { QuotationsModule} from '../quotations/quotations.module';
import { FinancialModule} from "../financial/financial.module";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
    imports: [SharedModule, CommonModule, CustomersRoutingModule, JBMButtonsModule, JBMTableModule, JBMViewModule, JBMFormModule, JBMAsidesModule,
        ProjectsModule, FinancialModule, QuotationsModule, SharedCustomersModule, JBMModalModule, LayoutModule],
  declarations: [CustomerFormComponent, CustomersComponent, CustomerViewComponent, CustomertypesComponent],
  exports: [JBMModalHeaderComponent]
})
export class CustomersModule {}
