<app-header>
    <jbm-view-title [caption]="'financial.title'|translate"></jbm-view-title>
</app-header>
<section class="module dashboard padding border bg-light">
    <div class="row">
        <div class="col-4">
            <div class="card border-light mb-2">
                <div class="card-header">{{'financial.concrete'|translate}}</div>
                <div class="card-body">
                    <a routerLink="/financial/concrete-pricelists" routerlinkactive="active">{{'financial.concrete-pricelists'|translate}}</a>
                    <div class="pb-3">{{'financial.concrete-specs'|translate}}</div>
                    <a routerLink="/financial/concrete-environments" routerlinkactive="active">{{'financial.concrete-environments'|translate}}</a>
                    <a routerLink="/financial/concrete-strengths" routerlinkactive="active">{{'financial.concrete-strengths'|translate}}</a>
                    <a routerLink="/financial/concrete-consistencies" routerlinkactive="active">{{'financial.concrete-consistencies'|translate}}</a>
                </div>
            </div>
        </div>
        <div class="col-4">
            <div class="card border-light">
                <div class="card-header">{{'financial.articles'|translate}}</div>
                <div class="card-body">
                    <a routerLink="/financial/article-pricelists" routerlinkactive="active">{{'financial.article-pricelists'|translate}}</a>
                    <a routerLink="/financial/articles" routerlinkactive="active">{{'financial.articles'|translate}}</a>
                    <a routerLink="/financial/article-pricetypes" routerlinkactive="active">{{'financial.article-price-types'|translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
