import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {QuotationItem} from "./interfaces/quotation-item";

const baseURL: string = 'quotations/';
const segment: string = 'items/';

@Injectable({
  providedIn: 'root'
})
export class QuotationItemsService {
  quotation_item: QuotationItem;

  constructor(private HttpService: HttpService) {}

  get(language: string, quotation_revision_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+quotation_revision_id.toString(), params );
  }
  getOne(id: number) {
    return this.HttpService.get(baseURL+'read/'+segment+'one/'+id.toString());
  }
  getEmpty() {
    this.quotation_item = {
      id: 0,
      quotation_revisions_id: null,
      description: '',
      concrete_price: 0,
      amount: 0,
      totalprice: 0,
      consistencies_id: null,
      strengths_id: null,
      environments_id: null,
      recipes_id: null,
      worktypes_id: 0,
      orders_id: null,
      unloading_methods_id: 0,
      comments: null
    }
    return this.quotation_item;
  }
  create(language: string, data: any) {
    return this.HttpService.post(baseURL+'create/'+segment+language, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  remove(id: number ) {
    return this.HttpService.delete(baseURL+'delete/'+segment+id.toString());
  }
}
