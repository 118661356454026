import { Injectable } from '@angular/core';

export interface Referer { section: string; name: string; route: string; caption: string; }

@Injectable({
  providedIn: 'root'
})
export class RefererService {
  private referers: Referer[]=[];

  constructor() {}

  addReferer(referer: Referer) {
    this.deleteReferer(referer.section, referer.name);
    this.referers.push(referer);
  }
  getReferer(section: string, name: string) {
    for(let r=0; r<this.referers.length; r++)
      if(this.referers[r].section===section && this.referers[r].name===name)
        return this.referers[r];
    return false;
  }
  hasReferer(section: string, name: string) {
    for(let r=0; r<this.referers.length; r++)
      if(this.referers[r].section===section && this.referers[r].name===name)
        return true;
    return false;
  }
  deleteReferer(section: string, name: string) {
    for(let r=0; r<this.referers.length; r++)
      if(this.referers[r].section===section && this.referers[r].name===name)
        this.referers.splice(r,1);
    return true;
  }
  clearSection(section: string) {
    for(let r=0; r<this.referers.length; r++)
      if(this.referers[r].section===section)
        this.referers.splice(r,1);
    return true;
  }
}
