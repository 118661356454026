export class BrowserLanguage {
  private default_language: string='nl';

  // Gets the ISO 639-1 codes compatible language from the browser
  // If not available return <default_language>
  getLanguage() {
    let lang=window.navigator.language;
    if( lang===undefined || lang.length<2 )
      return this.default_language;
    else
      /* The first two characters of the browser language string is the
         (country independent) language
      */
      return lang.slice(0,2);
  }
}
