import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'jbm-comments-dropdown',
  templateUrl: './JBMCommentsDropdown.component.html',
  styleUrls: ['./JBMCommentsDropdown.component.scss']
})
export class JBMCommentsDropdownComponent implements OnInit {

  constructor() { }

  @Input() comments: string;
  @Input() caption: string;
  @Input() class: string;
  @Input() btnContext: string;
  @Input() iconClass: string;
  @Input() extraSmall: boolean=false;

  @Output() onOpenChange=new EventEmitter();

  ngOnInit(): void {
    if(this.comments==undefined) this.comments='';
    if(this.caption==undefined) this.caption='';
    if(this.btnContext==undefined) this.btnContext='light';
    if(this.class==undefined) this.class='';
    if(this.iconClass==undefined) this.iconClass='comments';
    if(this.extraSmall==undefined) this.extraSmall=false;
  }
  openChange(opened: boolean) {
    this.onOpenChange.emit(opened);
  }
}
