<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'financial.resource-pricelist'|translate}} {{'ui.connect-to'|translate|lowercase}} {{'entity.customers'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <div class="jbm-properties-list uses">
      <div class="item">
         <label class="label">
            {{'financial.resource-pricelist'|translate}}:
         </label>
         <label class="value">
            {{pricelist.description}}
         </label>
      </div>
      <div class="item">
         <label class="label">
            {{'pricelist.type'|translate}}:
         </label>
         <label class="value">
            {{types[pricelist.type].value}}
         </label>
      </div>
   </div>
   <h6 class="mt-3">{{'entity.customers'|translate}} {{'ui.select'|translate|lowercase}}</h6>
   <hr class="mt-0 mb-0" />
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-2 float-end"></div>
      <jbm-searchbox [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
      <div class="toolbar-spacer-2 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
         [pageSize]="queryHttpHelper.paginationProps.pageSize"
         [page]="queryHttpHelper.paginationProps.page"
         (pageClick)="pageChange($event)">
      </jbm-pagination>
   </div>
   <jbm-table [classname]="'customers'"
      [size]="'xs'"
      [data]="items"
      [sortable]="true"
      [sortColumn]="queryHttpHelper.sortProps.name"
      [sortDirection]="queryHttpHelper.sortProps.direction"
      [hoverable]="true"
      [rowSelectable]="true"
      [columnDefs]="columnDefs"
      (sortChange)="sortChange($event)"
      (rowSelect)="rowSelect($event)">
   </jbm-table>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <button type="button" class="btn btn-sm btn-primary" [disabled]="customer_ids.length==0" (click)="connect()">
         {{'financial.resource-pricelist'|translate}} {{'ui.connect-to'|translate|lowercase}} {{'entity.customers'|translate|lowercase}}
      </button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
   </div>
</div>
<ng-template #toggleTemplate let-data="data">
   <input type="checkbox" class="mt-1" (click)="toggleCustomer(data.id); $event.stopPropagation()"
     [checked]="customer_ids.indexOf(data.id)>-1">
</ng-template>
