<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.recipe'|translate}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <jbm-static-label [labelCaption]="'order.production-date'|translate" [labelClass]="'md'" [class]="'mb-0'" [value]="delivery_date"></jbm-static-label>
   <jbm-static-label [labelCaption]="'entity.recipe'|translate" [labelClass]="'md'" [value]="recipe.code"></jbm-static-label>
   <hr />
   <h6>{{'financial.concrete-specs'|translate}}</h6>
   <jbm-static-label [labelCaption]="'concrete.strength'|translate" [labelClass]="'md'" [class]="'mb-0'" [value]="recipe.strength.description"></jbm-static-label>
   <jbm-static-label [labelCaption]="'concrete.consistency'|translate" [labelClass]="'md'" [class]="'mb-0'" [value]="recipe.consistency.description"></jbm-static-label>
   <jbm-static-label [labelCaption]="'concrete.environment'|translate" [labelClass]="'md'" [class]="'mb-0'" [value]="environment"></jbm-static-label>
   <hr />
   <h6>{{'entity.recipe'|translate}} {{'financial.articles'|translate|lowercase}}</h6>
   <table [hidden]="recipe.articles.length==0" class="table table-sm recipe-articles">
      <thead>
      <tr>
         <th class="description">{{'entity.description'|translate}}</th>
         <th class="amount align-right">{{'order.amount'|translate}}</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let article of recipe.articles">
         <td>{{article.article.name}}</td>
         <td class="align-right">{{article.amount}}</td>
      </tr>
      </tbody>
   </table>
   <jbm-empty-view [emptyView]="recipe.articles==0"></jbm-empty-view>
</div>