import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ResourcesService} from "../../../recipes/data/resources.service";
import {ResourcePricelistsService} from "../../data/resource-pricelists.service";
import {ResourcePricesService} from "../../data/resource-prices.service";
import {PriceService} from "../../../../services/price/price.service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMPropertiesListItem} from "../../../../components/JBM/Views/JBMPropertiesList/JBMPropertiesList.component";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'form-resource-price',
  templateUrl: './resource_price.component.html',
  styleUrls: ['./resource_price.component.scss']
})

export class ResourcePriceComponent implements OnInit {
  action: CRUDAction;
  data: any;
  warning: string;
  warningOverlap: string;

  @Input() resource_pricelist: any;
  @Input() userRights: any;
  @Input() id: number;
  @Input() details: any;
  @Input() resource_id: number;
  @Input() language: string;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private ResourcesService: ResourcesService,
    private ResourcePricelistsService: ResourcePricelistsService,
    private ResourcePricesService: ResourcePricesService,
    private PriceService: PriceService,
    private DatetimeService: DatetimeService,
    private TranslateService: TranslateService
  ) {}

  form: FormGroup;
  isSubmitted: boolean=false;
  priceListProps: JBMPropertiesListItem[]=[];
  resourceProps: JBMPropertiesListItem[]=[];
  selectList: boolean=false;

  ngOnInit(): void {
    if(this.id==undefined) this.id=0;
    if(this.resource_id==undefined) this.resource_id=0;
    if(this.data==undefined) this.data={ articles_id: 0 };
    if(this.details==undefined) this.details={ name: '', pricetype: '' };

    this.addControls();
    this.initData();
  }
  ngAfterViewInit(): void {
    if(this.id)
      document.getElementById('price').focus();
  }
  initData() {
    // Set pricelist properties
    let type=this.ResourcePricelistsService.getTypeAsString(this.resource_pricelist.type, this.TranslateService);
    this.priceListProps.push({
      label: this.TranslateService.GetTranslation('entity.resource') + ' ' + this.TranslateService.GetTranslation('financial.pricelist').toLowerCase(),
      value: this.resource_pricelist.description
    });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'), value: type });

    if(this.id) {
      this.action=CRUDAction.Update;
      this.setResourceDetails();
      this.ResourcePricesService.getOne(this.id).subscribe((data: any) => this.data=data.data,
          (error)=>console.error(error),()=>this.setControlValues());
    } else {
      this.action=CRUDAction.Create;
      this.selectList=true;
      this.data=this.ResourcePricesService.getEmpty();
      this.setControlValues();
    }
  }
  setResourceDetails() {
    // Set resource properties
    this.resourceProps=[];
    this.resourceProps.push({label: this.TranslateService.GetTranslation('entity.resource'), value: this.details.description});
  }
  addControls() {
    let negativeAllowed=this.resource_pricelist.type==1;
    this.form = this.formBuilder.group({
      valid_from: new FormControl('',[this.DatetimeService.dateValidator(this.DatetimeService,false)]),
      valid_to: new FormControl('',[this.DatetimeService.dateValidator(this.DatetimeService,false)]),
      price: new FormControl('0.0',[this.PriceService.priceValidator(this.PriceService, negativeAllowed)]),
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.valid_from.setValue(this.data.valid_from===null ? '' : this.data.valid_from);
    this.form.controls.valid_to.setValue(this.data.valid_to===null ? '' : this.data.valid_to);
    this.form.controls.price.setValue( this.PriceService.formatPrice(this.data.price));
  }
  showSelectList() {
    this.selectList=true;
  }
  selectResource(resource) {
    this.data.resources_id=resource.id;
    this.ResourcesService.getOne(resource.id).subscribe(
        (data: any) => {
            this.data.resources_id=data.data.id;
            this.details.description=data.data.description;
         },(error) =>console.error(error),
        ()=> {
            this.setResourceDetails();
            this.selectList=false;
        }
    );
  }
  save() {
    this.isSubmitted = true;
    if(this.form.invalid)
      return;

    let valid_from=this.DatetimeService.dateDMYToYMD(this.formControls.valid_from.value);
    let valid_to  =this.DatetimeService.dateDMYToYMD(this.formControls.valid_to.value);
    if(valid_to && valid_from>valid_to) {
      this.warning=this.TranslateService.GetTranslation('entity.to-date-before-from-date');
      return;
    }

    if(!valid_from) valid_from=null;
    if(!valid_to) valid_to=null;

    if(this.data.resources_id===0)
      return;

    let data ={
      id: this.id,
      resources_id: this.data.resources_id,
      resource_pricelists_id: this.resource_pricelist.id,
      valid_from: valid_from,
      valid_to: valid_to,
      price: this.PriceService.getPriceFromStr( this.formControls.price.value )
    }

    if(data.id==0)
      this.ResourcePricesService.create(data).subscribe(()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    else
      this.ResourcePricesService.update(data).subscribe(()=>{}, (error)=>this.handleError(error),()=>this.onSave.emit())
  }
  handleError(error) {
    if(error==='period_overlap') {
      this.warningOverlap=this.TranslateService.GetTranslation('pricelist.resource-price-validity-error');
      document.getElementById('valid_from').focus();
    } else
      console.error(error);
  }
  cancel() {
    this.onCancel.emit();
  }
}
