<input *ngIf="columnType==1 || columnType==3 || columnType==10 || columnType==11" [hidden]="hasFilterValues"
  [id]="'columnfilter-'+name" [value]="filterValue" type="text" class="form-control form-control-sm"
  autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" (keyup)="inputChange($event)" placement="bottom"  triggers="click:blur"
  ngbTooltip="{{'ui.search-min-'+searchMinLength+'-chars'|translate}}">
<input *ngIf="!hasFilterValues && columnType==2" digits-only-input [value]="filterValue" type="text" class="form-control form-control-sm"
  [id]="'columnfilter-'+name" maxlength="6"
  autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false"
  ngbTooltip="{{'ui.table.number-filter-tooltip'|translate}}" placement="bottom-left" triggers="click:blur"
  (keyup)="numberChange($event)"
  (keyup.enter)="numberSearch($event)">
<select [hidden]="!hasFilterValues" class="form-control form-control-sm form-select" (change)="selectChange($event)" [id]="'columnfilter-'+name">
    <option value="" [selected]="filterValue==''"></option>
    <option *ngFor="let v of filterValues" value="{{v.key}}" [selected]="filterValue==v.key">{{v.value}}</option>
</select>