<div *ngIf="inModal" class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.customer'|translate}} {{'ui.details'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div [ngClass]="{'jbm-modal-body': inModal}">
   <div class="row">
      <div class="col-md-6 mr-4">
         <div class="jbm-properties-list customer-details">
            <div class="item">
               <label class="label">{{'entity.name'|translate}}:</label>
               <label class="value">{{customer.name}}</label>
            </div>
            <div *ngIf="customer.full_name && customer.full_name!=customer.name" class="item">
               <label class="label">{{'customer.fullname'|translate}}:</label>
               <label class="value">{{customer.full_name}}</label>
            </div>
            <div class="item mt-2">
               <label class="label">{{'customer.type'|translate}}:</label>
               <label class="value">{{customer.customertype.description}}</label>
            </div>
            <div *ngIf="customer.coc" class="item">
               <label class="label">{{'customer.coc-number'|translate}}:</label>
               <label class="value">{{customer.coc}}</label>
            </div>
            <div *ngIf="customer.debtor_id" class="item">
               <label class="label">{{'customer.debtornumber'|translate}}:</label>
               <label class="value">{{customer.debtor_id}}</label>
            </div>
            <div *ngIf="customer.phone" class="item mt-2">
               <label class="label">{{'entity.phonenumber'|translate}}:</label>
               <label class="value">{{customer.phone}}</label>
            </div>
            <div *ngIf="customer.email" class="item">
               <label class="label">{{'entity.email'|translate}}:</label>
               <label class="value">{{customer.email}}</label>
            </div>
            <div class="item mt-2">
               <label class="label">{{'entity.blocked'|translate}}:</label>
               <label *ngIf="customer.blocked" class="value"><span class="badge bg-danger">{{'ui.yes'|translate}}</span></label>
               <label *ngIf="!customer.blocked" class="value"><span class="badge bg-success">{{'ui.no'|translate}}</span></label>
            </div>
            <div *ngIf="customer.description" class="item mt-2">
               <jbm-comments-box [comments]="customer.description" height="4"></jbm-comments-box>
            </div>

         </div>
      </div>
      <div class="col-md-6">
         <h6>{{'entity.address'|translate}}</h6>
         <div class="jbm-properties-list customer-address">
            <div class="item">
               <label class="label">{{'entity.streetname-number'|translate}}:</label>
               <label class="value">{{customer.address_details.street}}&nbsp;{{customer.address_details.no}}</label>
            </div>
            <div class="item">
               <label class="label">{{'entity.postalcode'|translate}} {{'ui.and'|translate}} {{'entity.cityname'|translate|lowercase}}:</label>
               <label class="value">{{customer.address_details.postcode}}&nbsp;&nbsp;{{customer.address_details.city}}</label>
            </div>
            <div *ngIf="!customer.invoice_address_id" class="item">
               <label class="value"><span class="badge badge-info">{{'customer.invoice-address-same'|translate}}</span></label>
            </div>
            <div class="item mt-1">
               <label class="label">&nbsp;</label>
               <label class="value">
                  <jbm-gps-location-button *ngIf="GPS_address.latitude"
                    [latitude]="GPS_address.latitude"
                    [longitude]="GPS_address.longitude"
                    title="{{'customer'|translate}}&nbsp;{{'entity.address'|translate|lowercase}}"
                    [address]="customer.address_details.street+'&nbsp;'+customer.address_details.no+', '+customer.address_details.city">
                  </jbm-gps-location-button>
               </label>
            </div>
         </div>

         <h6>{{'customer.billing'|translate}}</h6>
         <div class="jbm-properties-list customer-address">
            <div *ngIf="customer.invoice_email" class="item">
               <label class="label">{{'customer.invoice-email'|translate}}:</label>
               <label class="value">{{customer.invoice_email}}</label>
            </div>
         </div>
         <h6 *ngIf="customer.invoice_address_id" class="mt-3">{{'customer.invoice-address'|translate}}</h6>
         <div *ngIf="customer.invoice_address_id" class="jbm-properties-list customer-address">
            <div class="item">
               <label class="label">{{'entity.streetname-number'|translate}}:</label>
               <label class="value">{{customer.invoice_address_details.street}}&nbsp;{{customer.invoice_address_details.no}}</label>
            </div>
            <div class="item">
               <label class="label">{{'entity.postalcode'|translate}} {{'ui.and'|translate}} {{'entity.cityname'|translate|lowercase}}:</label>
               <label class="value">{{customer.invoice_address_details.postcode}}&nbsp;&nbsp;{{customer.invoice_address_details.city}}</label>
            </div>
            <div class="item mt-1">
               <label class="label">&nbsp;</label>
               <label class="value">
                  <jbm-gps-location-button  *ngIf="GPS_invoice_address.latitude"
                    [latitude]="GPS_invoice_address.latitude"
                    [longitude]="GPS_invoice_address.longitude"
                    title="{{'customer'|translate}}&nbsp;{{'customer.invoice-address'|translate|lowercase}}"
                    [address]="customer.invoice_address_details.street+'&nbsp;'+customer.invoice_address_details.no+', '+customer.invoice_address_details.city"
                  ></jbm-gps-location-button>
               </label>
            </div>
         </div>
         <div class="jbm-properties-list customer-address mt-3">
            <div class="item">
               <label class="label">{{'customer.shift-vat-setting'|translate}}:</label>
               <label *ngIf="shift_vat_setting" class="value">{{'ui.yes'|translate}}</label>
               <label *ngIf="!shift_vat_setting" class="value">{{'ui.no'|translate}}</label>
            </div>
            <div class="item">
               <label class="label">{{'invoicing.on-resources-based'|translate}} {{'invoice.invoicing'|translate|lowercase}}:</label>
               <label *ngIf="resources_based_invoicing" class="value">{{'ui.yes'|translate}}</label>
               <label *ngIf="!resources_based_invoicing" class="value">{{'ui.no'|translate}}</label>
            </div>
         </div>
      </div>
   </div>
</div>
<div *ngIf="inModal" class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button [visible]="true" (click)="close()"></jbm-close-button>
   </div>
</div>
