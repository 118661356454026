<form [formGroup]="form" (ngSubmit)="save()" class="text-placeholder">
    <div class="jbm-modal-header">
        <jbm-crud-title [subject]="'presentations.text-placeholder'|translate" [action]="action"></jbm-crud-title>
        <div class="toolbar float-end">
            <jbm-activity-log-button *ngIf="textPlaceholder.id" [title]="'presentations.text-placeholder'|translate"
              [subject]="'menu.presentations'|translate" [subject_type]="32" [subject_id]="textPlaceholder.id"></jbm-activity-log-button>
            <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <jbm-text-input-group [id]="'name'" [formControl]="formControls['name']" [required]="true" [submitted]="isSubmitted"
            prepend="[[" append="]]" [labelCaption]="'entity.name'|translate" labelClass="md" class="md">
            <jbm-input-error [formControl]="formControls['name']" [submitted]="isSubmitted">
                <span *ngIf="formControls['name'].hasError('notUnique')">{{'entity.name-not-unique'|translate}}</span>
                <span *ngIf="formControls['name'].hasError('required')">{{'entity.requiredfield'|translate}}</span>
            </jbm-input-error>
        </jbm-text-input-group>
        <jbm-text-input [id]="'description'" [formControl]="formControls['description']" [required]="true"
            [submitted]="isSubmitted" [labelCaption]="'entity.description'|translate" labelClass="md" class="md mt-4">
        </jbm-text-input>
        <jbm-textarea [formControl]="formControls.text" [submitted]="isSubmitted" [id]="'text'" [required]="true" class="mt-4 md"
          labelCaption="{{'ui.text'|translate}}" labelClass="md" rows="3">
        </jbm-textarea>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="'presentations.text-placeholder'|translate" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
<jbm-toasts></jbm-toasts>
