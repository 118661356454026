import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PriceService} from "../../../../services/price/price.service";
import {InvoicesService} from "../../data/invoices.service";
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {Invoice} from "../../data/interfaces/invoice";

@Component({
  selector: 'invoice-handle-payment',
  templateUrl: './invoice-handle-payment.component.html',
  styleUrls: ['./invoice-handle-payment.component.scss']
})
export class InvoiceHandlePaymentComponent implements OnInit {
  form: FormGroup;
  language: string=this.TokenService.getLanguage();
  invoice: Invoice;
  submitted: boolean=false;
  totalPriceStr: string='';
  outstandingStr: string='';

  constructor(
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private formBuilder: FormBuilder,
      private InvoicesService: InvoicesService,
      private PriceService: PriceService
  ) {
    this.invoice=this.InvoicesService.getEmpty();
    this.addControls();
  }

  @Input() invoice_id: number;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  ngOnInit(): void {
    if(this.invoice_id==undefined) this.invoice_id=0;
  }
  ngOnChanges() {
    this.InvoicesService.getOne(this.language, this.invoice_id,null).subscribe(
        (data)=>this.invoice=data.data,
        (error)=>console.error(error),
        ()=> {
          this.totalPriceStr='€ '+this.PriceService.formatPrice(this.invoice.totalprice_incl_vat);
          if(this.invoice.outstanding>0)
            this.outstandingStr='€ '+this.PriceService.formatPrice(this.invoice.outstanding);
          else
            this.outstandingStr=this.TranslateService.GetTranslation('ui.inapplicable');

          document.getElementById('amount').focus();
        }
    )
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          amount: new FormControl('',[this.PriceService.priceValidator(this.PriceService)] ),
          comments: new FormControl('' ),
        }
    );
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    // Check amount
    let amount=this.formControls.amount.value;
    amount=amount.replace(',','.');
    amount=parseFloat(amount);
    if(amount>this.invoice.outstanding) {
      this.formControls.amount.setErrors({outstandingExceed: true});
      return;
    }

    let data={
      invoice_id: this.invoice_id,
      amount: amount,
      comments: this.formControls.comments.value
    }
    this.InvoicesService.handlePayment(data).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=>this.onSuccess.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
