import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMTableDisplayValue} from "../../../components/JBM/JBMTable/JBMTable.component";
import {ArticlePricelist} from "./interfaces/article-pricelist";

export enum PricelistsType { articles, concrete, resource}

const baseURL: string = 'financial/';
const slugs = ['article','concrete','resource'];

@Injectable({
  providedIn: 'root'
})
export class ArticlePricelistsService {
  articlePricelist: ArticlePricelist;

  constructor(private HttpService: HttpService) {}

  get(pricelistsType: PricelistsType, language: string, params: HttpParams) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL + 'read/' + slug + '-pricelists/' + language, params);
  }
  getUses(pricelistsType: PricelistsType, id: number) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL+'read/'+slug+'-pricelist-uses/'+id.toString());
  }
  getDeletePermissions(pricelistsType: PricelistsType, ids: string[]) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL+'read/'+slug+'-pricelists-delete-permissions/'+ids.join());
  }

  /* Get base pricelists */
  getBaseLists(pricelistsType: PricelistsType, language: string) {
    let params=new HttpParams();
    if(pricelistsType===PricelistsType.articles)
      params=params.append('filter','apl.type eq 0');
    else
      params=params.append('filter', 'cpl.type eq 0');
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL + 'read/' + slug + language, params);
  }

  /* Get non-base pricelists (discounts/additions or adjustments */
  getNonBaseLists(pricelistsType: PricelistsType, language: string) {
    let params=new HttpParams();
    if(pricelistsType===PricelistsType.articles)
      params=params.append('filter', 'apl.type gt 0');
    else
      params=params.append('filter', 'cpl.type gt 0');
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL + 'read/' + slug + language, params);
  }

  getOne(pricelistsType: PricelistsType, id: number, language: string) {
    let params=new HttpParams().set('id',id.toString());
    return this.get(pricelistsType, language, params);
  }
  getEmpty() {
    this.articlePricelist={
      id: 0,
      description: '',
      valid_from: '',
      valid_to: '',
      type: -1
    }
    return this.articlePricelist;
  }

  create(pricelistsType: PricelistsType, data: any) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL + 'create/' + slug+'-pricelists', data);
  }
  update(pricelistsType: PricelistsType, data: any) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL + 'update/' + slug+'-pricelists', data);
  }
  copyNewYear(pricelistsType: PricelistsType, id: number, data) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.post(baseURL + 'create/' + slug +'-pricelists/new-year/' + id.toString(), data);
  }
  delete(pricelistsType: PricelistsType, id: number) {
    let slug=this.getPricelistsTypeSlug(pricelistsType);
    return this.HttpService.get(baseURL + 'delete/' + slug + '-pricelists/'+id);
  }

  getPricelistsTypeSlug(pricelistsType: PricelistsType) {
    return slugs[pricelistsType];
  }

  // Pricelists options

  getSelectOptions( pricelists: any[], withPeriod: boolean=true, insertEmptyOption: boolean=true ): JBMSelectOption[] {
    let options: JBMSelectOption[]=insertEmptyOption ? [{key:'',value:''}] : [];
    for(let p=0; p<pricelists.length; p++) {
      let pricelist=pricelists[p];
      if(withPeriod) {
        let dateFrom=pricelist.valid_from;
        let dateTo  =pricelist.valid_to;
        pricelist.description+=' ('+dateFrom+' - '+dateTo+')';
      }
      options.push( { key: pricelist.id.toString(), value: pricelist.description } );
    }
    return options;
  }

  // Pricelist type items

  getTypeItems(TranslateService: TranslateService) {
    return [
      {key: 0, value: TranslateService.GetTranslation('pricelist.type-base')},
      {key: 1, value: TranslateService.GetTranslation('pricelist.type-discount-addition')},
      {key: 2, value: TranslateService.GetTranslation('pricelist.type-overrule')}
    ];
  }

  getTypeSelectOptions(TranslateService: TranslateService): JBMSelectOption[] {
    let typeItems=this.getTypeItems(TranslateService);
    let options: JBMSelectOption[]=[];
    for(let ti=0; ti<typeItems.length; ti++)
      options.push({key: typeItems[ti].key.toString(), value: typeItems[ti].value});
    return options;
  }

  getTypeDisplayValues(TranslateService: TranslateService): JBMTableDisplayValue[] {
    let typeItems=this.getTypeItems(TranslateService);
    let values: JBMTableDisplayValue[]=[];
    for(let ti=0; ti<typeItems.length; ti++)
      values.push({dataValue: typeItems[ti].key, displayValue: typeItems[ti].value});
    return values;
  }

  getTypeAsString(type: number, TranslateService: TranslateService) {
    let typeItems=this.getTypeItems(TranslateService);
    if((type<0) || (type>=typeItems.length))
      return '';
    return typeItems[type].value;
  }
}
