import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'jbm-cross-close-button',
  templateUrl: './JBMCrossCloseButton.component.html',
  styleUrls: ['./JBMCrossCloseButton.component.scss'],
  host: {'class': 'JBMButton'}
})
export class JBMCrossCloseButtonComponent {
  @Input() visible: boolean;
  @Output() onClick = new EventEmitter();
  click() {
    this.onClick.emit();
  }
}
