<form [formGroup]="form" (ngSubmit)="save()" class="contact">
   <div class="jbm-modal-header">
      <jbm-crud-title [subject]="'entity.contact'" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <div class="btn-toolbar">
            <jbm-activity-log-button *ngIf="contact.id" subject_type="35" [subject_id]="contact.id" class="light"
              subject="{{'entity.contact'|translate}}" [title]="contact.name"></jbm-activity-log-button>
            <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         </div>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label *ngIf="role" labelClass="md" labelCaption="{{'entity.role'|translate}}" [value]="role"></jbm-static-label>
      <jbm-text-input [formControl]="formControls.firstname" [submitted]="submitted" [id]="'firstname'" [required]="nameRequired"
        [maxlength]="75" class="mt-3 md" labelCaption="{{'entity.firstname'|translate}}" labelClass="md">
         <jbm-input-error *ngIf="nameRequired" [formControl]="formControls.firstname" [submitted]="submitted">
            <span *ngIf="formControls.firstname.hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.lastname" [submitted]="submitted" [id]="'lastname'" class="md" [maxlength]="75"
        [required]="nameRequired" labelCaption="{{'entity.lastname'|translate}}" labelClass="md">
         <jbm-input-error *ngIf="nameRequired" [formControl]="formControls.lastname" [submitted]="submitted">
            <span *ngIf="formControls.lastname.hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.phone" [submitted]="submitted" [id]="'phone'" class="mt-3 xs"
        [required]="phoneRequired" [maxlength]="15" labelCaption="{{'entity.phone'|translate}}" labelClass="md">
         <jbm-input-error *ngIf="phoneRequired" [formControl]="formControls.phone" [submitted]="submitted">
            <span *ngIf="formControls.phone.hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.email" [submitted]="submitted" [id]="'email'"
        [required]="emailRequired" [maxlength]="125" labelCaption="{{'entity.emailaddress'|translate}}" class="md" labelClass="md">
         <jbm-input-error [formControl]="formControls.email" [submitted]="submitted">
            {{'entity.emailformat'|translate}}
         </jbm-input-error>
      </jbm-text-input>
   </div>
   <div class="jbm-modal-footer">
      <div class="action-buttons float-end">
         <jbm-save-button [subject]="'contact'|translate|titlecase" [small]="false" (click)="save()"></jbm-save-button>
         <jbm-cancel-button context="secondary" [small]="false" (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
