import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {RecipesService} from "../data/recipes.service";
import {ConcreteEnvironmentsService } from "../../financial/data/concrete-environments.service";
import {ConcreteStrengthsService } from "../../financial/data/concrete-strengths.service";
import {ConcreteConsistenciesService } from "../../financial/data/concrete-consistencies.service";
import {CRUDAction} from "../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {TokenService} from "../../../services/auth/token.service";
import {RecipeResourcesComponent} from "./recipe-resources/recipe-resources.component";
import {HttpParams} from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {JBMSelectOption} from '../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';

@Component({
  selector: 'recipe-form',
  templateUrl: './recipe-form.component.html',
  styleUrls: ['./recipe-form.component.scss']
})
export class RecipeFormComponent implements OnInit {
  recipe: any;
  action: CRUDAction;
  form: FormGroup;
  environments: JBMSelectOption[]=[];
  environment_ids: number[]=[];
  strengths: any[]=[];
  consistencies: any[]=[];
  strength_id: number=0;
  consistency_id: number=0;
  submitted: boolean=false;
  language: string=this.TokenService.getLanguage();

  constructor(
      private RecipesService: RecipesService,
      private ConcreteEnvironmentsService: ConcreteEnvironmentsService,
      private ConcreteStrengthsService: ConcreteStrengthsService,
      private ConcreteConsistenciesService: ConcreteConsistenciesService,
      private TokenService: TokenService,
      private formBuilder: FormBuilder
  ) {
    this.recipe={ id: 0, description:'', internal_description: '', strengths_id: 0, consistencies_id: 0 }
    this.addControls();
  }

  @Input() id: number;
  @Input() activeTab: number=0;

  @Output() onClose=new EventEmitter();
  @Output() onSave=new EventEmitter();

  @ViewChild(RecipeResourcesComponent) recipeResources: RecipeResourcesComponent;

  ngOnInit(): void {
    if(this.id===0) {
      this.action=CRUDAction.Create;
      this.getConcreteSpecs();
      return;
    }
    this.action=CRUDAction.Update;
    let params=new HttpParams();
    params=params.append('concrete-specifications','1');
    this.RecipesService.getOne(this.id, this.language, params).subscribe(
      (data)=> {
        this.recipe=data.data;
        this.environment_ids=this.recipe.environments;
      },(error)=>console.error(error),
      ()=> this.getConcreteSpecs())
  }
  getConcreteSpecs() {
    let params=new HttpParams().append('sort','descriptions.description'); // Sort +description
    params=params.append('no-baseprice-indicators','1');
    let envObservable=this.ConcreteEnvironmentsService.getData(this.language, params);
    let conObservable=this.ConcreteConsistenciesService.getData(this.language, params);
    let strObservable=this.ConcreteStrengthsService.getData(this.language, params);

    forkJoin([envObservable, conObservable, strObservable]).subscribe(results => {
      let envData=results[0].data;
      for(let environment of envData)
        this.environments.push({ key: environment.id.toString(), value: environment.code });
      this.consistencies=results[1].data;
      this.strengths=results[2].data;
      this.setControlValues();
    });
  }
  addControls() {
    this.form = this.formBuilder.group(
      {
        description: new FormControl('',{updateOn: 'blur', validators: [Validators.required]}),
        internal_description: new FormControl('',{updateOn: 'blur'}),
      }
    );
  }
  setControlValues() {
    this.form.controls.description.setValue(this.recipe.description);
    this.form.controls.internal_description.setValue(this.recipe.internal_description);
    this.strength_id=this.recipe.strengths_id;
    this.consistency_id=this.recipe.consistencies_id;
    this.environment_ids=this.recipe.environments;
  }
  get formControls() {
    return this.form.controls;
  }
  selectStrength(event) {
    this.strength_id=event.id;
  }
  selectConsistency(event) {
    this.consistency_id=event.id;
  }
  selectEnvironment(ids: number[]) {
    this.environment_ids=ids;
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let data={
      id: this.id,
      description: this.form.controls.description.value,
      internal_description: this.form.controls.internal_description.value,
      strengths_id: this.strength_id,
      consistencies_id: this.consistency_id,
      environment_ids: this.environment_ids.join(',')
    }

    if(this.recipe.id)
      this.RecipesService.update(data).subscribe(
        ()=>{},
        (error)=>this.handleError(error),
        ()=>this.afterSave())
    else
      this.RecipesService.create(data).subscribe(
        (data)=>this.id=data.id,  // Primary key is needed for the resources
        (error)=>this.handleError(error),
        ()=>this.afterSave())
  }
  handleError(error) {
    if(error=='code_not_unique' || error=='description_not_unique')
      this.formControls.description.setErrors({ notUnique: true});
    else
      console.error(error);
  }
  afterSave() {
    this.recipeResources.save(this.id);
    this.onSave.emit();
  }
  close() {
    this.onClose.emit();
  }
}
