import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'jbm-switch',
  templateUrl: './JBMSwitch.component.html',
  styleUrls: ['./JBMSwitch.component.scss']
})
export class JBMSwitchComponent implements OnInit {

  constructor() { }

  @Input() id: string='JBMSwitch';
  @Input() active: boolean;
  @Input() label: string;
  @Input() enabled: boolean;

  @Output() onClick=new EventEmitter();

  ngOnInit(): void {
    if(this.active==undefined) this.active=true;
    if(this.enabled==undefined) this.enabled=true;
  }
  click() {
    this.onClick.emit({ id: this.id, active: !this.active } );
  }
}
