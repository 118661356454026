import { Injectable } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {ProjectState} from "./interfaces/projectstate";

const baseURL: string = 'projects/';
const slug: string = 'projectstates/';

export const WorkflowStates = {
  potential: 1,
  quotation: 2,
  confirmed: 3,
  planned: 4,
  inProgress: 5,
  workDone: 6,
  completed: 7,
  cancelled: 8
}

@Injectable({
  providedIn: 'root'
})
export class ProjectstatesService {
  projectState: ProjectState;

  constructor(private HttpService: HttpService) { }

  get(language: string, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/'+slug+language, params);
  }
  getOne(id: number, language: string) {
    let params=new HttpParams().append('subject_id', id.toString());
    return this.HttpService.get(baseURL+'read/'+slug+language, params);
  }
  getEmpty() {
    this.projectState={
      id: 0,
      description: '',
      color: '',
      text_color: '',
      workflow_state: 0
    }
    return this.projectState;
  }
  filterPotentialState(projectStates: ProjectState[]): ProjectState {
    let result=projectStates.filter(state => state.workflow_state===WorkflowStates.potential);
    return result.length ? result[0]: null;
  }
  filterQuotationState(projectStates: ProjectState[]): ProjectState {
    let result=projectStates.filter(state => state.workflow_state===WorkflowStates.quotation);
    return result.length ? result[0]: null;
  }
  getSelectOptions(projectStates: ProjectState[]): JBMSelectOption[] {
    let options: JBMSelectOption[]=[];
    for(let ps=0; ps<projectStates.length; ps++)
      options.push({key: projectStates[ps].id.toString(), value: projectStates[ps].description});
    return options;
  }
  getByID(projectStates: any[], id: number): any {
    for(let ps=0; ps<projectStates.length; ps++)
      if(projectStates[ps].id===id)
        return projectStates[ps];
    return false;  // Means not found
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+slug, data);
  }
}
