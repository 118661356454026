import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {UsergroupService} from "../data/usergroup.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {
  JBMTableActionDef,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Subscription} from "rxjs";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {Usergroup} from "../data/interfaces/usergroup";

@Component({
  selector: 'usergroups',
  templateUrl: './usergroups.component.html',
  styleUrls: ['./usergroups.component.scss']
})
export class UsergroupsComponent implements OnInit {
  userRights: any;
  usergroups: any[];
  usergroup: Usergroup;

  queryHttpHelper: QueryHttpHelper;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];

  appID: number=0;  // Management

  data$: Subscription;

  @ViewChild('modalUsergroup') modalTemplate: ElementRef;
  @ViewChild('table') table: JBMTableComponent;

  constructor(
      private UsergroupService: UsergroupService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) {}

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('users');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'),
        filterable: true, width: 11 },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'),
        filterable: true, hideable: false },
      { name: 'grouprights', type: JBMTableColumnType.panelToggle, header: this.TranslateService.GetTranslation('users.grouprights'), width: 9 }
    ];

    this.actionDefs = [ { name: 'edit', iconClass: 'far fa-edit' } ];

    this.getData();
  }
  getData() {
    this.data$=this.UsergroupService.getData(this.appID).subscribe(
        (data: any) => this.usergroups = data.data,()=>{},()=>{
        });
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  rowSelect(event) {
    this.table.toggleRowExpanded(event);
  }
  actionClick(event) {
    if(event.name=='edit') this.editGroup(event.row.id);
  }
  appChange(event) {
    this.appID=parseInt(event.target.value);
    this.getData();
  }
  addGroup() {
    this.usergroup=this.UsergroupService.getEmpty();
    this.ModalService.open(this.modalTemplate,'usergroup');
  }
  editGroup(groupid: number) {
    this.UsergroupService.getOne(groupid).subscribe(
        (data)=>this.usergroup=data.data as Usergroup,(error)=>console.error(error),()=> {
          this.ModalService.open(this.modalTemplate,'usergroup');
        }
    )
  }
  saved() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Emit message for refreshing usergroups in parent component
    this.ModalService.close('usergroup');
    this.getData();
  }
  canceled() {
    this.ModalService.close('usergroup');
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.data$!=undefined) this.data$.unsubscribe();
  }
}
