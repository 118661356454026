import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
  HostListener,
  ElementRef,
  OnChanges, ViewChild
} from '@angular/core';
import {TrucksService} from "../../../../features/logistics/data/trucks.service";
import {PlantsService} from "../../../../features/company/data/plants.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnDef, JBMTableColumnType, JBMTableComponent} from "../../../JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {Truck} from "../../../../features/logistics/data/interfaces/truck";
import {JBMSelectOption} from '../../../JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';

@Component({
  selector: 'truck-selector',
  templateUrl: './truck.selector.component.html',
  styleUrls: ['./truck.selector.component.scss']
})
export class TruckSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  truck: Truck=this.TrucksService.getEmpty();
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();
  plantOptions: JBMSelectOption[]=[];

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;

  @Input() truck_id: number=0;
  @Input() plant_id: number=0;

  @Output() truckSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  @Input() template: TemplateRef<any>;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('capacityTemplate') capacityTemplate: TemplateRef<any>;

  constructor(
      private TrucksService: TrucksService,
      private PlantsService: PlantsService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'number', header: this.TranslateService.GetTranslation('ui.number'), sortable: true, filterable: true },
      { name: 'numberplate', header: this.TranslateService.GetTranslation('truck.numberplate'), sortable: true, filterable: true, width: 8 },
      { name: 'capacity', header: this.TranslateService.GetTranslation('production.capacity'), sortable: true, width: 6 },
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('number');
    this.queryHttpHelper.addSearchColumn('number', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('numberplate', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;

    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=> {
          if(plants.length > 1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
          }
        });
  }
  ngOnChanges() {
    if(this.truck_id) this.setTruck();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('truck-search').focus() },100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.truck_id=0;
    this.truck=this.TrucksService.getEmpty();
    this.hideList();
    this.truckSelected.emit(this.truck );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    this.columnDefs[3].template = this.capacityTemplate;

    let params=this.formatParams(false);
    this.items$=this.TrucksService.get(params).subscribe(
        (data: any) => this.items = data.data,(error)=>console.error(error),()=> {
          this.formatData();
          if(countRows) {
            let params=this.formatParams(true);
            this.count$=this.TrucksService.get(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  formatParams(rowcount: boolean=true) {
    let params=this.queryHttpHelper.getHttpParams(rowcount);
    if(this.plant_id)
      params=params.append('plant_id', this.plant_id.toString());
    return params;
  }
  formatData() {
    for(let truck of this.items) {
      truck.capacityStr=truck.capacity.toString().replace('.',',');
    }
  }
  changePlant(event) {
    this.plant_id=parseInt(event.target.value);
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  setTruck() {
    this.TrucksService.getOne(this.truck_id).subscribe(
        (data: any)=>this.truck=data.data as Truck,
        (error)=>console.error(error)
    )
  }
  rowSelect(row) {
    this.truck=row;
    this.truck_id=row.id;
    this.truckSelected.emit(this.truck);
    this.hideList();
  }

  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }

  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
