import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {PlantsService} from "../plants.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";
import {Plant} from "../interfaces/Plant";

@Injectable({
  providedIn: 'root'
})
export class PlantResolver implements Resolve<any> {

  constructor( private Router: Router, private PlantsService: PlantsService ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Plant> {
    let id=parseInt(route.paramMap.get('id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      return this.PlantsService.getOne(id).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
