import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'quotations/';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  constructor(private HttpService: HttpService) { }

  getBaseRecipe(params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/base-recipe', params );
  }
}
