import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RecipesService} from "../../recipes/data/recipes.service";
import {TokenService} from "../../../services/auth/token.service";
import {PriceService} from "../../../services/price/price.service";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'invoice-recipes',
  templateUrl: './invoice-recipes.component.html',
  styleUrls: ['./invoice-recipes.component.scss']
})
export class InvoiceRecipesComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  recipe: any= { code: '', strength: { description: ''}, consistency: { description: ''}, articles: [] };

  constructor(
      private RecipesService: RecipesService,
      private PriceService: PriceService,
      private TokenService: TokenService
  ) { }

  @Input() projects_id: number;
  @Input() customers_id: number;
  @Input() recipe_id: number;
  @Input() delivery_date: string;
  @Input() environment: string;

  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    let params=new HttpParams().append('articles','1').append('concrete-specifications','1');
    this.RecipesService.getOne(this.recipe_id, this.language, params ).subscribe(
        (data)=>this.recipe=data.data,
        (error)=>console.error(error),
        ()=> {
          for(let article of this.recipe.articles)
            article.amount=this.PriceService.formatPrice(article.amount) + ' ' + article.article.price_type_code;
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
