import { NgModule } from '@angular/core';
import { RouterModule} from "@angular/router";
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule} from "../shared/shared.module";
import { MainlayoutComponent} from "./mainlayout/mainlayout.component";
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { GuestheaderComponent } from './guestlayout/guestheader.component';
import { GuestlayoutComponent} from "./guestlayout/guestlayout.component";
import { JBMButtonsModule} from "../components/JBM/Buttons/jbmbuttons.module";
import { JBMModalModule} from "../components/JBM/JBMModal/jbmmodal.module";
import { JBMViewModule} from "../components/JBM/Views/jbmview.module";

@NgModule({
  declarations: [MainlayoutComponent, HeaderComponent, FooterComponent, SidebarComponent,
    GuestlayoutComponent, GuestheaderComponent],
  imports: [CommonModule, SharedModule.forRoot(), NgbModule, JBMButtonsModule, JBMViewModule, JBMModalModule, RouterModule.forChild([])],
    exports: [MainlayoutComponent, GuestlayoutComponent, HeaderComponent]
})
export class LayoutModule { }
