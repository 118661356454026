import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroupBaseComponent} from "../../formgroupbase.component";

@Component({
  selector: 'jbm-time-input',
  templateUrl: './JBMTimeInput.component.html',
  styleUrls: ['./JBMTimeInput.component.scss']
})

export class JBMTimeInputComponent extends FormGroupBaseComponent implements OnInit {

  @Output() onBlur = new EventEmitter();

  ngOnInit() {
    super.ngOnInit();
  }
  blur(event) {
    this.onBlur.emit(event);
  }
}