import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'password-constraints-alert',
  templateUrl: './password-constraints-alert.component.html',
  styleUrls: ['./password-constraints-alert.component.scss']
})
export class PasswordConstraintsAlertComponent implements OnInit {

  constructor() { }

  @Input() visible: boolean=false;

  ngOnInit(): void {
    if(this.visible==undefined) this.visible=false;
  }
}
