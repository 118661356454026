<app-header>
   <jbm-view-title [caption]="'menu.presentations'|translate"
     [subCaption]="('ui.text'|translate)+' '+('presentations.placeholders'|translate|lowercase)"
     [itemcount]="queryHttpHelper.paginationProps.itemCount"
     [parentLinkCaption]="'menu.settings'|translate"
     [parentLinkSlug]="'settings'"></jbm-view-title>
   <div class="toolbar float-start">
      <label for="language" class="label">{{'translate.language'|translate}}</label>
      <select id="language" class="form-control form-select" (change)="languageChange($event)">
         <option *ngFor="let lang of languages" value="{{lang.key}}">{{lang.value}}</option>
      </select>
      <jbm-create-button [visible]="userRights.AllowCreate" (click)="addItem()" class="ml-4"></jbm-create-button>
   </div>

   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page"
        (pageClick)="pageChange($event)">
      </jbm-pagination>
      <jbm-searchbox [id]="'search-box'" [visible]="searchboxVisble" [search]="search" [minlength]="2" (searchChange)="searchChange($event)"></jbm-searchbox>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module">
   <jbm-table #table [classname]="'text-placeholders padding'"
     [data]="items"
     [filterable]="true"
     [showFilters]="filtersVisible"
     [searchMinLength]="3"
     [sortable]="true"
     [sortColumn]="queryHttpHelper.sortProps.name"
     [sortDirection]="queryHttpHelper.sortProps.direction"
     [hoverable]="true"
     [rowSelectable]="userRights.AllowUpdate"
     [columnDefs]="columnDefs"
     (rowCountChange)="rowCountChange($event)"
     (filterChange)="filterChange($event)"
     (sortChange)="sortChange($event)"
     (rowSelect)="rowSelect($event)">
   </jbm-table>
</section>

<ng-template #actionsTemplate let-data="data">
   <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-sm btn-light mr-3 float-start" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
     (click)="edit(data.id); $event.stopPropagation()"><i class="far fa-edit"></i></button>
   <jbm-confirm-delete-dropdown *ngIf="userRights.AllowDelete && data.deletePermission" class="float-start"
     [ngbTooltip]="'ui.delete-confirm-tooltip'|translate"
     [placement]="'left-top'"
     [subject]="'presentations.text-placeholder'|translate"
     (onConfirm)="delete(data.id)">
   </jbm-confirm-delete-dropdown>
</ng-template>

<jbm-modal [id]="'textPlaceholder'" [className]="'textPlaceholder'"></jbm-modal>
<ng-template #modalTextPlaceholder let-modal>
   <form-text-placeholder [id]="id" [language]="language" (onCancel)="onCanceled()" (onSave)="onSaved()"></form-text-placeholder>
</ng-template>

<jbm-toasts></jbm-toasts>
