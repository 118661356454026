import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService} from "../../services/auth/authguard.service";
import { AppLayouts} from "../../app.component";
import { ResourcesTypesComponent} from "./datatable/resources-types.component";
import {RecipesComponent} from "./datatable/recipes.component";

const routes: Routes = [
  {
    path: 'settings/resources',
    component: ResourcesTypesComponent,
    canActivate: [ AuthGuardService ],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/recipes',
    component: RecipesComponent,
    canActivate: [ AuthGuardService ],
    data: {AppLayout: AppLayouts.User}
  }
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecipesRoutingModule { }
