import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {RecipeUsersComponent} from "./users/recipe-users.component";
import {RecipeAppGuardService} from "../../services/auth/recipeApp-guard.service";

const routes: Routes = [
  {
    path: 'recipe-app/users',
    component: RecipeUsersComponent,
    canActivate: [AuthGuardService, RecipeAppGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecipeAppRoutingModule { }
