<div class="aside-container">
    <div class="aside-header">
        <jbm-aside-title [caption]="title" [iconClassName]="'map'" (onClose)="close()"></jbm-aside-title>
    </div>
    <div class="aside-body gps-location">
        <link rel="stylesheet" href="https://openlayers.org/en/v6.1.1/css/ol.css" type="text/css">
        <div class="address">{{address}}</div>
        <div id="map" class="map"><div id="popup"></div></div>
        <div id="ol-marker" title="Marker">
            <img src="assets/icons/pin.png">
        </div>
    </div>
</div>