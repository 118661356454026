import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {QuotationsService} from "../data/quotations.service";
import {CustomerService} from "../../customers/data/customer.service";
import {ProjectstatesService} from "../../projects/data/projectstates.service";
import {TokenService} from "../../../services/auth/token.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {FormBuilder} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import {ProjectState} from "../../projects/data/interfaces/projectstate";

@Component({
  selector: 'quotation-details',
  templateUrl: './quotation-details.component.html',
  styleUrls: ['./quotation-details.component.scss']
})
export class QuotationDetailsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  quotation: any;
  projectState: ProjectState=this.ProjectstatesService.getEmpty();

  @Input() quotation_id: number=0;
  @Input() revision_number: number=0;
  @Input() expanded: boolean;
  @Input() editable: boolean;

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;
  @ViewChild('modalCustomerComments') modalCustomerComments: ElementRef;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;

  constructor(
      private QuotationsService: QuotationsService,
      private CustomerService: CustomerService,
      private ProjectstatesService: ProjectstatesService,
      private TokenService: TokenService,
      private formBuilder: FormBuilder,
      private ModalService: ModalService
  ) {}
  ngOnInit() {
    let params=new HttpParams().append('project','1').append('customer','1');
    this.QuotationsService.getOne(this.quotation_id, this.revision_number, params).subscribe(
        (data)=>this.quotation=data.data,
        (error)=>console.error(error),
        ()=> {
            let params=new HttpParams().append('id',this.quotation.project.projectstates_id.toString());
            this.ProjectstatesService.get(this.language, params).subscribe(
                (data)=>this.projectState=data.data,
                (error)=>console.error(error)
            )
        }
    )
  }

  // Customer
  showCustomer() {
      this.ModalService.open(this.modalCustomerDetails,'customerDetails')
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }
  editCustomerComments() {
    this.ModalService.open(this.modalCustomerComments,'customerComments')
  }
  saveCustomerComments(event) {
    this.quotation.customer_comments=event.comments;
    this.closeCustomerComments();
  }
  closeCustomerComments() {
    this.ModalService.close('customerComments');
  }
  showProject() {
    this.ModalService.open(this.modalProjectDetails,'projectDetails')
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }
}
