import {Component, Input, OnChanges} from '@angular/core';
import {PdfService} from "../../../services/pdf-make/pdf-service";

@Component({
  selector: 'pdfmake-viewer',
  templateUrl: './pdf-make-viewer.component.html',
  styleUrls: ['./pdf-make-viewer.component.scss'],
  providers: [PdfService]
})
export class PDFMakeViewerComponent implements OnChanges {
  @Input() template: string;

  constructor( private PdfService: PdfService) {
    this.template=JSON.stringify({});
  }
  ngOnChanges(): void {
    this.PdfService.loadPdfMaker().then(unfulfillment => {
      console.log(this.template);
        this.PdfService.pdfMake.createPdf(JSON.parse(this.template)).getDataUrl(function(outDoc) {
          (document.getElementById('pdfObject') as HTMLObjectElement).data = outDoc;
        });
      }
    );
  }
}
