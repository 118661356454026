<div class="bg-light p-1 pl-4 pr-3 clearfix">
  <div class="float-start">
    <contact-dropdown #contact1
      [subject]="0"
      [subject_id]="quotation_id"
      [roles_id]="1"
      role="{{'contact.person'|translate}}"
      class="float-start"
      allowEditing="true"
      (OnEdit)="onEditContact($event)">
    </contact-dropdown>
    <contact-dropdown #contact2
      [subject]="1"
      [subject_id]="project_id"
      [roles_id]="2"
      role="{{'project.site-manager'|translate}}"
      class="float-start"
      allowEditing="true"
      (OnEdit)="onEditContact($event)">
    </contact-dropdown>
  </div>
  <div class="float-end align-right">
    <label class="mr-2">{{'ui.view'|translate}}: </label>
    <jbm-toggle-button [class]="'light mr-1'" [small]="true" [caption]="('entity.quotation'|translate)+('ui.details'|translate|lowercase)"
       [toggled]="settings.details" (onToggle)="toggleDetails()"></jbm-toggle-button>
    <jbm-toggle-button [class]="'light mr-1'" [small]="true" [caption]="'project.orders'|translate"
      [toggled]="settings.orders" (onToggle)="toggleOrders()"></jbm-toggle-button>
    <jbm-toggle-button [class]="'light mr-1'" [small]="true" [caption]="'financial.articles'|translate"
      [toggled]="settings.articles" (onToggle)="toggleArticles()"></jbm-toggle-button>
    <jbm-toggle-button [class]="'light'" [small]="true" [caption]="'entity.comments'|translate"
      [toggled]="settings.comments" (onToggle)="toggleComments()"></jbm-toggle-button>
  </div>
</div>

<jbm-modal [id]="'form-contact'" [className]="'contact'"></jbm-modal>
<ng-template #modalContact let-modal>
  <contact-form
    [id]="contact.id"
    [subject]="contact.subject"
    [subject_id]="contact.subject_id"
    [roles_id]="contact.roles_id"
    [role]="role"
    [nameRequired]="contact.subject==0 && contact.roles_id==1"
    [phoneRequired]="contact.subject==0 && contact.roles_id==1"
    [emailRequired]="contact.subject==0 && contact.roles_id==1"
    (onSave)="onSaveContact()"
    (onCancel)="onCancelContact()">
  </contact-form>
</ng-template>
