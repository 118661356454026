import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ArticlePricelistsService} from "../../data/article-pricelists.service";
import {CustomerPricelistsService} from "../../data/customer-pricelists.service";
import {ResourcePricelistsService} from "../../data/resource-pricelists.service";
import {ProjectsService} from "../../../projects/data/projects.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {NgbAccordion} from "@ng-bootstrap/ng-bootstrap";
import {ResourcePricelist} from "../../data/interfaces/resource-pricelist";

@Component({
  selector: 'resource-pricelist-uses',
  templateUrl: './resource-pricelist-uses.component.html',
  styleUrls: ['./resource-pricelist-uses.component.scss']
})
export class ResourcePricelistUsesComponent implements OnInit {
  uses: any={ customers: [], projects: [] }
  types=this.ArticlePricelistsService.getTypeItems(this.TranslateService);

  constructor(
      private ArticlePricelistsService: ArticlePricelistsService,
      private ResourcePricelistsService: ResourcePricelistsService,
      private CustomerArticlePricelistsService: CustomerPricelistsService,
      private ProjectsService: ProjectsService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  )
  {
    this.pricelist=this.ResourcePricelistsService.getEmpty()
  }

  @Input() pricelist: ResourcePricelist;

  @Output() onClose=new EventEmitter();

  @ViewChild('acc') acc: NgbAccordion;

  ngOnInit(): void {
    this.getData();
  }
  getData() {
    this.ArticlePricelistsService.getUses(2, this.pricelist.id).subscribe(
        (data)=>this.uses=data.data,
        (error)=>console.error(error)
    )
  }

  // Disconnect items
  confirmDisconnectCustomer(customer_id: number) {
    if(this.uses.customers.length===1)
        // Last customer will be removed, close panel before it's gonna be disabled
      this.acc.toggle('panel-1');
    this.CustomerArticlePricelistsService.deleteUse(2, this.pricelist.id, customer_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData();
        }
    )
  }
  confirmDisconnectProject(project_id: number) {
    if(this.uses.projects.length===1)
      // Last project will be removed, close panel before it's gonna be disabled
      this.acc.toggle('panel-2');
    this.ProjectsService.deleteUse(2, project_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData();
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
