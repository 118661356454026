import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'company/';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private HttpService: HttpService) { }

  getData() {
    return this.HttpService.get(baseURL+'read/');
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/', data);
  }
}
