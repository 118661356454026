import {NgModule } from '@angular/core';
import {Routes, RouterModule } from '@angular/router';
import {AppLayouts } from "../../app.component";
import {ProjectsComponent} from "./datatable/projects.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {ProjectViewComponent} from "./project-view/project-view.component";
import {OrderComponent} from "./orders/form/order.component";
import {ProjectResolver} from "./data/resolvers/project.resolver";
import {OrderResolver} from "./data/resolvers/order.resolver";
import {ProjectstatesComponent} from "./projectstates/projectstates.component";
import {UnloadingMethodsComponent} from "./unloading-methods/unloading-methods.component";
import {WorktypesComponent} from "./worktypes/worktypes.component";

const routes: Routes = [
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'projects/:project_id',
    component: ProjectViewComponent,
    resolve: { project: ProjectResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'projects/:project_id/orders/create',
    component: OrderComponent,
    resolve: { project: ProjectResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'projects/:project_id/orders/edit/:order_id',
    component: OrderComponent,
    resolve:  { order: OrderResolver, project: ProjectResolver },
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/projectstates',
    component: ProjectstatesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/unloading-methods',
    component: UnloadingMethodsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/worktypes',
    component: WorktypesComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProjectsRoutingModule { }
