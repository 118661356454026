<div class="toolbar float-start">
   <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
   <div class="btn-toolbar btn-group drivers-view">
      <button type="button" class="btn btn-sm btn-outline-primary" [ngClass]="{active: view==0}" (click)="setView(0)">{{'ui.all'|translate}}</button>
      <button type="button" class="btn btn-sm btn-outline-primary" [ngClass]="{active: view==1}" (click)="setView(1)">{{'entity.active'|translate}}</button>
      <button type="button" class="btn btn-sm btn-outline-primary" [ngClass]="{active: view==2}" (click)="setView(2)">{{'entity.inactive'|translate}}</button>
   </div>
</div>
<div class="toolbar float-end">
   <jbm-spinner [loading]="loading"></jbm-spinner>
   <div class="toolbar-spacer-1 float-end"></div>
   <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
     [pageSize]="queryHttpHelper.paginationProps.pageSize"
     [page]="queryHttpHelper.paginationProps.page"
     (pageClick)="pageChange($event)">
   </jbm-pagination>
   <jbm-searchbox [visible]="searchboxVisible" [search]="search" [minlength]="2" (searchChange)="searchChange($event)"></jbm-searchbox>
</div>
<table class="table table-sm" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
   <thead>
   <tr>
      <th [hidden]="true"></th>
      <th jbm-table-column class="name" [name]="'name'" [header]="'entity.name'|translate"
        [sortable]="true" [sorted]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)" width="20"></th>
      <th class="active">{{'entity.active'|translate}}</th>
      <th class="actions editable"></th>
   </tr>
   </thead>
   <tbody>
   <tr *ngIf="createActive" class="edit-active">
      <th [hidden]="true"></th>
      <td>
         <input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="name"
        (change)="onChangeName()" [ngClass]="{'ng-valid':validateName() && unique, 'is-invalid':nameTouched && !validateName() || !unique }">
         <div *ngIf="nameError" class="invalid-feedback">{{nameError}}</div>
      </td>
      <td>
         <jbm-switch [id]="'0'" (onClick)="active=!active" [active]="active"></jbm-switch>
      </td>
      <td class="align-right">
         <button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!validateName()" (click)="createItem()">{{'ui.save'|translate}}</button>
         <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
      </td>
   </tr>
   <tr *ngFor="let item of items" [ngClass]="{'edit-active': item.editing}" (click)="startEditing(item.id)">
      <td [hidden]="true"></td>
      <td *ngIf="!item.editing" class="name">{{item.name}}</td>
      <td *ngIf="item.editing" class="editable name">
         <input class="form-control form-control-sm" type="text" autocomplete="off"
           [(ngModel)]="name" [placeholder]="item.name" (change)="onChangeName()" [ngClass]="{'ng-valid':this.validateName(), 'is-invalid':!this.validateName() }">
         <div *ngIf="nameError" class="invalid-feedback">{{nameError}}</div>
      </td>
      <td *ngIf="item.editing" class="editable">
         <jbm-switch [id]="item.id" (onClick)="active=!active" [active]="active"></jbm-switch>
      </td>
      <td *ngIf="!item.editing" class="editable">
         <jbm-switch [id]="item.id" (onClick)="toggleActive($event)" [enabled]="userRights.AllowUpdate" [active]="item.active"></jbm-switch>
      </td>
      <td *ngIf="!item.editing" class="actions"><button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm float-end"><i class="far fa-edit"></i></button></td>
      <td *ngIf="item.editing" class="editable align-right" style="width: 10rem">
         <button type="button" class="btn btn-sm btn-primary mr-1"
           [disabled]="!validateName()" (click)="updateItem()">{{'ui.save'|translate}}</button>
         <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
      </td>
   </tr>
   </tbody>
</table>
<jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
