export enum ContextType {
	primary,
	secondary,
	success,
	danger,
	warning,
	info,
	light,
	dark
}
export class Context {
	public getContextClassname(type: ContextType): string {
		switch(type) {
			case ContextType.primary: return 'primary';
			case ContextType.secondary: return 'secondary';
			case ContextType.success: return 'success';
			case ContextType.danger: return 'danger';
			case ContextType.warning: return 'warning';
			case ContextType.light: return 'light';
			case ContextType.info: return 'info';
			case ContextType.dark: return 'dark';
		}
		return 'primary';
	}
}