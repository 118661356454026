<form [formGroup]="form" (ngSubmit)="save()" class="revision">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'entity.quotation'|translate}} {{'quotation.revision'|translate|lowercase}} {{'entity.create'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      </div>
   </div>
   <div class="jbm-modal-body">
         <jbm-static-label labelClass="xl" [labelCaption]="'quotation.number'|translate" [value]="quotation.quotation_number"></jbm-static-label>
         <jbm-static-label labelClass="xl" [labelCaption]="'quotation.revision-number'|translate" [value]="(quotation.revision_number+1).toString()"></jbm-static-label>
         <ngb-alert *ngIf="warning" type="warning" [dismissible]="false">{{warning}}</ngb-alert>
         <jbm-date-input labelClass="xl" labelCaption="{{'quotation.date'|translate}}"
           [formControl]="formControls.quotation_date" placement="bottom" [required]="true" [submitted]="submitted">
            <jbm-input-error [formControl]="formControls.quotation_date" [submitted]="submitted">
               <span *ngIf="formControls.quotation_date.hasError('dateInvalid')">
                 {{'entity.dateformat'|translate}}
               </span>
            </jbm-input-error>
         </jbm-date-input>
         <jbm-integer-input-group [formControl]="formControls.validity_period" [required]="true" [append]="'datetime.days'|translate" [submitted]="submitted"
           [labelCaption]="'entity.validity-period'|translate" labelClass="xl" class="xxxs" [id]="'validity_period'" [maxlength]="3"
           (onBlur)="calcEndDate($event)">
            <jbm-input-error [formControl]="formControls.validity_period" [submitted]="submitted">
               <span *ngIf="formControls.validity_period.hasError('required')">{{'entity.requiredfield'|translate}}</span>
               <span *ngIf="formControls.validity_period.hasError('min')">{{'quotation.validity-period-range'|translate}}</span>
               <span *ngIf="formControls.validity_period.hasError('max')">{{'quotation.validity-period-range'|translate}}</span>
               <span *ngIf="formControls.validity_period.hasError('pattern')">{{'quotation.validity-period-range'|translate}}</span>
            </jbm-input-error>
         </jbm-integer-input-group>
         <jbm-date-input labelClass="xl" labelCaption="{{'entity.due-date'|translate}}"
           [formControl]="formControls.due_date" placement="top" [required]="true" [submitted]="submitted"
           (onBlur)="calcValidityPeriod()">
            <jbm-input-error [formControl]="formControls.due_date" [submitted]="submitted">
               <span *ngIf="formControls.due_date.hasError('dateInvalid')">
                 {{'entity.dateformat'|translate}}
               </span>
            </jbm-input-error>
         </jbm-date-input>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-primary" (click)="save();">
            {{'entity.quotation'|translate}} {{'quotation.revision'|translate|lowercase}} {{'entity.create'|translate|lowercase}}
         </button>
         <div class="toolbar-spacer-1"></div>
         <button type="button" class="btn btn-secondary" (click)="close();">
            {{'ui.cancel'|translate}}
         </button>
      </div>
   </div>
</form>
