import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ArticlePricelistsService, PricelistsType} from "../../data/article-pricelists.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {TokenService} from "../../../../services/auth/token.service";
import {RefererService} from '../../../../services/storage/referer.service';
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {HttpParams} from "@angular/common/http";
import {ConcretePricelist} from "../../data/interfaces/concrete-pricelist";
import {ArticlePricelist} from "../../data/interfaces/article-pricelist";
import {GroupRights} from '../../../users/data/interfaces/grouprights';

@Component({
  selector: 'pricelists',
  templateUrl: './pricelists.component.html',
  styleUrls: ['./pricelists.component.scss']
})
export class PricelistsComponent implements OnInit {
  asModule: boolean=true;
  view: number=1; // Active pricelists
  caption: string='';
  initialCount: number=null;
  userRights: GroupRights;
  items: any;
  pricelist: ArticlePricelist;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  language: string=this.TokenService.getLanguage();
  id: number;
  loading: boolean=false;
  tableClassname: string='concrete-pricelists';

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @Input() customer_id: number=0;
  @Input() pricelistsType: PricelistsType=PricelistsType.articles;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('usesTemplate') usesTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('pricelistModal') pricelistModal: ElementRef;
  @ViewChild('connectCustomersModal') connectCustomersModal: ElementRef;
  @ViewChild('assignProjectsModal') assignProjectsModal: ElementRef;
  @ViewChild('copyModal') copyModal: ElementRef;
  @ViewChild('usesModal') usesModal: ElementRef;

  constructor(
      private ArticlePricelistsService: ArticlePricelistsService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private RefererService: RefererService,
      private Router: Router,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    if(this.customer_id==undefined) this.customer_id=0;
    if(this.customer_id>0) this.asModule=false;
    if(this.asModule) {
      this.tableClassname+=' padding';

      // Delete optional existing reference to customer page
      this.RefererService.deleteReferer('customers','concrete-pricelists');

      // Determinate pricelists type by URL
      let url=this.Router.url;
      if(url.indexOf('concrete-pricelist')>-1)
        this.pricelistsType=PricelistsType.concrete;
    }
    this.setCaption();

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true},
      { name: 'valid_from', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('pricelist.valid-from'), sortable: true, filterable: true },
      { name: 'valid_to', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('pricelist.valid-to'), sortable: true, filterable: true },
      { name: 'type', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('pricelist.type'), filterable: true, width: 12,
        align: JBMTableColumnAlignment.left,
        filterValues: this.ArticlePricelistsService.getTypeSelectOptions(this.TranslateService) as JBMSelectOption[],
        displayValues: this.ArticlePricelistsService.getTypeDisplayValues(this.TranslateService)
      },
      { name: 'uses', type: JBMTableColumnType.boolean, width: 8 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 10 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    if(this.pricelistsType===PricelistsType.articles) {
      this.queryHttpHelper.addAliasedColumn('valid_from','apl.valid_from');
      this.queryHttpHelper.addAliasedColumn('valid_to','apl.valid_to');
    } else {
      this.queryHttpHelper.addAliasedColumn('valid_from','cpl.valid_from');
      this.queryHttpHelper.addAliasedColumn('valid_to','cpl.valid_to');
    }
    this.queryHttpHelper.setSortColumn('valid_to',true);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    if(!this.asModule) this.queryHttpHelper.paginationProps.pageSize=5;

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  setCaption() {
    if(this.pricelistsType===PricelistsType.articles)
      this.caption=this.TranslateService.GetTranslation('financial.article-pricelists');
    else
      this.caption=this.TranslateService.GetTranslation('financial.concrete-pricelists');
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    params=this.setFilters(params);
    this.items$=this.ArticlePricelistsService.get(this.pricelistsType, this.language, params).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{

          if(this.items.length)
            this.setDeletePermissions();

          // Set uses column template
          this.columnDefs[5].template=this.usesTemplate;

          // Set actions column template
          this.columnDefs[6].template=this.actionsTemplate;

          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            params=this.setFilters(params);
            this.count$=this.ArticlePricelistsService.get(this.pricelistsType, this.language, params).subscribe(
                (data: any) => {
                  this.queryHttpHelper.paginationProps.itemCount=data.data;
                  if(this.initialCount===null) this.initialCount=data.data;
                },(error)=>console.error(error),()=>{} );
          }
        } );
  }
  viewChange(event) {
    this.view=parseInt(event.target.value);
    this.queryHttpHelper.paginationProps.page=1;
    this.getData(true);
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  setFilters(params: HttpParams): HttpParams {
    if(this.customer_id)
      // Filter customer
      params=params.append('customer_id',this.customer_id.toString());
    if(this.view===1)
        // Filter active pricelists
      params=params.append('actual_only','1');
    if(this.view===2)
        // Filter non-actual pricelists
      params=params.append('not_actual_only','1');
    return params;
  }
  setDeletePermissions() {
    let ids=[];
    for(let i=0; i<this.items.length; i++ ) {
      this.items[i].deletePermission=false;
      ids.push(this.items[i].id);
    }
    let deletePermissions=[];
    this.ArticlePricelistsService.getDeletePermissions(this.pricelistsType, ids).subscribe(
        (data)=>deletePermissions=data.data,
        (error)=>console.error(error),
        ()=>{
          for(let i=0; i<this.items.length; i++ )
            this.items[i].deletePermission=(deletePermissions.indexOf(this.items[i].id)>-1);
        }
    )
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  rowSelect(row) {
    // Open pricelist;
    if(this.pricelistsType===PricelistsType.articles)
      this.Router.navigateByUrl('settings/financial/article-prices/'+row.id.toString());
    else
      this.Router.navigateByUrl('settings/financial/concrete-prices/'+row.id);
  }

  // Uses
  showUses(id: number) {
    this.ArticlePricelistsService.getOne(this.pricelistsType, id, this.language).subscribe(
        (data)=>this.pricelist=data.data as ConcretePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.usesModal,this.pricelistsType===0 ? 'uses-article-pricelist' : 'uses-concrete-pricelist')
    )
  }
  closeUses() {
    this.ModalService.close(this.pricelistsType===0 ? 'uses-article-pricelist' : 'uses-concrete-pricelist');
  }

  addItem() {
    this.pricelist=this.ArticlePricelistsService.getEmpty();
    this.ModalService.open(this.pricelistModal,this.pricelistsType==0 ? 'article-pricelist' :'concrete-pricelist');
  }
  edit(id: number) {
    this.ArticlePricelistsService.getOne(this.pricelistsType, id, this.language).subscribe(
        (data)=>this.pricelist=data.data as ArticlePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.pricelistModal,this.pricelistsType===0 ? 'article-pricelist' :'concrete-pricelist')
    )
  }
  pricelistSaved() {
    this.ModalService.close(this.pricelistsType===0 ? 'article-pricelist' :'concrete-pricelist');
    this.initialCount=null; // Needed for first added pricelist; shows table controls...
    this.getData(true);
  }
  pricelistCancelled() {
    this.ModalService.close(this.pricelistsType===0 ? 'article-pricelist' :'concrete-pricelist');
  }
  copy(id: number) {
    this.ArticlePricelistsService.getOne(this.pricelistsType, id, this.language).subscribe(
        (data)=>this.pricelist=data.data as ArticlePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.copyModal,this.pricelistsType===0 ? 'copy-article-pricelist' : 'copy-concrete-pricelist')
    )
  }
  copySaved() {
    this.ModalService.close(this.pricelistsType===0 ? 'copy-article-pricelist' : 'copy-concrete-pricelist');
    this.initialCount=null; // Needed for first added pricelist; shows table controls...
    this.getData(true);
  }
  copyCancelled() {
    this.ModalService.close(this.pricelistsType===0 ? 'copy-article-pricelist' : 'copy-concrete-pricelist');
  }

  // Connect to customers
  connectToCustomers(id: number) {
    this.ArticlePricelistsService.getOne(this.pricelistsType, id, this.language).subscribe(
        (data)=>this.pricelist=data.data as ArticlePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.connectCustomersModal,this.pricelistsType===0 ? 'connect-article-pricelist' : 'connect-concrete-pricelist')
    )
  }
  connected() {
    this.ModalService.close(this.pricelistsType===0 ? 'connect-article-pricelist' : 'connect-concrete-pricelist');
    this.getData(true);
  }
  cancelConnect() {
    this.ModalService.close(this.pricelistsType===0 ? 'connect-article-pricelist' : 'connect-concrete-pricelist');
  }

  // Assign to projects
  assignToProjects(id: number) {
    this.ArticlePricelistsService.getOne(this.pricelistsType, id, this.language).subscribe(
        (data)=>this.pricelist=data.data as ArticlePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.assignProjectsModal,this.pricelistsType===0 ? 'assign-article-pricelist' : 'assign-concrete-pricelist')
    )
  }
  projectsAssigned() {
    this.ModalService.close(this.pricelistsType===0 ? 'assign-article-pricelist' : 'assign-concrete-pricelist');
    this.getData(true);
  }
  projectsAssignCancel() {
    this.ModalService.close(this.pricelistsType===0 ? 'assign-article-pricelist' : 'assign-concrete-pricelist');
  }

  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Refresh view
    this.getData(true);
  }
  delete(id) {
    this.ArticlePricelistsService.delete(this.pricelistsType, id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData(true);
        }
    )
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
