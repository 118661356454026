import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {InvoicesService} from "../data/invoices.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {PdfService} from "../../../services/pdf-make/pdf-service";
import {Invoice} from "../data/interfaces/invoice";

@Component({
  selector: 'invoice-preview',
  templateUrl: './invoice-preview.component.html',
  styleUrls: ['./invoice-preview.component.scss'],
  providers: [PdfService]
})
export class InvoicePreviewComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  pdfMakeTemplate: string;
  invoice: Invoice;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private InvoicesService: InvoicesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private PdfService: PdfService,
      private Title: Title
  ) {
    // The route resolver has fetched the data
    this.invoice=this.ActivatedRoute.snapshot.data.invoice.data;

    this.Title.setTitle('PDF '+this.TranslateService.GetTranslation('ui.preview').toLowerCase());
  }

  ngOnInit(): void {
    this.InvoicesService.compose_pdf(this.language, this.invoice.id).subscribe(
     (data)=>this.pdfMakeTemplate=data.data,
        (error)=>console.error(error),
        ()=>
            this.PdfService.loadPdfMaker().then( ()=>
                  this.PdfService.pdfMake.createPdf(this.pdfMakeTemplate).getDataUrl(function(outDoc) {
                    (document.getElementById('pdfObject') as HTMLObjectElement).data=outDoc;
                  })
            )
    )
  }
}
