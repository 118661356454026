import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../shared.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import { RecipeDetailsComponent} from "./details/recipe-details.component";

@NgModule({
  declarations: [RecipeDetailsComponent],
  imports: [CommonModule, SharedModule, JBMButtonsModule, JBMViewModule],
  exports: [RecipeDetailsComponent]
})
export class SharedRecipesModule { }
