import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayouts } from "../../app.component";
import { AuthGuardService} from "../../services/auth/authguard.service";
import { TranslationsComponent} from "./translations.component";
import {ChangesGuard} from "../../guards/changes-guard";

const routes: Routes = [
  {
    path: 'settings/translations',
    component: TranslationsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [ChangesGuard],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TranslationsRoutingModule { }
