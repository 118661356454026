import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SanitizationService {

  constructor() { }

  // Return null value if given number has zero value
  nullIfZero( number: number ) {
    if(number===0) return null;
    return number;
  }

  // Return zero value if given number is null
  zeroIfNull( number: number ) {
    if(number===0) return null;
    return number;
  }

  // Return null value if given string is empty
  nullIfEmpty( value: string ) {
    if(value.trim()==='') return null;
    return value;
  }

  // Return empty string if given value string is null
  emptyIfNull( value: string ) {
    if(value===null) return '';
    return value;
  }

  floatRound2Decimals(value) {
    var m = Number((Math.abs(value) * 100).toPrecision(15));
    return Math.round(m) / 100 * Math.sign(value);
  }

  // Amounts
  checkAmountIntegerStr( amount: string, valueIfNaN: number=1 ): number {
    let check=parseInt(amount);
    if(isNaN(check)) return valueIfNaN;
    return check;
  }
  checkAmountFloatStr( amount: string, valueIfNaN: number=1 ): number {
    let check=parseFloat(amount.replace(',','.'));
    if(isNaN(check)) return valueIfNaN;
    return check;
  }
  amountFloatToStr(amount: number, decimals: number=2): string {
    let result=amount.toFixed(decimals).replace('.',',');
    if(result.endsWith(',0')) result=result.slice(0, -2);
    if(result.endsWith(',00')) result=result.slice(0, -3);
    return result;
  }

  // Prices
  checkPriceStr( price: string ): number {
    return parseFloat(price.replace(',','.'));
  }

  // Filesize
  formatFilesize( filesize: number ): string {
    if(filesize<5000) return filesize+' Bytes';
    if(filesize<5000000) return (filesize/1000).toFixed(1).replace('.',',')+' Kb';
    return (filesize/1000000).toFixed(1).replace('.',',')+' Mb';
  }

  trimTrailingZeros( value: string, separator: string, zeros: string): string {
    let parts=value.split(separator);
    let result='';
    for(let p=0; p<parts.length-1; p++)
      if(parts[p]!==zeros)
        result+=parts[p]+separator;
    result=result.slice(0, -1);
    return result;
  }
}
