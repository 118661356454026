import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ArticlePricelistsService, PricelistsType} from "../../data/article-pricelists.service";
import {CustomerPricelistsService} from "../../data/customer-pricelists.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {ArticlePricelist} from "../../data/interfaces/article-pricelist";
import {CustomersService} from "../../data/customers.service";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'pricelist-connect-customers',
  templateUrl: './pricelist_connect_customers.component.html',
  styleUrls: ['./pricelist_connect_customers.component.scss']
})
export class PricelistConnectCustomersComponent implements OnInit {
  items: any[]=[];
  columnDefs: JBMTableColumnDef[];
  loading: boolean=false;
  language: string=this.TokenService.getLanguage();
  customer_ids: number[]=[];
  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';

  types=this.ArticlePricelistsService.getTypeItems(this.TranslateService);
  caption: string;

  constructor(
      private ArticlePricelistsService: ArticlePricelistsService,
      private CustomerArticlePricelistsService: CustomerPricelistsService,
      private CustomersService: CustomersService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  )
  { this.pricelist=this.ArticlePricelistsService.getEmpty() }

  @Input() pricelistsType: PricelistsType=PricelistsType.articles;
  @Input() pricelist: ArticlePricelist;

  @Output() onConnected=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  @ViewChild('toggleTemplate') toggleTemplate: TemplateRef<any>;

  ngOnInit(): void {
    if(this.pricelistsType===PricelistsType.articles)
      this.caption='financial.article-pricelist';
    else
      this.caption='financial.concrete-pricelist';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'toggle', type: JBMTableColumnType.boolean, iconClassname: 'check', sortable: false, filterable: false, width: 1 },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'), sortable: true, filterable: true, width: 15 },
      { name: 'blocked', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left,
        header: this.TranslateService.GetTranslation('entity.blocked'),
        displayValues: [
          { dataValue: false, displayValue: this.TranslateService.GetTranslation('ui.no') },
          { dataValue: true, displayValue: this.TranslateService.GetTranslation('ui.yes'), className: 'text-danger' },
        ], width: 5 },
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=5;

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let category=this.pricelistsType===0 ? 1 : 0;
    let httpParams=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.CustomersService.getSelectList(this.language, category, this.pricelist.id, httpParams).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          this.loading=false;

          // Set toggle template
          this.columnDefs[1].template = this.toggleTemplate;

          if(countRows) {
            let httpParams=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.CustomersService.getSelectList(this.language, category, this.pricelist.id, httpParams).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    this.toggleCustomer(row.id);
  }
  toggleCustomer(id:number) {
    let index=this.customer_ids.indexOf(id);
    if(index===-1)
      this.customer_ids.push(id)
    else
      this.customer_ids.splice(index,1);
  }
  connect() {
    this.CustomerArticlePricelistsService.createMulti(this.pricelistsType,
        { pricelist_id: this.pricelist.id, customer_ids: this.customer_ids }).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entities-saved'));
          this.onConnected.emit();
        }
    )
  }
  cancel() {
    this.onCancel.emit();
  }
}
