import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GrouprightsService} from "../../data/grouprights.service";
import {UsergroupService} from "../../data/usergroup.service";
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from '../../data/interfaces/grouprights';

@Component({
  selector: 'grouprights',
  templateUrl: './grouprights.component.html',
  styleUrls: ['./grouprights.component.scss']
})
export class GroupRightsComponent implements OnInit, OnDestroy {

  @Input() appID: number=0; // Management
  @Input() usergroupID: number=0;

  userRights: GroupRights;
  groupRights: any[];
  grouprights$: Subscription;

  constructor(
      private UsergroupService: UsergroupService,
      private GrouprightsService: GrouprightsService,
      private TokenService: TokenService,
      public TranslateService: TranslateService,
      public JBMToastsService: JBMToastsService
  ) {}
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('userrights');
    this.getData();
  }
  getData() {
    this.grouprights$=this.GrouprightsService.getData(this.appID, this.usergroupID).subscribe(
        (data: any) => this.groupRights = data.data,()=>{},()=>{
          this.formatData();
          this.groupRights.sort(function(a, b) {
            if(a.description<b.description)
              return -1
            else
              return 1
          })
        } );
  }
  formatData() {
    for(let g=0; g<this.groupRights.length; g++) {
      this.groupRights[g].description=this.TranslateService.GetTranslation(this.groupRights[g].description);
    }
  }
  changeRight(event) {
    let temp=event.target.id;
    let parts=temp.split('_');
    let right=parts[0];
    let id=parseInt(parts[1]);

    for(let g=0; g<this.groupRights.length; g++) {
      if(this.groupRights[g].id===id) {
        switch(right) {
          case 'create':
            this.groupRights[g].allow_create=!this.groupRights[g].allow_create;
            break;
          case 'read':
            this.groupRights[g].allow_read=!this.groupRights[g].allow_read;
            break;
          case 'update':
            this.groupRights[g].allow_update=!this.groupRights[g].allow_update;
            break;
          case 'delete':
            this.groupRights[g].allow_delete=!this.groupRights[g].allow_delete;
        }
        this.update({
            id: this.groupRights[g].id,
            group_id: this.groupRights[g].group_id,
            right_id: this.groupRights[g].right_id,
            allow_create: this.groupRights[g].allow_create,
            allow_read: this.groupRights[g].allow_read,
            allow_update: this.groupRights[g].allow_update,
            allow_delete: this.groupRights[g].allow_delete
        });
      }
    }
  }
  update(data) {
    this.GrouprightsService.update(data).subscribe(
        data => data,
        error => { console.error(error) },
        () => {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
        });
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.grouprights$!=undefined) this.grouprights$.unsubscribe();
  }
}
