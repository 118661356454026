<table [hidden]="isEmpty" class="table table-xs invoice-validations">
   <thead>
      <tr>
         <th class="timestamp">{{'entity.timestamp'|translate}}</th>
         <th class="user">{{'entity.user'|translate}}</th>
         <th></th>
         <th>{{'entity.comments'|translate}}</th>
      </tr>
   </thead>
   <tbody>
      <ng-container *ngFor="let validation of validations">
      <tr [hidden]="excludeCurrentUser && validation.users_id==user_id">
         <td>{{validation.date}} <i class="far fa-clock"></i> {{validation.time}}</td>
         <td>{{validation.user.full_name}}</td>
         <td>
            <span *ngIf="validation.approved" class="badge bg-success">{{'ui.approved'|translate}}</span>
            <span *ngIf="validation.approved!=null && !validation.approved" class="badge bg-danger">{{'ui.rejected'|translate}}</span>
         </td>
         <td>
            <jbm-comments-box [comments]="validation.comments" [height]="2.75"></jbm-comments-box>
         </td>
      </tr>
      </ng-container>
   </tbody>
</table>
