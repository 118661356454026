<h6><i class="fa fa-history"></i> {{'financial.price'|translate}}{{'entity.history'|translate|lowercase}}</h6>
{{'pricelist.actual-price'|translate}}: <span class="fw-bolder">{{data.actualPrice|money}}</span>
<div class="article-price-history-container">
   <table class="table table-xs article-price-history mt-2">
      <tr>
         <th>{{'financial.pricelist'|translate}}</th>
         <th class="pricelist-type">{{'pricelist.type'|translate}}</th>
         <th class="price align-right">{{'financial.price'|translate}} {{'ui.old'|translate|lowercase}}</th>
         <th class="price align-right">{{'ui.change'|translate}}</th>
         <th class="price align-right">{{'financial.price'|translate}} {{'ui.new'|translate|lowercase}}</th>
      </tr>
      <tr *ngFor="let row of data.history" [ngClass]="{'table-warning':row.id==price_id}">
         <td>{{row.pricelist}}</td>
         <td *ngIf="row.type==0">{{'pricelist.type-base'|translate}}</td>
         <td *ngIf="row.type==1">{{'pricelist.type-discount-addition'|translate}}</td>
         <td *ngIf="row.type==2">{{'pricelist.type-overrule'|translate}}</td>
         <td class="align-right">{{row.oldPrice|money}}</td>
         <td class="align-right">{{row.price|money}}</td>
         <td class="align-right">{{row.newPrice|money}}</td>
      </tr>
   </table>
</div>