import {Component, EventEmitter, Input, Output} from '@angular/core';
import {QuotationsService} from "../../data/quotations.service";

@Component({
  selector: 'quotation-remove',
  templateUrl: './quotation-remove.component.html',
  styleUrls: ['./quotation-remove.component.scss']
})
export class QuotationRemoveComponent {
  removeProject: boolean=true;

  constructor(private QuotationsService: QuotationsService) {}

  @Input() quotation_id: number;
  @Input() quotationNumber: string;

  @Output() onClose=new EventEmitter();
  @Output() onRemove=new EventEmitter();

  remove() {
    this.QuotationsService.remove(this.quotation_id).subscribe(
        ()=>{},(error)=>console.error(error),()=>this.onRemove.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
