import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Event, NavigationEnd, Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {PlantsService} from "../../company/data/plants.service";
import {ProjectsService} from "../data/projects.service";
import {ProjectstatesService} from "../data/projectstates.service";
import {CustomerService} from "../../customers/data/customer.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {RefererService} from "../../../services/storage/referer.service";
import {StateService} from "../../../services/storage/state.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {TablelayoutService} from "../../users/data/tablelayout.service";
import {Subscription} from "rxjs";
import {ProjectState} from "../data/interfaces/projectstate";
import {ContextType} from "../../../components/JBM/Helpers/Context";
import {JBMSelectOption} from '../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';

const tableName='projects';

@Component({
  selector: 'projects-table',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, AfterViewInit, OnDestroy {
  asModule: boolean=true;
  plantOptions: JBMSelectOption[]=[];
  plant_id: number=0;
  projects: any[]=[];
  initialCount: number=null;
  userRights: any;
  projectstates: any[]=[];
  projectstate: ProjectState={ id: 0, description: '', color: '', text_color: '' };
  projectstateID: number=0;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[]=[];
  language: string=this.TokenService.getLanguage();
  id: number;
  customer: any;

  projects$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  filtersVisible: boolean=false;
  loading: boolean=false;

  @Input() customer_id: number=0;
  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('templateProjectstate') templateProjectstate: TemplateRef<any>;
  @ViewChild('templateCustomer') templateCustomer: TemplateRef<any>;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;
  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;

  constructor(
      private PlantsService: PlantsService,
      public  ProjectsService: ProjectsService,
      private ProjectstatesService: ProjectstatesService,
      private CustomerService: CustomerService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private RefererService: RefererService,
      private StateService: StateService,
      private Router: Router,
      private TranslateService: TranslateService,
      private TablelayoutService: TablelayoutService,
  ) {}

  ngOnInit(): void {
    // Refresh data after modal outlet closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && (event.url==='/projects' || event.url.indexOf('customers/details')>-1))
        this.getData();
    });

    this.userRights = this.TokenService.getRightsByName('projects');

    if(this.customer_id==undefined) this.customer_id=0;
    if(this.customer_id>0) this.asModule=false;

    if(this.asModule)
      // Remove all active project-related referers
      this.RefererService.clearSection('customers');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('name','p.name');
    this.queryHttpHelper.addAliasedColumn('description','p.description');
    this.queryHttpHelper.addAliasedColumn('projectstate','d.description');
    if(this.customer_id==0)
      this.queryHttpHelper.addAliasedColumn('customername','c.name');
    this.queryHttpHelper.addAliasedColumn('plant','pl.code');
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    if(this.customer_id==0)
      this.queryHttpHelper.addSearchColumn('customername', SearchColumnType.string);

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id, tag: 'id' },
      { name: 'name', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('entity.name'), visible: true,
        hideable: false, sortable: true, filterable: true, width: 15 },
      { name: 'projectstate', header: this.TranslateService.GetTranslation('project.state'), visible: true, width: 6,
        hideable: false, sortable: true, filterable: false, className: 'opacity-7' },
      { name: 'description', type: JBMTableColumnType.notes, visible: true, header: this.TranslateService.GetTranslation('entity.description'),
        hideable: true, sortable: false, filterable: true, width: 5, align: JBMTableColumnAlignment.center },
      { name: 'customername', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('customer'), visible: this.customer_id==0,
        hideable: this.customer_id==0, sortable: true, filterable: true, width: 15 },
      { name: 'plant', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('company.plant'), visible: true, hideable: true, filterable: true, width: 7.5 },
      { name: 'address_details_id', type: JBMTableColumnType.address, header: this.TranslateService.GetTranslation('project.work-location'), width: 4, visible: true,
        hideable: true, align: JBMTableColumnAlignment.center },
      { name: 'distance', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('geo.distance'), visible: true, hideable: true, sortable: true, width: 4.5,
        align: JBMTableColumnAlignment.right },
      { name: 'traveltime', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('geo.traveltime'), visible: true, hideable: true, sortable: true,
        width: 4.5, align: JBMTableColumnAlignment.right },
      { name: 'comments', type: JBMTableColumnType.notes, header: this.TranslateService.GetTranslation('entity.comments'), visible: true, hideable: true, filterable: true, width: 4,
        align: JBMTableColumnAlignment.center},
    ];

    if(this.userRights.AllowUpdate)
      this.actionDefs.push( { name: 'edit', iconClass: 'fa fa-edit' } );
    if(this.userRights.AllowRead)
      this.actionDefs.push( { name: 'details', iconClass: 'fa fa-info', context: ContextType.light } );

    if(this.asModule)
      this.TablelayoutService.setLayout(tableName, this.columnDefs);

    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }

          this.ProjectstatesService.get(this.language).subscribe(
              (data: any)=> this.projectstates=data.data, ()=>{}, ()=> {
                this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
                this.getData(true);
              }
          )
        }
    );
  }
  ngAfterViewInit() {
    this.columnDefs[2].template=this.templateProjectstate;
    this.columnDefs[4].template=this.templateCustomer;
  }
  getData(countRows:boolean=false) {
    this.loading=true;

    let params=this.queryHttpHelper.getHttpParams(false);
    // Include projectstate description, plant and customer
    params=params.append('state','1');
    params=params.append('plant','1');
    if(this.customer_id==0) params=params.append('customer','1');
    params=this.setFilters(params);

    this.projects$=this.ProjectsService.get(this.language, params).subscribe(
        (data: any) => this.projects=data.data,()=>{},()=>{
          this.loading=false;
          this.formatData();
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            params=this.setFilters(params);
            this.count$=this.ProjectsService.get(this.language, params).subscribe(
                (data: any) => {
                  this.queryHttpHelper.paginationProps.itemCount=data.data;
                  if(this.initialCount===null) this.initialCount=data.data
                },
                (error)=> console.error(error),()=>{} );
          }
        } );
  }
  setFilters(params: HttpParams): HttpParams {
    if(this.plant_id>0)
      // Filter plant
      params=params.append('plant_id',this.plant_id.toString());
    if(this.customer_id>0)
      // Filter customer
      params=params.append('customer_id',this.customer_id.toString());
    if(this.projectstateID>0) {
      this.projectstate=this.ProjectstatesService.getByID(this.projectstates, this.projectstateID);
      // Filter projectstate
      params=params.append('projectstates_id', this.projectstateID.toString());
    } else
      // Show active projects only
      params=params.append('active_only','1');

    return params;
  }
  formatData() {
    let km =this.TranslateService.GetTranslation('geo.km-abbr');
    let min=this.TranslateService.GetTranslation('datetime.minutes-abbr');

    for(let project of this.projects) {

      // Set projectstate context colors
      project.color='';
      project.text_color='';
      let projectState=this.ProjectstatesService.getByID(this.projectstates, project.projectstates_id);
      if(projectState) {
        project.color=projectState.color;
        project.text_color=projectState.text_color;
      }

      if(project.distance!==null)
        project.distance+=' '+km;
      if(project.traveltime!==null)
        project.traveltime+=' '+min;
    }
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  setProjectstate(id: number) {
    this.projectstateID=id;
    this.columnDefs[2].visible=(this.projectstateID===0);
    this.getData(true);
  }
  changePlant(event) {
    this.plant_id=parseInt(event.target.value);

    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
      this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');

    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  saveLayout() {
    this.TablelayoutService.saveLayout(tableName, this.columnDefs);
  }
  // Item actions
  addItem() {
    if(this.customer_id)
      this.StateService.addElement('customerID',this.customer_id);
    this.Router.navigate([ { outlets: {'modal': ['project', 'create']}} ] );
  }
  rowSelect(row) {
    this.Router.navigateByUrl('projects/'+row.id);
  }
  // Customer
  showCustomer(customer_id: number) {
    this.CustomerService.get(customer_id,true).subscribe(
        (data)=>this.customer=data.data,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }
  actionClick(action) {
    switch(action.name) {
      case 'edit':
        this.Router.navigate([ { outlets: {'modal': ['project', 'edit', action.row.id]}} ] );
        break;
      case 'details':
        this.id=action.row.id;
        this.ModalService.open(this.modalProjectDetails,'projectDetails');
        break;
    }
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.projects$!=undefined) this.projects$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
