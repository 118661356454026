import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {PlantsService} from "../../data/plants.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableActionDef, JBMTableColumnAlignment, JBMTableColumnDef, JBMTableColumnType} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {TablelayoutService} from "../../../users/data/tablelayout.service";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'plants-table',
  templateUrl: './plants.component.html',
  styleUrls: ['./plants.component.scss']
})
export class PlantsComponent implements OnInit, OnDestroy {
  userRights: any;
  items: any[];
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];

  items$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  loading: boolean=false;

  @ViewChild('content') modalTemplate: ElementRef;

  constructor(
      private Router: Router,
      public PlantsService: PlantsService,
      public modalService: NgbModal,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('company');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'code', header: this.TranslateService.GetTranslation('entity.code'),
        sortable: true, filterable: true, width: 10 },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'),
        sortable: true, filterable: true },
    ];
    this.actionDefs = [ { name: 'edit', iconClass: 'far fa-edit' } ];

    this.getData();
  }
  getData() {
    this.loading=true;
    let httpParams=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.PlantsService.getData(httpParams).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{
          this.loading=false;
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  // Item actions
  rowSelect(row) {
    this.Router.navigateByUrl('settings/company/plants/'+row.id.toString());
  }
  action(event) {
    if(event.name==='edit') {
      this.rowSelect(event.row);
    }
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
 }
}
