<app-header>
   <jbm-view-title [caption]="('entity.presentation'|translate) + ' ' + ('presentations.template'|translate|lowercase)"
     [parentLinkCaption]="('entity.presentation'|translate) + ' ' + ('presentations.templates'|translate|lowercase)"
     [parentLinkSlug]="'settings/presentations'">
   </jbm-view-title>
   <div class="toolbar float-end">
      <jbm-toggle-button caption="{{'ui.preview'|translate}}" [toggled]="showPreview" (onToggle)="togglePreview()"></jbm-toggle-button>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-save-button [small]="false" [subject]="'presentations.template'|translate" class="mr-2" (click)="save()"></jbm-save-button>
      <jbm-cancel-button [small]="false" context="light" (click)="cancel()"></jbm-cancel-button>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-activity-log-button [subject]="'menu.presentations'|translate"
        [title]="'presentations.template'|translate" [subject_type]="28" [subject_id]="presentation.id">
      </jbm-activity-log-button>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module overflow-y template-editor">
   <as-split direction="horizontal" (dragEnd)="splitterDragEnd('percent', $event)">
      <as-split-area [size]="panel_primary_width" [minSize]="30">
         <div class="code-editor">
            <div class="editor-header bg-light">
               <h5>{{presentation.description}}</h5>
            </div>
            <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="panel-1" (panelChange)="editorSectionChange($event)">
               <ngb-panel [id]="'panel-1'">
                  <ng-template ngbPanelTitle>
                     <div class="float-start pt-1">{{'template-editor.header-section'|translate}}</div>
                     <button *ngIf="activeEditorSection==1 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                       (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                     <ngb-alert type="danger" *ngIf="editorError!=''" [dismissible]="false">{{editorError}}</ngb-alert>
                     <ngx-codemirror #editorHeader [name]="'header'" [id]="'header'" [(ngModel)]="headerStr"
                       [options]="editorOptions" (ngModelChange)="onEditorChange($event,'header')"></ngx-codemirror>
                  </ng-template>
               </ngb-panel>
               <ngb-panel [id]="'panel-2'">
                  <ng-template ngbPanelTitle>
                     <div class="float-start pt-1">{{'template-editor.body-section'|translate}}</div>
                     <button *ngIf="activeEditorSection==2 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                       (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                     <ngb-alert type="danger" *ngIf="editorError!=''" [dismissible]="false">{{editorError}}</ngb-alert>
                     <ngx-codemirror #editorBody [name]="'body'" [id]="'body'" [(ngModel)]="bodyStr"
                       [options]="editorOptions" (ngModelChange)="onEditorChange($event,'body')"></ngx-codemirror>
                  </ng-template>
               </ngb-panel>
               <ngb-panel [id]="'panel-3'">
                  <ng-template ngbPanelTitle>
                     <div class="float-start pt-1">{{'template-editor.group-section'|translate}}</div>
                     <button *ngIf="activeEditorSection==3 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                       (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                     <ngb-alert type="danger" *ngIf="editorError!=''" [dismissible]="false">{{editorError}}</ngb-alert>
                     <ngx-codemirror #editorGroup [name]="'group'" [id]="'group'" [(ngModel)]="groupStr"
                       [options]="editorOptions" (ngModelChange)="onEditorChange($event,'group')"></ngx-codemirror>
                  </ng-template>
               </ngb-panel>
               <ngb-panel [id]="'panel-4'">
                  <ng-template ngbPanelTitle>
                     <div class="float-start pt-1">{{'template-editor.row-section'|translate}}</div>
                     <button *ngIf="activeEditorSection==4 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                       (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                     <ngb-alert type="danger" *ngIf="editorError!=''" [dismissible]="false">{{editorError}}</ngb-alert>
                     <ngx-codemirror #editorRow [name]="'row'" [id]="'row'" [(ngModel)]="rowStr"
                       [options]="editorOptions" (ngModelChange)="onEditorChange($event,'row')"></ngx-codemirror>
                  </ng-template>
               </ngb-panel>
               <ngb-panel [id]="'panel-5'">
                  <ng-template ngbPanelTitle>
                     <div class="float-start pt-1">{{'template-editor.footer-section'|translate}}</div>
                     <button *ngIf="activeEditorSection==5 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                       (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                  </ng-template>
                  <ng-template ngbPanelContent>
                     <ngb-alert type="danger" *ngIf="editorError!=''" [dismissible]="false">{{editorError}}</ngb-alert>
                     <ngx-codemirror #editorFooter [name]="'footer'" [id]="'footer'" [(ngModel)]="footerStr"
                       [options]="editorOptions" (ngModelChange)="onEditorChange($event,'footer')"></ngx-codemirror>
                  </ng-template>
               </ngb-panel>
               <ngb-panel [id]="'panel-6'">
                  <ng-template ngbPanelTitle>
                     <div class="float-start pt-1">{{'template-editor.styles-section'|translate}}</div>
                  </ng-template>
                  <ng-template ngbPanelContent>
                     <ngb-alert type="danger" *ngIf="editorError!=''" [dismissible]="false">{{editorError}}</ngb-alert>
                     <ngx-codemirror #editorStyles [name]="'styles'" [id]="'styles'" [(ngModel)]="stylesStr"
                       [options]="editorOptions" (ngModelChange)="onEditorChange($event,'styles')"></ngx-codemirror>
                  </ng-template>
               </ngb-panel>
            </ngb-accordion>
         </div>
      </as-split-area>
      <as-split-area [size]="panel_secondary_width" [minSize]="40" [visible]="showPreview">
         <div class="content-secondary pdf-viewer">
            <object id="pdfObject" type="application/pdf">
               <iframe id='pdfIFrame' type="application/pdf"></iframe>
            </object>
         </div>
      </as-split-area>
   </as-split>
</section>
<template-placeholder-picker [hidden]="!showPlaceholdersPicker" [language]="language" [placeholders]="placeholders"
  (onDataPlacement)="onDataPlacement($event)" (onTextPlacement)="onTextPlacement($event)" (onImagePlacement)="onImagePlacement($event)"
  (onClose)="closePlaceholderPicker()"></template-placeholder-picker>
<jbm-toasts></jbm-toasts>
