import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {ProjectsService} from "../projects.service";
import {Project} from "../interfaces/project";
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../../services/auth/token.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ProjectResolver implements Resolve<any> {
  language: string=this.TokenService.getLanguage();

  constructor(
      private Router: Router,
      private ProjectsService: ProjectsService,
      private TokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Project> {
    let id=parseInt(route.paramMap.get('project_id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      // Get the project inclusive related data
      let language=this.TokenService.getLanguage();
      let params=new HttpParams()
        .append('state','1')
        .append('address','1')
        .append('plant', '1')
        .append('customer','1');
      return this.ProjectsService.getOne(id,language,params).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
