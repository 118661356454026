import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {WorktypesService} from "../../../../projects/data/worktypes.service";
import {UnloadingMethodsService} from "../../../../projects/data/unloading-methods.service";
import {DefaultUnloadingMethodService} from "../../../data/default-unloading-method.service";
import {RecipesService} from '../../../../recipes/data/recipes.service';
import {TokenService} from "../../../../../services/auth/token.service";
import {QuotationItemsService} from "../../../data/quotation-items.service";
import {QuotationItem} from "../../../data/interfaces/quotation-item";
import {CRUDAction} from "../../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {ValidationPatternsService} from "../../../../../services/validation/patterns.service";
import {SanitizationService} from "../../../../../services/sanitization/sanitization.service";
import {forkJoin} from "rxjs";
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'form-quotation-item',
  templateUrl: './form-quotation-item.component.html',
  styleUrls: ['./form-quotation-item.component.scss']
})
export class FormQuotationItemComponent implements OnInit {
  quotation_item: QuotationItem=this.QuotationItemsService.getEmpty();
  language: string=this.TokenService.getLanguage();
  caption: string;
  action: CRUDAction;
  form: FormGroup;
  submitted: boolean=false;

  environment_id: number=0;
  strength_id: number=0;
  consistency_id: number=0;

  recipe_id: number=null;
  recipe: any= { strengths_id: null, consistencies_id: null };
  recipeUnknown: boolean=true;

  worktypes: any[]=[];
  worktypeOptions: any[]=[];

  unloading_methods: any[]=[];
  unloading_methodOptions: any[]=[];
  unloading_method_id: number=0;

  constructor(
      private WorktypesService: WorktypesService,
      private UnloadingMethodsService: UnloadingMethodsService,
      private DefaultUnloadingMethodService: DefaultUnloadingMethodService,
      private RecipesService: RecipesService,
      private QuotationItemsService: QuotationItemsService,
      private TokenService: TokenService,
      private FormBuilder: FormBuilder,
      private SanitizationService: SanitizationService,
      private ValidationPatternsService: ValidationPatternsService
  ) {
  }

  @Input() id: number=0;
  @Input() quotation_revision_id: number=0;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  ngOnInit(): void {
    if(this.id)
      this.action=CRUDAction.Update
    else
      this.action=CRUDAction.Create;

    this.addControls();

    let wtObservable=this.WorktypesService.get(this.language);
    let ulObservable=this.UnloadingMethodsService.get(this.language);
    let dulObservable=this.DefaultUnloadingMethodService.get();
    forkJoin([wtObservable, ulObservable, dulObservable]).subscribe(results => {
      this.worktypes=results[0].data;
      this.unloading_methods=results[1].data;
      this.unloading_method_id=results[2].data;

      if(this.id)
        this.QuotationItemsService.getOne(this.id).subscribe(
            (data)=>this.quotation_item=data.data,
            (error)=>console.error(error),
            ()=> {
              this.setControlValues();
              setTimeout(()=> { document.getElementById('amount').focus() },100);
            }
        )
      else
        this.setControlValues();
    });
  }

  addControls() {
    this.form=this.FormBuilder.group({
      description: new FormControl(''),
      recipe_unknown: new FormControl(false),
      consistencies_id: new FormControl('',[Validators.required]),
      environments_id: new FormControl('',[Validators.required]),
      strengths_id: new FormControl('',[Validators.required]),
      recipes_id: new FormControl(''),
      amount: new FormControl('', [Validators.pattern(this.ValidationPatternsService.floatPattern(2))]),
      worktypes_id: new FormControl('',[Validators.required]),
      comments: new FormControl(''),
      unloading_methods_id: new FormControl('', [Validators.required])
    }, {updateOn: "blur"});
  }

  get formControls() {
    return this.form.controls;
  }

  setControlValues() {
    this.worktypeOptions=this.WorktypesService.getSelectOptions(this.worktypes, true);
    this.unloading_methodOptions=this.UnloadingMethodsService.getSelectOptions(this.unloading_methods, true);

    this.form.controls.description.setValue(this.quotation_item.description);
    this.form.controls.environments_id.setValue(this.quotation_item.environments_id===null ? '' : this.quotation_item.environments_id.toString());
    this.form.controls.consistencies_id.setValue(this.quotation_item.consistencies_id===null ? '' : this.quotation_item.consistencies_id.toString());
    this.form.controls.strengths_id.setValue(this.quotation_item.strengths_id===null ? '' : this.quotation_item.strengths_id.toString());
    this.form.controls.amount.setValue(
        this.quotation_item.amount==0 ? '' : this.quotation_item.amount.toString().replace('.', ','));
    this.form.controls.worktypes_id.setValue(this.quotation_item.worktypes_id===0 ? '' : this.quotation_item.worktypes_id.toString());
    this.form.controls.unloading_methods_id.setValue(this.quotation_item.unloading_methods_id===0 ? this.unloading_method_id.toString() : this.quotation_item.unloading_methods_id.toString());
    this.form.controls.comments.setValue(this.quotation_item.comments===null ? '' : this.quotation_item.comments);
    this.form.controls.recipe_unknown.setValue(this.quotation_item.recipes_id===null);

    this.strength_id=this.quotation_item.strengths_id;
    this.consistency_id=this.quotation_item.consistencies_id;
    this.environment_id=this.quotation_item.environments_id;

  }

  recipeUnknownChange() {
    this.recipeUnknown=!this.recipeUnknown;

    this.formControls.strengths_id.clearValidators();
    this.formControls.environments_id.clearValidators();
    this.formControls.consistencies_id.clearValidators();
    this.formControls.recipes_id.clearValidators();

    if(this.recipeUnknown) {
      this.formControls.strengths_id.addValidators([Validators.required]);
      this.formControls.environments_id.addValidators([Validators.required]);
      this.formControls.consistencies_id.addValidators([Validators.required]);
    } else
      this.formControls.recipes_id.addValidators([Validators.required]);
  }
  selectRecipeEnvironment(event) {
    this.recipe_id=event.recipe_id;
    this.environment_id=event.environment_id;
    this.formControls.recipes_id.setValue(this.recipe_id.toString());
    this.formControls.environments_id.setValue(this.environment_id.toString());
    this.setRecipe(event.recipe_id);
  }
  setRecipe(id: number) {
    let params=new HttpParams().append('concrete-specifications','1');
    this.RecipesService.getOne(id, this.language, params).subscribe(
        (data)=>{
          this.recipe=data.data;
          this.formControls.strengths_id.setValue(this.recipe.strengths_id.toString());
          this.formControls.consistencies_id.setValue(this.recipe.consistencies_id.toString());
        },(error)=>console.error(error)
    )
  }

  selectStrength(event) {
    this.formControls.strengths_id.setValue(event.id===null ? '' : event.id.toString());
    this.strength_id=event.id;
  }

  selectEnvironment(event) {
    this.formControls.environments_id.setValue(event.id===null ? '' : event.id.toString());
    this.environment_id=event.id;
  }

  selectConsistency(event) {
    this.formControls.consistencies_id.setValue(event.id===null ? '' : event.id.toString());
    this.consistency_id=event.id;
  }

  save() {
    this.submitted=true;
    if(this.form.invalid)
      return;

    // Sanitize data
    let amount=parseFloat(this.formControls.amount.value.replace(',', '.'));
    let unloading_methods_id=this.SanitizationService.checkAmountIntegerStr(this.formControls.unloading_methods_id.value, 0);
    let recipes_id=null;
    if(this.formControls.recipes_id.value!=='')
      recipes_id=parseInt(this.formControls.recipes_id.value);

    let data={
      id: this.quotation_item.id,
      quotation_revisions_id: this.quotation_revision_id,
      description: this.formControls.description.value,
      recipes_id: this.recipeUnknown ? null : recipes_id,
      strengths_id: parseInt(this.formControls.strengths_id.value),
      environments_id: parseInt(this.formControls.environments_id.value),
      consistencies_id: parseInt(this.formControls.consistencies_id.value),
      amount: amount,
      worktypes_id: parseInt(this.formControls.worktypes_id.value),
      unloading_methods_id: unloading_methods_id,
      comments: this.formControls.comments.value,
    }

    if(this.quotation_item.id===0) {
      this.QuotationItemsService.create(this.language, data).subscribe(
          ()=>{},(error)=>console.error(error),()=>this.afterSave())
    } else {
      this.QuotationItemsService.update(data).subscribe(
          ()=>{},(error)=>console.error(error),()=>this.afterSave())
    }
  }
  afterSave() {
    this.onSave.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
}
