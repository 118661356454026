<table class="table table-xs pricerules-breakdown">
   <thead>
   <tr>
      <th class="entity">{{'ui.priority'|translate}} <i class="fa fa-sort-amount-up"></i></th>
      <th *ngIf="isConcrete"  class="price align-right">{{'concrete.price'|translate}}</th>
      <th *ngIf="!isConcrete" class="price align-right">{{'article.price'|translate}}</th>
      <th *ngIf="!isConcreteBasePrice" class="price align-right">{{'article.price-adjustment'|translate}}</th>
   </tr>
   </thead>
   <tbody>
   <tr *ngFor="let pricerule of pricerules">
      <td>{{pricerule.translationKey|translate}}</td>
      <td *ngIf="pricerule.price==0" class="align-right">
         <span [ngbTooltip]="'ui.inapplicable-info'|translate"><span class="hover-tooltip">{{'ui.inapplicable'|translate}}</span></span>
      </td>
      <td *ngIf="pricerule.price>0.0" class="align-right">
         {{pricerule.price|money}}
      </td>
      <td *ngIf="!isConcreteBasePrice && pricerule.adjustment==0" class="align-center">
         <span [ngbTooltip]="'ui.inapplicable-info'|translate"><span class="hover-tooltip">{{'ui.inapplicable'|translate}}</span></span>
      </td>
      <td *ngIf="!isConcreteBasePrice && pricerule.adjustment!=0.0" class="align-right">
         {{pricerule.adjustment|money}}
      </td>
   </tr>
   </tbody>
</table>
