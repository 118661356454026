import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppLayouts} from "../../app.component";
import {CompanyContainerComponent} from "./company.container.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {PlantComponent} from "./plants/form/plant.component";
import {PlantResolver} from "./data/resolvers/plant.resolver";

const routes: Routes = [
  {
    path: 'settings/company',
    component: CompanyContainerComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/company/settings',
    component: CompanyContainerComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/company/plants',
    component: CompanyContainerComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/company/plants/:id',
    component: PlantComponent,
    canActivate: [AuthGuardService],
    resolve: { plant: PlantResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/company/plants/create',
    component: PlantComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CompanyRoutingModule { }
