import {Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from "@angular/router";
import {Location} from "@angular/common";
import {CustomerPricelistsService } from "../../data/customer-pricelists.service";
import {CustomerService} from "../../../customers/data/customer.service";
import {CustomerSelectListComponent} from "../../customers/selectlist/customer_selectlist.component";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {TokenService} from "../../../../services/auth/token.service";
import {ResourcePricelist} from "../../data/interfaces/resource-pricelist";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Customer} from "../../../customers/data/interfaces/customer";
import {ResourcePricelistsService} from '../../data/resource-pricelists.service';
import {PricelistsType} from '../../data/article-pricelists.service';

@Component({
  selector: 'form-copy-resource-pricelist',
  templateUrl: './copy-resource-pricelist.component.html',
  styleUrls: ['./copy-resource-pricelist.component.scss']
})

export class CopyResourcePricelistComponent implements OnInit, AfterViewInit {
  customer: Customer=this.CustomerService.getEmpty();
  language: string;
  editable: boolean=false;
  valid_from: string;
  warning: string;
  priceListProps: any[]=[];
  selectList: boolean;
  form: FormGroup;
  firstinput: any;
  isSubmitted: boolean=false;
  pricelistsType: PricelistsType=PricelistsType.resource;

  @Input() pricelist: ResourcePricelist;
  @Input() customer_id: number=0;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  @ViewChild('content') modalTemplate: ElementRef;

  constructor(
    private ActivatedRoute: ActivatedRoute,
    private Location: Location,
    private formBuilder: FormBuilder,
    private ResourcePricelistsService: ResourcePricelistsService,
    private CustomerPricelistsService: CustomerPricelistsService,
    private CustomerService: CustomerService,
    private CustomerSelectListComponent: CustomerSelectListComponent,
    private TranslateService: TranslateService,
    private TokenService: TokenService,
    private DatetimeService: DatetimeService,
    private JBMToastsService: JBMToastsService
  ) {
    this.pricelist=this.ResourcePricelistsService.getEmpty();
    this.customer=this.CustomerService.getEmpty();
  }
  ngOnInit(): void {
    this.language=this.TokenService.getLanguage();
    this.warning='';

    // Set pricelist properties
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('financial.concrete-pricelist'), value: this.pricelist.description });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'),
      value: this.ResourcePricelistsService.getTypeAsString(this.pricelist.type, this.TranslateService)});

    this.addControls();
    this.setControlValues();
  }
  ngAfterViewInit(): void {
    this.firstinput=document.getElementById('description');
    this.firstinput.focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      description: new FormControl('',[Validators.required]),
      customer_id: new FormControl('')
    });
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.description.setValue(this.pricelist.description);
    this.form.controls.customer_id.setValue(0);
  }
  setCustomer(event) {
    this.formControls.customer_id.setValue(event.id);
  }
  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    let customer_id=parseInt(this.formControls.customer_id.value);

    let data={
      type: this.pricelist.type,
      l: {language: this.language, description: this.formControls.description.value},
    }

    // Copy for a customer
    this.ResourcePricelistsService.copy(this.pricelist.id, customer_id, data).subscribe(
    data => {}, error => console.error(error),() => this.afterSave())
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.onSave.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
}
