import {Injectable } from '@angular/core';
import {HttpService} from "../http.service";
import {TokenService} from "../auth/token.service";

const baseURL: string = 'settings/';

export const setting_subjects = {
  app: 0,             // App Wide Settings
  customers: 1,
  invoicing: 2,
  customer: 3,
  invoice: 4,
  project: 5,
  quotations: 6
}

export const settings = {
  shift_vat: 'shift_vat',
  resources_based_invoicing: 'resources_based_invoicing'
}

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(
      private HttpService: HttpService,
      private TokenService: TokenService
  ) {}

  setSetting(user_id: number, subject: number, subject_id: number, settingName: string, settingValue: any) {
    return this.HttpService.post(baseURL+'create/setting',{ user_id: user_id, subject: subject, subject_id: subject_id, settingName: settingName, settingValue: settingValue } );
  }
  setSubject(user_id: number, subject: number, subject_id: number, settings: any) {
    return this.HttpService.post(baseURL+'create/subject',{ user_id: user_id, subject: subject, subject_id: subject_id, settings: settings } );
  }
  getSetting(user_id: number, subject: number, subject_id: number, settingName: string) {
    return this.HttpService.get(baseURL+'read/setting/'+user_id.toString()+'/'+subject.toString()+'/'+subject_id.toString()+'/'+settingName);
  }
  getSubject(user_id: number, subject: number, subject_id: number=0) {
    return this.HttpService.get(baseURL+'read/subject/'+user_id.toString()+'/'+subject.toString()+'/'+subject_id.toString());
  }
  settingExists(user_id: number, subject: number, subject_id: number, settingName: string) {
    return this.HttpService.get(baseURL+'read/setting-exists/'+user_id.toString()+'/'+subject.toString()+'/'+subject_id.toString()+'/'+settingName);
  }
  subjectExists(user_id: number, subject: number, subject_id: number=0) {
    return this.HttpService.get(baseURL+'read/subject-exists/'+user_id.toString()+'/'+subject.toString()+'/'+subject_id.toString());
  }
  unsetSetting(user_id: number, subject: number, subject_id: number, settingName: string) {
    return this.HttpService.delete(baseURL+'delete/setting/'+user_id.toString()+'/'+subject.toString()+'/'+subject_id.toString()+'/'+settingName);
  }
  unsetSubject(user_id: number, subject: number, subject_id: number=0) {
    return this.HttpService.get(baseURL+'delete/subject/'+user_id.toString()+'/'+subject.toString()+'/'+subject_id.toString());
  }
  getSubjects() {
    return this.HttpService.get(baseURL+'read/subjects');
  }
  getUserID() {
    return this.TokenService.getUserId();
  }
}
