import { Component } from '@angular/core';
import { TokenService } from "../../services/auth/token.service";
import { MenuService } from "../../services/menu/menu.service";
import { RefererService} from "../../services/storage/referer.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  iconClassname: string;
  iconClassnames={ 'customers': null, 'users': null, 'log': null, 'translations': null,
    'financial': null, 'projects': null, 'company': null, 'logistics': null, 'presentations': null, 'recipes': null };
  rights=this.TokenService.getRights();

  recipeAppSupport: boolean=environment.RecipeAppSupport;

  constructor(
      private TokenService: TokenService,
      private MenuService: MenuService,
      private RefererService: RefererService
  ) {
    // Remove all active settings-related referers
    this.RefererService.clearSection('customers');

    let modules = this.TokenService.getAuthorizedModules();
    this.iconClassname=this.MenuService.getMenuItemIconClassname(modules,'settings');
    // Get icon classnames of the settings menuitems
    for(let name in this.iconClassnames)
      this.iconClassnames[name]=this.MenuService.getMenuItemIconClassname(modules, name);
  }
  hasAccessRights(module: string) {
    for(let r=0; r<this.rights.length; r++)
      if(this.rights[r].name===module)
        return (this.rights[r].allow_read || this.rights[r].allow_update || this.rights[r].allow_create || this.rights[r].allow_delete);
  }
}
