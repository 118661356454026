import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
  TemplateRef,
  HostListener,
  ElementRef,
  OnChanges, ViewChild
} from '@angular/core';
import {PresentationsService} from '../../../../features/presentations/data/presentations.service';
import {presentation_types} from '../../../../features/presentations/data/presentation-types.service';
import {presentation_output_types} from '../../../../features/presentations/data/output-types.service';
import {PresentationUsagesService, presentation_usages} from '../../../../features/presentations/data/presentation-usages.service';
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from '../../../../services/auth/token.service';
import {JBMTableColumnAlignment, JBMTableColumnDef, JBMTableColumnType, JBMTableComponent} from '../../../JBM/JBMTable/JBMTable.component';
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {Presentation} from '../../../../features/presentations/data/interfaces/presentation';
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'presentation-selector',
  templateUrl: './presentation.selector.component.html',
  styleUrls: ['./presentation.selector.component.scss']
})
export class PresentationSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  columnDefs: JBMTableColumnDef[];
  presentation: Presentation=this.PresentationsService.getEmpty();
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;

  @Input() presentation_type: number=presentation_types.invoice;
  @Input() output_type: number=presentation_output_types.pdf;
  @Input() usage: number=presentation_usages.default_usage;
  @Input() presentation_id: number=0;

  @Output() presentationSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();

  @Input() template: TemplateRef<any>;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('usageTemplate') usageTemplate: TemplateRef<any>;

  constructor(
      private PresentationsService: PresentationsService,
      private PresentationUsagesService: PresentationUsagesService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true},
      { name: 'usage', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('ui.usage'), sortable: true, width: 9},
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.addAliasedColumn('description','pd.description');
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.presentation_id) this.setPresentation();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('presentation-search').focus() }, 100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.presentation_id=0;
    this.presentation=this.PresentationsService.getEmpty();
    this.hideList();
    this.presentationSelected.emit(this.presentation);
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.getParams(false);
    this.items$=this.PresentationsService.get(this.language, params).subscribe(
        (data: any) => {
          this.items = data.data;

          this.columnDefs[2].template=this.usageTemplate;
          for(let item of this.items)
              // Set presentation usage labels
            item.usages=this.PresentationUsagesService.getLabels(item.presentation_type, item.usage);
        },(error)=>console.error(error),()=> {
          if(countRows) {
            let params=this.getParams(true);
            this.count$=this.PresentationsService.get(this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  getParams(countRows:boolean=false): HttpParams {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    let filter=params.has('filter') ? params.get('filter') +' and ' : '';

    filter+='p.presentation_type eq '+this.presentation_type.toString();
    filter+=' and p.output_type eq '+this.output_type.toString();
    filter+=' and p.usage eq '+this.usage.toString();

    if(!params.has('filter'))
      params=params.append('filter', filter);
    else
      params.set('filter',filter)

    return params;
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  setPresentation() {
    this.PresentationsService.getOne(this.language, this.presentation_id).subscribe(
        (data: any)=>this.presentation=data.data as Presentation,
        (error)=>console.error(error)
    )
  }
  rowSelect(row) {
    this.presentation=row;
    this.presentation_id=row.id;
    this.presentationSelected.emit(this.presentation);
    this.hideList();
  }

  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }

  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
