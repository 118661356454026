<form [formGroup]="form" (ngSubmit)="save()" class="usergroup">
    <div class="jbm-modal-header">
        <jbm-crud-title subject="entity.usergroup" [action]="action"></jbm-crud-title>
        <div class="toolbar float-end">
            <jbm-activity-log-button *ngIf="usergroup.id" [subject]="'entity.usergroup'|translate"
              [title]="usergroup.name" [subject_type]="25" [subject_id]="usergroup.id">
            </jbm-activity-log-button>
            <jbm-help-button [helpTopicID]="0"></jbm-help-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <jbm-select *ngIf="!this.usergroup.id" [formControl]="formControls.app" [options]="appOptions" [submitted]="submitted" class="input-25"
          [id]="'customer_type'" required="true" labelCaption="{{'ui.app'|translate}}" labelClass="width-7">
        </jbm-select>
        <jbm-text-input [id]="'name'" [formControl]="formControls['name']"
            [required]="true" [submitted]="submitted"
            [labelCaption]="'entity.name'|translate"
            [labelClass]="'width-7'" [class]="'input-25'">
                <jbm-input-error [formControl]="formControls['name']" [submitted]="submitted">
                    <span *ngIf="formControls['name'].hasError('notUnique')">
                        {{'entity.name-not-unique'|translate}}</span>
                    <span *ngIf="formControls['name'].hasError('required')">
                        {{'entity.requiredfield'|translate}}</span>
                </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [id]="'description'" [formControl]="formControls['description']"
            [required]="true" [submitted]="submitted"
            [labelCaption]="'entity.description'|translate"
            [labelClass]="'width-7'" [class]="'input-25'">
            <jbm-input-error [formControl]="formControls['description']" [submitted]="submitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-text-input>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="'entity.usergroup'|translate" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
