import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService} from "../services/multilingual/translate.service";

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private TranslateService: TranslateService) {}
  transform(key): string {
    return this.TranslateService.GetTranslation(key);
  }
}
