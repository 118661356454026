import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import { SharedModule} from "../../../shared/shared.module";
import { JBMButtonsModule} from "../Buttons/jbmbuttons.module";
import { JBMAddressDropdownComponent} from "./JBMAddressDropdown/JBMAddressDropdown.component";
import { JBMCommentsDropdownComponent} from "./JBMCommentsDropdown/JBMCommentsDropdown.component";
import { JBMConfirmDeleteDropdownComponent } from './JBMConfirmDeleteDropdown/JBMConfirmDeleteDropdown.component';
import { JBMProjectStateDropdownComponent } from './JBMProjectStateDropdown/JBMProjectStateDropdown.component';
import { JBMInvoiceStateDropdownComponent} from "./JBMInvoiceStateDropdown/JBMInvoiceStateDropdown.component";
import { JBMQuotationStateDropdownComponent} from "./JBMQuotationStateDropdown/JBMQuotationStateDropdown.component";

@NgModule({
  declarations: [JBMAddressDropdownComponent, JBMCommentsDropdownComponent, JBMConfirmDeleteDropdownComponent,
    JBMProjectStateDropdownComponent, JBMInvoiceStateDropdownComponent, JBMQuotationStateDropdownComponent],
  exports: [NgbDropdown, JBMAddressDropdownComponent, JBMCommentsDropdownComponent, JBMConfirmDeleteDropdownComponent,
    JBMProjectStateDropdownComponent, JBMInvoiceStateDropdownComponent, JBMQuotationStateDropdownComponent],
  imports: [CommonModule, SharedModule, JBMButtonsModule]
})
export class JBMDropdownsModule { }
