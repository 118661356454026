import {Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {
  addDays,
  addHours,
  addMonths, addWeeks, differenceInDays,
  differenceInHours, differenceInMinutes,
  endOfDay, endOfMonth, endOfWeek,
  format,
  getDate, getDaysInMonth,
  getMonth, getYear, isAfter, isBefore,
  startOfDay, startOfMonth,
  startOfWeek
} from 'date-fns';
import {StateService} from "../../services/storage/state.service";
import {ModalService} from "../../components/JBM/JBMModal/service/modal.service";
import {PlantsService} from "../company/data/plants.service";
import {PlanningService} from "./data/planning.service";
import {ProjectstatesService} from "../projects/data/projectstates.service";
import {TokenService} from "../../services/auth/token.service";
import {TranslateService} from "../../services/multilingual/translate.service";
import {DatetimeService} from "../../services/datetime/datetime.service";
import {SanitizationService} from "../../services/sanitization/sanitization.service";
import {ProjectState} from "../projects/data/interfaces/projectstate";
import {nl} from "date-fns/locale";
import {GroupRights} from "../users/data/interfaces/grouprights";
import {JBMSelectOption} from "../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMToastsService} from "../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {HttpParams} from "@angular/common/http";

enum PlanningView {
  Chart,
  Tasklist,
}

enum TaskListView {
  Compact,
  Extended
}

enum ChartPeriod {
  Day,
  Week,
  Month
}

interface DateRange {
  startDate: Date;
  endDate: Date;
  days?: number[];
}

interface dayTotals {
  day: number;
  totalOrders: number;
  totalM3: number;
}

interface Task {
  index?: number;
  startDate: Date;
  endDate: Date;
  completedPercentage: number;
  stagingPercentage?: number;
  stagingAmount?: number;
  order?: any;
  backgroundColor?: string;
  foregroundColor?: string;
  left?: number;
  width?: number;
}

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})
export class PlanningComponent implements OnInit, OnDestroy {

  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;
  @ViewChild('modalPlanOrder') modalPlanOrderTemplate: ElementRef;
  @ViewChild('modalOrderDetails') modalOrderDetailsTemplate: ElementRef;
  @ViewChild('modalInstantReceipt') modalInstantReceipt: ElementRef;

  userRights: GroupRights;
  language: string;
  loading = true;

  // Planned data
  totalM3: number;
  totalProducedM3: number;
  totalOrders: number;
  daysTotals: any[];
  orders: any[];
  truckHours: any[]=[];
  trucksCapacityIndication: any={ smallestPositive: 0, largestNegative: 0 }
  order: any;
  order_id: number;

  plantOptions: JBMSelectOption[]=[];
  plants_id: number=0;

  projectstates: any[];
  projectstate: ProjectState;
  projectstates_id: number;
  inProgressProjectstate: ProjectState = { id: 0, color: '', text_color: '', workflow_state: 0, description: '' };

  // Planning chart
  planningView: PlanningView;
  taskListView: TaskListView;
  plannableListView: boolean;
  chartPeriod: ChartPeriod;
  date: Date;
  dateRange: DateRange;
  selectedTaskIndex: number;
  timeLineTicks: any[];
  timeMarkerDate: Date;
  timeMarker: number;
  timeMarkerInterval;
  tasks: Task[][];
  dayTotals: dayTotals[];
  timer: any;

  customer_id: number=0;
  plant_id: number=0;
  amount: number=0.0;
  delivered: number=0.0;

  constructor(
      private Router: Router,
      private PlantsService: PlantsService,
      private PlanningService: PlanningService,
      private ProjectstatesService: ProjectstatesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private SanitizationService: SanitizationService,
      private DatetimeService: DatetimeService,
      private StateService: StateService,
      private JBMToastsService: JBMToastsService,
      private ModalService: ModalService) {
  }

  ngOnInit(): void {
    // Period planning data
    this.totalM3=0.0;
    this.totalOrders=0;
    this.daysTotals=[];
    this.orders=[];

    this.userRights=this.TokenService.getRightsByName('planning');
    this.language=this.TokenService.getLanguage();

    this.projectstates_id=0;
    this.projectstate={ id: 0, description: '', color: '', text_color: '' };

    this.planningView=PlanningView.Chart;
    this.taskListView=TaskListView.Compact;
    this.plannableListView=false;
    this.chartPeriod=ChartPeriod.Day;
    this.date=new Date();

    this.dateRange={ startDate: startOfDay(this.date), endDate: endOfDay(this.date) };

    this.tasks=[];
    this.dayTotals=[];

    this.selectedTaskIndex=-1;
    this.timeMarkerInterval=null;

    this.PlanningService.getProjectstates(this.language,null).subscribe(
        (data: any)=> this.projectstates=data.data,
        (error)=>console.error(error),()=> {

          // Get projectstate color for scheme legend
          this.inProgressProjectstate = this.ProjectstatesService.getByID(this.projectstates, 5);

          let plants=[];
          this.PlantsService.getData(null).subscribe(
              (data)=>plants=data.data,(error)=>console.error(error),()=>{
                if(plants.length>1) {
                  this.plantOptions = this.PlantsService.getSelectOptions(plants);
                    this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
                }
              }
          );

          this.setDateRange();
        }
    )
  }
  getData() {
    this.tasks = [];
    if(this.plannableListView)
      this.getPlannableData();
    else
      this.getPlannedData();
  }
  getPlannableData() {
    clearTimeout(this.timer);

    let params=new HttpParams();
    if(this.plants_id)
      params=params.append('plant_id',this.plants_id.toString());
    this.PlanningService.getPlannableOrders(this.language, params).subscribe(
        (data)=>this.orders=data.data,
        (error)=>console.error(error),
        ()=>{
          this.wrapOrdersInTasks()
          // Refresh view every minute (Periodically poll or server refresh)
          this.timer=setTimeout(()=> this.getPlannableData(),60000)
        }
    )
  }
  changePlantPlanned(event) {
    this.plants_id=parseInt(event.target.value);
    this.getPlannedData();
  }
  changePlantPlannable(event) {
    this.plants_id=parseInt(event.target.value);
    this.getPlannableData();
  }

  getPlannedData() {
    clearTimeout(this.timer);

    this.loading = true;
    let params=new HttpParams();
    if(this.plants_id)
      params=params.append('plant_id',this.plants_id.toString());
    let startYMD=format(this.dateRange.startDate,'y-MM-dd');
    let endYMD=format(this.dateRange.endDate,'y-MM-dd');
    this.PlanningService.getPeriod(this.language, startYMD, endYMD,params).subscribe(
        (data) => {
          this.totalM3 = data.data.totalM3;
          this.totalProducedM3 = data.data.totalProducedM3;
          this.daysTotals = data.data.daysTotals;
          this.orders = data.data.orders;

          if(this.planningView==PlanningView.Tasklist)
            this.sortData();

          this.truckHours = data.data.truckHours;
        },
        (error) => console.error(error),
        () => {
          this.setTasks();
          this.formatTruckHours();
          this.trucksCapacityIndication=this.PlanningService.getTrucksCapacityIndication(this.truckHours);
          this.loading = false;

          // Refresh view every minute (Periodically poll or server refresh)
          this.timer=setTimeout(()=> this.getPlannedData(),60000)
        }
    )
  }
  // Sorts orders on complete percentage
  sortData() {
    this.orders.sort(function(a, b) {
      let completedA=100;
      if((a.delivered<a.amount)) {
        completedA=Math.round((a.delivered / a.amount) * 100);
      }
      let completedB=100;
      if((b.delivered<b.amount)) {
        completedA=Math.round((b.delivered / b.amount) * 100);
      }
      if (completedA < completedB) {
        return -1;
      }
      if (completedA > completedB) {
        return 1;
      }
      // completed's are the same
      return 0;
    });
  }
  formatData() {
    for(let o=0; o<this.orders.length; o++) {
      let order=this.orders[o];

      // Set projectstate
      let projectState=this.ProjectstatesService.getByID(this.projectstates, order.projectstates_id);
      order.projectState=projectState.description;

      // Set concrete amount
      order.amountStr=this.SanitizationService.amountFloatToStr(order.amount,1)
          + ' ' + this.TranslateService.GetTranslation('quantity.m3');

      // Calculate completed percentage
      order.completed=100;
      if((order.delivered<order.amount)) {
        // Show when in Production or after this
        order.completed=Math.round((order.delivered / order.amount) * 100);
      }

      // Calculate staging receipts percentage
      order.staging=this.calcStagingReceipts(order);

      // Set unloading flow
      order.unloadingFlowStr=this.SanitizationService.amountFloatToStr(order.unloading_flow,1)
          + ' ' + this.TranslateService.GetTranslation('quantity.m3') + '/'
          + this.TranslateService.GetTranslation('datetime.hour');
    }
  }
  setTasks() {
    let date = this.dateRange.startDate;

    this.totalM3 = 0.0;
    this.totalOrders = 0;

    for( let dayIndex=0; dayIndex<this.dateRange.days.length; dayIndex++ ) {

      let y = getYear(date);
      let m = getMonth(date)+1;
      let d = getDate(date);

      let dayTasks=[];

      // Init day totals
      let dayTotalM3 = 0.0;
      let dayOrders = 0;

      for( let o=0; o<this.orders.length; ++o ) {

        let production_date = this.DatetimeService.getDatePartsDMY(this.orders[o].production_date);
        if(!(production_date.year===y && production_date.month===m && production_date.day===d))
          continue;

        let starttime = this.DatetimeService.getTimeParts(this.orders[o].starttime ? this.orders[o].starttime : this.orders[o].loadtime);
        let start = new Date(production_date.year,production_date.month-1, production_date.day, starttime.hours, starttime.minutes);
        let endtime = this.DatetimeService.getTimeParts(this.orders[o].endtime);
        let end = new Date(production_date.year, production_date.month-1, production_date.day, endtime.hours, endtime.minutes);

        let completedPercentage=100;
        if(this.orders[o].delivered<this.orders[o].amount)
          completedPercentage = Math.ceil((this.orders[o].delivered / this.orders[o].amount) * 100);

        if(completedPercentage===100)
          // Give this order visually the Work-done project state
          this.orders[o].project.projectstates_id=6;

        let staging=this.calcStagingReceipts(this.orders[o]);

        // Set project state colors
        this.projectstate = this.ProjectstatesService.getByID(this.projectstates, this.orders[o].project.projectstates_id);
        this.orders[o].project.projectState=this.projectstate;
        let backgroundColor=this.projectstate.color;
        let foregroundColor=this.projectstate.text_color;

        // Mark for today a too late started production of an order when the order loadtime is expired
        if(this.chartPeriod===ChartPeriod.Day && completedPercentage===0) {
          let loadtime = this.DatetimeService.getTimeParts(this.orders[o].loadtime);
          if(this.DatetimeService.getCurrentDay()===production_date.day &&
             this.DatetimeService.getCurrentHour() >= loadtime.hours) {
            backgroundColor = 'red';
            foregroundColor = 'white';
          }
        }

        if(this.orders[o].on_call) {
          backgroundColor = 'yellow';
          foregroundColor = '#000';
        }

        let task = {
          index: dayIndex,
          startDate: start,
          endDate: end,
          order: this.orders[o],
          completedPercentage: completedPercentage,
          stagingPercentage: staging.percentage,
          stagingAmount: staging.amount,
          backgroundColor: backgroundColor,
          foregroundColor: foregroundColor
        }

        this.setTaskMetrix(task);
        dayTasks.push(task);

        dayTotalM3 += this.orders[o].amount;
        dayOrders++;
      }
      this.tasks[dayIndex] = dayTasks;
      this.dayTotals[dayIndex] = {day: dayIndex, totalOrders: dayOrders, totalM3: dayTotalM3}

      this.totalM3 += dayTotalM3;
      this.totalOrders += dayOrders;

      date=addDays(date,1);
    }
  }
  wrapOrdersInTasks() {
    this.totalM3 = 0.0;
    this.totalOrders = 0;
    let tasks=[];
    for( let o=0; o<this.orders.length; ++o ) {

      let completedPercentage = Math.ceil((this.orders[o].delivered / this.orders[o].amount) * 100);

      // Set project state colors
      this.projectstate = this.ProjectstatesService.getByID(this.projectstates, this.orders[o].project.projectstates_id);

      let task = {
        startDate: null,
        endDate: null,
        order: this.orders[o],
        completedPercentage: completedPercentage,
        backgroundColor: this.projectstate.color,
        foregroundColor: this.projectstate.text_color
      }

      this.setTaskMetrix(task);
      tasks.push(task);

      this.totalM3 += this.orders[o].amount;
      this.totalOrders++;
    }
    this.tasks[0] = tasks;
  }
  calcStagingReceipts(order: any) {
    // Calculate percentage and total amount of staging receipts
    let result={ percentage: 0, amount: 0 }

    for(let stagingReceipt of order.stagingReceipts)
      result.amount+=stagingReceipt.receipt_amount;
    result.percentage=Math.round((result.amount / order.amount) * 100);

    return result;
  }
  formatTruckHours() {
    let truckHours=[];
    for(const data in this.truckHours) {
      let data1=this.truckHours[data].truckHours;
      let dataHours=[];
      let totalTrucks=0;
      const keys=Object.keys(data1);
      for(const key of keys) {
        totalTrucks+=data1[key].totalTrucks;
        let orders=[];
        let dataOrders=data1[key].orders;
        const orderKeys=Object.keys(dataOrders);
        for(const orderKey of orderKeys)
            orders.push( { id: parseInt(orderKey), trucks: dataOrders[orderKey] });
        dataHours.push( { hour: parseInt(key), totalTrucks: data1[key].totalTrucks, orders: orders });
      }
      truckHours.push( { plant: this.truckHours[data].plant, average_truck_capacity: this.truckHours[data].average_truck_capacity, totalTrucks: totalTrucks, truckHours: dataHours } );
    }
    this.truckHours=truckHours;
  }
  setPlanningView(planningView: PlanningView) {
    if(this.planningView===planningView) return;
    this.planningView=planningView;
    this.setDateRange();
  }
  toggleTaskListCompactView() {
    this.taskListView=this.taskListView ? TaskListView.Compact : TaskListView.Extended;
  }
  togglePlannableListView() {
    if(!this.plannableListView)
      // Initial show order details while planning orders
      this.toggleTaskListCompactView();
    this.plannableListView=!this.plannableListView;

    this.planningView=this.plannableListView ? PlanningView.Tasklist : PlanningView.Chart;
    this.getData();
  }

  setChartPeriod(ChartPeriod:ChartPeriod) {
    if(this.chartPeriod===ChartPeriod) return;
    this.date=new Date();
    this.chartPeriod=ChartPeriod;
    this.setDateRange();
  }
  setDateRange() {
    this.selectedTaskIndex=-1;

    // todo: Remove; Just for test reasons
//    this.date=new Date('2022-03-21');

    let startDate = startOfDay(this.date);
    let endDate = endOfDay(this.date);
    switch(this.chartPeriod) {
      case ChartPeriod.Week:
        startDate = startOfWeek(this.date,{weekStartsOn: 1});
        endDate = endOfWeek(this.date,{weekStartsOn: 1});
        break;
      case ChartPeriod.Month: {
        startDate = startOfMonth(this.date);
        endDate = endOfMonth(this.date);
        break;
      }
    }
    this.calcDateRange(startDate, endDate);
    this.setTimeLine();
    this.setTimeMarker();
    this.getData();
  }
  calcDateRange( startDate: Date, endDate: Date ) {
    let daysDifference=differenceInDays(endDate, startDate)+1;
    let days=[];
    this.dayTotals=[];
    for(let d=0; d<daysDifference; d++) {
      days.push(d);
      this.dayTotals[d] = {day: d, totalOrders: 0, totalM3: 0.0}
    }
    this.dateRange = { startDate: startDate, endDate: endDate, days: days }
  }
  setTimeLine() {
    this.timeLineTicks=[];
    switch(this.chartPeriod) {
      case ChartPeriod.Day:
        this.setDayTimeline();
        break;
      case ChartPeriod.Week:
        this.setWeekTimeline();
        break;
      case ChartPeriod.Month:
        this.setMonthTimeline();
        break;
    }
  }
  setDayTimeline() {
    let ticks=0;
    let date=this.dateRange.startDate;
    let markers=[0,25,50,75];
    do {
      this.timeLineTicks.push( { label: format(date,'HH:mm'), class: '', markers: markers } );
      date=addHours( date,1);
      ticks++;
    } while(ticks<24);
  }
  setWeekTimeline() {
    let ticks=0;
    let date=this.dateRange.startDate;
    let markers=[0];
    do {
      this.timeLineTicks.push( { label: format(date,'EEEEEE d',{locale: nl}), class: '', markers: markers } );
      date=addDays(date,1);
      ticks++;
    } while(ticks<7);
  }
  setMonthTimeline() {
    let ticks=0;
    let date=this.dateRange.startDate;
    let daysInMonth=getDaysInMonth(date);
    let markers=[0];
    do {
      this.timeLineTicks.push( { label: format(date,'EEEEEE d',{locale: nl}), class: 'month-'+daysInMonth, markers: markers } );
      date=addDays(date,1);
      ticks++;
    } while(ticks<daysInMonth);
  }
  setTimeMarker() {
    this.timeMarker=0;
    this.timeMarkerDate=new Date();

    if(isAfter(this.timeMarkerDate, this.dateRange.endDate) &&
        isBefore(this.timeMarkerDate, this.dateRange.startDate))
      return;

    let rangeDifference=differenceInMinutes(this.dateRange.endDate, this.dateRange.startDate);
    let timeDifference=differenceInMinutes(this.timeMarkerDate, this.dateRange.startDate);
    this.timeMarker=(timeDifference/rangeDifference) * 100;

    if(this.timeMarkerInterval===null) {
      this.timeMarkerInterval=setInterval(() => {
        this.setTimeMarker() },1000)
    }
  }
  navigatePrevious() {
    switch(this.chartPeriod) {
      case ChartPeriod.Day:
        this.date=addDays(this.date,-1);
        break;
      case ChartPeriod.Week:
        this.date=addWeeks(this.date,-1);
        break;
      case ChartPeriod.Month:
        this.date=addMonths(this.date,-1);
        break;
    }
    this.setDateRange();
  }
  navigateToday() {
    this.date=new Date();
    this.setDateRange();
  }
  navigateNext() {
    switch(this.chartPeriod) {
      case ChartPeriod.Day:
        this.date=addDays(this.date,1);
        break;
      case ChartPeriod.Week:
        this.date=addWeeks(this.date,1);
        break;
      case ChartPeriod.Month:
        this.date=addMonths(this.date,1);
        break;
    }
    this.setDateRange();
  }
  taskClick(index) {
    if(this.selectedTaskIndex===index)
      this.selectedTaskIndex=-1;
    else {
      this.selectedTaskIndex=index;
    }
  }

  // Helper functions
  setTaskMetrix(task: Task) {
    // Position and size
    let percentage=0;
    let start=0;
    let end=0;

    switch(this.chartPeriod ) {
      case ChartPeriod.Day:
        // Calculate start and end times in minutes
        start=differenceInMinutes(task.startDate, this.dateRange.startDate);
        end=differenceInMinutes(task.endDate, this.dateRange.startDate);
        percentage=(1/1440)*100;
        break;
      case ChartPeriod.Week:
        // Calculate start and end times in days
        start=differenceInHours(task.startDate, this.dateRange.startDate);
        end=differenceInHours(task.endDate, this.dateRange.startDate);
        percentage=(1/168)*100;
        break;
      case ChartPeriod.Month:
        // Calculate start and end times in days
        start=differenceInDays(task.startDate, this.dateRange.startDate);
        end=differenceInDays(task.endDate, this.dateRange.startDate);
        break;
    }

    task.left=start*percentage;
    task.width=(end*percentage)-(start*percentage);
  }

  showOrderDetails(task) {
    this.StateService.setData( {'task': task } );
    this.ModalService.open(this.modalOrderDetailsTemplate,'orderdetails');
  }
  closeOrderDetails() {
    this.ModalService.close('orderdetails');
  }

  showInstantReceipt(task: any) {
    this.order_id=task.order.id;
    this.customer_id=task.order.project.customer_id;
    this.plant_id=task.order.order_plants_id===null ? task.order.project.plants_id : task.order.order_plants_id;
    this.amount=task.order.amount;
    this.delivered=task.order.delivered;

    this.ModalService.open(this.modalInstantReceipt,'instant-receipt');
  }
  instantReceiptSuccess() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.closeInstantReceipt();
  }
  closeInstantReceipt() {
    this.ModalService.close('instant-receipt');
  }

  // Prevent auto closing active dropdown(s) when planning data is refreshed
  dropdownOpenChanged(opened: boolean) {
    if(opened)
      clearTimeout(this.timer);
    else {
      if(this.plannableListView)
        this.timer=setTimeout(()=>this.getPlannableData(),60000)
      else
        this.timer=setTimeout(()=>this.getPlannableData(),60000)
    }
  }

  onDelete() {
    this.ModalService.close('orderdetails');
    if(this.selectedTaskIndex>-1) this.selectedTaskIndex=-1;
    this.getData();
  }
  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape' && this.selectedTaskIndex>-1)
      // Close active task details panel
      this.selectedTaskIndex=-1;
  }
}
