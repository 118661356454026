<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.project'|translate}} {{'order.production'|translate|lowercase}}{{'production.receipts'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <h6>{{'invoice.invoiced'|translate}}</h6>
   <table class="table table-xs invoice-receipts">
      <thead>
         <tr>
            <th class="id">{{'ui.id'|translate}}</th>
            <th class="production-date">{{'order.production-date'|translate}}</th>
            <th class="recipe">{{'entity.recipe'|translate}}</th>
            <th class="amount align-right pr-2">{{'order.amount'|translate}}</th>
            <th class="truck">{{'production.truck'|translate}}</th>
            <th class="driver">{{'production.driver'|translate}}</th>
            <th class="plant">{{'company.plant'|translate}}</th>
            <th class="comment">{{'entity.comments'|translate}}</th>
            <th class="action"></th>
         </tr>
      </thead>
      <tbody>
         <tr *ngFor="let receipt of receipts">
            <td>{{receipt.receipt.id}}</td>
            <td>{{receipt.invoice_item.delivery_date}}</td>
            <td>
               <jbm-link-button [caption]="receipt.invoice_item.description"
                 (onClick)="showRecipe(receipt.invoice_item.recipes_id, receipt.invoice_item.environment.description, receipt.invoice_item.delivery_date)">
               </jbm-link-button>
            </td>
            <td class="align-right pr-2">{{receipt.receipt.amount}} {{'quantity.m3'|translate}}</td>
            <td>{{receipt.receipt.truck.numberplate}}</td>
            <td>{{receipt.receipt.driver.name}}</td>
            <td>{{receipt.receipt.plant.code}}</td>
            <td><jbm-comments-dropdown [comments]="receipt.receipt.comment"></jbm-comments-dropdown></td>
            <td class="action">
               <jbm-confirm-delete-dropdown *ngIf="editable && receipts.length>1" [class]="'float-end'"
                 [subject]="('menu.production'|translate)+('production.receipt'|translate|lowercase)+' '+('ui.delete'|translate|lowercase)"
                 (onConfirm)="delete(receipt.id)">
               </jbm-confirm-delete-dropdown>
            </td>
         </tr>
      </tbody>
   </table>
   <h6 class="mt-3">{{'invoice.to-invoice'|translate}}</h6>
   <table [hidden]="invoiceableReceipts.length==0" class="table table-xs invoice-receipts">
      <thead>
      <tr>
         <th class="id">{{'ui.id'|translate}}</th>
         <th class="production-date">{{'order.production-date'|translate}}</th>
         <th class="recipe">{{'entity.recipe'|translate}}</th>
         <th class="amount align-right pr-2">{{'order.amount'|translate}}</th>
         <th class="truck">{{'production.truck'|translate}}</th>
         <th class="driver">{{'production.driver'|translate}}</th>
         <th class="plant">{{'company.plant'|translate}}</th>
         <th class="comment">{{'entity.comments'|translate}}</th>
         <th class="action"></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let receipt of invoiceableReceipts">
         <td>{{receipt.receipt.id}}</td>
         <td>{{receipt.execute_date}}</td>
         <td>
            <jbm-link-button [caption]="receipt.recipe.code"
              (onClick)="showRecipe(receipt.order.recipes_id, receipt.order.environment, receipt.execute_date)">
            </jbm-link-button>
         </td>
         <td class="align-right pr-2">{{receipt.receipt.amount}} {{'quantity.m3'|translate}}</td>
         <td>{{receipt.receipt.truck.numberplate}}</td>
         <td>{{receipt.receipt.driver.name}}</td>
         <td>{{receipt.receipt.plant.code}}</td>
         <td><jbm-comments-dropdown [comments]="receipt.receipt.comment"></jbm-comments-dropdown></td>
         <td class="float-end">
            <button *ngIf="editable" class="btn btn-sm btn-primary" (click)="add(receipt.id)"><i class="fa fa-calculator"></i> {{'entity.create'|translate}}</button>
         </td>
      </tr>
      </tbody>
   </table>
   <jbm-empty-view [emptyView]="invoiceableReceipts.length==0"></jbm-empty-view>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button (click)="close()"></jbm-close-button>
   </div>
</div>

<jbm-modal [id]="'receipt_recipeDetails'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [delivery_date]="recipeData.delivery_date" [recipe_id]="recipeData.id" [environment]="recipeData.environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>