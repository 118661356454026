import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {TextPlaceholdersService} from "../../data/text_placeholders.service";
import {MediaFilesService} from "../../data/media_files.service";
import {MimeTypesService} from "../../../../services/storage/mimetypes.service";
import {MediaFile} from "../../data/interfaces/media_file";
import {TextPlaceholder} from "../../data/interfaces/text_placeholder";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'template-placeholder-picker',
  templateUrl: './placeholder-picker.component.html',
  styleUrls: ['./placeholder-picker.component.scss']
})
export class PlaceholderPickerComponent implements OnInit, OnChanges {
  safeURL: SafeResourceUrl;
  images: MediaFile[]=[];
  textPlaceholders: TextPlaceholder[]=[];

  constructor(
      private TextPlaceholdersService: TextPlaceholdersService,
      private MediaFilesService: MediaFilesService,
      private MimeTypesService: MimeTypesService,
      private DomSanitizer: DomSanitizer
  ) { }

  @Input() language: string;
  @Input() pickImages: boolean=true;
  @Input() placeholders: any[]=[];
  @Output() onDataPlacement=new EventEmitter();
  @Output() onTextPlacement=new EventEmitter();
  @Output() onImagePlacement=new EventEmitter();
  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    // Get text and image placeholders
    this.TextPlaceholdersService.get(this.language,null).subscribe(
        (data)=>this.textPlaceholders=data.data,
        (error)=>console.error(error),
        ()=> {
          let param = new HttpParams().append('images','1').append('content','1');
          this.MediaFilesService.get(param).subscribe(
            (data)=>this.images=data.data,
            (error)=>console.error(error),
          )
        }
    )
  }
  ngOnChanges() {
  }
  getSafeImageURL(index: number) {
    let mimeType = this.MimeTypesService.getMimetype(this.images[index].extension);
    this.safeURL=this.DomSanitizer.bypassSecurityTrustResourceUrl('data:' + mimeType + ';base64,' + this.images[index].content);
    return this.safeURL;
  }
  pickData(placeholder: string) {
    this.onDataPlacement.emit(placeholder);
  }
  pickText(placeholder: string) {
    this.onTextPlacement.emit(placeholder);
  }
  pickImage( image: any ) {
    this.onImagePlacement.emit( image );
  }
  close() {
    this.onClose.emit();
  }
}
