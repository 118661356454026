import {Component, ElementRef, HostListener, OnInit} from '@angular/core';
import {Location} from "@angular/common";
import {LogService} from "../../../../services/activity-log/log.service";
import {ActivitiesService} from "../../../../services/activity-log/activities.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {StateService} from "../../../../services/storage/state.service";
import {BodyClassTogglerService} from "../../../../services/interface/body-class-toggler.service";
import {JBMTableColumnDef,JBMTableColumnType} from "../../JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";

@Component({
  selector: 'jbm-activity-log',
  templateUrl: './JBMActivityLog.component.html',
  styleUrls: ['./JBMActivityLog.component.scss']
})
export class JBMActivityLogComponent implements OnInit {
  logs: any[];
  columnDefs: JBMTableColumnDef[];
  subject_type: number;
  subject_id: number;
  subject: string;
  title: string;

  logs$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  constructor(
      private Location: Location,
      public LogService: LogService,
      private ActivitiesService: ActivitiesService,
      private StateService: StateService,
      private BodyClassTogglerService: BodyClassTogglerService,
      public TranslateService: TranslateService,
      private eRef: ElementRef
  ) {    this.queryHttpHelper = new QueryHttpHelper();
  }

  ngOnInit(): void {

    // Has State the required data-structure ?
    let state=this.StateService.getData();
    if(!state.hasOwnProperty('subject_type')) {
      this.Location.back();
      return;
    }

    this.subject_type=state.subject_type;
    this.subject_id=state.subject_id;
    this.subject=state.subject;
    this.title=state.title;

    this.BodyClassTogglerService.checkClass('aside-right');

//    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('timestamp',true);
    this.queryHttpHelper.addSearchColumn('full_name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=10;

    this.columnDefs = [
      {
        name: 'id',
        type: JBMTableColumnType.id,
      },
      {
        name: 'full_name',
        header: this.TranslateService.GetTranslation('login.username'),
        filterable: true,
        width: 11
      },
      {
        name: 'activity',
        header: this.TranslateService.GetTranslation('log.activity'),
        filterable: true,
      },
      {
        name: 'timestamp',
        type: JBMTableColumnType.datetime,
        header: this.TranslateService.GetTranslation('entity.timestamp'),
        sortable: true,
      }
    ];

    this.setActivityFilterValues();
    this.getData(true);
  }
  setActivityFilterValues() {
    this.columnDefs[2].filterValues = this.ActivitiesService.getActivityItems();
  }
  getData(countRows:boolean=false) {
    let httpParams=this.queryHttpHelper.getHttpParams(false);
    this.logs$=this.LogService.getSubjectData(this.subject_type, this.subject_id, httpParams).subscribe(
        (data: any) => this.logs = data.data,()=>{},()=>{

          // Set translated activity description
          for(let l=0; l<this.logs.length; l++)
            this.logs[l].activity=this.TranslateService.GetTranslation('userrights.'+this.logs[l].activity);

          if(countRows) {
            let httpParams=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.LogService.getSubjectData(this.subject_type, this.subject_id, httpParams).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = parseInt(data.data) );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  close() {
    this.Location.back();
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.close();
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.logs$!=undefined) this.logs$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();

    // Clear state data
    this.StateService.clear();

    // De-activate backdrop
    this.BodyClassTogglerService.toggleClass('aside-right');
  }
}
