import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {Usergroup} from "./interfaces/usergroup";

const insertURL: string = 'users/create/usergroup';
const updateURL: string = 'users/update/usergroup';
const emptyURL: string = 'users/read/usergroup/empty';
const readURL: string = 'users/read/usergroups';
const getOneURL: string = 'users/read/usergroup/one';

@Injectable({
  providedIn: 'root'
})
export class UsergroupService {
  usergroup: Usergroup;

  constructor(private HttpService: HttpService) {}

  getData(appID: number=0) {
    return this.HttpService.get(readURL+'/'+appID.toString());
  }
  getOne(id: number) {
    let params=new HttpParams().append('id',id.toString());
    return this.HttpService.get(getOneURL, params);
  }
  insert(data: any) {
    return this.HttpService.post(insertURL, data);
  }
  getEmpty() {
    this.usergroup = {
      id: 0,
      app: 0,
      name: '',
      description: ''
    }
    return this.usergroup;
  }
  update(data: any) {
    return this.HttpService.post(updateURL, data);
  }
}
