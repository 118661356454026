import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PriceService} from "../../../services/price/price.service";
import {ArticlePricesService} from "../../financial/data/article-prices.service";
import {article_usages, InvoiceArticlesService} from "../data/invoice-articles.service";
import {ArticlesService} from "../../financial/data/articles.service";
import {TokenService} from "../../../services/auth/token.service";
import {SanitizationService} from "../../../services/sanitization/sanitization.service";
import {DatetimeService} from "../../../services/datetime/datetime.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {HttpParams} from "@angular/common/http";
import {
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerKeyboardService,
  NgbDateStruct
} from "@ng-bootstrap/ng-bootstrap";
import {CustomDatepickerI18n} from "../../../services/NgbDatepicker/custom-datepicker-i18n.service";
import {CustomAdapter} from "../../../services/NgbDatepicker/custom-adapter.service";
import {CustomDateParserFormatter} from "../../../services/NgbDatepicker/custom-dateparser-formatter.service";
import {CustomKeyboardService} from "../../../services/NgbDatepicker/custom-keyboard-service";
import {price_usage_subjects} from "../../financial/data/price-usages.service";
import {price_type_system_codes} from "../../projects/data/order-articles.service";

@Component({
  selector: 'invoice-articles',
  templateUrl: './invoice-articles.component.html',
  styleUrls: ['./invoice-articles.component.scss'],
  providers: [
    {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, // define custom NgbDatepickerI18n provider
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    {provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService}
  ]
})
export class InvoiceArticlesComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  subject: number=price_usage_subjects.invoice_article;
  articles: any[]=[];
  articlesTotalPrice: number=0;
  article: any;

  allowedPricetypes = [price_type_system_codes.M3, price_type_system_codes.M, price_type_system_codes.M2,
    price_type_system_codes.PIECE];
  article_price_types_id: number=price_type_system_codes.M3;

  pricerules: any[]=[];
  article_id: number = 0;

  code: string = '';
  date: string='';
  dateTouched: boolean=false;
  description: string='';
  descriptionTouched: boolean = false;
  descriptionError: string = '';
  amount: string = '';
  amountTouched: boolean = false;
  amountError: string = '';
  price: string = '';
  priceTouched: boolean = false;
  priceError: string = '';
  totalPrice: number = 0.0;

  creating: boolean = false;
  editing: boolean = false;
  id: number = 0;

  @Input() projects_id: number;
  @Input() customers_id: number;
  @Input() invoice_id: number;
  @Input() invoice_item_id: number;
  @Input() userRights: GroupRights;
  @Input() editable: boolean;

  @Output() onUpdateTotalsNeeded=new EventEmitter();

  constructor(
      private InvoiceArticlesService: InvoiceArticlesService,
      private ArticlesService: ArticlesService,
      private ArticlePricesService: ArticlePricesService,
      private PriceService: PriceService,
      private TokenService: TokenService,
      private DatetimeService: DatetimeService,
      private TranslateService: TranslateService,
      private SanitizationService: SanitizationService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    if(this.invoice_id==undefined) this.invoice_id=0;
    if(this.invoice_item_id==undefined) this.invoice_item_id=null;
    if(this.editable==undefined) this.editable=true;
    this.getData();
  }
  getData() {
    let params=new HttpParams().append('totalizeprices','1').append('article','1');
    this.InvoiceArticlesService.getExtraArticles( this.language, this.invoice_id,0, params).subscribe(
        (data)=>this.articles=data.data,
        (error)=>console.error(error),
        ()=>this.updateArticlesTotal()
    )
  }
  updateArticlesTotal() {
    this.articlesTotalPrice=0;
    for(let article of this.articles)
      this.articlesTotalPrice+=article.totalprice;
  }
  editArticle(id: number) {
    if(!this.editable) return;

    let article=this.getArticle(id);
    if(!article || article.editing) return false;

    if(this.creating)
      this.cancelCreating();

    if(this.editing)
      this.cancelEditing();

    article['editing']=true;
    this.editing = true;
    this.clearItemData();
    this.setItemData(article);
    this.totalPrice=this.InvoiceArticlesService.calcTotalPrice(
        article.price, article.amount, this.article_price_types_id,0);

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('article-amount-' + this.id.toString())).focus();
    },100);
  }
  cancelArticle() {
    let article=this.getArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.clearItemData();
  }
  saveArticle() {
    let article=this.getArticle(this.id);
    if(!article || !article.editing) return false;

    article['editing']=false;
    this.editing = false;
    this.updateArticle(article);
  }
  getArticle(id: number) {
    for(let article of this.articles)
      if(article.id===id) {
        this.article_price_types_id=article.article_price_types_id;
        return article;
      }
    return false;
  }

  // Create item
  startCreating() {
    if(this.editing) this.cancelEditing();
    this.creating=true;
    this.clearItemData();
  }
  cancelCreating() {
    this.invoice_item_id=null;
    this.creating=false;
    this.clearItemData();
  }
  selectDate(event) {
  }
  onChangeDate() {
    this.dateTouched=true;
  }
  validateDate() {
    if(this.date===null) return true;
    this.date=this.date.trim();
    return !this.date || this.DatetimeService.validateDateStr(this.date);
  }
  onChangeDescription() {
    this.descriptionTouched = true;
  }
  validateDescription() {
    let valid=this.description.trim()!=='';
    if( !valid )
      this.descriptionError = this.TranslateService.GetTranslation('ui.invalid');
    return valid;
  }
  onChangeAmount() {
    this.amountTouched = true;
    this.calculateTotalPrice();
  }
  cancelEditing() {
    for(let article of this.articles)
      article.editing=false;
  }
  clearItemData() {
    this.id=0;
    this.date=this.DatetimeService.getCurrentDateDMY();
    this.dateTouched=false;
    this.article_id=0;
    this.amount='';
    this.amountTouched=false;
    this.price='';
    this.totalPrice=null;
    this.priceTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  calculateTotalPrice() {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount,0);
    let price  = this.SanitizationService.checkPriceStr(this.price);

    this.totalPrice=this.InvoiceArticlesService.calcTotalPrice(price, amount, this.article_price_types_id,0);
  }
  validateAmount() {
    let amount = this.SanitizationService.checkAmountFloatStr(this.amount, 0);
    if( amount < 1 )
      this.amountError = this.TranslateService.GetTranslation('ui.invalid');
    return amount > 0;
  }
  onChangePrice() {
    this.price=this.price.trim();
    if(this.price==='') this.price='0';
    this.priceTouched = true;
    this.calculateTotalPrice();
  }
  validatePrice() {
    return this.SanitizationService.checkPriceStr(this.price) >= 0;
  }
  selectArticle(article) {
    if(!article.id) return;
    this.article=article;
    this.article_id=article.id;
    this.article_price_types_id=article.article_price_types_id;
    this.code=article.price_type_code;
    this.price=this.SanitizationService.amountFloatToStr(article.price);
    this.description=article.name;

    setTimeout(()=> {
      (<HTMLInputElement>document.getElementById('article-amount-' + this.id.toString())).focus();
    },100);
  }
  setItemData(article) {
    this.id=article.id;
    this.article_id=article.articles_id;
    this.date=article.delivery_date===null ? '' : article.delivery_date;
    this.article_price_types_id=article.article_price_types_id;
    this.code=article.price_type_code;
    this.amount=this.SanitizationService.amountFloatToStr(article.amount);
    this.price=this.SanitizationService.amountFloatToStr(article.price);
    this.description=article.description;
  }
  insertArticle() {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount);
    let price =this.SanitizationService.checkPriceStr(this.price);
    this.calculateTotalPrice();

    let data= {
      id: 0,
      invoices_id: this.invoice_id,
      invoice_items_id: null,
      delivery_date: this.getDeliveryDate(),
      orders_id: null,
      articles_id: this.article_id,
      article_price_types_id: this.article_price_types_id,
      description: this.description,
      article_display: 0,
      usage: article_usages.extra,
      price: price,
      amount: amount,
      totalprice: this.totalPrice
    }
    this.InvoiceArticlesService.create(data).subscribe(()=>{},(error)=>console.error(error),()=> this.afterSave())
  }
  updateArticle(article: any) {
    let amount=this.SanitizationService.checkAmountFloatStr(this.amount);
    let price =this.SanitizationService.checkPriceStr(this.price);
    this.calculateTotalPrice();

    // Update fields in interface to prevent showing old values before data update completion
    article.amount=amount;
    article.price=price;
    article.totalprice=this.totalPrice;

    let delivery_date=this.getDeliveryDate();
    let data={
      id: this.id,
      invoices_id: article.invoices_id,
      invoice_items_id: article.invoice_items_id,
      articles_id: this.article_id,
      usage: article.usage,
      delivery_date: delivery_date,
      description: article.usage===article_usages.recipe ? article.description : this.description,
      price: price,
      amount: amount,
      totalprice: this.totalPrice
    }
    this.InvoiceArticlesService.update(data).subscribe(()=>{},(error)=>console.error(error),()=> this.afterSave())
  }
  getDeliveryDate() {
    let delivery_date=null;
    if(this.date)
      delivery_date=this.DatetimeService.dateDMYToYMD(this.date);
    return delivery_date;
  }
  afterSave() {
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-saved'));
    this.getData();
    this.cancelCreating();
    this.cancelEditing();
    this.updateArticlesTotal();
    this.onUpdateTotalsNeeded.emit();
  }
  delete(invoice_article_id: number) {
    this.InvoiceArticlesService.delete(invoice_article_id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.onUpdateTotalsNeeded.emit();
          this.getData();
        }
    )
  }
}
