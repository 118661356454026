import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {TrucksService} from "../data/trucks.service";
import {PlantsService} from "../../company/data/plants.service";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {
  JBMTableActionDef,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {Subscription} from "rxjs";
import {Truck} from "../data/interfaces/truck";
import {ContextType} from "../../../components/JBM/Helpers/Context";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'trucks',
  templateUrl: './trucks.component.html',
  styleUrls: ['./trucks.component.scss']
})
export class TrucksComponent implements OnInit, OnDestroy {
  truck: Truck;
  userRights: GroupRights;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  queryHttpHelper: QueryHttpHelper;
  plantOptions: JBMSelectOption[]=[];
  plant_id: number=0;
  items: any[]=[];
  view: number=1; // Active trucks
  loading: boolean=false;
  showFilters: boolean=false;
  searchboxVisible: boolean=true;
  search: string='';

  items$: Subscription;
  count$: Subscription;

  constructor(
      private TrucksService: TrucksService,
      private PlantsService: PlantsService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) { }

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('modalTruck') modalTruck: ElementRef;
  @ViewChild('activeTemplate') activeTemplate: TemplateRef<any>;

  @Output() afterCount=new EventEmitter();

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('logistics');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('number');
    this.queryHttpHelper.addSearchColumn('number', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('numberplate', SearchColumnType.string);

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'plant', header: this.TranslateService.GetTranslation('company.plant'), width: 12 },
      { name: 'number', header: this.TranslateService.GetTranslation('ui.number'), sortable: true, filterable: true, width: 8 },
      { name: 'numberplate', header: this.TranslateService.GetTranslation('truck.numberplate'), sortable: true, filterable: true, width: 8 },
      { name: 'capacity', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('production.capacity'), sortable: true, filterable: true, width: 6, className: 'pr-3' },
      { name: 'driver', header: this.TranslateService.GetTranslation('production.driver') },
      { name: 'active', header: this.TranslateService.GetTranslation('entity.active'), width: 4 }
    ];

    this.actionDefs=[
      { name: 'edit', caption: '', context: ContextType.primary, iconClass: 'far fa-edit'}
    ];

    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }
          this.getData(true);
        }
    );
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.getParams(false);
    this.items$=this.TrucksService.get(params).subscribe(
        (data: any) => this.items=data.data,(error)=>console.error(error),()=>{
          this.loading=false;

          // Set active template
          this.columnDefs[6].template = this.activeTemplate;

          for(let item of this.items)
            item.capacity=item.capacity.toString().replace('.',',');

          if(countRows) {
            let params=this.getParams(true);
            this.count$=this.TrucksService.get(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,
                (error)=>console.error(error),
                ()=> this.afterCount.emit(this.queryHttpHelper.paginationProps.itemCount)
            );
          }
        });
  }
  getParams(countRows:boolean) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    params=params.append('plant','1');
    params=params.append('driver','1');
    if(this.view)
      params=params.append('view',this.view.toString());
    if(this.plant_id)
      params=params.append('plant_id',this.plant_id.toString());
    return params;
  }
  changePlant(event) {
    this.plant_id=parseInt(event.target.value);
    this.getData(true);
  }
  setView(view: number) {
    this.view=view;
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.showFilters = !this.showFilters;
    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
      this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');
    this.search='';
    this.getData(true);
  }
  filterChange(event) {
    // Delete capacity filter when another filter becomes active
    if(event.name!=='capacity') {
      this.queryHttpHelper.deleteFilterItem('capacity');
      this.table.resetColumnFilter('capacity');
    }
    // Delete other filters when capacity filter becomes active
    if(event.name==='capacity') {
      this.queryHttpHelper.clearFilters();
      this.table.resetColumnFilters();
    }

    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  insert() {
    this.truck=this.TrucksService.getEmpty();
    this.ModalService.open(this.modalTruck,'truck');
  }
  saved() {
    this.ModalService.close('truck');
    this.getData(true);
  }
  canceled() {
    this.ModalService.close('truck');
  }
  rowSelect(row) {
    this.edit(row.id);
  }
  actionClick(event) {
    this.rowSelect(event.row)
  }
  edit(truck_id: number) {
    this.TrucksService.getOne(truck_id).subscribe(
        (data)=>this.truck=data.data as Truck,(error)=>console.error(error),()=>{
          this.ModalService.open(this.modalTruck,'truck');
        }
    )
  }
  toggleActive( id: number, active: boolean) {
    this.TrucksService.updateActive(id, active).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.showToast();
          this.getData(true);
        }
    )
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
