import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMTableDisplayValue} from "../../../components/JBM/JBMTable/JBMTable.component";
import {ResourcePricelist} from "./interfaces/resource-pricelist";

const baseURL: string = 'financial/';
const slug = 'resource-pricelists/';

@Injectable({
  providedIn: 'root'
})
export class ResourcePricelistsService {
  resourcePricelist: ResourcePricelist;

  constructor(private HttpService: HttpService) {}

  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL + 'read/' + slug + language, params);
  }
  getUses(id: number) {
   return this.HttpService.get(baseURL+'read/resource-pricelist-uses/'+id.toString());
  }
  getDeletePermissions(ids: string[]) {
    return this.HttpService.get(baseURL+'read/resource-pricelists-delete-permissions/'+ids.join());
  }

  /* Get base pricelists */
  getBaseLists(language: string) {
    let params=new HttpParams().append('filter','rpl.type eq 0');
    return this.get(language, params);
  }

  /* Get non-base pricelists (discounts/additions or adjustments */
  getNonBaseLists(language: string) {
    let params=new HttpParams().append('filter', 'rpl.type gt 0');
    return this.get(language, params);
  }

  getOne(language: string, id: number) {
    let params=new HttpParams().append('id',id.toString());
    return this.get(language, params);
  }
  getEmpty() {
    this.resourcePricelist={
      id: 0,
      plants_id: null,
      description: '',
      type: -1
    }
    return this.resourcePricelist;
  }

  create(data: any) {
    return this.HttpService.post(baseURL + 'create/' + slug, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL + 'update/' + slug, data);
  }
  copy(id: number, customer_id: number, data: any) {
    return this.HttpService.post(baseURL + 'create/' + slug +'copy/' + id.toString() + '/' + customer_id.toString(), data);
  }
  delete(id: number) {
    return this.HttpService.get(baseURL + 'delete/' + slug + id.toString());
  }

  // Pricelists options

  getSelectOptions( pricelists: any[], insertEmptyOption: boolean=true ): JBMSelectOption[] {
    let options: JBMSelectOption[]=insertEmptyOption ? [{key:'',value:''}] : [];
    for(let p=0; p<pricelists.length; p++) {
      let pricelist=pricelists[p];
      options.push( { key: pricelist.id.toString(), value: pricelist.description } );
    }
    return options;
  }

  // Pricelist type items

  getTypeItems(TranslateService: TranslateService) {
    return [
      {key: 0, value: TranslateService.GetTranslation('pricelist.type-base')},
      {key: 1, value: TranslateService.GetTranslation('pricelist.type-discount-addition')},
      {key: 2, value: TranslateService.GetTranslation('pricelist.type-overrule')}
    ];
  }

  getTypeSelectOptions(TranslateService: TranslateService): JBMSelectOption[] {
    let typeItems=this.getTypeItems(TranslateService);
    let options: JBMSelectOption[]=[];
    for(let ti=0; ti<typeItems.length; ti++)
      options.push({key: typeItems[ti].key.toString(), value: typeItems[ti].value});
    return options;
  }

  getTypeDisplayValues(TranslateService: TranslateService): JBMTableDisplayValue[] {
    let typeItems=this.getTypeItems(TranslateService);
    let values: JBMTableDisplayValue[]=[];
    for(let ti=0; ti<typeItems.length; ti++)
      values.push({dataValue: typeItems[ti].key, displayValue: typeItems[ti].value});
    return values;
  }

  getTypeAsString(type: number, TranslateService: TranslateService) {
    let typeItems=this.getTypeItems(TranslateService);
    if((type<0) || (type>=typeItems.length))
      return '';
    return typeItems[type].value;
  }
}
