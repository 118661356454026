import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../shared.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { CustomerDetailsComponent} from "./details/customer-details.component";
import {JBMViewModule} from "../../components/JBM/Views/jbmview.module";

@NgModule({
  declarations: [CustomerDetailsComponent],
	imports: [CommonModule, SharedModule, JBMButtonsModule, JBMViewModule],
  exports: [CustomerDetailsComponent]
})
export class SharedCustomersModule { }
