<form [formGroup]="form" (ngSubmit)="save()" class="plan-order clearfix pb-1">
    <div class="clearfix">
        {{'order.amount'|translate}} {{'financial.concrete'|translate|lowercase}}
        <span class="badge bg-secondary">{{order.amount}} {{'quantity.m3'|translate}}</span>
        <span class="mr-4"></span>{{'order.unloading-flow'|translate}}
        <span *ngIf="order.unloading_flow"><span class="badge bg-secondary">{{order.unloading_flow}} {{'quantity.m3'|translate}}</span></span>
        <span *ngIf="!order.unloading_flow"><span class="badge bg-secondary">{{'ui.not-available'|translate}}</span></span>
        <hr class="mt-0 mb-1" />
    </div>
    <div class="clearfix">
        <div class="float-start mr-4">
            <jbm-select [formControl]="formControls.plants_id" [options]="plantOptions" [id]="'plants_id'" labelCaption="{{'company.plant'|translate}}">
            </jbm-select>
        </div>
        <div class="float-start mr-4">
            <jbm-date-input [hidden]="saved" labelCaption="{{'order.production-date'|translate}}" [id]="'production_date'+order.id"
              [formControl]="formControls.production_date" [maxdate]="maxdate" [required]="true"
              placement="bottom">
                <jbm-input-error [formControl]="formControls.production_date" [submitted]="submitted">
                     <span *ngIf="formControls['production_date'].hasError('dateInvalid')">
                       {{'entity.dateformat'|translate}}
                     </span>
                </jbm-input-error>
            </jbm-date-input>
            <jbm-static-label [hidden]="!saved" [labelCaption]="'order.production-date'|translate" [value]="formControls.production_date.value"></jbm-static-label>
        </div>
        <div class="float-start mr-4">
            <jbm-time-input [hidden]="saved" [formControl]="formControls.starttime" [submitted]="submitted" [required]="true"
              [id]="'starttime'+order.id" labelCaption="{{'order.starttime'|translate}}"
              (onBlur)="calcEndTime(); calcLoadTime()">
                <jbm-input-error [formControl]="formControls.starttime" [submitted]="submitted">
                    {{'entity.timeformat'|translate}}
                </jbm-input-error>
            </jbm-time-input>
            <jbm-static-label [hidden]="!saved" [labelCaption]="'order.starttime'|translate" [value]="formControls.starttime.value"></jbm-static-label>
        </div>
        <div class="float-start mr-4">
            <jbm-static-label *ngIf="loadTime" [labelCaption]="'order.loadtime'|translate" [value]="loadTime"> {{'datetime.hour'|translate}}</jbm-static-label>
        </div>
        <div class="float-start mr-4">
            <jbm-static-label *ngIf="endTime" [labelCaption]="'order.endtime'|translate" [value]="endTime"> {{'datetime.hour'|translate}}</jbm-static-label>
        </div>
        <div class="toolbar float-end">
            <button [hidden]="saved" type="submit" class="btn btn-sm btn-primary" (click)="save();"><i class="fas fa-check"></i> {{'planning.plan'|translate}}</button>
            <button [hidden]="!saved" type="button" class="btn btn-sm btn-primary" (click)="edit();"><i class="far fa-edit"></i> {{'ui.edit'|translate}}</button>
            <div class="toolbar-spacer-1"></div>
            <jbm-confirm-delete-dropdown [hidden]="!saved" [subject]="'planning.remove'|translate" [placement]="'left'"
              (onConfirm)="confirmDelete(order.id)"></jbm-confirm-delete-dropdown>
            <jbm-cancel-button [hidden]="saved" (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
