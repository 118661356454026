import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'article-price-history-table',
  templateUrl: './ArticlePriceHistoryTable.component.html',
  styleUrls: ['./ArticlePriceHistoryTable.component.scss']
})
export class ArticlePriceHistoryTableComponent implements OnInit {
  constructor() {}

  @Input() price_id: number=0;
  @Input() data: any;

  ngOnInit(): void {
    if(this.data==undefined) this.data={ history: [], actualPrice: 0 };
  }
}
