import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {FormValidationService} from "../../../../services/validation/form.validation.service";
import {UserService} from "../../data/user.service";
import {User} from "../../data/interfaces/user";

@Component({
  selector: 'form-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit, AfterViewInit {
  passwordForm: FormGroup;
  isSubmitted: boolean=false;

  constructor(
      private FormBuilder: FormBuilder,
      private FormValidationService: FormValidationService,
      private UserService: UserService
  ) {
    this.addControls();
  }

  @Input() user: User;
  @Output() onChanged=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    document.getElementById('passwordNew').focus();
  }
  addControls() {
    this.passwordForm=this.FormBuilder.group(
        {
          passwordNew: new FormControl('',[this.FormValidationService.passwordValidator()]),
          passwordConfirm: new FormControl('',[this.FormValidationService.passwordValidator()])
        },
        { updateOn: "blur"}
    );
  }
  get formControls() {
    return this.passwordForm.controls;
  }
  change() {
    this.isSubmitted = true;

    if(this.passwordForm.invalid)
      return;

    if(this.formControls.passwordNew.value!=this.formControls.passwordConfirm.value) {
      this.formControls.passwordConfirm.setErrors({confirmInvalid: true});
      return;
    }

    // Store user
    this.user.password=this.formControls.passwordNew.value;

    this.UserService.update(this.user).subscribe(
        ()=>{},error =>console.error(error),()=>{})

    this.onChanged.emit();
  }
  cancel() {
    this.onCancel.emit();
  }
}
