<form [formGroup]="form" (ngSubmit)="save()" class="article" xmlns="http://www.w3.org/1999/html">
   <app-header>
      <jbm-view-title [caption]="caption"
        [parentLinkCaption]="'financial.articles'|translate"
        [parentLinkSlug]="'settings/financial/articles'">
      </jbm-view-title>
      <div class="toolbar float-end">
         <div class="action-buttons">
            <jbm-save-button subject="{{'financial.article'|translate}}" [small]="false" (click)="save()"></jbm-save-button>
            <jbm-cancel-button context="light" [small]="false" (click)="cancel()"></jbm-cancel-button>
         </div>
         <div class="toolbar-spacer-1"></div>
         <div class="btn-toolbar">
            <jbm-activity-log-button *ngIf="article.id" subject_type="11"
              [subject_id]="article.id" subject="{{'financial.article'|translate}}"
              [title]="article.name">
            </jbm-activity-log-button>
            <jbm-help-button></jbm-help-button>
         </div>
      </div>
   </app-header>
   <section class="module padding padding-top border-top">
      <jbm-text-input [formControl]="formControls.name" [submitted]="isSubmitted" [id]="'name'"
         required="true" [maxlength]="125" labelCaption="{{'entity.name'|translate}}" labelClass="width-8" class="mt-3 input-25">
        <jbm-input-error [formControl]="formControls.name" [submitted]="isSubmitted">
           <span *ngIf="formControls.name.hasError('name_not_unique')">{{'entity.name-not-unique'|translate}}</span>
           <span *ngIf="formControls.name.hasError('required')">{{'entity.requiredfield'|translate}}</span>
        </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [formControl]="formControls.description" [submitted]="isSubmitted" [id]="'description'"
          required="true" [maxlength]="125" class="mb-3" labelCaption="{{'entity.description'|translate}}" labelClass="width-8" class="input-25">
          <jbm-input-error [formControl]="formControls.description" [submitted]="isSubmitted">
              {{'entity.requiredfield'|translate}}
          </jbm-input-error>
      </jbm-text-input>
      <jbm-select [formControl]="formControls.articlegroups_id" [options]="articlegroup_options" [submitted]="isSubmitted"
        (change)="changeArticlegroup($event)" [id]="'articlegroups_id'" [insertLeadingOption]="true" required="true"
        class="mb-3" labelCaption="{{'financial.articlegroup'|translate}}" labelClass="width-8" class="input-20">
         <jbm-input-error [formControl]="formControls.articlegroups_id" [submitted]="isSubmitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-select>
      <jbm-select [formControl]="formControls.article_price_types_id" [options]="pricetype_options" [submitted]="isSubmitted"
        (change)="changePriceType($event)" [id]="'article_price_types_id'" [insertLeadingOption]="true" required="true"
        class="mb-3" labelCaption="{{'article.pricetype'|translate}}" labelClass="width-8" class="input-20">
        <jbm-input-error [formControl]="formControls.article_price_types_id" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-select>
      <jbm-check [formControl]="formControls.mandatory" [id]="'mandatory'" labelCaption="{{'ui.mandatory'|translate}}"
        labelClass="width-8" class="input-7 mt-1 mb-3" textChecked="{{'ui.yes'|translate}}" textUnchecked="{{'ui.no'|translate}}">
      </jbm-check>
      <jbm-text-input [formControl]="formControls.reference_id" [submitted]="isSubmitted" [id]="'reference_id'"
           [maxlength]="50" labelCaption="{{'article.reference-id'|translate}}" labelClass="width-8" class="input-20">
      </jbm-text-input>
      <fieldset class="width-34 mt-3"><legend>{{'financial.quotation-invoice'|translate}}</legend>
          <jbm-select [formControl]="formControls.display" [options]="displayOptions" [submitted]="isSubmitted"
              [id]="'display'" insertLeadingOption="true" required="true"
              labelCaption="{{'article.display'|translate}}" labelStacked="true" labelClass="width-8" class="input-31">
              <jbm-input-error [formControl]="formControls.display" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
              </jbm-input-error>
          </jbm-select>
      </fieldset>
   </section>
</form>
<jbm-toasts></jbm-toasts>
