import {Component, OnInit, OnDestroy} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ArticlePriceTypesService} from "../data/article-price-types.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {JBMTableColumnDef} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'article-price-types',
  templateUrl: './article_price_types.component.html',
  styleUrls: ['./article_price_types.component.scss']
})
export class ArticlePriceTypesComponent implements OnInit, OnDestroy {
  userRights: any;
  types: any[];
  columnDefs: JBMTableColumnDef[];
  language: string=this.TokenService.getLanguage();
  article_price_types_id: number;
  loading: boolean=false;

  types$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';

  createActive: boolean=false;
  code: string='';
  description: string='';
  codeError: string='';
  descriptionError: string='';
  codeTouched: boolean=false;
  descriptionTouched: boolean=false;
  editing: boolean=false;
  codeUnique:boolean=true;
  descriptionUnique:boolean=true;

  constructor(
      public ArticlePriceTypesService: ArticlePriceTypesService,
      public modalService: NgbModal,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('code');
    this.queryHttpHelper.addSearchColumn('code', SearchColumnType.string);

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let httpParams=this.queryHttpHelper.getHttpParams(false);
    this.types$=this.ArticlePriceTypesService.getData(this.language, httpParams).subscribe(
        (data: any) => this.types = data.data,()=>{},()=>{
          this.loading=false;

          // Set editmode to false for each item
          for(let type of this.types)
            type.editing=false;

          if(countRows) {
            let httpParams=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.ArticlePriceTypesService.getData(this.language, httpParams).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
    this.getData(false);
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let type of this.types)
      if(type.article_price_types_id===id) {
        if(type.editing) return false;  // Row alreay in edit mode
        type.editing=true;
      } else
        type.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.code=data.code;
    this.codeTouched=true;
    this.description=data.description;
    this.descriptionTouched=true;
    this.codeUnique=true;
    this.descriptionUnique=true;
  }
  cancelEditing() {
    for(let type of this.types)
      type.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData(false);
  }
  onChangeCode() {
    this.codeTouched=true;
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateCode() {
    let value=this.code.trim();
    if(!value) this.codeError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.descriptionError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    this.ArticlePriceTypesService.create(data).subscribe(()=>{},
       error => { this.handleError(error) },() => { this.afterSave() })
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.code==this.code && originalValues.description==this.description) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }
    let data=this.setItemData(originalValues.article_price_types_id);
    this.ArticlePriceTypesService.update(data).subscribe(
        ()=>{},error => { this.handleError(error) },
        () => { this.afterSave() })
  }
  getValues() {
    this.code=this.code.trim();
    this.description=this.description.trim();
  }
  getOriginalValues() {
    for(let type of this.types)
      if(type.editing)
        return type;
    return null;
  }
  setItemData(id) {
    return { l: { article_price_types_id: id, language: this.language, code: this.code, description: this.description} }
  }
  clearItemData() {
    this.code='';
    this.description='';
    this.codeTouched=false;
    this.descriptionTouched=false;
  }
  handleError(error) {
    this.codeUnique=true;
    this.descriptionUnique=true;

    if(error=='code_not_unique') {
      this.codeUnique=false;
      this.codeError=this.TranslateService.GetTranslation('entity.code-not-unique');
    }
    if(error=='description_not_unique') {
      this.descriptionUnique=false;
      this.descriptionError=this.TranslateService.GetTranslation('entity.description-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) this.createCancel();
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.types$!=undefined) this.types$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
