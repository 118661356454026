<div *ngFor="let row of items; index as i" class="order-details mb-3">
    <div class="view-section-title bg-light-darker bottom-blue clearfix">
        <div class="float-start mt-1">
            {{'project.order'|translate}}:
        </div>
        <div class="float-start fw-bold mt-1 ml-2">
            <span *ngIf="!row.description">{{row.worktype.description}}</span>
            <span *ngIf="row.description">{{row.description}}</span>
        </div>
        <div class="actions float-end align-right">
            <jbm-confirm-delete-dropdown *ngIf="!row.inProduction && editable && row.delivered==0" placement="top-right" (onConfirm)="deleteItem(row.id)"
              [subject]="('project.order'|translate)+' '+('ui.delete'|translate|lowercase)" [class]="'ml-4 float-end'">
            </jbm-confirm-delete-dropdown>
            <button [disabled]="!row.articles_count" type="button" tabindex="-1" class="btn btn-sm btn-primary ml-3"
              popoverClass="order-articles-list" container="body" placement="left" [ngbPopover]="popContent"
              [popoverTitle]="('project.order'|translate)+' '+('financial.articles'|translate|lowercase)">
                <i class="fa fa-info"></i> {{'financial.articles'|translate}}
                <span *ngIf="row.articles_count" class="badge bg-light text-dark">{{row.articles_count}}</span>
            </button>
            <ng-template #popContent>
                <order-articles-list [orderID]="row.id"></order-articles-list>
            </ng-template>
            <button *ngIf="row.production_date && editable && row.unloading_flow" class="btn btn-sm btn-primary ml-3"
              container="body" placement="top" ngbTooltip="{{'project.order'|translate}} {{'ui.copy'|translate|lowercase}}" (click)="copy(row)">
                <i class="far fa-copy"></i> {{'ui.copy'|translate}}
            </button>
            <button *ngIf="editable" class="btn btn-sm btn-primary ml-3"
              container="body" placement="top" ngbTooltip="{{'ui.edit-tooltip'|translate}}" (click)="edit(row.id)">
                <i class="far fa-edit"></i>
            </button>
        </div>
        <div class="float-end mt-1">
            <ngb-progressbar type="success" class="float-start mr-1" [value]="row.completed"></ngb-progressbar>
            <div class="progress-info">
                {{'order.amount'|translate}}: <span class="fw-bold mr-2">{{row.amount}}</span>
                <span *ngIf="row.delivered">
                    {{'order.delivered'|translate}}: <span class="fw-bold">{{row.delivered}}</span>
                </span>
            </div>
        </div>
        <div *ngIf="row.inProduction" class="float-end mt-1 mr-1">
            <ngb-progressbar type="warning" class="production float-start mr-1" [value]="100">
                <span class="text-dark">In productie</span>
            </ngb-progressbar>
        </div>
    </div>
    <jbm-comments-box [comments]="row.comments" [hideIfEmpty]="true" height="2.75"></jbm-comments-box>
    <div class="clearfix mt-2">
        <section class="worktype">
            <label class="top">{{'order.worktype'|translate}}</label>
            <label class="value">{{row.worktype.description}}</label>
        </section>
        <section class="recipe">
            <label class="top">{{'entity.recipe'|translate}}</label>
            <label *ngIf="!row.recipe" class="value">{{'order.recipe-unknown'|translate}}</label>
            <jbm-link-button *ngIf="row.recipe" [caption]="row.recipe.code" [class]="'mr-3'" (onClick)="showRecipe(row.recipe.id, row.concrete_specs.environment.description)"></jbm-link-button>
            <jbm-comments-box [comments]="row.recipe_comments" [hideIfEmpty]="true" [height]="2.75"></jbm-comments-box>
        </section>
        <section class="unloading-method">
            <label class="top">{{'order.unloading-method'|translate}}</label>
            <label class="value">{{row.unloading_method}}</label>
        </section>
        <section class="unloading-flow">
            <label class="top">{{'order.unloading-flow'|translate}}</label>
            <label class="value">{{row.unloading_flow}}</label>
        </section>
        <section *ngIf="row.production_date" class="production">
            <div class="float-end">
                <div class="float-start mr-3">
                    <label class="top">{{'datetime.start'|translate}} {{'order.production'|translate|lowercase}}</label>
                    <label class="value">{{row.production_date}} <i class="fa fa-clock-o"></i> {{row.starttime}}</label>
                </div>
                <div class="float-start mr-3">
                    <label class="top">{{'order.loadtime'|translate}}</label>
                    <label class="value">{{row.loadtime}}</label>
                </div>
                <div class="float-start">
                    <label class="top">{{'order.endtime'|translate}}</label>
                    <label class="value">{{row.endtime}}</label>
                </div>
            </div>
        </section>
    </div>
</div>
<jbm-empty-view [emptyView]="items.length==0"></jbm-empty-view>
<jbm-toasts></jbm-toasts>

<jbm-modal [id]="'amount'" [className]="'amount'"></jbm-modal>
<ng-template #modalAmount let-modal>
    <order-amount-form [order_id]="id" (onSave)="onSaveAmountDialog()" (onCancel)="onCancelAmountDialog()"></order-amount-form>
</ng-template>

<jbm-modal [id]="'copy'" [className]="'copy'"></jbm-modal>
<ng-template #modalCopy let-modal>
    <order-copy [order_id]="id" (onSave)="onSaveCopy()" (onCancel)="onCancelCopy()"></order-copy>
</ng-template>

<jbm-modal [id]="'recipe'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
    <recipe-details [recipe_id]="recipe_id" [environment]="environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>
