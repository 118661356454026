<app-header>
    <jbm-view-title [caption]="'financial.article-price-types'|translate"
        [itemcount]="queryHttpHelper.paginationProps.itemCount"
        [parentLinkCaption]="'menu.settings'|translate"
        [parentLinkSlug]="'settings'"></jbm-view-title>
    <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
    <div class="btn-group float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="header-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisble" [search]="search" [minlength]="2" (searchChange)="searchChange($event)"></jbm-searchbox>
        <div class="header-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module">
    <div class="table-responsive">
        <table class="table padding table-sm article_price_types" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
            <thead>
            <tr>
                <th [hidden]="true"></th>
                <th jbm-table-column class="code" [name]="'code'" [header]="'entity.code'|translate"
                    [sortable]="true" [sorted]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)" [width]="12"></th>
                <th jbm-table-column class="description" [name]="'description'" [header]="'entity.description'|translate"
                    [sortable]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)"></th>
                <th class="actions editable"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="createActive" class="edit-active">
                <th [hidden]="true"></th>
                <td><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="code"
                    (change)="onChangeCode()" [ngClass]="{'ng-valid':validateCode() && codeUnique, 'is-invalid':codeTouched && (!validateCode() || !codeUnique) }">
                    <div *ngIf="codeError" class="invalid-feedback">{{codeError}}</div>
                </td>
                <td><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="description"
                    (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && descriptionUnique, 'is-invalid':this.descriptionTouched && (!validateDescription() || !descriptionUnique) }">
                    <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
                </td>
                <td class="editable actions">
                    <button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!(validateCode() && validateDescription())" (click)="createItem()">{{'ui.save'|translate}}</button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
                </td>
            </tr>
            <tr *ngFor="let item of types" [ngClass]="{'edit-active': item.editing}" (click)="startEditing(item.article_price_types_id)">
                <td [hidden]="true"></td>
                <td *ngIf="!item.editing" class="code">{{item.code}}</td>
                <td *ngIf="!item.editing">{{item.description}}</td>
                <td *ngIf="!item.editing" class="actions"><button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm float-end"><i class="far fa-edit"></i></button></td>
                <td *ngIf="item.editing" class="editable code">
                    <input class="form-control form-control-sm" type="text" autocomplete="off"
                       [(ngModel)]="code" [placeholder]="item.code" (change)="onChangeCode()" [ngClass]="{'ng-valid':this.validateCode() && codeUnique, 'is-invalid':!this.validateCode() || !codeUnique }">
                    <div *ngIf="codeError" class="invalid-feedback">{{codeError}}</div>
                </td>
                <td *ngIf="item.editing" class="editable">
                    <input class="form-control form-control-sm" type="text" autocomplete="off"
                           [(ngModel)]="description" [placeholder]="item.description" (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && descriptionUnique, 'is-invalid':!validateDescription() || !descriptionUnique }">
                    <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
                </td>
                <td *ngIf="item.editing" class="editable actions">
                    <button type="button" class="btn btn-sm btn-primary mr-1"
                      [disabled]="!(validateCode() && validateDescription())" (click)="updateItem()">{{'ui.save'|translate}}</button>
                    <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
    <jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
</section>
<jbm-toasts></jbm-toasts>
