<table class="userrights table table-hover table-striped">
    <thead>
    <tr>
        <th>{{'userrights.right'|translate}}</th>
        <th class="right">{{'userrights.create'|translate}}</th>
        <th class="right">{{'userrights.read'|translate}}</th>
        <th class="right">{{'userrights.update'|translate}}</th>
        <th class="right">{{'userrights.delete'|translate}}</th>
    </tr>
    </thead>
    <tbody>
        <tr *ngFor="let rights of groupRights">
            <td>{{rights.description}}</td>
            <td class="right"><input id="create_{{rights.id}}" type="checkbox" [checked]="rights.allow_create" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
            <td class="right"><input id="read_{{rights.id}}" type="checkbox" [checked]="rights.allow_read" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
            <td class="right"><input id="update_{{rights.id}}" type="checkbox" [checked]="rights.allow_update" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
            <td class="right"><input id="delete_{{rights.id}}" type="checkbox" [checked]="rights.allow_delete" [disabled]="!userRights.AllowUpdate" (click)="changeRight($event)"></td>
        </tr>
    </tbody>
</table>
<jbm-toasts></jbm-toasts>
