import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {TranslateService} from "../../../../services/multilingual/translate.service"

@Component({
  selector: 'jbm-save-button',
  templateUrl: './JBMSaveButton.component.html',
  styleUrls: ['./JBMSaveButton.component.scss'],
  host: {'class': 'JBMButton'}
})

export class JBMSaveButtonComponent implements OnInit, OnChanges {
  language: string='';
  caption: string='';
  tooltip: string='';
  clickedOnce: boolean=false;

  @Input() subject: string='';
  @Input() showIcon: boolean=true;
  @Input() showCaption: boolean=true;
  @Input() class: string='primary';
  @Input() small: boolean=true;
  @Input() tooltipPlacement: string='bottom';
  @Input() disabled: boolean=false;
  @Input() onlyOnceClickable: boolean=true;

  @Output() click = new EventEmitter();

  constructor(private TokenService: TokenService, private TranslateService: TranslateService) {
    this.language=this.TokenService.getLanguage();
  }
  ngOnInit() {}

  ngOnChanges() {
    switch(this.language) {
      case 'en':
        this.caption=this.TranslateService.GetTranslation('ui.save');
        if(this.subject) this.caption+=' '+this.subject.toLowerCase();
        break;
      default:
        let save=this.TranslateService.GetTranslation('ui.save');
        if(this.subject) save=save.toLowerCase();
        this.caption=this.subject+' '+save;
        break;
    }
    if(!this.showCaption)
      this.tooltip=this.caption;
  }
  onBtnClick() {
    if(this.onlyOnceClickable && !this.clickedOnce) {
      // Prevents double click
      this.clickedOnce=true;
      this.click.emit();
    } else
      this.click.emit();
  }
}
