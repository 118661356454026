import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../shared/shared.module";
import { KtdGridModule } from '@katoid/angular-grid-layout';
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
    imports: [CommonModule, DashboardRoutingModule, SharedModule, KtdGridModule, JBMButtonsModule, JBMViewModule, LayoutModule],
  declarations: [DashboardComponent]
})
export class DashboardModule { }
