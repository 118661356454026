import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {Receipt} from "../../production/data/interfaces/receipt";

const baseURL: string = 'planning/';
const segment: string = 'instant-production-orders/';

@Injectable({
  providedIn: 'root'
})
export class ProductionReceiptsService {
  receipt: Receipt;

  constructor(
      private HttpService: HttpService,
  ) {}

  getEmpty() {
    this.receipt = {
      id: 0,
      plants_id: 0,
      loading_time: 0,
      leave_plant_time: 0,
      comment: '',
      amount: 0,
      receipt_amount: 0,
      trucks_id: 0,
      drivers_id: 0,
      orders_id: 0,
      execute_date: 0
    }
    return this.receipt;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update'+segment, data);
  }
}
