<app-header>
    <jbm-view-title
      [caption]="'entity.resources'|translate"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'">
    </jbm-view-title>
    <div class="toolbar float-end">
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module resource-types pl-4 pr-4">
    <nav #nav="ngbNav" [(activeId)]="activeTab" ngbNav class="nav-tabs module bg-light">
        <ng-container *ngFor="let type of types; let i=index" ngbNavItem [ngbNavItem]="i">
            <a ngbNavLink>{{type.description}}</a>
            <ng-template ngbNavContent>
                <resources [type_id]="type.id" [userRights]="userRights"></resources>
            </ng-template>
        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>
<jbm-toasts></jbm-toasts>
