import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {ContactsService, roles, subjects} from "../../data/contacts.service";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {Contact} from "../../data/interfaces/contact";
import {FormControl, FormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent implements OnInit {
  action: CRUDAction=CRUDAction.Create;
  contact: Contact=this.ContactsService.getEmpty();
  form: FormGroup;
  submitted: boolean=false;

  @Input() id: number=0;
  @Input() subject: number;
  @Input() subject_id: number;
  @Input() roles_id: number;
  @Input() role: string='';
  @Input() nameRequired: boolean=false;
  @Input() phoneRequired: boolean=false;
  @Input() emailRequired: boolean=false;

  @Output() onSave  =new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
      private FormBuilder: FormBuilder,
      private ContactsService: ContactsService
  ) {
    this.addControls();
  }
  ngOnInit(): void {
    if(!this.id) {
      this.setControlValues();
      return;
    }
    this.ContactsService.getById(this.id).subscribe(
        (data)=>this.contact=data.data as Contact,
        (error)=>console.error(error),
        ()=> {
          this.action=CRUDAction.Update;

          this.setControlValues();
        }
    )
  }
  addControls() {
    this.form = this.FormBuilder.group({
      firstname: new FormControl(''),
      lastname: new FormControl(''),
      phone: new FormControl(''),
      email: new FormControl('',[Validators.email]),
    }, { updateOn: "blur" } );

    if(this.nameRequired) {
      this.form.controls.firstname.setValidators([Validators.required, Validators.minLength(2)]);
      this.form.controls.lastname.setValidators([Validators.required, Validators.minLength(2)]);
    }
    if(this.phoneRequired)
      this.form.controls.phone.setValidators([Validators.required,Validators.minLength(10)]);
    if(this.emailRequired)
      this.form.controls.email.setValidators([Validators.required,Validators.email]);
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.firstname.setValue(this.contact.firstname);
    this.form.controls.lastname.setValue(this.contact.lastname);
    this.form.controls.phone.setValue(this.contact.phone);
    this.form.controls.email.setValue(this.contact.email);
  }
  save() {
    this.submitted = true;
    if(this.form.invalid)
      return;

    let data = {
      id: this.contact.id,
      subject: this.subject,
      subject_id: this.subject_id,
      roles_id: this.roles_id,
      firstname: this.formControls.firstname.value,
      lastname: this.formControls.lastname.value,
      phone: this.formControls.phone.value,
      email: this.formControls.email.value
    }
    this.ContactsService.update(data).subscribe(
        ()=>this.onSave.emit(),
        (error)=>{
          console.error(error);
          this.onSave.emit()
        }
    )
  }
  cancel() {
    this.onCancel.emit();
  }
}
