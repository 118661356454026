import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../../shared/shared.module";
import { JBMPriceRulesBreakdownPopoverComponent} from "./PriceRulesBreakdowns/JBMPriceRulesBreakdownPopover/JBMPriceRulesBreakdownPopover.component";
import { JBMArticlePriceRulesBreakdownPopoverComponent} from "./PriceRulesBreakdowns/JBMArticlePriceRulesBreakdownPopover/JBMArticlePriceRulesBreakdownPopover.component";
import { JBMResourcePriceRulesBreakdownPopoverComponent} from "./PriceRulesBreakdowns/JBMResourcePriceRulesBreakdownPopover/JBMResourcePriceRulesBreakdownPopover.component";
import { JBMConcretePriceRulesBreakdownPopoverComponent} from "./PriceRulesBreakdowns/JBMConcretePriceRulesBreakdownPopover/JBMConcretePriceRulesBreakdownPopover.component";
import { JBMConcreteBasePriceRulesBreakdownPopoverComponent} from "./PriceRulesBreakdowns/JBMConcreteBasePriceRulesBreakdownPopover/JBMConcreteBasePriceRulesBreakdownPopover.component";
import { JBMPriceRulesTableComponent } from './PriceRulesBreakdowns/JBMPriceRulesTable/JBMPriceRulesTable.component';
import { JBMColorPalettePickerComponent } from './JBMColorPalettePicker/JBMColorPalettePicker.component';

@NgModule({
  declarations: [
    JBMPriceRulesBreakdownPopoverComponent,
    JBMArticlePriceRulesBreakdownPopoverComponent,
    JBMResourcePriceRulesBreakdownPopoverComponent,
    JBMConcretePriceRulesBreakdownPopoverComponent,
    JBMConcreteBasePriceRulesBreakdownPopoverComponent,
    JBMPriceRulesTableComponent,
    JBMColorPalettePickerComponent
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    JBMPriceRulesBreakdownPopoverComponent,
    JBMArticlePriceRulesBreakdownPopoverComponent,
    JBMResourcePriceRulesBreakdownPopoverComponent,
    JBMConcretePriceRulesBreakdownPopoverComponent,
    JBMConcreteBasePriceRulesBreakdownPopoverComponent,
    JBMPriceRulesTableComponent,
    JBMColorPalettePickerComponent
  ]
})
export class JBMPopoversModule { }
