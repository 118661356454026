import {Component, Input, OnInit} from '@angular/core';
import {ContactsService, roles, subjects} from "../../contacts/data/contacts.service";
import {Contact} from "../../contacts/data/interfaces/contact";

@Component({
  selector: 'quotation-contacts',
  templateUrl: './quotation-contacts.component.html',
  styleUrls: ['./quotation-contacts.component.scss']
})
export class QuotationContactsComponent {
  contactPerson: Contact=this.ContactsService.getEmpty();
  siteManager: Contact=this.ContactsService.getEmpty();
  loaded: boolean=false;

  constructor(
      private ContactsService: ContactsService
  ) { }

  @Input() quotation_id: number=0;
  @Input() project_id: number=0;
  @Input() caption: string='';
  @Input() class: string='';

  toggle() {
    if(!this.loaded)
      this.loadContacts();
  }
  loadContacts() {
    this.ContactsService.getOne(subjects.quotation, this.quotation_id, roles.contact_person).subscribe(
        (data)=>this.contactPerson=data.data as Contact,
        (error)=>console.error(error),
        ()=>this.loaded=true
    )
    this.ContactsService.getOne(subjects.project, this.project_id, roles.site_manager).subscribe(
        (data)=>this.siteManager=data.data as Contact,
        (error)=>console.error(error),
        ()=>this.loaded=true
    )
  }
}
