import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams, HttpBackend } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment} from "../../environments/environment";

const APIUrl=environment.APIUrl;
const company=environment.company;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  private httpWithoutInterceptor: HttpClient;

  constructor( private http: HttpClient, private httpBackend: HttpBackend ) {
    this.httpWithoutInterceptor = new HttpClient(httpBackend);
  }
  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let headers=new HttpHeaders();
    headers=headers.append('company', company);
    return this.http.get(`${APIUrl+path}`,{ headers: headers, params, 'responseType': 'json' } )
        .pipe(catchError(this.formatErrors));
  }
  getAsPromise(path: string, params: HttpParams = new HttpParams()): Promise<any> {
    let headers=new HttpHeaders();
    headers=headers.append('company', company);
    return this.http.get(`${APIUrl+path}`,{ headers: headers, params, 'responseType': 'json' } )
        .pipe(catchError(this.formatErrors)).toPromise();
  }
  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(`${APIUrl+path}`, JSON.stringify(body) ).
      pipe(catchError(this.formatErrors));
  }

  post(path: string, body: Object = {}, options: Object = {}): Observable<any> {
    return this.http.post(`${APIUrl+path}`, body, options ).pipe(catchError(this.formatErrors));
  }

  delete(path): Observable<any> {
    return this.http.delete(`${APIUrl+path}` ).pipe(catchError(this.formatErrors));
  }

  _get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    let headers=new HttpHeaders();
    headers=headers.append('company', company);
    return this.httpWithoutInterceptor.get(`${APIUrl+path}`,{ headers: headers, params })
        .pipe(catchError(this.formatErrors));
  }

  _put(path: string, body: Object = {}): Observable<any> {
    return this.httpWithoutInterceptor.put(`${APIUrl+path}`, JSON.stringify(body)).pipe(catchError(this.formatErrors));
  }

  _postResponseText(path: string, body: Object = {} ): Observable<any> {
    return this.httpWithoutInterceptor.post(`${APIUrl+path}`, JSON.stringify(body),
      { headers: this.preparePOSTHeaders(), responseType: 'text' }).
      pipe(catchError(this.formatErrors));
  }
  _postResponseJSON(path: string, body: Object = {} ): Observable<any> {
    return this.httpWithoutInterceptor.post(`${APIUrl+path}`, JSON.stringify(body),
      { headers: this.preparePOSTHeaders(), responseType: 'json' }).pipe(catchError(this.formatErrors));
  }
  preparePOSTHeaders(): HttpHeaders {
    let headers=new HttpHeaders();
    headers=headers.append('company', company);
    headers=headers.append('Content-Type', 'application/json');
    return headers;
  }
  _delete(path): Observable<any> {
    return this.httpWithoutInterceptor.delete(`${APIUrl+path}`).pipe(catchError(this.formatErrors));
  }
  private formatErrors(error: any) { return throwError(error.error); }
}
