import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProjectState} from "../../../../features/projects/data/interfaces/projectstate";

@Component({
  selector: 'jbm-projectstate-dropdown',
  templateUrl: './JBMProjectStateDropdown.component.html',
  styleUrls: ['./JBMProjectStateDropdown.component.scss']
})
export class JBMProjectStateDropdownComponent implements OnInit {

  @Input() id: number;
  @Input() projectstate: any;
  @Input() projectstates: ProjectState[];

  @Output() projectStateChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  setProjectstate(id) {
    this.id=id;
    this.projectStateChanged.emit(id);
  }
}
