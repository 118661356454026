import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'financial/';
const slug: string = 'price-usage/';

export const price_usage_subjects = {
  order_concrete: 0,
  order_article: 1,
  order_resource: 2,
  quotation_item_concrete: 3,
  quotation_article: 4,
  invoice_concrete: 5,
  invoice_article: 6,
  invoice_resource: 7
}

@Injectable({
  providedIn: 'root'
})
export class PriceUsagesService {

  constructor(private HttpService: HttpService) { }

  get(subject: number, subject_id: number) {
    return this.HttpService.get(baseURL+'read/'+slug+subject.toString()+'/'+subject_id.toString());
  }
}
