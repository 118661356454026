import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {QuotationFlowsService} from "../data/quotation-flows.service";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'quotation-states-flow',
  templateUrl: './quotation-states-flow.component.html',
  styleUrls: ['./quotation-states-flow.component.scss']
})
export class QuotationStatesFlowComponent implements OnInit, OnChanges {
  items: any[];
  editID: number;
  comments: string;

  constructor(private QuotationFlowsService: QuotationFlowsService) { }

  @Input() revision_id: number;
  @Input() language: string;
  @Input() quotationNumber: string;

  @Output() onClose=new EventEmitter();

  ngOnInit(): void {
    this.items=[];
    this.editID=-1;
    if(this.revision_id==undefined) this.revision_id=0;
    if(this.language==undefined) this.language='';
    if(this.quotationNumber==undefined) this.quotationNumber='';
  }
  ngOnChanges() {
    let params=new HttpParams().append('quotation_states','1');
    this.QuotationFlowsService.get(this.language, this.revision_id, params).subscribe(
        (data)=>this.items=data,
        (error)=>console.error(error)
    )
  }
  toggleEditing(id: number, comments: string) {
    this.editID=this.editID>0 ? -1 : id;
    if(this.editID>-1) {
      this.comments=comments;
      setTimeout(() =>
          document.getElementById('comments-' + this.editID.toString()).focus(), 100);
    }
  }
  save(id: number) {
    // Store quotation flow comment
    let data={id: id, comments: this.comments}
    this.QuotationFlowsService.update(data).subscribe(
        ()=>{},error=>console.error(error),()=> {
          // Reset editing
          this.editID = -1;
          // Update items
          for(let i=0; i<this.items.length; i++)
            if(this.items[i].id===id)
              this.items[i].comments=this.comments;
        }
    );
  }
  close() {
    this.onClose.emit();
  }
}
