import {Component, EventEmitter, Input, OnInit, Output, AfterViewInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {ArticlesService } from "../../data/articles.service";
import {ArticlePricelistsService } from "../../data/article-pricelists.service";
import {ArticlePricesService } from "../../data/article-prices.service";
import {PriceService } from "../../../../services/price/price.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {JBMPropertiesListItem } from "../../../../components/JBM/Views/JBMPropertiesList/JBMPropertiesList.component";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";

@Component({
  selector: 'form-article-price',
  templateUrl: './article_price.component.html',
  styleUrls: ['./article_price.component.scss']
})

export class ArticlePriceComponent implements OnInit {
  action: CRUDAction;
  data: any;

  @Input() article_pricelist: any;
  @Input() userRights: any;
  @Input() id: number;
  @Input() details: any;
  @Input() articles_id: number;
  @Input() language: string;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
    private formBuilder: FormBuilder,
    private ArticlePricesService: ArticlePricesService,
    private ArticlePricelistsService: ArticlePricelistsService,
    private ArticlesService: ArticlesService,
    private PriceService: PriceService,
    private TranslateService: TranslateService
  ) {}

  form: FormGroup;
  isSubmitted: boolean=false;
  priceListProps: JBMPropertiesListItem[]=[];
  articleProps: JBMPropertiesListItem[]=[];
  selectList: boolean=false;

  ngOnInit(): void {
    if(this.id==undefined) this.id=0;
    if(this.articles_id==undefined) this.articles_id=0;
    if(this.data==undefined) this.data={ articles_id: 0 };
    if(this.details==undefined) this.details={ name: '', pricetype: '' };

    this.addControls();
    this.initData();
  }
  ngAfterViewInit(): void {
    document.getElementById('price').focus();
  }
  initData() {
    // Set pricelist properties
    let type=this.ArticlePricelistsService.getTypeAsString(this.article_pricelist.type, this.TranslateService);
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('financial.article-pricelist'), value: this.article_pricelist.description });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'), value: type });

    if(this.id) {
      this.setArticleDetails();

      this.ArticlePricesService.getOne(this.id, this.language).subscribe(
          (data: any) => this.data=data.data[0],(error) => console.error(error),
          ()=> this.setControlValues());
    } else {
      this.selectList=true;

      this.ArticlePricesService.getEmpty().subscribe(
          (data: any) => this.data=data,() => {},
          ()=>this.setControlValues());
    }
  }
  setArticleDetails() {
    // Set article properties
    this.articleProps=[];
    this.articleProps.push({label: this.TranslateService.GetTranslation('financial.article'), value: this.details.name});
    this.articleProps.push({label: this.TranslateService.GetTranslation('article.pricetype'), value: this.details.pricetype});
  }
  addControls() {
    let negativeAllowed=this.article_pricelist.type==1;
    this.form = this.formBuilder.group({
      price: new FormControl('0.0',[this.PriceService.priceValidator(this.PriceService, negativeAllowed)]),
    }, { updateOn: "blur" } );
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.price.setValue( this.PriceService.formatPrice(this.data.price));
  }
  showSelectList() {
    this.selectList=true;
  }

  selectArticle(article) {
    this.data.articles_id=article.id;
    this.ArticlesService.getOne(article.id, this.language).subscribe(
        (data: any) => {
            this.data.articles_id=data.data.id;
            this.details.name=data.data.name;
            this.details.pricetype=data.data.price_type_code; },() => {},
        ()=> {
            this.setArticleDetails();
            this.selectList=false;
        }
    );
  }
  save() {
    this.isSubmitted = true;
    if(this.form.invalid)
      return;

    if(this.data.articles_id===0)
      return;

    let data ={
      id: this.id,
      articles_id: this.data.articles_id,
      article_pricelists_id: this.article_pricelist.id,
      price: this.PriceService.getPriceFromStr( this.formControls.price.value )
    }

    if(data.id==0) {
      this.ArticlePricesService.create(data).subscribe(
          data => this.data=data,error => { console.error(error) },
          () => this.onSave.emit())
    } else {
      this.ArticlePricesService.update(data).subscribe(
          data => this.data=data,error => { console.error(error) },
          () => this.onSave.emit())
    }
  }
  cancel() {
    this.onCancel.emit();
  }
}