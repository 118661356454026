<app-header>
   <jbm-view-title [caption]="('entity.email'|translate) + ' ' + ('presentations.template'|translate|lowercase) + ' ' + ('ui.editor'|translate|lowercase)"
     [parentLinkCaption]="('entity.presentation'|translate) + ' ' + ('presentations.templates'|translate|lowercase)"
     [parentLinkSlug]="'settings/presentations'">
   </jbm-view-title>
   <div class="toolbar float-end">
      <jbm-toggle-button *ngIf="activeEditorSection==2" caption="{{'ui.preview'|translate}}" [toggled]="showPreview" (onToggle)="togglePreview()"></jbm-toggle-button>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-save-button [small]="false" [subject]="'presentations.template'|translate" (click)="save()"></jbm-save-button>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-cancel-button [small]="false" context="light" (click)="cancel()"></jbm-cancel-button>
      <div class="toolbar-spacer-2 float-end"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module overflow-y border-top template-editor">
   <as-split direction="horizontal" (dragEnd)="splitterDragEnd('percent', $event)">
      <as-split-area [size]="panel_primary_width" [minSize]="30">
         <div class="content-primary code-editor">
            <div class="editor-container">
               <div class="editor-header bg-light">
                  <h5>{{presentation.description}}</h5>
               </div>
               <ngb-accordion #acc="ngbAccordion" [closeOthers]="true" activeIds="panel-1" (panelChange)="editorSectionChange($event)">
                  <ngb-panel [id]="'panel-1'">
                     <ng-template ngbPanelTitle>
                        <div class="float-start pt-1 pl-2">{{'template-editor.mail-subject'|translate}}</div>
                        <button *ngIf="activeEditorSection==1 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                          (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}...</button>
                     </ng-template>
                     <ng-template ngbPanelContent>
                        <ngx-codemirror #editorSubject [name]="'subject'" [id]="'subject'" [(ngModel)]="subjectStr"
                          [options]="editorTextOptions" (ngModelChange)="onEditorChange($event,'subject')"></ngx-codemirror>
                     </ng-template>
                  </ngb-panel>
                  <ngb-panel [id]="'panel-2'">
                     <ng-template ngbPanelTitle>
                        <div class="float-start pt-1 pl-2">{{'template-editor.html-body'|translate}}</div>
                        <button *ngIf="activeEditorSection==2 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                          (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                     </ng-template>
                     <ng-template ngbPanelContent>
                        <ngx-codemirror #editorHTMLMessage [name]="'message'" [id]="'message'" [(ngModel)]="HTMLMessageStr"
                          [options]="editorHTMLOptions" (ngModelChange)="onEditorChange($event,'html-message')"></ngx-codemirror>
                     </ng-template>
                  </ngb-panel>
                  <ngb-panel [id]="'panel-3'">
                     <ng-template ngbPanelTitle>
                        <div class="float-start pt-1 pl-2">{{'template-editor.text-body'|translate}}</div>
                        <button *ngIf="activeEditorSection==3 && !showPlaceholdersPicker" type="button" class="btn btn-primary btn-sm ml-3"
                          (click)="insertPlaceholders(activeEditorSection);$event.stopPropagation()">{{'template-editor.insert'|translate}}</button>
                     </ng-template>
                     <ng-template ngbPanelContent>
                        <ngx-codemirror #editorTextMessage [name]="'message'" [id]="'message'" [(ngModel)]="TextMessageStr"
                          [options]="editorTextOptions" (ngModelChange)="onEditorChange($event,'text-message')"></ngx-codemirror>
                     </ng-template>
                  </ngb-panel>
               </ngb-accordion>
            </div>
         </div>
      </as-split-area>
      <as-split-area [size]="panel_secondary_width" [minSize]="40" [visible]="showPreview">
         <div class="content-secondary mail-viewer-container">
            <mail-viewer [base64]="base64"></mail-viewer>
         </div>
      </as-split-area>
   </as-split>
</section>
<template-placeholder-picker [hidden]="!showPlaceholdersPicker" [language]="language" [placeholders]="placeholders"
  (onDataPlacement)="onDataPlacement($event)" (onTextPlacement)="onTextPlacement($event)" [pickImages]="pickImages" (onImagePlacement)="onImagePlacement($event)"
  (onClose)="closePlaceholderPicker()"></template-placeholder-picker>
<jbm-toasts></jbm-toasts>
