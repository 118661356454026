<form [formGroup]="form" (ngSubmit)="save()" class="approve">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'entity.invoice'|translate}} {{'entity.comments'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label [labelCaption]="'invoice.number'|translate" [value]="invoiceNumber"></jbm-static-label>
      <div class="row">
         <div class="col-md-6 mr-2">
            <jbm-static-label [labelCaption]="'entity.recipe'|translate" [value]="invoice_item.description"></jbm-static-label>
            <jbm-textarea [id]="'comments'" [formControl]="formControls.recipe_internal_comments" labelStacked="true" class="lg"
              [labelCaption]="('entity.comments'|translate)+' '+('ui.internal'|translate|lowercase)"></jbm-textarea>
            <jbm-textarea [id]="'comments'" [formControl]="formControls.recipe_external_comments" labelStacked="true" class="lg"
              [labelCaption]="('entity.comments'|translate)+' '+('ui.external'|translate|lowercase)"></jbm-textarea>
         </div>
         <div class="col-md-6 ml-2">
            <jbm-static-label [labelCaption]="'project.order'|translate" [value]="invoice_item.concretes[0].order.description"></jbm-static-label>
            <jbm-textarea [id]="'comments'" [formControl]="formControls.order_internal_comments" labelStacked="true" class="lg"
              [labelCaption]="('entity.comments'|translate)+' '+('ui.internal'|translate|lowercase)"></jbm-textarea>
            <jbm-textarea [id]="'comments'" [formControl]="formControls.order_external_comments" labelStacked="true" class="lg"
              [labelCaption]="('entity.comments'|translate)+' '+('ui.external'|translate|lowercase)"></jbm-textarea>
         </div>
      </div>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-sm btn-primary" (click)="save()">{{'entity.comments'|translate}} {{'ui.save'|translate|lowercase}}</button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
