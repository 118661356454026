import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'quotations/';
const segment: string = 'attachments/';

@Injectable({
  providedIn: 'root'
})
export class QuotationAttachmentsService {

  constructor(
      private HttpService: HttpService,
  ) {}
  check(quotation_id: number) {
    return this.HttpService.get(baseURL+'read/'+segment+'check/'+quotation_id.toString(),null );
  }
  get(quotation_id: number) {
    return this.HttpService.get(baseURL+'read/'+segment+quotation_id.toString(),null );
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  remove(id: number ) {
    return this.HttpService.delete(baseURL+'delete/'+segment+id.toString());
  }
}
