<form [formGroup]="form" (ngSubmit)="save()" class="article-price">
   <div class="jbm-modal-header">
      <jbm-crud-title subject="financial.article-price" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-activity-log-button *ngIf="id" subject_type="14"
           [subject_id]="id" subject="{{'financial.article-price'|translate}}"
           [title]="details.name">
         </jbm-activity-log-button>
         <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-properties-list className="article-pricelist" [properties]="priceListProps"></jbm-properties-list>
      <jbm-properties-list className="article" [properties]="articleProps"></jbm-properties-list>
      <div *ngIf="id" class="jbm-properties-list article">
         <div class="item">
            <label class="label"></label>
            <label class="value">
               <article-price-history-dropdown [subject]="0" [price_id]="id"></article-price-history-dropdown>
            </label>
         </div>
      </div>
      <ngb-alert *ngIf="isSubmitted && data.articles_id==0" type="danger" class="mb-0" [dismissible]="false">{{'articles.select-article'|translate}}</ngb-alert>
      <button *ngIf="id==0 && !selectList" class="btn btn-secondary" (click)="showSelectList()">{{'articles.select-article'|translate}}</button>
      <article-selectlist *ngIf="selectList" [article_pricelists_id]="article_pricelist.id" [caption]="'articles.select-article'|translate" (articleSelected)="selectArticle($event)"></article-selectlist>
      <hr />
      <jbm-float-input-group [formControl]="formControls.price" [submitted]="isSubmitted" [id]="'price'" required="true"
        labelCaption="{{'article.price'|translate}}" labelClass="width-7" class="input-8" prepend="&euro;">
          <jbm-input-error [formControl]="formControls.price" [submitted]="isSubmitted">
              <span *ngIf="formControls['price'].hasError('priceInvalid')">
                        {{'entity.priceformat'|translate}}</span>
          </jbm-input-error>
      </jbm-float-input-group>
    </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'financial.article-price'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>