import { Injectable } from '@angular/core';
import { TranslateService } from "../../../services/multilingual/translate.service";
import { presenterConfig } from "../../../config/presenter"
import { PriceService} from "../../../services/price/price.service";
import {UnloadingMethod} from "../data/interfaces/unloading-method";

@Injectable({
  providedIn: 'root'
})
export class OrderPresenter {
  m3: string;
  hour: string;

  constructor( private TranslateService: TranslateService, private PriceService: PriceService) {
    this.m3=this.TranslateService.GetTranslation('quantity.m3');
    this.hour=this.TranslateService.GetTranslation('datetime.hour');
  }

  format( order: any) {
    order.recipe_unknown='';
    if(order.recipe_environment===null)
      order.recipe_unknown=this.TranslateService.GetTranslation('order.recipe-unknown' );
    order.concrete_priceStr='€ '+this.PriceService.formatPrice(order.concrete_price);
    if(order.amount===null || order.amount===0)
        order.amount=presenterConfig.emptyString;
      else
        order.amount=order.amount.toString().replace('.',',')+' '+this.m3;
    if(order.delivered===null || order.delivered===0)
      order.delivered=0;//presenterConfig.emptyString;
    else
      order.delivered=order.delivered.toString().replace('.',',')+' '+this.m3;
    order.unloading_method=this.formatUnloadingMethod(order.unloading_method as UnloadingMethod);

    if(order.unloading_flow===null || order.unloading_flow===0)
      order.unloading_flow=presenterConfig.emptyString;
    else
      order.unloading_flow=order.unloading_flow+' '+this.m3+'/'+this.hour;

    if(order.loadtime!==null)
      order.loadtime=order.loadtime+' '+this.hour;
    else
      order.loadtime='';
    if(order.starttime!==null)
      order.starttime=order.starttime+' '+this.hour;
    else
      order.starttime='';
    if(order.hasOwnProperty('endtime'))
      order.endtime=order.endtime+' '+this.hour;
    else
      order.endtime='';
    return order;
  }
  formatUnloadingMethod(unloading_method: UnloadingMethod) {
    let speed=unloading_method.speed.toString().replace('.',',');
    return unloading_method.description+' ('+speed+' '+this.m3+'/'+this.hour+')';
  }
}
