<form [formGroup]="userForm" (ngSubmit)="save()" class="user">
    <div class="jbm-modal-header">
        <jbm-crud-title subject="entity.user" [action]="action"></jbm-crud-title>
        <div class="toolbar float-end">
            <jbm-activity-log-button *ngIf="user.id" [subject]="'entity.user'|translate" [title]="user.full_name" [subject_type]="5" [subject_id]="user.id"></jbm-activity-log-button>
            <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <jbm-text-input [id]="'username'" [formControl]="formControls['username']"
            [required]="true" [submitted]="isSubmitted"
            [labelCaption]="'login.username'|translate"
            labelClass="width-10" class="input-20">
                <jbm-input-error [formControl]="formControls['username']" [submitted]="isSubmitted">
                    <span *ngIf="formControls['username'].hasError('notUnique')">
                        {{'entity.name-not-unique'|translate}}</span>
                    <span *ngIf="formControls['username'].hasError('required')">
                        {{'entity.requiredfield'|translate}}</span>
                </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [id]="'full_name'" [formControl]="formControls['full_name']"
            [required]="true" [submitted]="isSubmitted"
            [labelCaption]="'entity.fullname'|translate"
            labelClass="width-10" class="input-20">
            <jbm-input-error [formControl]="formControls['full_name']" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-text-input>
        <jbm-select [id]="'language_choice'" [formControl]="formControls['language_choice']"
            [options]="languages"
            [insertLeadingOption]="true"
            [required]="true" [submitted]="isSubmitted"
            [labelCaption]="'translation.language'|translate"
            labelClass="width-10" class="input-20">
                <jbm-input-error [formControl]="formControls['language_choice']" [submitted]="isSubmitted">
                    {{'entity.requiredfield'|translate}}
                </jbm-input-error>
        </jbm-select>
        <jbm-check [id]="'active'" [formControl]="formControls['active']"
            [textChecked]="'entity.active'|translate"
            [textUnchecked]="'entity.inactive'|translate"
            [labelCaption]="'entity.active'|translate"
            labelClass="width-11" class="input-15">
        </jbm-check>
        <fieldset *ngIf="!user.id" class="width-30 mt-3">
            <legend>{{'users.password-set'|translate}}</legend>
            <password-constraints-alert visible="true"></password-constraints-alert>

            <jbm-text-input
              [id]="'passwordNew'"
              [formControl]="formControls.passwordNew"
              [required]="true"
              [class]="'input-15'"
              [labelCaption]="'users.password-new'|translate"
              [labelClass]="'width-9'"
              [submitted]="isSubmitted"
              [type]="'password'">
                <jbm-input-error [formControl]="formControls.passwordNew" [submitted]="isSubmitted">
                    <span *ngIf="formControls.passwordNew.hasError('passwordInvalid')">
                        {{'login.password-error'|translate}}
                    </span>
                </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input
              [id]="'passwordConfirm'"
              [formControl]="formControls.passwordConfirm"
              [required]="true"
              [class]="'input-15'"
              [labelCaption]="('users.password-new'|translate)+' '+('users.password-confirm'|translate)"
              [labelClass]="'width-9'"
              [submitted]="isSubmitted"
              [type]="'password'">
                <jbm-input-error [formControl]="formControls.passwordConfirm" [submitted]="isSubmitted">
                    <span *ngIf="formControls.passwordConfirm.hasError('passwordInvalid')">
                        {{'login.password-error'|translate}}
                    </span>
                    <span *ngIf="formControls.passwordConfirm.hasError('confirmInvalid')">
                        {{'users.password-confirm-error'|translate}}
                    </span>
                </jbm-input-error>
            </jbm-text-input>
        </fieldset>
        <div *ngIf="user.id" class="mt-3 mb-3 clearfix">
            <div class="width-11 float-start">&nbsp;</div>
            <div class="float-start">
                <button type="button" class="btn btn-outline-secondary" (click)="passwordChange()">{{'users.password-change'|translate}}</button>
            </div>
        </div>
        <fieldset *ngIf="usergroups" class="width-30 mt-3"><legend>Management {{'users.usergroups'|translate|lowercase}}</legend>
            <ngb-alert *ngIf="!groupsChecked" [type]="'danger'" [dismissible]="false">
                {{'users.groups-empty'|translate}}
            </ngb-alert>
            <div *ngFor="let u of usergroups">
                <div *ngIf="userRights.AllowCreate || userRights.AllowUpdate; else group" class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="u.checked" value="" id="ug-{{u.name}}" (change)="groupChange(u)">
                    <label class="form-check-label" for="ug-{{u.name}}">{{u.name}}<br /><span class="font-weight-light">{{u.description}}</span></label>
                </div>
                <ng-template #group>
                    <label *ngIf="u.checked" class="form-check-label" for="ug-{{u.name}}">{{u.name}}<br /><span class="font-weight-light">{{u.description}}</span></label>
                </ng-template>
            </div>
        </fieldset>
        <fieldset *ngIf="recipeGroups" class="width-30 mt-3"><legend>Receptuur/Lab {{'users.usergroups'|translate|lowercase}}</legend>
            <div *ngFor="let u of recipeGroups">
                <div *ngIf="userRights.AllowCreate || userRights.AllowUpdate; else group" class="form-check">
                    <input class="form-check-input" type="checkbox" [checked]="u.checked" value="" id="rug-{{u.name}}" (change)="recipeGroupChange(u)">
                    <label class="form-check-label" for="rug-{{u.name}}">{{u.name}}<br /><span class="font-weight-light">{{u.description}}</span></label>
                </div>
                <ng-template #group>
                    <label *ngIf="u.checked" class="form-check-label" for="rug-{{u.name}}">{{u.name}}<br /><span class="font-weight-light">{{u.description}}</span></label>
                </ng-template>
            </div>
        </fieldset>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="'entity.user'|translate" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
<jbm-modal [id]="'userpassword'" [className]="'userpassword'"></jbm-modal>
<ng-template #userPasswordModal let-modal>
    <form-password-change [user]="user"
      (onChanged)="onPasswordChange()" (onCancel)="onPasswordChangeCancel()"></form-password-change>
</ng-template>
<jbm-toasts></jbm-toasts>
