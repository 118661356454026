import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {
  ContactDropdownComponent
} from "../../../../components/domain/dropdowns/ContactDropdown/ContactDropdown.component";

@Component({
  selector: 'quotation-toolbar',
  templateUrl: './quotation-toolbar.component.html',
  styleUrls: ['./quotation-toolbar.component.scss']
})
export class QuotationToolbarComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  contact: any;
  role: string='';

  constructor(
    private TokenService: TokenService,
    private ModalService: ModalService,
    private TranslateService: TranslateService,
    private JBMToastsService: JBMToastsService
  ) {}

  @Input() quotation_id: number=0;
  @Input() project_id: number=0;
  @Input() customer_id: number=0;
  @Input() plant_id: number=0;
  @Input() settings: any;

  @Output() onChange=new EventEmitter();
  @Output() onCreateRevision=new EventEmitter();

  @ViewChild('contact1') contact1: ContactDropdownComponent;
  @ViewChild('contact2') contact2: ContactDropdownComponent;
  @ViewChild('modalContact') modalContact: ElementRef;

  ngOnInit(): void {}
  toggleDetails() {
    this.settings.details=!this.settings.details;
    this.onChange.emit({settings: this.settings});
  }
  toggleOrders() {
    this.settings.orders=!this.settings.orders;
    this.onChange.emit({settings: this.settings});
  }
  toggleArticles() {
    this.settings.articles=!this.settings.articles;
    this.onChange.emit({settings: this.settings});
  }
  toggleComments() {
    this.settings.comments=!this.settings.comments;
    this.onChange.emit({settings: this.settings});
  }
  onEditContact(event) {
    this.contact=event.contact;
    this.role=event.role;
    this.ModalService.open(this.modalContact,'form-contact');
  }
  onSaveContact() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.closeContact();
    // Refresh contact dropdowns
    this.contact1.loadContact();
    this.contact2.loadContact();
  }
  onCancelContact() {
    this.closeContact();
  }
  closeContact() {
    this.ModalService.close('form-contact');
  }
}
