<div class="clearfix bg-light-darker" [ngClass]="{'pl-4 pr-4': padding, 'pl-1 pr-1':!padding}">
   <div class="toolbar float-start mr-4">
      <h5 class="pt-1">{{'presentations.mediafiles'|translate}}
        <span *ngIf="queryHttpHelper.paginationProps.itemCount" class="badge bg-light text-dark">{{queryHttpHelper.paginationProps.itemCount}}</span>
      </h5>
   </div>
   <div class="toolbar float-start">
      <jbm-create-button [visible]="userRights.AllowCreate" (click)="addItem()"></jbm-create-button>
   </div>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page"
        (pageClick)="pageChange($event)">
      </jbm-pagination>
      <jbm-searchbox [id]="'search-box'" [visible]="searchBoxVisible && !filtersVisible" [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-filter-toggle [showFilter]="filtersVisible" [class]="'light'" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-help-button [helpTopicID]="0" [class]="'light'"></jbm-help-button>
   </div>
</div>
<div>
   <jbm-table #table
     [data]="items"
     [filterable]="true"
     [showFilters]="filtersVisible"
     [sortable]="true"
     [sortColumn]="queryHttpHelper.sortProps.name"
     [sortDirection]="queryHttpHelper.sortProps.direction"
     [hoverable]="true"
     [rowSelectable]="userRights.AllowCreate"
     [columnDefs]="columnDefs"
     [actionDefs]="actionDefs"
     [classname]="'mediafiles'+className"
     (sortChange)="sortChange($event)"
     (filterChange)="filterChange($event)"
     (rowSelect)="rowSelect($event)">
   </jbm-table>
</div>
<ng-template #actionsTemplate let-data="data">
   <button type="button" class="btn btn-sm btn-light mr-3 float-start" (click)="showPreview(data); $event.stopPropagation()">{{'ui.preview'|translate}}</button>
   <button type="button" class="btn btn-sm btn-primary mr-3 float-start" [ngbTooltip]="('ui.properties'|translate)+' '+('ui.edit'|translate|lowercase)"
     (click)="edit(data.id); $event.stopPropagation()"><i class="far fa-edit"></i></button>
   <jbm-confirm-delete-dropdown *ngIf="data.deletePermission" class="float-start"
     [placement]="'left-bottom'"
     [subject]="('presentations.mediafile'|translate)"
     (onConfirm)="delete(data.id)">
   </jbm-confirm-delete-dropdown>
</ng-template>

<div [hidden]="!preview" class="document-preview">
   <div class="header">
      <div class="title float-start">
         {{'ui.preview'|translate}}: <span class="fw-bold">{{description}}</span>
      </div>
      <jbm-cross-close-button class="float-end" (onClick)="preview=!preview"></jbm-cross-close-button>
   </div>
   <jbm-document-viewer [base64]="base64" [fileExtension]="fileExtension"></jbm-document-viewer>
</div>

<jbm-modal [id]="'mediafile'" [className]="'mediafile'"></jbm-modal>
<ng-template #modalMediaFile let-modal>
   <form-media-file [mediaFile]="mediaFile" (onSave)="saved()" (onCancel)="canceled()"></form-media-file>
</ng-template>
<jbm-toasts></jbm-toasts>
