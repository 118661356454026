import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef
} from '@angular/core';
import {ArticlesService} from "../../../../features/financial/data/articles.service";
import {InvoiceArticlesService} from "../../../../features/invoicing/data/invoice-articles.service";
import {PriceService} from "../../../../services/price/price.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnAlignment, JBMTableColumnDef, JBMTableColumnType} from "../../../JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {Article} from "../../../../features/financial/data/interfaces/article";

@Component({
  selector: 'invoice-article-selector',
  templateUrl: './invoice-article.selector.component.html',
  styleUrls: ['./invoice-article.selector.component.scss']
})
export class InvoiceArticleSelectorComponent implements OnInit, OnChanges, OnDestroy {
  listOpen: boolean=false;
  touched: boolean=false;
  items: any[]=[];
  article: Article;
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  search: string='';
  language: string=this.TokenService.getLanguage();

  items$: Subscription;
  count$: Subscription;

  @Input() id: string='id';
  @Input() labelCaption: string='';
  @Input() class: string='';
  @Input() labelClass: string='';
  @Input() labelStacked: boolean=false;
  @Input() required: boolean=false;
  @Input() showError: boolean=true;

  @Input() invoice_id: number=0;
  @Input() invoice_item_id: number=0;
  @Input() articles_id: number=0;
  @Input() allowedPricetypes: number[]=[];
  @Output() articleSelected = new EventEmitter();
  @Output() onBlur = new EventEmitter();
  @Input() template: TemplateRef<any>;

  constructor(
      private InvoiceArticlesService: InvoiceArticlesService,
      private ArticlesService: ArticlesService,
      private PriceService: PriceService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private eRef: ElementRef
  ) {}

  ngOnInit(): void {
    if(this.id==undefined) this.id='id';
    if(this.labelCaption==undefined) this.labelCaption='';
    if(this.class==undefined) this.class='';
    if(this.labelStacked==undefined) this.labelStacked=false;
    if(this.labelClass==undefined) this.labelClass='';
    if(this.showError==undefined) this.showError=true;

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'), sortable: true, filterable: true, width: 18 },
      { name: 'price_type_code', header: this.TranslateService.GetTranslation('article.pricetype-short'), width: 5 },
      { name: 'price', type: JBMTableColumnType.price, header: this.TranslateService.GetTranslation('article.pricetype-price'), width: 5, align: JBMTableColumnAlignment.right }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.paginationProps.pageSize=7;
  }
  ngOnChanges() {
    if(this.articles_id) this.setArticle();
  }
  toggleList() {
    this.listOpen=!this.listOpen;
    if(this.listOpen) {
      this.getData(true);
      setTimeout(()=> { document.getElementById('article-search').focus() }, 100 );
    }
  }
  hideList() {
    this.listOpen=false;
  }
  clearSelection() {
    this.articles_id=0;
    this.hideList();
    this.articleSelected.emit({ id: 0 } );
  }
  setFocus() {
    document.getElementById(this.id).focus();
  }
  blur() {
    this.touched=true;
    this.onBlur.emit();
  }
  getData(countRows:boolean=false) {
    let params=this.getParams();
    this.items$=this.InvoiceArticlesService.getSelectList(this.language, this.invoice_id, this.invoice_item_id, params).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          if(countRows) {
            let params=this.getParams(true);
            this.count$=this.InvoiceArticlesService.getSelectList(this.language, this.invoice_id, this.invoice_item_id, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  getParams(countRows:boolean=false) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    if(this.allowedPricetypes.length)
      params=params.append('allowed_price_types', this.allowedPricetypes.join());

    return params;
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  rowSelect(row) {
    // Emit event
    this.articles_id=row.id;
    this.setArticle();
    this.hideList();
    this.articleSelected.emit( row );
  }
  setArticle() {
    this.ArticlesService.getOne(this.articles_id,this.language).subscribe(
        (data: any)=>this.article=data.data as Article,
        (error)=>console.error(error)
    )
  }
  @HostListener("document:click", ['$event'])
  clickedOut(event) {
    if(!this.eRef.nativeElement.contains(event.target))
      this.hideList();
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape') this.hideList();
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
