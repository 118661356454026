<app-header>
   <jbm-view-title [caption]="('entity.resources'|translate) + ('resource.usage'|translate|lowercase) + ' ' + ('reports.report'|translate|lowercase)"
     [iconClassname]="iconClassname"
     [parentLinkCaption]="'menu.reports'|translate"
     [parentLinkSlug]="'reports'">
   </jbm-view-title>
   <div class="toolbar ml-4 float-start">
      <label>{{'company.plant'|translate}}</label>
      <select (change)="selectPlant($event)" class="form-control form-control-sm form-select">
         <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
      </select>
   </div>
</app-header>
<section class="module report">
   <div class="bg-blue-extra-light pl-4 pt-1 pb-1">
       <jbm-date-range-input [setToday]="true" [style]="'height:auto'" (onSelectRange)="setDateRange($event)" (onUndo)="undoDateRange()"></jbm-date-range-input>
   </div>
   <div [hidden]="empty" class="viewer pl-4 pr-4 pb-4 clearfix">
      <div class="content-secondary pdf-viewer">
         <object id="pdfObject" type="application/pdf">
            <iframe id='pdfIFrame' type="application/pdf"></iframe>
         </object>
      </div>
   </div>
   <jbm-empty-view [emptyView]="empty"></jbm-empty-view>
</section>
