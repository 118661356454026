<table class="table table-sm table-row-selectable invoice-items mb-3">
   <thead>
   <tr>
      <th class="delivery-date">{{'order.delivery-date'|translate}}</th>
      <th class="description">{{'entity.description'|translate}}</th>
      <th class="section"></th>
      <th class="amount align-right">{{'order.amount'|translate}}</th>
      <th class="price-type"></th>
      <th class="price align-right">{{'article.pricetype-price'|translate}}</th>
      <th class="totalprice align-right">{{'ui.totalprice'|translate}}</th>
      <th class="actions align-right"><span *ngIf="articlesTotalPrice">{{articlesTotalPrice|money}}</span></th>
   </tr>
   </thead>
   <tbody>
   <tr *ngIf="!creating && editable">
      <td colspan="2" class="p-0"></td>
      <td class="p-0 pl-1">
         <button type="button" class="btn btn-xs btn-primary" (click)="startCreating()">
            <i class="fa fa-plus"></i>
            <span *ngIf="invoice_item_id"> {{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}}</span>
            <span *ngIf="!invoice_item_id"> {{'financial.article'|translate}}</span>
         </button>
      </td>
      <td colspan="5" class="p-0"></td>
   </tr>
   <tr *ngIf="creating">
      <th></th>
      <th>
         <span *ngIf="invoice_item_id"> {{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}} </span>
         <span *ngIf="!invoice_item_id"> {{'financial.article'|translate}} </span>
         {{'entity.create'|translate|lowercase}}
      </th>
      <th colspan="6"></th>
   </tr>
   <tr *ngIf="creating">
      <td class="delivery-date">
         <div class="float-start">
            <input class="form-control form-control-sm delivery-date" [maxLength]="10" [placeholder]="'datetime.date-format'|translate" [placement]="'right'"
              [container]="'body'" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker" (dateSelect)="selectDate($event)"
              (change)="onChangeDate()"
              (blur)="onChangeDate()"
              [ngClass]="{'ng-valid':validateDate(), 'is-invalid':this.dateTouched && !validateDate()}">
         </div>
         <div class="float-start">
            <button type="button" class="btn btn-sm btn-light" (click)="d.toggle()">
               <i class="fa fa-calendar"></i>
            </button>
         </div>
      </td>

      <td>
         <invoice-article-selector [id]="'article-selector'" [invoice_id]="invoice_id" [invoice_item_id]="0"
           [articles_id]="article_id" [allowedPricetypes]="allowedPricetypes" [required]="true" [labelStacked]="true" [template]="articleselect" [showError]="false"
           [class]="'mb-0'" (articleSelected)="selectArticle($event)">
         </invoice-article-selector>
         <ng-template #articleselect let-article="article">
            <div *ngIf="article">{{article.name}}</div>
         </ng-template>
      </td>

      <td>
         <!--<span class="badge bg-light text-dark">{{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}}</span>-->
      </td>

      <td *ngIf="article_price_types_id!=58" class="editable">
         <input float-only-input type="text" autocomplete="off" [disabled]="!this.article_id" class="form-control form-control-sm align-right"
           [(ngModel)]="amount" [id]="'article-amount-0'" (change)="onChangeAmount()" (blur)="onChangeAmount()"
           [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
      </td>
      <td *ngIf="article_price_types_id==58" class="editable">
         <input digits-only-input type="text" autocomplete="off" [disabled]="!this.article_id" class="form-control form-control-sm align-right"
           [(ngModel)]="amount" [maxLength]="3" [minLength]="1" [id]="'article-amount-0'"
           (change)="onChangeAmount()" (blur)="onChangeAmount()"
           [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
      </td>
      <td>{{code}}</td>

      <td>
         <input [disabled]="!this.article_id" float-only-input class="form-control form-control-sm price align-right" type="text" autocomplete="off"
           [(ngModel)]="price" (change)="onChangePrice()"
           [ngClass]="{'ng-valid':validatePrice(), 'is-invalid':this.priceTouched && !validatePrice()}">
      </td>
      <td class="align-right"><span *ngIf="totalPrice">{{totalPrice|money}}</span></td>
      <td class="editable actions p-0">
         <jbm-cancel-button class="float-end" [showCaption]="false" (click)="cancelCreating()"></jbm-cancel-button>
         <jbm-save-button [disabled]="!validateDate() || !validateDescription() || !validateAmount() || !validatePrice()" class="float-end mr-1"
           [showCaption]="false" (click)="insertArticle()">
         </jbm-save-button>
      </td>
   </tr>
   <ng-container *ngFor="let article of articles">
      <tr class="borderless" (click)="editArticle(article.id)">
         <td *ngIf="!article.editing" [ngClass]="{editable: article.editing}">{{article.delivery_date}}</td>
         <td *ngIf="article.editing">
            <div class="float-start">
               <input class="form-control form-control-sm delivery-date" [maxLength]="10" [placeholder]="'datetime.date-format'|translate" [placement]="'right'"
                 [container]="'body'" name="dp" [(ngModel)]="date" ngbDatepicker #d="ngbDatepicker"
                 (change)="onChangeDate()"
                 (blur)="onChangeDate()"
                 [ngClass]="{'ng-valid':validateDate(), 'is-invalid':this.dateTouched && !validateDate()}">
            </div>
            <div class="float-start">
               <button type="button" class="btn btn-sm btn-light" (click)="d.toggle()">
                  <i class="fa fa-calendar"></i>
               </button>
            </div>
         </td>

         <td *ngIf="!article.editing">{{article.description}}</td>
         <td *ngIf="article.editing">
            <input class="form-control form-control-sm description ml-1" type="text" autocomplete="off"
              [(ngModel)]="description" (change)="onChangeDescription()"
              [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':this.descriptionTouched && !validateDescription()}">
         </td>

         <td>
<!--            <span class="badge bg-light text-dark">{{'ui.extra'|translate}} {{'financial.article'|translate|lowercase}}</span>-->
         </td>

         <td *ngIf="!article.editing" class="align-right">{{article.amount}}</td>
         <td *ngIf="article.editing && article.article_price_types_id!=58" class="editable">
            <input float-only-input class="form-control form-control-sm align-right" [id]="'article-amount-'+article.id" type="text" autocomplete="off"
              [(ngModel)]="amount" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
         </td>
         <td *ngIf="article.editing && article.article_price_types_id==58" class="editable">
            <input digits-only-input class="form-control form-control-sm align-right" [id]="'article-amount-'+article.articles_id" type="text" autocomplete="off"
              [(ngModel)]="amount" [maxLength]="3" [minLength]="1" (change)="onChangeAmount()" (blur)="onChangeAmount()" [ngClass]="{'ng-valid':validateAmount(), 'is-invalid':this.amountTouched && !validateAmount()}">
         </td>
         <td class="pl-0">{{article.price_type.code}}</td>

         <td *ngIf="!article.editing" class="align-right">
            <jbm-article-pricerules-breakdown-popover *ngIf="article.price"
              [subject]="subject" [subject_id]="article.id" [price]="article.price" [class]="'btn-sm'">
            </jbm-article-pricerules-breakdown-popover>
         </td>
         <td *ngIf="article.editing" class="editable">
            <input float-only-input class="form-control form-control-sm align-right" type="text" autocomplete="off"
              [(ngModel)]="price"
              (change)="onChangePrice()"
              [ngClass]="{'ng-valid':validatePrice(), 'is-invalid':this.priceTouched && !validatePrice()}">
         </td>
         <td *ngIf="!article.editing" class="align-right">{{article.totalprice|money}}</td>
         <td *ngIf="article.editing" class="align-right editable"><span *ngIf="totalPrice">{{totalPrice|money}}</span></td>

         <td *ngIf="editable && !article.editing" class="action-buttons align-right p-0">
            <jbm-confirm-delete-dropdown
              [subject]="('entity.invoice'|translate)+' '+('financial.article'|translate|lowercase)"
              [placement]="'top-right'"
              [class]="'float-end ml-2 mr-0'"
              (onConfirm)="delete(article.id)">
            </jbm-confirm-delete-dropdown>
            <jbm-edit-button *ngIf="userRights.AllowUpdate" (onClick)="editArticle(article.id)"></jbm-edit-button>
         </td>
         <td *ngIf="editable && article.editing" class="editable action-buttons align-right p-0">
            <jbm-cancel-button class="float-end" [showCaption]="false" [small]="true" (click)="cancelArticle()"></jbm-cancel-button>
            <jbm-save-button [disabled]="!validateDate() || !validateDescription() || !validateAmount()" class="float-end mr-1"
              [showCaption]="false" (click)="saveArticle()">
            </jbm-save-button>
         </td>
      </tr>
   </ng-container>
   </tbody>
</table>
