import { Injectable } from '@angular/core';
import { HttpService} from "../../http.service";

@Injectable({
  providedIn: 'root'
})
export class UploadSettingsService {

  constructor(private HttpService: HttpService) { }

  get() {
    return this.HttpService.get('filesystem/read/upload-settings');
  }

  extensionsAsString( extensionsArray ) {
    return extensionsArray.split()
  }

}
