import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppLayouts} from "../../app.component";
import {HelpTopicsComponent} from "./datatable/helptopics.component";
import {HelpTopicComponent} from "./form/helptopic.component";
import {AuthGuardService} from "../../services/auth/authguard.service";
import {HelpTopicResolver} from "./data/resolvers/helptopic.resolver";

const routes: Routes = [
  {
    path: 'settings/helpcentre',
    component: HelpTopicsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/helptopic/create/:id',
    component: HelpTopicComponent,
    canActivate: [AuthGuardService],
    resolve: { helptopic: HelpTopicResolver },
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'settings/helptopic/:id',
    component: HelpTopicComponent,
    canActivate: [AuthGuardService],
    resolve: { helptopic: HelpTopicResolver },
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HelpCentreRoutingModule { }
