<form [formGroup]="form" (ngSubmit)="save()" class="order" xmlns="http://www.w3.org/1999/html">
   <div class="jbm-modal-header">
      <jbm-crud-title [subject]="'order.amount'" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <div class="form-group row readonly">
         <label class="lg col-form-label">{{'datetime.start'|translate}} {{'order.production'|translate|lowercase}}:</label>
         <div class="data-value">
            <label class="col-form-label">
               {{order.production_date}} <i class="far fa-clock"></i> {{order.starttime}} {{'datetime.hour'|translate}}
            </label>
         </div>
      </div>
      <jbm-float-input-group [formControl]="formControls.amount" [submitted]="isSubmitted"
        [id]="'amount'" required="true" [inputClass]="'xxs'" append="{{'quantity.m3'|translate}}"
        labelCaption="{{'order.amount'|translate}}" labelClass="lg" decimals="2" [decimalsOptional]="true"
        [negativeAllowed]="false">
         <jbm-input-error [formControl]="formControls.amount" [submitted]="isSubmitted">
            {{'entity.concrete-amount-format'|translate}}
         </jbm-input-error>
      </jbm-float-input-group>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button subject="{{'order.amount'|translate}}" [class]="'mr-2'" (click)="save()"></jbm-save-button>
         <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>