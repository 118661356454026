import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../shared/shared.module";
import { JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import { JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import { JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { RecipesRoutingModule } from './recipes-routing.module';
import { ResourcesTypesComponent} from "./datatable/resources-types.component";
import { ResourcesComponent} from "./datatable/resources.component";
import { RecipesComponent} from "./datatable/recipes.component";
import { RecipeFormComponent} from "./form/recipe-form.component";
import { RecipeArticlesComponent } from './form/recipe-articles/recipe-articles.component';
import { RecipeResourcesComponent } from './form/recipe-resources/recipe-resources.component';
import {LayoutModule} from '../../layout/layout.module';
import {SelectorsModule} from '../../components/domain/selectors/selectors.module';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  declarations: [ResourcesTypesComponent, ResourcesComponent, RecipesComponent, RecipeFormComponent, RecipeArticlesComponent, RecipeResourcesComponent],
    imports: [CommonModule, RecipesRoutingModule, SharedModule, JBMFormModule, JBMViewModule, JBMButtonsModule,
        JBMTableModule, JBMDropdownsModule, JBMModalModule, LayoutModule, SelectorsModule, NgSelectModule]
})
export class RecipesModule { }
