import {
  AfterViewInit,
  Component,
  EventEmitter, HostListener,
  Input, OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef
} from '@angular/core';
import {ContextType} from "../Helpers/Context";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {LocalStorageService} from "../../../services/storage/localstorage.service";

export interface JBMTableFilter { key: string, value: string }
export interface JBMTableDisplayValue { dataValue: any, displayValue: string, className?: string }

export enum JBMTableColumnType {
  id,                 // Unique row-id or record id
  string,
  number,
  date,               // dd-mm-yyyy
  dateshort,          // dd-mm
  time,               // hh:mm
  timeseconds,        // hh:mm:ss
  datetime,           // dd-mm-yyyy hh:mm
  datetimeseconds,    // dd-mm-yyyy hh:mm:ss
  boolean,
  price,
  email,
  notes,
  address,
  custom,
  panelToggle
}

export enum JBMTableColumnAlignment { left, center, right}

export interface JBMTableColumnDef {
  name: string;
  header?: string;
  type?: JBMTableColumnType;
  sortable?: boolean;
  sorted?: boolean;
  visible?: boolean;
  hideable?:boolean;
  filterable?: boolean;
  searchMinLength?: number;
  filterValues?: JBMTableFilter[],
  displayValues?: JBMTableDisplayValue[],
  filterValue?: string,
  width?: number;
  align?: JBMTableColumnAlignment;
  template?: TemplateRef<any>;
  className?: string;
  iconClassname?: string;
  tag?: any; // Internal purposes
}

export const IDTag='id';

export interface JBMTableActionDef {
  name: string;
  context?: ContextType;
  iconClass?: string;
  caption?: string;
  disabled?: boolean;
}

export interface JBMTableColumnSortEvent {
  name: string;
  descending: boolean;
}

export interface JBMTableColumnFilterEvent {
  name: string;
  value: any;
}

export interface JBMTableActionClickEvent {
  name: string;
  row: any;
}

@Component({
  selector: 'jbm-table',
  templateUrl: './JBMTable.component.html',
  styleUrls: ['./JBMTable.component.scss']
})
export class JBMTableComponent implements OnInit, OnChanges {
  rowExpanded: number=-1;

  @Input() id: string='JBMTable';
  @Input() classname: string='JBM-table';
  // Look and feel
  @Input() striped: boolean=false;
  @Input() bordered: boolean=false;
  @Input() size: string='sm';
  @Input() rowExpandTooltip: string='';
  @Input() rowCollapseTooltip: string='';
  // Behavioral
  @Input() absolute: boolean=false;
  @Input() responsive: boolean=true;
  @Input() hoverable: boolean=false;
  @Input() sortable: boolean=true;
  @Input() filterable: boolean=true;
  @Input() searchMinLength: number=2;
  @Input() showFilters: boolean=false;
  @Input() rowSelectable: boolean=false;
  // Columns
  @Input() columnDefs: JBMTableColumnDef[]=[];
  @Input() actionDefs: JBMTableActionDef[]=[];
  // Data
  @Input() sortColumn: string='';
  @Input() sortDirection: string='+';
  @Input() data: any[];
  // Events
  @Output() rowCountChange = new EventEmitter();

  @Output() sortChange = new EventEmitter();
  @Output() filterChange = new EventEmitter();
  @Output() rowSelect = new EventEmitter();
  @Output() rowExpand = new EventEmitter();
  @Output() rowCollapse = new EventEmitter();
  @Output() actionClick = new EventEmitter();
  @Output() onDropdownOpenChange = new EventEmitter();
  @Output() toggleRowExpandChange = new EventEmitter();

  @Input() template: TemplateRef<any>;

  columnFilterEvent: JBMTableColumnFilterEvent;
  columnSortEvent: JBMTableColumnSortEvent;
  actionClickEvent: JBMTableActionClickEvent;

  private static LocalStoragePrefix='JBMTable';

  constructor(
      private LocalStorageService: LocalStorageService,
      private TranslateService: TranslateService
  ) {}

  ngOnInit(): void {
    if(this.columnDefs==undefined) this.columnDefs=[];
    if(this.actionDefs==undefined) this.actionDefs=[];

    // Default min string length of search filter for all table columns
    if(this.searchMinLength==undefined) this.searchMinLength=2;

    if(this.size==undefined) this.size='sm';

    if(this.rowExpandTooltip==undefined) this.rowExpandTooltip=this.TranslateService.GetTranslation('ui.table.row-expand-tooltip');
    if(this.rowCollapseTooltip==undefined) this.rowCollapseTooltip=this.TranslateService.GetTranslation('ui.table.row-collapse-tooltip');

    if(this.template==undefined) this.template=null;

    // Set default values for optional/omitted columnDef properties
    for(let c=0; c<this.columnDefs.length; c++) {
      // Hide ID column if set; only for (record) reference purposes
      if(this.columnDefs[c].type===JBMTableColumnType.id) {
        this.columnDefs[c].visible = false;
        // Tag as ID column
        this.columnDefs[c].tag = IDTag;
      }
      // If header property not set, assume empty header
      if(this.columnDefs[c].header===undefined)
        this.columnDefs[c].header='';
      if(this.columnDefs[c].className===undefined)
        this.columnDefs[c].className='';
      // If header iconClass property not set, assume no icon in header
      if(this.columnDefs[c].iconClassname===undefined)
        this.columnDefs[c].iconClassname='';
      // If columntype property not set, assume string columntype
      if(this.columnDefs[c].type===undefined)
        this.columnDefs[c].type=JBMTableColumnType.string;
      // If hideable property not set, assume hideable
      if(this.columnDefs[c].hideable===undefined)
        this.columnDefs[c].hideable=true;
      // If visible property not set, assume visible
      if(this.columnDefs[c].visible===undefined)
        this.columnDefs[c].visible=true;
      // If sortable property not set, assume not sortable
      if(this.columnDefs[c].sortable===undefined)
        this.columnDefs[c].sortable=false;
      if(this.columnDefs[c].sorted===undefined)
        this.columnDefs[c].sorted=false;
      // If min string length of search filter not set, assume global table setting
      if(this.columnDefs[c].searchMinLength===undefined)
        this.columnDefs[c].searchMinLength=this.searchMinLength;
      // If filterable property not set, assume not filterable
      if(this.columnDefs[c].filterable===undefined)
        this.columnDefs[c].filterable=false;
      // If width property not set, width is calculated via the tablecolumn datatype,
      // or is set to auto (width=0); meaning visually controlled by <table> tag
      if(this.columnDefs[c].width===undefined)
        this.columnDefs[c].width=this._calcColumnWidth(this.columnDefs[c].type);
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes.hasOwnProperty('sortColumn'))
      this._setSortedColumn(changes.sortColumn.currentValue);

    if(changes.hasOwnProperty('showFilters') && !changes.showFilters.isFirstChange() && this.absolute)
      // Absolute positioned table, so recalculate rowcount
      // Use SetTimeOut to prevent ExpressionChangedAfterItHasBeenCheckedError
      setTimeout(() => {
        this.rowCountChange.emit(this.getRowCount());
      });
  }
  // Event handling
  tableSortChange(event) {
    this.columnSortEvent={ name: event.name, descending: event.descending };
    // Emit event
    this.sortChange.emit( this.columnSortEvent );
  }
  tableFilterChange(event) {
    this.columnFilterEvent={ name: event.name, value: event.value };
    this._saveFilterValue(event.name, event.value);
    // Emit event
    this.filterChange.emit( this.columnFilterEvent );
  }
  onRowSelect(rowData) {
    if(!this.rowSelectable) return;
    this.rowSelect.emit( rowData );
  }
  isExpandable(rowData) {
    return !rowData.hasOwnProperty('isExpandable') || (rowData.hasOwnProperty('isExpandable') && rowData.isExpandable);
  }
  public toggleRowExpanded(rowData) {
    if(this.rowExpanded) this.rowCollapse.emit(rowData);
    if(this.rowExpanded==-1) this.rowExpand.emit(rowData);
    this.rowExpanded=this.rowExpanded===rowData.id ? -1 : rowData.id;
    this.toggleRowExpandChange.emit(this.rowExpanded!==1);
  }
  onActionClick(row, actionName) {
    this.actionClickEvent={ name: actionName, row: row };
    this.actionClick.emit( this.actionClickEvent );
  }

  // State
  saveState(storageKey: string) {
    let key=JBMTableComponent.LocalStoragePrefix+storageKey;
    if(this.LocalStorageService.hasKey(key))
        // Delete actual state data
      this.clearState(storageKey);

    let columns=[];
    for(let i=0; i<this.columnDefs.length; i++)
      if(this.columnDefs[i].filterable && this.columnDefs[i].filterValue!==undefined && this.columnDefs[i].filterValue!=='')
        columns.push( { name: this.columnDefs[i].name, filterValue: this.columnDefs[i].filterValue } );

    this.LocalStorageService.set(key, { columns: columns, sortColumn: this.sortColumn, sortDirection: this.sortDirection });
  }
  restoreState(storageKey: string, clearState: boolean=true) {
    let key=JBMTableComponent.LocalStoragePrefix+storageKey;
    if(!this.LocalStorageService.hasKey(key)) return;
    let state=this.LocalStorageService.get(key);

    this.sortColumn=state.sortColumn;
    this.sortDirection=state.sortDirection;

    for(let cd=0; cd<this.columnDefs.length; cd++)
      for(let i=0; i<state.columns.length; i++)
        if(state.columns[i].name===this.columnDefs[cd].name)
          this.columnDefs[cd].filterValue=state.columns[i].filterValue;

    if(clearState)
      this.clearState(storageKey);
  }
  clearState(storageKey: string) {
    let key=JBMTableComponent.LocalStoragePrefix+storageKey;
    if(this.LocalStorageService.hasKey(key))
      this.LocalStorageService.remove(key);
  }

  /* Service routines */

  // Reset filter values and empty filter inputs
  resetColumnFilters() {
    for(let columnDef of this.columnDefs) {
      if(!columnDef.filterable) continue;
      columnDef.filterValue = '';
      (<HTMLInputElement>document.getElementById('columnfilter-'+columnDef.name)).value='';
    }
  }
  // Reset filter value and empty filter input
  resetColumnFilter(name: string) {
    for(let columnDef of this.columnDefs) {
      if(columnDef.name===name && columnDef.filterable) {
        columnDef.filterValue = '';
        (<HTMLInputElement>document.getElementById('columnfilter-'+columnDef.name)).value='';
      }
    }
  }
  resetColumnFilterValues() {
    for(let columnDef of this.columnDefs) {
      if(!columnDef.filterable) continue;
      columnDef.filterValue = '';
    }
  }
  resetColumnFilterValue(name: string) {
    for(let columnDef of this.columnDefs)
      if(columnDef.name===name && columnDef.filterable)
        columnDef.filterValue = '';
  }

  _setSortedColumn(columnName: string) {
    this._resetSortedColumn();
    for(let c=0; c<this.columnDefs.length; c++)
      if(this.columnDefs[c].name===columnName)
        this.columnDefs[c].sorted=true;
  }
  _resetSortedColumn() {
    for(let c=0; c<this.columnDefs.length; c++)
        this.columnDefs[c].sorted=false;
  }
  _isSorted(columnName: string) {
    return this.sortColumn===columnName;
  }

  _saveFilterValue(columnName: string, filterValue: any) {
    for(let c=0; c<this.columnDefs.length; c++)
      if(this.columnDefs[c].name===columnName)
        this.columnDefs[c].filterValue=filterValue;
  }

  _calcColumnWidth(columnType: JBMTableColumnType): number {
    switch (columnType) {
      case JBMTableColumnType.datetime: { return 7.5; }
      case JBMTableColumnType.datetimeseconds: { return 9; }
      case JBMTableColumnType.date: { return 6; }
      case JBMTableColumnType.dateshort: { return 3; }
      case JBMTableColumnType.time: { return 5; }
      case JBMTableColumnType.timeseconds: { return 6.5; }
      case JBMTableColumnType.panelToggle: { return 2; }
      default: { return 0; }
    }
  }

  openDropdownChange(opened: boolean) {
    this.onDropdownOpenChange.emit(opened);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if(!this.absolute) return;
    this.rowCountChange.emit(this.getRowCount());
  }
  getRowCount() {
    if(!this.absolute) return 0;

    // Size dependant table header/row heights
    let rowHeight=40;
    let marge=5;
    if(this.size==='sm') rowHeight=34;
    if(this.size==='xs') {
      rowHeight=32;
      marge=2;
    }

    // Calculate (new) row count
    let height=(<HTMLDivElement>document.getElementById(this.id)).clientHeight;

    // Subtract header and filter row heights
    height-=rowHeight;
    if(this.showFilters) height-=rowHeight;

    return Math.ceil(height / (rowHeight+marge));
  }
}
