import { Injectable } from '@angular/core';
import { HttpService } from "../http.service";
import {HttpParams} from "@angular/common/http";

const URL='customers/read/google-maps-service';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapsService {

  constructor(private HttpService: HttpService) {}

  getLookup(street, no, postcode, city, fullAddress: boolean=false) {
    let flag=fullAddress ? '1' : '0';

    postcode=postcode.replace(' ','');  // Remove space

    let params=new HttpParams().
      set('street',street).
      set('no',no).
      set('postcode',postcode).
      set('city',city).
      set('fulladdress', flag );
    return this.HttpService.get(URL,params);
  }
}
