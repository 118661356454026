<header class="module subheader">
    <div class="header-section">
        <jbm-create-button [visible]="userRights.AllowCreate" class="mr-4" (click)="addUser()"></jbm-create-button>
        <label for="app" class="label">{{'ui.app'|translate}}</label>
        <select id="app" class="form-control form-select mr-4" (change)="appChange($event)">
            <option value="0">Management</option>
            <option value="1">Receptuur</option>
        </select>
        <label for="ug" class="label">{{'users.usergroup'|translate}}</label>
        <select id="ug" class="form-control form-select" (change)="usergroupChange($event)">
            <option value="0">{{'ui.all'|translate}}</option>
            <option *ngFor="let usergroup of usergroups" value="{{usergroup.id}}">{{usergroup.name}}</option>
        </select>
    </div>
    <div class="">
        <jbm-filter-toggle [showFilter]="showFilter" (onToggle)="filterToggle($event)"></jbm-filter-toggle>
        <div class="header-spacer-1 float-end"></div>
        <jbm-searchbox [visible]="searchboxVisible && !showFilter" [search]="queryHttpHelper.getSearchValue()" [minlength]="3"
                       (searchChange)="searchChange($event)">
        </jbm-searchbox>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
                        [pageSize]="queryHttpHelper.paginationProps.pageSize"
                        [page]="queryHttpHelper.paginationProps.page"
                        (pageClick)="pageChange($event)">
        </jbm-pagination>
    </div>
</header>
<table class="users table table-sm table-hover table-row-selectable">
    <thead>
    <tr>
        <th style="width: 12rem">{{'login.username'|translate}}</th>
        <th style="width: 15rem" >{{'entity.fullname'|translate}}</th>
        <th style="width: 20rem" >{{'users.usergroups'|translate}}</th>
        <th style="width: 9rem">{{'translation.language'|translate}}</th>
        <th>{{'entity.active'|translate}}</th>
        <th style="width: 10.5rem" class="actions"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="showFilter" class="table-filters">
        <td>
            <jbm-table-column-filter [name]="'name'" [columnType]="columnTypes.name" (filterChange)="filterChange($event)"></jbm-table-column-filter>
        </td>
        <td>
            <jbm-table-column-filter [name]="'full_name'" [columnType]="columnTypes.full_name" (filterChange)="filterChange($event)"></jbm-table-column-filter>
        </td>
        <td>&nbsp;</td>
        <td>
            <jbm-table-column-filter [name]="'language_choice'" [columnType]="columnTypes.language_choice"
              [filterValues]="languages" (filterChange)="filterChange($event)"></jbm-table-column-filter>
        </td>
        <td>
            <jbm-table-column-filter [name]="'active'" [columnType]="columnTypes.active"
              [filterValues]="activestates" (filterChange)="filterChange($event)"></jbm-table-column-filter>
        </td>
        <td class="actions">&nbsp;</td>
    </tr>
    <tr *ngFor="let u of users" (click)="editUser(u)">
        <td>{{u.username}}</td>
        <td>{{u.full_name}}</td>
        <td>{{u.usergroups}}</td>
        <td>{{u.language}}</td>
        <td *ngIf="u.active"><i class="fas fa-check"></i> {{'entity.active'|translate}}</td>
        <td *ngIf="!u.active"><span class="text-danger">{{'entity.inactive'|translate}}</span></td>
        <td class="actions">
            <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-primary btn-sm mr-3"><i class="far fa-edit"></i></button>
            <button *ngIf="userRights.AllowUpdate" type="button" class="btn btn-light btn-sm"
              (click)="editMailSettings(u.id);$event.stopPropagation()">
                <i class="far fa-edit"></i> {{'mail.server'|translate}}
            </button>
        </td>
    </tr>
    </tbody>
</table>
<jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
<jbm-toasts></jbm-toasts>
<jbm-modal [id]="'user'" [className]="'user'"></jbm-modal>
<ng-template #userModal let-modal>
    <form-user [user]="user" (onSave)="userSaved()" (onCancel)="userCanceled()"></form-user>
</ng-template>
<jbm-modal [id]="'mailsettings'" [className]="'mailsettings'"></jbm-modal>
<ng-template #modalMailsettings let-modal>
    <form-email-settings [users_id]="userid" (onSave)="mailSettingsSaved()" (onCancel)="mailSettingsCanceled()"></form-email-settings>
</ng-template>
