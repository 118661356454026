<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'quotation.state'|translate}} {{'entity.history'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <jbm-static-label [labelCaption]="'quotation.number'|translate" [value]="quotationNumber"></jbm-static-label>
   <div *ngFor="let item of items; let last=last;">
      {{item.date}}&nbsp;&nbsp;<i class="far fa-clock"></i> {{item.time}}&nbsp;&nbsp;
      <span class="badge badge-pill" style="background-color: {{item.quotation_state.color}};color: {{item.quotation_state.text_color}}">
         {{item.quotation_state.description}}
      </span>
      <button *ngIf="editID==-1" class="btn btn-sm btn-light float-end" [ngbTooltip]="'ui.edit'|translate"
        (click)="toggleEditing(item.id, item.comments);$event.stopPropagation();">
         <i class="far fa-edit"></i>
      </button>
      <div [hidden]="editID!=item.id" class="clearfix mt-2 mb-2">
         <textarea id="comments-{{item.id}}" [(ngModel)]="comments" class="form-control mb-2"></textarea>
         <button class="btn btn-sm btn-light float-end" [ngbTooltip]="'ui.cancel'|translate"
           (click)="toggleEditing(item.id, item.comments)">
            <i class="fa fa-undo"></i>
         </button>
         <button class="btn btn-sm btn-light float-end mr-2" [ngbTooltip]="'ui.save'|translate"
           (click)="save(item.id)">
            <i class="fas fa-check"></i>
         </button>
      </div>
      <div *ngIf="editID!==item.id">
         <div *ngIf="item.comments" class="comments">{{item.comments}}</div>
         <div *ngIf="!item.comments" class="comments">{{'entity.no-comments'|translate}}</div>
      </div>
      <hr *ngIf="!last" />
   </div>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <jbm-close-button [visible]="true" (click)="close()"></jbm-close-button>
   </div>
</div>
