<form [formGroup]="form" (ngSubmit)="save()" id="company" class="company" xmlns="http://www.w3.org/1999/html">
  <div class="toolbar buttons">
    <jbm-save-button *ngIf="userRights.AllowUpdate" [subject]="'company.settings'|translate" [small]="false" [onlyOnceClickable]="false" (click)="save()"></jbm-save-button>
    <div class="toolbar-spacer-1"></div>
    <jbm-cancel-button *ngIf="userRights.AllowUpdate" [small]="false" (click)="cancel()"></jbm-cancel-button>
    <div class="toolbar-spacer-1"></div>
    <jbm-activity-log-button
      [title]="'company.data'|translate" [subject_type]="23" [subject_id]="1">
    </jbm-activity-log-button>
  </div>
  <div class="row">
    <div class="col-lg-6">
        <jbm-text-input-group [formControl]="formControls.max_traveltime" [id]="'driving_time_margin'" [required]="true" [submitted]="isSubmitted"
          [alignRight]="true" [editable]="userRightsFinancial.AllowUpdate" labelCaption="{{'ui.maximum-adjective'|translate}} {{'geo.traveltime'|translate|lowercase}}"
          labelClass="width-9" class="xxxs" [maxlength]="3" [append]="'datetime.minutes'|translate">
          <jbm-input-error [formControl]="formControls.max_traveltime" [submitted]="isSubmitted">
            <span *ngIf="formControls.max_traveltime.hasError('pattern')">{{'entity.integer-format'|translate}}</span>
          </jbm-input-error>
        </jbm-text-input-group>
        <jbm-select [formControl]="formControls.unloading_methods_id" [submitted]="isSubmitted" [editable]="editable"
          [options]="unloading_methodOptions" insertLeadingOption="true" [required]="true" [id]="'unloading_methods_id'"
          class="input-23" labelClass="lg" labelCaption="{{'order.unloading-method'|translate}}">
          <jbm-input-error [formControl]="formControls.unloading_methods_id" [submitted]="isSubmitted">
            {{'entity.requiredfield'|translate}}
          </jbm-input-error>
        </jbm-select>
        <jbm-static-label *ngIf="!userRightsRecipes.AllowRead" [labelCaption]="('ui.base'|translate) + ('entity.recipe'|translate|lowercase)"
           labelClass="lg" [class]="'mt-3'" [value]="baseRecipe.description">
        </jbm-static-label>
        <div *ngIf="userRightsRecipes.AllowRead" class="form-group row mt-3">
          <label class="width-9 col-form-label">
            {{'ui.base'|translate}}{{'entity.recipe'|translate|lowercase}}:
          </label>
          <div class="col-form-label ml-2">
            <jbm-link-button [caption]="baseRecipe.description" (onClick)="showRecipe()"></jbm-link-button>
          </div>
        </div>
        <div *ngIf="userRightsFinancial.AllowUpdate">
          <fieldset><legend>{{'financial.prices'|translate}} {{'ui.and'|translate|lowercase}} {{'financial.pricelists'|translate|lowercase}}</legend>
            <jbm-float-input-group [formControl]="formControls.baseprice" [id]="'baseprice'"
               [required]="true" [editable]="editable" labelCaption="{{'concrete.price'|translate}}" labelClass="width-8" class="input-8 mt-2"
               [prepend]="'&euro;'" [submitted]="isSubmitted">
              <jbm-input-error [formControl]="formControls.baseprice" [submitted]="isSubmitted">
                <span *ngIf="formControls['baseprice'].hasError('priceInvalid')">{{'entity.priceformat'|translate}}</span>
              </jbm-input-error>
            </jbm-float-input-group>
            <article-pricelist-selector
              [pricelistsType]="1"
              labelCaption="{{'financial.concrete-pricelist'|translate}}" labelStacked="true"
              [id]="'concrete-selector'"
              [pricelist_id]="formControls.concrete_pricelists_id.value"
              [types]="'0'"
              [actualOnly]="true"
              [required]="true"
              [editable]="editable"
              [template]="concreteselect"
              (pricelistSelected)="setConcretePricelist($event)">
            </article-pricelist-selector>
            <ng-template #concreteselect let-pricelist="pricelist">
              <div *ngIf="pricelist">
                {{pricelist.description}}<br />
                {{'pricelist.validity'|translate}}: {{pricelist.valid_from}} {{'ui.up-to-and-including'|translate}} {{pricelist.valid_to}}<br />
                {{'pricelist.type'|translate}}: {{ArticlePricelistsService.getTypeAsString(pricelist.type,TranslateService)}}
              </div>
            </ng-template>
            <article-pricelist-selector
              [pricelistsType]="0"
              labelCaption="{{'financial.article-pricelist'|translate}}" labelStacked="true"
              [id]="'article-selector'"
              [pricelist_id]="formControls.article_pricelists_id.value"
              [types]="'0'"
              [actualOnly]="true"
              [required]="true"
              [editable]="editable"
              [template]="articleselect"
              (pricelistSelected)="setArticlePricelist($event)">
            </article-pricelist-selector>
            <ng-template #articleselect let-pricelist="pricelist">
              <div *ngIf="pricelist">
                {{pricelist.description}}<br />
                {{'pricelist.validity'|translate}}: {{pricelist.valid_from}} {{'ui.up-to-and-including'|translate}} {{pricelist.valid_to}}<br />
                {{'pricelist.type'|translate}}: {{ArticlePricelistsService.getTypeAsString(pricelist.type,TranslateService)}}
              </div>
            </ng-template>
            <ngb-alert *ngIf="isSubmitted && resources_based_invoicing && !formControls.resource_pricelists_id.value" [type]="'warning'" [dismissible]="false" class="mb-0 mt-2">{{warning}}</ngb-alert>
            <resource-pricelist-selector [hidden]="!resources_based_invoicing" labelCaption="{{'entity.resource'|translate}}
              {{'financial.pricelist'|translate|lowercase}}" labelStacked="true" [id]="'resource-selector'"
              [pricelist_id]="formControls.resource_pricelists_id.value" [types]="'0'" [required]="true" [editable]="editable"
              [template]="resourceselect" (pricelistSelected)="setResourcePricelist($event)">
            </resource-pricelist-selector>
            <ng-template #resourceselect let-pricelist="pricelist">
              <div *ngIf="pricelist">
                {{pricelist.description}}<br />
                {{'pricelist.type'|translate}}: {{ResourcePricelistsService.getTypeAsString(pricelist.type,TranslateService)}}
              </div>
            </ng-template>
          </fieldset>
        </div>
      </div>
      <div class="col-lg-6">
        <fieldset><legend>{{'entity.quotations'|translate}}</legend>
          <fieldset class="mt-2"><legend>{{'mail.servers'|translate}}</legend>
            <mail-settings-selector
              [labelCaption]="'entity.quotations'|translate"
              labelStacked="true"
              class="mt-2"
              [mail_settings_id]="formControls.quotation_mail_settings_id.value"
              [required]="true"
              [editable]="editable"
              [template]="mailserver1"
              (onSelect)="setQuotationSMTP($event)">
              <jbm-input-error [formControl]="formControls.quotation_mail_settings_id" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
              </jbm-input-error>
            </mail-settings-selector>
            <ng-template #mailserver1 let-settings="settings">
              <div *ngIf="settings">
                {{settings.description}}<br />
                {{settings.name}}
              </div>
            </ng-template>
            <mail-settings-selector
              [labelCaption]="'quotation.confirmation'|translate"
              labelStacked="true"
              [mail_settings_id]="formControls.quotation_confirmation_mail_settings_id.value"
              [required]="true"
              [editable]="editable"
              [template]="mailserver2"
              (onSelect)="setQuotationConfirmationSMTP($event)">
              <jbm-input-error [formControl]="formControls.quotation_confirmation_mail_settings_id" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
              </jbm-input-error>
            </mail-settings-selector>
            <ng-template #mailserver2 let-settings="settings">
              <div *ngIf="settings">
                {{settings.description}}<br />
                {{settings.name}}
              </div>
            </ng-template>
          </fieldset>
        </fieldset>
        <fieldset><legend>{{'menu.invoicing'|translate}}</legend>
          <div class="form-group row">
            <div class="sm">
              <div class="form-check form-check-inline">
                <input type="checkbox" class="form-check-input ml-0" id="setting" [disabled]="!editable"
                  [ngModelOptions]="{standalone: true}" [(ngModel)]="resources_based_invoicing" (change)="setResourcesBasedInvoicing($event)">
                <label class="form-check-label" for="setting">&nbsp;{{'invoicing.on-resources-based'|translate}} {{'invoice.invoicing'|translate|lowercase}}</label>
              </div>
            </div>
          </div>
          <mail-settings-selector
            [labelCaption]="('menu.invoicing'|translate) + ' ' + ('mail.servers'|translate|lowercase)"
            labelStacked="true"
            [mail_settings_id]="formControls.invoicing_mail_settings_id.value"
            [required]="true"
            [editable]="editable"
            [template]="mailserver3"
            (onSelect)="setInvoicingSMTP($event)">
            <jbm-input-error [formControl]="formControls.invoicing_mail_settings_id" [submitted]="isSubmitted">
              {{'entity.requiredfield'|translate}}
            </jbm-input-error>
          </mail-settings-selector>
          <ng-template #mailserver3 let-settings="settings">
            <div *ngIf="settings">
              {{settings.description}}<br />
              {{settings.name}}
            </div>
          </ng-template>
        </fieldset>
      </div>
    </div>
</form>
<jbm-toasts></jbm-toasts>

<jbm-modal [id]="'recipeDetails'" [className]="'recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
  <recipe-details [recipe_id]="baseRecipe.id" [environment]="baseRecipe.environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>
