<div class="jbm-view-title" [ngClass]="{'has-parent-slug': parentLinkSlug, 'sm': size=='sm'}">
    <div class="clearfix">
        <div *ngIf="!linkSlug" class="title"><span *ngIf="iconClassname"><i class="fas fa-{{iconClassname}}"></i>&nbsp;</span>{{caption}}</div>
        <div *ngIf="linkSlug" class="title"><a routerLink="/{{linkSlug}}">{{caption}}</a></div>
        <div *ngIf="itemcount>0" class="count"><span class="badge bg-light text-dark">{{itemcount}}</span></div>
        <div *ngIf="subCaption" class="separator"></div>
        <div *ngIf="subCaption" class="sub-caption">{{subCaption}}</div>
    </div>
    <div *ngIf="parentLinkSlug" class="parent-slug">
        <a routerLink="/{{parentLinkSlug}}"><i class="fa fa-chevron-left"></i> {{parentLinkCaption}}</a>
    </div>
</div>

