import {
  AfterViewInit,
  Component, ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UserService} from "../data/user.service";
import {user_group, User_GroupService} from "../data/user_group.service";
import {UsergroupService} from "../data/usergroup.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {LanguagesService} from "../../../services/multilingual/languages.service";
import {TokenService} from "../../../services/auth/token.service";
import {FormValidationService} from "../../../services/validation/form.validation.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {User} from "../data/interfaces/user";
import {GroupRights} from "../data/interfaces/grouprights";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {CRUDAction} from "../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {BodyClassTogglerService} from "../../../services/interface/body-class-toggler.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'form-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  action: CRUDAction;
  languages: any[]=this.LanguagesService.GetLanguages();
  userRights: GroupRights=this.TokenService.getRightsByName('users');

  userForm: FormGroup;
  isSubmitted: boolean=false;
  linkedGroups: any[]=[];
  groups: user_group[]=[];
  groupsChecked: boolean=true;

  usergroups$: Subscription;
  usergroups: any[]=[];
  recipeGroups$: Subscription;
  recipeGroups: any[]=[];

  @Input() user: User;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();
  @ViewChild('userPasswordModal') modalTemplate: ElementRef;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      public UserService: UserService,
      private UsergroupService: UsergroupService,
      private User_GroupService: User_GroupService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private LanguagesService: LanguagesService,
      private ModalService: ModalService,
      private formBuilder: FormBuilder,
      private FormValidationService: FormValidationService,
      private BodyClassTogglerService: BodyClassTogglerService,
      private JBMToastsService: JBMToastsService
  ) {
    this.addControls();
  }
  ngOnInit() {
    if(this.usergroups==undefined) this.usergroups=[];
    this.setControlValues();
    this.getUsergroups();
  }
  ngOnChanges() {
    if(this.user.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;
  }
  ngAfterViewInit(): void {
    document.getElementById('username').focus();
  }
  getUsergroups() {
    // Get managagement app groups
    this.usergroups$=this.UsergroupService.getData(0).subscribe(
      (data: any)=>this.usergroups=data.data,(error)=> console.error(error),()=> {
        // Get recipe app groups
        this.recipeGroups$=this.UsergroupService.getData(1).subscribe(
            (data: any) => this.recipeGroups=data.data,(error)=> console.error(error),() => {
              this.setLinkedUsergroups();
        });
    })
  }
  addControls() {
    this.userForm = this.formBuilder.group(
        {
          username: new FormControl('',{updateOn: 'blur', validators: [Validators.required]} ),
          full_name: new FormControl('',{updateOn: 'blur', validators: [Validators.required]} ),
          language_choice: new FormControl('',{updateOn: 'blur', validators:[Validators.required]} ),
          active: new FormControl(true),
          passwordNew: new FormControl('',[this.FormValidationService.passwordValidator()]),
          passwordConfirm: new FormControl('',[this.FormValidationService.passwordValidator()])
        }
    );
  }
  setControlValues() {
    this.userForm.controls.username.setValue(this.user.username);
    this.userForm.controls.full_name.setValue(this.user.full_name);
    this.userForm.controls.language_choice.setValue(this.user.language_choice);
    this.userForm.controls.active.setValue(this.user.active);
    this.userForm.controls.passwordNew.setValue('');
    this.userForm.controls.passwordConfirm.setValue('');
  }
  setLinkedUsergroups() {
    if(this.user.id) {
      // Set linked usergroups
      this.User_GroupService.getGroups(this.user.id).subscribe(
          (data: any) => this.linkedGroups = data.data,(error) =>console.error(error),
          () => {
            // Add checked (linked status) property to management app usergroups
            for (let u = 0; u < this.usergroups.length; u++) {
              let item = this.linkedGroups.find(item => item.group_id === this.usergroups[u].id);
              this.usergroups[u].checked = (item !== undefined);
            }
            // Add checked (linked status) property to recipe app usergroups
            for (let u = 0; u < this.recipeGroups.length; u++) {
              let item = this.linkedGroups.find(item => item.group_id === this.recipeGroups[u].id);
              this.recipeGroups[u].checked = (item !== undefined);
            }
          }
      );
    } else {
      // Set empty usergroups lists
      for (let u = 0; u < this.usergroups.length; u++) {
        this.usergroups[u].checked = false;
      }
      for (let u = 0; u < this.recipeGroups.length; u++) {
        this.recipeGroups[u].checked = false;
      }
    }
  }
  get formControls() {
    return this.userForm.controls;
  }

  passwordChange() {
    this.ModalService.open(this.modalTemplate,'userpassword');
  }

  onPasswordChange() {
    this.ModalService.close('userpassword');
  }
  onPasswordChangeCancel() {
    this.ModalService.close('userpassword');
  }
  groupChange(u) {
    console.log(u);
    u.checked=!u.checked;
    this.checkGroups();
  }
  checkGroups() {
    this.groupsChecked=false;
    for (let u = 0; u < this.usergroups.length; u++) {
      if(this.usergroups[u].checked) {
        this.groupsChecked=true;
        break;
      }
    }
    return this.groupsChecked;
  }
  recipeGroupChange(u) {
    u.checked=!u.checked;
  }
  save() {
    this.isSubmitted = true;

    if(this.userForm.invalid)
      return;

    // Check confirmed password
    if(this.formControls.passwordNew.value!=this.formControls.passwordConfirm.value)
      this.formControls.passwordConfirm.setErrors({confirmInvalid: true});

    if(!this.checkGroups()) return;

    // Store user
    let data = {
      id: this.user.id,
      username: this.formControls.username.value,
      full_name: this.formControls.full_name.value,
      password: this.formControls.passwordNew.value,
      language_choice: this.formControls.language_choice.value,
      active: this.formControls.active.value
    }

    if(data.id===0) {
      this.UserService.create(data).subscribe(
          (data)=>this.user.id=data.data.id,error => this.handleError(error),
          () => { this.handleGroups() })
    } else {
      this.UserService.update(data).subscribe(
          ()=>{},error => { this.handleError(error) },
          () => { this.handleGroups() })
    }
  }
  afterSave() {
    this.onSave.emit();
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  cancel() {
    this.onCancel.emit();
  }
  handleError(error) {
    if(error=='not_unique')
      this.formControls.username.setErrors({ notUnique: true});
  }
  handleGroups() {
    // Transform management app usergroup selection into user_group records
    for(let g=0; g<this.usergroups.length; g++)
      if(this.usergroups[g].checked)
        this.groups.push(
            {user_id: this.user.id, group_id: this.usergroups[g].id} );

    // Transform recipe app usergroup selection into user_group records
    for(let g=0; g<this.recipeGroups.length; g++)
      if(this.recipeGroups[g].checked)
        this.groups.push(
            {user_id: this.user.id, group_id: this.recipeGroups[g].id} );

    // Save the user-group records
    this.User_GroupService.update(this.groups).subscribe(
        ()=>{},error=>console.error(error),() => this.afterSave())
  }
  ngOnDestroy() {
    if(this.usergroups$!=undefined) this.usergroups$.unsubscribe();
    if(this.recipeGroups$!=undefined) this.recipeGroups$.unsubscribe();
  }
}
