import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'jbm-aside-title',
  templateUrl: './JBMAsideTitle.component.html',
  styleUrls: ['./JBMAsideTitle.component.scss']
})
export class JBMAsideTitleComponent implements OnInit {
  @Input() caption: string;
  @Input() iconClassName: string;
  @Output() onClose=new EventEmitter();

  constructor() { }

  ngOnInit(): void {
    if(this.caption==undefined) this.caption='';
    if(this.iconClassName==undefined) this.iconClassName='';
  }
  close() {
    return this.onClose.emit();
  }
}
