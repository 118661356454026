import {Component, Input, OnInit} from '@angular/core';

export interface JBMCheckGroupItem {
  checked: boolean;
  text: string;
}

@Component({
  selector: 'jbm-check-group',
  templateUrl: './JBMCheckGroup.component.html',
  styleUrls: ['./JBMCheckGroup.component.scss']
})
export class JBMCheckGroupComponent implements OnInit {

  @Input() items: JBMCheckGroupItem[]=[];

  constructor() { }

  ngOnInit(): void {
  }

}
