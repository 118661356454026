<form [formGroup]="form" (ngSubmit)="save()" class="approve">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'entity.quotation'|translate}} {{'entity.approve'|translate|lowercase}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label [labelCaption]="'quotation.number'|translate" [value]="quotationNumber"></jbm-static-label>
      <jbm-textarea [id]="'comments'" [formControl]="formControls.comments" labelStacked="true" class="lg"
        [labelCaption]="'entity.comments'|translate"></jbm-textarea>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-sm btn-primary" (click)="save()">{{'entity.quotation'|translate}} {{'entity.approve'|translate|lowercase}}</button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
