import {Component, EventEmitter, Input, OnInit, AfterViewInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {UsergroupService} from "../data/usergroup.service";
import {Usergroup} from "../data/interfaces/usergroup";
import {CRUDAction} from "../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {AppsService} from '../../../services/apps.service';

@Component({
  selector: 'form-usergroup',
  templateUrl: './usergroup.component.html',
  styleUrls: ['./usergroup.component.scss']
})
export class UsergroupComponent implements OnInit, AfterViewInit {
  action: CRUDAction;
  appOptions: any[]=this.AppsService.getSelectOptions(false)
  form: FormGroup;
  submitted: boolean=false;
  unique: boolean=true;

  @Input() userRights: any;
  @Input() usergroup: Usergroup;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
      private AppsService: AppsService,
      public UsergroupService: UsergroupService,
      private formBuilder: FormBuilder ) {
    this.addControls();
  }

  ngOnInit(): void {
      if(this.usergroup.id===0)
        this.action=CRUDAction.Create;
      else
        this.action=CRUDAction.Update;
      this.setControlValues();
  }
  ngAfterViewInit(): void {
    document.getElementById('name').focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
        app: new FormControl('0', {updateOn: 'blur', validators: [Validators.required]} ),
        name: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} ),
        description: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} )
    });
  }
  setControlValues() {
    this.form.controls.app.setValue(this.usergroup.app);
    this.form.controls.name.setValue(this.usergroup.name);
    this.form.controls.description.setValue(this.usergroup.description);
  }
  get formControls() {
    return this.form.controls;
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    // Store usergroup
    let data = {
      id: this.usergroup.id,
      name: this.formControls.name.value,
      description: this.formControls.description.value,
    }
    if(data.id===0) {
      this.UsergroupService.insert(data).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    } else {
      this.UsergroupService.update(data).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    }
  }
  handleError(error) {
    if(error=='not_unique')
      this.formControls.name.setErrors({ notUnique: true});
    else
      console.error(error);
  }
  cancel() {
    this.onCancel.emit();
  }
}


