import {Component, OnInit, OnDestroy} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {UnloadingMethodsService} from "../data/unloading-methods.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {ValidationPatternsService} from "../../../services/validation/patterns.service";
import {JBMTableColumnDef} from "../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'unloading-speeds',
  templateUrl: './unloading-methods.component.html',
  styleUrls: ['./unloading-methods.component.scss']
})
export class UnloadingMethodsComponent implements OnInit, OnDestroy {
  userRights: any;
  items: any[];
  columnDefs: JBMTableColumnDef[];
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';

  createActive: boolean=false;
  speed: string='';
  speedError: string='';
  speedTouched: boolean=false;
  description: string='';
  descriptionError: string='';
  descriptionTouched: boolean=false;
  editing: boolean=false;
  unique:boolean=true;

  constructor(
      public UnloadingSpeedsService: UnloadingMethodsService,
      public modalService: NgbModal,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService,
      private ValidationPatternsService: ValidationPatternsService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('projects');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('speed');
    this.queryHttpHelper.addSearchColumn('code', SearchColumnType.string);

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.UnloadingSpeedsService.get(this.language, params).subscribe(
        (data: any) => this.items=data.data,()=>{},()=>{
          this.loading=false;

          this.formatData();

          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.UnloadingSpeedsService.get(this.language, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  formatData() {
    for(let item of this.items) {
      item.speed=item.speed.toString().replace('.',',');
      // Set editmode to false for item
      item.editing=false;
    }
  }

  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row alreay in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.speed=data.speed;
    this.speedTouched=true;
    this.description=data.description;
    this.descriptionTouched=true;
    this.unique=true;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData(false);
  }
  onChangeSpeed() {
    this.speedTouched=true;
  }
  validateSpeed() {
    let value=this.speed.trim();
    if(!value) {
      this.speedError=this.TranslateService.GetTranslation('entity.requiredfield');
      return false;
    }

    let reg=RegExp( this.ValidationPatternsService.floatPattern(2));
    if(!reg.test(value)) {
      this.speedError=this.TranslateService.GetTranslation('entity.amount-2-decimal-format');
      return false;
    }
    return true;
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.descriptionError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    this.UnloadingSpeedsService.create(data).subscribe(()=>{},
       error => { this.handleError(error) },() => { this.afterSave() })
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.speed==this.speed && originalValues.description==this.description) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }
    let data=this.setItemData(originalValues.id);
    this.UnloadingSpeedsService.update(data).subscribe(
        ()=>{},error => { this.handleError(error) },
        () => { this.afterSave() })
  }
  getValues() {
    this.speed=this.speed.trim();
    this.description=this.description.trim();
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    this.speed=this.speed.replace(',','.');
    return { id: id, speed: parseFloat(this.speed), l: { language: this.language, description: this.description } }
  }
  clearItemData() {
    this.speed='';
    this.speedTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  handleError(error) {
    this.unique=true;
    if(error=='not_unique') {
      this.unique=false;
      this.descriptionError=this.TranslateService.GetTranslation('entity.description-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) {
      this.createCancel();
      this.getData(true);
    }
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
