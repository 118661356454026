<header [class]="class" [ngClass]="{'module': module_header}">
  <ng-content></ng-content>
  <div *ngIf="module_header" class="toolbar float-end pl-2 pr-2">
    <div ngbDropdown class="appDropdown">
      <button type="button" class="btn btn-light float-start" id="appDropdown" ngbDropdownToggle>
        <i class="fa fa-th"></i>
      </button>
      <div ngbDropdownMenu aria-labelledby="appDropdown">
        <button type="button" ngbDropdownItem>
          <img src="assets/icons/receptuur.png">
          <br />Receptuur
        </button>
        <button type="button" ngbDropdownItem>
          <img src="assets/icons/lab.png">
          <br />Lab
        </button>
      </div>
    </div>
  </div>
</header>
