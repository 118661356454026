import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {forkJoin} from 'rxjs';
import {CRUDAction} from '../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component';
import {JBMSelectOption} from '../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';
import {JBMRadioOption} from '../../../components/JBM/Forms/JBMFormGroups/JBMRadioGroup/JBMRadioGroup.component';
import {TokenService} from '../../../services/auth/token.service';
import {TranslateService} from '../../../services/multilingual/translate.service';
import {Presentation} from '../data/interfaces/presentation';
import {PresentationsService} from '../data/presentations.service';
import {presentation_types, PresentationTypesService} from '../data/presentation-types.service';
import {presentation_usages, PresentationUsagesService} from '../data/presentation-usages.service';
import {OutputTypesService, presentation_output_types} from '../data/output-types.service';
import {defaultPageSize} from '../../../components/JBM/Helpers/QueryHttpHelper';

@Component({
  selector: 'form-presentation',
  templateUrl: './presentation.component.html',
  styleUrls: ['./presentation.component.scss']
})
export class PresentationComponent implements OnInit, AfterViewInit {
  action: CRUDAction;
  language: string = this.TokenService.getLanguage();
  presentationTypes: JBMSelectOption[] = [];
  presentationUsages: JBMRadioOption[] = [
      {
        value: 0,
        label: this.TranslateService.GetTranslation('entity.project') + ' (' + this.TranslateService.GetTranslation('presentations.usage-project-info') + ')'
      },
      {
        value: 1,
        label: this.TranslateService.GetTranslation('entity.customer') + ' (' + this.TranslateService.GetTranslation('presentations.usage-customer-info') + ')'
      }
  ];
  outputTypes: JBMRadioOption[];
  form: FormGroup;
  data: Presentation;

  isAlwaysDefault: boolean;
  // Is true when there's only one presentation occurrence of a specific presentation and output type combination
  // Rule applies only if editing presentation properties

  submitted: boolean = false;

  @Input() presentation: Presentation;
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();

  constructor(
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private PresentationsService: PresentationsService,
      private PresentationTypesService: PresentationTypesService,
      private PresentationUsagesService: PresentationUsagesService,
      private OutputTypesService: OutputTypesService,
      private formBuilder: FormBuilder
  ) {
    this.presentation = this.PresentationsService.getEmpty();
    this.addControls();
  }

  ngOnInit(): void {
    if (this.presentation.id === 0)
      this.action = CRUDAction.Create;
    else
      this.action = CRUDAction.Update;

    this.isAlwaysDefault = false;

    this.presentationTypes = [];
    this.outputTypes = [];

    let ptObservable = this.PresentationTypesService.get(this.language, null);
    let otObservable = this.OutputTypesService.get(this.language, null);
    forkJoin([ptObservable, otObservable]).subscribe(
        results => {
          let types = results[0].data;
          for (let t = 0; t < types.length; t++)
            this.presentationTypes.push({key: types[t].id, value: types[t].description});
          types = results[1].data;
          for (let t = 0; t < types.length; t++)
            this.outputTypes.push({value: types[t].id, label: types[t].description});
        },
        (error) => console.error(error),
        () => {
          this.PresentationsService.getIsAlwaysDefault(this.presentation.id).subscribe(
              (data) => this.isAlwaysDefault = data.data,
              (error) => console.error(error),
              () => {
                this.setControlValues()
              })
        }
    );
  }

  ngAfterViewInit(): void {
    if (this.presentation.id === 0)
      document.getElementById('presentation_type').focus();
    else
      document.getElementById('description').focus();
  }

  addControls() {
    this.form = this.formBuilder.group({
      presentation_type: new FormControl('', {validators: [Validators.required]}),
      output_type: new FormControl('', {validators: [Validators.required]}),
      usage: new FormControl('', {validators: [Validators.required]}),
      shifted_vat: new FormControl(false),
      description: new FormControl('', {validators: [Validators.required]}),
      is_default: new FormControl(false),
    }, {updateOn: "blur"});
  }

  setControlValues() {
    this.form.controls.presentation_type.setValue(
        this.presentation.presentation_type === null ? '' : this.presentation.presentation_type.toString());
    this.form.controls.output_type.setValue(this.presentation.output_type === null ?
        presentation_output_types.pdf.toString() : this.presentation.output_type.toString());
    this.form.controls.usage.setValue(
        this.presentation.usage === null ? '' : this.getUsage().toString());
    this.form.controls.shifted_vat.setValue(this.getShiftedTax());
    this.form.controls.description.setValue(this.presentation.description);
    this.form.controls.is_default.setValue(this.presentation.is_default || this.isAlwaysDefault);
  }

  get formControls() {
    return this.form.controls;
  }

  getUsage(): number {
    if(this.presentation.presentation_type === presentation_types.invoice)
      switch(this.presentation.usage) {
        case presentation_usages.invoice_customer_shifted_vat:
          return presentation_usages.invoice_customer;
        case presentation_usages.invoice_shifted_vat:
          return presentation_usages.default_usage;
        default:
          return this.presentation.usage;
      }
    return this.presentation.usage;
  }

  getShiftedTax() {
    return this.presentation.presentation_type === presentation_types.invoice &&
        (this.presentation.usage === presentation_usages.invoice_shifted_vat || this.presentation.usage === presentation_usages.invoice_customer_shifted_vat);
  }

  typeChange(event) {
    this.presentation.presentation_type = parseInt(event.target.value);
    this.form.controls.shifted_vat.setValue(this.getShiftedTax());
  }

  save() {
    this.submitted = true;

    if (this.form.invalid)
      return;

    let type = parseInt(this.formControls.presentation_type.value);
    let usage = presentation_usages.default_usage;
    let shifted_vat = this.formControls.shifted_vat.value;
    if (type === presentation_types.invoice) {
      usage = parseInt(this.formControls.usage.value);
      if (usage === 0 && shifted_vat)
        usage = presentation_usages.invoice_shifted_vat;
      if (usage === 1 && !shifted_vat)
        usage = presentation_usages.invoice_customer;
      if (usage === 1 && shifted_vat)
        usage = presentation_usages.invoice_customer_shifted_vat;
    }

    // Store presentation
    this.data = {
      id: this.presentation.id,
      presentation_type: type,
      output_type: parseInt(this.formControls.output_type.value),
      usage: usage,
      description: this.formControls.description.value,
      language: this.language,
      is_default: this.formControls.is_default.value,
      is_system: this.presentation.is_system
    }
    if(this.data.id===0) {
      this.PresentationsService.create(this.data).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    } else {
      this.PresentationsService.update(this.data).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    }
  }

  handleError(error) {
    if (error == 'not_unique') {
      this.formControls.description.setErrors({notUnique: true});
      document.getElementById('description').focus();
    } else
      console.error(error);
  }

  cancel() {
    this.onCancel.emit();
  }
}
