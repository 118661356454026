import { Injectable } from '@angular/core';
import { HttpService} from "../http.service";
import {HttpParams} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LogService {

  constructor(private HttpService: HttpService) { }

  getData(params: HttpParams) {
    return this.HttpService.get('log/read/view', params);
  }
  getSubjectData(subjecttype: number, subjectid: number, params: HttpParams) {

    // Insert subject and subject_id filters before other filter settings
    let filter=params.get('filter');
    let subjectfilter='subject_type eq '+subjecttype+' and subject_id eq '+subjectid;
    if(!filter)
      filter=subjectfilter;
    else
      filter=subjectfilter+' and '+filter;

    // Reset filter parameter
    params=params.set('filter', filter);

    return this.HttpService.get('log/read/view', params);
  }

}
