import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'jbm-edit-button',
  templateUrl: './JBMEditButton.component.html',
  styleUrls: ['./JBMEditButton.component.scss']
})
export class JBMEditButtonComponent {
  @Input() showIcon: boolean=true;
  @Input() caption: string='';
  @Input() tooltipPlacement: string='bottom';
  @Input() class: string='btn-sm';

  @Output() onClick = new EventEmitter();

  click() {
    this.onClick.emit();
  }
}
