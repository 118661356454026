import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {InvoiceResource} from "./interfaces/invoice-resource";

const baseURL: string = 'invoicing/';
const segment: string = 'invoice-resources/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceResourcesService {
  invoiceResource: InvoiceResource;

  constructor(private HttpService: HttpService) {}

  get(language: string, invoice_id: number, invoice_item_id: number, usage: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+invoice_id.toString()+'/'+invoice_item_id.toString()+'/'+usage.toString(), params );
  }
  getSelectList(language: string, invoice_id: number, invoice_item_id:number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+'selectlist/'+language+'/'+invoice_id+'/'+invoice_item_id, params );
  }
  getEmpty() {
    this.invoiceResource = {
      id: 0,
      invoices_id: 0,
      invoice_items_id: null,
      delivery_date: '',
      resources_id: 0,
      description: '',
      amount: 0,
      price: 0,
      totalprice: 0
    }
    return this.invoiceResource;
  }
  calcTotalPrice(price: number, amount: number, invoice_item_amount: number) {
      return (invoice_item_amount * amount) * price;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
  delete(invoice_resource_id: number) {
    return this.HttpService.get(baseURL+'delete/'+segment+invoice_resource_id.toString());
  }
}