import {Injectable} from '@angular/core';
import {TranslateService} from '../../../services/multilingual/translate.service';
import {presentation_types} from './presentation-types.service';

export enum presentation_usages { default_usage, invoice_customer, invoice_shifted_vat, invoice_customer_shifted_vat}

@Injectable({
  providedIn: 'root'
})
export class PresentationUsagesService {
  constructor(private TranslateService:TranslateService) {}

  getLabels(type: presentation_types, usage: presentation_usages) {
      let labels=[];
      switch(type) {
          case presentation_types.quotation:
              labels.push(this.TranslateService.GetTranslation('entity.project'));
              break;
          case presentation_types.quotationConfirmation:
              labels.push(this.TranslateService.GetTranslation('entity.project'));
              break;
          case presentation_types.invoice:
              switch(usage) {
                  case presentation_usages.default_usage:
                      labels.push(this.TranslateService.GetTranslation('entity.project'));
                      break;
                  case presentation_usages.invoice_customer:
                      labels.push(this.TranslateService.GetTranslation('entity.customer'));
                      break;
                  case presentation_usages.invoice_shifted_vat:
                      labels.push(this.TranslateService.GetTranslation('entity.project'));
                      labels.push(this.TranslateService.GetTranslation('invoicing.shifted-vat'));
                      break;
                  case presentation_usages.invoice_customer_shifted_vat:
                      labels.push(this.TranslateService.GetTranslation('entity.customer'));
                      labels.push(this.TranslateService.GetTranslation('invoicing.shifted-vat'));
                      break;
              }
      }
      return labels;
  }
}
