import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";

const baseUrl='recipe-app/';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  constructor(private HttpService: HttpService) { }

  get() {
    return this.HttpService.get(baseUrl+'read/permissions');
  }
}
