import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { Customer } from "./interfaces/customer";
import { Address } from "../../addresses/data/interfaces/address";
import { AddressesService} from "../../addresses/data/addresses.service";

const BaseURL: string = 'customers/';

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    customer: Customer;
    address: Address;

    constructor(
        private HttpService: HttpService,
        private AddressesService: AddressesService
    ) {}

    getData(params: HttpParams, withAddresses: boolean=false) {
        let url=BaseURL+'read/';
        if(withAddresses) url+='addresses';
        return this.HttpService.get(url, params );
    }
    getEmpty() {
        this.customer= {
            id: 0,
            debtor_id: '',
            name: '',
            full_name: '',
            address_id: 0,
            address_details: this.AddressesService.getEmpty(),
            phone: '',
            email: '',
            description: '',
            blocked: false,
            invoice_email: '',
            invoice_address_id: 0,
            invoice_address_details: this.AddressesService.getEmpty(),
            coc: '',
            customer_type: 1,
            baseprice: 0.0,
            baseprice_discount: 0.0
        }
        return this.customer;
    }
    get(id: number, addresses=false) {
        let params=new HttpParams();
        params=params.append('id',id.toString());
        let url=BaseURL+'read/one';
        if(addresses)
            // Include customer addresses data
            url+='/addresses';
        return this.HttpService.get(url, params );
    }

    create(data: any) {
        return this.HttpService.post(BaseURL+'create',data);
    }
    update(data: any) {
        return this.HttpService.post(BaseURL+'update', data);
    }
}
