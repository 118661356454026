import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from "@angular/common";
import {ArticlePricelistsService, PricelistsType} from "../../data/article-pricelists.service";
import {CustomerService} from "../../../customers/data/customer.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {Customer} from "../../../customers/data/interfaces/customer";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {ArticlePricelist} from "../../data/interfaces/article-pricelist";

@Component({
  selector: 'form-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss']
})

export class PricelistComponent implements OnInit, AfterViewInit, OnChanges {
  action: CRUDAction;
  customer: Customer;
  caption: string;
  activity_subject: string;
  language: string;
  type_options: any;
  data: any;
  warning: string;
  warningOverlap: string;
  form: FormGroup;
  isSubmitted: boolean=false;

  @Input() pricelistsType: PricelistsType=PricelistsType.articles;
  @Input() pricelist: ArticlePricelist;
  @Input() customer_id: number=0;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;

  constructor(
      private Router: Router,
      private Location: Location,
      private ActivatedRoute: ActivatedRoute,
      private formBuilder: FormBuilder,
      private ArticlePricelistsService: ArticlePricelistsService,
      private CustomerService: CustomerService,
      private TranslateService: TranslateService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService,
      private DatetimeService: DatetimeService
  ) {
    this.pricelist=this.ArticlePricelistsService.getEmpty();
    this.customer=this.CustomerService.getEmpty();
  }
  ngOnInit(): void {
    if(this.pricelistsType===PricelistsType.articles) {
      this.caption='financial.article-pricelist';
      this.activity_subject=this.TranslateService.GetTranslation('financial.article-pricelist');
    } else {
      this.caption='financial.concrete-pricelist';
      this.activity_subject=this.TranslateService.GetTranslation('financial.concrete-pricelist');
    }

    this.language=this.TokenService.getLanguage();
    this.warning='';
    this.addControls();
    this.type_options=this.ArticlePricelistsService.getTypeSelectOptions(this.TranslateService);
    this.setControlValues();

    if(this.customer_id===0)
      return;

    // Remove basic prices option
    this.type_options.splice(0,1);

    this.CustomerService.get(this.customer_id, true).subscribe(
        (data)=>this.customer=data.data as Customer,
        (error)=>console.log(error)
    )
  }
  ngOnChanges() {
    if(this.pricelist.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;
  }
  ngAfterViewInit(): void {
    document.getElementById('description').focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      description: new FormControl('',[Validators.required]),
      valid_from: new FormControl(0,[this.DatetimeService.dateValidator(this.DatetimeService,true)]),
      valid_to: new FormControl(0,[this.DatetimeService.dateValidator(this.DatetimeService,true)]),
      type: new FormControl('',[Validators.required])
    },{
      updateOn: "blur"
    });
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    if(!this.pricelist.valid_from)
      this.pricelist.valid_from=
          this.DatetimeService.getDateDMYFromParts(1,1, this.DatetimeService.getCurrentYear());
    if(!this.pricelist.valid_to)
      this.pricelist.valid_to=
          this.DatetimeService.getDateDMYFromParts(31,12, this.DatetimeService.getCurrentYear());

    this.form.controls.description.setValue(this.pricelist.description);
    this.form.controls.valid_from.setValue(this.pricelist.valid_from);
    this.form.controls.valid_to.setValue(this.pricelist.valid_to);
    if(this.pricelist.type>-1)
      this.form.controls.type.setValue(this.pricelist.type.toString());
  }

  // Customer
  showCustomer() {
    this.ModalService.open(this.modalCustomerDetails,'customerDetails');
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }

  save() {
    this.isSubmitted = true;

    let valid_from=this.DatetimeService.dateDMYToYMD(this.formControls.valid_from.value);
    let valid_to  =this.DatetimeService.dateDMYToYMD(this.formControls.valid_to.value);
    if(valid_from>valid_to) {
      this.warning=this.TranslateService.GetTranslation('entity.to-date-before-from-date');
      return;
    }

    if(this.form.invalid)
      return;

    let data={
      id: this.pricelist.id,
      valid_from: valid_from,
      valid_to: valid_to,
      type: parseInt(this.formControls.type.value),
      l: {language: this.language, description: this.formControls.description.value},
    }

    if(this.pricelist.id===0) {
        if(this.customer_id>0)
          data['customer_id']=this.customer_id;
        this.ArticlePricelistsService.create(this.pricelistsType, data).subscribe(
          ()=>{},(error)=>this.handleError(error),()=>this.afterSave())
    } else
      this.ArticlePricelistsService.update(this.pricelistsType, data).subscribe(
          ()=>{},(error)=>this.handleError(error),()=>this.afterSave())
  }
  handleError(error) {
    if(error.error==='period_overlap') {
      this.warningOverlap=this.TranslateService.GetTranslation('pricelist.validity-error');
      this.data=error.data;
      document.getElementById('valid_from').focus();
    } else
      console.error(error);
  }
  afterSave() {
    this.onSave.emit();
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  cancel() {
    this.onCancel.emit();
  }
}
