import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {InvoicesService} from '../data/invoices.service';
import {PresentationsService} from '../../presentations/data/presentations.service';
import {PresentationUsesService} from '../../presentations/data/presentation-uses.service';
import {TokenService} from '../../../services/auth/token.service';
import {Invoice} from '../data/interfaces/invoice';
import {presentation_types} from '../../presentations/data/presentation-types.service';
import {presentation_output_types} from '../../presentations/data/output-types.service';

@Component({
  selector: 'invoice-settings',
  templateUrl: './invoice-settings.component.html',
  styleUrls: ['./invoice-settings.component.scss']
})
export class InvoiceSettingsComponent implements OnInit {
  invoice: Invoice=this.InvoicesService.getEmpty();
  form: FormGroup;
  language: string=this.TokenService.getLanguage();
  submitted: boolean=false;

  constructor(
      private TokenService: TokenService,
      private formBuilder: FormBuilder,
      private InvoicesService: InvoicesService,
      private PresentationsService: PresentationsService,
      private PresentationUsesService: PresentationUsesService,
  ) {
    this.addControls();
  }

  @Input() invoice_id: number;

  @Output() onClose=new EventEmitter();
  @Output() onSucces=new EventEmitter();

  ngOnInit(): void {
    if(this.invoice_id==undefined) this.invoice_id=0;
    this.addControls();
    this.InvoicesService.getOne(this.language, this.invoice_id,null).subscribe(
        (data)=>this.invoice=data.data,
        (error)=>console.error(error),
        ()=> {
          this.setControlValues();
          // Set presentation uses
          this.PresentationUsesService.get(presentation_types.invoice, this.invoice.id, presentation_output_types.pdf).subscribe(
              (data)=>{
                  this.form.controls.pdf_presentations_id.setValue(data.data);
                  this.PresentationUsesService.get(presentation_types.invoice, this.invoice.id, presentation_output_types.mail).subscribe(
                     (data)=>this.form.controls.mail_presentations_id.setValue(data.data),
                     (error)=>{
                        if(error==='not_found')
                            this.setDefaultPresentations()
                  })
              },
              (error)=>{
                if(error==='not_found')
                    this.setDefaultPresentations()
              })
        })
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          term_of_payment: new FormControl('', {updateOn: 'blur',
            validators: [Validators.required, Validators.min(1), Validators.max(365), Validators.pattern('[1-9][0-9]*')]} ),
          pdf_presentations_id: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} ),
          mail_presentations_id: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} ),
          resources_based_invoicing: new FormControl(false),
          shifted_vat: new FormControl(false),
        }
    );
  }
  setControlValues() {
    this.form.controls.term_of_payment.setValue(this.invoice.term_of_payment);
    this.form.controls.resources_based_invoicing.setValue(this.invoice.resources_based_invoicing);
    this.form.controls.shifted_vat.setValue(this.invoice.shifted_vat);
  }
  get formControls() {
    return this.form.controls;
  }
  setPDFPresentation(event) {
    this.formControls.pdf_presentations_id.setValue(event.id);
  }
  setMailPresentation(event) {
    this.formControls.mail_presentations_id.setValue(event.id);
  }
  shiftedVatChange(event) {
    console.log(event);
    this.invoice.shifted_vat=this.form.controls.shifted_vat.value;
    this.setDefaultPresentations();
  }
  setDefaultPresentations() {
    // Set default PDF presentation
    this.PresentationsService.getDefaultID(
        presentation_types.invoice,
        presentation_output_types.pdf,
        this.InvoicesService.getPresentationUsage(this.invoice)).subscribe(
        (data: any)=>this.formControls.pdf_presentations_id.setValue(data.data.id),
        (error)=>console.error(error),
        ()=> {
          // Set default Mail presentation
          this.PresentationsService.getDefaultID(
              presentation_types.invoice,
              presentation_output_types.mail,
              this.InvoicesService.getPresentationUsage(this.invoice)).subscribe(
              (data: any)=>this.formControls.mail_presentations_id.setValue(data.data.id),
              (error)=>console.error(error)
          )
        })
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let data={
      id: this.invoice_id,
      resources_based_invoicing: this.form.controls.resources_based_invoicing.value,
      shifted_vat: this.form.controls.shifted_vat.value,
      term_of_payment: parseInt(this.form.controls.term_of_payment.value)
    }
    this.InvoicesService.update(data).subscribe(()=>{
      // Save presentation settings
      this.PresentationUsesService.deleteFromSubject(
          presentation_types.invoice, this.invoice_id).subscribe(
          ()=>{
            this.PresentationUsesService.create(
                presentation_types.invoice,
                this.invoice_id,
                parseInt(this.form.controls.pdf_presentations_id.value)).subscribe(
                ()=>{
                  this.PresentationUsesService.create(
                      presentation_types.invoice,
                      this.invoice_id,
                      parseInt(this.form.controls.mail_presentations_id.value)).subscribe(
                      ()=>{},
                      (error)=>console.error(error),
                      ()=>this.onSucces.emit()
                  )
                },(error)=>console.error(error)
            )
          },(error)=>console.error(error)
      )
    },(error)=>console.error(error))
  }
  close() {
    this.onClose.emit();
  }
}
