import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {QuotationState} from "../../../../features/quotations/data/interfaces/quotation-state";

@Component({
  selector: 'jbm-quotationstate-dropdown',
  templateUrl: './JBMQuotationStateDropdown.component.html',
  styleUrls: ['./JBMQuotationStateDropdown.component.scss']
})
export class JBMQuotationStateDropdownComponent implements OnInit {

  @Input() id: number;
  @Input() quotationState: any;
  @Input() quotationStates: QuotationState[];

  @Output() quotationStateChanged = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }
  setQuotationState(id) {
    this.id=id;
    this.quotationStateChanged.emit(id);
  }
}
