import {Component, EventEmitter, Input, OnInit, Output,} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../services/storage/state.service";
import {PlanningService} from "../data/planning.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {TokenService} from "../../../services/auth/token.service";
import {GroupRights} from '../../users/data/interfaces/grouprights';

@Component({
  selector: 'planning-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent implements OnInit {
  userRights: GroupRights;
  order_id: number=0;
  customer_id: number=0;
  plant_id: number=0;
  amount: number=0.0;
  delivered: number=0.0;

  constructor(
      private StateService: StateService,
      private PlanningService: PlanningService,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private Router: Router,
      private ModalService: ModalService,
      private JBMToastsService: JBMToastsService
  ) {}

  @Input() task: any;
  @Input() showAsModal: boolean;
  @Input() compactView: boolean;
  @Input() plannable: boolean;

  @Output() onDelete = new EventEmitter();
  @Output() onInstantReceipt = new EventEmitter();
  @Output() onOpenDropdownChange=new EventEmitter();

  ngOnInit(): void {
    this.userRights=this.TokenService.getRightsByName('planning');

    let state=this.StateService.getData();
    if(state.hasOwnProperty('task')) {
      this.task=state.task;
      this.showAsModal=true;
      this.compactView=false;
      this.plannable=false;
      this.StateService.clear();
    }
  }
  openProject(project_id:number) {
    this.Router.navigateByUrl('projects/'+project_id.toString());
  }
  showInstantReceipt(task: any) {
    this.onInstantReceipt.emit(task);
  }
  confirmDelete(order_id: number) {
    this.PlanningService.remove(order_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.JBMToastsService.success(this.TranslateService.GetTranslation('planning.removed-message'));
          this.onDelete.emit();
        }
    );
  }
  popupOpenChange(opened: boolean) {
    this.onOpenDropdownChange.emit(opened);
  }
}


