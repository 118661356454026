<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked, 'readonly': !editable}">
    <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked }">
        {{labelCaption}}
        <span *ngIf="!editable">:</span>
        <span *ngIf="editable && required" class="asterix">*</span>
    </label>
    <div class="input-container controls float {{inputClass}}" [ngClass]="{'data-value': !editable }">
        <label *ngIf="!editable" class="col-form-label">{{getDataValue()}}</label>
        <input *ngIf="editable" float-only-input id="{{id}}" [type]="type" [formControl]="ngControl.control"
          [attr.maxlength]="maxlength>0 ? maxlength : null" [attr.tabindex]="tabIndex!==null ? tabIndex : null"
          (blur)="blur($event)" class="form-control align-right" [ngClass]="{'is-invalid': editable && ngControl.invalid &&
           (ngControl.touched || submitted), 'form-control-sm': sm, 'negative': negativeAllowed }"
          autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false">
        <ng-content></ng-content>
    </div>
</div>

