<jbm-modal [id]="'project'" [className]="'content'" (onClose)="close()"></jbm-modal>
<ng-template #modalProject let-modal>
<form [formGroup]="form" (ngSubmit)="save()" class="project" xmlns="http://www.w3.org/1999/html">
   <div class="jbm-modal-header">
      <jbm-crud-title [subject]="'entity.project'" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'entity.project'|translate|titlecase" (click)="save()"></jbm-save-button>
         <jbm-cancel-button context="secondary" class="ml-2" (click)="close()"></jbm-cancel-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-activity-log-button *ngIf="project.id" subject_type="3" [subject_id]="project.id" [subject]="'project'|translate"
           [title]="project.name">
         </jbm-activity-log-button>
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <div class="split-container clearfix">
         <div class="left-side">
            <jbm-text-input [formControl]="formControls.name" [submitted]="isSubmitted" [id]="'name'" [editable]="editing" class="mt-3 lg"
              required="true" labelCaption="{{'entity.name'|translate}}" labelClass="md">
               <jbm-input-error [formControl]="formControls.name" [submitted]="isSubmitted">
                  <span *ngIf="formControls.name.hasError('not_unique')">{{'entity.name-not-unique'|translate}}</span>
                  <span *ngIf="formControls.name.hasError('required')">{{'entity.requiredfield'|translate}}</span>
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.description" [submitted]="isSubmitted" [id]="'description'" [editable]="editing" class="lg"
              labelCaption="{{'entity.description'|translate}}" labelClass="md">
            </jbm-text-input>
            <jbm-select [formControl]="formControls.plants_id" [options]="plantOptions" [submitted]="isSubmitted" class="lg"
              [id]="'plants_id'" [editable]="editing" [insertLeadingOption]="true" [leadingOptionText]="''"
              labelCaption="{{'company.plant'|translate}}" labelClass="md" (onChange)="plantChange($event)" (onBlur)="updateRouteInfo()">
            </jbm-select>
            <ngb-alert *ngIf="stateError" [type]="'danger'" [dismissible]="false" class="width-34 mt-3">{{stateError}}</ngb-alert>
            <jbm-select [formControl]="formControls.projectstates_id" [options]="projectstatesOptions" [submitted]="isSubmitted" class="lg"
              [id]="'projectstates_id'" [required]="true" [editable]="editing" insertLeadingOption="true" labelCaption="{{'project.state'|translate}}"
              labelClass="md">
               <jbm-input-error [formControl]="formControls.projectstates_id" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-select>
            <customer-selector labelCaption="{{'customer'|translate}}" class="lg mt-3" labelClass="md"
              [id]="'customer-selector'"
              [customer_id]="formControls.customer_id.value"
              [template]="customerselect"
              [filter]="0"
              [required]="true"
              [isSubmitted]="isSubmitted"
              (customerSelected)="setCustomer($event)">
            </customer-selector>
            <ng-template #customerselect let-customer="customer">
               <div *ngIf="customer">
                  {{customer.name}}<br />
                  {{customer.address_details.street}}&nbsp;{{customer.address_details.no}}<br />
                  {{customer.address_details.postcode}}&nbsp;&nbsp;{{customer.address_details.city}}<br />
                  {{'entity.blocked'|translate}}: <span *ngIf="customer.blocked" class="badge bg-danger">{{'ui.yes'|translate}}</span>
                  <span *ngIf="!customer.blocked" class="badge bg-success">{{'ui.no'|translate}}</span>
               </div>
            </ng-template>
            <article-pricelist-selector
              [pricelistsType]="1"
              labelCaption="{{'financial.concrete-pricelist'|translate}}" labelClass="md" class="mt-3 lg"
              [id]="'concrete-selector'"
              [pricelist_id]="formControls.concrete_pricelists_id.value"
              [types]="'1,2'"
              [actualOnly]="true"
              [required]="false"
              [template]="concreteselect"
              (pricelistSelected)="setConcretePricelist($event)">
            </article-pricelist-selector>
            <ng-template #concreteselect let-pricelist="pricelist">
               <div *ngIf="pricelist">
                  {{pricelist.description}}<br />
                  {{'pricelist.validity'|translate}}: {{pricelist.valid_from}} {{'ui.up-to-and-including'|translate}} {{pricelist.valid_to}}<br />
                  {{'pricelist.type'|translate}}: {{ArticlePricelistsService.getTypeAsString(pricelist.type,TranslateService)}}
               </div>
            </ng-template>
            <article-pricelist-selector
              [pricelistsType]="0"
              labelCaption="{{'financial.article-pricelist'|translate}}" labelClass="md" class="lg"
              [id]="'article-selector'"
              [pricelist_id]="formControls.article_pricelists_id.value"
              [types]="'1,2'"
              [actualOnly]="true"
              [required]="false"
              [template]="articleselect"
              (pricelistSelected)="setArticlePricelist($event)">
            </article-pricelist-selector>
            <ng-template #articleselect let-pricelist="pricelist">
               <div *ngIf="pricelist">
                  {{pricelist.description}}<br />
                  {{'pricelist.validity'|translate}}: {{pricelist.valid_from}} {{'ui.up-to-and-including'|translate}} {{pricelist.valid_to}}<br />
                  {{'pricelist.type'|translate}}: {{ArticlePricelistsService.getTypeAsString(pricelist.type,TranslateService)}}
               </div>
            </ng-template>
            <resource-pricelist-selector
              labelCaption="{{'entity.resource'|translate}} {{'financial.pricelist'|translate|lowercase}}"
              labelClass="md" class="lg"
              [id]="'resource-selector'"
              [pricelist_id]="formControls.resource_pricelists_id.value"
              [types]="'0'"
              [template]="resourceselect"
              (pricelistSelected)="setResourcePricelist($event)">
            </resource-pricelist-selector>
            <ng-template #resourceselect let-pricelist="pricelist">
               <div *ngIf="pricelist">
                  {{pricelist.description}}<br />
                  {{'pricelist.type'|translate}}: {{ResourcePricelistsService.getTypeAsString(pricelist.type,TranslateService)}}
               </div>
            </ng-template>
            <div class="form-group row">
               <label class="md col-form-label"></label>
               <div>
                  <div class="form-check form-check-inline">
                     <input type="checkbox" class="form-check-input" id="setting"
                       [ngModelOptions]="{standalone: true}" [(ngModel)]="resources_based_invoicing" (change)="setResourcesBasedInvoicing($event)">
                     <label class="form-check-label" for="setting">&nbsp;{{'invoicing.on-resources-based'|translate}} {{'invoice.invoicing'|translate|lowercase}}</label>
                  </div>
               </div>
            </div>
         </div>
         <div class="right-side">
            <div class="form-section-separator mt-4">{{'project.work-location'|translate}}</div>
            <jbm-text-input [formControl]="formControls.street" [submitted]="isSubmitted" [id]="'street'" [editable]="editing"
              class="sm" labelCaption="{{'entity.streetname'|translate}}" labelClass="md"
              (onBlur)="updateAddress()">
               <jbm-input-error [formControl]="formControls.street" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.no" [submitted]="isSubmitted" [id]="'no'" [editable]="editing"
              class="xxs" labelCaption="{{'entity.housenumber'|translate}}" labelClass="md"
              (onBlur)="updateAddress()">
               <jbm-input-error [formControl]="formControls.no" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.postcode" [submitted]="isSubmitted" [id]="'postcode'" [editable]="editing"
              class="xxs" labelCaption="{{'entity.postalcode'|translate}}" labelClass="md" (onBlur)="updateAddress()">
               <jbm-input-error [formControl]="formControls.postcode" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <jbm-text-input [formControl]="formControls.city" [submitted]="isSubmitted" [id]="'city'" [editable]="editing"
              class="sm" labelCaption="{{'entity.cityname'|translate}}" labelClass="md"
              (onBlur)="updateAddress()">
               <jbm-input-error [formControl]="formControls.city" [submitted]="isSubmitted">
                  {{'entity.requiredfield'|translate}}
               </jbm-input-error>
            </jbm-text-input>
            <fieldset class="mt-3" style="position: relative"><legend>{{'gps.location'|translate}}</legend>
               <ngb-alert *ngIf="error_lonlat" [type]="'warning'" [dismissible]="false">{{error_lonlat}}</ngb-alert>
               <jbm-text-input [formControl]="formControls.lon" [submitted]="isSubmitted" [id]="'longitude'" class="xxs"
                 [maxlength]="13" labelCaption="{{'gps.longitude'|translate}}" labelClass="width-7">
                  <jbm-input-error [formControl]="formControls.lon" [submitted]="isSubmitted">
                     {{'gps.longitude-latitude-format'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <jbm-text-input [formControl]="formControls.lat" [submitted]="isSubmitted" [id]="'latitude'"  class="xxs"
                 [maxlength]="12" labelCaption="{{'gps.latitude'|translate}}" labelClass="width-7">
                  <jbm-input-error [formControl]="formControls.lat" [submitted]="isSubmitted">
                     {{'gps.longitude-latitude-format'|translate}}
                  </jbm-input-error>
               </jbm-text-input>
               <div *ngIf="GPS_address.latitude && formControls.lat.value && formControls.lon.value" class="form-group">
                  <div class="col-form-label width-7 float-start">&nbsp;</div>
                  <div class="float-start ml-2">
                     <jbm-gps-location-button
                       [latitude]="GPS_address.latitude"
                       [longitude]="GPS_address.longitude"
                       title="{{'customer'|translate}}&nbsp;{{'entity.address'|translate|lowercase}}"
                       [address]="formControls.street.value+'&nbsp;'+formControls.no.value+', '+formControls.city.value"
                       context="outline-secondary"
                     ></jbm-gps-location-button>
                  </div>
               </div>
            </fieldset>
            <jbm-integer-input-group [formControl]="formControls.distance" [submitted]="isSubmitted" [id]="'distance'" [editable]="editing"
              class="xxs" [inputClass]="'xxs'" labelCaption="{{'geo.distance'|translate}}" labelClass="md" [append]="'geo.km-abbr'|translate">
               <jbm-input-error [formControl]="formControls.distance" [submitted]="isSubmitted">
                  {{'entity.integer-format'|translate}}
               </jbm-input-error>
            </jbm-integer-input-group>
            <jbm-integer-input-group [formControl]="formControls.traveltime" [submitted]="isSubmitted" [id]="'traveltime'" [editable]="editing"
              class="xxs" [inputClass]="'xxs'" labelCaption="{{'geo.traveltime'|translate}}" labelClass="md"
              [append]="'datetime.minutes-abbr'|translate">
               <jbm-input-error [formControl]="formControls.traveltime" [submitted]="isSubmitted">
                  {{'entity.integer-format'|translate}}
               </jbm-input-error>
            </jbm-integer-input-group>
         </div>
      </div>
      <hr />
      <jbm-textarea [formControl]="formControls.comments" [submitted]="isSubmitted" [id]="'comments'"
        class="mt-3 lg" [editable]="editing" labelCaption="{{'entity.notes'|translate}}" labelClass="md" rows="3">
      </jbm-textarea>
   </div>
   <jbm-toasts></jbm-toasts>
</form>
</ng-template>
