import {Component, Input, OnInit} from '@angular/core';
import {FormGroupBaseComponent} from "../formgroupbase.component";

export interface JBMRadioOption {
  label: string;
  value: any;
}

@Component({
  selector: 'jbm-radio-group',
  templateUrl: './JBMRadioGroup.component.html',
  styleUrls: ['./JBMRadioGroup.component.scss']
})
export class JBMRadioGroupComponent extends FormGroupBaseComponent implements OnInit {
  @Input() name: string;
  @Input() options: JBMRadioOption[];

  getSelected() {
    for(let o=0; o<this.options.length; o++ )
      if(this.options[o].value==this.ngControl.control.value)
        return this.options[o].label;
    return '';
  }
}
