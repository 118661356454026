import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {Invoice} from "./interfaces/invoice";
import {presentation_usages} from '../../presentations/data/presentation-usages.service';

const baseURL: string = 'invoicing/';

export const invoice_usage = {
  receipts: 0,    // Receipts based invoice
  orders: 1,      // Project orders based invoice
  customer: 2,    // Customer invoice (articles only, without project)
  project: 3      // Project invoice (articles only)
}

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  invoice: Invoice;

  constructor(
      private HttpService: HttpService,
  ) {}
  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+language, params );
  }
  getOne(language: string, id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/one/'+language+'/'+id.toString(), params );
  }
  getInvoiceableOrders(language: string, project_id: number) {
    return this.HttpService.get(baseURL+'read/invoiceable-orders/'+language+'/'+project_id.toString());
  }
  getInvoicedOrders(language: string, invoice_id: number) {
    return this.HttpService.get(baseURL+'read/invoiced-concretes/'+language+'/'+invoice_id.toString());
  }
  getEmpty() {
    this.invoice = {
      id: 0,
      year: 0,
      customers_id: 0,
      invoice_number: '',
      serial_number: 0,
      customer_comments: null,
      projects_id: null,
      term_of_payment: null,
      invoice_date: null,
      due_date: null,
      totalprice: null,
      concrete_amount: null,
      concrete_total: null,
      articles_amount: null,
      articles_total: null,
      outstanding: null,
      paid: null,
      presentations_id: 0,
      invoice_states_id: 100,
      usage: 0,
      resources_based_invoicing: false,
      shifted_vat: false
    }
    return this.invoice;
  }
  getPresentationUsage(invoice: Invoice): presentation_usages {
    if(invoice.projects_id && invoice.customers_id) {
      // Project and customer
      if(invoice.shifted_vat)
        return presentation_usages.invoice_shifted_vat;
    } else {
      // Customer only
      if(invoice.shifted_vat)
        return presentation_usages.invoice_customer_shifted_vat;
      else
        return presentation_usages.invoice_customer;
    }
    return presentation_usages.default_usage;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create', data);
  }
  createFromReceipts(language: string, project_id: number, receipt_ids: string, invoice_date: string) {
    if(!invoice_date) invoice_date='none';
    return this.HttpService.get(baseURL+'create/from-receipts/'+language+'/'+project_id.toString()+'/'+receipt_ids+'/'+invoice_date );
  }
  createFromOrders(language: string, data: any) {
    return this.HttpService.post(baseURL+'create/from-orders/'+language, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/', data);
  }
  validate( data: any ) {
    return this.HttpService.post(baseURL+'update/validate', data);
  }
  approve( data: any ) {
    return this.HttpService.post(baseURL+'update/approve', data);
  }
  makeConcept( data: any ) {
    return this.HttpService.post(baseURL+'update/make-concept', data);
  }
  send( data: any ) {
    return this.HttpService.post(baseURL+'update/send', data);
  }
  mail( invoice_id: number, mail_settings_id: number, language: string) {
    return this.HttpService.get(baseURL+'read/mail/'+invoice_id.toString()+'/'+mail_settings_id.toString()+'/'+language);
  }
  compose_pdf( language: string, id: number) {
    return this.HttpService.get(baseURL+'read/compose-pdf/'+language+'/'+id.toString());
  }
  compose_mail( language: string, id: number) {
    return this.HttpService.get(baseURL+'read/compose-mail/'+language+'/'+id.toString());
  }
  handlePayment( data: any ) {
    return this.HttpService.post(baseURL+'update/payment', data);
  }
  reject( data: any ) {
    return this.HttpService.post(baseURL+'update/reject', data);
  }
  copy( id: number, invoice_date: string) {
    return this.HttpService.get(baseURL+'read/copy/'+id.toString()+'/'+invoice_date);
  }
  remove( id: number ) {
    return this.HttpService.get(baseURL+'delete/'+id.toString());
  }
}
