<div class="aside-container">
    <div class="aside-header jbm-activity-log">
        <jbm-aside-title [caption]="'log.activity-log'|translate" [iconClassName]="'history'" (onClose)="close()"></jbm-aside-title>
    </div>
    <div *ngIf="title" class="aside-subject-title bg-light">
        <div>{{title}} <span *ngIf="subject && subject!=title">({{subject}})</span></div>
    </div>
    <div class="aside-body">
        <div class="toolbar float-end">
            <jbm-pagination [hidden]="queryHttpHelper.paginationProps.itemCount==0" [itemCount]="queryHttpHelper.paginationProps.itemCount"
                            [pageSize]="queryHttpHelper.paginationProps.pageSize"
                            [page]="queryHttpHelper.paginationProps.page"
                            (pageClick)="pageChange($event)">
            </jbm-pagination>
            <jbm-searchbox [visible]="searchboxVisble && !filtersVisible" [search]="search" [minlength]="3"
              (searchChange)="searchChange($event)">
            </jbm-searchbox>
            <div class="toolbar-spacer-1 float-end"></div>
            <jbm-filter-toggle [showFilter]="filtersVisible" [class]="'light'" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
        </div>
        <jbm-table
          [classname]="'log'" [data]="logs" [filterable]="true" [showFilters]="filtersVisible" [sortable]="true"
          [sortColumn]="queryHttpHelper.sortProps.name" [sortDirection]="queryHttpHelper.sortProps.direction"
          [hoverable]="true" [rowSelectable]="false" [columnDefs]="columnDefs" (sortChange)="sortChange($event)"
          (filterChange)="filterChange($event)">
        </jbm-table>
    </div>
</div>
