<div class="aside-container">
    <div class="aside-header jbm-help">
        <jbm-aside-title [caption]="'ui.helpcenter'|translate" [iconClassName]="'question-circle'" (onClose)="close()"></jbm-aside-title>
    </div>
    <div class="aside-body jbm-help">
        <h5>{{helpTopic.title}}</h5>
        <div *ngIf="helpTopic.parent_id>-1">
            <span *ngFor="let helpTopic of parentTopics; index as i">
                <a href="javascript:void(0);" (click)="setHelpTopic(helpTopic.id);$event.stopPropagation()">
                    {{helpTopic.title}}
                </a>
                <span *ngIf="i<parentTopics.length-1">/</span>
            </span>
        </div>
        <hr />
        <p *ngIf="helpTopic.content" [innerHTML]="helpTopic.content"></p>

        <a *ngFor="let helpTopic of childTopics" href="javascript:void(0);" class="topicLink"
          (click)="setHelpTopic(helpTopic.id);$event.stopPropagation()">
            {{helpTopic.title}}
        </a>
    </div>
</div>