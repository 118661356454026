import { Injectable } from '@angular/core';
import { HttpService} from "../../http.service";

@Injectable({
  providedIn: 'root'
})
export class Base64UploadService {

  constructor(private HttpService: HttpService) { }

  upload(data: any) {
    return this.HttpService.post('filesystem/create/base64-upload', data );
  }
}
