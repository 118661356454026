import {Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';
import {JBMTableColumnAlignment, JBMTableColumnSortEvent, JBMTableColumnType} from "./JBMTable.component";

@Directive({
  selector: '[jbm-table-column]'
})

export class ColumnDirective {

  sortEvent: JBMTableColumnSortEvent;
  descending: boolean=false;
  element: any;

  @Input() name: string;
  @Input() type: JBMTableColumnType;
  @Input() header: string;
  @Input() iconClassname: string;
  @Input() width: number;
  @Input() align: JBMTableColumnAlignment;
  @Input() sortable: boolean;
  @Input() sorted: boolean;
  @Input() sortdirection: string;
  @Input() filterable: boolean;

  @Output() sortChange = new EventEmitter();

  constructor(private e:ElementRef, private renderer: Renderer2) {
    this.element=e.nativeElement;
    this.renderer=renderer;
  }

  ngOnInit() {
    if(this.header==undefined) this.header='';
    if(this.iconClassname==undefined) this.iconClassname='';
    if(this.iconClassname)
      this.header='<i class="text-muted ' + this.iconClassname + '"></i>' + this.header;
    this.element.innerHTML=this.header;

    if(this.sortdirection===undefined)
      this.sortdirection = '+'; // Assume ascending
    this.descending=(this.sortdirection=='-');

    if(this.align==undefined)
      this.align=this._getAlignment();

    this.setClassNames();
    this.setStyles();
  }

  _getAlignment() {
    switch (this.type) {
      case JBMTableColumnType.number: { return JBMTableColumnAlignment.right; }
      case JBMTableColumnType.price: { return JBMTableColumnAlignment.right; }
      case JBMTableColumnType.boolean: { return JBMTableColumnAlignment.center; }
      default: return JBMTableColumnAlignment.left;
    }
  }

  setClassNames() {
    this.renderer.removeClass(this.element,this.name);
    this.renderer.removeClass(this.element,'sortable');
    this.renderer.removeClass(this.element,'sorted');
    this.renderer.removeClass(this.element,'asc');
    this.renderer.removeClass(this.element,'desc');
    this.renderer.addClass(this.element,this.name);
    if(this.sortable)
      this.renderer.addClass(this.element,'sortable');
    if(this.sorted) {
      this.renderer.addClass(this.element,'sorted');
      if(this.descending)
        this.renderer.addClass(this.element,'desc');
      else
        this.renderer.addClass(this.element,'asc');
    }
  }
  setStyles() {
    let styles=[];

    // Add width style rule
    let width='auto';
    if(this.width) width = this.width + 'rem';
    styles.push( { rule: 'width', value: width } );

    // Add alignment style
    switch (this.align) {
      case JBMTableColumnAlignment.right: {
        styles.push( { rule: 'text-align', value: 'right' } );
        break;
      }
      case JBMTableColumnAlignment.center: {
        styles.push( { rule: 'text-align', value: 'center' } );
        break;
      }
    }
    if(styles.length) {
      for(let s=0; s<styles.length; s++) {
        this.renderer.setStyle(this.element,styles[s].rule, styles[s].value);
      }
    }
  }

  @HostListener('mouseup') onMouseUp() {
    if(!this.sortable)
      return false;
    if(this.sorted===undefined || !this.sorted) {
      this.sorted=true;
      if (this.descending===undefined)
        this.descending=false;  // Assume default direction is ascending
    } else
      this.descending=!this.descending;

    // Remove sorting classnames of all columns
    let nodes=this.element.parentNode.childNodes;
    for(let n=0; n<nodes.length; n++) {
      if(nodes[n].classList===undefined)
        continue;
      this.renderer.removeClass(nodes[n],'sorted');
      this.renderer.removeClass(nodes[n],'asc');
      this.renderer.removeClass(nodes[n],'desc');
    }

    this.setClassNames();

    // Emit event
    this.sortEvent = { name: this.name, descending: this.descending };
    this.sortChange.emit( this.sortEvent );
  }
}
