import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'resources/';

@Injectable({
  providedIn: 'root'
})
export class ResourceTypesService {
  constructor(
      private HttpService: HttpService,
  ) {}

  get() {
    return this.HttpService.get(baseURL+'read/types');
  }
}
