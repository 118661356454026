import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from "@angular/common";
import {ResourcePricelistsService} from "../../data/resource-pricelists.service";
import {CustomerService} from "../../../customers/data/customer.service";
import {PlantsService} from "../../../company/data/plants.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {Customer} from "../../../customers/data/interfaces/customer";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {ResourcePricelist} from "../../data/interfaces/resource-pricelist";
import {JBMSelectOption} from '../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component';

@Component({
  selector: 'form-resource-pricelist',
  templateUrl: './resource-pricelist.component.html',
  styleUrls: ['./resource-pricelist.component.scss']
})

export class ResourcePricelistComponent implements OnInit, AfterViewInit, OnChanges {
  language: string=this.TokenService.getLanguage();
  action: CRUDAction;
  customer: Customer;
  type_options: any;
  plantOptions: JBMSelectOption[]=[];
  data: any;
  form: FormGroup;
  isSubmitted: boolean=false;

  @Input() pricelist: ResourcePricelist;
  @Input() customer_id: number=0;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;

  constructor(
      private Router: Router,
      private Location: Location,
      private ActivatedRoute: ActivatedRoute,
      private formBuilder: FormBuilder,
      private ResourcePricelistsService: ResourcePricelistsService,
      private CustomerService: CustomerService,
      private PlantsService: PlantsService,
      private TranslateService: TranslateService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService,
  ) {
    this.pricelist=this.ResourcePricelistsService.getEmpty();
    this.customer=this.CustomerService.getEmpty();
  }
  ngOnInit(): void {
    this.addControls();
    this.type_options=this.ResourcePricelistsService.getTypeSelectOptions(this.TranslateService);
    this.setControlValues();

    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=> {
          if(plants.length > 1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
          }

          if(this.customer_id===0)
            return;

          // Remove basic prices option
          this.type_options.splice(0,1);

          this.CustomerService.get(this.customer_id,true).subscribe(
              (data)=>this.customer=data.data as Customer,
              (error)=>console.log(error)
          )
        });
  }
  ngOnChanges() {
    if(this.pricelist.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;
  }
  ngAfterViewInit(): void {
    document.getElementById( 'description').focus();
  }
  addControls() {
    this.form = this.formBuilder.group({
      plants_id: new FormControl(''),
      description: new FormControl('',[Validators.required]),
      type: new FormControl('',[Validators.required])
    },{
      updateOn: "blur"
    });
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.form.controls.plants_id.setValue(this.pricelist.plants_id===null ? '' : this.pricelist.plants_id.toString());
    this.form.controls.description.setValue(this.pricelist.description);
    if(this.pricelist.type>-1)
      this.form.controls.type.setValue(this.pricelist.type.toString());
  }

  // Customer
  showCustomer() {
    this.ModalService.open(this.modalCustomerDetails,'customerDetails');
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }

  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    let plants_id=parseInt(this.formControls.plants_id.value);
    let data={
      id: this.pricelist.id,
      plants_id: plants_id===0 ? null : plants_id,
      type: parseInt(this.formControls.type.value),
      l: {language: this.language, description: this.formControls.description.value},
    }

    if(this.pricelist.id===0) {
        if(this.customer_id>0)
          data['customer_id']=this.customer_id;
        this.ResourcePricelistsService.create(data).subscribe(
          ()=>{},(error)=>this.handleError(error),()=>this.afterSave())
    } else
      this.ResourcePricelistsService.update(data).subscribe(
          ()=>{},(error)=>this.handleError(error),()=>this.afterSave())
  }
  handleError(error) {
    console.error(error);
  }
  afterSave() {
    this.onSave.emit();
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  cancel() {
    this.onCancel.emit();
  }
}
