<div class="jbm-modal-header">
   <div class="jbm-crud-title">
      <div class="title">{{'entity.invoice'|translate}} {{'ui.copy'|translate|lowercase}}</div>
   </div>
   <div class="toolbar float-end">
      <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
   </div>
</div>
<div class="jbm-modal-body">
   <form [formGroup]="form" (ngSubmit)="save()" class="send">
      <jbm-date-input labelClass="md" labelCaption="{{'invoice.date'|translate}}"
        [formControl]="formControls.invoice_date" placement="bottom" [submitted]="submitted">
         <jbm-input-error [required]="false" [formControl]="formControls.invoice_date" [submitted]="submitted">
            <span *ngIf="formControls['invoice_date'].hasError('dateInvalid')">
              {{'entity.dateformat'|translate}}
            </span>
         </jbm-input-error>
      </jbm-date-input>
   </form>
</div>
<div class="jbm-modal-footer">
   <div class="toolbar float-end">
      <button type="button" class="btn btn-sm btn-primary" (click)="save()">{{'entity.invoice'|translate}} {{'ui.copy'|translate|lowercase}}</button>
      <div class="toolbar-spacer-1"></div>
      <jbm-cancel-button (click)="close()"></jbm-cancel-button>
   </div>
</div>
