import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {MailSettings} from "./interfaces/mail-settings";

const baseURL: string = 'users/';
const segment: string = 'mail-settings/';

@Injectable({
  providedIn: 'root'
})
export class MailSettingsService {
  mailSettings: MailSettings;

  constructor(private HttpService: HttpService) {}

  get(params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment, params );
  }
  getOne(user_id :number) {
    return this.HttpService.get(baseURL+'read/'+segment+'one/'+user_id.toString(),null );
  }
  getEmpty() {
    this.mailSettings = {
      id: 0,
      users_id: 0,
      name: '',
      description: null,
      smtp: '',
      port: 0,
      password: ''
    }
    return this.mailSettings;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
}
