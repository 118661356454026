import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import {JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import {JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import {JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import {HelpCentreRoutingModule} from "./helpcentre-routing.module";
import {HelpTopicsComponent} from "./datatable/helptopics.component";
import {HelpTopicComponent} from "./form/helptopic.component";
import {AngularEditorModule} from "@kolkov/angular-editor";
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [HelpTopicsComponent, HelpTopicComponent],
    imports: [CommonModule, SharedModule, HelpCentreRoutingModule, JBMTableModule,
        JBMViewModule, JBMFormModule, JBMButtonsModule, AngularEditorModule, LayoutModule]
})
export class HelpCentreModule { }
