import {Component, OnInit } from '@angular/core';
import {TokenService} from "../../../../services/auth/token.service";
import {MenuService} from "../../../../services/menu/menu.service";
import {ReportsService} from "../../data/reports.service";
import {PlantsService} from "../../../company/data/plants.service";
import {PdfService} from "../../../../services/pdf-make/pdf-service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {HttpParams} from "@angular/common/http";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'resource-usage-report',
  templateUrl: './resource-usage-report.component.html',
  styleUrls: ['./resource-usage-report.component.scss'],
  providers: [PdfService]
})
export class ResourceUsageReportComponent implements OnInit {
  template: any={};
  plantOptions: JBMSelectOption[]=[];
  data: any;
  empty: boolean=true;
  modules: any[]=this.TokenService.getAuthorizedModules();
  iconClassname: string=this.MenuService.getMenuItemIconClassname(this.modules, 'reports');

  plant_id: number=0;

  // Date range filtering
  fromDate: number=0;
  toDate: number=0;

  constructor(
      private MenuService: MenuService,
      private TokenService: TokenService,
      private PlantsService: PlantsService,
      private TranslateService: TranslateService,
      private ReportsService: ReportsService,
      private PdfService: PdfService
  ) {}
  ngOnInit(): void {
    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          this.plantOptions = this.PlantsService.getSelectOptions(plants);
          this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          this.refresh();
        }
    );
  }
  setDateRange(event) {
    this.fromDate=event.fromUnixTime;
    this.toDate=event.toUnixTime;
    this.refresh();
  }
  undoDateRange() {
    this.fromDate=0;
    this.toDate=0;
    this.refresh();
  }
  selectPlant(event) {
    this.plant_id=parseInt(event.target.value);
    this.refresh();
  }
  refresh() {
    let params=new HttpParams()
        .append('plant_id',this.plant_id.toString())
        .append('fromUnixTime', this.fromDate.toString())
        .append('toUnixTime', this.toDate.toString());
    this.ReportsService.getProduction('resources-usage', params).subscribe(
        (data)=>this.data=data,
        (error)=>console.error(error),
        ()=> {
          // Check empty report
          this.empty=this.data.length===0;
          if(this.empty) return;

          let settings= {
            from: new Date(this.fromDate),
            to: new Date(this.toDate) }
          this.composeTemplate(settings);
          this.createPDF()
        }
    )
  }
  composeTemplate(settings) {
    let content=[];
    content.push(
      { text: this.TranslateService.GetTranslation('entity.resources') +
            this.TranslateService.GetTranslation('resource.usage').toLowerCase() + ' ' +
            this.TranslateService.GetTranslation('reports.report').toLowerCase(), style : "header"}
    );
    if(this.fromDate)
      content.push(
        { text: this.TranslateService.GetTranslation('ui.from') + ': ' + settings.from.toLocaleDateString('nl-NL') + ' ' + this.TranslateService.GetTranslation('ui.upto') + ': ' + settings.to.toLocaleDateString('nl-NL') },
        { text: ' ' }
      );

    let plant=this.getPlant();
    if(plant)
      content.push({ text: this.TranslateService.GetTranslation('company.plant')+': '+plant, style:"subheader" });

    let body = [[
      { text: this.TranslateService.GetTranslation('entity.resource') + this.TranslateService.GetTranslation('entity.name').toLowerCase(), fillColor: '#dddddd', bold:true},
      { text: this.TranslateService.GetTranslation('ui.desired') + ' ' + this.TranslateService.GetTranslation('ui.value').toLowerCase() + " (GW) \n [kg]", fillColor: '#dddddd',bold:true, alignment:'center'},
      { text: this.TranslateService.GetTranslation('production.weighed') + this.TranslateService.GetTranslation('ui.value').toLowerCase() + " (AW) \n [kg]", fillColor: '#dddddd',bold:true, alignment:'center'},
      { text: this.TranslateService.GetTranslation('ui.difference') + " AW - GW \n [kg]", fillColor: '#dddddd',bold:true, alignment:'center'},
      { text: this.TranslateService.GetTranslation('ui.difference') + " AW - GW \n [%]", fillColor: '#dddddd',bold:true, alignment:'center'},
      { text: this.TranslateService.GetTranslation('ui.manually') + " \n [kg]", fillColor: '#dddddd',bold:true, alignment:'center'},
      { text: this.TranslateService.GetTranslation('ui.total') + " \n [kg]", fillColor: '#dddddd',bold:true, alignment:'center'},
    ]]
    this.sortByResourceTypeId();
    for(let type of this.data){
      let type_line=[
        {text: type.type, bold:true, fillColor: '#eeeeee', alignment: 'left'},
        {text: type.gewenst.toFixed(2), bold:true, fillColor: '#eeeeee', alignment:'right'},
        {text: type.gewogen.toFixed(2), bold:true, fillColor: '#eeeeee', alignment:'right'},
        {text: type.verschilkg.toFixed(2), bold:true, fillColor: '#eeeeee', alignment:'right'},
        {text: type.verschilperc, bold:true, fillColor: '#eeeeee', alignment:'right'},
        {text: type.hand.toFixed(2), fillColor: '#eeeeee', bold:true, alignment:'right'},
        {text: type.totaal.toFixed(2), fillColor: '#eeeeee', bold:true, alignment:'right'},
      ]
      body.push(type_line);
      this.sortByResourceId(type.resources);
      for (let res of type.resources){
        let resource_line=[
          { text: res.id + " " + res.naam, margin: [10, 0,0,0], alignment: 'left' },
          { text: res.gewenst.toFixed(2), alignment: 'right' },
          { text: res.gewogen.toFixed(2), alignment: 'right' },
          { text: res.verschilkg.toFixed(2), alignment: 'right' },
          { text: res.verschilperc, alignment: 'right' },
          { text: res.hand.toFixed(2), alignment: 'right' },
          { text: res.totaal.toFixed(2), alignment: 'right' }
        ];
        body.push(resource_line as any);
      }
    }
    content.push({
      table: {
        body: body
      }
    });

    this.template = {
      content:content,
      styles: {
        header: {
          fontSize: 14,
          bold: true,
          margin: [0, 30, 30, 0]
        },
        subheader: {
          fontSize: 12,
          bold: true,
          margin: [0, 20, 20, 0]
        },
        small: {
          fontSize: 8
        }
      },
      defaultStyle: {
        fontSize: 8
      }
    }
  }
  sortByResourceTypeId() {
    this.data.sort(compareTypeId);
  }
  sortByResourceId(resources) {
    resources.sort(compareResourceId);
  }
  getPlant() {
    let plant='';
    if(!this.plant_id) return plant;

    for(let option of this.plantOptions)
      if(this.plant_id.toString()===option.key) {
        plant=option.value;
        break;
      }

    return plant;
  }
  createPDF() {
    this.PdfService.loadPdfMaker().then(unfulfillment => {
          this.PdfService.pdfMake.createPdf(this.template).getDataUrl(function(outDoc) {
            (document.getElementById('pdfObject') as HTMLObjectElement).data=outDoc;
          });
        }
    );
  }
}

function compareTypeId(a, b) {
  if (a.type_id > b.type_id) return 1;
  return 0;
}
function compareResourceId(a, b) {
  if (a.id > b.id) return 1;
  return 0;
}
