<div class="form-group row {{class}}">
   <label *ngIf="labelCaption" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked, 'col-form-label-sm': sm}">
      <span *ngIf="labelCaption">
         {{labelCaption}}
         <span *ngIf="!editable">:</span>
      </span>
      <span *ngIf="editable && required" class="asterix"> *</span>
   </label>
   <div class="{{inputClass}}" [ngClass]="{'data-value': !editable}">
      <label *ngIf="!editable" class="col-form-label">{{getSelected()}}</label>
      <div [hidden]="!editable" *ngFor="let option of options; index as i" class="form-check">
         <input type="radio" value="{{option.value}}" [name]="name" [id]="name+i.toString()" [formControl]="ngControl.control" class="form-check-input">
         <label class="form-check-label" [for]="name+i.toString()">&nbsp;{{option.label}}</label>
      </div>
      <ng-content></ng-content>
   </div>
</div>