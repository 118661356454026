<div class="form-group row {{class}}" [ngClass]="{'readonly': !editable}">
    <label *ngIf="!editable && labelCaption" class="{{labelClass}} col-form-label" [ngClass]="{'col-form-label-sm': sm}">
        <span>{{labelCaption}}:</span>
    </label>
    <label *ngIf="editable && labelClass" class="{{labelClass}} col-form-label" [ngClass]="{'col-form-label-sm': sm}"></label>
    <div class="{{inputClass}}" [ngClass]="{'data-value': !editable}">
        <label *ngIf="!editable" class="col-form-label" [ngClass]="{'col-form-label-sm': sm}">
            <span *ngIf="ngControl.control.value && textChecked">{{textChecked}}</span>
            <span *ngIf="!ngControl.control.value && textUnchecked">{{textUnchecked}}</span>
        </label>
        <div *ngIf="editable" class="form-check form-check-inline" [ngClass]="{'pl-0': alignLeft}">
            <input id="{{id}}" type="checkbox" [formControl]="ngControl.control"
                   class="form-check-input" [ngClass]="{'ml-0': !labelClass, 'is-invalid': editable && ngControl.invalid && (ngControl.touched || submitted) }">
            <label *ngIf="labelCaption" for="{{id}}" class="form-check-label">
                <span *ngIf="required" class="asterix">*</span>&nbsp;{{labelCaption}}
            </label>
        </div>
        <ng-content></ng-content>
    </div>
</div>
