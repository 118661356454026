import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {QuotationsService} from "../../data/quotations.service";
import {QuotationAttachmentsService} from "../../data/quotation-attachments.service";
import {QuotationState} from "../../data/interfaces/quotation-state";
import {MediaFilesService} from "../../../presentations/data/media_files.service";
import {MailTemplatesService} from "../../../presentations/data/mail_templates.service";
import {CustomerService} from "../../../customers/data/customer.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Quotation} from "../../data/interfaces/quotation";
import {DatetimeService} from "../../../../services/datetime/datetime.service";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {quotation_state} from "../../data/quotation-states.service";

@Component({
  selector: 'quotation-send',
  templateUrl: './quotation-send.component.html',
  styleUrls: ['./quotation-send.component.scss']
})
export class QuotationSendComponent implements OnInit {
  form: FormGroup;
  customer: any;
  language: string=this.TokenService.getLanguage();
  mail: any={ subject: '', html_body: '', text_body: '' };
  attachments: any[]=[];
  isSent: boolean=false;
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  submitted: boolean=false;
  fileExtension: string;
  HTMLHead : string=this.MailTemplatesService.getHTMLHead();
  HTMLClose: string=this.MailTemplatesService.getHTMLClose();
  HTML: string='';
  mailBase64: string='';
  base64: string;
  description: string='';
  preview: boolean=false;

  constructor(
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService,
      private formBuilder: FormBuilder,
      private QuotationsService: QuotationsService,
      private QuotationAttachmentsService: QuotationAttachmentsService,
      private MediaFilesService: MediaFilesService,
      private MailTemplatesService: MailTemplatesService,
      private CustomerService: CustomerService,
      private DatetimeService: DatetimeService
  ) {
    this.addControls();
  }

  @Input() quotation: Quotation;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

  ngOnInit(): void {

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true},
      { name: 'filename', header: this.TranslateService.GetTranslation('ui.filename'), sortable: true, width: 18 },
      { name: 'extension', header: this.TranslateService.GetTranslation('ui.file-extension'), sortable: true, width: 8 },
      { name: 'size', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('ui.file-size'), sortable: true, width: 5 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 13.57 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('extension', SearchColumnType.string);

    this.isSent=this.quotation.quotation_states_id >= quotation_state.send;

    this.CustomerService.get(this.quotation.customers_id).subscribe(
        (data)=>this.customer=data.data,
        (error)=>console.error(error),
        ()=> {
            this.QuotationsService.compose_mail(this.language, this.quotation.revision_id).subscribe(
                (data)=> {
                    this.mail=data.data;
                    this.mail.html_body=this.mail.html_body.replace('\"','"');
                    this.mail.html_body=this.mail.html_body.replace('\\','');
                    this.HTML=this.mail.html_body;
                    let HTML=this.HTMLHead+this.HTML+this.HTMLClose;
                    this.mailBase64=btoa(unescape(encodeURIComponent(HTML)));
                },
                (error)=>console.error(error),
                ()=> {
                    this.getAttachments();
                    this.setControlValues();
                }
            )
        }
    )
  }
  getAttachments() {
    this.QuotationAttachmentsService.get(this.quotation.id).subscribe(
        (data)=>this.attachments=data.data,
        (error)=>console.error(error),
        ()=> {
          // Set actions column template
          this.columnDefs[5].template=this.actionsTemplate;
        }
    )
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          email: new FormControl('',{updateOn: 'blur', validators: [Validators.required]}),
          subject: new FormControl('',{updateOn: 'blur', validators: [Validators.required]}),
          quotation_date: new FormControl(0,[this.DatetimeService.dateValidator(this.DatetimeService,true)]),
          validity_period: new FormControl('',{updateOn: 'blur',
            validators: [Validators.required, Validators.min(1), Validators.max(365), Validators.pattern('[1-9][0-9]*')]} ),
          comments: new FormControl('' ),
        }
    );
  }
  setControlValues() {
    if(this.customer.email) this.form.controls.email.setValue(this.customer.email);
    if(this.customer.invoice_email) this.form.controls.email.setValue(this.customer.invoice_email);
    this.form.controls.quotation_date.setValue(this.DatetimeService.getCurrentDateDMY());
    this.form.controls.validity_period.setValue(this.quotation.validity_period.toString());
    this.form.controls.subject.setValue(this.mail.subject);
  }
  get formControls() {
    return this.form.controls;
  }
  selectMediaFile(event) {
    this.QuotationAttachmentsService.create( { invoices_id: this.quotation.id, media_files_id: event.id } ).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=>{
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
          this.getAttachments();
        }
    )
  }
  showPreview(event) {
    this.base64='';
    this.fileExtension='';
    this.MediaFilesService.getOne(event.id).subscribe(
        (data)=> {
          this.base64=data.content;
          this.fileExtension=data.extension;
          this.description=data.description;
          this.preview=true;
        },
        (error)=> console.error(error)
    )
  }
  delete(id) {
    this.QuotationAttachmentsService.remove(id).subscribe(()=>{},(error)=>console.error(error),
        ()=>{
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getAttachments();
        }
    )
  }

  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let data={
      quotation_id: this.quotation.id,
      quotation_revision_id: this.quotation.revision_id,
      quotation_date: this.DatetimeService.getDatePartsDMY(this.form.controls.quotation_date.value),
      comments: this.formControls.comments.value,
      email: this.form.controls.email.value,
      validity_period: parseInt(this.form.controls.validity_period.value),
      subject: this.form.controls.subject.value
    }
    this.QuotationsService.send(data).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=> {
            this.QuotationsService.mail(this.language, this.quotation.revision_id).subscribe(
                ()=>{},
                (error)=>console.error(error),
                ()=>
                    this.JBMToastsService.success( this.TranslateService.GetTranslation('quotation.is-sent'))
            )
            this.onSuccess.emit();
        }
    )
  }
  close() {
    this.onClose.emit();
  }
}
