import {Injectable} from '@angular/core';
import {HttpService} from "../http.service";
import {HttpParams} from "@angular/common/http";

const URL='projects/read/openroute';

export interface GeoSegment {
  distance: number;
  duration: number;
}

@Injectable({
  providedIn: 'root'
})
export class OpenRouteService {
  GeoSegment: GeoSegment;

  constructor(private HttpService: HttpService) {}

  getGeoData(lonFrom: string, latFrom: string, lonTo: string, latTo: string) {

    let params=new HttpParams().set('lonfrom',lonFrom).set('latfrom',latFrom)
        .set('lonto',lonTo).set('latto',latTo);
    return this.HttpService.get(URL,params);
  }
  getGeoDistance(geoData) {
    this.GeoSegment=this.getGeoSegment(geoData);
    return this.GeoSegment.distance;
  }
  getGeoDuration(geoData) {
    this.GeoSegment=this.getGeoSegment(geoData);
    return this.GeoSegment.duration;
  }
  getGeoSegment(geoData): GeoSegment {
    this.GeoSegment={ distance: 0, duration: 0 };
    if(this.checkGeoDataStructure(geoData)) {
      this.GeoSegment.distance=Math.round(geoData.features[0].properties.segments[0].distance / 1000);  // In KM
      this.GeoSegment.duration=Math.round(geoData.features[0].properties.segments[0].duration / 60 );   // In Minutes
    }
    return this.GeoSegment;
  }
  checkGeoDataStructure(geoData) {
    if(typeof geoData!='object') return false;
    if(!geoData.hasOwnProperty('features')) return false;
    if(!Array.isArray(geoData.features)) return false;
    if(geoData.features.length===0) return false;
    if(!geoData.features[0].hasOwnProperty('properties')) return false;
    if(!geoData.features[0].properties.hasOwnProperty('segments')) return false;
    if(!Array.isArray(geoData.features[0].properties.segments)) return false;
    if(geoData.features[0].properties.segments===0) return false;

    return true;
  }
}
