<div class="app">
  <app-sidebar (onSidebarMenuShow)="showSidebarMenu($event)"></app-sidebar>
  <div class="content">
    <router-outlet></router-outlet>
  </div>
  <aside class="left">
    <!-- al (aside left) -->
    <router-outlet name="al"></router-outlet>
  </aside>
  <aside class="right">
    <!-- ar (aside right) -->
    <router-outlet name="ar"></router-outlet>
  </aside>
  <router-outlet name="modal"></router-outlet>
</div>
<div class="app-backdrop"></div>
