import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {JBMModalComponent} from "./JBMModal.component";

@NgModule({
  declarations: [JBMModalComponent],
  imports: [CommonModule],
  exports: [JBMModalComponent]
})
export class JBMModalModule { }
