import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../../shared/shared.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMFormModule} from "../../components/JBM/Forms/jbmform.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import { ContactFormComponent } from './form/contact-form/contact-form.component';

@NgModule({
  declarations: [
    ContactFormComponent
  ],
  imports: [
    CommonModule, SharedModule, JBMModalModule, JBMFormModule, JBMButtonsModule, JBMViewModule
  ],
  exports: [
      ContactFormComponent
  ]
})
export class ContactsModule { }
