<jbm-modal [id]="'validations'" [className]="'content invoice-validation'" (onClose)="close()"></jbm-modal>
<ng-template #modalValidations let-modal>
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">
            {{'entity.invoices'|translate}} {{'ui.validate'|translate|lowercase}}
         </div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <div *ngFor="let invoice of invoices" class="clearfix mb-3"
        [ngClass]="{'pb-3': invoice_id==invoice.id}">
         <div class="header bg-light p-2 clearfix">
            <section class="number">
               <label class="top">{{'invoice.number'|translate}}</label>
               <label class="value">{{invoice.invoice_number}}</label>
            </section>
            <section class="customer">
               <label class="top">{{'entity.customer'|translate}}</label>
               <label class="value">{{invoice.customer.name}}</label>
            </section>
            <section class="project" *ngIf="invoice.project">
               <label class="top">{{'entity.project'|translate}}</label>
               <label class="value">{{invoice.project.name}}</label>
            </section>
            <div class="float-end">
               <span *ngIf="invoice.approved" class="badge bg-success mr-3">{{'ui.approved'|translate}}</span>
               <span *ngIf="invoice.approved!=null && !invoice.approved" class="badge bg-danger mr-3">{{'ui.rejected'|translate}}</span>
               <button *ngIf="invoice_id!=invoice.id" type="button" class="btn btn-sm btn-primary" (click)="setInvoice(invoice.id)">{{'ui.validate'|translate}}</button>
               <button *ngIf="invoice_id==invoice.id" type="button" class="btn btn-sm btn-secondary" (click)="cancelValidation()">{{'ui.cancel'|translate}}</button>
            </div>
         </div>
         <div *ngIf="invoice.id==invoice_id" class="clearfix">
            <invoice-validations [invoice_id]="invoice.id" [excludeCurrentUser]="false"></invoice-validations>
            <pdfmake-viewer [template]="PDFTemplate"></pdfmake-viewer>
            <invoice-validation-form [invoice_id]="invoice.id" (validated)="refresh()"></invoice-validation-form>
         </div>
      </div>
   </div>
</ng-template>
