import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {ProjectsService} from "../data/projects.service";
import {OrdersService} from "../data/orders.service";
import {ProjectstatesService} from "../data/projectstates.service";
import {PlantsService} from "../../company/data/plants.service";
import {CustomerService} from "../../customers/data/customer.service";
import {StateService} from "../../../services/storage/state.service";
import {TokenService} from "../../../services/auth/token.service";
import {LocalStorageService} from "../../../services/storage/localstorage.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {RefererService} from "../../../services/storage/referer.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {Project} from "../data/interfaces/project";
import {ProjectPresenter} from "../presenters/project.presenter";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';

@Component({
  selector: 'app-project',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})

export class ProjectViewComponent implements OnInit {
  caption: string;
  parentLinkCaption: string;
  parentLinkRoute: string;
  id: number;
  data: any=[];
  project: Project;
  customer: any;
  hasCustomer: boolean=false;
  hasPlant: boolean=false;
  hasAddress: boolean=false;

  language: string=this.TokenService.getLanguage();
  userRights: GroupRights;
  financialUserRights: GroupRights;
  editable: boolean=false;

  @ViewChild('content') modalTemplate: ElementRef;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;
  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private Router: Router,
      private ProjectsService: ProjectsService,
      private OrdersService: OrdersService,
      private ProjectstatesService: ProjectstatesService,
      private PlantsService: PlantsService,
      private CustomerService: CustomerService,
      private TokenService: TokenService,
      private LocalStorageService: LocalStorageService,
      private StateService: StateService,
      private TranslateService: TranslateService,
      private RefererService: RefererService,
      private ProjectPresenter: ProjectPresenter,
      private ModalService: ModalService
  ) {
    // The route resolver has fetched the data
    this.project=this.ActivatedRoute.snapshot.data.project.data;

    // Which related data is available?
    this.hasCustomer=this.project.hasOwnProperty('customer') && this.project.customer!==null;
    this.hasPlant=this.project.hasOwnProperty('plant') && this.project.plant!==null;
    this.hasAddress=this.project.hasOwnProperty('address') && this.project.address!==null;

    // Get userrights
    this.userRights=this.TokenService.getRightsByName('projects');
    this.financialUserRights=this.TokenService.getRightsByName('financial');

    this.editable=this.userRights.AllowCreate || this.userRights.AllowUpdate;
  }

  ngOnInit(): void {
    this.data=this.ProjectPresenter.format(this.project);
    this.caption=this.project.name;

    // Refresh data after modal outlet closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && event.url.indexOf('projects/')>-1) {
        // Get the project inclusive related data
        let language=this.TokenService.getLanguage();
        let params=new HttpParams()
            .append('state','1')
            .append('address','1')
            .append('plant', '1')
            .append('customer','1');
        this.ProjectsService.getOne(this.project.id,language,params).subscribe(
            (data)=>this.project=data.data,
            (error)=>console.error(error),
            ()=>{
              this.data=this.ProjectPresenter.format(this.project);
              this.caption=this.project.name
            })
      }
    });

    // Set parent route
    this.parentLinkCaption=this.TranslateService.GetTranslation('menu.projects');
    this.parentLinkRoute='projects';

    // Set optional parent route from active referer
    let referer=this.RefererService.getReferer('customers','projects');
    if(typeof referer=='object') {
      this.parentLinkCaption=referer.caption;
      this.parentLinkRoute=referer.route;
    }
  }
  edit() {
    this.Router.navigate([ { outlets: {'modal': ['project', 'edit', this.project.id]}} ] );
  }
  addOrder() {
    this.Router.navigateByUrl('projects/'+this.project.id+'/orders/create');
  }
  map() {
    // Show the Map
    this.Router.navigate([ { outlets: {'ar': ['map']}} ] );
  }
  projectDetails() {
    this.ModalService.open(this.modalProjectDetails,'projectDetails');
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }
  customerDetails() {
    this.CustomerService.get(this.project.customer_id,true).subscribe(
        (data)=>this.customer=data.data,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }
}
