import {Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {QuotationsService} from "../data/quotations.service";
import {QuotationStatesService} from "../data/quotation-states.service";
import {QuotationAttachmentsService} from "../data/quotation-attachments.service";
import {CustomerService} from '../../customers/data/customer.service';
import {PriceService} from "../../../services/price/price.service";
import {RecipesService} from "../data/recipes.service";
import {SettingsService} from "../../../services/storage/settings.service";
import {setting_subjects} from "../../../services/storage/settings.service";
import {Quotation} from "../data/interfaces/quotation";
import {QuotationState} from "../data/interfaces/quotation-state";
import {HttpParams} from "@angular/common/http";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {QuotationItemsComponent} from "../items/quotation-items.component";

@Component({
  selector: 'quotation-view',
  templateUrl: './quotation-view.component.html',
  styleUrls: ['./quotation-view.component.scss']
})
export class QuotationViewComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  userRights: GroupRights;
  userRightsRecipes: GroupRights;
  editable: boolean=true;
  activeTab: number=1;
  taxInclusive: boolean=true;
  id: number=0;
  customer: any;
  quotation: any;
  quotationState: QuotationState;
  baseRecipe: any={ description: '' };
  viewSettings: any={ details: true, orders: true, articles: true, comments: true };

  @ViewChild('templateCustomer') templateCustomer: TemplateRef<any>;
  @ViewChild('templateProject') templateProject: TemplateRef<any>;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;
  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;
  @ViewChild('quotationItems') quotationItems: QuotationItemsComponent;
  @ViewChild('modalPreview') modalPreview: ElementRef;
  @ViewChild('modalStatesFlow') modalStatesFlow: ElementRef;
  // State actions
  @ViewChild('modalRevision') modalRevision: ElementRef;
  @ViewChild('modalApprove') modalApprove: ElementRef;
  @ViewChild('modalDraft') modalDraft: ElementRef;
  @ViewChild('modalSend') modalSend: ElementRef;
  @ViewChild('modalNegotiate') modalNegotiate: ElementRef;
  @ViewChild('modalAccept') modalAccept: ElementRef;
  @ViewChild('modalCancel') modalCancel: ElementRef;
  @ViewChild('modalReject') modalReject: ElementRef;
  @ViewChild('modalRemove') modalRemove: ElementRef;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private TokenService: TokenService,
      private QuotationsService: QuotationsService,
      private QuotationStatesService: QuotationStatesService,
      private QuotationAttachmentsService: QuotationAttachmentsService,
      private CustomerService: CustomerService,
      private PriceService: PriceService,
      private RecipesService: RecipesService,
      private SettingsService: SettingsService,
      private ModalService: ModalService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) {
    this.userRights=this.TokenService.getRightsByName('quotations');
    this.userRightsRecipes = this.TokenService.getRightsByName('recipes');

    // The route resolver has fetched the data
    this.quotation=this.ActivatedRoute.snapshot.data.quotation.data;
    this.quotationState=this.QuotationStatesService.getEmpty();

    this.setEditable();
  }
  ngOnInit(): void {
    let plant_id=this.quotation.project.plants_id!==null ? this.quotation.project.plants_id : 0;
    let params=new HttpParams()
        .append('project_id', this.quotation.projects_id.toString())
        .append('customer_id', this.quotation.customers_id.toString())
        .append('plant_id', plant_id.toString())
        .append('concrete_specs','1')
        .append('language', this.language);
    this.RecipesService.getBaseRecipe(params).subscribe(
        (data)=>this.baseRecipe=data,
        (error)=>console.error(error),
        ()=> {
          let exists=true;
          this.SettingsService.subjectExists( this.SettingsService.getUserID(), setting_subjects.quotations ).subscribe(
              (data)=>exists=data.exists,
              (error)=>console.error(error),
              ()=> {
                if(exists)
                  this.getViewSettings()
                else
                  this.saveViewSettings()
              }
          );
        }
    )

    this.setQuotationState();
  }
  setQuotationState() {
    let params=new HttpParams().append('id',this.quotation.quotation_states_id.toString());
    this.QuotationStatesService.get(this.language, params).subscribe(
        (data)=>this.quotationState=data,
        (error)=>console.error(error)
    )
  }
  refreshQuotation() {
    let params=new HttpParams()
        .append('customer','1')
        .append('project','1')
        .append('permissions','1');
    this.QuotationsService.getOne(this.quotation.id, this.quotation.revision_number, params).subscribe(
      (data)=>this.quotation=data.data as Quotation,
      (error)=>console.error(error),
      ()=>{
        this.setEditable();
        // Refresh quotation items component
        this.quotationItems.getData();
      }
    )
  }
  refreshState() {
    let quotation=null;
    this.QuotationsService.getOne(this.quotation.id, this.quotation.revision_number).subscribe(
        (data)=>quotation=data.data,
        (error)=>console.error(error),
        ()=> {
          this.quotation.quotation_states_id = quotation.quotation_states_id;
          this.setQuotationState()
        }
    )
  }
  toggleTaxInclusive() {
    this.taxInclusive=!this.taxInclusive;
  }
  setEditable() {
    this.editable=this.userRights.AllowUpdate && this.quotation.quotation_revisions_id===this.quotation.revision_id;
  }

  // Invoice states flow
  showStateFlow() {
    this.ModalService.open(this.modalStatesFlow,'statesFlow');
  }
  statesFlowClose() {
    this.ModalService.close('statesFlow');
  }

  // View Settings
  getViewSettings() {
    this.SettingsService.getSubject(this.SettingsService.getUserID(), setting_subjects.quotations).subscribe(
        (data)=>this.viewSettings=data.settings,(error)=>console.error(error)), ()=>console.log(this.viewSettings)
  }
  saveViewSettings() {
    this.SettingsService.setSubject(this.SettingsService.getUserID(), setting_subjects.quotations,null, this.viewSettings).subscribe(
        ()=>{},(error)=>console.error(error))
  }
  updateSettings(event) {
    this.viewSettings=event.settings;
    this.saveViewSettings();
  }

  createRevision() {
    this.ModalService.open(this.modalRevision,'revision');
  }
  revisionSuccess() {
    this.ModalService.close('revision');
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));

    // Increment revision
    this.quotation.revision_number++;

    this.refreshQuotation();
    this.refreshState();
  }
  revisionClose() {
    this.ModalService.close('revision');
  }

  // Base Recipe
  showRecipe() {
    this.ModalService.open(this.modalRecipe,'recipeDetails');
  }
  closeRecipe() {
    this.ModalService.close('recipeDetails');
  }

  // Customer
  showCustomer(customer_id: number) {
    this.CustomerService.get(customer_id,true).subscribe(
      (data)=>this.customer=data.data,
      (error)=>console.error(error),
      ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }

  // Project
  showProject(project_id: number) {
    this.id=project_id;
    this.ModalService.open(this.modalProjectDetails,'projectDetails')
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }

  // Quotation state actions
  approve() {
    this.ModalService.open(this.modalApprove,'stateAction');
  }
  approveSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.approved'));
    this.refreshState();
  }
  draft() {
    this.ModalService.open(this.modalDraft,'stateAction');
  }
  draftSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.made-draft'));
    this.refreshState();
  }
  send() {
    this.QuotationAttachmentsService.check(this.quotation.id).subscribe(
      ()=>{},(error)=>console.error(error),()=> {
        this.ModalService.open(this.modalSend,'quotationSend');
      }
    );
  }
  sendSuccess() {
    this.sendClose();
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.is-sending'));
    this.refreshQuotation();
    this.refreshState();
  }
  sendClose() {
    this.ModalService.close('quotationSend');
  }
  negotiate() {
    this.ModalService.open(this.modalNegotiate,'stateAction');
  }
  negotiateSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.set-negotiation'));
    this.refreshState();
  }
  accept() {
    this.ModalService.open(this.modalAccept,'stateAction');
  }
  acceptSuccess() {
    this.sendClose();
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.accepted'));
    this.refreshState();
  }
  cancel() {
    this.ModalService.open(this.modalCancel,'stateAction');
  }
  cancelSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('quotation.cancelled'));
    this.refreshState();
  }
  reject() {
    this.ModalService.open(this.modalReject,'stateAction');
  }
  rejectSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('entity.quotation') + ' '+ this.TranslateService.GetTranslation('ui.rejected').toLowerCase());
    this.refreshState();
  }
  remove() {
    this.ModalService.open(this.modalRemove,'stateAction');
  }
  removeSuccess() {
    this.ModalService.close('stateAction');
    this.JBMToastsService.success(this.TranslateService.GetTranslation('ui.entity-deleted'));
    this.Router.navigateByUrl('quotations');
  }
  stateActionClose() {
    this.ModalService.close('stateAction');
  }

}
