<div ngbDropdown #deleteDrop="ngbDropdown" class="{{class}}" [container]="'body'"
  placement="{{placement}}" (openChange)="openChange($event)">
   <button type="button" [disabled]="!enabled" class="btn btn-danger" id="cddd1" ngbDropdownToggle
     [ngClass]="{'btn-sm': !extraSmall, 'btn-xs': extraSmall}" (click)="$event.stopPropagation();"
     placement="{{tooltipPlacement}}" container="body" [ngbTooltip]="'ui.delete-confirm'|translate">
      <i *ngIf="iconClass" class="fas fa-{{iconClass}}"></i>
   </button>
   <div ngbDropdownMenu [attr.aria-labelledby]="'cddd1'" class="table-cell confirm-delete clearfix">
      <h6>{{subject}}</h6>
      <div *ngIf="info">{{info}}</div>
      <div class="text-danger">{{'ui.action-without-undo'|translate}}</div>
      <hr />
      <div class="float-end">
         <button type="button" class="btn btn-light btn-sm mr-2" (click)="deleteDrop.close()">{{'ui.cancel'|translate}}</button>
         <button type="button" class="btn btn-danger btn-sm" (click)="confirm();deleteDrop.close()">{{'ui.delete'|translate}}</button>
      </div>
   </div>
</div>
