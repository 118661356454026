import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {ProjectstatesService} from "../data/projectstates.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'projectstates',
  templateUrl: './projectstates.component.html',
  styleUrls: ['./projectstates.component.scss']
})
export class ProjectstatesComponent implements OnInit, OnDestroy {
  userRights: any;
  items: any[]=[];
  language: string=this.TokenService.getLanguage();
  article_price_types_id: number;
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  createActive: boolean=false;
  description: string='';
  descriptionError: string='';
  descriptionTouched: boolean=false;
  color: string='';
  text_color: string='';
  editing: boolean=false;
  unique:boolean=true;

  constructor(
      public ProjectstatesService: ProjectstatesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('projects');
    this.getData();
  }
  getData() {
    this.loading=true;
    this.items$=this.ProjectstatesService.get(this.language).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{
          this.loading=false;
          // Set editmode to false for each item
          for(let item of this.items)
            item.editing=false;
        } );
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.setDefaultColors();
    // Set default projectstate badge colors
    this.color='#f8f9fa';
    this.text_color='#000000';
    this.createActive=true;
    this.editing=true;
  }
  createCancel() {
    this.createActive=false;
    this.editing=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row already in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.description=data.description;
    this.descriptionTouched=true;
    this.color=data.color;
    this.text_color=data.text_color;

    this.unique=true;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData();
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.descriptionError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  changedColor(colors: any) {
    this.color=colors.background;
    this.text_color=colors.foreground;
  }
  acceptColor(colors: any) {
    this.color=colors.background;
    this.text_color=colors.foreground;
  }
  cancelColor(item) {
    if(item===null) {
      this.setDefaultColors();
      return;
    }
    this.color=item.color;
    this.text_color=item.text_color;
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    this.ProjectstatesService.create(data).subscribe(()=>{},
        (error)=>this.handleError(error),()=>this.afterSave())
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.description===this.description && originalValues.color===this.color && originalValues.text_color===this.text_color) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }

    console.log(originalValues);

    let data=this.setItemData(originalValues.id);
    this.ProjectstatesService.update(data).subscribe(
        ()=>{},error => { this.handleError(error) },
        () => { this.afterSave() })
  }
  getValues() {
    this.description=this.description.trim();
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    return { id: id, language: this.language, description: this.description, color: this.color, text_color: this.text_color }
  }
  clearItemData() {
    this.description='';
    this.descriptionTouched=false;
  }
  setDefaultColors() {
    // Set default projectstate badge colors
    this.color='#f8f9fa';
    this.text_color='#000000';
  }
  handleError(error) {
    this.unique=true;
    if(error=='not_unique') {
      this.unique=false;
      this.descriptionError=this.TranslateService.GetTranslation('entity.description-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) {
      this.createCancel();
      this.getData();
    }
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
