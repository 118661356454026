<form [formGroup]="form" (ngSubmit)="save()" class="mediafile">
   <div class="jbm-modal-header">
      <jbm-crud-title [subject]="'presentations.mediafile'" [action]="action"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-activity-log-button *ngIf="mediaFile.id" [subject]="'menu.presentations'|translate"
           [title]="'presentations.mediafile'|translate" [subject_type]="30" [subject_id]="mediaFile.id">
         </jbm-activity-log-button>
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <div class="upload mt-2 mb-2">
         <div class="form-section-separator">{{'ui.upload-file'|translate}}</div>
         <div class="custom-file mb-2">
            <input id="customFile" type="file" class="custom-file-input" [accept]="requiredFileType" (change)="onFileSelected($event)" #fileUpload>
            <label class="custom-file-label" for="customFile">{{fileName || 'ui.upload-select-file'|translate }}</label>
         </div>
         <ngb-alert *ngIf="error" type="danger" [dismissible]="false" class="mt-2">{{error}}</ngb-alert>
         <div class="form-text text-muted">
            <small>{{'ui.upload-allowed-extensions'|translate}}: <b>{{allowedExtensions}}</b></small><br />
            <small>{{'ui.upload-max-file-size'|translate}}: <b>{{uploadInfo.maxSize|filesize}}</b></small>
         </div>
      </div>
      <jbm-text-input [id]="'filename'" [formControl]="formControls['filename']" [required]="true" [submitted]="submitted"
        [labelCaption]="'ui.filename'|translate" class="lg" [labelClass]="'lg'" [class]="'lg mb-3'">
         <jbm-input-error [formControl]="formControls['filename']" [submitted]="submitted">
            <span *ngIf="formControls.filename.hasError('notUnique')">{{'entity.description-not-unique'|translate}}</span>
            <span *ngIf="formControls.filename.hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input [id]="'description'" [formControl]="formControls['description']" [required]="true" [submitted]="submitted"
        [labelCaption]="'entity.description'|translate" class="lg" [labelClass]="'lg'" [class]="'lg'">
         <jbm-input-error [formControl]="formControls['description']" [submitted]="submitted">
            <span *ngIf="formControls.description.hasError('notUnique')">{{'entity.description-not-unique'|translate}}</span>
            <span *ngIf="formControls.description.hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button [subject]="'presentations.mediafile'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
