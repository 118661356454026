<div class="aside-container">
    <div class="aside-header jbm-shortcuts">
        <jbm-aside-title [caption]="'ui.shortcuts'|translate" [iconClassName]="'keyboard'" (onClose)="close()"></jbm-aside-title>
    </div>
    <div class="aside-body jbm-shortcuts">
        <h6>{{'entity.common'|translate}}</h6>
        <table class="table table-sm">
            <thead>
                <tr><th>{{'ui.key-combination'|translate}}</th><th>{{'ui.show'|translate}}</th></tr>
            </thead>
            <tbody>
                <tr><td>Ctrl + D</td><td>{{'menu.dashboard'|translate}}</td></tr>
                <tr><td>Ctrl + C</td><td>{{'menu.customers'|translate}}</td></tr>
                <tr><td>Ctrl + P</td><td>{{'menu.projects'|translate}}</td></tr>
                <tr><td>Ctrl + L</td><td>{{'menu.planning'|translate}}</td></tr>
                <tr><td>Ctrl + I</td><td>{{'menu.invoicing'|translate}}</td></tr>
                <tr><td>Ctrl + S</td><td>{{'menu.settings'|translate}}</td></tr>
                <tr><td>Ctrl + H</td><td>{{'ui.helpcenter'|translate}}</td></tr>
            </tbody>
        </table>
        <br />
        <h6>{{'ui.data-tables'|translate}}</h6>
        <table class="table table-sm">
            <thead>
                <tr><th>{{'ui.key'|translate}}/{{'ui.key-combination'|translate|lowercase}}</th><th>{{'ui.go-to'|translate}}</th></tr>
            </thead>
            <tbody>
                <tr><td>Page Up</td><td>{{'ui.navigate-previous'|translate}} {{'ui.page'|translate|lowercase}}</td></tr>
                <tr><td>Page Down</td><td>{{'ui.navigate-next'|translate}} {{'ui.page'|translate|lowercase}}</td></tr>
                <tr><td>Home</td><td>{{'ui.first'|translate}} {{'ui.page'|translate|lowercase}}</td></tr>
                <tr><td>End</td><td>{{'ui.last'|translate}} {{'ui.page'|translate}}</td></tr>
                <tr><td>Ctrl + F</td><td>{{'ui.search-via-search-box'|translate}}</td></tr>
            </tbody>
        </table>
    </div>
</div>