<div *ngIf="visible" class="jbm-search-box input-group" [ngClass]="{'jbm-search-box-xs': size=='xs', 'focused':focused}">
    <input [attr.id]="id=='' ? null : id" class="form-control" type="text" autocomplete="off" [value]="search"
      (focusin)="focused=true" (focusout)="focused=false"
      (keyup)="keyUp($event)" placement="bottom" triggers="click:blur" ngbTooltip="{{'ui.search-min-'+minlength+'-chars'|translate}}">
    <i class="fa fa-search"></i>
    <div class="input-group-append">
        <button type="button" tabindex="-1" class="btn btn-light" [ngClass]="{'btn-sm': size=='xs'}"
          [disabled]="search==''" (click)="onUndo()"><i class="fa fa-undo"></i></button>
    </div>
</div>
