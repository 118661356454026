<jbm-table [classname]="'quotation-revisions bg-white'"
  [data]="revisions"
  [hoverable]="true"
  [rowSelectable]="true"
  [columnDefs]="columnDefs"
  [actionDefs]="actionDefs"
  (rowSelect)="rowSelect($event)">
</jbm-table>

<ng-template #templateAmount let-data="data">
   <span class="float-end" *ngIf="data.concrete_amount">{{data.concrete_amount}} {{'quantity.m3'|translate}}</span>
</ng-template>

<ng-template #templatePreview let-data="data">
   <a *ngIf="data.concrete_total" class="btn btn-sm btn-link" target="_blank" [ngbTooltip]="'PDF '+('ui.preview'|translate|lowercase)"
     [href]="'quotation-preview/'+data.id" (click)="$event.stopPropagation()">
      <i class="far fa-file-pdf"></i> <i class="fa fa-external-link-alt ml-1"></i>
   </a>
</ng-template>
