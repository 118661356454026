import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";

const baseURL: string = 'reports/';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  constructor(
      private HttpService: HttpService,
  ) {}

  getProduction(report_code: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/production/'+report_code, params );
  }
}
