import {Component, Input, OnInit} from '@angular/core';
import {RecipeResourcesService} from "../../data/recipe_resources.service";
import {ResourcesService} from "../../data/resources.service";
import {SanitizationService} from "../../../../services/sanitization/sanitization.service";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'recipe-resources',
  templateUrl: './recipe-resources.component.html',
  styleUrls: ['./recipe-resources.component.scss']
})
export class RecipeResourcesComponent implements OnInit {
  resources: any[]=[];
  resources_list: any[]=[];
  resource_options: JBMSelectOption[]=[];
  density: string;
  resource_id: number=0;
  amount: number=0.0;

  constructor(
      private RecipeResourcesService: RecipeResourcesService,
      private ResourcesService: ResourcesService,
      private SanitizationService: SanitizationService
  ) {}

  @Input() recipe_id: number=0;

  ngOnInit(): void {
    this.ResourcesService.getSelectList(0).subscribe(
        (data)=> this.resources_list=data.data,
        (error)=>console.error(error),
        ()=> {
          if(this.recipe_id>0)
            this.RecipeResourcesService.get(this.recipe_id).subscribe(
                (data)=>this.resources=data.data,
                (error)=>console.error(error),
                ()=> {
                  this.updateResourceOptions();
                  this.formatResources();
                  this.calculateDensity();
                }
            )
          else
            this.updateResourceOptions()
        }
    )
  }
  updateResourceOptions() {
    this.resource_options=[];
    for(let resource of this.resources_list)
      if(this.resourceIndex(resource.id)===-1)
        this.resource_options.push({ key: resource.id.toString(), value: resource.description });
  }
  resourceIndex(id: number) {
    for(let r=0; r<this.resources.length; r++)
      if(this.resources[r].resource_id===id) return r;
    return -1;
  }
  formatResources() {
    for(let resource of this.resources)
      resource.amountStr=resource.amount.toString().replace('.',',');
  }
  // Resource
  changeResource(event) {
    this.resource_id=parseInt(event.target.value);
  }
  getResourceValue(key: string) {
    for(let option of this.resource_options)
      if(option.key===key)
        return option.value;
    return '';
  }
  // Amount
  keyupAmount(event) {
    this.amount=parseFloat(event.target.value);
    if(isNaN(this.amount))
      this.amount=0;
  }
  blurAmount(id: number, event) {
    let amount=event.target.value;
    if(isNaN(parseFloat(amount))) {
      amount = '0';
      (<HTMLInputElement>document.getElementById('amount-'+id.toString())).value = '0';
    }
    if(amount==='0') {
      this.delete(id);
      return;
    }
    this.calculateDensity();
  }
  // Actions
  add() {
    this.resources.push( {
      id: 0,
      resource_id: this.resource_id,
      description: this.getResourceValue(this.resource_id.toString()),
      amount: this.amount,
      amountStr: this.amount.toString().replace('.',',')
    });

    // Reset inputs
    this.resource_id=0;
    this.amount=0;
    (<HTMLInputElement>document.getElementById('resource')).value = '';
    (<HTMLInputElement>document.getElementById('amount')).value = '';

    this.updateResourceOptions();
    this.calculateDensity();
  }
  delete(id: number) {
    for(let r=0; r<this.resources.length; r++)
      if(this.resources[r].id===id)
        this.resources.splice(r,1);

    this.updateResourceOptions();
    this.calculateDensity();
  }
  calculateDensity() {
    let density=0.0;
    for(let resource of this.resources)
      density+=resource.amount;
    this.density=this.SanitizationService.amountFloatToStr(density,2);
  }
  getResourcesData(recipe_id: number) {
    let data={ recipes_id: recipe_id, resources: [] }
    for(let resource of this.resources)
      data.resources.push( { resources_id: resource.resource_id, amount: resource.amount })
    return data;
  }
  save(recipe_id: number) {
    let data=this.getResourcesData(recipe_id);
    this.RecipeResourcesService.update(data).subscribe(()=>{},(error)=>console.error(error))
  }
}
