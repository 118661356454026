<div class="jbm-state-dropdown">
   <label for="stateDropdown" class="float-start">{{'invoice.state'|translate}}:</label>
   <div ngbDropdown>
      <button type="button" class="btn btn-light float-start" id="stateDropdown" ngbDropdownToggle>
         <span *ngIf="!id">{{'ui.all'|translate}}</span>
         <span *ngIf="id">{{invoiceState.description}}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="stateDropdown">
         <button type="button" *ngIf="id" ngbDropdownItem (click)="setInvoiceState(0)">
            <span class="badge badge-pill bg-white text-dark">{{'ui.all'|translate}}</span>
         </button>
         <div *ngFor="let is of invoiceStates">
            <button type="button" *ngIf="!(is.id==id)" ngbDropdownItem (click)="setInvoiceState(is.id)">
               <span class="badge badge-pill opacity-7" style="background-color: {{is.color}}; color:{{is.text_color}}">
                  {{is.description}}
               </span>
            </button>
         </div>
      </div>
   </div>
</div>
