import { Injectable } from '@angular/core';
import { HttpService } from "../http.service";
import {HttpParams} from "@angular/common/http";

const URL='customers/read/cocdata';

@Injectable({
  providedIn: 'root'
})
export class COCService {

  constructor(private HttpService: HttpService) {}

  getAddress(COCNumber) {
    let params=new HttpParams().set('cocnumber',COCNumber);
    return this.HttpService.get(URL,params);
  }
}
