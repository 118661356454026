import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'jbm-view-title',
  templateUrl: './JBMViewTitle.component.html',
  styleUrls: ['./JBMViewTitle.component.scss']
})
export class JBMViewTitle implements OnInit {

  @Input() caption: string;
  @Input() iconClassname: string;
  @Input() subCaption: string;
  @Input() itemcount: number;
  @Input() size: string;
  @Input() linkSlug: string;
  @Input() parentLinkCaption: string;
  @Input() parentLinkSlug: string;

  constructor() { }

  ngOnInit(): void {
    if(this.caption==undefined) this.caption='';
    if(this.iconClassname==undefined) this.iconClassname='';
    if(this.size==undefined) this.size='';
    if(this.itemcount==undefined) this.itemcount=0;
    if(this.parentLinkCaption==undefined) this.parentLinkCaption='';
    if(this.linkSlug==undefined) this.linkSlug='';
    if(this.parentLinkSlug==undefined) this.parentLinkSlug='';
  }
}
