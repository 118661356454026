<form [formGroup]="passwordForm" (ngSubmit)="change()" class="user">
   <div class="jbm-modal-header">
      <jbm-crud-title subject="login.password" [action]="2"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-help-button [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label
        [labelCaption]="'login.username'|translate"
        [labelClass]="'width-9'"
        [value]="user.username">
      </jbm-static-label>

      <password-constraints-alert
        [visible]="formControls.passwordNew.hasError('passwordInvalid') ||
                   formControls.passwordConfirm.hasError('passwordInvalid')">
      </password-constraints-alert>
      <jbm-text-input
           [id]="'passwordNew'"
           [formControl]="formControls.passwordNew"
           [required]="true"
           [class]="'input-15'"
           [labelCaption]="'users.password-new'|translate"
           [labelClass]="'width-9'"
           [submitted]="isSubmitted"
           [type]="'password'">
         <jbm-input-error [formControl]="formControls.passwordNew" [submitted]="isSubmitted">
           <span *ngIf="formControls.passwordNew.hasError('passwordInvalid')">
             {{'login.password-error'|translate}}
           </span>
           <span *ngIf="formControls.passwordNew.hasError('samePassword')">
             {{'users.password-current-error'|translate}}
           </span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-text-input
        [id]="'passwordConfirm'"
        [formControl]="formControls.passwordConfirm"
        [required]="true"
        [class]="'input-15'"
        [labelCaption]="('users.password-new'|translate)+' '+('users.password-confirm'|translate)"
        [labelClass]="'width-9'"
        [submitted]="isSubmitted"
        [type]="'password'">
         <jbm-input-error [formControl]="formControls.passwordConfirm" [submitted]="isSubmitted">
           <span *ngIf="formControls.passwordConfirm.hasError('passwordInvalid')">
             {{'login.password-error'|translate}}
           </span>
           <span *ngIf="formControls.passwordConfirm.hasError('confirmInvalid')">
             {{'users.password-confirm-error'|translate}}
           </span>
         </jbm-input-error>
      </jbm-text-input>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button class="btn btn-primary" (click)="change()"><i class="fas fa-check"></i> {{'users.password-change'|translate}}</button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
