<div ngbDropdown #dropdown="ngbDropdown" container="body">
   <button type="button" class="btn btn-sm btn-outline-secondary btn-block" id="jbm-cpp" ngbDropdownToggle>
      <i class="fa fa-tint"></i> {{'ui.colors'|translate}}
   </button>
   <div ngbDropdownMenu [attr.aria-labelledby]="'jbm-cpp'" class="jbm-color-palette-picker table-cell">
      <div class="clearfix">
         <div class="backgrounds-container">
            <h6>{{'ui.background'|translate}}{{'ui.color'|translate|lowercase}}</h6>
            <div *ngFor="let b of backgrounds; let i=index">
               <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" [checked]="b==background" [value]=b id="backgrounds{{i}}"
                    (change)="changeBackground(b)">
                  <label class="form-check-label" for="backgrounds{{i}}" style="background-color: {{b}};"></label>
               </div>
            </div>
         </div>
         <div class="foregrounds-container">
            <h6>{{'ui.text'|translate}}{{'ui.color'|translate|lowercase}}</h6>
            <div *ngFor="let f of foregrounds; let i=index">
               <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" [checked]="f==foreground" [value]=f id="foregrounds{{i}}"
                    (change)="changeForeground(f)">
                  <label class="form-check-label" for="foregrounds{{i}}" style="background-color: {{f}};"></label>
               </div>
            </div>
         </div>
      </div>
      <div class="footer">
         <button type="button" class="btn btn-primary btn-sm mr-2" (click)="accept()">{{'ui.accept'|translate}}</button>
         <button type="button" class="btn btn-secondary btn-sm" (click)="cancel()">{{'ui.cancel'|translate}}</button>
      </div>
   </div>
</div>
