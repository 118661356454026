import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {PlanningService} from "../data/planning.service";
import {PlantsService} from "../../company/data/plants.service";
import {OrdersService} from "../../projects/data/orders.service";
import {DatetimeService} from "../../../services/datetime/datetime.service";
import {NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Plant} from "../../company/data/interfaces/Plant";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'form-plan-order',
  templateUrl: './plan-order.component.html',
  styleUrls: ['./plan-order.component.scss']
})
export class PlanOrderComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  form: FormGroup;
  submitted: boolean=false;
  plants: Plant[]=[];
  plant: Plant;
  plantOptions: JBMSelectOption[]=[];
  loadingTime: number;
  endTime: string;
  loadTime: string;
  date: NgbDateStruct;
  maxdate: NgbDateStruct;
  saved: boolean;

  @Input() order: any;
  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  constructor(
      private PlanningService: PlanningService,
      private PlantsService: PlantsService,
      private OrdersService: OrdersService,
      private DatetimeService: DatetimeService,
      private formBuilder: FormBuilder,
      private TranslateService: TranslateService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService ) {
    this.addControls();
  }
  ngOnInit(): void {
    this.saved=false;
    this.PlantsService.getData(null).subscribe(
        (data)=>this.plants=data.data,
        (error)=>console.log(error),
        ()=> {
          this.plantOptions=this.PlantsService.getSelectOptions(this.plants);
          this.OrdersService.getLoadingTime().subscribe(
              (data)=>this.loadingTime=data.data,
              (error)=>console.log(error),
              ()=>this.setControlValues()
          );
        }
    );
  }
  addControls() {
    this.form = this.formBuilder.group({
      plants_id: new FormControl(''),
      production_date: new FormControl('',{ updateOn: 'submit', validators: [this.DatetimeService.dateValidator(this.DatetimeService,true)]} ),
      starttime: new FormControl('',{ updateOn: 'blur', validators: [Validators.pattern(this.DatetimeService.getTimeRegEx())]} )
    })
  }
  setControlValues() {
    this.form.controls.plants_id.setValue(this.order.project.plants_id.toString());
    this.form.controls.production_date.setValue(this.order.production_date===null ? '' : this.order.production_date );
    this.form.controls.starttime.setValue( this.order.starttime===null ? '' : this.order.starttime );
  }
  get formControls() {
    return this.form.controls;
  }
  calcLoadTime() {
    this.loadTime='';

    if(!this.form.controls.starttime.valid) return;
    let starttime=this.form.controls.starttime.value.trim();
    if(starttime!=='')
      this.loadTime=this.OrdersService.calcLoadTime(starttime, this.order.project.traveltime, this.loadingTime );
  }
  calcEndTime() {
    this.endTime='';
    if(!this.form.controls.starttime.valid) return;
    let starttime=this.form.controls.starttime.value.trim();
    if(starttime!=='')
      this.endTime=this.OrdersService.calcEndTime(starttime, this.order.amount, this.order.unloading_flow);
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let production_date=this.DatetimeService.dateDMYToYMD(this.formControls.production_date.value);

    // Plan order
    let data = {
      id: this.order.id,
      recipes_id: this.order.recipes_id,
      amount: this.order.amount,
      projects_id: this.order.projects_id,
      plants_id: parseInt(this.formControls.plants_id.value),
      production_date: production_date,
      starttime: this.formControls.starttime.value,
      loadtime: this.loadTime
    }

    this.saved=true;
    this.order.production_date=this.formControls.production_date.value;
    this.order.starttime=this.formControls.starttime.value;

    this.OrdersService.update(data, this.language).subscribe(
        ()=>{},error=>console.error(error),()=> {
          this.saved=true;
          this.JBMToastsService.success(this.TranslateService.GetTranslation('planning.planned-message'));
          this.onSave.emit();
        }
    )
  }
  edit() {
    this.saved=false;
    this.setControlValues();
    document.getElementById('production_date'+this.order.id).focus();
  }
  cancel() {
    this.setControlValues();
    this.loadTime='';
    this.endTime='';
    document.getElementById('production_date'+this.order.id).focus();
    this.onCancel.emit();
  }
  confirmDelete(order_id) {
    this.PlanningService.remove(order_id).subscribe(
        ()=>{},(error)=>console.error(error),
        ()=> {
          this.order.production_date=null;
          this.order.starttime=null;
          this.loadTime='';
          this.endTime='';
          this.setControlValues();
          this.saved=false;

          this.form.controls.production_date.markAsUntouched();
          this.form.controls.production_date.markAsPristine();
          this.form.controls.production_date.setErrors({dateInvalid: null});
          this.form.controls.starttime.setErrors({pattern: null});

          this.JBMToastsService.success(this.TranslateService.GetTranslation('planning.removed-message'));
          document.getElementById('production_date'+this.order.id).focus();
        }
    )
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if(event.key=='Escape')
      this.cancel();
  }
}


