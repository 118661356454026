import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'jbm-breadcrumbs',
  templateUrl: './JBMBreadcrumbs.component.html',
  styleUrls: ['./JBMBreadcrumbs.component.scss']
})
export class JBMBreadcrumbsComponent implements OnInit {

  constructor() { }

  @Input() activeCaption: string='';
  @Input() level0Caption: string='';
  @Input() level0Route: string='';
  @Input() level1Caption: string='';
  @Input() level1Route: string='';
  @Input() level2Caption: string='';
  @Input() level2Route: string='';
  @Input() level3Caption: string='';
  @Input() level3Route: string='';

  ngOnInit(): void {
    if(this.activeCaption==undefined) this.activeCaption='';
    if(this.level0Caption==undefined) this.level0Caption='';
    if(this.level0Route==undefined) this.level0Route='';
    if(this.level1Caption==undefined) this.level1Caption='';
    if(this.level1Route==undefined) this.level1Route='';
    if(this.level2Caption==undefined) this.level2Caption='';
    if(this.level2Route==undefined) this.level2Route='';
    if(this.level3Caption==undefined) this.level3Caption='';
    if(this.level3Route==undefined) this.level3Route='';
  }
}
