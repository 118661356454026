<form [formGroup]="form" (ngSubmit)="save()" class="payment">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">{{'invoice.handle-payment'|translate}}</div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-static-label labelClass="md" [labelCaption]="'invoice.number'|translate" [value]="invoice.invoice_number"></jbm-static-label>
      <jbm-static-label labelClass="md" [labelCaption]="'ui.totalprice'|translate" [value]="totalPriceStr"></jbm-static-label>
      <jbm-static-label labelClass="md" [labelCaption]="'entity.outstanding'|translate" [value]="outstandingStr"></jbm-static-label>
      <jbm-float-input-group [id]="'amount'" [formControl]="formControls.amount" [required]="true" [submitted]="submitted" [labelCaption]="'entity.payment-amount'|translate" class="xxs"
        labelClass="md" prepend="&euro;">
         <jbm-input-error [formControl]="formControls.amount" [submitted]="submitted">
           <span *ngIf="formControls['amount'].hasError('priceInvalid')">{{'entity.priceformat'|translate}}</span>
           <span *ngIf="formControls['amount'].hasError('outstandingExceed')">{{'invoice.payment-exceeds-outstanding'|translate}}</span>
         </jbm-input-error>
      </jbm-float-input-group>
      <jbm-textarea [id]="'comments'" [formControl]="formControls.comments" [submitted]="submitted" labelStacked="true"
        class="xl" [labelCaption]="'entity.comments'|translate"></jbm-textarea>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <button type="button" class="btn btn-sm btn-primary" (click)="save();">{{'invoice.handle-payment'|translate}}</button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="close()"></jbm-cancel-button>
      </div>
   </div>
</form>
