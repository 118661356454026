import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router } from  '@angular/router';
import {User } from '../../../services/auth/user';
import {AuthService } from '../../../services/auth/auth.service';
import {TranslatePipe } from '../../../pipes/translate.pipe';
import {LocalStorageService } from "../../../services/storage/localstorage.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {LanguagesService } from '../../../services/multilingual/languages.service';
import {FormValidationService } from "../../../services/validation/form.validation.service";
import {BrowserLanguage} from "../../../services/multilingual/browser.language";
import {GroupRights} from '../data/interfaces/grouprights';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [TranslatePipe]
})
export class LoginComponent implements OnInit {
  translationsLoaded: boolean=false;
  loginForm: FormGroup;
  isSubmitted: boolean=false;
  showAlert: boolean=false;
  languages=this.languagesService.GetLanguages();
  rights: GroupRights=this.TokenService.getRightsByName('customer');

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private LocalStorageService: LocalStorageService,
    private TokenService: TokenService,
    private TranslateService: TranslateService,
    private languagesService: LanguagesService,
    private FormValidationService: FormValidationService
  ) {
    this.checkTranslationLoaded();
  }

  checkTranslationLoaded(): void{
    let check = this.LocalStorageService.hasKey('translation');

    if(!check){
      this.translationsLoaded=false;
      setTimeout(()=>{
        this.checkTranslationLoaded();
      },1000)
    } else {
      setTimeout(()=>{
        this.translationsLoaded=true;
        setTimeout(()=>{
          document.getElementById('username').focus();
        },200);
      },100)
    }
  }

  ngOnInit(): void {
    // Determine and set user language
    let language='';
    // Try token language setting
    if(this.authService.getToken()!==null)
      language=this.TokenService.getLanguage();
    else {
      console.log('LoginComponent ngOnInit');
      // No token set, so try localstorage language setting
      language=this.LocalStorageService.get('language');
      if(language===null) {
        // No localstorage language set, so try native browser language
        let browserLanguage = new BrowserLanguage();
        language = browserLanguage.getLanguage();
      }
    }

    this.loginForm=this.formBuilder.group({
      username: ['', [Validators.required, Validators.minLength(4)] ],
      password: ['', [Validators.required, this.FormValidationService.passwordValidator()]],
      language: [language,[Validators.required]]
    },{
      updateOn: "submit"
    });
  }

  get formControls() { return this.loginForm.controls; }

  login() {
    this.isSubmitted = true;
    if(this.loginForm.invalid) return;

    let user = new User();
    user.username=this.loginForm.controls.username.value;
    user.password=this.loginForm.controls.password.value;
    user.language_choice=this.loginForm.controls.language.value;
    this.LocalStorageService.set('language',user.language_choice);

    this.authService.logIn(user)
      .toPromise()
      .then(text => {
        this.authService.setToken(text);
        this.TranslateService.LoadTranslations();
        // Reload App to ensure that also authentication token is set
        // Show initial page
        if(this.rights.AllowRead)
          window.location.pathname='customers';
        else
          window.location.pathname='';
      }).catch(() => {
        this.showAlert=true;
      });
  }
}
