import { Component } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'users-management',
  templateUrl: './users.management.component.html',
  styleUrls: ['./users.management.component.scss']
})
export class UsersManagementComponent {
  active: number;

  constructor(private Router: Router) {
    // Set tab according active URL
    if(Router.url.indexOf('usergroups')>0)  this.active=1;
    if(Router.url.indexOf('grouprights')>0) this.active=2;
  }
}
