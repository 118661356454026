import {Component, OnInit, OnDestroy, ViewChild, ElementRef} from '@angular/core';
import {ConcreteConsistenciesService} from "../../data/concrete-consistencies.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {JBMTableColumnDef} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'consistencies',
  templateUrl: './consistencies.component.html',
  styleUrls: ['./consistencies.component.scss']
})
export class ConsistenciesComponent implements OnInit, OnDestroy {
  userRights: any;
  items: any;
  columnDefs: JBMTableColumnDef[];
  language: string=this.TokenService.getLanguage();
  id: number;
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisble: boolean=true;
  search: string='';

  createActive: boolean=false;
  description: string='';
  descriptionTouched: boolean=false;
  code: string='';
  codeError: string='';
  codeTouched: boolean=false;
  codeUnique:boolean=true;
  editing: boolean=false;
  error: string='';
  unique:boolean=true;

  constructor(
      public ConcreteConsistenciesService: ConcreteConsistenciesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let httpParams=this.queryHttpHelper.getHttpParams(false);
    httpParams=httpParams.append('no-baseprice-indicators','1');
    this.items$=this.ConcreteConsistenciesService.getData(this.language, httpParams).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{

          // Set editmode to false for each item
          for(let item of this.items)
            item.editing=false;

          this.loading=false;
          if(countRows) {
            let httpParams=this.queryHttpHelper.getHttpParams(true);
            httpParams=httpParams.append('no-baseprice-indicators','1');
            this.count$=this.ConcreteConsistenciesService.getData(this.language, httpParams).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();

    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row already in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.code=data.code;
    this.codeTouched=true;
    this.description=data.description;
    this.descriptionTouched=true;
    this.unique=true;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData(false);
  }
  onChangeCode() {
    this.codeTouched=true;
  }
  validateCode() {
    let value=this.code.trim();
    if(!value) this.codeError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.error=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    delete data.id;
    this.ConcreteConsistenciesService.create(data).subscribe(()=>{},
        error => { this.handleError(error) },() => { this.afterSave() })
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.code===this.code && originalValues.description===this.description) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }
    let data=this.setItemData(originalValues.id);
    this.ConcreteConsistenciesService.update(data).subscribe(
        ()=>{},error => { this.handleError(error) },
        () => { this.afterSave() })
  }
  getValues() {
    this.code=this.code.trim();
    this.description=this.description.trim();
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    return { id: id, code: this.code, language: this.language, description: this.description}
  }
  clearItemData() {
    this.code='';
    this.codeTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  handleError(error) {
    this.codeUnique=true;
    if(error==='code_not_unique') {
      this.codeUnique=false;
      this.codeError=this.TranslateService.GetTranslation('entity.code-not-unique');
    }
    this.unique=true;
    if(error=='not_unique') {
      this.unique=false;
      this.error=this.TranslateService.GetTranslation('entity.code-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) {
      this.createCancel();
      this.getData(true);
    }
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
