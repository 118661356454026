<div class="form-group row {{class}}" [ngClass]="{'stacked': labelStacked, 'readonly': !editable}">
   <label *ngIf="labelCaption" for="{{id}}" class="{{labelClass}}" [ngClass]="{'col-form-label': !labelStacked, 'col-form-label-sm': sm}">
      {{labelCaption}}
      <span *ngIf="!editable">:</span>
      <span *ngIf="editable && required" class="asterix">*</span>
   </label>
   <div class="controls group">
      <div class="input-group">
         <div *ngIf="prepend" class="input-group-prepend">
            <span class="input-group-text">{{prepend}}</span>
         </div>
         <label *ngIf="!editable" class="col-form-label" [ngClass]="{'col-form-label-sm': sm}">{{getDataValue()}}</label>
         <input *ngIf="editable" [type]="type" class="form-control" id="{{id}}" autocomplete="off" [formControl]="ngControl.control"
           [attr.maxlength]="maxlength>0 ? maxlength : null"
           [ngClass]="{'is-invalid': editable && ngControl.invalid && (ngControl.touched || submitted), 'form-control-sm': sm, 'align-right': alignRight }"
           (blur)="blur($event)">
         <div *ngIf="appendTemplate" class="input-group-append input-group-text p-0">
            <ng-container *ngTemplateOutlet="appendTemplate"></ng-container>
         </div>
         <div *ngIf="append" class="input-group-append">
            <span class="input-group-text">{{append}}</span>
         </div>
      </div>
      <ng-content></ng-content>
   </div>
</div>
