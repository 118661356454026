import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild} from '@angular/core';
import {InvoicesService} from "./../data/invoices.service";
import {CustomerService} from "../../customers/data/customer.service";
import {GoogleMapsService} from "../../../services/google-maps/google-maps.service";
import {GPSCoordinates} from "../../../components/JBM/Helpers/GPSInfo";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {FormBuilder} from "@angular/forms";

@Component({
  selector: 'invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.scss']
})
export class InvoiceDetailsComponent implements OnChanges {
  customer: any;
  project_id: number=0;
  projectSelect: boolean=false;

  GPS_address: GPSCoordinates={longitude: '', latitude: ''};
  GPS_invoice_address: GPSCoordinates={longitude: '', latitude:''};

  @Input() invoice: any;
  @Input() expanded: boolean;
  @Input() editable: boolean;

  @Output() projectSelected=new EventEmitter();

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;
  @ViewChild('modalCustomerComments') modalCustomerComments: ElementRef;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;

  constructor(
      private InvoicesService: InvoicesService,
      private GoogleMapsService: GoogleMapsService,
      private CustomerService: CustomerService,
      private formBuilder: FormBuilder,
      private ModalService: ModalService
  ) {}
  ngOnChanges() {
    // Get GPS Location of address
    this.GoogleMapsService.getLookup(
        this.invoice.customer.address_details.street,
        this.invoice.customer.address_details.no,
        this.invoice.customer.address_details.postcode,
        this.invoice.customer.address_details.city).subscribe(
        (data)=> this.GPS_address=data,(error)=>console.error(error),()=>{}
    );

    // Get GPS Location of invoice address if set
    if(this.invoice.customer.invoice_address_id==0)
      return;
    this.GoogleMapsService.getLookup(
        this.invoice.customer.invoice_address_details.street,
        this.invoice.customer.invoice_address_details.no,
        this.invoice.customer.invoice_address_details.postcode,
        this.invoice.customer.invoice_address_details.city).subscribe(
        (data)=> this.GPS_invoice_address=data,(error)=>console.error(error),()=>{}
    );
  }

  // Customer
  showCustomer(customer_id: number) {
    this.CustomerService.get(customer_id,true).subscribe(
        (data)=>this.customer=data.data,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
    );
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }
  editCustomerComments() {
    this.ModalService.open(this.modalCustomerComments,'customerComments')
  }
  saveCustomerComments(event) {
    this.invoice.customer_comments=event.comments;
    this.closeCustomerComments();
  }
  closeCustomerComments() {
    this.ModalService.close('customerComments');
  }

  // Project
  initProjectSelect() {
    this.projectSelect=true;
  }
  selectProject(project) {
    this.project_id=project.id;
  }
  setProject() {
    this.projectSelect=false;
    this.InvoicesService.update({ id: this.invoice.id, projects_id: this.project_id }).subscribe(
      ()=>{},(error)=>console.error(error),()=> this.projectSelected.emit()
    )
  }
  endProjectSelect() {
    this.projectSelect=false;
  }
  showProject() {
    this.ModalService.open(this.modalProjectDetails,'projectDetails')
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }
}
