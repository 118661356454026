import {Component, Input, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {StateService} from "../../../../services/storage/state.service";

@Component({
  selector: 'jbm-gps-location-button',
  templateUrl: './JBMGPSLocationButton.component.html',
  styleUrls: ['./JBMGPSLocationButton.component.scss'],
})

export class JBMGPSLocationButtonComponent implements OnInit {
  @Input() longitude: string;
  @Input() latitude: string;
  @Input() title: string;
  @Input() address: string;
  @Input() btnContext: string;
  @Input() extraSmall: boolean;

  constructor(
      private Router: Router,
      private StateService: StateService
  ) {}

  ngOnInit(): void {
    if(this.longitude==undefined) this.longitude='';
    if(this.latitude==undefined) this.latitude='';
    if(this.title==undefined) this.title='';
    if(this.address==undefined) this.address='';
    if(this.btnContext==undefined) this.btnContext='outline-secondary';
    if(this.extraSmall==undefined) this.extraSmall=false;
  }
  click() {
    // No title/address in the url; so use state
    this.StateService.addElement(this.longitude+','+this.latitude,{title: this.title, address: this.address});
    // Show the Map
    this.Router.navigate([ { outlets: {'ar': ['map', this.longitude, this.latitude]}} ] );
  }
}

