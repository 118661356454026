<form [formGroup]="form" (ngSubmit)="save()" class="mailsettings">
    <div class="jbm-modal-header">
        <div class="jbm-crud-title">
            <div class="title">{{'mail.server'|translate}} {{'entity.settings'|translate|lowercase}}</div>
        </div>
        <div class="toolbar float-end">
            <jbm-activity-log-button *ngIf="mailSettings.id" [subject]="('mail.server'|translate) + ' ' + ('entity.settings'|translate|lowercase)"
              [subject_type]="31" [subject_id]="mailSettings.id">
            </jbm-activity-log-button>
            <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <jbm-text-input [id]="'name'" [formControl]="formControls['name']" [required]="true" [submitted]="isSubmitted"
            [labelCaption]="'entity.name'|translate" labelClass="md" class="md">
                <jbm-input-error [formControl]="formControls['name']" [submitted]="isSubmitted">
                    <span *ngIf="formControls['name'].hasError('notUnique')">{{'entity.name-not-unique'|translate}}</span>
                    <span *ngIf="formControls['name'].hasError('required')">{{'entity.requiredfield'|translate}}</span>
                </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [id]="'description'" [formControl]="formControls['description']"
            [submitted]="isSubmitted" [labelCaption]="'entity.description'|translate" labelClass="md" class="md mt-4">
        </jbm-text-input>
        <jbm-text-input [id]="'smtp'" [formControl]="formControls['smtp']" [required]="true" [submitted]="isSubmitted"
          [labelCaption]="'mail.smtp-host'|translate" labelClass="md" class="md mt-4">
            <jbm-input-error [formControl]="formControls['smtp']" [submitted]="isSubmitted">
                <span *ngIf="formControls['smtp'].hasError('required')">{{'entity.requiredfield'|translate}}</span>
            </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [formControl]="formControls.port" [required]="true" labelClass="md" [submitted]="isSubmitted"
            [labelCaption]="'mail.smtp-port'|translate" class="xxs" [id]="'port'" [maxlength]="4">
            <jbm-input-error [formControl]="formControls.port" [submitted]="isSubmitted">
                <span *ngIf="formControls.port.hasError('required')">{{'entity.requiredfield'|translate}}</span>
                <span *ngIf="formControls.port.hasError('pattern')">{{'mail.smtp-port-error'|translate}}</span>
            </jbm-input-error>
        </jbm-text-input>
        <jbm-text-input [id]="'password'" [formControl]="formControls.password" [required]="mailSettings.id===0"
          [class]="'md mt-4'" [labelCaption]="'login.password'|translate" [labelClass]="'md'"
          [submitted]="isSubmitted" [type]="'password'">
            <jbm-input-error [formControl]="formControls.password" [submitted]="isSubmitted">
                <span *ngIf="formControls.password.hasError('required')">
                    {{'entity.requiredfield'|translate}}
                </span>
            </jbm-input-error>
        </jbm-text-input>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="('mail.server'|translate) + ' ' + ('entity.settings'|translate|lowercase)"
                 (click)="save()">
            </jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
<jbm-toasts></jbm-toasts>
