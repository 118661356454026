<table *ngIf="resources.length" class="table table-xs mt-4 recipe-resources">
   <tr>
      <th>{{'entity.resource'|translate}}</th>
      <th class="amount">{{'order.amount'|translate}}</th>
      <th class="action"></th>
   </tr>
   <tr *ngFor="let resource of resources">
      <td>{{resource.description}}</td>
      <td><input float-only-input class="form-control form-control-sm align-right" [id]="'amount-'+resource.id.toString()" [value]="resource.amountStr" (blur)="blurAmount(resource.id,$event)"></td>
      <td><button type="button" class="btn btn-sm btn-block btn-danger" tabindex="-1" (click)="delete(resource.id)">{{'ui.delete'|translate}}</button></td>
   </tr>
   <tr>
      <th>{{'recipe.density'|translate}}</th>
      <th>{{density}}</th>
      <th></th>
   </tr>
</table>
<table class="table table-xs mt-3 recipe-resources">
   <tr>
      <th>{{'entity.resource'|translate}}</th>
      <th class="amount">{{'order.amount'|translate}}</th>
      <th class="action"></th>
   </tr>
   <tr>
      <td style="width: 356px;">
         <select id="resource" class="form-control form-control-sm form-select" (change)="changeResource($event)">
            <option value=""></option>
            <option *ngFor="let option of resource_options" [value]="option.key">{{option.value}}</option>
         </select>
      </td>
      <td style="width: 128px;">
         <input id="amount" float-only-input class="form-control form-control-sm align-right" (keyup)="keyupAmount($event)">
      </td>
      <td style="width: 92px;">
         <button [disabled]="resource_id==0 || amount==0.0" type="button" class="btn btn-sm btn-block btn-primary" (click)="add()">{{'ui.insert'|translate}}</button>
      </td>
   </tr>
</table>
