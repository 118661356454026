<div *ngIf="translationsLoaded" class="login-container">
  <form [formGroup]="loginForm" (ngSubmit)="login()" class="bg-white">
    <div class="logo-container">
      <img src="../../../../assets/img/logo.png" alt="JBM Logo">
    </div>
    <h5>{{'login.title'|translate}}</h5>
    <hr />
    <ngb-alert *ngIf="showAlert" type="danger" [dismissible]="false">{{'login.failed'|translate}}</ngb-alert>
    <jbm-text-input [formControl]="formControls['username']" [id]="'username'" [submitted]="isSubmitted" class="input-15"
      required="true" labelCaption="{{'login.username'|translate}}" labelStacked="true" maxlength="50">
      <jbm-input-error [formControl]="formControls['username']" [submitted]="isSubmitted">
        {{'login.username-error'|translate}}
      </jbm-input-error>
    </jbm-text-input>
    <jbm-text-input-group [formControl]="formControls['password']" [id]="'password'" [submitted]="isSubmitted" class="input-15"
      required="true" labelCaption="{{'login.password'|translate}}" labelStacked="true" [type]="'password'" maxlength="50"
      [appendTemplate]="password_help">
      <jbm-input-error [formControl]="formControls['password']" [submitted]="isSubmitted">
        <span *ngIf="formControls['password'].hasError('required')">
            {{'login.password-error'|translate}}
        </span>
      </jbm-input-error>
    </jbm-text-input-group>
    <ng-template #password_help>
      <ng-template #popContent>
        <ul class="pl-1">
          <li>{{'login.password-length'|translate}}</li>
          <li>{{'login.password-uppercase'|translate}}</li>
          <li>{{'login.password-lowercase'|translate}}</li>
          <li>{{'login.password-digits'|translate}}</li>
          <li>{{'login.password-spaces'|translate}}</li>
          <li>{{'login.password-special'|translate}}</li>
        </ul>
      </ng-template>
      <button type="button" class="btn btn-link" [ngbPopover]="popContent">
        <i class="fa fa-info"></i>
      </button>
    </ng-template>
    <div *ngIf="languages.length>1">
      <jbm-select [formControl]="formControls['language']" [options]="languages" [id]="'language'" [submitted]="isSubmitted" required="true"
        class="input-15" [tabIndex]="-1" labelCaption="{{'translate.language'|translate}}" labelStacked="true">
        <jbm-input-error [formControl]="formControls['language']" [submitted]="isSubmitted">
          {{'entity.requiredfield'|translate}}
        </jbm-input-error>
      </jbm-select>
    </div>
    <div class="actions mt-4">
      <button type="submit" class="btn btn-primary w-100">{{'login.button'|translate}}</button>
    </div>
  </form>
</div>
