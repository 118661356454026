import { Injectable } from '@angular/core';
import { AuthService} from "./auth.service";
import * as jwt_decode from 'jwt-decode';
import {GroupRights} from "../../features/users/data/interfaces/grouprights";

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  token: string;
  decoded: any;
  groupRights: GroupRights;

  constructor(private AuthService: AuthService) {
    this.token = this.AuthService.getToken();
    this.decoded = false;
    if(this.token!==null) {
      try {
        this.decoded = jwt_decode(this.token);
      }
      catch(err) {
        console.log(err);
      }
    }
  }
  getUserId() {
    if(!this.decoded) return false;
    return this.decoded.userid;
  }
  getUsername() {
    if(!this.decoded) return false;
    return this.decoded.username;
  }
  getLanguage() {
    if(!this.decoded) return 'nl'; // Fallback to the dutch language
    return this.decoded.language;
  }
  getModules() {
    if(!this.decoded) return false;
    return this.decoded.menu;
  }
  // Gets the App Modules with at least Read rights
  getAuthorizedModules(): any[] {
    let modules=this.getModules();
    if(!modules) return [];

    let rights=this.getRights();
    if(!rights) return [];

    let authorized=[];
    for(let m=0; m<modules.length; m++) {
      for(let r=0; r<rights.length; r++) {
        if(modules[m].right_id===rights[r].id) {
          if(rights[r].allow_read)
            authorized.push(modules[m]);
        }
      }
    }
    return authorized;
  }
  getRights(): any[] {
    if(!this.decoded) return [];
    return this.decoded.rights;
  }
  getRightsByName( name: string ) {
    // Get usergroup rights from authorisation token
    let rights = this.getRights();
    if(!rights.length) return this.emptyGroupRights();
    for(let i=0; i<rights.length; i++) {
      if(rights[i].name===name.toLowerCase()) {
        // Grouprights exists
        this.groupRights = {
          AllowCreate: rights[i].allow_create,
          AllowRead: rights[i].allow_read,
          AllowUpdate: rights[i].allow_update,
          AllowDelete: rights[i].allow_delete
        }
        return this.groupRights;
      }
    }
    return this.emptyGroupRights();
  }
  emptyGroupRights() {
    return {
      AllowCreate: false,
      AllowRead: false,
      AllowUpdate: false,
      AllowDelete: false
    };
  }
}
