<app-header>
    <jbm-view-title [caption]="'menu.dashboard'|translate" [iconClassname]="'tachometer-alt'"></jbm-view-title>
    <div class="toolbar float-end">
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module padding padding-top bg-light-darker">
    <div class="grid-container">
        <ktd-grid cols="12" [className]="'dashboard-layout'"
          rowHeight="50"
          compactType="vertical"
          compactOnPropsChange="true"
          preventCollision="false"
          [layout]="layout"
          [scrollableParent]="document"
          (layoutUpdated)="onLayoutUpdated($event)">
            <ktd-grid-item *ngFor="let item of layout; trackBy:trackById"
              draggable="true"
              resizable="false"
              dragStartThreshold="0"
              [id]="item.id">
                <div class="card">
                    <div class="card-header bg-white">
                        <div ktdGridDragHandle class="handle-1 float-start">Widget Handle {{item.id}}</div>
                        <div class="grid-item-remove-handle float-end" (click)="removeItem(item.id)">
                            <i class="fa fa-times"></i>
                        </div>
                    </div>
                    <div class="card-body">
                        Content
                    </div>
                </div>
            </ktd-grid-item>
        </ktd-grid>
    </div>



</section>
