import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {InvoiceState} from "./interfaces/invoice-state";

export const invoice_state = {
  draft: 100,
  validation: 150,
  approved: 200,
  send: 300,
  rejected: 400,
  overdue: 500,
  paid: 600
}

const baseURL: string = 'invoicing/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceStatesService {
  invoiceState: InvoiceState;

  constructor(private HttpService: HttpService) {}

  get(language, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/invoice-states/'+language, params );
  }
  getEmpty() {
    this.invoiceState = {
      id: 0,
      description: '',
      color: '',
      text_color: ''
    }
    return this.invoiceState;
  }
  getByID(invoiceStates: any[], id: number): any {
    for(let is=0; is<invoiceStates.length; is++)
      if(invoiceStates[is].id===id)
        return invoiceStates[is];
    return false;  // Means not found
  }
}