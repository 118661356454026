import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as codemirror from "codemirror";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/htmlmixed/htmlmixed";
import "codemirror/addon/lint/json-lint";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/javascript-hint";

@Component({
  selector: 'codemirror-editor',
  templateUrl: './codemirror-editor.component.html',
  styleUrls: ['./codemirror-editor.component.scss']
})
export class CodemirrorEditorComponent implements OnInit {
  @ViewChild("ref", {static: true}) ref: ElementRef;
  editor: codemirror.EditorFromTextArea;

  code: string;

  constructor() {
  }

  ngOnInit(): void {
    this.editor = codemirror.fromTextArea(this.ref.nativeElement, {
      mode: "javascript",
      indentWithTabs: true,
      smartIndent: true,
      lineNumbers: true,
      autofocus: true,
      showHint: true,
      extraKeys: {"Ctrl-Space": "autocomplete"}
    });
    this.editor.setValue('');
  }
  public getEditor() {
    return this.editor;
  }
  public insertPlaceholder(code: string) {
    this.editor.replaceSelection(code);
  }
}