import {Component, Input, OnInit} from '@angular/core';

export type JBMPropertiesListItem = {
  label: string;
  value: string;
  className?: string;
}

@Component({
  selector: 'jbm-properties-list',
  templateUrl: './JBMPropertiesList.component.html',
  styleUrls: ['./JBMPropertiesList.component.scss']
})
export class JBMPropertiesListComponent implements OnInit {

  @Input() className: string;
  @Input() properties: JBMPropertiesListItem[]=[];

  ngOnInit(): void {
    if(this.className==undefined) this.className='';
    if(this.properties==undefined) this.properties=[];
  }
}
