<app-header>
    <jbm-view-title [caption]="'order.unloading-methods'|translate"
        [itemcount]="queryHttpHelper.paginationProps.itemCount"
        [parentLinkCaption]="'menu.settings'|translate"
        [parentLinkSlug]="'settings'"></jbm-view-title>
    <div class="toolbar float-start">
        <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
    </div>
    <div class="toolbar float-end">
        <jbm-spinner [loading]="loading"></jbm-spinner>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
        <jbm-searchbox [visible]="searchboxVisble" [search]="search" [minlength]="2" (searchChange)="searchChange($event)"></jbm-searchbox>
        <div class="toolbar-spacer-1 float-end"></div>
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module">
    <div class="table-responsive">
        <table class="table padding table-sm" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
        <thead>
        <tr>
            <th [hidden]="true"></th>
            <th jbm-table-column class="speed align-right" [name]="'speed'" [header]="'order.unloading-speed'|translate"
                [sortable]="true" [sorted]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)" [width]="8"></th>
            <th jbm-table-column class="description" [name]="'description'" [header]="'entity.description'|translate"
                [sortable]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)"></th>
            <th class="actions editable"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="createActive" class="edit-active">
            <th [hidden]="true"></th>
            <td><input class="form-control form-control-sm" style="text-align: right" type="text" autocomplete="off" [(ngModel)]="speed"
                (change)="onChangeSpeed()" [ngClass]="{'ng-valid':validateSpeed(), 'is-invalid':speedTouched && !validateSpeed() }">
                <div *ngIf="speedError" class="invalid-feedback">{{speedError}}</div>
            </td>
            <td><input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="description"
                (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription(), 'is-invalid':this.descriptionTouched && (!validateDescription() || !unique) }">
                <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
            </td>
            <td class="editable actions">
                <button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!(validateSpeed() && validateDescription())" (click)="createItem()">{{'ui.save'|translate}}</button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
            </td>
        </tr>
        <tr *ngFor="let item of items" [ngClass]="{'edit-active': item.editing}" (click)="startEditing(item.id)">
            <td [hidden]="true"></td>
            <td *ngIf="!item.editing" class="speed align-right">{{item.speed}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}</td>
            <td *ngIf="!item.editing">{{item.description}}</td>
            <td *ngIf="!item.editing" class="actions"><button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm float-end"><i class="far fa-edit"></i></button></td>
            <td *ngIf="item.editing" class="editable code">
                <input class="form-control form-control-sm" style="text-align: right" type="text" autocomplete="off"
                   [(ngModel)]="speed" [placeholder]="item.speed" (change)="onChangeSpeed()" [ngClass]="{'ng-valid':this.validateSpeed(), 'is-invalid':!this.validateSpeed() }">
                <div *ngIf="speedError" class="invalid-feedback">{{speedError}}</div>
            </td>
            <td *ngIf="item.editing" class="editable actions">
                <input class="form-control form-control-sm" type="text" autocomplete="off"
                       [(ngModel)]="description" [placeholder]="item.description" (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && unique, 'is-invalid':!validateDescription() || !unique }">
                <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
            </td>
            <td *ngIf="item.editing" class="editable" style="width: 10rem">
                <button type="button" class="btn btn-sm btn-primary mr-1"
                  [disabled]="!(validateSpeed() && validateDescription())" (click)="updateItem()">{{'ui.save'|translate}}</button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
            </td>
        </tr>
        </tbody>
        </table>
        <jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
    </div>
</section>
<jbm-toasts></jbm-toasts>
