import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {AddressesService} from "../../../../features/addresses/data/addresses.service";
import {Address} from "../../../../features/addresses/data/interfaces/address";
import {GoogleMapsService} from "../../../../services/google-maps/google-maps.service";

@Component({
  selector: 'jbm-address-dropdown',
  templateUrl: './JBMAddressDropdown.component.html',
  styleUrls: ['./JBMAddressDropdown.component.scss']
})
export class JBMAddressDropdownComponent implements OnInit, OnChanges {
  addressLoaded: boolean=false;
  address: Address=this.AddressesService.getEmpty();

  constructor(
      private AddressesService: AddressesService,
      private GoogleMapsService: GoogleMapsService
  ) { }

  @Input() id: string='dd1';
  @Input() address_details_id: number;
  @Input() caption: string;
  @Input() btnContext: string;
  @Input() class: string;
  @Input() iconClass: string;
  @Input() extraSmall: boolean;

  @Output() onOpenChange=new EventEmitter();

  ngOnInit(): void {
    if(this.id==undefined) this.id='dd1';
    if(this.address_details_id==undefined) this.address_details_id=0;
    if(this.caption==undefined) this.caption='';
    if(this.btnContext==undefined) this.btnContext='light';
    if(this.class==undefined) this.class='';
    if(this.iconClass==undefined) this.iconClass='map-marker-alt';
    if(this.extraSmall==undefined) this.extraSmall=false;
  }
  ngOnChanges() {
      if(this.caption) this.caption+=' '; // Insert space between caption and icon
  }
  toggle() {
    this.loadAddress();
  }
  loadAddress() {
    if(this.address_details_id===null || this.address_details_id===0 || this.addressLoaded) return false;
    this.AddressesService.get(this.address_details_id).subscribe(
        (data)=>this.address=data.data,
        (error)=>console.error(error),
        ()=>{
          this.addressLoaded=true;
          if(this.address.city && !this.address.lat && !this.address.lon) {
            // Get GPS Location of address
            this.GoogleMapsService.getLookup( this.address.street, this.address.no,
                this.address.postcode, this.address.city).subscribe(
                    (data)=> {
                      this.address.lat=data.latitude;
                      this.address.lon=data.longitude;
                    },
                    (error)=>console.error(error),()=>{}
            );
          }
        }
    )
  }
  openChange(opened: boolean) {
    this.onOpenChange.emit(opened);
  }
}
