import {Component, ElementRef, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ResourcePricelistsService} from "../../data/resource-pricelists.service";
import {PlantsService} from "../../../company/data/plants.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {TokenService} from "../../../../services/auth/token.service";
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType, JBMTableComponent
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {ResourcePricelist} from "../../data/interfaces/resource-pricelist";
import {GroupRights} from '../../../users/data/interfaces/grouprights';

@Component({
  selector: 'resource-pricelists',
  templateUrl: './resource-pricelists.component.html',
  styleUrls: ['./resource-pricelists.component.scss']
})
export class ResourcePricelistsComponent implements OnInit {
  language: string=this.TokenService.getLanguage();
  asModule: boolean=true;
  caption: string='';
  tableClassname: string='pricelists';
  initialCount: number=null;
  userRights: GroupRights;
  items: any;
  pricelist: ResourcePricelist;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  id: number;
  loading: boolean=false;
  plantOptions: JBMSelectOption[]=[];
  plants_id: number=0;

  items$: Subscription;
  count$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @Input() customer_id: number=0;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('usesTemplate') usesTemplate: TemplateRef<any>;
  @ViewChild('pricelistModal') pricelistModal: ElementRef;
  @ViewChild('copyModal') copyModal: ElementRef;
  @ViewChild('usesModal') usesModal: ElementRef;
  @ViewChild('connectCustomersModal') connectCustomersModal: ElementRef;
  @ViewChild('assignProjectsModal') assignProjectsModal: ElementRef;

  constructor(
      private ResourcePricelistsService: ResourcePricelistsService,
      private PlantsService: PlantsService,
      private ModalService: ModalService,
      private TokenService: TokenService,
      private Router: Router,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    if(this.customer_id==undefined) this.customer_id=0;
    if(this.customer_id>0) this.asModule=false;
    if(this.asModule)
      this.tableClassname+=' padding';

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true},
      { name: 'type', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('pricelist.type'), filterable: true, width: 12,
        align: JBMTableColumnAlignment.left,
        filterValues: this.ResourcePricelistsService.getTypeSelectOptions(this.TranslateService) as JBMSelectOption[],
        displayValues: this.ResourcePricelistsService.getTypeDisplayValues(this.TranslateService)
      },
      { name: 'plant', header: this.TranslateService.GetTranslation('company.plant'), visible: true, width: 15 },
      { name: 'uses', type: JBMTableColumnType.boolean, width: 8 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 9.5 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    let plants=[];
    this.PlantsService.getData().subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }
          setTimeout(()=> {
            this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
            this.getData(true);
          },100);
        }
    )
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.setParams();
    this.items$=this.ResourcePricelistsService.get(this.language, params).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{

          if(this.items.length)
            this.setDeletePermissions();

          // Set plant column visibility
          this.columnDefs[3].visible=!this.plants_id;

          // Set uses column template
          this.columnDefs[4].template=this.usesTemplate;

          // Set actions column template
          this.columnDefs[5].template=this.actionsTemplate;

          this.loading=false;
          if(countRows) {
            let params=this.setParams(true);
            this.count$=this.ResourcePricelistsService.get(this.language, params).subscribe(
                (data: any) => {
                  this.queryHttpHelper.paginationProps.itemCount=data.data;
                  if(this.initialCount===null) this.initialCount=data.data;
                },(error)=>console.error(error),()=>{} );
          }
        } );
  }
  setParams(countRows=false) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    if(this.plants_id) params=params.append('plants_id', this.plants_id.toString());
    if(this.customer_id) params=params.append('customer_id', this.customer_id.toString());
    return params;
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  changePlant(event) {
    this.plants_id=parseInt(event.target.value);

    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
      this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');

    this.getData(true);
  }
  setDeletePermissions() {
    let ids=[];
    for(let i=0; i<this.items.length; i++ ) {
      this.items[i].deletePermission=false;
      ids.push(this.items[i].id);
    }
    let deletePermissions=[];
    this.ResourcePricelistsService.getDeletePermissions(ids).subscribe(
        (data)=>deletePermissions=data.data,
        (error)=>console.error(error),
        ()=>{
          for(let i=0; i<this.items.length; i++ )
            this.items[i].deletePermission=(deletePermissions.indexOf(this.items[i].id)>-1);
        }
    )
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  rowSelect(row) {
    this.Router.navigateByUrl('settings/financial/resource-prices/'+row.id.toString());
  }

  // Uses
  showUses(id: number) {
    this.ResourcePricelistsService.getOne(this.language, id).subscribe(
        (data)=>this.pricelist=data.data as ResourcePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.usesModal,'uses')
    )
  }
  closeUses() {
    this.ModalService.close('uses');
  }

  // Connect to customers
  connectToCustomers(id: number) {
    this.ResourcePricelistsService.getOne(this.language, id).subscribe(
        (data)=>this.pricelist=data.data as ResourcePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.connectCustomersModal,'connect')
    )
  }
  connected() {
    this.ModalService.close('connect');
    this.getData(true);
  }
  cancelConnect() {
    this.ModalService.close('connect');
  }

  // Assign to projects
  assignToProjects(id: number) {
    this.ResourcePricelistsService.getOne(this.language, id).subscribe(
        (data)=>this.pricelist=data.data as ResourcePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.assignProjectsModal,'assign')
    )
  }
  projectsAssigned() {
    this.ModalService.close('assign');
    this.getData(true);
  }
  projectsAssignCancel() {
    this.ModalService.close('assign');
  }

  addItem() {
    this.pricelist=this.ResourcePricelistsService.getEmpty();
    this.ModalService.open(this.pricelistModal,'resource-pricelist');
  }
  edit(id: number) {
    this.ResourcePricelistsService.getOne(this.language, id).subscribe(
        (data)=>this.pricelist=data.data as ResourcePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.pricelistModal,'resource-pricelist')
    )
  }
  pricelistSaved() {
    this.ModalService.close('resource-pricelist');
    this.initialCount=null; // Needed for first added pricelist; shows table controls...
    this.getData(true);
  }
  pricelistCancelled() {
    this.ModalService.close('resource-pricelist');
  }

  copy(id: number) {
    this.ResourcePricelistsService.getOne(this.language, id).subscribe(
        (data)=>this.pricelist=data.data as ResourcePricelist,
        (error)=>console.error(error),
        ()=>this.ModalService.open(this.copyModal,'copy-pricelist')
    )
  }
  copySaved() {
    this.ModalService.close('copy-pricelist');
    this.initialCount=null; // Needed for first added pricelist; shows table controls...
    this.getData(true);
  }
  copyCancelled() {
    this.ModalService.close('copy-pricelist');
  }

  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Refresh view
    this.getData(true);
  }
  delete(id) {
    this.ResourcePricelistsService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData();
        }
    )
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
