<header *ngIf="userRights.AllowCreate" class="module subheader">
    <jbm-create-button [visible]="userRights.AllowCreate" (click)="startCreate()"></jbm-create-button>
    <div class="float-end">
        <jbm-searchbox
          [search]="queryHttpHelper.getSearchValue()"
          [minlength]="3"
          (searchChange)="searchChange($event)">
        </jbm-searchbox>
        <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
          [pageSize]="queryHttpHelper.paginationProps.pageSize"
          [page]="queryHttpHelper.paginationProps.page"
          (pageClick)="pageChange($event)">
        </jbm-pagination>
    </div>
</header>
<div class="table-responsive">
    <table class="table padding table-sm" [ngClass]="{'table-row-selectable table-hover': userRights.AllowUpdate}">
        <thead>
        <tr>
            <th [hidden]="true"></th>
            <th jbm-table-column class="code" [name]="'code'" [header]="'entity.code'|translate" width="16" [sortable]="false"></th>
            <th jbm-table-column class="description" [name]="'description'" [header]="'entity.description'|translate"
                [sortable]="true" [sorted]="true" [sortdirection]="'+'" (sortChange)="sortChange($event)"></th>
            <th jbm-table-column class="absorption" [name]="'absorption'" [header]="'resource.absorption'|translate" width="6" [sortable]="false"></th>
            <th class="actions editable"></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="createActive" [ngClass]="{ 'has-error': (codeTouched && codeError!='') || (descriptionTouched && descriptionError!='') || (absorptionTouched && absorptionError!='') }">
            <th [hidden]="true"></th>
            <td>
               <input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="code"
                 (change)="onChangeCode()" [ngClass]="{'ng-valid':validateCode() && codeUnique, 'is-invalid':this.codeTouched && !validateCode() || !codeUnique }">
               <div *ngIf="codeError" class="invalid-feedback">{{codeError}}</div>
            </td>
            <td>
               <input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="description"
                 (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && descriptionUnique, 'is-invalid':this.descriptionTouched && !validateDescription() || !descriptionUnique }">
               <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
            </td>
            <td>
               <input float-only-input class="form-control form-control-sm" type="text" autocomplete="off" [(ngModel)]="absorption"
                 (change)="onChangeAbsorption()" [ngClass]="{'ng-valid':validateAbsorption(), 'is-invalid':this.absorptionTouched && !validateAbsorption() }">
               <div *ngIf="absorptionError" class="invalid-feedback">{{absorptionError}}</div>
            </td>
            <td class="editable actions">
               <button type="button" class="btn btn-sm btn-primary mr-1" [disabled]="!validateCode() || !validateDescription() || !validateAbsorption()" (click)="createItem()">{{'ui.save'|translate}}</button>
               <button type="button" class="btn btn-sm btn-secondary" (click)="createCancel()">{{'ui.cancel'|translate}}</button>
            </td>
        </tr>
        <tr *ngFor="let item of items" (click)="startEditing(item.id)" [ngClass]="{'has-error': item.editing && ((codeTouched && codeError!='') || (descriptionTouched && descriptionError!='') || (absorptionTouched && absorptionError!=''))}">
            <td [hidden]="true"></td>
            <td *ngIf="!item.editing">{{item.code}}</td>
            <td *ngIf="item.editing" class="editable">
                <input class="form-control form-control-sm" type="text" autocomplete="off"
                  [(ngModel)]="code" [placeholder]="item.code" (change)="onChangeCode()" [ngClass]="{'ng-valid':validateCode() && codeUnique, 'is-invalid':!validateCode() || !codeUnique }">
                <div *ngIf="codeError" class="invalid-feedback">{{codeError}}</div>
            </td>
            <td *ngIf="!item.editing">{{item.description}}</td>
            <td *ngIf="item.editing" class="editable">
                <input class="form-control form-control-sm" type="text" autocomplete="off"
                  [(ngModel)]="description" [placeholder]="item.description" (change)="onChangeDescription()" [ngClass]="{'ng-valid':validateDescription() && descriptionUnique, 'is-invalid':!validateDescription() || !descriptionUnique }">
                <div *ngIf="descriptionError" class="invalid-feedback">{{descriptionError}}</div>
            </td>
            <td *ngIf="!item.editing">{{item.absorptionStr}}</td>
            <td *ngIf="item.editing" class="editable">
                <input float-only-input class="form-control form-control-sm" type="text" autocomplete="off"
                  [(ngModel)]="absorption" [placeholder]="item.absorption" (change)="onChangeAbsorption()" [ngClass]="{'ng-valid':validateAbsorption(), 'is-invalid':!validateAbsorption() }">
                <div *ngIf="absorptionError" class="invalid-feedback">{{absorptionError}}</div>
            </td>
            <td *ngIf="!item.editing" class="actions">
                <button *ngIf="userRights.AllowUpdate" class="btn btn-primary btn-sm float-end mr-2"><i class="far fa-edit"></i></button>
            </td>
            <td *ngIf="item.editing" class="editable actions">
                <button type="button" class="btn btn-sm btn-primary mr-1"
                  [disabled]="!validateCode() || !validateDescription() || !validateAbsorption()" (click)="updateItem()">{{'ui.save'|translate}}</button>
                <button type="button" class="btn btn-sm btn-secondary" (click)="cancelEditing()">{{'ui.cancel'|translate}}</button>
            </td>
        </tr>
        </tbody>
    </table>
    <jbm-empty-view [emptyView]="queryHttpHelper.paginationProps.itemCount==0"></jbm-empty-view>
</div>
