import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {TokenService} from "../../../services/auth/token.service";
import {PlaceholdersService} from "../data/placeholders.service";
import {Presentation} from "../data/interfaces/presentation";
import {MailTemplatesService} from "../data/mail_templates.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {MailTemplate} from "../data/interfaces/mail_template";
import {LocalStorageService} from "../../../services/storage/localstorage.service";

const localStorageKeys = { primary: 'mail_editor_primary_size', secondary: 'mail_editor_secondary_size' }

@Component({
  selector: 'pdf-template-editor',
  templateUrl: './mail-template-editor.component.html',
  styleUrls: ['./mail-template-editor.component.scss']
})
export class MailTemplateEditorComponent implements OnInit {
  presentation: Presentation;
  mailTemplate: MailTemplate=this.MailTemplatesService.getEmpty();
  language: string;
  showPreview: boolean=false;
  panel_primary_width: number=40;
  panel_secondary_width: number=60;
  placeholders: any;
  allocation: number=0;
  pickImages: boolean=true;
  showPlaceholdersPicker: boolean=false;
  HTMLHead : string=this.MailTemplatesService.getHTMLHead();
  HTMLClose: string=this.MailTemplatesService.getHTMLClose();
  HTML: string='';
  base64: string='';
  HTMLMessageStr: string='';
  TextMessageStr: string='';
  subjectStr: string='';

  editorHTMLOptions: any= { lineNumbers: true, theme: 'default', mode: {name: "htmlmixed"} }
  editorTextOptions: any= { lineNumbers: true, theme: 'default', mode: {name: "simplemode"} }
  activeEditorSection: number=1;

  @ViewChild('editorSubject') private editorSubject;
  @ViewChild('editorHTMLMessage') private editorHTMLMessage;
  @ViewChild('editorTextMessage') private editorTextMessage;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private MailTemplatesService: MailTemplatesService,
      private PlaceholdersService: PlaceholdersService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private LocalStorageService: LocalStorageService,
      private JBMToastsService: JBMToastsService,
  ) {
    this.presentation=this.ActivatedRoute.snapshot.data.presentation.data;
  }

  ngOnInit(): void {
    this.language=this.TokenService.getLanguage();
    this.MailTemplatesService.getOne(this.language, this.presentation.id).subscribe(
        (data)=>this.mailTemplate=data.data as MailTemplate,
        (error)=>console.error(error),
        ()=>{
          this.mailTemplate.subject=this.mailTemplate.subject.replace('\"','"');
          this.mailTemplate.subject=this.mailTemplate.subject.replace('\\"','');
          this.subjectStr=this.mailTemplate.subject;
          this.mailTemplate.html_body=this.mailTemplate.html_body.replace('\"','"');
          this.mailTemplate.html_body=this.mailTemplate.html_body.replace('\\','');
          this.HTML=this.mailTemplate.html_body;
          this.HTMLMessageStr=this.HTML;
          this.mailTemplate.text_body=this.mailTemplate.text_body.replace('\"','"');
          this.mailTemplate.text_body=this.mailTemplate.text_body.replace('\\"','"');
          this.TextMessageStr=this.mailTemplate.text_body;
          this.createEMail();
          this.initPanelSizes();
        }
    )
  }
  initPanelSizes() {
    if(this.LocalStorageService.hasKey( localStorageKeys.primary ))
      this.panel_primary_width=this.LocalStorageService.get( localStorageKeys.primary );
    if(this.LocalStorageService.hasKey( localStorageKeys.secondary ))
      this.panel_secondary_width=this.LocalStorageService.get( localStorageKeys.secondary );
  }
  splitterDragEnd(unit: string, event) {
    // Store new panel sizes in localstorage
    this.LocalStorageService.set( localStorageKeys.primary, event.sizes[0]);
    this.LocalStorageService.set( localStorageKeys.secondary, event.sizes[1]);
  }
  togglePreview() {
    if(this.activeEditorSection!==2)
      return;
    this.showPreview=!this.showPreview;
  }
  editorSectionChange(event) {
    this.showPlaceholdersPicker=false;
    this.activeEditorSection=-1;
    if(event.nextState) {
      // Will be opened
      this.activeEditorSection = parseInt(event.panelId.replace('panel-', ''));
      this.showPreview=this.activeEditorSection===2; // Preview only for HTML mails
      this.pickImages=this.activeEditorSection===2; // Images only in HTML mails
    }
  }
  onEditorChange(event, section) {
    if(section==='html-message') {
      this.HTML=event;
      this.createEMail();
    }
  }

  // Placeholders
  insertPlaceholders(activeEditorSection: number) {
    switch(activeEditorSection) {
      case 1:
        this.allocation=6;
        break;
      case 2:
        this.allocation=2;
        break;
      case 3:
        this.allocation=2;
        break;
    }
    this.getPlaceholders();
  }
  getPlaceholders() {
    this.PlaceholdersService.get(this.language, this.presentation.presentation_type, this.presentation.output_type, this.allocation).subscribe(
        (data)=>this.placeholders=data.data,
        (error)=>console.error(error),
        ()=>this.showPlaceholdersPicker=true
    );
  }
  onDataPlacement(placeholder) {
    this.insertPlaceholder(placeholder);
  }
  onTextPlacement(placeholder) {
    this.insertPlaceholder(placeholder);
  }
  onImagePlacement(image: any) {
    this.insertPlaceholder('image'+image.id.toString(),false);
    this.checkTemplateImage(image);
  }
  insertPlaceholder( placeholder: string, includeDelimiters: boolean=true ) {
    let delimiters=this.PlaceholdersService.getDelimiters();
    let editor=null;
    switch(this.activeEditorSection) {
      case 1: // Subject
        editor=this.editorSubject;
        break;
      case 2: // HTML Message
        editor=this.editorHTMLMessage;
        break;
      case 3: // Text Message
        editor=this.editorTextMessage;
        break;
    }
    if(editor===null) return;
    let doc=editor.codeMirror.doc;
    if(includeDelimiters)
      doc.replaceSelection(delimiters.left+placeholder+delimiters.right);
    else
      doc.replaceSelection(placeholder);
    editor.codeMirror.focus();
  }
  checkTemplateImage(image: any) {
    let placeholder='image'+image.id.toString();
/*    if(!this.template.hasOwnProperty('images'))
      this.template.images={};
    if(!this.template.images.hasOwnProperty(placeholder))
      this.template.images[placeholder]='data:image/jpeg;base64,'+image.content;

    console.log(this.template);
*/
    this.createEMail();
  }
  closePlaceholderPicker() {
    this.showPlaceholdersPicker=false;
  }
  createEMail() {
    let HTML=this.HTMLHead+this.HTML+this.HTMLClose;
    this.base64=btoa(HTML);
  }

  save() {
    this.mailTemplate.subject=this.subjectStr;
    this.mailTemplate.html_body=this.HTMLMessageStr;
    this.mailTemplate.text_body=this.TextMessageStr;
    this.MailTemplatesService.update(this.mailTemplate).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=>this.afterSave()
    )
  }

  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.redirect();
  }
  cancel() {
    this.redirect();
  }
  redirect() {
    this.Router.navigateByUrl('settings/presentations');
  }
}
