import {Component, HostListener} from '@angular/core';
import {KeyboardShortcutsService} from "../../services/interface/keyboard-shortcuts/keyboard-shortcuts.service";

@Component({
  selector: 'app-mainlayout',
  templateUrl: './mainlayout.component.html',
  styleUrls: ['./mainlayout.component.scss']
})
export class MainlayoutComponent {
  menu_header: string;
  menu_iconClass: string;
  menu_items: any[];

  constructor(private KeyboardShortcutsService: KeyboardShortcutsService) { }

  showSidebarMenu(event) {
    this.menu_header=event.header;
    this.menu_iconClass=event.iconClass;
    this.menu_items=event.menuitems;
  }

  @HostListener('window:keydown', ['$event'])
  handleShortcut(event: KeyboardEvent) {
    this.KeyboardShortcutsService.handleShortCut(event);
  }
}
