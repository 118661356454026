<form [formGroup]="form" (ngSubmit)="save()" class="presentation">
   <div class="jbm-modal-header">
      <jbm-crud-title *ngIf="presentation.id" [subject]="'presentations.template-properties'" [action]="2"></jbm-crud-title>
      <jbm-crud-title *ngIf="!presentation.id" [subject]="'presentations.template'" [action]="0"></jbm-crud-title>
      <div class="toolbar float-end">
         <jbm-activity-log-button *ngIf="presentation.id" [subject]="'entity.presentation'|translate"
           [title]="presentation.description" [subject_type]="28" [subject_id]="presentation.id">
         </jbm-activity-log-button>
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <jbm-select [formControl]="formControls['presentation_type']" [options]="presentationTypes" [required]="true"
         [submitted]="submitted" [editable]="presentation.id==0"
         class="lg" labelClass="lg" labelCaption="{{'entity.presentationtype'|translate}}" [id]="'presentation_type'"
         insertLeadingOption="true" (onChange)="typeChange($event)">
         <jbm-input-error [formControl]="formControls['presentation_type']" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-select>
      <jbm-radio-group [formControl]="formControls['output_type']" name="output_type" [options]="outputTypes" [required]="true"
        [submitted]="submitted" [editable]="presentation.id==0"
        class="lg" labelClass="lg" [labelCaption]="('presentations.output'|translate)+('entity.type'|translate|lowercase)">
         <jbm-input-error [formControl]="formControls['output_type']" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-radio-group>
      <jbm-radio-group [hidden]="presentation.presentation_type!=2" [formControl]="formControls['usage']" name="usage" [options]="presentationUsages" [required]="true"
        [submitted]="submitted" [editable]="presentation.id==0"
        class="lg" labelClass="lg" [labelCaption]="('ui.usage'|translate)">
         <jbm-input-error [formControl]="formControls['usage']" [submitted]="submitted">
            {{'entity.requiredfield'|translate}}
         </jbm-input-error>
      </jbm-radio-group>
      <jbm-check [hidden]="presentation.presentation_type!=2" [editable]="presentation.id==0" [formControl]="formControls['shifted_vat']" [id]="'shifted_vat'" [submitted]="submitted"
        labelCaption="{{'customer.shift-vat-setting'|translate}}"
        labelClass="lg" class="lg mt-2" [textChecked]="'ui.yes'|translate" [textUnchecked]="'ui.no'|translate">
      </jbm-check>
      <jbm-text-input [id]="'description'" [formControl]="formControls['description']" [required]="true" [submitted]="submitted"
        [labelCaption]="'entity.description'|translate" class="lg" [labelClass]="'lg'" [class]="'lg mt-3'">
         <jbm-input-error [formControl]="formControls['description']" [submitted]="submitted">
            <span *ngIf="formControls.description.hasError('notUnique')">{{'entity.description-not-unique'|translate}}</span>
            <span *ngIf="formControls.description.hasError('required')">{{'entity.requiredfield'|translate}}</span>
         </jbm-input-error>
      </jbm-text-input>
      <jbm-check [editable]="!isAlwaysDefault" [formControl]="formControls.is_default" [id]="'is_default'" [submitted]="submitted"
        labelCaption="{{'entity.default'|translate}} {{'entity.presentation'|translate|lowercase}} {{'presentations.template'|translate|lowercase}}"
        labelClass="lg" class="lg mt-4" [textChecked]="'ui.yes'|translate" [textUnchecked]="'ui.no'|translate">
      </jbm-check>
   </div>
   <div class="jbm-modal-footer">
      <div class="toolbar float-end">
         <jbm-save-button *ngIf="presentation.id" [subject]="'presentations.template-properties'|translate" (click)="save()"></jbm-save-button>
         <jbm-save-button *ngIf="!presentation.id" [subject]="'presentations.template'|translate" (click)="save()"></jbm-save-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cancel-button (click)="cancel()"></jbm-cancel-button>
      </div>
   </div>
</form>
