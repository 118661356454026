import {Injectable } from '@angular/core';
import {HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {InvoiceFlow} from "./interfaces/invoice-flow";

const baseURL: string = 'invoicing/';
const segment: string = 'state-flow/';

@Injectable({
  providedIn: 'root'
})
export class InvoiceFlowsService {
  invoice_flow: InvoiceFlow;

  constructor(private HttpService: HttpService) {}

  get(language: string, id: number, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+segment+language+'/'+id.toString(), params);
  }
  getEmpty() {
    this.invoice_flow = {
      id: 0,
      invoices_id: 0,
      invoice_states_id: 0,
      date: '',
      time: '',
      comments: ''
    }
    return this.invoice_flow;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create/'+segment, data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update/'+segment, data);
  }
}
