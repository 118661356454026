import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {EMPTY, Observable, throwError} from "rxjs";
import {HelpTopicsService} from "../helptopics.service";
import {TokenService} from "../../../../services/auth/token.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";
import {HelpTopic} from "../interfaces/helptopic";

@Injectable({
  providedIn: 'root'
})
export class HelpTopicResolver implements Resolve<any> {
  language: string=this.TokenService.getLanguage();

  constructor(
      private Router: Router,
      private HelpTopicsService: HelpTopicsService,
      private TokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<HelpTopic> {
    let id=parseInt(route.paramMap.get('id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      let language=this.TokenService.getLanguage();
      let params=new HttpParams().append('id',id.toString());
      return this.HelpTopicsService.get(language, params).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
