import { Injectable } from '@angular/core';
import { User } from './user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { HttpService } from "../http.service";
import { LocalStorageService } from "../storage/localstorage.service";

@Injectable({ providedIn: 'root' })
export class AuthService {

  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    public router: Router,
    private HttpService: HttpService,
    private LocalStorageService: LocalStorageService )
  {}

  logIn(user: User) {
    return this.HttpService._postResponseText('users/login', user );
  }
  logOut() {
    this.LocalStorageService.remove('token');
    if(!this.getIsLoggedIn())
      this.router.navigateByUrl('login');
  }
  setToken(token:string) {
    this.LocalStorageService.set('token', token);
  }
  getToken() {
    return this.LocalStorageService.get('token');
  }
  getIsLoggedIn(): boolean {
    let authToken = this.LocalStorageService.get('token');
    return (authToken !== null);
  }
}
