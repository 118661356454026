import {Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {TokenService} from "../../../services/auth/token.service";
import {ProductionReceiptsService} from "../../production/data/production-receipts.service";
import {InvoicesService} from "../data/invoices.service";
import {CustomerService} from "../../customers/data/customer.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {DatetimeService} from "../../../services/datetime/datetime.service";
import {PlantsService} from "../../company/data/plants.service";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {Subscription} from "rxjs";
import {HttpParams} from "@angular/common/http";
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerKeyboardService} from '@ng-bootstrap/ng-bootstrap';
import {CustomDatepickerI18n} from '../../../services/NgbDatepicker/custom-datepicker-i18n.service';
import {CustomAdapter} from '../../../services/NgbDatepicker/custom-adapter.service';
import {CustomDateParserFormatter} from '../../../services/NgbDatepicker/custom-dateparser-formatter.service';
import {CustomKeyboardService} from '../../../services/NgbDatepicker/custom-keyboard-service';

@Component({
  selector: 'invoiceable-receipts',
  templateUrl: './invoiceable-receipts.component.html',
  styleUrls: ['./invoiceable-receipts.component.scss'],
  providers: [
      {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}, // define custom NgbDatepickerI18n provider
      {provide: NgbDateAdapter, useClass: CustomAdapter},
      {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
      {provide: NgbDatepickerKeyboardService, useClass: CustomKeyboardService}
  ]
})
export class InvoiceableReceiptsComponent implements OnInit, OnDestroy {
  language: string;
  projects: any[]=[];
  receipts: any[]=[];
  receipt_ids: number[]=[];
  plantOptions: JBMSelectOption[]=[];
  project_id: number;
  plants_id: number=0;
  customer: any;
  loading: boolean=false;
  expanded: number=-1;
  itemCount: number=0;
  delivery_date: string='';
  recipe_id: number=0;
  order_id: number=0;
  environment: string='';

  date: string=this.DatetimeService.getCurrentDateDMY();
  dateTouched: boolean=false;

  count$: Subscription;

  @Output() onStartLoading=new EventEmitter();
  @Output() onStopLoading=new EventEmitter();

  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;
  @ViewChild('modalProjectDetails') modalProjectDetails: ElementRef;
  @ViewChild('modalRecipe') modalRecipe: ElementRef;
  @ViewChild('modalOrder') modalOrder: ElementRef;

  constructor(
      private Router: Router,
      private TokenService: TokenService,
      private ProductionReceiptsService: ProductionReceiptsService,
      private InvoicesService: InvoicesService,
      private CustomerService: CustomerService,
      private PlantsService: PlantsService,
      private TranslateService: TranslateService,
      private ModalService: ModalService,
      private DatetimeService: DatetimeService,
      private JBMToastsService: JBMToastsService
  ) {
    this.language=this.TokenService.getLanguage();
  }

  ngOnInit(): void {
    let plants=[];
    this.PlantsService.getData(null).subscribe(
        (data)=>plants=data.data,(error)=>console.error(error),()=>{
          if(plants.length>1) {
            this.plantOptions = this.PlantsService.getSelectOptions(plants);
            this.plantOptions.unshift({ key: '0', value: this.TranslateService.GetTranslation('ui.all')})
          }
          this.getData(true);
        }
    );
  }
  getData(countRows:boolean=false) {
    this.loading = true;
    this.receipt_ids=[];
    this.onStartLoading.emit();

    let params=new HttpParams();
    if(this.plants_id) params=params.append('plants_id', this.plants_id.toString());
    this.ProductionReceiptsService.getInvoiceableProjects(this.language, params).subscribe(
        (data)=>this.projects=data.data,
        (error)=>console.error(error),
        ()=> {
          this.loading=false;
          this.onStopLoading.emit();
          if(countRows) {
            this.count$=this.ProductionReceiptsService.getInvoiceableReceiptsCount(params).subscribe(
                (data: any)=>this.itemCount=data.data,(error)=>console.error(error) );
          }
        }
    )
  }
  changePlant(event) {
      this.plants_id=parseInt(event.target.value);
      this.getData(true);
  }
  toggleReceipts(project_id: number) {
      this.expanded=this.expanded===project_id ? this.expanded=-1 : this.expanded=project_id;
      if(this.expanded>-1)
          this.getReceiptsData(this.expanded);
  }
  getReceiptsData(project_id: number) {
      this.loading = true;
      this.receipt_ids=[];

      let params=new HttpParams().append('receipt','1');
      this.ProductionReceiptsService.getInvoiceableReceipts(this.language, project_id, params).subscribe(
          (data)=>this.receipts=data.data,
          (error)=>console.error(error),
          ()=> {
              this.loading=false;
              this.formatReceipts();
          }
      )
  }
  formatReceipts() {
      for(let receipt of this.receipts )
          receipt.receipt_amount=receipt.receipt_amount.toString() + ' ' + this.TranslateService.GetTranslation('quantity.m3');
  }

  // Customer
  showCustomer(customer_id: number) {
      this.CustomerService.get(customer_id,true).subscribe(
          (data)=>this.customer=data.data,
          (error)=>console.error(error),
          ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
      );
  }
  closeCustomer() {
      this.ModalService.close('customerDetails');
  }

  onChangeDate() {
    this.dateTouched=true;
  }
  validateDate() {
      if(this.date===null) return true;
      this.date=this.date.trim();
      return !this.date || this.DatetimeService.validateDateStr(this.date);
  }

  generateInvoice(project_id: number, receipt_id: number=0) {
      let invoice_date=this.DatetimeService.dateDMYToYMD(this.date);
      this.InvoicesService.createFromReceipts( this.language, project_id, receipt_id.toString(), invoice_date).subscribe(
          ()=>{},(error)=>console.error(error),()=> {
              this.getData(true);
              this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
          }
      )
  }
  generateReceiptsInvoice(project_id: number) {
      let invoice_date=this.DatetimeService.dateDMYToYMD(this.date);
      let receipt_ids=this.receipt_ids.join();
      this.InvoicesService.createFromReceipts( this.language, project_id, receipt_ids, invoice_date).subscribe(
          ()=>{},(error)=>console.error(error), ()=> {
              this.toggleReceipts(project_id);
              this.getData(true);
              this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
          }
      )
  }
  toggleProject() {
      let toggledReceipts=this.receipt_ids.length;
      this.receipt_ids = [];
      // Reset ?
      if(toggledReceipts===this.receipts.length)
          return;
      // Select all
      for(let receipt of this.receipts)
          this.receipt_ids.push(receipt.id);
  }
  toggleReceipt(id: number) {
      let receipt_index=this.receipt_ids.indexOf(id);
      if(receipt_index===-1)
          this.receipt_ids.push(id);
      else
          this.receipt_ids=this.receipt_ids.splice(receipt_index, 1);
  }
  // Project
  showProject(project_id: number) {
    this.project_id=project_id;
    this.ModalService.open(this.modalProjectDetails,'projectDetails')
  }
  closeProject() {
    this.ModalService.close('projectDetails');
  }
  showRecipe(recipe_id: number, delivery_date: string, environment: string) {
    this.recipe_id=recipe_id;
    this.delivery_date=delivery_date;
    this.environment=environment;
    this.ModalService.open(this.modalRecipe,'recipe');
  }
  closeRecipe() {
    this.ModalService.close('recipe');
  }
  // Order
  showOrder(order_id: number) {
      this.order_id=order_id;
      this.ModalService.open(this.modalOrder,'order')
  }
  closeOrder() {
      this.ModalService.close('order');
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
