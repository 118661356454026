import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {QuotationsService} from "../data/quotations.service";
import {
  JBMTableActionDef, JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType
} from "../../../components/JBM/JBMTable/JBMTable.component";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {HttpParams} from '@angular/common/http';

@Component({
  selector: 'quotation-revisions',
  templateUrl: './quotation-revisions.component.html',
  styleUrls: ['./quotation-revisions.component.scss']
})
export class QuotationRevisionsComponent implements OnInit {
  revisions: any[]=[];
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[]=[];

  constructor(
      private Router: Router,
      private QuotationsService: QuotationsService,
      private TranslateService: TranslateService
  ) { }

  @Input() quotation_id: number=0;
  @Input() revision_number: number=0;

  @ViewChild('templateAmount') templateAmount: TemplateRef<any>;
  @ViewChild('templatePreview') templatePreview: TemplateRef<any>;

  ngOnInit(): void {
    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id, tag: 'id' },
      { name: 'quotation_number', type: JBMTableColumnType.string, header: this.TranslateService.GetTranslation('quotation.number-short'), visible: true, width: 7 },
      { name: 'quotation_date', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('quotation.date'), hideable: true, width: 7 },
      { name: 'due_date', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('entity.due-date'), width: 7 },
      { name: 'concrete_total', type: JBMTableColumnType.price, visible: true, header: this.TranslateService.GetTranslation('ui.totalprice'), width: 7.5 },
      { name: 'concrete_amount', type: JBMTableColumnType.number, visible: true, header: this.TranslateService.GetTranslation('order.amount'), width: 5.5 },
      { name: 'preview', type: JBMTableColumnType.string, visible: true, header: this.TranslateService.GetTranslation('ui.preview'), width: 4 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, hideable: false, width: 3.5 }
    ];

    let params=new HttpParams()
      .append('quotation_number','1')
      .append('exclude_revision_number', this.revision_number.toString());
    this.QuotationsService.getRevisions(this.quotation_id, this.revision_number, params).subscribe(
        (data)=> {
          this.columnDefs[5].template=this.templateAmount;
          this.columnDefs[6].template=this.templatePreview;
          this.revisions=data.data;
        },
        (error)=>console.error(error)
    )
  }
  rowSelect(row) {
    this.Router.navigateByUrl('quotations/'+row.id);
  }
}
