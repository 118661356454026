<header class="module subheader">
    <jbm-view-title [caption]="caption" [size]="'sm'"></jbm-view-title>
    <div class="btn-group float-end">
        <jbm-filter-toggle [showFilter]="filtersVisible" (onToggle)="toggleFilters($event)"></jbm-filter-toggle>
    </div>
    <div class="header-spacer-2 float-end"></div>
    <jbm-searchbox [id]="'resource-search'" [visible]="searchboxVisible && !filtersVisible" [search]="search" (searchChange)="searchChange($event)"></jbm-searchbox>
    <div class="header-spacer-2 float-end"></div>
    <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page"
        (pageClick)="pageChange($event)">
    </jbm-pagination>
    <div class="header-spacer-2 float-end"></div>
    <jbm-spinner [loading]="loading"></jbm-spinner>
</header>
<jbm-table [classname]="'articles'"
           [data]="items"
           [filterable]="true"
           [showFilters]="filtersVisible"
           [sortable]="true"
           [sortColumn]="queryHttpHelper.sortProps.name"
           [sortDirection]="queryHttpHelper.sortProps.direction"
           [hoverable]="true"
           [rowSelectable]="true"
           [columnDefs]="columnDefs"
           (sortChange)="sortChange($event)"
           (filterChange)="filterChange($event)"
           (rowSelect)="rowSelect($event)">
</jbm-table>
<jbm-toasts></jbm-toasts>