import { Injectable } from '@angular/core';
import { HttpService} from "../../../services/http.service";
import {HttpParams} from "@angular/common/http";
import {User} from "./interfaces/user";

const baseUrl='users/';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User;

  constructor(private HttpService: HttpService) { }

  create(data: any) {
    return this.HttpService.post(baseUrl+'create', data);
  }
  update(data: any) {
    return this.HttpService.post(baseUrl+'update', data);
  }
  getData(params: HttpParams) {
    return this.HttpService.get(baseUrl+'read', params);
  }
  getOne(userid: number=0) {
    let params=new HttpParams();
    params=params.append('id', userid.toString());
    return this.HttpService.get( baseUrl+'read', params );
  }
  checkPassword(userid: number, password: string ) {
    let params=new HttpParams();
    params=params.append('id', userid.toString());
    params=params.append('pw', btoa(password));
    return this.HttpService.get( baseUrl+'read/checkpw', params );
  }
  getEmpty() {
    this.user= { id: 0, username: '', full_name: '', password: '', language_choice: '', active: true }
    return this.user;
  }
}
