import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: '[float-only-input]'
})
export class FloatOnlyInputDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    let initalValue = this._el.nativeElement.value;
    if(this._el.nativeElement.classList.contains('negative'))
      this._el.nativeElement.value=initalValue.replace(/[^0-9.,-]*/g, '');
    else
      this._el.nativeElement.value=initalValue.replace(/[^0-9.,]*/g, '');
    if(initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
  @HostListener('window:keyup', ['$event']) keyUpEvent(event: KeyboardEvent) {
    if([',','.'].indexOf(event.key)===-1)
      return;

    if(event.key===',') this._el.nativeElement.value=this._el.nativeElement.value.replace('.','');
    if(event.key==='.') this._el.nativeElement.value=this._el.nativeElement.value.replace(',','');

    // Only one comma/point in float allowed, between the first and last digits
    if(this._el.nativeElement.value.indexOf(event.key)===0)
      this._el.nativeElement.value=this._el.nativeElement.value.replace(event.key,'');
    let count=(this._el.nativeElement.value.match(/,/g) || []).length;
    if(count!==null && count>1)
      this._el.nativeElement.value=this._el.nativeElement.value.replace(event.key,'');
  }
}
