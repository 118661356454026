import {Injectable } from '@angular/core';
import {HttpParams } from "@angular/common/http";
import {HttpService} from "../../../services/http.service";
import {Quotation} from "./interfaces/quotation";

const baseURL: string = 'quotations/';

export enum usages  { concept, quotation, confirmation}

@Injectable({
  providedIn: 'root'
})
export class QuotationsService {
  quotation: Quotation;

  constructor(
      private HttpService: HttpService,
  ) {}
  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+language, params );
  }
  getOne(quotation_id: number, revision_number: number=0, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/one/'+quotation_id.toString()+'/'+revision_number.toString(), params );
  }
  getByRevision(quotation_revision_id: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/revision/'+quotation_revision_id.toString(), params );
  }
  getRevisions(quotation_id: number, revision_number: number, params: HttpParams=null) {
    return this.HttpService.get(baseURL+'read/revisions/'+quotation_id.toString()+'/'+revision_number.toString(), params);
  }
  getEmpty() {
    this.quotation = {
      id: 0,
      customers_id: 0,
      projects_id: null,
      quotation_number: '',
      presentations_id: 0,
      quotation_states_id: 100,
      vatrate: 0,
      quotation_revisions_id: null,
      revision_id: 0,
      revision_number: 0,
      customer_comments: null,
      quotation_date: null,
      validity_period: 0,
      due_date: null,
      totalprice: null,
      concrete_amount: null,
      concrete_total: null,
    }
    return this.quotation;
  }
  create(data: any) {
    return this.HttpService.post(baseURL+'create', data);
  }
  createRevision(data: any) {
    return this.HttpService.post(baseURL+'create/revision', data);
  }
  updateRevision(data: any) {
    return this.HttpService.post(baseURL+'update/revision', data);
  }
  update(data: any) {
    return this.HttpService.post(baseURL+'update', data);
  }
  draft(data: any) {
    return this.HttpService.post(baseURL+'update/draft', data);
  }
  approve(data: any) {
    return this.HttpService.post(baseURL+'update/approve', data);
  }
  send(data: any) {
    return this.HttpService.post(baseURL+'update/send', data);
  }
  negotiate(data: any) {
    return this.HttpService.post(baseURL+'update/negotiate', data);
  }
  accept(data: any) {
    return this.HttpService.post(baseURL+'update/accept', data);
  }
  reject(data: any) {
    return this.HttpService.post(baseURL+'update/reject', data);
  }
  cancel(data: any) {
    return this.HttpService.post(baseURL+'update/cancel/', data);
  }
  compose_pdf( language: string, quotation_revision_id: number, usage: number=usages.quotation ) {
    return this.HttpService.get(baseURL+'read/compose-pdf/'+language+'/'+quotation_revision_id.toString()+'/'+usage.toString());
  }
  compose_mail( language: string, quotation_revision_id: number) {
    return this.HttpService.get(baseURL+'read/compose-mail/'+language+'/'+quotation_revision_id.toString());
  }
  mail( language: string, quotation_revision_id: number, mail_settings_id: number=0) {
    return this.HttpService.get(baseURL+'read/mail/'+quotation_revision_id.toString()+'/'+mail_settings_id.toString()+'/'+language);
  }
  remove(quotation_id: number ) {
    return this.HttpService.get(baseURL+'delete/'+quotation_id.toString());
  }
}
