<app-header>
   <jbm-view-title [caption]="'entity.email-attachments'|translate"
     [subCaption]="presentation.description"
     [parentLinkCaption]="'menu.presentations'|translate"
     [parentLinkSlug]="'settings/presentations'">
   </jbm-view-title>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div class="toolbar-spacer-1 float-end"></div>
      <jbm-pagination [itemCount]="queryHttpHelper.paginationProps.itemCount"
        [pageSize]="queryHttpHelper.paginationProps.pageSize"
        [page]="queryHttpHelper.paginationProps.page" (pageClick)="pageChange($event)">
      </jbm-pagination>
      <jbm-searchbox [visible]="queryHttpHelper.paginationProps.itemCount>queryHttpHelper.paginationProps.pageSize" [search]="queryHttpHelper.getSearchValue()"
        [minlength]="3" (searchChange)="searchChange($event)">
      </jbm-searchbox>
      <div class="toolbar-spacer-2 float-end"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module overflow-y attachments">
   <as-split direction="vertical" (dragEnd)="splitterDragEnd('percent', $event)">
      <as-split-area [size]="panel_primary_height" [minSize]="25">
         <jbm-table [classname]="'attachments padding'"
           [data]="attachments"
           [filterable]="false"
           [sortable]="true"
           [rowSelectable]="true"
           [sortColumn]="queryHttpHelper.sortProps.name"
           [sortDirection]="queryHttpHelper.sortProps.direction"
           [hoverable]="true"
           [columnDefs]="columnDefs"
           (sortChange)="sortChange($event)">
         </jbm-table>
      </as-split-area>
      <as-split-area [size]="panel_secondary_height" [minSize]="25">
         <media-files-selector #mediaFileSelector (onSelect)="selectMediaFile($event)"></media-files-selector>
      </as-split-area>
   </as-split>
   <ng-template #actionsTemplate let-data="data">
      <button type="button" class="btn btn-sm btn-light mr-3 float-start" (click)="showPreview(data); $event.stopPropagation()">{{'ui.preview'|translate}}</button>
      <jbm-confirm-delete-dropdown class="float-start"
        [placement]="'left-top'"
        [subject]="('entity.email-attachment'|translate)"
        (onConfirm)="delete(data.mail_attachments_id)">
      </jbm-confirm-delete-dropdown>
   </ng-template>
</section>
<div [hidden]="!preview" class="document-preview">
   <div class="header">
      <div class="title float-start">
         {{'ui.preview'|translate}}: <span class="fw-bold">{{description}}</span>
      </div>
      <jbm-cross-close-button class="float-end" (onClick)="preview=!preview"></jbm-cross-close-button>
   </div>
   <jbm-document-viewer [base64]="base64" [fileExtension]="fileExtension"></jbm-document-viewer>
</div>
