<form [formGroup]="form" (ngSubmit)="save()" class="article_pricelist">
    <div class="jbm-modal-header">
        <jbm-crud-title
          [subject]="'financial.resource-pricelist'"
          [action]="action">
        </jbm-crud-title>
        <div class="toolbar float-end">
            <jbm-activity-log-button *ngIf="pricelist.id" subject_type="33" class="bg-light"
              [subject_id]="pricelist.id" [subject]="('entity.resources'|translate) + ' ' + ('financial.pricelist'|translate|lowercase)"
              [title]="pricelist.description">
            </jbm-activity-log-button>
            <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cross-close-button (onClick)="cancel()"></jbm-cross-close-button>
        </div>
    </div>
    <div class="jbm-modal-body">
        <div *ngIf="customer_id" class="clearfix">
            <jbm-static-label labelClass="width-8" [labelCaption]="'entity.customer'|translate"
              [value]="customer.name">
                <br />
                <button class="btn btn-sm btn-light ml-2" tabindex="-1" (click)="showCustomer();$event.stopPropagation();">
                    {{'entity.customer'|translate}} {{'ui.details'|translate|lowercase}}
                </button>
            </jbm-static-label>
            <hr />
        </div>
        <jbm-select [formControl]="formControls.type" [options]="type_options" [submitted]="isSubmitted"
          [id]="'type'" [editable]="pricelist.id==0" [insertLeadingOption]="true" [required]="true"
          labelCaption="{{'pricelist.type'|translate}}" labelClass="width-8" class="input-25">
            <jbm-input-error [formControl]="formControls.type" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-select>
        <jbm-select *ngIf="plantOptions.length>1" [formControl]="formControls.plants_id" [options]="plantOptions" [submitted]="isSubmitted" class="lg"
          [id]="'plants_id'" [insertLeadingOption]="true" [leadingOptionText]="''"
          labelCaption="{{'company.plant'|translate}}" labelClass="width-8">
        </jbm-select>
        <jbm-text-input [formControl]="formControls.description" [submitted]="isSubmitted" [id]="'description'"
          [maxlength]="125" required="true" labelCaption="{{'entity.description'|translate}}"
          labelClass="width-8" class="mt-3 mb-3 input-25">
            <jbm-input-error [formControl]="formControls.description" [submitted]="isSubmitted">
                {{'entity.requiredfield'|translate}}
            </jbm-input-error>
        </jbm-text-input>
    </div>
    <div class="jbm-modal-footer">
        <div class="toolbar float-end">
            <jbm-save-button [subject]="'financial.resource-pricelist'|translate" (click)="save()"></jbm-save-button>
            <div class="toolbar-spacer-1"></div>
            <jbm-cancel-button context="light" (click)="cancel()"></jbm-cancel-button>
        </div>
    </div>
</form>
<jbm-toasts></jbm-toasts>
<jbm-modal *ngIf="customer_id" [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
    <customer-details [customer]="customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>
