import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'jbm-confirm-delete-dropdown',
  templateUrl: './JBMConfirmDeleteDropDown.component.html',
  styleUrls: ['./JBMConfirmDeleteDropdown.component.scss']
})
export class JBMConfirmDeleteDropdownComponent implements OnInit {

  constructor() { }

  @Input() subject: string='';
  @Input() info: string='';
  @Input() enabled: boolean=true;
  @Input() class: string='';
  @Input() iconClass: string='trash';
  @Input() extraSmall: boolean=false;
  @Input() placement: string='bottom-right';
  @Input() tooltipPlacement: string='bottom';

  @Output() onConfirm=new EventEmitter();
  @Output() onOpenChange=new EventEmitter();

  ngOnInit(): void {}
  confirm() {
    this.onConfirm.emit();
  }
  openChange(opened: boolean) {
    this.onOpenChange.emit(opened);
  }
}
