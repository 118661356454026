<app-header>
   <jbm-view-title [caption]="'menu.reports'|translate" [iconClassname]="'chart-line'"></jbm-view-title>
</app-header>
<section class="module reports padding padding-top bg-light-darker">
   <div class="row ml-0">
      <div class="col-lg-3 pl-0">
         <div class="card mb-3">
            <div class="card-body">
               <h5 class="card-title"><i *ngIf="iconClassnames.production" class="text-muted fa fa-{{iconClassnames.production}}"></i> {{'menu.production'|translate}}</h5>
               <a routerLink="/reports/production">{{'menu.production'|translate}}</a>
               <a routerLink="/reports/resource-usage">{{'entity.resources'|translate}}{{'resource.usage'|translate|lowercase}}</a>
            </div>
         </div>
      </div>
   </div>
</section>
