<jbm-modal [id]="'project-invoice'" [className]="'project-invoice'" (onClose)="close()"></jbm-modal>
<ng-template #modalInvoice let-modal>
<form [formGroup]="form" (ngSubmit)="save()" class="invoice" xmlns="http://www.w3.org/1999/html">
   <div class="jbm-modal-header">
      <div class="jbm-crud-title">
         <div class="title">
            {{'entity.project'|translate}} {{'entity.invoice'|translate|lowercase}} {{'ui.generate'|translate|lowercase}}
         </div>
      </div>
      <div class="toolbar float-end">
         <jbm-help-button [class]="'light'" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="close()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <project-selector [template]="project" [id]="'project'" labelCaption="{{'entity.project'|translate}}"
        [required]="true" [isSubmitted]="submitted"
        [with_customers_only]="true" [labelStacked]="true" [project_id]="formControls.project_id.value"
        (projectSelected)="setProject($event)">
      </project-selector>
      <ng-template #project let-project="project">
         <div *ngIf="project">
            {{project.name}}<br />
            <span *ngIf="project.description">{{project.description}}<br /></span>
            {{'entity.customer'|translate}}: {{customer_name}}
         </div>
      </ng-template>

      <div *ngIf="orders.length" class="table-responsive">
         <h6>{{'invoice.invoiceable'|translate}} {{'project.orders'|translate|lowercase}}</h6>
         <table class="table table-xs table-hover table-row-selectable invoice-orders">
            <thead>
            <tr>
               <th class="check">
                  <input *ngIf="orders.length>1" type="checkbox" (change)="toggleOrders()" [checked]="order_ids.length==orders.length">
               </th>
               <th class="worktype">{{'order.worktype'|translate}}</th>
               <th class="description">{{'entity.description'|translate}}</th>
               <th class="recipe">{{'entity.recipe'|translate}}</th>
               <th class="amount align-right">{{'order.amount'|translate}}</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let order of orders" (click)="toggleOrder(order.id)">
               <td>
                  <input type="checkbox" (click)="toggleOrder(order.id); $event.stopPropagation()"
                    [checked]="order_ids.indexOf(order.id)>-1">
               </td>
               <td>
                  <jbm-link-button [caption]="order.worktype.description" (onClick)="showOrder(order.id)"></jbm-link-button>
               </td>
               <td>
                  <jbm-link-button *ngIf="order.description" [caption]="order.description" (onClick)="showOrder(order.id)"></jbm-link-button>
               </td>
               <td>
                  <jbm-link-button [caption]="order.recipe.code" (onClick)="showRecipe(order)"></jbm-link-button>
               </td>
               <td class="amount align-right">{{order.amount}}</td>
            </tr>
            </tbody>
         </table>
      </div>
      <jbm-textarea [id]="'comments'" [formControl]="formControls.comments" [submitted]="submitted" [rows]="4" labelStacked="true"
        class="mt-3" inputClass="w-100" [labelCaption]="('entity.concept'|translate) + ' ' + ('entity.invoice'|translate|lowercase) + ' ' + ('entity.comments'|translate|lowercase)">
      </jbm-textarea>
      <jbm-check [id]="'openInvoice'" labelCaption="{{'invoice.open-after-create'|translate}}"
        [formControl]="formControls.openInvoice" alignLeft="true"></jbm-check>
   </div>
   <div class="jbm-modal-footer text-right">
      <button type="button" class="btn btn-primary" (click)="save()"><i class="fa fa-check"></i> {{'entity.project'|translate}} {{'entity.invoice'|translate|lowercase}} {{'entity.create'|translate|lowercase}}</button>
      <jbm-cancel-button context="secondary" [small]="false" class="ml-2" (click)="close()"></jbm-cancel-button>
   </div>
</form>
</ng-template>
<jbm-toasts></jbm-toasts>

<jbm-modal [id]="'recipe'" [className]="'invoice-recipe'"></jbm-modal>
<ng-template #modalRecipe let-modal>
   <recipe-details [delivery_date]="" [recipe_id]="recipe_id" [environment]="environment" (onClose)="closeRecipe()"></recipe-details>
</ng-template>

<jbm-modal [id]="'order'" [className]="'order-details'"></jbm-modal>
<ng-template #modalOrder let-modal>
   <order-details [order_id]="order_id" (onClose)="closeOrder()"></order-details>
</ng-template>
