import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {FormGroupBaseComponent} from "../formgroupbase.component";

@Component({
  selector: 'jbm-text-input-group',
  templateUrl: './JBMTextInputGroup.component.html',
  styleUrls: ['./JBMTextInputGroup.component.scss']
})

export class JBMTextInputGroupComponent extends FormGroupBaseComponent implements OnInit {

  @Input() type: string='text';
  @Input() maxlength: number=0;
  @Input() alignRight: boolean=false;
  @Input() prepend: string;
  @Input() append: string;
  @Input() appendTemplate: TemplateRef<any>;

  @Output() onBlur = new EventEmitter();

  ngOnInit() {
    super.ngOnInit();
    if(this.type==undefined) this.type='text';
    if(this.maxlength==undefined) this.maxlength=0;
    if(this.alignRight==undefined) this.alignRight=false;
    if(this.prepend==undefined) this.prepend='';
    if(this.append==undefined) this.append='';
  }
  blur(event) {
    this.onBlur.emit(event);
  }
}
