import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";
import { Address } from "./interfaces/address";

const BaseURL: string = 'addresses/';

@Injectable({
    providedIn: 'root'
})
export class AddressesService {
    address: Address;
    constructor(private HttpService: HttpService) {}

    getData(params: HttpParams) {
        return this.HttpService.get(BaseURL+'read/', params );
    }
    getEmpty() {
        this.address= {
            id: 0,
            city: '',
            street: '',
            postcode: '',
            no: '',
            lat: '',
            lon: ''
        }
        return this.address;
    }
    get(id: number) {
        let params=new HttpParams();
        params=params.append('id',id.toString());
        let url=BaseURL+'read/one';
        return this.HttpService.get(url, params );
    }

    create(data: any) {
        return this.HttpService.post(BaseURL+'create',data);
    }
    update(data: any) {
        return this.HttpService.post(BaseURL+'update', data);
    }
}
