import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Event, NavigationEnd, Router} from '@angular/router';
import {CustomerService} from '../data/customer.service';
import {CustomertypesService} from '../data/customertypes.service';
import {LocalStorageService} from '../../../services/storage/localstorage.service';
import {TranslateService} from '../../../services/multilingual/translate.service';
import {TokenService} from '../../../services/auth/token.service';
import {ModalService} from "../../../components/JBM/JBMModal/service/modal.service";
import {MenuService} from '../../../services/menu/menu.service';
import {StateService} from '../../../services/storage/state.service';
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from '../../../components/JBM/JBMTable/JBMTable.component';
import {QueryHttpHelper, SearchColumnType} from '../../../components/JBM/Helpers/QueryHttpHelper';
import {TablelayoutService} from '../../users/data/tablelayout.service';
import {Subscription} from 'rxjs';
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {ContextType} from "../../../components/JBM/Helpers/Context";

const tableName = 'customers';

@Component({
  selector: 'customers-table',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, OnDestroy {
  userRights: GroupRights;
  customers: any[];
  customer: any;
  customertypes: any[];
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[]=[];

  customers$: Subscription;
  customertypes$: Subscription;
  count$: Subscription;
  timer: any;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible = true;
  search = '';
  filtersVisible = false;
  loading = false;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('blockedtemplate') blockedtemplate: TemplateRef<any>;
  @ViewChild('modalCustomerDetails') modalCustomerDetails: ElementRef;

  constructor(
      private Router: Router,
      public CustomerService: CustomerService,
      public CustomertypeService: CustomertypesService,
      public StateService: StateService,
      private TokenService: TokenService,
      private ModalService: ModalService,
      private MenuService: MenuService,
      private LocalStorageService: LocalStorageService,
      private TranslateService: TranslateService,
      private TablelayoutService: TablelayoutService
  ) {
    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('full_name', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('phone', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('email', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('coc', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('debtor_id', SearchColumnType.string);
  }

  ngOnInit(): void {
    // Refresh data when modal outlet closes
    this.Router.events.subscribe((event: Event) => {
      if(event instanceof NavigationEnd && event.url==='/customers')
        this.getData();
    });

    this.userRights = this.TokenService.getRightsByName('customers');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'name', header: this.TranslateService.GetTranslation('entity.name'),
        hideable: false, sortable: true, filterable: true },
      { name: 'customer_type', type: JBMTableColumnType.string,
        header: this.TranslateService.GetTranslation('customer.type'),
        filterable: true, width: 7.5, align: JBMTableColumnAlignment.left },
      { name: 'debtor_id', type: JBMTableColumnType.string,
        header: this.TranslateService.GetTranslation('customer.debtornumber-short'),
        width: 6, filterable: true },
      { name: 'coc', type: JBMTableColumnType.string,
        header: this.TranslateService.GetTranslation('customer.coc-number'),
        sortable: true, filterable: true, width: 7 },
      { name: 'address_id', type: JBMTableColumnType.address,
        header: this.TranslateService.GetTranslation('entity.address'), width: 4 },
      { name: 'phone', header: this.TranslateService.GetTranslation('entity.phone'),
        sortable: true, filterable: true, width: 8 },
      { name: 'email', type: JBMTableColumnType.email, header: this.TranslateService.GetTranslation('entity.email'),
        sortable: true, filterable: true, width: 12 },
      { name: 'blocked', type: JBMTableColumnType.boolean,
        header: this.TranslateService.GetTranslation('entity.blocked'),
        filterable: true, filterValues: [
            {key: '1', value: this.TranslateService.GetTranslation('ui.yes')},
            {key: '0', value: this.TranslateService.GetTranslation('ui.no')}
        ],
        displayValues: [
          { dataValue: false, displayValue: this.TranslateService.GetTranslation('ui.no') },
          { dataValue: true, displayValue: this.TranslateService.GetTranslation('ui.yes'), className: 'text-danger' },
        ], width: 5, className: 'opacity-7' },
      { name: 'description', type: JBMTableColumnType.notes, header: this.TranslateService.GetTranslation('entity.comments'),
        sortable: false, filterable: true, align: JBMTableColumnAlignment.center, width: 4 }
    ];

    if(this.userRights.AllowUpdate)
      this.actionDefs.push( { name: 'edit', iconClass: 'far fa-edit' } );
    if(this.userRights.AllowRead)
      this.actionDefs.push( { name: 'details', iconClass: 'fa fa-info', context: ContextType.light } );

    this.queryHttpHelper.addSearchColumn('customer_type', SearchColumnType.number);

    this.TablelayoutService.setLayout(tableName, this.columnDefs);

    // Get Customertypes
    this.customertypes$ = this.CustomertypeService.get().subscribe(
        (data: any) => this.customertypes = data.data, (error) => console.error(error),() => {
          const values = [];
          for (let c = 0; c < this.customertypes.length; c++)
            values.push( { key: this.customertypes[c].id, value: this.customertypes[c].description } );
          this.setColumnDefFilterValues( 'customer_type', values );
          this.checkViewState();

          this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
          this.getData(true);

          // Set custom blocked template
          this.columnDefs[8].template = this.blockedtemplate;
        } );
  }
  setColumnDefFilterValues( name, values ) {
    for (let c = 0; c < this.columnDefs.length; c++) {
      if (this.columnDefs[c].name === name) {
        this.columnDefs[c].filterValues = values;
      }
    }
  }

  // View state
  saveViewState() {
    this.queryHttpHelper.saveState(tableName);
    this.table.saveState(tableName);
  }
  checkViewState() {
    this.queryHttpHelper.restoreState(tableName);
    this.filtersVisible=this.queryHttpHelper.hasAndFilters();
    this.table.restoreState(tableName);
  }
  saveLayout() {
    this.TablelayoutService.saveLayout(tableName, this.columnDefs);
  }

  // Data
  getData(countRows: boolean= false) {
    this.loading = true;
    let params = this.queryHttpHelper.getHttpParams(false);
    this.customers$ = this.CustomerService.getData(params).subscribe(
        (data: any)=>this.customers=data.data,(error)=>console.error(error), ()=> {
          this.formatData();
          this.loading = false;

          if(!countRows)
            return;

          params=this.queryHttpHelper.getHttpParams(true);
          this.count$ = this.CustomerService.getData(params).subscribe(
            (data: any)=>this.queryHttpHelper.paginationProps.itemCount=data.data,
            (error)=>console.log(error),
            ()=>
              // Refresh view every minute (Periodically poll or server refresh)
              this.timer=setTimeout(()=> this.getData(true),60000) )
        } );
  }
  formatData() {
    for(let c = 0; c<this.customers.length; c++) {
      this.customers[c].customer_type =
          this.CustomertypeService.getDescriptionByID(this.customertypes, this.customers[c].customer_type);
    }
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }

  // Event handling
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page = page;
    this.getData();
  }
  searchChange(event) {
    let value = event.value;
    const minlength = event.minlength;
    value = value.length < minlength ? '' : value;
    if (this.queryHttpHelper.getSearchValue() !== value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  toggleFilters(event) {
    this.filtersVisible = !this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    for(let c=0; c<this.table.columnDefs.length; c++)
      this.table.columnDefs[c].filterValue='';
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }

  // Customer actions
  addCustomer() {
    this.Router.navigate([ { outlets: {'modal': ['customer', 'create']}} ] );
  }
  rowSelect(row) {
    this.saveViewState();
    this.Router.navigateByUrl('customers/details/' + row.id.toString());
  }
  action(action) {
    switch(action.name) {
      case 'edit':
        this.saveViewState();
        this.Router.navigate([ { outlets: {'modal': ['customer', 'edit', action.row.id]}} ] );
        break;
      case 'details':
        this.CustomerService.get(action.row.id,true).subscribe(
          (data)=>this.customer=data.data,
          (error)=>console.error(error),
          ()=>this.ModalService.open(this.modalCustomerDetails,'customerDetails')
        );
        break;
    }
  }
  closeCustomer() {
    this.ModalService.close('customerDetails');
  }
  // Prevent auto closing active dropdown(s) when data is refreshed
  dropdownOpenChanged(opened: boolean) {
    if(opened)
      clearTimeout(this.timer);
    else
      this.timer=setTimeout(()=> this.getData(true),60000);
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if (this.customers$ !== undefined) { this.customers$.unsubscribe(); }
    if (this.customertypes$ !== undefined) { this.customertypes$.unsubscribe(); }
    if (this.count$ !== undefined) { this.count$.unsubscribe(); }

    clearTimeout(this.timer);
 }
}
