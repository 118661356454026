import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CustomerService} from "../../customers/data/customer.service";
import {ProductionReceiptsService} from "../data/production-receipts.service";
import {PlantsService} from "../../company/data/plants.service";
import {ValidationPatternsService} from "../../../services/validation/patterns.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Receipt} from "../../production/data/interfaces/receipt";
import {Customer} from "../../customers/data/interfaces/customer";
import {JBMSelectOption} from "../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";

@Component({
  selector: 'instant-production-order',
  templateUrl: './instant-production-order.component.html',
  styleUrls: ['./instant-production-order.component.scss']
})
export class InstantProductionOrderComponent implements OnInit {
  receipt: Receipt=this.ProductionReceiptsService.getEmpty();
  plantOptions: JBMSelectOption[]=[];
  form: FormGroup;
  isSubmitted: boolean=false;
  customer: Customer=this.CustomerService.getEmpty();
  amountStr: string='';
  deliveredStr: string='';
  capacity: number=0;

  constructor(
      private CustomerService: CustomerService,
      private ProductionReceiptsService: ProductionReceiptsService,
      private PlantsService: PlantsService,
      private ValidationPatternsService: ValidationPatternsService,
      private TranslateService: TranslateService,
      private formBuilder: FormBuilder,
  ) { }

  @Input() order_id: number=0;
  @Input() plant_id: number=0;
  @Input() customer_id: number=0;
  @Input() amount: number=0.0;
  @Input() delivered: number=0.0;

  @Output() onClose=new EventEmitter();
  @Output() onSuccess=new EventEmitter();

  ngOnInit(): void {
    this.addControls();

    this.CustomerService.get(this.customer_id, true).subscribe(
        (data)=>this.customer=data.data,
        (error)=>console.error(error),
        ()=> {

          let plants=[];
          this.PlantsService.getData(null).subscribe(
              (data)=>plants=data.data,(error)=>console.error(error),()=>{
                this.plantOptions=this.PlantsService.getSelectOptions(plants);

                this.setValues();
                this.amountStr=this.amount.toString().replace('.',',');
                this.deliveredStr=this.delivered.toString().replace('.',',');

                document.getElementById('truck_id').focus();
              }
          );
        }
    )
  }
  addControls() {
    this.form = this.formBuilder.group(
        {
          plants_id: new FormControl('',[Validators.required]),
          trucks_id: new FormControl('',[Validators.required]),
          amount: new FormControl('',[Validators.pattern(this.ValidationPatternsService.floatPattern(2))]),
          receipt_amount: new FormControl('',[Validators.pattern(this.ValidationPatternsService.floatPattern(2))]),
          comment: new FormControl('' ),
        }
    );
  }
  setValues() {
    this.formControls.plants_id.setValue(this.plant_id.toString());
    this.formControls.amount.setValue(this.receipt.amount===0 ? '' : this.receipt.amount.toString().replace('.',','));
    this.formControls.receipt_amount.setValue(this.receipt.receipt_amount===0 ? '' : this.receipt.receipt_amount.toString().replace('.',','));
  }
  get formControls() {
    return this.form.controls;
  }
  changePlant(event) {
    this.plant_id=parseInt(event.target.value);
    this.formControls.plants_id.setValue(this.plant_id.toString());
  }
  selectTruck(data) {
    this.formControls.trucks_id.setValue(data.id.toString());
    let amount=this.amount-this.delivered;
    this.receipt.drivers_id = data.drivers_id;
    if(amount<0) amount=0;
    if(amount)
      amount=Math.min(amount,data.capacity);
    else
      amount=data.capacity;

    this.formControls.amount.setValue(amount.toString().replace('.',','));
    this.formControls.receipt_amount.setValue(this.formControls.amount.value);
  }
  save() {
    this.isSubmitted=true;
    if(this.form.invalid)
      return;

    let amount=parseFloat(this.formControls.amount.value.replace(',','.'));
    let receipt_amount=parseFloat(this.formControls.receipt_amount.value.replace(',','.'));

    this.receipt = {
      id: this.receipt.id,
      plants_id: this.plant_id,
      loading_time: this.receipt.loading_time,
      leave_plant_time: this.receipt.leave_plant_time,
      comment: this.formControls.comment.value,
      amount: amount,
      receipt_amount: receipt_amount,
      trucks_id: parseInt(this.formControls.trucks_id.value),
      drivers_id: this.receipt.drivers_id,
      orders_id: this.order_id,
      execute_date: new Date().getTime()
    }

    this.ProductionReceiptsService.create(this.receipt).subscribe(
        ()=>{},
        (error)=>console.error(error),
        ()=>this.onSuccess.emit()
    )
  }
  close() {
    this.onClose.emit();
  }
}
