import {Router, ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {EMPTY, Observable, throwError} from "rxjs";
import {PresentationsService} from "../presentations.service";
import {Presentation} from "../interfaces/presentation";
import {HttpParams} from "@angular/common/http";
import {TokenService} from "../../../../services/auth/token.service";
import {Injectable} from "@angular/core";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MailPresentationResolver implements Resolve<any> {
  language: string=this.TokenService.getLanguage();

  constructor(
      private Router: Router,
      private PresentationsService: PresentationsService,
      private TokenService: TokenService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Presentation> {
    let id=parseInt(route.paramMap.get('id'));
    if(isNaN(id)) {
      this.redirect();
      return EMPTY; // Empty observable will not activate this route
    } else {
      // Get the presentation data
      let language=this.TokenService.getLanguage();
      return this.PresentationsService.getEmail(language,id).pipe(catchError(this.handleError));
    }
  }
  handleError(error: any) {
    this.redirect();
    return throwError(error);
  }
  redirect() {
    this.Router.navigateByUrl('');
  }
}
