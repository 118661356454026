import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { HttpService} from "../../../services/http.service";

const baseURL: string = 'projects/';
const slug: string = 'recipes/';

@Injectable({
  providedIn: 'root'
})
export class RecipesService {
  constructor(private HttpService: HttpService) { }

  getOne(id: number, language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+'one/'+id.toString()+'/'+language, params);
  }
  get(language: string, params: HttpParams) {
    return this.HttpService.get(baseURL+'read/'+slug+language, params);
  }
}
