<div #ddContact="ngbDropdown" ngbDropdown autoClose="true" container="body" [placement]="'bottom-left'" class="{{class}}">
   <button type="button" class="btn btn-sm btn-light" id="contact-dropdown" ngbDropdownToggle
     (click)="toggle();$event.stopPropagation();">
      <i class="fas fa-user"></i> {{role}}
   </button>
   <div ngbDropdownMenu [attr.aria-labelledby]="'contact-dropdown'" class="table-cell contact">
      <div class="jbm-properties-list stacked">
         <ngb-alert *ngIf="!contact.name && !contact.phone && !contact.email" [dismissible]="false" [type]="'info'" [class]="'mb-0'">
            {{'ui.empty-item'|translate}}
         </ngb-alert>
         <div *ngIf="contact.name" class="item">
            <label class="label">
               {{'entity.name'|translate}}
            </label>
            <label class="value">
               {{contact.name}}
            </label>
         </div>
         <div *ngIf="contact.email" class="item">
            <label class="label">
               {{'entity.email'|translate}}
            </label>
            <label class="value">
               {{contact.email}}
            </label>
         </div>
         <div *ngIf="contact.phone" class="item">
            <label class="label">
               {{'entity.phonenumber'|translate}}
            </label>
            <label class="value">
               {{contact.phone}}
            </label>
         </div>
         <div *ngIf="allowEditing" class="item">
            <label class="label">&nbsp;</label>
            <label class="value">
               <button class="btn btn-light btn-sm" (click)="ddContact.close();edit()">{{'ui.edit'|translate}}</button>
            </label>
         </div>
      </div>
   </div>
</div>
