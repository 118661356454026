import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {GrouprightsService} from "../data/grouprights.service";
import {UsergroupService} from "../data/usergroup.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'groupsrights',
  templateUrl: './groupsrights.component.html',
  styleUrls: ['./groupsrights.component.scss']
})
export class GroupsRightsComponent implements OnInit, OnDestroy {

  appID: number=0;  // Management
  usergroupID: number=0;

  usergroups: any[];
  usergroups$: Subscription;
  userRights: any;
  groupRights: any[];
  grouprights$: Subscription;

  constructor(
      private UsergroupService: UsergroupService,
      private GrouprightsService: GrouprightsService,
      private TokenService: TokenService,
      public TranslateService: TranslateService,
      public JBMToastsService: JBMToastsService
  ) {}
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('userrights');
    this.usergroups$=this.UsergroupService.getData(this.appID).subscribe(
      (data: any) => this.usergroups = data.data,()=>{},()=> this.getData());
  }
  getData() {
    this.grouprights$=this.GrouprightsService.getData(this.appID, this.usergroupID).subscribe(
        (data: any) => this.groupRights = data.data,()=>{},()=>{
          this.formatData();
          this.groupRights.sort(function(a, b) {
            if(a.description<b.description)
              return -1
            else
              return 1
          })
        } );
  }
  formatData() {
    for(let g=0; g<this.groupRights.length; g++) {
      this.groupRights[g].description=this.TranslateService.GetTranslation(this.groupRights[g].description);
    }
  }
  appChange(event) {
    this.appID=parseInt(event.target.value);
    this.usergroups$=this.UsergroupService.getData(this.appID).subscribe(
      (data: any) => {
        this.usergroups = data.data;
        this.usergroupID=this.usergroups.length ? this.usergroups[0].id : 0;
      },()=>{},()=> this.getData());
  }
  usergroupChange(event) {
    this.usergroupID=parseInt(event.target.value);
    this.getData();
  }
  changeRight(event) {
    let temp=event.target.id;
    let parts=temp.split('_');
    let right=parts[0];
    let id=parseInt(parts[1]);

    for(let g=0; g<this.groupRights.length; g++) {
      if(this.groupRights[g].id===id) {
        switch(right) {
          case 'create':
            this.groupRights[g].allow_create=!this.groupRights[g].allow_create;
            break;
          case 'read':
            this.groupRights[g].allow_read=!this.groupRights[g].allow_read;
            break;
          case 'update':
            this.groupRights[g].allow_update=!this.groupRights[g].allow_update;
            break;
          case 'delete':
            this.groupRights[g].allow_delete=!this.groupRights[g].allow_delete;
        }
        this.update({
            id: this.groupRights[g].id,
            group_id: this.groupRights[g].group_id,
            right_id: this.groupRights[g].right_id,
            allow_create: this.groupRights[g].allow_create,
            allow_read: this.groupRights[g].allow_read,
            allow_update: this.groupRights[g].allow_update,
            allow_delete: this.groupRights[g].allow_delete
        });
      }
    }
  }
  update(data) {
    this.GrouprightsService.update(data).subscribe(
        data => data,
        error => { console.error(error) },
        () => {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
        });
  }
  ngOnDestroy(): void {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.grouprights$!=undefined) this.grouprights$.unsubscribe();
  }
}
