import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuardService} from "../../services/auth/authguard.service";
import {AppLayouts} from "../../app.component";
import {ReportsComponent} from "./reports.component";
import {ProductionReportComponent} from "./production/production-report/production-report.component";
import {ResourceUsageReportComponent} from "./production/resource-usage-report/resource-usage-report.component";

const routes: Routes = [
  {
    path: 'reports',
    component: ReportsComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'reports/production',
    component: ProductionReportComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  },
  {
    path: 'reports/resource-usage',
    component: ResourceUsageReportComponent,
    canActivate: [AuthGuardService],
    data: {AppLayout: AppLayouts.User}
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
