import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Presentation} from "../data/interfaces/presentation";
import {MailAttachmentsService} from "../data/mail_attachments.service";
import {MediaFilesService} from "../data/media_files.service";
import {MailTemplatesService} from "../data/mail_templates.service";
import {MailDefaultAttachmentsService} from "../data/mail_default_attachments.service";
import {TokenService} from "../../../services/auth/token.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {LocalStorageService} from "../../../services/storage/localstorage.service";
import {Subscription} from "rxjs";
import {
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from '../../../components/JBM/JBMTable/JBMTable.component';
import {QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import {MailTemplate} from "../data/interfaces/mail_template";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../users/data/interfaces/grouprights";
import {MediaFilesComponent} from '../media-files/datatable/media-files.component';
import {MediaFilesSelectorComponent} from '../media-files/selector/media-files-selector.component';

const localStorageKeys = { primary: 'mail-attachments_primary_size', secondary: 'mail-attachments_secondary_size' }

@Component({
  selector: 'mail-attachments',
  templateUrl: './mail-attachments.component.html',
  styleUrls: ['./mail-attachments.component.scss']
})
export class MailAttachmentsComponent implements OnInit, OnDestroy {
  userRights: GroupRights;
  language: string=this.TokenService.getLanguage();
  presentation: Presentation;
  mailTemplate: MailTemplate=this.MailTemplatesService.getEmpty();
  panel_primary_height: number=40;
  panel_secondary_height: number=60;
  attachments: any;
  loading: boolean=false;
  columnDefs: JBMTableColumnDef[];
  queryHttpHelper: QueryHttpHelper;
  fileExtension: string;
  base64: string;
  description: string='';

  searchboxVisible = true;
  search = '';
  preview: boolean=false;

  attachments$: Subscription;
  count$: Subscription;

  @ViewChild('mediaFileSelector') mediaFileSelector: MediaFilesSelectorComponent;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

  constructor(
      private Router: Router,
      private ActivatedRoute: ActivatedRoute,
      private MailAttachmentsService: MailAttachmentsService,
      private MediaFilesService: MediaFilesService,
      private MailTemplatesService: MailTemplatesService,
      private MailDefaultAttachmentsService: MailDefaultAttachmentsService,
      private TranslateService: TranslateService,
      private LocalStorageService: LocalStorageService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService
  ) {
    this.presentation=this.ActivatedRoute.snapshot.data.presentation.data;
  }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('presentations');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true},
      { name: 'filename', header: this.TranslateService.GetTranslation('ui.filename'), sortable: true, width: 18 },
      { name: 'extension', header: this.TranslateService.GetTranslation('ui.file-extension'), sortable: true, width: 8 },
      { name: 'size', type: JBMTableColumnType.number, header: this.TranslateService.GetTranslation('ui.file-size'), sortable: true, width: 5 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 10 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);
    this.queryHttpHelper.addSearchColumn('extension', SearchColumnType.string);

    this.MailTemplatesService.getOne(this.language, this.presentation.id).subscribe(
        (data)=>this.mailTemplate=data.data as MailTemplate,
        (error)=>console.error(error),
        ()=> {
          this.initPanelSizes();
          this.getData(true)
        }
    )
  }
  getData(countRows: boolean= false) {
    this.loading=true;
    let params = this.queryHttpHelper.getHttpParams(false);
    this.attachments$=this.MailAttachmentsService.get(this.mailTemplate.id, params).subscribe(
        (data: any) => this.attachments = data.data,(error) => console.error(error),()=> {
          this.loading=false;

          // Set actions column template
          this.columnDefs[5].template=this.actionsTemplate;

          if(countRows) {
            params=this.queryHttpHelper.getHttpParams(true);
            this.count$ = this.MailAttachmentsService.get(this.mailTemplate.id, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data, (error) =>console.error(error),() => {} );
          }
        }
    )
  }
  initPanelSizes() {
    if(this.LocalStorageService.hasKey( localStorageKeys.primary ))
      this.panel_primary_height=this.LocalStorageService.get( localStorageKeys.primary );
    if(this.LocalStorageService.hasKey( localStorageKeys.secondary ))
      this.panel_secondary_height=this.LocalStorageService.get( localStorageKeys.secondary );
  }
  splitterDragEnd(unit: string, event) {
    // Store new panel sizes in localstorage
    this.LocalStorageService.set( localStorageKeys.primary, event.sizes[0]);
    this.LocalStorageService.set( localStorageKeys.secondary, event.sizes[1]);
  }

  // Event handling
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page = page;
    this.getData();
  }
  searchChange(event) {
    let value = event.value;
    const minlength = event.minlength;
    value = value.length < minlength ? '' : value;
    if (this.queryHttpHelper.getSearchValue() !== value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }

  selectMediaFile(event) {
    this.MailDefaultAttachmentsService.create( { mail_templates_id: this.mailTemplate.id, media_files_id: event.id } ).subscribe(
      ()=>{},(error)=>console.error(error),
      ()=>{
        this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
        this.getData();
        this.mediaFileSelector.getData();
      }
    )
  }

  showPreview(event) {
    this.base64='';
    this.fileExtension='';
    this.MediaFilesService.getOne(event.id).subscribe(
        (data)=> {
          this.base64=data.content;
          this.fileExtension=data.extension;
          this.description=data.description;
          this.preview=true;
        },
        (error)=> console.error(error)
    )
  }
  delete(id) {
    this.MailDefaultAttachmentsService.delete(id).subscribe(()=>{},(error)=>console.error(error),
      ()=>{
        this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
        this.getData();
        this.mediaFileSelector.getData();
      }
    )
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.attachments$!=undefined) this.attachments$.unsubscribe();
  }
}
