import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TrucksService} from "../../data/trucks.service";
import {PlantsService} from "../../../company/data/plants.service";
import {ValidationPatternsService} from "../../../../services/validation/patterns.service";
import {Truck} from "../../data/interfaces/truck";
import {CRUDAction} from "../../../../components/JBM/Views/JBMCRUDTitle/JBMCRUDTitle.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormBuilder} from "@angular/forms";
import {Plant} from "../../../company/data/interfaces/Plant";
import {JBMSelectOption} from "../../../../components/JBM/Forms/JBMFormGroups/JBMSelect/JBMSelect.component";
import {Driver} from "../../data/interfaces/driver";

@Component({
  selector: 'truck-form',
  templateUrl: './truck-form.component.html',
  styleUrls: ['./truck-form.component.scss']
})
export class TruckFormComponent implements OnInit {
  action: CRUDAction;
  form: FormGroup;
  submitted: boolean=false;
  unique: boolean=true;

  plants: Plant[]=[];
  plantOptions: JBMSelectOption[]=[];

  constructor(
      private TrucksService: TrucksService,
      private PlantsService: PlantsService,
      private ValidationPatternsService: ValidationPatternsService,
      private formBuilder: FormBuilder
  ) {
    this.addControls();
  }

  @Input() truck: Truck;

  @Output() onSave=new EventEmitter();
  @Output() onCancel=new EventEmitter();

  ngOnInit(): void {
    if(this.truck.id===0)
      this.action=CRUDAction.Create;
    else
      this.action=CRUDAction.Update;

    this.PlantsService.getData(null).subscribe(
        (data)=>this.plants=data.data,
        (error)=>console.error(error),
        ()=> {
          this.plantOptions=this.PlantsService.getSelectOptions(this.plants);
          this.setControlValues();
        }
    )
  }
  addControls() {
    this.form = this.formBuilder.group({
      plants_id: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} ),
      number: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} ),
      numberplate: new FormControl('', {updateOn: 'blur', validators: [Validators.required]} ),
      capacity: new FormControl('',[Validators.pattern(this.ValidationPatternsService.floatPattern(2))]),
      active: new FormControl(true ),
    });
  }
  setControlValues() {
    this.form.controls.plants_id.setValue(this.truck.plants_id.toString());
    this.form.controls.number.setValue(this.truck.number);
    this.form.controls.numberplate.setValue(this.truck.numberplate);
    this.form.controls.capacity.setValue( this.truck.id===0 ? '' : this.truck.capacity.toString().replace('.',','));
    this.form.controls.active.setValue(this.truck.active);
  }
  get formControls() {
    return this.form.controls;
  }
  selectDriver(driver: Driver) {
    this.truck.drivers_id=driver.id;
  }
  save() {
    this.submitted = true;

    if(this.form.invalid)
      return;

    let capacity=parseFloat(this.formControls.capacity.value.replace(',','.'));

    this.truck = {
      id: this.truck.id,
      plants_id: parseInt(this.formControls.plants_id.value),
      drivers_id: this.truck.drivers_id===0 ? null : this.truck.drivers_id,
      number: this.formControls.number.value,
      numberplate: this.formControls.numberplate.value,
      capacity: capacity,
      active: this.formControls.active.value,
    }
    if(this.truck.id===0) {
      this.TrucksService.create(this.truck).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    } else {
      this.TrucksService.update(this.truck).subscribe(
          ()=>{},error=>this.handleError(error),()=>this.onSave.emit())
    }
  }
  handleError(error) {
    if(error.toLowerCase()==='number_not_unique') {
      this.formControls.number.setErrors({notUnique: true});
      return;
    }
    if(error.toLowerCase()==='numberplate_not_unique') {
      this.formControls.numberplate.setErrors({notUnique: true});
      return;
    }
    console.error(error);
  }
  cancel() {
    this.onCancel.emit();
  }
}
