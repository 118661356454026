export class InputHelper {
	sanitizeFloatValue=function( value: string, decimalsOptional: boolean, decimals: number, emptyIfZero: boolean=true) {

		if(value.length) {
			// Remove sequence of leading zeros
			let floatValue=parseFloat(value.replace(',','.'));
			if(isNaN(floatValue))
				value='';
			value=floatValue.toString();
			value=value.replace('.',',');
		}

		let index=value.indexOf(',');
		if(decimalsOptional) {
			// Remove extra decimals
			if(index>-1) {
				let decimalCount=value.length - (index+1);
				value=value.substr(0, value.length - (decimalCount-decimals));
			}
		} else {
			// Guarantee required amount of decimals
			if(index===-1)
				value += ',';
			index=value.indexOf(',');
			for(let d=value.length - (index+1); d<decimals; d++)
				value+='0';
		}

		if(emptyIfZero && value==='0')
			value='';

		return value;
	}
	sanitizeIntegerValue=function( value: string, emptyIfZero: boolean=true) {

		if(value.length) {
			// Remove sequence of leading zeros
			let intValue=parseInt(value);
			if(isNaN(intValue))
				value='';
			value=intValue.toString();
		}

		if(emptyIfZero && value==='0')
			value='';

		return value;
	}
}