<app-header [class]="'planning'">
   <jbm-view-title caption="{{'menu.planning'|translate}}" [iconClassname]="'calendar'"></jbm-view-title>
   <div class="toolbar float-start">
      <div *ngIf="totalOrders" class="period-totals">
         <span class="badge badge-pill bg-light text-dark mr-2">{{totalOrders}} <span *ngIf="totalOrders==1">{{'project.order'|translate}}</span><span *ngIf="totalOrders>1">{{'project.orders'|translate}}</span></span>
         <span class="badge badge-pill bg-light text-dark mr-2"><span *ngIf="totalProducedM3">{{totalProducedM3}}/</span>{{totalM3}} {{'quantity.m3'|translate}}</span>
         <span *ngIf="!plannableListView && (trucksCapacityIndication.smallestPositive!=0 || trucksCapacityIndication.largestNegative!=0)" class="truck-capacity badge-pill bg-light text-dark mr-2">
            <span class="mr-1"><i class="fa fa-truck"></i></span>
            <span *ngIf="trucksCapacityIndication.smallestPositive>0" class="badge badge-pill bg-success">{{trucksCapacityIndication.smallestPositive}}</span>
            <span *ngIf="trucksCapacityIndication.largestNegative<0" class="badge badge-pill bg-danger">{{trucksCapacityIndication.largestNegative}}</span>
         </span>
      </div>
      <label *ngIf="plannableListView && plantOptions.length>1">{{'company.plant'|translate}}</label>
      <select *ngIf="plannableListView && plantOptions.length>1" (change)="changePlantPlannable($event)" class="plant form-control form-control-sm form-select lg">
         <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
      </select>
      <truck-capacity-indication *ngIf="!plannableListView && truckHours.length" [truckHours]="truckHours"></truck-capacity-indication>
   </div>
   <div class="toolbar float-end">
      <jbm-spinner [loading]="loading"></jbm-spinner>
      <div *ngIf="loading" class="toolbar-spacer-1"></div>
      <jbm-toggle-button (onToggle)="togglePlannableListView()" [toggled]="plannableListView" caption="{{'planning.plannable'|translate}} {{'project.orders'|translate|lowercase}}"></jbm-toggle-button>
      <div class="toolbar-spacer-1"></div>
      <jbm-toggle-button *ngIf="planningView==1" (onToggle)="toggleTaskListCompactView()" [toggled]="taskListView==0" caption="{{'ui.view-compact'|translate}}"></jbm-toggle-button>
      <div *ngIf="planningView==1 && !plannableListView" class="toolbar-spacer-1"></div>
      <button *ngIf="!plannableListView" type="button" class="btn btn-sm btn-light" [ngClass]="{active: planningView==0}" (click)="setPlanningView(0)">{{'ui.chart'|translate}}</button>
      <button *ngIf="!plannableListView" type="button" class="btn btn-sm btn-light" [ngClass]="{active: planningView==1}" (click)="setPlanningView(1)">{{'ui.list'|translate}}</button>
      <div class="toolbar-spacer-2"></div>
      <jbm-help-button [helpTopicID]="0"></jbm-help-button>
   </div>
</app-header>
<section class="module padding planning">
   <div class="planning-chart">
      <div *ngIf="!plannableListView" class="header clearfix">
         <div class="date-time">
            <div *ngIf="chartPeriod==0" class="date-range">{{dateRange.startDate|date:'EEEE d MMMM y':'':'nl'|titlecase}}</div>
            <div *ngIf="chartPeriod>0" class="date-range">
               {{dateRange.startDate|date:'EEEEEE d MMM':'':'nl'|titlecase}} -
               {{dateRange.endDate|date:'EEEEEE d MMM y':'':'nl'|titlecase}}
            </div>
            <div class="time">
               <i class="far fa-clock"></i> {{timeMarkerDate|date:'HH:mm:ss':'':'nl'}}
            </div>
         </div>
         <div *ngIf="plantOptions.length" class="toolbar plant-selector">
            <label>{{'company.plant'|translate}}</label>
            <select (change)="changePlantPlanned($event)" class="plant form-control form-control-sm form-select lg">
               <option *ngFor="let plant of plantOptions" value="{{plant.key}}">{{plant.value}}</option>
            </select>
         </div>
         <div class="toolbar float-end ml-4">
            <div ngbDropdown container="body">
              <button [disabled]="loading" type="button" class="btn btn-light btn-sm" id="planning-legend" ngbDropdownToggle>
                {{'ui.diagram-legend'|translate}}
              </button>
              <div ngbDropdownMenu [attr.aria-labelledby]="'planning-legend'" class="table-cell planning-legend">
                <table class="table table-xs table-borderless">
                  <tr><th>{{'ui.color'|translate}}</th><th class="state">{{'ui.state'|translate}}</th></tr>
                  <tr><td><div style="background-color: #28a745" class="p-1"></div></td><td class="value">{{inProgressProjectstate.description}}</td></tr>
                  <tr><td><div style="background-color: yellow" class="p-1"></div></td><td class="value">{{'project.order'|translate}} {{'order.on-call'|translate|lowercase}}</td></tr>
                  <tr><td><div style="background-color: red" class="p-1"></div></td><td class="value">{{'project.order'|translate}} {{'order.loadtime'|translate|lowercase}} {{'ui.expired'|translate|lowercase}}</td></tr>
                  <tr><td><div style="background-color: darkorange" class="p-1"></div></td><td class="value">{{'order.staging-production'|translate}}</td></tr>
                </table>
              </div>
            </div>
          </div>

         <div class="controls">
            <div class="navigation-controls">
               <button type="button" class="btn btn-sm btn-light" (click)="navigatePrevious()"><i class="fa fa-arrow-left"></i></button>
               <button type="button" class="btn btn-sm btn-light" (click)="navigateToday()">{{'ui.calendar-today'|translate}}</button>
               <button type="button" class="btn btn-sm btn-light" (click)="navigateNext()"><i class="fa fa-arrow-right"></i></button>
            </div>
            <div class="view-controls">
               <button type="button" class="btn btn-sm btn-light" [ngClass]="{active: chartPeriod==0}" (click)="setChartPeriod(0)">{{'ui.calendar-day'|translate}}</button>
               <button type="button" class="btn btn-sm btn-light" [ngClass]="{active: chartPeriod==1}" (click)="setChartPeriod(1)">{{'ui.calendar-week'|translate}}</button>
               <button type="button" class="btn btn-sm btn-light" [ngClass]="{active: chartPeriod==2}" (click)="setChartPeriod(2)">{{'ui.calendar-month'|translate}}</button>
            </div>
         </div>
      </div>
      <div *ngIf="!plannableListView && planningView==0" class="timeline clearfix">
         <div *ngFor="let tick of timeLineTicks" class="tick {{tick.class}}" [ngClass]="{'day':chartPeriod==0, 'week':chartPeriod==1}">
            <div>{{tick.label}}</div>
            <div *ngFor="let marker of tick.markers; let i = index" class="marker" [ngClass]="{first:i==0}" style="left:{{marker}}%"></div>
         </div>
      </div>
      <div *ngIf="planningView===0" class="chart tasks">
         <div [ngSwitch]="chartPeriod">
            <div *ngSwitchCase="0">
               <ng-container *ngFor="let day of dateRange.days">
                  <ng-container *ngFor="let task of tasks[day]; let i = index">
                     <div class="task-line day" [ngClass]="{selected: selectedTaskIndex===i}">
                        <jbm-cross-close-button *ngIf="selectedTaskIndex===i" (onClick)="selectedTaskIndex=-1;"></jbm-cross-close-button>
                        <ng-container *ngTemplateOutlet="chartDayTaskTemplate; context: {'task': task, 'index': i}"></ng-container>
                     </div>
                     <div *ngIf="selectedTaskIndex===i" class="task-line-details" [ngClass]="{selected: selectedTaskIndex===i}">
                        <ng-container *ngTemplateOutlet="listTaskLineTemplate; context: {'chartPeriod': chartPeriod, 'task': task}"></ng-container>
                     </div>
                  </ng-container>
               </ng-container>
               <div *ngIf="timeMarker<100" class="time-marker" style="left:{{timeMarker}}%"></div>
            </div>
            <div *ngSwitchCase="1" class="clearfix">
               <div *ngFor="let day of dateRange.days" class="day-column clearfix">
                  <span *ngIf="dayTotals[day].totalM3==0">&nbsp;</span>
                  <div class="day-totals">
                     <ng-container *ngTemplateOutlet="chartWeekdayTotalsTemplate; context: {'totals': dayTotals[day]}"></ng-container>
                  </div>
                  <ng-container *ngFor="let task of tasks[day]; let i = index">
                     <div class="task-line week" [ngClass]="{selected: selectedTaskIndex===i}" (click)="showOrderDetails(task)">
                        <ng-container *ngTemplateOutlet="chartWeekTaskTemplate; context: {'task': task, 'index': i}"></ng-container>
                     </div>
                  </ng-container>
               </div>
               <div *ngIf="timeMarker<100" class="time-marker" style="left:{{timeMarker}}%"></div>
            </div>
            <div *ngSwitchCase="2" class="clearfix">
               <div *ngFor="let day of dateRange.days" class="day-column month month-{{dateRange.days.length}}">
                  <span *ngIf="dayTotals[day].totalM3==0">&nbsp;</span>
                  <div *ngIf="dayTotals[day].totalOrders" class="day-totals">
                     <ng-container *ngTemplateOutlet="chartMonthdayTotalsTemplate; context: {'totals': dayTotals[day]}"></ng-container>
                  </div>
                  <ng-container *ngFor="let task of tasks[day]; let i = index">
                     <div class="task-line month" [ngClass]="{selected: selectedTaskIndex===i}" (click)="showOrderDetails(task)">
                        <ng-container *ngTemplateOutlet="chartMonthTaskTemplate; context: {'task': task, 'day': day, 'index': i}"></ng-container>
                     </div>
                  </ng-container>
               </div>
               <div *ngIf="timeMarker<100" class="time-marker" style="left:{{timeMarker}}%"></div>
            </div>
         </div>
      </div>
      <div *ngIf="planningView===1 && !plannableListView" class="tasklist tasks">
         <div *ngFor="let day of dateRange.days">
            <ng-container *ngFor="let task of tasks[day]; let i = index">
               <div class="task-line clearfix">
                  <ng-container *ngTemplateOutlet="listTaskTemplate; context: {'task': task, 'index': i}"></ng-container>
                  <div class="task-line-details bg-blue-extra-light">
                     <ng-container *ngTemplateOutlet="listTaskLineTemplate;
                        context: {'chartPeriod': chartPeriod, 'task': task}">
                     </ng-container>
                  </div>
                  <div *ngIf="timeMarker<100" class="time-marker" style="left:{{timeMarker}}%"></div>
               </div>
            </ng-container>
         </div>
      </div>
      <div *ngIf="plannableListView" class="plannable orders tasklist tasks">
         <h5 class="subtitle">{{'project.orders'|translate}} {{'planning.plan'|translate|lowercase}}</h5>
         <ng-container *ngFor="let task of tasks[0]; let i = index">
            <div class="task-line clearfix">
               <ng-container *ngTemplateOutlet="planOrderTemplate; context: {'order': task.order}"></ng-container>
               <div class="task-line-details">
                  <ng-container *ngTemplateOutlet="listTaskLineTemplate;
                        context: {'chartPeriod': chartPeriod, 'task': task}">
                  </ng-container>
               </div>
               <div *ngIf="timeMarker<100" class="time-marker" style="left:{{timeMarker}}%"></div>
            </div>
         </ng-container>
      </div>
   </div>
   <jbm-empty-view [emptyView]="!loading && totalOrders==0"></jbm-empty-view>
</section>
<ng-template #chartDayTaskTemplate let-task="task" let-index="index">
   <ng-template #taskTooltip>
      <table class="table table-xs tooltip-table">
         <tr><td colspan="2"><span class="fw-bold">{{task.order.project.projectState.description}}</span></td></tr>
         <tr *ngIf="task.order.starttime"><td>{{'order.starttime'|translate}}:</td><td><span class="fw-bold">{{task.order.starttime}}</span> {{'datetime.hour'|translate}}</td></tr>
         <tr><td>{{'order.loadtime'|translate}}:</td><td><span class="fw-bold">{{task.order.loadtime}}</span> {{'datetime.hour'|translate}}</td></tr>
         <tr *ngIf="task.order.endtime"><td>{{'order.endtime'|translate}}:</td><td><span class="fw-bold">{{task.order.endtime}}</span> {{'datetime.hour'|translate}}</td></tr>
      </table>
   </ng-template>
   <div *ngIf="task.width<50" class="task-left pr-1" style="float:left;text-align:right;width: {{task.left}}%;">
      <div *ngIf="task.left>50" class="projectname">{{task.order.project.name}}</div>
      <span *ngIf="task.left>50" class="label">{{task.order.amount}} {{'quantity.m3'|translate}}-{{task.order.unloading_flow}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}</span>
   </div>
   <div *ngIf="task.width<50" class="task-middle"
     style="float:left;width:{{task.width}}%;background-color:{{task.backgroundColor}};color:{{task.foregroundColor}}"
     (click)="taskClick(index)" [ngbTooltip]="taskTooltip" [placement]="task.left>50 ? 'left' : 'right'">
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100"
        class="task-progress bg-success float-start" style="width:{{task.completedPercentage}}%">
         <span class="label">{{task.order.delivered}}</span>
      </div>
      <div *ngIf="task.completedPercentage==0" class="task-progress" style="width:100%">
         <span class="label">{{task.order.delivered}}</span>
      </div>
      <div *ngIf="task.stagingPercentage>0" class="task-progress float-start" style="background-color: darkorange;width:{{task.stagingPercentage}}%">
         <span class="label">{{task.stagingAmount}}</span>
      </div>
   </div>
   <div *ngIf="task.width>=50" class="task-large" style="left: {{task.left}}%;width:{{task.width}}%;background-color:{{task.backgroundColor}};color:{{task.foregroundColor}}" (click)="taskClick(index)"
     [ngbTooltip]="taskTooltip" [placement]="task.left>50 ? 'left' : 'right'">
      <div class="projectname">{{task.order.project.name}}</div>
      <div *ngIf="task.completedPercentage==0 || task.completedPercentage==100" class="label amount-flow">
         {{task.order.amount}} {{'quantity.m3'|translate}}-{{task.order.unloading_flow}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}
      </div>
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100"
        class="task-progress bg-success float-start" style="width:{{task.completedPercentage}}%">
         <span class="label">{{task.order.delivered}}</span>
      </div>
      <div *ngIf="task.completedPercentage==0"
        class="task-progress float-start" style="width:100%">
         <span class="label" style="color:{{task.foregroundColor}}">{{task.order.delivered}}</span>
      </div>
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100" class="label amount" style="color:{{task.foregroundColor}}">
         {{task.order.amount}}
      </div>
      <div *ngIf="task.stagingPercentage>0" class="task-progress float-start" style="background-color: darkorange;width:{{task.stagingPercentage}}%">
         <span class="label">{{task.stagingAmount}}</span>
      </div>
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100" class="label flow"
        style="color:{{task.foregroundColor}}">
         {{task.order.unloading_flow}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}
      </div>
   </div>
   <div *ngIf="task.width<50" class="task-right pl-1" style="float:left;width:{{(task.left+task.width)!=0 ? (100-(task.left+task.width)) : 'auto'}}%">
      <div *ngIf="task.left<50" class="projectname">{{task.order.project.name}}</div>
      <div *ngIf="task.left<50" class="label amount-flow">
         {{task.order.amount}} {{'quantity.m3'|translate}}-{{task.order.unloading_flow}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}
      </div>
   </div>
</ng-template>

<ng-template #chartWeekdayTotalsTemplate let-totals="totals">
   <span *ngIf="totals.totalOrders>0" class="badge bg-secondary">{{totals.totalOrders}}</span>
   <span *ngIf="totals.totalOrders>0">&nbsp;/&nbsp;</span>
   <span *ngIf="totals.totalM3>0" class="badge bg-secondary">{{totals.totalM3}}</span>
</ng-template>
<ng-template #chartWeekTaskTemplate let-task="task" let-index="index">
   <div class="times" [ngbTooltip]="('order.starttime'|translate)+' '+('order.loadtime'|translate)+' '+('order.endtime'|translate)">
      <label class="value">{{task.order.starttime}}</label>&nbsp;&nbsp;<label class="value">{{task.order.loadtime}}</label>&nbsp;&nbsp;<label class="value">{{task.order.endtime}}</label>
   </div>
   <div class="projectname">{{task.order.project.name}}</div>
   <div class="task" style="left:0;width:100%;background-color:{{task.backgroundColor}};color:{{task.foregroundColor}}" ngbTooltip="{{task.order.project.projectState.description}}">
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100"
        class="task-progress bg-success float-start" style="width:{{task.completedPercentage}}%">
         <span class="label">{{task.order.delivered}}</span>
      </div>
      <div *ngIf="task.stagingPercentage>0" class="task-progress float-start" style="background-color: darkorange;width:{{task.stagingPercentage}}%">
         <span class="label">{{task.stagingAmount}}</span>
      </div>
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100" class="label amount">
         {{task.order.amount}}
      </div>
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100" class="label flow">
         {{task.order.unloading_flow}} {{'quantity.m3'|translate}} {{'datetime.hour'|translate}}
      </div>
      <div *ngIf="task.completedPercentage==0 || task.completedPercentage==100" class="label amount-flow">
         {{task.order.amount}} {{'quantity.m3'|translate}}-{{task.order.unloading_flow}} {{'quantity.m3'|translate}}/{{'datetime.hour'|translate}}
      </div>
   </div>
</ng-template>

<ng-template #chartMonthdayTotalsTemplate let-totals="totals">
   <span class="badge bg-secondary">{{totals.totalOrders}}</span>
   <span *ngIf="totals.totalM3>0" class="badge bg-secondary">{{totals.totalM3}}</span>
</ng-template>
<ng-template #chartMonthTaskTemplate let-task="task" let-day="day" let-index="index">
   <ng-template #taskTooltip>
      <table class="table table-xs tooltip-table">
         <tr><td colspan="2"><span class="fw-bold">{{task.order.project.name}}</span></td></tr>
         <tr><td>{{'project.state'|translate}}:</td><td><span class="fw-bold">{{task.order.project.projectState.description}}</span></td></tr>
         <tr *ngIf="task.order.starttime"><td>{{'order.starttime'|translate}}:</td><td><span class="fw-bold">{{task.order.starttime}}</span> {{'datetime.hour'|translate}}</td></tr>
         <tr><td>{{'order.loadtime'|translate}}:</td><td><span class="fw-bold">{{task.order.loadtime}}</span> {{'datetime.hour'|translate}}</td></tr>
         <tr *ngIf="task.order.endtime"><td>{{'order.endtime'|translate}}:</td><td><span class="fw-bold">{{task.order.endtime}}</span> {{'datetime.hour'|translate}}</td></tr>
      </table>
   </ng-template>
   <div class="task"
      style="left:0;width:100%;background-color:{{task.backgroundColor}};color:{{task.foregroundColor}}"
      [placement]="'right'" [ngbTooltip]="taskTooltip" tooltipClass="planning-month-tooltip">
      <div *ngIf="task.completedPercentage>0 && task.completedPercentage<100" class="task-progress bg-success float-start" style="width:{{task.completedPercentage}}%"></div>
      <div *ngIf="task.stagingPercentage" class="task-progress float-start" style="background-color:darkorange;width:{{task.completedPercentage}}%"></div>
      <div class="label amount">{{task.order.amount}}</div>
   </div>
</ng-template>

<ng-template #listTaskTemplate let-task="task" let-index="index">
</ng-template>
<ng-template #listTaskLineTemplate let-task="task">
   <planning-task-details [task]="task" [showAsModal]="true" [compactView]="planningView==1 && taskListView==0" [plannable]="plannableListView"
     (onInstantReceipt)="showInstantReceipt($event)" (onDelete)="onDelete()" (onOpenDropdownChange)="dropdownOpenChanged($event)">
   </planning-task-details>
</ng-template>

<ng-template #planOrderTemplate let-order="order">
   <form-plan-order [order]="order"></form-plan-order>
</ng-template>

<jbm-modal [id]="'orderdetails'" [className]="'order-details'"></jbm-modal>
<ng-template #modalOrderDetails let-modal>
   <div class="jbm-modal-header">
      <jbm-view-title [caption]="('entity.planning'|translate)+' '+('ui.details'|translate|lowercase)"></jbm-view-title>
      <div class="toolbar float-end">
         <jbm-help-button class="btn-light" [helpTopicID]="0"></jbm-help-button>
         <div class="toolbar-spacer-1"></div>
         <jbm-cross-close-button (onClick)="closeOrderDetails()"></jbm-cross-close-button>
      </div>
   </div>
   <div class="jbm-modal-body">
      <planning-task-details (onInstantReceipt)="showInstantReceipt($event)"
        (onDelete)="onDelete()" (onOpenDropdownChange)="dropdownOpenChanged($event)">
      </planning-task-details>
   </div>
</ng-template>

<jbm-modal [id]="'instant-receipt'" [className]="'instant-receipt'"></jbm-modal>
<ng-template #modalInstantReceipt let-modal>
   <instant-production-order [order_id]="order_id" [customer_id]="customer_id" [plant_id]="plant_id"
     [amount]="amount" [delivered]="delivered" (onSuccess)="instantReceiptSuccess()" (onClose)="closeInstantReceipt()"></instant-production-order>
</ng-template>

<jbm-toasts></jbm-toasts>
