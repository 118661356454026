import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'jbm-edit-round-button',
  templateUrl: './JBMEditRoundButton.component.html',
  styleUrls: ['./JBMEditRoundButton.component.scss'],
  host: {'class': 'JBMButton'}
})
export class JBMEditRoundButtonComponent {
  @Input() class: string;
  @Output() click = new EventEmitter();
  onBtnClick(event) {
    this.click.emit();
  }
}
