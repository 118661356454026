import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule} from "../shared.module";
import { JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import { JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import { JBMModalModule} from "../../components/JBM/JBMModal/jbmmodal.module";
import { JBMViewModule} from "../../components/JBM/Views/jbmview.module";
import { SharedCustomersModule} from "../customers/shared.customers.module";
import { ProjectDetailsComponent} from "./details/project-details.component";
import { OrderDetailsComponent } from './orders/details/order-details.component';
import { OrderArticlesListComponent} from "./orders/order-articles-list/order-articles-list.component";

@NgModule({
  declarations: [ProjectDetailsComponent, OrderDetailsComponent, OrderArticlesListComponent],
  imports: [CommonModule, SharedModule, JBMButtonsModule, JBMDropdownsModule, JBMModalModule, JBMViewModule,
    SharedCustomersModule],
  exports: [ProjectDetailsComponent, OrderDetailsComponent, OrderArticlesListComponent]
})
export class SharedProjectsModule { }
