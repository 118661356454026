<app-header>
    <jbm-view-title [caption]="('production.drivers'|translate) + ' ' + ('ui.and'|translate|lowercase) +' ' + ('trucks'|translate|lowercase)"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'"></jbm-view-title>
    <div class="btn-group float-end">
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module padding">
    <nav ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav-tabs module bg-light">
        <ng-container ngbNavItem [ngbNavItem]="0">
            <a ngbNavLink>
                {{'production.drivers'|translate}}
                <span *ngIf="activeTab==0 && this.driverCount" class="badge bg-secondary">{{this.driverCount}}</span>
            </a>
            <ng-template ngbNavContent>
                <drivers (afterCount)="updateDriverCount($event)"></drivers>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink>
                {{'production.trucks'|translate}}
                <span *ngIf="activeTab==1 && this.truckCount" class="badge bg-secondary">{{this.truckCount}}</span>
            </a>
            <ng-template ngbNavContent>
                <trucks (afterCount)="updateTruckCount($event)"></trucks>
            </ng-template>
        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>
<jbm-toasts></jbm-toasts>
