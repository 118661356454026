<div class="modal-header">
    <div class="float-start">
        <h4 *ngIf="editable && !updateMode" class="modal-title">{{createTitle}}</h4>
        <h4 *ngIf="editable && updateMode" class="modal-title">{{updateTitle}}</h4>
        <h4 *ngIf="!editable" class="modal-title">{{readTitle}}</h4>
    </div>
    <div class="float-end">
        <div *ngFor="let b of buttons" class="buttons">
            <button *ngIf="b.visible" type="button" class="btn btn-light" [disabled]="!b.enabled" (click)="OnClick(b)"><i *ngIf="b.iconclass" class="{{b.iconclass}}"></i> {{b.caption}}</button>
        </div>
        <input *ngIf="editable" type="submit" class="btn btn-primary" value="{{saveTitle}}">
        <button *ngIf="editable" type="button" class="btn btn-secondary" (click)="onClose()">{{'ui.cancel'|translate}}</button>
        <button *ngIf="!editable" type="button" class="btn btn-secondary" (click)="onClose()">{{'ui.close'|translate}}</button>
    </div>
</div>
