import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { DriversService} from "../data/drivers.service";
import { GroupRights} from "../../users/data/interfaces/grouprights";
import { TokenService} from "../../../services/auth/token.service";
import { QueryHttpHelper, SearchColumnType} from "../../../components/JBM/Helpers/QueryHttpHelper";
import { Subscription} from "rxjs";
import { JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import { TranslateService} from "../../../services/multilingual/translate.service";

@Component({
  selector: 'drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss']
})
export class DriversComponent implements OnInit {
  userRights: GroupRights;
  queryHttpHelper: QueryHttpHelper;
  items: any[]=[];
  view: number=1; // Active drivers
  loading: boolean=false;
  showFilter: boolean=false;
  searchboxVisible: boolean=true;
  search: string='';

  items$: Subscription;
  count$: Subscription;

  createActive: boolean=false;
  name: string='';
  nameError: string='';
  nameTouched: boolean=false;
  active: boolean=true;
  editing: boolean=false;
  unique:boolean=true;

  @Output() afterCount=new EventEmitter();

  constructor(
      private DriversService: DriversService,
      private TokenService: TokenService,
      private JBMToastsService: JBMToastsService,
      private TranslateService: TranslateService
  ) {
    this.userRights=this.TokenService.getRightsByName('logistics');
  }
  ngOnInit(): void {
    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('name');
    this.queryHttpHelper.addSearchColumn('name', SearchColumnType.string);

    this.getData(true);
  }
  getData(countRows:boolean=false) {
    this.loading=true;
    let params=this.getParams(false);
    this.items$=this.DriversService.get(params).subscribe(
        (data: any) => this.items=data.data,(error)=>console.error(error),()=>{
          this.loading=false;

          if(countRows) {
            let params=this.getParams(true);
            this.count$=this.DriversService.get(params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,
                (error)=>console.error(error),
                ()=>this.afterCount.emit(this.queryHttpHelper.paginationProps.itemCount)
            );
          }
        });
  }
  getParams(countRows:boolean) {
    let params=this.queryHttpHelper.getHttpParams(countRows);
    if(this.view)
      params=params.append('view',this.view.toString());
    return params;
  }
  setView(view: number) {
    this.view=view;
    this.getData(true);
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row alreay in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.name=data.name;
    this.nameTouched=true;
    this.active=data.active;
    this.unique=true;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData(false);
  }
  toggleActive(event) {
    let driver_id=parseInt(event.id);
    for(let item of this.items)
      if(item.id===driver_id) {
        item.active=event.active;
        let driver= { id: item.id, name: item.name, active: item.active }
        this.DriversService.update(driver).subscribe(()=>{},error=>this.handleError(error),()=>this.afterSave())
      }
  }
  validateName() {
    let value=this.name.trim();
    if(!value) {
      this.nameError=this.TranslateService.GetTranslation('entity.requiredfield');
      return false;
    }
    return true;
  }
  onChangeName() {
    this.nameTouched=true;
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    this.DriversService.create(data).subscribe(()=>{},error=>this.handleError(error),()=>this.afterSave())
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.name==this.name && originalValues.active==this.active) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }
    let data=this.setItemData(originalValues.id);
    this.DriversService.update(data).subscribe(()=>{},error=>this.handleError(error),()=>this.afterSave())
  }
  getValues() {
    this.name=this.name.trim();
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    return { id: id, name: this.name, active: this.active }
  }
  clearItemData() {
    this.name='';
    this.nameTouched=false;
    this.active=true;
  }
  handleError(error) {
    this.unique=true;
    if(error==='not_unique') {
      this.unique=false;
      this.nameError=this.TranslateService.GetTranslation('entity.name-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) {
      this.createCancel();
      this.getData(true);
    }
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
