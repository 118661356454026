<div *ngIf="quotation" class="quotation-details pt-3 pb-1" [ngClass]="{'bg-blue-extra-light p-3 mb-3': expanded}">
   <div class="expandable clearfix">
      <div class="row">
         <div class="col-md-6">
            <div class="jbm-properties-list" [ngClass]="{'collapsed': !expanded}">
               <div class="item">
                  <label class="label">{{'customer'|translate}}:</label>
                  <label class="value">
                     <jbm-link-button
                       [caption]="quotation.customer.name"
                       [style]="'margin-top:-0.15rem'"
                       (onClick)="showCustomer()">
                     </jbm-link-button>
                  </label>
               </div>
            </div>
         </div>
         <div class="col-md-5">
            <div class="jbm-properties-list ml-3" [ngClass]="{'collapsed': !expanded}">
               <div class="item">
                  <label class="label">{{'entity.project'|translate}}:</label>
                  <label class="value">
                     <jbm-link-button
                       [caption]="quotation.project.name"
                       [style]="'margin-top:-0.15rem'"
                       (onClick)="showProject()">
                     </jbm-link-button>
                  </label>
               </div>
            </div>
         </div>
      </div>
   </div>

   <div *ngIf="expanded" class="row">
      <div class="col-md-6">
         <div class="jbm-properties-list">
            <div *ngIf="quotation.customer.phone" class="item">
               <label class="label">{{'entity.phone'|translate}}:</label>
               <label class="value">{{quotation.customer.phone}}</label>
            </div>
            <div *ngIf="quotation.customer.email" class="item">
               <label class="label">{{'entity.email'|translate}}:</label>
               <label class="value">{{quotation.customer.email}}</label>
            </div>
            <div class="item">
               <label class="label">{{'entity.blocked'|translate}}:</label>
               <label class="value">
                  <span *ngIf="quotation.customer.blocked"  class="badge bg-danger">{{'ui.yes'|translate}}</span>
                  <span *ngIf="!quotation.customer.blocked" class="badge bg-success">{{'ui.no'|translate}}</span>
               </label>
            </div>
            <div *ngIf="quotation.customer.description" class="item">
               <label class="label">{{'entity.notes'|translate}}:</label>
               <label>
                  <jbm-comments-box [comments]="quotation.customer.description" height="2.75" class="customer"></jbm-comments-box>
               </label>
            </div>
            <div class="item">
               <label class="label">{{'entity.customer'|translate}}{{'ui.message'|translate|lowercase}}:</label>
               <label class="value" style="margin-top: -0.2rem;">
                  {{quotation.customer_comments}}
                  <button *ngIf="editable" class="btn btn-sm round btn-primary" [ngClass]="{'ml-3': quotation.customer_comments}" (click)="editCustomerComments()">
                     <i class="far fa-edit"></i>
                  </button>
               </label>
            </div>
         </div>
      </div>
      <div class="col-md-6">
         <div class="jbm-properties-list ml-3">
            <div *ngIf="quotation.project.address" class="item">
               <label class="label">{{'project.work-location'|translate}}:</label>
               <label class="value location">
                  <div class="float-start">
                     {{quotation.project.address.street}}&nbsp;{{quotation.project.address.no}}<br />
                     {{quotation.project.address.postcode}}&nbsp;&nbsp;{{quotation.project.address.city}}
                  </div>
               </label>
            </div>
            <div class="item">
               <label class="label">{{'project.state'|translate}}:</label>
               <label class="value">{{projectState.description}}</label>
            </div>
            <div *ngIf="quotation.project.plant" class="item">
               <label class="label">{{'company.plant'|translate}}:</label>
               <label class="value">{{quotation.project.plant.name}}</label>
            </div>
         </div>
      </div>
   </div>
</div>

<jbm-modal [id]="'customerDetails'" [className]="'customer-details'"></jbm-modal>
<ng-template #modalCustomerDetails let-modal>
   <customer-details [customer]="quotation.customer" [inModal]="true" (onClose)="closeCustomer()"></customer-details>
</ng-template>

<jbm-modal [id]="'customerComments'" [className]="'customer-comments'"></jbm-modal>
<ng-template #modalCustomerComments let-modal>
   <quotation-customer-comments [quotation_id]="quotation.id" [revision_number]="quotation.revision_number" (onSave)="saveCustomerComments($event)" (onClose)="closeCustomerComments()"></quotation-customer-comments>
</ng-template>

<jbm-modal [id]="'projectDetails'" [className]="'project-details'"></jbm-modal>
<ng-template #modalProjectDetails let-modal>
   <project-details [project_id]="quotation.project.id" (onClose)="closeProject()"></project-details>
</ng-template>
