import {Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'jbm-empty-view',
  templateUrl: './JBMEmptyView.component.html',
  styleUrls: ['./JBMEmptyView.component.scss']
})
export class JBMEmptyViewComponent implements OnInit {
  showable: boolean=false;

  @Input() emptyView: boolean=true;
  @Input() className: string='';

  ngOnInit() {
    if(this.className==undefined) this.className='';

    // Delay .5 second to prevend from showing empty view message on loading data parent component
    setTimeout(()=>this.showable=true,500);
  }
}
