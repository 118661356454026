import {Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {PriceService} from "../../../../services/price/price.service";
import {ResourcePricelistsService} from "../../data/resource-pricelists.service";
import {ResourcePricesService} from "../../data/resource-prices.service";
import {ResourcesService} from "../../../recipes/data/resources.service";
import {TokenService} from "../../../../services/auth/token.service";
import {LocalStorageService} from "../../../../services/storage/localstorage.service";
import {RefererService} from "../../../../services/storage/referer.service";
import {
  JBMTableActionDef,
  JBMTableColumnAlignment,
  JBMTableColumnDef,
  JBMTableColumnType,
  JBMTableComponent
} from "../../../../components/JBM/JBMTable/JBMTable.component";
import {JBMPropertiesListItem} from "../../../../components/JBM/Views/JBMPropertiesList/JBMPropertiesList.component";
import {QueryHttpHelper, SearchColumnType} from "../../../../components/JBM/Helpers/QueryHttpHelper";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {ModalService} from "../../../../components/JBM/JBMModal/service/modal.service";
import {HttpParams} from "@angular/common/http";
import {ResourcePricelist} from "../../data/interfaces/resource-pricelist";
import {GroupRights} from '../../../users/data/interfaces/grouprights';

@Component({
  selector: 'resource-prices',
  templateUrl: './resource_prices.component.html',
  styleUrls: ['./resource_prices.component.scss']
})
export class ResourcePricesComponent implements OnInit, OnDestroy {
  resourcePricelist: ResourcePricelist;
  userRights: GroupRights;
  items: any;
  columnDefs: JBMTableColumnDef[];
  actionDefs: JBMTableActionDef[];
  language: string=this.TokenService.getLanguage();
  parentLinkCaption: string;
  parentLinkRoute: string;
  priceListProps: JBMPropertiesListItem[];
  id: number;
  details: any;
  loading: boolean=false;
  available: boolean=true;

  items$: Subscription;
  count$: Subscription;
  resourcesCount$: Subscription;

  queryHttpHelper: QueryHttpHelper;
  searchboxVisible: boolean=true;
  search: string='';
  filtersVisible: boolean=false;

  @ViewChild('table') table: JBMTableComponent;
  @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;
  @ViewChild('modalContent') modalTemplate: ElementRef;

  constructor(
      private ActivatedRoute: ActivatedRoute,
      private PriceService: PriceService,
      private ResourcePricelistsService: ResourcePricelistsService,
      private ResourcePricesService: ResourcePricesService,
      private ResourcesService: ResourcesService,
      public modalService: ModalService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private LocalStorageService: LocalStorageService,
      private RefererService: RefererService,
      private JBMToastsService: JBMToastsService
  ) {
    // The route resolver has fetched the pricelist data
    this.resourcePricelist=this.ActivatedRoute.snapshot.data.pricelist.data;
  }

  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('financial');

    this.columnDefs = [
      { name: 'id', type: JBMTableColumnType.id },
      { name: 'description', header: this.TranslateService.GetTranslation('entity.description'), sortable: true, filterable: true, width: 15},
      { name: 'valid_from', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('pricelist.valid-from'), sortable: true, width: 7},
      { name: 'valid_to', type: JBMTableColumnType.date, header: this.TranslateService.GetTranslation('pricelist.valid-to'), sortable: true, width: 7},
      { name: 'price', type: JBMTableColumnType.price, header: this.TranslateService.GetTranslation('financial.resource-price'), sortable: true, width: 9 },
      { name: 'actions', type: JBMTableColumnType.boolean, align: JBMTableColumnAlignment.left, width: 10 }
    ];

    this.queryHttpHelper = new QueryHttpHelper();
    this.queryHttpHelper.setSortColumn('description');
    this.queryHttpHelper.addSearchColumn('description', SearchColumnType.string);

    // Set parent route
    this.parentLinkCaption=this.TranslateService.GetTranslation('entity.resource')+' '+
        this.TranslateService.GetTranslation('financial.pricelists').toLowerCase();
    this.parentLinkRoute='settings/financial/resource-pricelists';

    // Set optional parent route from active referer
    let referer=this.RefererService.getReferer('customers','resource-pricelists');
    if(typeof referer=='object') {
      this.parentLinkCaption=referer.caption;
      this.parentLinkRoute=referer.route;
    }

    setTimeout(()=> {
      this.queryHttpHelper.paginationProps.pageSize=this.table.getRowCount();
      this.getData(true);
    },100);
  }
  getData(countRows:boolean=false) {
    // Set pricelist properties
    let type=this.ResourcePricelistsService.getTypeAsString(this.resourcePricelist.type, this.TranslateService);
    this.priceListProps=[];
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('financial.resource-pricelist'), value: this.resourcePricelist.description });
    this.priceListProps.push({ label: this.TranslateService.GetTranslation('pricelist.type'), value: type });

    this.loading=true;
    let params=this.queryHttpHelper.getHttpParams(false);
    this.items$=this.ResourcePricesService.getData(this.resourcePricelist.id, params).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{
          if(this.items.length)
            this.setDeletePermissions();

          // Set actions column template
          this.columnDefs[5].template=this.actionsTemplate;

          this.loading=false;
          if(countRows) {
            let params=this.queryHttpHelper.getHttpParams(true);
            this.count$=this.ResourcePricesService.getData(this.resourcePricelist.id, params).subscribe(
                (data: any) => this.queryHttpHelper.paginationProps.itemCount = data.data,()=>{},()=>{} );
          }
        } );
  }
  rowCountChange(rowCount) {
    this.queryHttpHelper.paginationProps.pageSize=rowCount;
    this.getData();
  }
  setDeletePermissions() {
    let ids=[];
    for(let i=0; i<this.items.length; i++ ) {
      this.items[i].deletePermission=false;
      ids.push(this.items[i].id);
    }
    let deletePermissions=[];
    this.ResourcePricesService.getDeletePermissions(ids).subscribe(
        (data)=>deletePermissions=data.data,
        (error)=>console.error(error),
        ()=>{
          for(let i=0; i<this.items.length; i++ )
            this.items[i].deletePermission=(deletePermissions.indexOf(this.items[i].id)>-1);
        }
    )
  }
  pageChange(page) {
    this.queryHttpHelper.paginationProps.page=page;
    this.getData();
  }
  sortChange(event) {
    this.queryHttpHelper.setSortColumn(event.name, event.descending);
    this.getData();
  }
  searchChange(event) {
    let value=event.value;
    let minlength=event.minlength;
    value=value.length<minlength ? '' : value;
    if(this.queryHttpHelper.getSearchValue() != value) {
      this.queryHttpHelper.setSearchValue(value);
      this.getData(true);
    }
  }
  toggleFilters(event) {
    this.filtersVisible=!this.filtersVisible;
    this.queryHttpHelper.clearFilters();
    this.queryHttpHelper.setSearchValue('');
    this.getData(true);
  }
  filterChange(event) {
    this.queryHttpHelper.setFilterItem(event.name, event.value);
    this.getData(true);
  }
  addItem() {
    this.id=0;

    // Check availability of articles that do not belong to this pricelist
    let httpParams=new HttpParams();
    httpParams=httpParams.append('count','1');
    this.resourcesCount$=this.ResourcesService.getSelectList(this.resourcePricelist.id, httpParams).subscribe(
        (data: any) => this.available=data.data>0, () => {
        }, () => {
          if(!this.available)
            this.JBMToastsService.error( this.TranslateService.GetTranslation('pricelist.no-resources-available'));
          else
            this.openModal();
        });
  }
  action(event) {
    this.rowSelect(event.row);
  }
  rowSelect(row) {
    this.id=row.id;
    this.details=row;
    this.openModal();
  }
  openModal() {
    this.modalService.open( this.modalTemplate,'resource-price');
  }
  saved() {
    this.closeModal();
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    // Refresh view
    this.getData(true);
  }
  canceled() {
    this.closeModal();
  }
  closeModal() {
    this.modalService.close('resource-price');
  }
  delete(id) {
    this.ResourcePricesService.delete(id).subscribe(
        ()=>{},(error)=>console.error(error),()=> {
          this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-deleted'));
          this.getData(true);
        }
    )
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
    if(this.resourcesCount$!=undefined) this.resourcesCount$.unsubscribe();
  }
}
