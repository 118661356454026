import {NgModule } from '@angular/core';
import {CommonModule } from '@angular/common';
import {SharedModule} from "../../shared/shared.module";
import {AngularSplitModule} from "angular-split";
import {JBMButtonsModule} from "../../components/JBM/Buttons/jbmbuttons.module";
import {JBMDropdownsModule} from "../../components/JBM/Dropdowns/jbmdropdowns.module";
import {JBMTableModule} from "../../components/JBM/JBMTable/jbmtable.module";
import {JBMViewModule } from "../../components/JBM/Views/jbmview.module";
import {JBMFormModule } from "../../components/JBM/Forms/jbmform.module";
import {JBMModalModule } from "../../components/JBM/JBMModal/jbmmodal.module";
import {JBMAsidesModule} from "../../components/JBM/Asides/jbmasides.module";
import {JBMMediaModule} from "../../components/JBM/Media/jbmmedia.module";
import {PresentationsRoutingModule } from './presentations-routing.module';
import {PresentationsComponent} from "./datatable/presentations.component";
import {PresentationComponent} from "./form/presentation.component";
import {PdfTemplateEditorComponent } from './pdf-template-editor/pdf-template-editor.component';
import {CodemirrorModule} from "@ctrl/ngx-codemirror";
import {CodemirrorEditorComponent } from './codemirror-editor/codemirror-editor.component';
import {PDFMakeViewerComponent } from './pdfmake-viewer/pdf-make-viewer.component';
import {MailTemplateEditorComponent} from "./mail-template-editor/mail-template-editor.component";
import {MailViewerComponent } from './mail-viewer/mail-viewer.component';
import {MailAttachmentsComponent } from './mail-attachments/mail-attachments.component';
import {MediaFilesComponent } from './media-files/datatable/media-files.component';
import {MediaFilesSelectorComponent} from "./media-files/selector/media-files-selector.component";
import {MediaFileComponent } from './media-files/form/mediafile.component';
import {TextPlaceholdersComponent } from './text-placeholders/datatable/text-placeholders.component';
import {TextPlaceholderComponent} from "./text-placeholders/form/text-placeholder.component";
import {PlaceholderPickerComponent } from './templates/placeholder-picker/placeholder-picker.component';
import {LayoutModule} from '../../layout/layout.module';

@NgModule({
  declarations: [PresentationsComponent, PresentationComponent, PDFMakeViewerComponent,
    MailTemplateEditorComponent, PdfTemplateEditorComponent, CodemirrorEditorComponent, MailViewerComponent, MailAttachmentsComponent,
    MediaFilesComponent, MediaFilesSelectorComponent, MediaFileComponent, TextPlaceholdersComponent, TextPlaceholderComponent,
    PlaceholderPickerComponent],
    imports: [CommonModule, SharedModule, PresentationsRoutingModule, AngularSplitModule, CodemirrorModule,
        JBMAsidesModule, JBMButtonsModule, JBMDropdownsModule, JBMTableModule, JBMViewModule, JBMFormModule, JBMModalModule,
        JBMMediaModule, LayoutModule
    ],
	exports: [MediaFilesSelectorComponent, MailViewerComponent, PDFMakeViewerComponent]
})
export class PresentationsModule { }
