<app-header>
    <jbm-view-title [caption]="'financial.concrete-specs'|translate"
      [parentLinkCaption]="'menu.settings'|translate"
      [parentLinkSlug]="'settings'"></jbm-view-title>
    <div class="float-end">
        <jbm-help-button [helpTopicID]="0"></jbm-help-button>
    </div>
</app-header>
<section class="module padding">
    <nav ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs module bg-light">
        <ng-container ngbNavItem [ngbNavItem]="0">
            <a ngbNavLink>{{'financial.concrete-environments'|translate}}</a>
            <ng-template ngbNavContent>
                <environments></environments>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem [ngbNavItem]="1">
            <a ngbNavLink>{{'financial.concrete-consistencies'|translate}}</a>
            <ng-template ngbNavContent>
                <consistencies></consistencies>
            </ng-template>
        </ng-container>
        <ng-container ngbNavItem [ngbNavItem]="2">
            <a ngbNavLink>{{'financial.concrete-strengths'|translate}}</a>
            <ng-template ngbNavContent>
                <strengths></strengths>
            </ng-template>
        </ng-container>
    </nav>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>
