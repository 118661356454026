import {Component, OnInit, OnDestroy} from '@angular/core';
import {ArticlegroupsService} from "../data/articlegroups.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";

@Component({
  selector: 'articlegroups',
  templateUrl: './articlegroups.component.html',
  styleUrls: ['./articlegroups.component.scss']
})
export class ArticlegroupsComponent implements OnInit, OnDestroy {
  userRights: any;
  items: any[]=[];
  language: string=this.TokenService.getLanguage();
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  createActive: boolean=false;
  name: string='';
  nameError: string='';
  nameTouched: boolean=false;
  unique:boolean=true;
  description: string='';
  descriptionError: string='';
  descriptionTouched: boolean=false;
  editing: boolean=false;

  constructor(
      public ArticlegroupsService: ArticlegroupsService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('customers');
    this.getData();
  }
  getData() {
    this.loading=true;
    this.items$=this.ArticlegroupsService.get(this.language).subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{
          this.loading=false;
          // Set editmode to false for each item
          for(let item of this.items)
            item.editing=false;
        } );
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row already in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.name=data.name;
    this.unique=true;
    this.description=data.description;
    this.descriptionTouched=true;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData();
  }
  onChangeName() {
    this.nameTouched=true;
  }
  validateName() {
    let value=this.name.trim();
    if(!value) this.nameError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.descriptionError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    this.ArticlegroupsService.create(data).subscribe(()=>{},
       error => { this.handleError(error) },() => { this.afterSave() })
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.name==this.name && originalValues.description==this.description) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }
    let data=this.setItemData(originalValues.id);
    this.ArticlegroupsService.update(data).subscribe(
        ()=>{},error => { this.handleError(error) },
        () => { this.afterSave() })
  }
  getValues() {
    this.name=this.name.trim();
    this.description=this.description.trim();
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    return { l: { article_groups_id: id, language: this.language, name: this.name, description: this.description }}
  }
  clearItemData() {
    this.name='';
    this.nameTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  handleError(error) {
    this.unique=true;
    if(error=='not_unique') {
      this.unique=false;
      this.nameError=this.TranslateService.GetTranslation('entity.name-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) {
      this.createCancel();
      this.getData();
    }
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
