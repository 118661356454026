import {Component, OnInit, OnDestroy} from '@angular/core';
import {CustomertypesService} from "../data/customertypes.service";
import {TranslateService} from "../../../services/multilingual/translate.service";
import {TokenService} from "../../../services/auth/token.service";
import {Subscription} from "rxjs";
import {JBMToastsService} from "../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from '../../users/data/interfaces/grouprights';

@Component({
  selector: 'customertypes',
  templateUrl: './customertypes.component.html',
  styleUrls: ['./customertypes.component.scss']
})
export class CustomertypesComponent implements OnInit, OnDestroy {
  userRights: GroupRights;
  items: any[]=[];
  loading: boolean=false;

  items$: Subscription;
  count$: Subscription;

  createActive: boolean=false;
  code: string='';
  codeError: string='';
  codeTouched: boolean=false;
  codeUnique:boolean=true;
  description: string='';
  descriptionError: string='';
  descriptionTouched: boolean=false;
  descriptionUnique:boolean=true;
  editing: boolean=false;

  constructor(
      public CustomertypesService: CustomertypesService,
      private TokenService: TokenService,
      private TranslateService: TranslateService,
      private JBMToastsService: JBMToastsService
  ) { }
  ngOnInit(): void {
    this.userRights = this.TokenService.getRightsByName('customers');
    this.getData();
  }
  getData() {
    this.loading=true;
    this.items$=this.CustomertypesService.get().subscribe(
        (data: any) => this.items = data.data,()=>{},()=>{
          this.loading=false;
          // Set editmode to false for each item
          for(let item of this.items)
            item.editing=false;
        } );
  }
  // Create item
  startCreate() {
    if(this.editing) this.cancelEditing();
    this.clearItemData();
    this.createActive=true;
  }
  createCancel() {
    this.createActive=false;
    this.clearItemData();
  }
  // Update item
  startEditing(id) {
    if(!this.userRights.AllowUpdate) return false;
    if(this.createActive) this.createCancel();
    for(let item of this.items)
      if(item.id===id) {
        if(item.editing) return false;  // Row already in edit mode
        item.editing=true;
      } else
        item.editing=false;
    this.editing=true;
    let data=this.getOriginalValues();
    this.code=data.code;
    this.codeUnique=true;
    this.description=data.description;
    this.descriptionTouched=true;
    this.descriptionUnique=true;
  }
  cancelEditing() {
    for(let item of this.items)
      item.editing=false;
    this.editing=false;
    this.clearItemData();
    this.getData();
  }
  onChangeCode() {
    this.codeTouched=true;
  }
  validateCode() {
    let value=this.code.trim();
    if(!value) this.codeError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  onChangeDescription() {
    this.descriptionTouched=true;
  }
  validateDescription() {
    let value=this.description.trim();
    if(!value) this.descriptionError=this.TranslateService.GetTranslation('entity.requiredfield');
    return value!='';
  }
  // Save item data
  createItem() {
    this.getValues();
    let data=this.setItemData(0);
    this.CustomertypesService.create(data).subscribe(()=>{},
       error => { this.handleError(error) },() => { this.afterSave() })
  }
  updateItem() {
    this.getValues();
    let originalValues=this.getOriginalValues();
    if(originalValues.code===this.code && originalValues.description===this.description) {
      // Nothing changed; pretend if it has been a successful update
      this.showToast();
      this.cancelEditing();
      return false;
    }
    let data=this.setItemData(originalValues.id);
    this.CustomertypesService.update(data).subscribe(
        ()=>{},error => { this.handleError(error) },
        () => { this.afterSave() })
  }
  getValues() {
    this.code=this.code.trim();
    this.description=this.description.trim();
  }
  getOriginalValues() {
    for(let item of this.items)
      if(item.editing)
        return item;
    return null;
  }
  setItemData(id) {
    return { id: id, code: this.code, description: this.description }
  }
  clearItemData() {
    this.code='';
    this.codeTouched=false;
    this.description='';
    this.descriptionTouched=false;
  }
  handleError(error) {
    this.codeUnique=true;
    this.descriptionUnique=true;
    if(error==='code_not_unique') {
      this.codeUnique=false;
      this.codeError=this.TranslateService.GetTranslation('entity.code-not-unique');
    }
    if(error==='description_not_unique') {
      this.descriptionUnique=false;
      this.descriptionError=this.TranslateService.GetTranslation('entity.description-not-unique');
    }
  }
  afterSave() {
    this.showToast();
    if(this.createActive) {
      this.createCancel();
      this.getData();
    }
    if(this.editing) this.cancelEditing();
  }
  showToast() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
  }
  ngOnDestroy() {
    // Clean up observable subscriptions to avoid memory leaks
    if(this.items$!=undefined) this.items$.unsubscribe();
    if(this.count$!=undefined) this.count$.unsubscribe();
  }
}
