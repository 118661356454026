import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute } from '@angular/router';
import {Location} from "@angular/common";
import {PlantsService } from "../../data/plants.service";
import {TranslateService} from "../../../../services/multilingual/translate.service";
import {TokenService} from "../../../../services/auth/token.service";
import {PriceService } from "../../../../services/price/price.service";
import {ValidationPatternsService} from "../../../../services/validation/patterns.service";
import {GoogleMapsService} from "../../../../services/google-maps/google-maps.service";
import {Plant} from "../../data/interfaces/Plant";
import {JBMToastsService} from "../../../../components/JBM/Views/JBMToasts/JBMToasts.service";
import {GroupRights} from "../../../users/data/interfaces/grouprights";
import {EmptyGPSAddress, GPSAddress} from "../../../../components/JBM/Helpers/GPSInfo";

@Component({
  selector: 'form-plant',
  templateUrl: './plant.component.html',
  styleUrls: ['./plant.component.scss']
})

export class PlantComponent implements OnInit {
  plant: Plant;
  financialRights: GroupRights=this.TokenService.getRightsByName('financial');
  basepriceFormatted: string='';
  GPS_address: GPSAddress=EmptyGPSAddress();
  error_lonlat: string='';

  @ViewChild('content') modalTemplate: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private PlantsService: PlantsService,
    private JBMToastsService: JBMToastsService,
    private TokenService: TokenService,
    private GoogleMapsService: GoogleMapsService,
    private ActivatedRoute: ActivatedRoute,
    private Location: Location,
    private Router: Router,
    private TranslateService: TranslateService,
    private PriceService: PriceService,
    private ValidationPatternsService: ValidationPatternsService
  ) {
    // The route resolver has fetched the plant data
    this.plant=this.ActivatedRoute.snapshot.data.plant.data;
  }

  form: FormGroup;
  isSubmitted: boolean=false;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      code: new FormControl('',[Validators.required]),
      name: new FormControl('',[Validators.required]),
      baseprice: new FormControl('',[this.PriceService.priceValidator(this.PriceService)]),
      city : new FormControl('',[Validators.required]),
      street: new FormControl('',[Validators.required]),
      no: new FormControl('',[Validators.required]),
      postcode: new FormControl('',[Validators.required, Validators.pattern('[1-9][0-9]{3}[\\s]?[A-Za-z]{2}')]),
      lon: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      lat: new FormControl('',[Validators.pattern(this.ValidationPatternsService.latLongitudePattern())]),
      production_capacity: new FormControl('',[Validators.pattern('[0-9]+$'), Validators.min(0), Validators.max(1000)]),
      driving_time_margin: new FormControl('',[Validators.pattern('[0-9]+$'), Validators.min(0), Validators.max(120)])
    }, { updateOn: "blur" } );

    this.setControlValues();
  }

  get formControls() { return this.form.controls; }

  setControlValues() {
    this.basepriceFormatted=this.PriceService.formatPrice(this.plant.baseprice);
    this.form.controls.code.setValue(this.plant.code);
    this.form.controls.name.setValue(this.plant.name);
    this.form.controls.baseprice.setValue(this.basepriceFormatted);
    this.form.controls.city.setValue(this.plant.address_details.city);
    this.form.controls.street.setValue(this.plant.address_details.street);
    this.form.controls.no.setValue(this.plant.address_details.no);
    this.form.controls.postcode.setValue(this.plant.address_details.postcode);
    this.form.controls.lon.setValue(this.plant.id>0 ? this.plant.address_details.lon : '');
    this.form.controls.lat.setValue(this.plant.id>0 ? this.plant.address_details.lat : '');
    this.form.controls.production_capacity.setValue(this.plant.production_capacity);
    this.form.controls.driving_time_margin.setValue(this.plant.driving_time_margin);
    this.updateAddress();
  }

  updateAddress() {
    // Lookup if postcode and no are given
    if(this.formControls.postcode.value.trim() && this.formControls.no.value.trim()) {
      this.GoogleMapsService.getLookup('', this.formControls.no.value,
          this.formControls.postcode.value,'',true)
          .subscribe(
              (data)=>this.GPS_address=data,
              (error)=>{console.error(error)},
              ()=>this.autoCompleteAddress()
          );
    } else {
      // Lookup if street, no and city are given
      if(this.formControls.street.value.trim() && this.formControls.no.value.trim() &&
          this.formControls.city.value.trim()) {
        this.GoogleMapsService.getLookup( this.formControls.street.value,
            this.formControls.no.value,'', this.formControls.city.value,true)
            .subscribe(
                (data)=>this.GPS_address=data,
                (error)=>{console.error(error)},
                ()=>{this.autoCompleteAddress()}
            );
      }
    }
  }
  autoCompleteAddress() {
    if(this.GPS_address.street) this.formControls.street.setValue(this.GPS_address.street);
    if(this.GPS_address.postcode) this.formControls.postcode.setValue(this.GPS_address.postcode);
    if(this.GPS_address.city) this.formControls.city.setValue(this.GPS_address.city);
    if(this.GPS_address.no) this.formControls.no.setValue(this.GPS_address.no);
    this.formControls.lat.setValue(this.GPS_address.latitude);
    this.formControls.lon.setValue(this.GPS_address.longitude);
  }

  save() {
    this.isSubmitted = true;

    if(this.form.invalid)
      return;

    // Validate longitudes and latitudes
    let valid=true;
    let lon=this.formControls.lon.value.toString().trim();
    let lat=this.formControls.lat.value.toString().trim();

    if(lat.length && lon.length && lat==lon) {
      this.error_lonlat=this.TranslateService.GetTranslation('gps.longitude-latitude-same');
      valid=false;
    }
    if(lat.length && lon.length===0) {
      this.formControls.lon.setErrors({'required': true});
      valid=false;
    }
    if(lon.length && lat.length===0) {
      this.formControls.lat.setErrors({'required': true});
      valid=false;
    }
    if(!valid) return;

    // Store plant
    this.plant = {
      id: this.plant.id,
      code: this.formControls.code.value,
      name: this.formControls.name.value,
      baseprice: this.PriceService.getPriceFromStr(this.formControls.baseprice.value),
      address_details_id: this.plant.address_details_id,
      address_details: {
        id: this.plant.address_details_id,
        city: this.formControls.city.value,
        street: this.formControls.street.value,
        postcode: this.formControls.postcode.value,
        no: this.formControls.no.value,
        lat: this.formControls.lat.value,
        lon: this.formControls.lon.value
      },
      version: this.plant.version,
      production_capacity: parseInt(this.formControls.production_capacity.value),
      driving_time_margin: parseInt(this.formControls.driving_time_margin.value)
    }
    this.PlantsService.update(this.plant).subscribe(
      ()=>{},(error)=>console.error(error),()=>this.afterSave())
  }
  afterSave() {
    this.JBMToastsService.success( this.TranslateService.GetTranslation('ui.entity-saved'));
    this.redirect();
  }
  cancel() {
    this.redirect();
  }
  redirect() {
    this.Router.navigateByUrl('settings/company/plants');
  }
}
